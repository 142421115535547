import { gql } from "@urql/core"
import { Observable } from "rxjs"
import { CONTRACT_DEPLOYER } from "../../../../config"
import {
  GetEarningPreviewQuery,
  GetEarningPreviewQueryVariables,
} from "../../../../generated/graphql/graphql.generated"
import { asSender } from "../../../../generated/smartContractHelpers/asSender"
import { Currency } from "../../../../utils/alexjs/Currency"
import { FWPToken } from "../../../../utils/alexjs/currencyHelpers"
import { gqlQuery } from "../../../../utils/graphqlHelpers"
import { fromUrqlSource } from "../../../../utils/Observable/fromUrqlSource"
import pLimit from "../../../../utils/pLimit"

// dev env cycle is way too fast
// we need to use production annual factor for reasonable APR / APY
export const PRODUCTION_ANNUAL_FACTOR = 100.15

const limit = pLimit(5)
// const limit = <T>(g: () => T): T => g()

export type StakingToken = Currency.ALEX | FWPToken

export async function getStakingStatsAtCycle(
  token: StakingToken,
  cycle: number,
): Promise<number | undefined> {
  return await limit(() =>
    asSender(CONTRACT_DEPLOYER)
      .contract("alex-reserve-pool")
      .func("get-staking-stats-at-cycle")
      .call({
        token,
        "reward-cycle": cycle,
      }),
  )
}

// export const aprSample = [
//   13.7001, 17.2945, 19.1106, 20.2081, 20.9949, 21.6285, 22.1743, 22.7546,
//   23.2516, 23.7251, 24.2595, 24.8012, 25.6486, 26.5401, 27.3215, 28.0125,
//   28.6364, 29.196, 29.7063, 30.1699, 30.5974, 30.9893, 31.3594, 31.7044,
//   32.0609, 32.4756, 33.0738, 33.9306, 34.8662, 36.1761, 37.9446, 40.6256,
// ]

export function getEarningPreview(token: StakingToken): Observable<number[]> {
  return fromUrqlSource(
    gqlQuery(
      gql<GetEarningPreviewQuery, GetEarningPreviewQueryVariables>`
        query GetEarningPreview($tokenName: String!) {
          laplace_latest_alex_reserve_pools(
            where: { token_name: { _eq: $tokenName } }
          ) {
            earning_preview
          }
        }
      `,
      { tokenName: token },
    ),
    result => {
      const previews = result.data.laplace_latest_alex_reserve_pools[0]!
        .earning_preview as any
      return fixCycle32(previews)
    },
  )
}

function fixCycle32(input: number[]): number[] {
  // in the case of last number being too high, we need to use 31 instead
  const fixedLastCycle =
    input[input.length - 2]! +
    (input[input.length - 2]! - input[input.length - 3]!)
  if (!isNaN(fixedLastCycle)) {
    input[input.length - 1] = fixedLastCycle
  }
  return input
}
