import {
  ClarityType,
  contractPrincipalCV,
  PrincipalCV,
  standardPrincipalCV,
  stringAsciiCV,
  uintCV,
} from "@stacks/transactions"
import {
  addressResult,
  contractResult,
  stringResult,
  transcoders,
} from "clarity-codegen"
import type { Decoder, Encoder } from "clarity-codegen/lib/runtime/types"
import { CONTRACT_DEPLOYER, CURRENCY_CONTRACT_OVERWRITES } from "../../config"

export * from "clarity-codegen"

// 39.055547 * 1e8
// 3905554699.9999995
// when need to round it first before floor it
export const numberCV: Encoder<number> = input =>
  uintCV(Math.floor(Math.round(input * 100) / 100))

export const intResult: Decoder<number> = result => {
  if (result.type === ClarityType.Int || result.type === ClarityType.UInt) {
    return Number(result.value)
  }
  throw new Error(`Expected integer, got ${result.type}`)
}

export const numberT = transcoders({
  encode: numberCV,
  decode: intResult,
})

export const stringAsciiT = transcoders({
  encode: stringAsciiCV,
  decode: stringResult,
})

export function principalCV(principal: string): PrincipalCV {
  if (principal.includes(".")) {
    const [address, contractName] = principal.split(".")
    return contractPrincipalCV(address!, contractName!)
  }
  if (principal.startsWith("SP") || principal.startsWith("ST")) {
    return standardPrincipalCV(principal)
  }
  const contractName = CURRENCY_CONTRACT_OVERWRITES[principal] ?? principal
  return contractPrincipalCV(CONTRACT_DEPLOYER, contractName)
}

export const principleResult: Decoder<string> = result => {
  if (result.type === ClarityType.PrincipalStandard) {
    return addressResult(result)
  } else if (result.type === ClarityType.PrincipalContract) {
    return contractResult(result)
  }
  throw new Error(`Expected principal, got ${result.type}`)
}

export const principalT = transcoders({
  encode: principalCV,
  decode: principleResult,
})

// export const bufferT = transcoders({
//   encode: bufferCV,
//   decode: bufferResult,
// })
