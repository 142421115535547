import { FC } from "react"
import { Card } from "../../../../../components/Card"
import { TextTokenCount } from "../../../../../components/RichTokenCount"
import { Spensor } from "../../../../../components/Spensor"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { FCC } from "../../../../../utils/reactHelpers/types"
import { withClassName } from "../../../../../utils/reactHelpers/withClassName"
import {
  readResource,
  SuspenseResource,
} from "../../../../../utils/SuspenseResource"
import { ReactComponent as Step1Icon } from "./step-1.svg"
import { ReactComponent as Step2Icon } from "./step-2.svg"
import { ReactComponent as Step3Icon } from "./step-3.svg"

export const HowToPlay: FC<{
  priceToken: SuspenseResource<TokenInfo>
  priceTokenCountPerTicket: SuspenseResource<number>
}> = props => (
  <section className="w-full flex flex-col gap-y-2.5">
    <h1 className="text-xl leading-7 font-medium text-white">How to Play</h1>

    <ul className="grid grid-cols-3 gap-6 items-stretch">
      <StepCard className={"col-span-3 lg:col-span-1"}>
        <StepIndex>Step 1</StepIndex>
        <Step1Icon />
        <StepContent>
          <StepTitle>Buy Tickets</StepTitle>
          <StepDescription>
            Prices for tickets are fixed at{" "}
            <Spensor fallback={"-"}>
              {() => (
                <TextTokenCount
                  token={readResource(props.priceToken)}
                  count={readResource(props.priceTokenCountPerTicket)}
                />
              )}
            </Spensor>{" "}
            per ticket.
          </StepDescription>
        </StepContent>
      </StepCard>

      <StepCard className={"col-span-3 lg:col-span-1"}>
        <StepIndex>Step 2</StepIndex>
        <Step2Icon />
        <StepContent>
          <StepTitle>Wait for the Drawing</StepTitle>
          <StepDescription>
            There is one drawing every cycle, the drawing will be done based
            upon a random block at the end of the cycle approaches
          </StepDescription>
        </StepContent>
      </StepCard>

      <StepCard className={"col-span-3 lg:col-span-1"}>
        <StepIndex>Step 3</StepIndex>
        <Step3Icon />
        <StepContent>
          <StepTitle>Check for Prizes</StepTitle>
          <StepDescription>
            Once the round is over, review the drawing page to check if you’ve
            won.
          </StepDescription>
        </StepContent>
      </StepCard>
    </ul>
  </section>
)

const StepCard: FCC<{ className?: string }> = ({ className, children }) => (
  <li className={className}>
    <Card
      backgroundImage=""
      className="bg-gray-800 flex flex-col items-center justify-start h-full"
      roundClassName="rounded-2xl"
    >
      {children}
    </Card>
  </li>
)

const StepIndex = withClassName(
  "text-gray-400 text-base leading-6 font-normal self-start",
  "span",
)

const StepContent = withClassName(
  "flex flex-col gap-y-2.5 items-center pb-6",
  "div",
)

const StepTitle = withClassName(
  "text-base leading-6 font-semibold text-white",
  "h2",
)

const StepDescription = withClassName(
  "text-sm leading-5 font-normal text-gray-400 text-center",
  "p",
)
