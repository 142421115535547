import {
  createFungiblePostCondition,
  createSTXPostCondition,
  FungibleConditionCode,
  FungiblePostCondition,
  STXPostCondition,
} from "@stacks/transactions"
import { contractAddr } from "../../generated/smartContractHelpers/asSender"
import { AMMSwapPool } from "./AMMSwapPool"
import { Currency, DownstreamCurrency, WrappedCurrency } from "./Currency"
import { currencyScale, getAssetIdentifierByCurrency } from "./currencyHelpers"

export const AlexVault = contractAddr("alex-vault")

export function transfer(
  senderAddress: string,
  currency: Currency,
  amount: number,
  compare: FungibleConditionCode = FungibleConditionCode.Equal,
): FungiblePostCondition | STXPostCondition {
  const unwrappedCurrency = WrappedCurrency.safeUnwrap(currency)

  const scale = currencyScale(unwrappedCurrency)
  // 39.055547 * 1e8
  // 3905554699.9999995
  // when need to round it first before floor it
  const withJSPrecisionFixed = Math.round(amount * scale * 100) / 100
  const roundedAmount =
    compare === FungibleConditionCode.LessEqual
      ? Math.ceil(withJSPrecisionFixed)
      : Math.floor(withJSPrecisionFixed)
  if (unwrappedCurrency === DownstreamCurrency.STX) {
    return createSTXPostCondition(senderAddress, compare, roundedAmount)
  }
  const assetIdentifier = AMMSwapPool.isPoolToken(currency)
    ? AMMSwapPool.getAssetIdentifier(currency)
    : getAssetIdentifierByCurrency(unwrappedCurrency)
  return createFungiblePostCondition(
    senderAddress,
    compare,
    roundedAmount,
    assetIdentifier,
  )
}
