import { parseISO } from "date-fns"
import { action } from "mobx"
import { FC, useState } from "react"
import { suspenseResource } from "../../../utils/SuspenseResource"
import { recentMonth } from "../store/CRPSimulatorStore"
import { useCRPSimulatorStore } from "../store/useCRPSimulatorStore"
import { SelectorItem } from "./DateRangeSelector"
import { SimulatorPanel } from "./SimulatorPanel"

export const selectorItems: SelectorItem[] = [
  {
    label: "Mar 1 2020 - Mar 30 2020",
    value: "1",
    dateRange: {
      from: parseISO("20200301T000000Z"),
      to: parseISO("20200330T000000Z"),
    },
  },
  {
    label: "May 1 2021 - May 30 2021",
    value: "2",
    dateRange: {
      from: parseISO("20210501T000000Z"),
      to: parseISO("20210530T000000Z"),
    },
  },
  {
    label: "Recent 30 Days",
    value: "3",
    dateRange: recentMonth,
  },
]

export const WiredSimulatorPanel: FC = () => {
  const store = useCRPSimulatorStore()
  const [value, setValue] = useState<string | undefined>("3")
  return (
    <SimulatorPanel
      collateralBtcCount={store.collateralBtcCount}
      onCollateralBtcCountChange={action((value?: number | null) => {
        store.collateralBtcCount = value ?? undefined
      })}
      estimateReceivedUSDCount={suspenseResource(() => store.estimateReceived$)}
      selectorItems={selectorItems}
      selectorValue={value}
      selectorDateRange={store.dateRange}
      onDateRangeSelect={(v, d) => {
        setValue(v)
        store.setDateRange(d)
      }}
    />
  )
}
