import { FC } from "react"
import { useLaunchPadContractStore } from "../../../../store/useLaunchPadStores"
import { ActionSectionContent$ClaimForRegistered } from "../../_/ActionSectionContent$ClaimForRegistered"

export const WiredActionSectionContent$ClaimForRegistered: FC = () => {
  const store = useLaunchPadContractStore()

  return (
    <ActionSectionContent$ClaimForRegistered
      idoToken={store.tokenInfo$}
      priceToken={store.priceTokenInfo$}
      lotteryTickets={store.claimViewModule.tickets}
    />
  )
}
