import clsx from "clsx"
import React, {
  ReactNode,
  Ref,
  useImperativeHandle,
  useRef,
  useState,
} from "react"
import { first } from "../utils/arrayHelpers"
import { scrollIntoView } from "../utils/domHelpers/scrollIntoView"
import { PropsWithChildren } from "../utils/reactHelpers/types"
import { OneOrMore } from "../utils/types"

export interface TabBarRefValue<T> {
  scrollIntoView(offset: { top?: number }): void
  selectTab(tabValue: T): void
}

export interface TabItem<T> {
  label: ReactNode
  value: T
}

export interface TabBarProps<T>
  extends PropsWithChildren<
    unknown,
    ReactNode | ReactNode[] | ((info: { value: T }) => ReactNode | ReactNode[])
  > {
  className?: string
  tabClassName?: string
  tabbarRef?: Ref<TabBarRefValue<T>>
  tabs: OneOrMore<TabItem<T>>
  selectedTab?: {
    tabValue: T
    onChange?: (selectedTab: TabItem<T>) => void
  }
  rightSide?: ReactNode
}

export function TabBar<T>(props: TabBarProps<T>): JSX.Element {
  const [_selectedTab, _setSelectedTab] = useState(
    () => first(props.tabs).value,
  )
  const _onSelectedTabChanged = (tab: TabItem<T>): void => {
    _setSelectedTab(tab.value)
  }

  const [selectedTab, setSelectedTab] =
    props.selectedTab != null
      ? [props.selectedTab.tabValue, props.selectedTab.onChange]
      : [_selectedTab, _onSelectedTabChanged]

  const ulRef = useRef<HTMLUListElement>(null)

  useImperativeHandle(props.tabbarRef, () => ({
    scrollIntoView(offset) {
      if (!ulRef.current) return
      scrollIntoView(ulRef.current, { offset: offset.top })
    },
    selectTab(tabValue: T) {
      const item = props.tabs.find(t => t.value === tabValue)
      if (item == null) return
      setSelectedTab?.(item)
    },
  }))

  return (
    <>
      <div
        className={clsx(
          "flex items-center border-b border-white/10",
          props.className,
        )}
      >
        <ul ref={ulRef} className={clsx("flex flex-row")}>
          {props.tabs.map((t, idx) => (
            <li
              key={idx}
              className={clsx(
                "cursor-pointer",
                selectedTab === t.value ? "text-gray-200" : "text-gray-400",
                selectedTab === t.value && "border-b-2 border-white",
                props.tabClassName,
              )}
              onClick={() => setSelectedTab?.(t)}
            >
              {t.label}
            </li>
          ))}
        </ul>

        {props.rightSide}
      </div>

      {typeof props.children === "function"
        ? props.children({ value: selectedTab })
        : props.children}
    </>
  )
}
