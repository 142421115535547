import {
  booleanT,
  bufferT,
  defineContract,
  listT,
  numberT,
  optionalT,
  principalT,
  responseSimpleT,
  tupleT,
} from "../smartContractHelpers/codegenImport"

export const alexLottery = defineContract({
  "alex-lottery": {
    "add-approved-operator": {
      input: [{ name: "new-approved-operator", type: principalT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    claim: {
      input: [
        { name: "lottery-id", type: numberT },
        { name: "round-id", type: numberT },
        { name: "winners", type: listT(principalT) },
        { name: "token-trait", type: principalT },
      ],
      output: responseSimpleT(
        tupleT({ gross: numberT, net: numberT, payout: numberT, tax: numberT }),
      ),
      mode: "public",
    },
    "create-pool": {
      input: [
        { name: "token", type: principalT },
        { name: "tokens-per-ticket-in-fixed", type: numberT },
        { name: "registration-start-height", type: numberT },
        { name: "registration-end-height", type: numberT },
      ],
      output: responseSimpleT(numberT),
      mode: "public",
    },
    register: {
      input: [
        { name: "lottery-id", type: numberT },
        { name: "tickets", type: numberT },
        { name: "token-trait", type: principalT },
        { name: "bonus-tickets", type: numberT },
      ],
      output: responseSimpleT(tupleT({ end: numberT, start: numberT })),
      mode: "public",
    },
    "set-apower-per-bonus-in-fixed": {
      input: [{ name: "new-amount", type: numberT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-bonus-max": {
      input: [{ name: "new-max", type: listT(numberT) }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-bonus-thresholds": {
      input: [{ name: "new-thresholds", type: listT(numberT) }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-contract-owner": {
      input: [{ name: "owner", type: principalT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-lottery-round": {
      input: [
        { name: "lottery-id", type: numberT },
        { name: "round-id", type: numberT },
        { name: "draw-height", type: numberT },
        { name: "percent", type: numberT },
        { name: "total-tickets", type: numberT },
        { name: "payout-rate", type: numberT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "transfer-all-to-owner": {
      input: [{ name: "token-trait", type: principalT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "buff-to-uint64": {
      input: [{ name: "bytes", type: bufferT }],
      output: numberT,
      mode: "readonly",
    },
    "byte-to-uint": {
      input: [{ name: "byte", type: bufferT }],
      output: numberT,
      mode: "readonly",
    },
    "div-down": {
      input: [
        { name: "a", type: numberT },
        { name: "b", type: numberT },
      ],
      output: numberT,
      mode: "readonly",
    },
    "get-apower-per-bonus-in-fixed": {
      input: [],
      output: numberT,
      mode: "readonly",
    },
    "get-bonus-max-or-default": {
      input: [{ name: "index", type: numberT }],
      output: numberT,
      mode: "readonly",
    },
    "get-bonus-thresholds-or-default": {
      input: [{ name: "index", type: numberT }],
      output: numberT,
      mode: "readonly",
    },
    "get-contract-owner": {
      input: [],
      output: responseSimpleT(principalT),
      mode: "readonly",
    },
    "get-lottery-id-nonce": { input: [], output: numberT, mode: "readonly" },
    "get-lottery-or-fail": {
      input: [{ name: "lottery-id", type: numberT }],
      output: responseSimpleT(
        tupleT({
          "registration-end-height": numberT,
          "registration-start-height": numberT,
          token: principalT,
          "tokens-per-ticket-in-fixed": numberT,
        }),
      ),
      mode: "readonly",
    },
    "get-lottery-round-or-fail": {
      input: [
        { name: "lottery-id", type: numberT },
        { name: "round-id", type: numberT },
      ],
      output: responseSimpleT(
        tupleT({
          "draw-height": numberT,
          "payout-rate": numberT,
          percent: numberT,
          play: booleanT,
          "total-tickets": numberT,
        }),
      ),
      mode: "readonly",
    },
    "get-lottery-walk-parameters": {
      input: [
        { name: "lottery-id", type: numberT },
        { name: "round-id", type: numberT },
      ],
      output: responseSimpleT(
        tupleT({
          "max-step-size": numberT,
          "total-tickets": numberT,
          "walk-position": numberT,
        }),
      ),
      mode: "readonly",
    },
    "get-max-bonus-for-tickets": {
      input: [{ name: "tickets", type: numberT }],
      output: numberT,
      mode: "readonly",
    },
    "get-ticket-bounds-or-fail": {
      input: [
        { name: "lottery-id", type: numberT },
        { name: "owner", type: principalT },
      ],
      output: responseSimpleT(tupleT({ end: numberT, start: numberT })),
      mode: "readonly",
    },
    "get-total-bonus-in-fixed": {
      input: [{ name: "lottery-id", type: numberT }],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-total-bonus-tickets-registered-or-default": {
      input: [{ name: "lottery-id", type: numberT }],
      output: numberT,
      mode: "readonly",
    },
    "get-total-pot-in-fixed": {
      input: [{ name: "lottery-id", type: numberT }],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-total-tickets-registered-or-default": {
      input: [{ name: "lottery-id", type: numberT }],
      output: numberT,
      mode: "readonly",
    },
    "get-vrf-uint": {
      input: [{ name: "height", type: numberT }],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "is-lottery-round-claimable": {
      input: [
        { name: "lottery-id", type: numberT },
        { name: "round-id", type: numberT },
      ],
      output: responseSimpleT(booleanT),
      mode: "readonly",
    },
    "lcg-next": {
      input: [
        { name: "current", type: numberT },
        { name: "max-step", type: numberT },
      ],
      output: numberT,
      mode: "readonly",
    },
    "mul-down": {
      input: [
        { name: "a", type: numberT },
        { name: "b", type: numberT },
      ],
      output: numberT,
      mode: "readonly",
    },
    "approved-operators": {
      input: principalT,
      output: optionalT(booleanT),
      mode: "mapEntry",
    },
    lottery: {
      input: numberT,
      output: optionalT(
        tupleT({
          "registration-end-height": numberT,
          "registration-start-height": numberT,
          token: principalT,
          "tokens-per-ticket-in-fixed": numberT,
        }),
      ),
      mode: "mapEntry",
    },
    "lottery-round": {
      input: tupleT({ "lottery-id": numberT, "round-id": numberT }),
      output: optionalT(
        tupleT({
          "draw-height": numberT,
          "payout-rate": numberT,
          percent: numberT,
          play: booleanT,
          "total-tickets": numberT,
        }),
      ),
      mode: "mapEntry",
    },
    "start-indexes": {
      input: numberT,
      output: optionalT(numberT),
      mode: "mapEntry",
    },
    "ticket-bounds": {
      input: tupleT({ "lottery-id": numberT, owner: principalT }),
      output: optionalT(tupleT({ end: numberT, start: numberT })),
      mode: "mapEntry",
    },
    "total-bonus-tickets-registered": {
      input: numberT,
      output: optionalT(numberT),
      mode: "mapEntry",
    },
    "total-tickets-registered": {
      input: numberT,
      output: optionalT(numberT),
      mode: "mapEntry",
    },
  },
} as const)
