import { action } from "mobx"
import React, { FC } from "react"
import { Modal } from "../../../components/Modal"
import {
  SelectTokenModalContent,
  TokenCandidate,
} from "../../../components/SelectTokenModalContent/SelectTokenModalContent"
import { Spensor } from "../../../components/Spensor"
import { WiredTransactionStateDialog } from "../../../components/TransactionStateDialog/WiredTransactionStateDialog"
import { usePathGenerator } from "../../../routes/routes"
import { Result } from "../../../utils/Result"
import { suspenseResource } from "../../../utils/SuspenseResource"
import { TokenInfoPresets } from "../../../utils/TokenInfoPresets/TokenInfoPresets"
import { WithdrawConfirmModalContent } from "../components/WithdrawConfirmModalContent"
import { WithdrawModalContent } from "../components/WithdrawModalContent"
import {
  allStxDxAssets,
  StxDxAsset,
} from "../store/OrderbookStore.service/OrderbookStore.service"
import { useOrderbookStore } from "../store/useOrderbookStore"

export const WiredWithdrawDialog: FC = () => {
  const store = useOrderbookStore()
  const onClose = action(() => (store.withdraw.withdrawing = false))
  const onCloseConfirm = action(() => (store.withdraw.confirming = false))
  const { currency } = store
  return (
    <>
      <Modal
        visible={store.withdraw.withdrawing && !store.withdraw.confirming}
        onClose={onClose}
      >
        <Spensor>
          {() => (
            <WithdrawModalContent
              onSelectCurrency={action(
                from =>
                  (store.withdraw.selectingFromToken =
                    TokenInfoPresets.toCurrency(from) as StxDxAsset),
              )}
              onClose={onClose}
              onSubmit={action(() => (store.withdraw.confirming = true))}
              tokens={store.withdraw.tokens.map(token => ({
                token: currency.getTokenInfo$(token.token),
                onPressMax: () =>
                  token.amount.set(
                    store.withdraw.availableBalance$(token.token),
                  ),
                tokenCount: suspenseResource(() => token.amount.get() ?? null),
                isError: suspenseResource(
                  () =>
                    token.amount.read$ >
                    store.withdraw.availableBalance$(token.token),
                ),
                onDelete: store.withdraw.canDelete
                  ? () => store.withdraw.deleteToken(token.token)
                  : undefined,
                onTokenCountChange: newCount =>
                  token.amount.set(newCount ?? undefined),
                tokenBalanceCount: suspenseResource(() =>
                  store.withdraw.availableBalance$(token.token),
                ),
              }))}
              onAdd={
                store.withdraw.canAddMoreToken
                  ? () => store.withdraw.addToken()
                  : undefined
              }
              onClear={() => store.withdraw.reset()}
              error={suspenseResource(() =>
                Result.maybeError(store.withdraw.formData$),
              )}
            />
          )}
        </Spensor>
      </Modal>
      <WiredSelectTokenModal />
      <Modal visible={store.withdraw.confirming} onClose={onCloseConfirm}>
        <Spensor>
          {() => (
            <WithdrawConfirmModalContent
              onCancel={onCloseConfirm}
              onClose={onCloseConfirm}
              onConfirm={async () => {
                const form = Result.maybeValue(store.withdraw.formData$)
                if (form != null) {
                  await store.withdraw.withdraw(form)
                }
              }}
              tokens={store.withdraw.tokens.map(token => ({
                token: store.currency.getTokenInfo$(token.token),
                count: token.amount.read$,
              }))}
            />
          )}
        </Spensor>
      </Modal>
      <WiredTransactionStateDialog store={store.withdraw.txStore} />
    </>
  )
}

const WiredSelectTokenModal: FC = () => {
  const g = usePathGenerator()
  const store = useOrderbookStore()
  const { currency, account } = store

  const existingOnes = store.withdraw.tokens.map(t => t.token)

  const commonTokens = allStxDxAssets.map((c): TokenCandidate => {
    return {
      disabled: existingOnes.includes(c),
      tokenInfo: currency.getTokenInfo$(c),
      balance: suspenseResource(() => account.getBalance$(c)),
    }
  })

  const allTokens = allStxDxAssets.map((c): TokenCandidate => {
    return {
      disabled: existingOnes.includes(c),
      tokenInfo: currency.getTokenInfo$(c),
      balance: suspenseResource(() => account.getBalance$(c)),
    }
  })

  return (
    <Modal
      visible={store.withdraw.selectingFromToken != null}
      onClose={action(() => store.withdraw.selectingFromToken == null)}
    >
      <SelectTokenModalContent
        commonBasesTokens={commonTokens}
        allTokens={allTokens}
        tokenListLink={g.tokenList()}
        onSelect={action(currency => {
          const fromToken = store.withdraw.selectingFromToken
          if (fromToken == null) return
          const pair = store.withdraw.tokens.find(a => a.token === fromToken)
          if (pair == null) return
          pair.token = TokenInfoPresets.toCurrency(currency) as StxDxAsset
          store.withdraw.selectingFromToken = undefined
        })}
        onClose={action(() => (store.withdraw.selectingFromToken = undefined))}
      />
    </Modal>
  )
}
