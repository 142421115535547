import { generatePath, Params } from "react-router-dom"
import { StxDxMarket } from "../../pages/Orderbook/store/OrderbookStore.service/OrderbookStore.service"
import { TokenInfo } from "../../utils/models/TokenInfo"
import { RoutePath } from "./RoutePath"

export type PathGenerator = typeof pathGenerator

export const pathGenerator = {
  index: g(RoutePath.Launchpad),
  faucet: g(RoutePath.Faucet),
  stake: g(RoutePath.Stake),
  swap: g(RoutePath.Swap),
  margin: g(RoutePath.Margin),
  tokenList: g(RoutePath.TokenList),

  poolList: g(RoutePath.Pool),
  poolDetail: g(RoutePath.PoolDetail, (token: TokenInfo) => ({
    token: token.id,
  })),

  farmList: g(RoutePath.Farm),
  farmDetail: g(RoutePath.FarmDetail, (token: { id: string }) => ({
    token: token.id,
  })),
  coFarmDetail: g(RoutePath.CoFarmDetail, (token: TokenInfo) => ({
    token: token.id,
  })),
  notify: g(RoutePath.Notify),
  launchpad: g(RoutePath.Launchpad),
  launchpadDashboard: g(RoutePath.LaunchpadDashboard, (urlSlug: string) => ({
    urlSlug,
  })),
  launchpadDetail: g(RoutePath.LaunchpadDetail, (urlSlug: string) => ({
    urlSlug,
  })),
  launchpadValidateList: g(
    RoutePath.LaunchpadValidateList,
    (urlSlug: string) => ({
      urlSlug,
    }),
  ),
  launchpadLotteryList: g(
    RoutePath.LaunchpadLotteryList,
    (urlSlug: string) => ({
      urlSlug,
    }),
  ),

  lottery: g(RoutePath.Lottery),

  analytics: g(RoutePath.Analytics),
  analyticsDetail: g(RoutePath.AnalyticsDetail, (urlSlug: string) => ({
    urlSlug,
  })),

  lockdropDetail: g(RoutePath.LockdropDetail, (urlSlug: string) => ({
    urlSlug,
  })),

  CRPSimulator: g(RoutePath.CRPSimulator),

  lend: g(RoutePath.Lend),
  lendDetailDeposit: g(
    RoutePath.LendDetailDeposit,
    (token: Pick<TokenInfo, "id">) => ({
      token: token.id,
    }),
  ),
  lendDetailBorrow: g(
    RoutePath.LendDetailBorrow,
    (token: Pick<TokenInfo, "id">) => ({
      token: token.id,
    }),
  ),

  orderbook: g(RoutePath.Orderbook),
  orderbookDetail: g(RoutePath.OrderbookDetail, (market: StxDxMarket) => ({
    market,
  })),

  wrapBridge: g(RoutePath.WrapBridge),
}

function g(pathName: RoutePath): () => string
function g<T = undefined>(
  pathName: RoutePath,
  args: (input: T) => Params,
): (input: T) => string
function g<T = undefined>(
  pathName: RoutePath,
  args?: (input: T) => Params,
): (input: T) => string {
  return (input: T) =>
    generatePath(`/${pathName}`, args == null ? undefined : args(input))
}
