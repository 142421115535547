export class CancelError extends Error {
  constructor(message?: string) {
    super(message)
    this.name = "CancelError"
  }
}

export class DisplayableError extends Error {
  constructor(public message: string, options?: ErrorConstructorOptions) {
    super(message, options)
  }
}

export interface ErrorAlerter {
  show(options: { message: string }): void
}
export const alertError = (
  prefix: string,
  alerter: ErrorAlerter,
): ((err: unknown) => void) => {
  return err => {
    if (err != null) {
      if (err instanceof DisplayableError || "message" in (err as any)) {
        alerter.show({ message: `${prefix}: ${(err as any).message}` })
        return
      }
    }

    alerter.show({ message: prefix })
  }
}
