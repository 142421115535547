import clsx from "clsx"
import { FC, ReactNode } from "react"
import { Card, CardTitle } from "../../../../components/Card"
import { Spensor } from "../../../../components/Spensor"
import { BlockTimeCountdown } from "../../../../components/TimeCountdown/BlockTimeCountdown"
import { Tooltip } from "../../../../components/Tooltip/Tooltip"
import { FCC } from "../../../../utils/reactHelpers/types"
import {
  readResource,
  SuspenseResource,
} from "../../../../utils/SuspenseResource"
import { assertNever } from "../../../../utils/types"
import { LotteryStage, LotteryStageType } from "../types"

export const ActionPanelFrame: FCC<{
  className?: string
  round: SuspenseResource<number>
  lotteryStage: SuspenseResource<LotteryStage>
  currentBlock: SuspenseResource<number>
}> = props => (
  <Card className={clsx("flex flex-col gap-4", props.className)}>
    <div className={"flex flex-wrap gap-2.5 justify-between"}>
      <CardTitle>Current Round</CardTitle>
      <Spensor>
        {() => {
          const currentBlock = readResource(props.currentBlock)
          const status = readResource(props.lotteryStage)
          const round = readResource(props.round)
          if (status.type === LotteryStageType.BuyTicket) {
            return (
              <Countdown
                prefix={`#${round} Draw begins in`}
                currentBlock={currentBlock}
                lotteryStage={status}
              />
            )
          }
          if (status.type === LotteryStageType.Drawing) {
            return (
              <Countdown
                prefix={`#${round} Draw ends in`}
                currentBlock={currentBlock}
                lotteryStage={status}
              />
            )
          }
          if (status.type === LotteryStageType.Finished) {
            return (
              <Countdown
                prefix={`Next round starts in`}
                currentBlock={currentBlock}
                lotteryStage={status}
              />
            )
          }
          assertNever(status.type)
        }}
      </Spensor>
    </div>

    {props.children}
  </Card>
)

const Countdown: FC<{
  className?: string
  prefix: ReactNode
  lotteryStage: LotteryStage
  currentBlock: number
}> = props => (
  <Tooltip
    title={`Start Block: ${props.lotteryStage.currentStageStartBlock} / End Block: ${props.lotteryStage.nextStageStartBlock}`}
  >
    <BlockTimeCountdown
      prefix={props.prefix}
      currentBlock={props.currentBlock}
      targetBlock={props.lotteryStage.nextStageStartBlock}
      time={props.lotteryStage.nextStageStartDate}
    />
  </Tooltip>
)
