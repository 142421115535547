import React, { FC } from "react"
import { useParams } from "react-router-dom"
import { Card } from "../../components/Card"
import { PageStackPage } from "../../components/PageStack/PageStackPage"
import { PrimaryPageTitle } from "../../components/PageStack/PrimaryPageTitle"
import { suspenseResource } from "../../utils/SuspenseResource"
import { LotteryRecordsTable } from "./component/LotteryRecordsTable"
import {
  LaunchPadProvider,
  useLaunchPadContractStore,
} from "./store/useLaunchPadStores"

const WiredValidateListPageContent: FC = () => {
  const store = useLaunchPadContractStore()

  const { validateListViewModule } = store

  return (
    <Card>
      <LotteryRecordsTable
        hideResultColumn={true}
        currentPage={validateListViewModule.currentPage}
        recordCountPerPage={validateListViewModule.recordCountPerPage}
        recordCountTotal={suspenseResource(
          () => validateListViewModule.recordCountTotal,
        )}
        records={suspenseResource(() => validateListViewModule.records)}
        onChangePage={info => validateListViewModule.onChangePage(info)}
      />
    </Card>
  )
}

export const LaunchpadValidateListPage: FC = () => {
  const urlSlug = useParams<"urlSlug">().urlSlug!

  return (
    <LaunchPadProvider urlSlug={urlSlug}>
      <PageStackPage title={<PrimaryPageTitle>Validate List</PrimaryPageTitle>}>
        <WiredValidateListPageContent />
      </PageStackPage>
    </LaunchPadProvider>
  )
}
