import { Currency } from "../../../../../utils/alexjs/Currency"
import { getTransferAmounts } from "../../../../../utils/transferHelpers"
import {
  BaseRowData,
  CurrencyAmount,
  NotifyTransactionType,
  TransformerGroup,
} from "../../../types"

const contracts = ["auto-alex"] as const
const functionName = "claim-and-mint" as const

export interface StakingHarvestAndMintAtAlexRowData extends BaseRowData {
  type: NotifyTransactionType.StakingHarvestAndMintAtAlex
  alexCurrencyAmount: CurrencyAmount
  apowerCurrencyAmount: CurrencyAmount
  atAlexCurrencyAmount: CurrencyAmount
}

export const StakingHarvestAndMintAtAlexTransformerGroup: TransformerGroup<
  typeof contracts,
  typeof functionName,
  StakingHarvestAndMintAtAlexRowData
> = {
  contracts,
  functionName,
  type: NotifyTransactionType.StakingHarvestAndMintAtAlex,
  confirmedTransformer: helpers => {
    const tx = helpers.getTx()
    const transfers = helpers.getTransfers()
    const transferAmounts = getTransferAmounts(transfers, tx.sender_address)

    return {
      alexCurrencyAmount: {
        currency: Currency.ALEX,
        amount: transferAmounts[Currency.ALEX]?.out,
      },
      apowerCurrencyAmount: {
        currency: Currency.APOWER,
        amount: transferAmounts[Currency.APOWER]?.in,
      },
      atAlexCurrencyAmount: {
        currency: Currency.ATALEX,
        amount: transferAmounts[Currency.ATALEX]?.in,
      },
    }
  },
  pendingOrFailedTransformer: () => {
    return {
      alexCurrencyAmount: {
        currency: Currency.ALEX,
      },
      apowerCurrencyAmount: {
        currency: Currency.APOWER,
      },
      atAlexCurrencyAmount: {
        currency: Currency.ATALEX,
      },
    }
  },
}
