// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import termHtml from "markdown-loader!./_/term.md?raw"
import { FC } from "react"
import { GradientFilledButton } from "../button/variants/GradientFilledButton/GradientFilledButton"
import { CardInset } from "../Card"
import { CardModalContent } from "../CardModal/CardModal"
import styles from "./DisclaimerModalContent.module.scss"

export interface DisclaimerModalContentProps {
  onAccept?: () => void
}

export const DisclaimerModalContent: FC<
  DisclaimerModalContentProps
> = props => {
  return (
    <CardModalContent
      layoutClassName={"flex flex-col"}
      gapClassName={"gap-6"}
      title={"Disclaimer"}
      width={640}
      height={520}
    >
      <CardInset
        className={
          "p-4 flex-1 text-xs leading-4 font-normal text-gray-400 overflow-auto"
        }
      >
        <div
          className={styles.term}
          dangerouslySetInnerHTML={{ __html: termHtml }}
        />
      </CardInset>

      <GradientFilledButton onClick={props.onAccept}>
        Accept
      </GradientFilledButton>
    </CardModalContent>
  )
}
