import { FC } from "react"
import bgImgSrc from "./_/bg.svg"

export const ConnectorBg: FC<{ width: number; height: number }> = props => (
  <img
    width={props.width}
    height={props.height}
    src={bgImgSrc}
    alt="Connector Background"
  />
)
