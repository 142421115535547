import clsx from "clsx"
import { action } from "mobx"
import React, { FC, Suspense, useRef } from "react"
import { Helmet } from "react-helmet-async"
import { LoadingIndicator } from "../../../components/LoadingIndicator/LoadingIndicator"
import { Modal } from "../../../components/Modal"
import { NavLink } from "../../../components/NavLink"
import { PageStackPage } from "../../../components/PageStack/PageStackPage"
import { PrimaryPageTitle } from "../../../components/PageStack/PrimaryPageTitle"
import type { TabBarRefValue } from "../../../components/TabBar"
import { TopNavBarHeight } from "../../../components/TopNavBar/TopNavBar"
import { useCurrencyStore } from "../../../stores/currencyStore/useCurrencyStore"
import { Currency } from "../../../utils/alexjs/Currency"
import { suspenseResource } from "../../../utils/SuspenseResource"
import WiredLoadingOrConnectWallet from "../../Auth/components/WiredLoadingOrConnectWallet"
import { ActionPanelFrame } from "../component/ActionPanel/ActionPanelFrame"
import { HistoryTabContent } from "../component/HistoryTabContent/HistoryTabContent"
import { MyLotteryPanelFrame } from "../component/MyLotteryPanel/MyLotteryPanelFrame"
import { RulesTabContent } from "../component/RulesTab/RulesTabContent"
import { TabBar } from "../component/TabBar"
import { WinnerListModalContent } from "../component/WinnerListModalContent/WinnerListModalContent"
import { WiredActionPanelContent } from "../LotteryPage/WiredActionPanelContent"
import {
  LotteryPageStoreProvider,
  useLotteryPageStore,
} from "../store/useLotteryPageStore"
import styles from "./LotteryPage.module.scss"
import {
  WiredMyLotteryHistory,
  WiredMyLotteryPanel,
} from "./WiredMyLotteryPanel"
import { WiredPrizePoolPanel } from "./WiredPrizePoolPanel"

const LotteryPageContent: FC = () => {
  const tabbarRef = useRef<TabBarRefValue<"history" | "rule">>(null)
  const store = useLotteryPageStore()
  const currency = useCurrencyStore()

  return (
    <PageStackPage
      title={
        <>
          <PrimaryPageTitle>Lottery</PrimaryPageTitle>

          <NavLink
            className={"ml-auto text-blue-600"}
            to={"https://medium.com/alexgobtc/the-alex-lottery-60f217222e7"}
          >
            How does the lottery work?
          </NavLink>
        </>
      }
    >
      <div className={"grid grid-cols-24 gap-y-8 lg:gap-8 mb-8"}>
        <div
          className={clsx(
            "col-start-1 col-span-24 lg:col-span-9",
            "row-span-1 lg:row-start-1",
            "flex flex-col gap-8",
          )}
        >
          <WiredPrizePoolPanel
            className={clsx()}
            onClickRules={() => {
              if (!tabbarRef.current) return
              tabbarRef.current.selectTab("rule")
              tabbarRef.current.scrollIntoView({
                top: TopNavBarHeight,
              })
            }}
          />

          <Suspense
            fallback={
              <MyLotteryPanelFrame>
                <WiredLoadingOrConnectWallet />
              </MyLotteryPanelFrame>
            }
          >
            <MyLotteryPanelFrame
              onOpenHistory={action(
                () => (store.historical.showMyHistory = true),
              )}
              showHistoryEntry
            >
              <WiredMyLotteryPanel />
              <WiredMyLotteryHistory />
            </MyLotteryPanelFrame>
          </Suspense>
        </div>

        <ActionPanelFrame
          className={clsx(
            "col-start-1 col-span-24 lg:col-start-10 lg:col-span-15",
            "row-span-1 lg:row-start-1 lg:row-span-2",
          )}
          lotteryStage={suspenseResource(() => store.info.status$)}
          currentBlock={suspenseResource(
            () => store.chainStore.currentBlockHeight$,
          )}
          round={suspenseResource(() => store.info.currentLotteryId$)}
        >
          <Suspense fallback={<LoadingIndicator className="mx-auto" />}>
            <WiredActionPanelContent />
          </Suspense>
        </ActionPanelFrame>

        <TabBar
          tabbarRef={tabbarRef}
          className={"col-start-1 col-span-24"}
          tabs={[
            { label: "History", value: "history" as const },
            { label: "Rules & Terms", value: "rule" as const },
          ]}
        >
          {({ value }) => (
            <div className="col-span-24">
              {value === "rule" && (
                <RulesTabContent
                  priceToken={suspenseResource(() =>
                    currency.getTokenInfo$(Currency.ALEX),
                  )}
                  priceTokenCountPerTicket={suspenseResource(
                    () => store.buy.alexPerTicket$,
                  )}
                />
              )}
              {value === "history" && (
                <Suspense fallback={<LoadingIndicator className="mx-auto" />}>
                  <WiredHistoryTabContent />
                </Suspense>
              )}
            </div>
          )}
        </TabBar>
      </div>
    </PageStackPage>
  )
}

const WiredHistoryTabContent: FC = () => {
  const store = useLotteryPageStore()
  const currency = useCurrencyStore()
  const token = currency.getTokenInfo$(Currency.ALEX)

  return (
    <>
      <HistoryTabContent
        rows={store.historical.currentHistories$.map(lotteryId => ({
          token,
          round: lotteryId,
          prizes: store.historical.prizesForLotteryId$(lotteryId),
          participants: suspenseResource(
            () => store.historical.participantCount(lotteryId).value$,
          ),
          burnedAlexCount: suspenseResource(
            () => store.historical.totalPotCount(lotteryId).value$ * 0.2,
          ),
          prizePot: suspenseResource(
            () => store.historical.totalPotCount(lotteryId).value$,
          ),
        }))}
        paginationInfo={{
          currentPage: store.historical.inlineHistoryCurrentPage,
          recordCountPerPage: store.historical.inlineHistoryRecordPerPage,
          recordCountTotal: store.historical.totalLotteryCount$,
          onChange: action(
            ({ page }) => (store.historical.inlineHistoryCurrentPage = page),
          ),
        }}
        onWinnerListClick={action((lotteryId: number) => {
          store.historical.winnerListCurrentLotteryId = lotteryId
          store.historical.showWinnerList = true
        })}
      />
      <Modal visible={store.historical.showWinnerList}>
        <WinnerListModalContent
          lotteryId={store.historical.winnerListCurrentLotteryId}
          winnerList={suspenseResource(
            () => store.historical.currentWinnerList$,
          )}
          tokenInfo={token}
          explorerLinks={suspenseResource(
            () => store.historical.currentClaimExplorerLinks$,
          )}
          onClose={action(() => (store.historical.showWinnerList = false))}
        />
      </Modal>
    </>
  )
}

export const LotteryPage: FC = () => {
  return (
    <LotteryPageStoreProvider>
      <Helmet>
        <body className={styles.html} />
      </Helmet>
      <Suspense>
        <LotteryPageContent />
      </Suspense>
    </LotteryPageStoreProvider>
  )
}
