import clsx from "clsx"
import { FC } from "react"
import {
  Card,
  CardDivider,
  CardInset,
  CardTitle,
} from "../../../../../components/Card"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../../components/InfoList"
import { PercentNumber } from "../../../../../components/PercentNumber"
import { IconTokenCount } from "../../../../../components/RichTokenCount"
import { TipIcon } from "../../../../../components/TipIcon/TipIcon"
import { TokenCountPair } from "../../../../../components/TokenInput/TokenBlock"
import { TokenName } from "../../../../../components/TokenName"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"

export const TransformToWalletCard: FC<{
  className?: string
  transformToToken: TokenInfo
  transformToTokenCount: number
  transformToTokenAPY: number
  originToken: TokenInfo
  harvestableRewards: TokenCountPair[]
}> = props => {
  return (
    <Card className={clsx("flex flex-col gap-3", props.className)}>
      <CardTitle>Sent to your wallet</CardTitle>

      <CardInset>
        <InfoList
          direction={"row"}
          listItemDirection={"column"}
          listItemClassName={"flex-1 items-center gap-2.5"}
        >
          <InfoListItem>
            <InfoListItemDetail>
              <IconTokenCount
                inline={false}
                iconSize={32}
                className={"text-2xl font-medium"}
                tokenNameClassName={"sr-only"}
                token={props.transformToToken}
                count={props.transformToTokenCount}
              />
            </InfoListItemDetail>
            <InfoListItemTitle className={"text-xl font-medium"}>
              <TokenName token={props.transformToToken} />
            </InfoListItemTitle>
          </InfoListItem>
        </InfoList>
      </CardInset>

      <InfoList direction={"column"} listItemDirection={"row-responsive"}>
        <InfoListItem>
          <InfoListItemTitle>Est. APY</InfoListItemTitle>
          <InfoListItemDetail>
            <PercentNumber number={props.transformToTokenAPY} />
          </InfoListItemDetail>
        </InfoListItem>
      </InfoList>

      <CardDivider className={"w-full"} />

      <InfoList direction={"row"} listItemDirection={"column"}>
        <InfoListItem className={"gap-2.5"}>
          <InfoListItemTitle>
            Harvest interest&nbsp;
            <TipIcon
              inline
              tip={
                <>
                  The harvest interests are the{" "}
                  <TokenName token={props.originToken} /> rewards from your
                  previous LP cycles. Co-Farming with{" "}
                  <TokenName token={props.originToken} /> will mint those
                  rewards into <TokenName token={props.transformToToken} />{" "}
                  providing compound interest.
                </>
              }
            />
          </InfoListItemTitle>
          <InfoListItemDetail>
            <ul>
              {props.harvestableRewards.map((r, idx) => (
                <li key={idx}>
                  <IconTokenCount
                    inline={false}
                    iconSize={24}
                    className={"text-xl"}
                    tokenNameClassName={"text-base"}
                    token={r.token}
                    count={r.count}
                  />
                </li>
              ))}
            </ul>
          </InfoListItemDetail>
        </InfoListItem>
      </InfoList>
    </Card>
  )
}
