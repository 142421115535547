import { range } from "lodash"
import { action, runInAction } from "mobx"
import { FC, useCallback } from "react"
import { APowerRatioProvider } from "../../../components/APowerTip/APowerTip"
import {
  CentralizedContentContainer,
  CollapsableCard,
} from "../../../components/CollapsableCard/CollapsableCard"
import { useConnect } from "../../../components/ConnectWallet/ConnectProvider"
import { LoadingIndicator } from "../../../components/LoadingIndicator/LoadingIndicator"
import { Modal } from "../../../components/Modal"
import { Spensor } from "../../../components/Spensor"
import { WiredTransactionStateDialog } from "../../../components/TransactionStateDialog/WiredTransactionStateDialog"
import { useAuthStore } from "../../../stores/authStore/useAuthStore"
import { useChainStore } from "../../../stores/chainStore/useChainStore"
import { useCurrencyStore } from "../../../stores/currencyStore/useCurrencyStore"
import { Currency } from "../../../utils/alexjs/Currency"
import {
  safeReadResource,
  suspenseResource,
} from "../../../utils/SuspenseResource"
import { TokenInfoPresets } from "../../../utils/TokenInfoPresets/TokenInfoPresets"
import { BetterHarvestAndSwap } from "../autoStakeComponents/BetterPricesInSwap/BetterPricesInSwap"
import { WiredAddStakingConfirmation } from "../autoStakeComponents/WiredAddStakingConfirmation"
import WiredBetterPricesInSwap from "../autoStakeComponents/WiredBetterPricesInSwap"
import { WiredEarningsPreviewSection as WiredManualStakingEarningsPreviewSection } from "../components/WiredStakePageContent"
import { ConfirmHarvestModalContent } from "../manualStakeComponents/ConfirmHarvestModalContent/ConfirmHarvestModalContent"
import { AddStakeSection } from "../mixedStakeComponents/AddStakeSection/AddStakeSection"
import { AllCyclesModalContent } from "../mixedStakeComponents/AllCyclesModalContent/AllCyclesModalContent"
import { AutoAlexApowerDistributionContent } from "../mixedStakeComponents/ApowerDistribution/AutoAlexApowerDistributionContent"
import { ConfirmHarvestAndMintAtAlexModalContent } from "../mixedStakeComponents/ConfirmHarvestModalContent/ConfirmHarvestAndMintAtAlexModalContent"
import {
  ChartCurves,
  EarningsPreviewSection,
} from "../mixedStakeComponents/EarningsPreviewSection/EarningsPreviewSection"
import { MyStakingSection } from "../mixedStakeComponents/MyStakingSection/MyStakingSection"
import { useMixedStakingStore } from "../store/mixedStaking/useMixedStakingStore"
import { StakingCopywriting } from "../_/StakingCopywriting"
import { CopywritingProvider } from "./CopywritingProvider"
import { WiredConnectWalletButton } from "./WiredConnectWalletButton"
import { WiredManualAddStakeConfirmation } from "./WiredStakePageContent"

const WiredHarvestAllConfirmation: FC = () => {
  const store = useMixedStakingStore()
  const {
    myStaking,
    tokenInfo$: alexTokenInfo,
    rewardInfos$,
  } = store.manualStore
  const onClose = useCallback(() => {
    runInAction(() => (store.harvestConfirming = undefined))
  }, [store])
  const atAlexTokenInfo = store.autoStore.atAlexTokenInfo
  return (
    <>
      <Modal visible={store.harvestConfirming != null} onClose={onClose}>
        {store.harvestConfirming === "autoMint" ? (
          <ConfirmHarvestAndMintAtAlexModalContent
            alexToken={alexTokenInfo}
            atAlexToken={atAlexTokenInfo}
            harvestTokens={[
              {
                token: alexTokenInfo,
                count: myStaking.pendingReturns + myStaking.pendingReward,
              },
              {
                token: TokenInfoPresets.APower,
                count:
                  myStaking.pendingReward * store.manualStore.apowerMultiplier$,
              },
            ]}
            mintedTokens={[
              {
                token: atAlexTokenInfo,
                count:
                  (myStaking.pendingReturns + myStaking.pendingReward) /
                  store.autoStore.intrinsic.value$,
              },
              {
                token: TokenInfoPresets.APower,
                count:
                  myStaking.pendingReward * store.manualStore.apowerMultiplier$,
              },
            ]}
            intrinsicAlexValue={store.autoStore.intrinsic.value$}
            onConfirm={() => store.harvestAndMintAlex()}
            onClose={onClose}
          />
        ) : (
          <ConfirmHarvestModalContent
            principalToClaim={{
              token: alexTokenInfo,
              count: myStaking.pendingReturns,
              countToUSD: suspenseResource(() => myStaking.pendingReturnsToUSD),
            }}
            rewardToClaim={rewardInfos$}
            onClose={onClose}
            onConfirm={() => {
              runInAction(() => {
                store.harvestConfirming = undefined
              })
              return myStaking.harvest()
            }}
          />
        )}
      </Modal>
      <WiredTransactionStateDialog store={store.harvestTransaction} />
      <WiredTransactionStateDialog store={myStaking.harvestTransaction} />
    </>
  )
}

const WiredMyStakingShowAllCircles: FC = () => {
  const currencyStore = useCurrencyStore()
  const chainStore = useChainStore()
  const store = useMixedStakingStore()

  return (
    <Modal
      visible={store.autoStore.showAllCycles}
      onClose={action(() => (store.autoStore.showAllCycles = false))}
    >
      <AllCyclesModalContent
        alexToken={currencyStore.getTokenInfo$(Currency.ALEX)}
        autoAlexToken={currencyStore.getTokenInfo$(Currency.ATALEX)}
        currentBlock={chainStore.currentBlockHeight$}
        currentCycleEndedAt={store.stakeChain.nextCycleDate$}
        currentCycleStartedAtBlock={store.stakeChain.currentCycleBlock$}
        currentCycleEndedAtBlock={store.stakeChain.nextCycleBlock$}
        cycles={suspenseResource(() =>
          range(
            store.stakeChain.currentCycle$,
            store.stakeChain.currentCycle$ + 32,
          ).map(cycle => store.cycleViewModule(cycle)),
        )}
        isHasMore={false}
        onClose={action(() => (store.autoStore.showAllCycles = false))}
      />
    </Modal>
  )
}

const WiredBetterHarvestAndSwap: FC = () => {
  const store = useMixedStakingStore()
  const onClose = action(() => (store.confirmStakingDiscount = false))
  const percentage = safeReadResource(
    suspenseResource(() => store.autoStore.swapDiscountPercentage$),
  )
  return (
    <Modal visible={!!store.confirmStakingDiscount} onClose={onClose}>
      <Spensor>
        {() => (
          <BetterHarvestAndSwap
            discountPercentage={
              percentage ?? store.autoStore.swapDiscountPercentage$
            }
            onClose={onClose}
            onOnlyHarvest={action(() => {
              store.confirmStakingDiscount = false
              store.harvestConfirming = "harvestOnly"
            })}
            onHarvestAndAutoStake={action(() => {
              store.confirmStakingDiscount = false
              store.harvestConfirming = "autoMint"
            })}
          />
        )}
      </Spensor>
    </Modal>
  )
}

const WiredAutoAlexApowerDistributionModal: FC = () => {
  const currencyStore = useCurrencyStore()
  const store = useMixedStakingStore()

  return (
    <Modal
      visible={store.autoStore.showApowerDistribution}
      onClose={action(() => (store.autoStore.showApowerDistribution = false))}
    >
      <AutoAlexApowerDistributionContent
        cycles={suspenseResource(() => store.autoStore.apowerDistribution$)}
        currentCycleNumber={suspenseResource(
          () => store.stakeChain.currentCycle$,
        )}
        autoAlexToken={suspenseResource(() =>
          currencyStore.getTokenInfo$(Currency.ATALEX),
        )}
        onClose={action(() => (store.autoStore.showApowerDistribution = false))}
      />
    </Modal>
  )
}

const WiredMyStakingSection: FC<{ className?: string }> = props => {
  const chainStore = useChainStore()
  const currencyStore = useCurrencyStore()
  const { isWalletConnected } = useAuthStore()
  const store = useMixedStakingStore()
  return (
    <Spensor
      fallback={
        <CollapsableCard
          title={"My Staking"}
          ContentContainer={CentralizedContentContainer}
        >
          {isWalletConnected ? (
            <LoadingIndicator />
          ) : (
            <WiredConnectWalletButton />
          )}
        </CollapsableCard>
      }
    >
      {() => (
        <>
          <MyStakingSection
            className={props.className}
            defaultCollapsed={!store.hasAnyStaking}
            alexToken={currencyStore.getTokenInfo$(Currency.ALEX)}
            autoAlexToken={currencyStore.getTokenInfo$(Currency.ATALEX)}
            autoAlexTokenCount={store.autoStore.atAlexBalance$}
            autoStakingAPY={store.averageAPY$}
            manualStakingAlexTokenCount={
              store.manualStore.myStaking.activeStaking
            }
            manualStakingAPR={store.averageAPR$}
            harvestableAlexTokenCount={
              store.manualStore.myStaking.pendingReward +
              store.manualStore.myStaking.pendingReturns
            }
            harvestableAPowerCount={
              store.manualStore.myStaking.pendingReward *
              store.manualStore.apowerMultiplier$
            }
            currentBlock={chainStore.currentBlockHeight$}
            currentCycleEndedAt={store.stakeChain.nextCycleDate$}
            currentCycleStartedAtBlock={store.stakeChain.currentCycleBlock$}
            currentCycleEndedAtBlock={store.stakeChain.nextCycleBlock$}
            stakingCycles={range(
              store.stakeChain.currentCycle$,
              store.stakeChain.currentCycle$ + 3,
            ).map(cycle => store.cycleViewModule(cycle))}
            onHarvestAll={autoMint => store.onHarvestClicked(autoMint)}
            onShowAllCycles={action(
              () => (store.autoStore.showAllCycles = true),
            )}
            autoAlexApowerToBeDistributed={
              store.autoStore.apowerToBeDistributed$
            }
            onShowApowerDistribution={action(
              () => (store.autoStore.showApowerDistribution = true),
            )}
          />

          <APowerRatioProvider
            ratio={suspenseResource(() => store.manualStore.apowerMultiplier$)}
          >
            <WiredHarvestAllConfirmation />
            <WiredMyStakingShowAllCircles />
          </APowerRatioProvider>
          <WiredAutoAlexApowerDistributionModal />
          <WiredBetterHarvestAndSwap />
        </>
      )}
    </Spensor>
  )
}

const WiredAddStakingSection: FC<{ className?: string }> = props => {
  const currency = useCurrencyStore()
  const store = useMixedStakingStore()
  const [connect] = useConnect()

  return (
    <>
      <AddStakeSection
        className={props.className}
        alexToken={currency.getTokenInfo$(Currency.ALEX)}
        autoAlexToken={currency.getTokenInfo$(Currency.ATALEX)}
        form={store.form}
        maxCycleCount={
          store.manualStore.addStake.dynamicStakingCycleNumberLimit$
        }
        onSwitchFormMode={info => store.switchInfo(info)}
        onConnectWallet={connect}
        onSubmit={() => store.submit()}
        onClear={() => store.clear()}
        error={suspenseResource(() => store.formError$)}
      />

      <WiredManualAddStakeConfirmation cycleTip="Staking locks up the set amount in the contract for the selected number of reward cycles." />
      <WiredAddStakingConfirmation />
      <WiredBetterPricesInSwap />
    </>
  )
}

const WiredEarningsPreviewSection: FC<{ className?: string }> = props => {
  const currency = useCurrencyStore()
  const store = useMixedStakingStore()

  // const cycles = range(100).map(c => store.shared.nextCycle$ + c)

  const { alex, atAlex } = store.earningPreview.value$
  // (1 + 32-cycle APR / 100) ^ (100 / 2) - 1
  // const lastCycleAPR = last(manualAprs)!
  // const autoAPY = store.cycleViewModule(
  //   store.shared.currentCycle$ + 16,
  // ).autoStakingApy
  const chartCurves: ChartCurves = {
    autoStakeEarningsCurve: atAlex,
    manualStakeEarningsCurve: alex,
  }

  return (
    <EarningsPreviewSection
      className={props.className}
      alexToken={currency.getTokenInfo$(Currency.ALEX)}
      autoAlexToken={currency.getTokenInfo$(Currency.ATALEX)}
      apy={suspenseResource(() => store.averageAPY$)}
      totalStakedCount={suspenseResource(
        () =>
          store.cycleViewModule(store.stakeChain.nextCycle$).manual.totalStaked
            .count,
      )}
      chartCurves={chartCurves}
    />
  )
}

export interface WiredMixedStakePageContentProps {}
export const WiredMixedStakePageContent: FC<
  WiredMixedStakePageContentProps
> = () => {
  const store = useMixedStakingStore()

  return (
    <div className={"grid grid-cols-24 gap-y-8 lg:gap-8 mb-8"}>
      <div className={"col-span-full"}>
        <WiredMyStakingSection />
      </div>

      <WiredAddStakingSection className={"col-span-full lg:col-span-11"} />

      {store.form.mode === "manual" && store.form.maxCycleCount != null ? (
        <CopywritingProvider
          value={new StakingCopywriting(store.manualStore.tokenInfo$)}
        >
          <WiredManualStakingEarningsPreviewSection
            className={"col-span-full lg:col-span-13"}
          />
        </CopywritingProvider>
      ) : (
        <WiredEarningsPreviewSection
          className={"col-span-full lg:col-span-13"}
        />
      )}
    </div>
  )
}
