import clsx from "clsx"
import React, { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { CardDivider } from "../../../components/Card"
import { NavLink } from "../../../components/NavLink"
import { PercentNumber } from "../../../components/PercentNumber"
import { Spensor } from "../../../components/Spensor"
import { TokenCount } from "../../../components/TokenCount"
import {
  BlockInputContainer,
  defaultInputClassNames,
} from "../../../components/TokenInput/BlockInputContainer"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { withClassName } from "../../../utils/reactHelpers/withClassName"
import { readResource, SuspenseResource } from "../../../utils/SuspenseResource"
import { Colors } from "./designTokens"
import { TokenIconStack } from "./TokenIconStack"
import { TokenNameStack } from "./TokenNameStack"
import { CompactCard } from "./wrappedCommonComponents/CompactCard"

export interface TokenPairOption {
  priceToken: TokenInfo
  tradeToken: TokenInfo
  tradeTokenLastPrice: SuspenseResource<number>
  tradeToken24HourChangePercentage: SuspenseResource<number>
  link: string
}
export const isSameTokenPairOption = (
  o1: TokenPairOption,
  o2: TokenPairOption,
): boolean => {
  return (
    TokenInfo.isIdentical(o1.priceToken, o2.priceToken) &&
    TokenInfo.isIdentical(o1.tradeToken, o2.tradeToken)
  )
}

export interface TokenPairSelectorProps {
  className?: string
  width?: number
  query: null | string
  selectedOption: TokenPairOption
  tokenPairOptions: TokenPairOption[]
  onQueryChange?: (newQuery: null | string) => void
}

export const TokenPairSelector: FC<TokenPairSelectorProps> = props => {
  const { $t } = useIntl()

  return (
    <CompactCard
      className={clsx(
        "flex flex-col gap-4",
        "bg-gray-900 backdrop-blur-2xl",
        props.className,
      )}
      boxClassName={"pt-4 pr-3 pb-3 pl-3"}
      style={{ width: props.width ?? 440 }}
    >
      <BlockInputContainer boxClassName={"p-3"}>
        {p => (
          <input
            ref={p.inputRef}
            type="text"
            placeholder={$t(
              defineMessage({
                defaultMessage: "Search token name",
                description: `Orderbook/TokenPairSelector`,
              }),
            )}
            className={defaultInputClassNames()}
            value={props.query ?? ""}
            onChange={e => props.onQueryChange?.(e.target.value)}
          />
        )}
      </BlockInputContainer>

      <div className={"flex flex-col gap-2.5"}>
        <RowContainer className={"text-xs font-normal text-gray-500"}>
          <SymbolCell>
            {$t(
              defineMessage({
                defaultMessage: "Symbol",
                description: `Orderbook/TokenPairSelector/header row/symbol`,
              }),
            )}
          </SymbolCell>
          <LastPriceCell>
            {$t(
              defineMessage({
                defaultMessage: "Last price",
                description: `Orderbook/TokenPairSelector/header row/last price`,
              }),
            )}
          </LastPriceCell>
          <ChangePercentCell>
            {$t(
              defineMessage({
                defaultMessage: "24h%",
                description: `Orderbook/TokenPairSelector/header row/24h%`,
              }),
            )}
          </ChangePercentCell>
        </RowContainer>

        <CardDivider className={"w-full"} />

        <div className={"max-h-[280px] overflow-y-auto"}>
          {props.tokenPairOptions.map((option, idx) => (
            <NavLink to={option.link}>
              <OptionRow
                key={idx}
                option={option}
                disabled={isSameTokenPairOption(props.selectedOption, option)}
              />
            </NavLink>
          ))}
        </div>
      </div>
    </CompactCard>
  )
}

const OptionRow: FC<{
  option: TokenPairOption
  disabled?: boolean
}> = props => {
  const { option } = props

  return (
    <RowContainer
      className={clsx(
        props.disabled ? "opacity-30" : "cursor-pointer hover:bg-white/5",
        "py-2",
        "text-sm text-gray-200",
      )}
    >
      <SymbolCell className={"flex items-center"}>
        <TokenIconStack
          size={24}
          tradeToken={option.tradeToken}
          priceToken={option.priceToken}
        />
        &nbsp;
        <TokenNameStack
          tradeToken={option.tradeToken}
          priceToken={option.priceToken}
        />
      </SymbolCell>

      <LastPriceCell>
        <Spensor fallback={"-"}>
          {() => (
            <TokenCount
              token={option.tradeToken}
              count={readResource(option.tradeTokenLastPrice)}
            />
          )}
        </Spensor>
      </LastPriceCell>

      <ChangePercentCell>
        <Spensor fallback={"-"}>
          {() => {
            const percent = readResource(
              option.tradeToken24HourChangePercentage,
            )

            return (
              <span
                className={clsx(
                  percent > 0
                    ? Colors.increaseTextClassName
                    : percent < 0
                    ? Colors.decreaseTextClassName
                    : "",
                )}
              >
                {percent < 0 ? "-" : ""}
                <PercentNumber number={Math.abs(percent)} />
              </span>
            )
          }}
        </Spensor>
      </ChangePercentCell>
    </RowContainer>
  )
}

const RowContainer = withClassName("flex gap-2.5 items-center", "div")

const SymbolCell = withClassName("flex-[180]", "div")
const LastPriceCell = withClassName("flex-[136] text-right", "div")
const ChangePercentCell = withClassName("flex-[80] text-right", "div")
