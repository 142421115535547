import clsx from "clsx"
import { ComponentType, FC, ReactNode } from "react"
import { Tooltip } from "../Tooltip/Tooltip"
import { QuestionFilledIcon } from "./QuestionFilledIcon"

export interface TipIconProps {
  className?: string

  Icon?: ComponentType<{
    fill?: string
    opacity?: string | number
    width?: string | number
    height?: string | number
  }>

  /**
   * is if show as inline element
   *
   * will add `inline` and `align-text-bottom` class name automatically
   */
  inline?: boolean

  /**
   * pass in `null` to disable tooltip
   */
  tip: null | ReactNode
  tipMaxWidth?: number

  /**
   * @default 16
   */
  size?: number
}

export const TipIcon: FC<TipIconProps> = props => {
  const { size = 16, Icon = QuestionFilledIcon } = props

  const svg = <Icon fill={"#C4C4C4"} opacity={0.4} width={size} height={size} />

  const className = clsx(
    "shrink-0",
    props.className,
    props.inline && "inline-block align-text-bottom",
  )

  if (props.tip === null) {
    return <div className={className}>{svg}</div>
  } else {
    return (
      <Tooltip
        className={className}
        title={props.tip}
        titleMaxWidth={props.tipMaxWidth}
      >
        {svg}
      </Tooltip>
    )
  }
}
