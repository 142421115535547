import { computed, makeObservable } from "mobx"
import { of } from "rxjs"
import AccountStore from "../../../../stores/accountStore/AccountStore"
import { AppEnvStore } from "../../../../stores/appEnvStore/AppEnvStore"
import AuthStore from "../../../../stores/authStore/AuthStore"
import CurrencyStore from "../../../../stores/currencyStore/CurrencyStore"
import { LazyValue } from "../../../../stores/LazyValue/LazyValue"
import { AMMSwapPool } from "../../../../utils/alexjs/AMMSwapPool"
import {
  autoTokenForYTP,
  isYTPToken,
  LiquidityPoolToken,
  liquidityTokenPairs,
} from "../../../../utils/alexjs/currencyHelpers"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { AddLiquidityModule } from "./AddLiquidityModule"
import { MyLiquidityModule } from "./MyLiquidityModule"
import type { LiquidityInfo } from "./PoolDetailStore.services"
import {
  getAMMPoolLiquidityInfo,
  getPoolLiquidityInfo,
  getYTPPoolInfo,
} from "./PoolDetailStore.services"
import { RemoveLiquidityModule } from "./RemoveLiquidityModule"

class PoolDetailStore {
  constructor(
    readonly poolToken: LiquidityPoolToken,
    readonly appEnv: Pick<AppEnvStore, "config$">,
    readonly authStore: Pick<AuthStore, "stxAddress$" | "currentBlockHeight$">,
    readonly accountStore: Pick<AccountStore, "getBalance$">,
    readonly currencyStore: Pick<
      CurrencyStore,
      | "getPrice$"
      | "fetchPoolBreakdown$"
      | "getTokenInfo$"
      | "ammPoolIdFromCurrency"
    >,
  ) {
    makeObservable(this)
  }

  get poolTokenInfo(): TokenInfo {
    return this.currencyStore.getTokenInfo$(this.poolToken)
  }

  private info = new LazyValue(
    () =>
      [
        this.poolToken,
        this.currencyStore.getPrice$(liquidityTokenPairs(this.poolToken)[0]),
        this.authStore.currentBlockHeight$,
      ] as const,
    ([token, tokenXPrice]) => {
      return getPoolLiquidityInfo(token, tokenXPrice)
    },
  )

  private ytpInfo = new LazyValue(
    () => this.poolToken,
    ytpToken => getYTPPoolInfo(ytpToken),
  )

  @computed get ammPoolId$(): number | null {
    if (!AMMSwapPool.isPoolToken(this.poolToken)) {
      return null
    }
    return this.currencyStore.ammPoolIdFromCurrency(this.poolToken)
  }

  private ammInfo = new LazyValue(
    () =>
      [
        this.ammPoolId$,
        this.currencyStore.getPrice$(liquidityTokenPairs(this.poolToken)[0]),
      ] as const,
    ([poolId, tokenXPrice]) => {
      if (poolId == null) {
        return of(null)
      }
      return getAMMPoolLiquidityInfo(poolId, tokenXPrice)
    },
  )

  @computed get info$(): LiquidityInfo {
    if (this.ytpInfo.value$ != null) {
      const { tokenXBalance, tokenYBalance } = this.ytpInfo.value$
      const [tokenX, tokenY] = liquidityTokenPairs(this.poolToken)
      const tokenXInUSD = tokenXBalance * this.currencyStore.getPrice$(tokenX)
      const tokenYInUSD = tokenYBalance * this.currencyStore.getPrice$(tokenY)
      return {
        ...this.info.value$,
        tokenXPercentage: tokenXInUSD / (tokenXInUSD + tokenYInUSD),
        tokenYPercentage: tokenYInUSD / (tokenXInUSD + tokenYInUSD),
      }
    }
    if (this.ammInfo.value$ != null) {
      return this.ammInfo.value$
    }
    return this.info.value$
  }

  myLiquidity = new MyLiquidityModule(this)
  addLiquidity = new AddLiquidityModule(this)
  removeLiquidity = new RemoveLiquidityModule(this)

  @computed get pooledAmount$(): number {
    if (isYTPToken(this.poolToken)) {
      return this.accountStore.getBalance$(autoTokenForYTP(this.poolToken))
    }
    return this.accountStore.getBalance$(this.poolToken)
  }
}

export default PoolDetailStore
