import { memoize, uniq } from "lodash"
import { computed, makeObservable } from "mobx"
import { computedFn } from "mobx-utils"
import { CONTRACT_DEPLOYER } from "../../../../config"
import { asSender } from "../../../../generated/smartContractHelpers/asSender"
import { LazyValue } from "../../../../stores/LazyValue/LazyValue"
import { MemoValue } from "../../../../stores/MemoValue"
import { OrderbookStore } from "../OrderbookStore"
import {
  allStxDxMarkets,
  fetchPriceFor,
  fetchPriceTickerFor,
  StxDxAsset,
  StxDxMarket,
  stxDxTokenPairs,
} from "../OrderbookStore.service/OrderbookStore.service"
import type { MarketSummary } from "./OrderbookInfoModule.service"
import { fetchMarketSummary } from "./OrderbookInfoModule.service"

export class OrderbookInfoModule {
  constructor(readonly store: OrderbookStore) {
    makeObservable(this)
  }

  @computed get allSupportedCurrencies(): StxDxAsset[] {
    return uniq([
      ...allStxDxMarkets.map(market => stxDxTokenPairs(market).tradeToken),
      ...allStxDxMarkets.map(market => stxDxTokenPairs(market).priceToken),
    ])
  }

  #tokenPairs = memoize(
    (currency: StxDxAsset) =>
      new LazyValue(
        () => currency,
        async currency =>
          asSender(CONTRACT_DEPLOYER)
            .contract("stxdx-registry")
            .func("get-asset-id")
            .call({
              asset: currency,
            }),
      ),
  )

  currencyAssetId$ = computedFn((currency: StxDxAsset) => {
    const assetId = this.#tokenPairs(currency).value$
    if (assetId == null) {
      throw new Error(`Currency ${currency} is not registered`)
    }
    return assetId
  })

  @computed get allAssetIdMaps(): { [assetId: number]: StxDxAsset } {
    const result: { [assetId: number]: StxDxAsset } = {}
    for (const currency of this.allSupportedCurrencies) {
      const id = this.#tokenPairs(currency).value$
      if (id != null) {
        result[id] = currency
      }
    }
    return result
  }

  currentPriceValue = memoize(
    (market: StxDxMarket) => new LazyValue(() => market, fetchPriceFor),
  )

  marketSummary = memoize(
    (market: StxDxMarket) => new LazyValue(() => market, fetchMarketSummary),
  )

  @computed get currentMarketSummary$(): MarketSummary {
    return this.marketSummary(this.store.market).value$
  }

  @computed get currentPrice$(): number {
    return this.currentPriceValue(this.store.market).value$
  }

  marketOrderTick = memoize(
    (market: StxDxMarket) => new LazyValue(() => market, fetchPriceTickerFor),
  )

  @computed get currentOrderTick$(): { bid: number; ask: number } | null {
    return this.marketOrderTick(this.store.market).value$
  }

  private memoCurrentPrice = new MemoValue(() => this.currentPrice$)
  @computed get currentPriceDeltaPercentage$(): number {
    return (
      (this.memoCurrentPrice.previousValue ?? 0 - this.currentPrice$) /
      this.currentPrice$
    )
  }
}
