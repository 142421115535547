import clsx from "clsx"
import { FC } from "react"
import { NavLink } from "../../../../../../components/NavLink"
import { ReactComponent as ViewInExplorerIcon } from "./viewInExplorer.svg"

export const ExplorerLink: FC<{ className?: string; link: string }> = props => (
  <NavLink
    className={clsx(
      "text-xs leading-4 font-normal text-gray-100",
      props.className,
    )}
    to={props.link}
  >
    <ViewInExplorerIcon
      className={"inline align-baseline mr-[5px] opacity-50"}
    />
    View in explorer
  </NavLink>
)
