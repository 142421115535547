import { FC, useState } from "react"
import { Modal } from "../../../../../../components/Modal"
import { WiredTransactionStateDialog } from "../../../../../../components/TransactionStateDialog/WiredTransactionStateDialog"
import { useAccountStore } from "../../../../../../stores/accountStore/useAccountStore"
import { Currency } from "../../../../../../utils/alexjs/Currency"
import { Result } from "../../../../../../utils/Result"
import {
  readResource,
  suspenseResource,
} from "../../../../../../utils/SuspenseResource"
import { RegisterFormData } from "../../../../store/LaunchPadRegisterModule"
import { useLaunchPadContractStore } from "../../../../store/useLaunchPadStores"
import { SwitchRegisterMultiTimesModalContent } from "../../../SwitchRegisterMultiTimesModalContent"
import { ActionSectionContent$BeforeRegistered } from "../../_/ActionSectionContent$BeforeRegistered"
import { RegisterConfirmModalContent } from "../../_/RegisterConfirmModalContent/RegisterConfirmModalContent"

export const WiredActionSectionContent$BeforeRegistered: FC<{
  onLearnMoreAPowerRule?: () => void
}> = props => {
  const store = useLaunchPadContractStore()
  const accStore = useAccountStore()

  const shouldConfirmSwitchBrowserDialog = store.hasPendingMemPoolTx$

  const [formData, setFormData] = useState<null | RegisterFormData>(null)
  const [multiTimesDialogConfirmed, setMultiTimesDialogConfirmed] =
    useState(false)

  let showRegisterMultiTimesDialog: boolean
  let showConfirmRegisterDialog: boolean
  if (shouldConfirmSwitchBrowserDialog) {
    showRegisterMultiTimesDialog = !multiTimesDialogConfirmed && !!formData
    showConfirmRegisterDialog = multiTimesDialogConfirmed && !!formData
  } else {
    showRegisterMultiTimesDialog = false
    showConfirmRegisterDialog = !!formData
  }

  return (
    <>
      <ActionSectionContent$BeforeRegistered
        formError={suspenseResource(() =>
          Result.maybeError(store.register.formData),
        )}
        lotteryTicketCount={store.register.ticketToRegister}
        onLotteryTicketCountChange={c =>
          store.register.setTicketToRegister(c ?? 0)
        }
        onPressMaxLotteryTicketButton={suspenseResource(() => {
          const maxAllowedTicket = readResource(
            store.register.maxAllowedTicket$,
          )

          return maxAllowedTicket === store.register.ticketToRegister
            ? undefined
            : () => {
                store.register.setTicketToRegister(maxAllowedTicket)
              }
        })}
        aPowerBalance={suspenseResource(() =>
          accStore.getBalance$(Currency.APOWER),
        )}
        aPowerPerTicket={store.tokenProfileViewModule.aPowerCountPerTicket}
        willLockAPowerCount={store.register.willLockedAPowerCount$}
        priceToken={store.priceTokenInfo$}
        priceTokenBalance={suspenseResource(() =>
          accStore.getBalance$(store.priceToken$),
        )}
        pricePerTicket={store.tokenProfileViewModule.pricePerTicket}
        willLockPriceTokenCount={store.register.willLockedPriceTokenCount$}
        onPressRegister={suspenseResource(() => {
          const { formData } = store.register
          return formData.type !== "ok"
            ? undefined
            : () => {
                setMultiTimesDialogConfirmed(false)
                setFormData(formData.payload)
              }
        })}
        onLearnMoreAPowerRule={props.onLearnMoreAPowerRule}
        aPowerRuleRanges={suspenseResource(() => store.apowerRanges$)}
      />

      <Modal
        visible={showRegisterMultiTimesDialog}
        onClose={() => setMultiTimesDialogConfirmed(true)}
      >
        <SwitchRegisterMultiTimesModalContent
          onConfirm={() => setMultiTimesDialogConfirmed(true)}
        />
      </Modal>
      <Modal
        visible={showConfirmRegisterDialog}
        onClose={() => setFormData(null)}
      >
        <RegisterConfirmModalContent
          lotteryTicketCount={store.register.ticketToRegister}
          priceToken={store.priceTokenInfo$}
          priceTokenCount={store.register.willLockedPriceTokenCount$}
          aPowerCount={store.register.willLockedAPowerCount$}
          onConfirm={() => {
            if (!formData) return
            setFormData(null)
            void store.register.submitForm(formData)
          }}
          onClose={() => setFormData(null)}
        />
      </Modal>
      <WiredTransactionStateDialog store={store.register.addTx} />
    </>
  )
}
