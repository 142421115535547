import { CopyOutlined, DisconnectOutlined } from "@ant-design/icons"
import clsx from "clsx"
import Lottie from "lottie-react"
import { ComponentType, CSSProperties, FC, ReactNode } from "react"
import { btnPresets } from "../../../../../components/button/Button"
import { HeadlessButton } from "../../../../../components/button/HeadlessButton"
import { RoundedButton } from "../../../../../components/button/RoundedButton"
import { GradientBorderButton } from "../../../../../components/button/variants/GradientBorderButton/GradientBorderButton"
import { GradientFilledButton } from "../../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { RedBorderButton } from "../../../../../components/button/variants/RedBorderButton"
import { BlueCard } from "../../../../../components/Card"
import { Dropdown, DropdownItem } from "../../../../../components/Dropdown"
import { useMessage } from "../../../../../components/message/MessageProvider"
import { NavLink } from "../../../../../components/NavLink"
import {
  BalanceBottomArea,
  EstimatedUSD,
} from "../../../../../components/TokenInput/BalanceBottomArea"
import { DefaultTokenNameArea } from "../../../../../components/TokenInput/Block"
import {
  TokenInput,
  TokenInputProps,
} from "../../../../../components/TokenInput/TokenInput"
import { FCC } from "../../../../../utils/reactHelpers/types"
import { SuspenseResource } from "../../../../../utils/SuspenseResource"
import { RectButton } from "../../../../Orderbook/components/wrappedCommonComponents/RectButton"
import connectorBgSrc from "../../assets/connector.svg"
import { ReactComponent as DisconnectIcon } from "../../assets/disconnectIcon.svg"
import { ReactComponent as MetamaskIcon } from "../../assets/metamask.svg"
import { ReactComponent as SwitchButtonIcon } from "../../assets/switchIcon.svg"
import { ReactComponent as ExplorerIcon } from "./assets/explorer.svg"
import animationData from "./assets/white-lightning.json"

export interface ConnectorBgProps {
  width: number
  height: number
}
export type ConnectorBg = ComponentType<ConnectorBgProps>

const Connector: FC<{
  ConnectorBg?: ConnectorBg
  style?: CSSProperties
  onClick: () => void
}> = props => {
  const {
    ConnectorBg = props => (
      <img
        width={props.width}
        height={props.height}
        src={connectorBgSrc}
        alt="Connector bg"
      />
    ),
  } = props

  return (
    <div className="relative self-center hidden md:flex" style={props.style}>
      <ConnectorBg width={152} height={160} />
      <Lottie
        animationData={animationData}
        className="absolute inset-0 rotate-90"
      />
      <HeadlessButton onClick={props.onClick}>
        <SwitchButtonIcon className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
      </HeadlessButton>
    </div>
  )
}

const sideTitleLineHeightUnit = 10
const sideTitleHeight = sideTitleLineHeightUnit * 4
const Side: FCC<{
  className?: string
  title: ReactNode
}> = props => {
  return (
    <div className={props.className}>
      <div
        className={"text-xl font-medium"}
        style={{ lineHeight: `${sideTitleHeight}px` }}
      >
        {props.title}
      </div>

      <div
        className={clsx(
          "flex flex-col p-4 bg-black bg-opacity-20 min-h-[200px] rounded-xl",
        )}
      >
        {props.children}
      </div>
    </div>
  )
}

export interface WrapBridgePanelFrameProps {
  fromTitle: ReactNode
  ToTitle: ReactNode
  fromForm: ReactNode
  toForm: ReactNode
  ConnectorBg?: ConnectorBg
  backgroundImage?: string
  onSwitchClicked: () => void
}

export const WrapBridgePanelFrame: FCC<WrapBridgePanelFrameProps> = props => {
  return (
    <BlueCard
      className={"flex flex-col gap-8"}
      backgroundImage={props.backgroundImage}
    >
      <div className="flex items-stretch flex-col md:flex-row md:gap-0 gap-12">
        <Side className={"flex-1"} title={<>From {props.fromTitle}:</>}>
          {props.fromForm}
        </Side>
        <Connector
          style={{ marginTop: sideTitleHeight }}
          ConnectorBg={props.ConnectorBg}
          onClick={props.onSwitchClicked}
        />
        <Side className={"flex-1"} title={<>To {props.ToTitle}:</>}>
          {props.toForm}
        </Side>
      </div>
      {props.children}
    </BlueCard>
  )
}

export const SideFrame: FCC<{
  network: ReactNode
  address: ReactNode
}> = props => {
  return (
    <div className="flex flex-col gap-4 flex-1">
      <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-2">
        {props.network}
        {props.address}
      </div>
      {props.children}
    </div>
  )
}

export const FromInput: FC<
  Pick<
    TokenInputProps,
    "token" | "value" | "onChange" | "error" | "disabled"
  > & {
    tokenNameArea?: ReactNode
    balance: SuspenseResource<number>
    usdCount: SuspenseResource<number>
    onPressMax?: SuspenseResource<undefined | (() => void)>
  }
> = ({
  token,
  value,
  onChange,
  error,
  disabled,
  balance,
  usdCount,
  onPressMax,
  tokenNameArea,
}) => (
  <TokenInput
    className="flex-1"
    token={token}
    tokenNameArea={tokenNameArea}
    value={value}
    onChange={onChange}
    error={error}
    disabled={disabled}
    bottomArea={
      <BalanceBottomArea
        token={token}
        balance={balance}
        rightSide={<EstimatedUSD usdCount={usdCount} />}
        onPressMax={onPressMax}
      />
    }
  />
)

export const ToInput: FC<
  Pick<TokenInputProps, "token" | "value"> & {
    usdCount: SuspenseResource<number>
    tokenNameArea?: ReactNode
  }
> = ({ token, value, tokenNameArea, usdCount }) => (
  <TokenInput
    readonly={true}
    className="flex-1"
    token={token}
    tokenNameArea={
      tokenNameArea ?? <DefaultTokenNameArea token={token} className={"h-12"} />
    }
    value={value}
    bottomArea={
      <BalanceBottomArea
        token={token}
        rightSide={<EstimatedUSD usdCount={usdCount} />}
      />
    }
  />
)

export const ConnectMetaMask: FC<{
  disabled?: boolean
  className?: string
  onClick?: () => void
}> = props => (
  <RectButton
    disabled={props.disabled}
    Variant={GradientFilledButton}
    className="flex flex-row gap-2 h-9 items-center"
    onClick={props.onClick}
    textClassName="text-sm leading-5 font-semibold"
    {...btnPresets.small}
  >
    <MetamaskIcon />
    Connect Metamask
  </RectButton>
)

export const ConnectWallet: FC<{
  className?: string
  onClick?: () => void
}> = props => (
  <RectButton
    Variant={GradientFilledButton}
    className="flex flex-row gap-2 h-9 items-center"
    onClick={props.onClick}
    textClassName="text-sm leading-5 font-semibold"
    {...btnPresets.small}
  >
    Connect Wallet
  </RectButton>
)

export const AddressConnected: FC<{
  error?: boolean
  disabled?: boolean
  address: string
  addressExplorerLink?: string
  className?: string
  onDisconnect?: () => void
}> = props => {
  const message = useMessage()

  return (
    <Dropdown
      trigger={
        <RoundedButton
          Variant={props.error ? RedBorderButton : GradientBorderButton}
          disabled={props.disabled}
          roundedClassName="rounded-lg"
          className={"flex flex-row gap-2 items-center h-9"}
          textClassName="text-sm leading-5 font-semibold"
          {...btnPresets.small}
        >
          {props.address.slice(0, 4)}...{props.address.slice(-4)}
          <DisconnectIcon />
        </RoundedButton>
      }
    >
      <DropdownItem
        icon={<CopyOutlined />}
        onClick={() => {
          void navigator.clipboard.writeText(props.address).then(() =>
            message.show({
              key: "Account address copied!",
              message: `Account address copied!`,
            }),
          )
        }}
      >
        <span>Copy address</span>
      </DropdownItem>
      {props.addressExplorerLink != null && (
        <NavLink to={props.addressExplorerLink}>
          <DropdownItem icon={<ExplorerIcon className={"fill-current"} />}>
            <span>View on Explorer</span>
          </DropdownItem>
        </NavLink>
      )}
      <DropdownItem icon={<DisconnectOutlined />} onClick={props.onDisconnect}>
        <span>Disconnect</span>
      </DropdownItem>
    </Dropdown>
  )
}
