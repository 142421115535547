import * as Sentry from "@sentry/react"
import { FinishedTxData, openContractCall } from "@stacks/connect"
import {
  AnchorMode,
  callReadOnlyFunction,
  ClarityValue,
  PostCondition,
  PostConditionMode,
} from "@stacks/transactions"
import {
  FunctionDescriptor,
  OpenCallFunctionDescriptor,
  ParameterObjOfDescriptor,
  ReadonlyFunctionDescriptor,
  ReturnTypeOfDescriptor,
} from "clarity-codegen"
import {
  CONTRACT_DEPLOYER,
  DISABLE_POST_CONDITION,
  STACK_APP_DETAILS,
  STACK_NETWORK,
} from "../../config"
import { CancelError } from "../../utils/error"
import { StringOnly } from "../../utils/types"
import { AlexContracts } from "../smartContract/contracts_Alex"

export type Contracts = typeof AlexContracts
export type ContractName = keyof Contracts

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const asSender = (senderAddress: string, _tip?: string) => ({
  contract: <T extends keyof Contracts>(contract: T) => ({
    func: <F extends keyof Contracts[T]>(functionName: StringOnly<F>) => ({
      call: async <Descriptor extends Contracts[T][F]>(
        args: ParameterObjOfDescriptor<Descriptor>,
        postConditions?: PostCondition[],
      ): Promise<
        Descriptor extends ReadonlyFunctionDescriptor
          ? ReturnTypeOfDescriptor<Descriptor>
          : Descriptor extends OpenCallFunctionDescriptor
          ? FinishedTxData
          : never
      > => {
        if (senderAddress == null) {
          throw new Error(`senderAddress is not set`)
        }
        const functionDescriptor = AlexContracts[contract][
          functionName
        ] as any as FunctionDescriptor
        if (functionDescriptor.mode === "mapEntry") {
          throw new Error(`Map Entry not supported`)
        }
        const clarityArgs = functionDescriptor.input.map(arg =>
          arg.type.encode(args[arg.name]),
        )
        const sharedOptions = {
          network: STACK_NETWORK as any,
          contractName: contract,
          contractAddress: CONTRACT_DEPLOYER,
          functionName: String(functionName),
          senderAddress,
        } as const
        if (functionDescriptor.mode === "public") {
          console.log(`Calling public ${contract}.${functionName} with: `, args)
          return (await new Promise<FinishedTxData>((resolve, reject) => {
            openContractCall({
              ...sharedOptions,
              ...(postConditions == null || DISABLE_POST_CONDITION
                ? { postConditionMode: PostConditionMode.Allow }
                : {
                    postConditionMode: PostConditionMode.Deny,
                    postConditions,
                  }),
              functionArgs: clarityArgs as (string | ClarityValue)[],
              appDetails: STACK_APP_DETAILS,
              anchorMode: AnchorMode.Any,
              onFinish: e => {
                // note(zhigang1992): this is a hack to get around the
                // fact hiro updated their API accidentally
                // noinspection SuspiciousTypeOfGuard
                resolve({
                  ...e,
                  txId:
                    typeof e.txId === "string" ? e.txId : (e.txId as any).txid,
                })
              },
              onCancel: () => reject(new CancelError("Cancelled")),
            }).catch(e => {
              console.error(e)
              Sentry.captureException(e)
              reject(e)
            })
          })) as any
        } else {
          console.log(
            `Calling readonly ${contract}.${functionName} with: `,
            args,
          )
          const result = await callReadOnlyFunction({
            ...sharedOptions,
            functionArgs: clarityArgs,
          })
          const value = functionDescriptor.output.decode(result)
          console.log(
            `Finished readonly ${contract}.${functionName} with: `,
            value,
          )
          return value
        }
      },
    }),
  }),
})

export function contractAddr(c: keyof Contracts): `${string}.${string}` {
  return `${CONTRACT_DEPLOYER}.${c}`
}

export const currentContractName = (contract: ContractName): string => contract

// @ts-ignore
window._asSender = asSender
