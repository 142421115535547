import { ComponentType, FC } from "react"
import { ButtonVariantProps } from "../../../components/button/ButtonVariant"
import { LoadingIndicator } from "../../../components/LoadingIndicator/LoadingIndicator"
import { Modal } from "../../../components/Modal"
import { Spensor } from "../../../components/Spensor"
import { readResource, suspenseResource } from "../../../utils/SuspenseResource"
import { useWrapBridgeStore } from "../store/useWrapBridgeStore"
import { WiredFromForm } from "./WiredFromForm"
import { WiredToForm } from "./WiredToForm"
import { SubmitButton } from "./WrapBridgePanel/SubmitButton"
import {
  WrapBridgeConfirmation,
  WrapBridgeConfirmationFrame,
} from "./WrapBridgePanel/WrapBridgeConfirmation/WrapBridgeConfirmation"
import {
  ConnectorBg,
  WrapBridgePanelFrame,
} from "./WrapBridgePanel/WrapBridgePanel/WrapBridgePanel"
import { WrapBridgePanelDetails } from "./WrapBridgePanel/WrapBridgePanelDetails/WrapBridgePanelDetails"

const WiredWrapBridgeConfirmation: FC = () => {
  const { wrapForm, wrapFormViewModule } = useWrapBridgeStore()
  const { confirmingFormData } = wrapFormViewModule

  if (confirmingFormData == null) return null

  return (
    <Spensor
      fallback={
        <WrapBridgeConfirmationFrame
          className={"min-h-[400px]"}
          onClose={() => wrapFormViewModule.closeConfirmModal()}
        >
          <LoadingIndicator className={"m-auto"} />
        </WrapBridgeConfirmationFrame>
      }
    >
      {() => (
        <WrapBridgeConfirmation
          {...readResource(confirmingFormData.confirmationData)}
          onConfirm={async () => {
            await wrapForm.execute(confirmingFormData.executionFormData)
            wrapFormViewModule.closeConfirmModal()
          }}
          onClose={() => wrapFormViewModule.closeConfirmModal()}
        />
      )}
    </Spensor>
  )
}

const WiredWrapBridgePanelDetails: FC<{ widthClassName?: string }> = props => {
  const { wrapForm, wrapFormViewModule } = useWrapBridgeStore()

  return (
    <WrapBridgePanelDetails
      className={props.widthClassName}
      wrapCostedMilliseconds={suspenseResource(
        () => wrapForm.wrapCostedMilliseconds$,
      )}
      fees={suspenseResource(() => wrapForm.wrapFeeTokenCount$)}
      feeToken={suspenseResource(() => wrapFormViewModule.wrapFeeToken$)}
      fromToken={suspenseResource(() => wrapFormViewModule.fromToken$)}
      toToken={suspenseResource(() => wrapFormViewModule.toToken$)}
      fromToExchangeRate={suspenseResource(
        () => wrapFormViewModule.unitTokenPrices$.fromToExchangeRate,
      )}
      toFromExchangeRate={suspenseResource(
        () => wrapFormViewModule.unitTokenPrices$.toFromExchangeRate,
      )}
      fromUnitUSD={suspenseResource(
        () => wrapFormViewModule.unitTokenPrices$.fromUnitUSD,
      )}
      toUnitUSD={suspenseResource(
        () => wrapFormViewModule.unitTokenPrices$.toUnitUSD,
      )}
    />
  )
}

export const WiredBridgePanel: FC<{
  ConnectorBg?: ConnectorBg
  SubmitButtonVariant?: ComponentType<ButtonVariantProps>
  backgroundImage?: string
}> = props => {
  const { wrapForm, wrapFormViewModule } = useWrapBridgeStore()

  const [fromTitle, toTitle] =
    wrapForm.direction === "unwrap" ? ["Stacks", "ETH"] : ["ETH", "Stacks"]

  return (
    <WrapBridgePanelFrame
      onSwitchClicked={() => wrapForm.swapWrapDirection()}
      fromTitle={fromTitle}
      ToTitle={toTitle}
      fromForm={<WiredFromForm />}
      toForm={<WiredToForm />}
      ConnectorBg={props.ConnectorBg}
      backgroundImage={props.backgroundImage}
    >
      <WiredWrapBridgePanelDetails widthClassName={"mx-auto w-full max-w-lg"} />

      <SubmitButton
        className={"mx-auto w-full max-w-lg"}
        wrapDirection={wrapForm.direction}
        SubmitButtonVariant={props.SubmitButtonVariant}
        onSubmit={suspenseResource(() =>
          wrapFormViewModule.openConfirmModal$ == null
            ? undefined
            : () => wrapFormViewModule.openConfirmModal$?.(),
        )}
      />

      <Modal
        visible={!!wrapFormViewModule.confirmingFormData}
        onClose={() => wrapFormViewModule.closeConfirmModal()}
      >
        <WiredWrapBridgeConfirmation />
      </Modal>
    </WrapBridgePanelFrame>
  )
}
