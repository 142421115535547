export function trunc(
  str: string,
  {
    longerThan = 8,
    head = 4,
    tail = 3,
  }: { head?: number; tail?: number; longerThan?: number } = {},
): string {
  if (str.length > longerThan) {
    return `${str.substring(0, head)}....${str.substring(str.length - tail)}`
  }
  return str
}

export function contractEquals(a: string, b: string): boolean {
  return a === b || a.split("::")[0] === b.split("::")[0]
}
