import { isEqual } from "lodash"
import { distinctUntilChanged, from, map, Observable, switchMap } from "rxjs"
import { components } from "../../../../generated/stxdx/types"
import { sendRequest } from "../../../../generated/stxdxHelpers/stxdxApi"
import { currencyScale } from "../../../../utils/alexjs/currencyHelpers"
import { assertNever } from "../../../../utils/types"
import {
  OpenOrderRecord,
  OrderDirection,
  StxDxOrderStatus,
  StxDxOrderType,
  TradeHistoryRecord,
  TradesHistoryRecordStatus,
  TriggerCondition,
} from "../../components/types"
import {
  StxDxMarket,
  stxDxMarketPricePrecision,
  stxDxTokenPairs,
} from "../OrderbookStore.service/OrderbookStore.service"
import { refreshOrderHistorySignal } from "./_/orderbookHelpers"

export type ServerOrderData = Omit<
  OpenOrderRecord.Common,
  "tradeToken" | "priceToken" | "onCancel"
> & {
  orderType: StxDxOrderType
  market: StxDxMarket
  status: StxDxOrderStatus
  priceTokenCountPerTradeToken: number
  priceTokenTotalCount: number
  priceTokenCountAverage: number
  triggerCondition?: TriggerCondition
}
export type ServerTradeOrderData = Omit<
  TradeHistoryRecord.Limit,
  "tradeToken" | "priceToken" | "onCancel"
> & {
  market: StxDxMarket
}

export function getTradeHistory({
  auth,
  market,
}: {
  auth: string
  market?: StxDxMarket
}): Observable<ServerTradeOrderData[]> {
  return refreshOrderHistorySignal(auth).pipe(
    switchMap(() =>
      from(
        sendRequest(auth)("OrderController_getFills", {
          query: {
            market,
          },
        }),
      ),
    ),
    map(({ data: { fills } }) =>
      fills.map((order): ServerTradeOrderData => {
        const size = Number(order.size)
        const price = Number(order.price)
        return {
          id: order.id.toString(),
          market: order.market as unknown as StxDxMarket,
          status: orderFillDTOStatusEnumToTradesStatus(order.status),
          orderType: StxDxOrderType.Limit,
          createdAt: new Date(Number(order.created_at)),
          executedTradeTokenCount: size,
          feePriceTokenCount: Number(order.sender_fee) / 1e8,
          orderDirection: order.side,
          priceTokenTotalCount: price * size,
          priceTokenCountPerTradeToken: price,
        }
      }),
    ),
    distinctUntilChanged(isEqual),
  )
}

function orderFillDTOStatusEnumToTradesStatus(
  input: components["schemas"]["OrderFillDTO"]["status"],
): TradesHistoryRecordStatus {
  switch (input) {
    case "settled":
      return TradesHistoryRecordStatus.Succeed
    case "failed":
      return TradesHistoryRecordStatus.Failed
    case "assigned":
    case "fatal":
    case "generated":
    case "matched":
    case "pending":
    case "submitted":
      return TradesHistoryRecordStatus.Settling
    default:
      assertNever(input)
  }
}

export function getOrderHistory({
  auth,
  side,
  market,
  status,
}: {
  auth: string
  market?: StxDxMarket
  side?: OrderDirection
  status?: StxDxOrderStatus
}): Observable<ServerOrderData[]> {
  return refreshOrderHistorySignal(auth).pipe(
    switchMap(() =>
      from(
        sendRequest(auth)("OrderController_getOrders", {
          query: {
            market,
            side,
            limit: 40,
            status,
          },
        }),
      ),
    ),
    map(({ data: { orders } }) => {
      return orders.map((order): ServerOrderData => {
        const market = order.market as unknown as StxDxMarket
        const { priceToken } = stxDxTokenPairs(market)

        const size = Number(order.size)
        let price = Number(order.avg_settled_price) / currencyScale(priceToken)
        if (isNaN(price) || price === 0) {
          price = Number(order.price) / currencyScale(priceToken)
        }
        const stopPrice = Number(order.stop_price) / currencyScale(priceToken)
        const isStopLimit = !isNaN(stopPrice) && stopPrice !== 0
        return {
          orderHash: order.order_hash,
          market,
          status: order.status as unknown as StxDxOrderStatus,
          orderType:
            order.type === "vanilla"
              ? isStopLimit
                ? StxDxOrderType.StopLimit
                : StxDxOrderType.Limit
              : StxDxOrderType.Market,
          createdAt: new Date(Number(order.created_at)),
          expectedTradeTokenCount: size,
          filledPercentage: Number(order.filled) / Number(order.maximum_fill),
          filledPriceTokenCount:
            (price * Number(order.filled)) /
            Math.pow(10, 8 - stxDxMarketPricePrecision[market]),
          orderDirection: order.side,
          priceTokenTotalCount: price * size,
          priceTokenCountPerTradeToken: price,
          priceTokenCountAverage: price,
          triggerCondition: isStopLimit
            ? {
                priceTokenCountPerTradeToken: stopPrice,
                type:
                  order.side === "sell"
                    ? order.risk
                      ? "lte"
                      : "gte"
                    : order.risk
                    ? "gte"
                    : "lte",
              }
            : undefined,
        }
      })
    }),
    distinctUntilChanged(isEqual),
  )
}

export type ServerFundsHistoryRow = Pick<
  components["schemas"]["GetFundsResponse"]["history"][number],
  "type" | "status"
> & {
  amount: number
  assetId: number
  txId: string
  createdAt: Date
}

export async function getMyFundsHistory({
  uid,
  auth,
}: {
  uid: number
  auth: string
}): Promise<ServerFundsHistoryRow[]> {
  const {
    data: { history },
  } = await sendRequest(auth)("AccountController_getFundHistory", {
    path: {
      uid,
    },
    query: {
      limit: 40,
    },
  })

  return history.map(found => {
    const type = found.type
    const amount = Number(found.amount) / 1e8
    const status = found.status
    const txId = found.tx_id
    const assetId = Number(found.asset_id)
    const createdAt = new Date(Number(found.created_at) * 1000)
    return {
      type,
      amount,
      status,
      txId,
      assetId,
      createdAt,
    }
  })
}
