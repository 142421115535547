import { FC } from "react"
import { GradientFilledButton } from "../../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { CardInset, CardInsetTitle } from "../../../../../components/Card"
import { ReactComponent as ConnectWalletIcon } from "./connectWalletImage.svg"
import { MainButton } from "./MainButton"

export const ActionSectionContent$ConnectWallet: FC<{
  onConnect?: () => void
}> = props => {
  return (
    <>
      <CardInset className={"flex flex-col items-center gap-6"}>
        <CardInsetTitle>Connect wallet to join IDO</CardInsetTitle>

        <ConnectWalletIcon />
      </CardInset>

      <MainButton Variant={GradientFilledButton} onClick={props.onConnect}>
        Connect Wallet
      </MainButton>
    </>
  )
}
