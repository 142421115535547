import { noop } from "lodash"
import { action, computed, makeObservable, observable, reaction } from "mobx"
import AccountStore from "../../../stores/accountStore/AccountStore"
import AuthStore from "../../../stores/authStore/AuthStore"
import { ETHChain } from "../../../stores/authStore/MetaMaskModule.service"
import { ConfirmTransactionStore } from "../../../stores/confirmTransactionDialogStore/ConfirmTransactionStore"
import { ConfirmTransactionStoreForGeneral } from "../../../stores/confirmTransactionDialogStore/ConfirmTransactionStoreForGeneral"
import { LazyValue } from "../../../stores/LazyValue/LazyValue"
import { SuspenseObservable } from "../../../stores/SuspenseObservable"
import { Currency } from "../../../utils/alexjs/Currency"
import { asyncAction, runAsyncAction } from "../../../utils/asyncAction"
import { DisplayableError } from "../../../utils/error"
import { assertNever, OneOrMore, Tail } from "../../../utils/types"
import { waitFor } from "../../../utils/waitFor"
import { isWalletAddressInWhitelist } from "../../Orderbook/store/modules/OrderbookMyInfoModule.service"
import type { ETHBridgeChain } from "../types/BridgeChain"
import {
  BridgeChain,
  contractAssignedChainIdFromBridgeChain,
  ethBridgeChains,
  getETHChainExplorerTxLink,
  isETHBridgeChain,
  isUnwrappableETHBridgeChain,
  isWrappableETHBridgeChain,
  UnwrappableETHBridgeChain,
  WrappableETHBridgeChain,
} from "../types/BridgeChain"
import type { BridgeCurrency } from "./utils/BridgeCurrency"
import {
  getCorrespondingBridgeCurrency,
  isUnwrappableBridgeCurrency,
  isWrappableBridgeCurrency,
  unwrappableBridgeCurrencies,
  UnwrappableBridgeCurrency,
  wrappableBridgeCurrencies,
  WrappableBridgeCurrency,
} from "./utils/BridgeCurrency"
import { ETHCurrency, isETHCurrency } from "./utils/ETHCurrency"
import {
  createTransferProphet,
  unwrapStacksToken,
  wrapStacksToken,
} from "./WrapFormModule.service"

export type FormData = FormData.WrappingFormData | FormData.UnwrappingFormData
export namespace FormData {
  interface Common {
    fromAddress: string
    fromAmount: number

    toAddress: string

    fee: number
  }

  export interface WrappingFormData extends Common {
    fromNetwork: WrappableETHBridgeChain
    fromToken: WrappableBridgeCurrency

    toNetwork: BridgeChain.Stacks
    toToken: UnwrappableBridgeCurrency
  }

  export interface UnwrappingFormData extends Common {
    fromNetwork: BridgeChain.Stacks
    fromToken: UnwrappableBridgeCurrency

    toNetwork: UnwrappableETHBridgeChain
    toToken: WrappableBridgeCurrency
  }
}

export class WrapFormModule {
  private disposableBag: (() => void)[] = []

  constructor(
    private readonly accountStore: Pick<AccountStore, "getBalance$">,
    private readonly authStore: Pick<
      AuthStore,
      "metaMaskModule" | "stxAddress$"
    >,
  ) {
    makeObservable(this)

    this.disposableBag.push(
      reaction(
        () => this.authStore.metaMaskModule.connectedChain.value$,
        chain => {
          this.onETHChainUpdated(chain.chain)
        },
        { fireImmediately: true, onError: noop },
      ),
    )
  }

  destroy(): void {
    this.disposableBag.forEach(f => f())
  }

  isWalletInWhitelist = new LazyValue(
    () => this.authStore.stxAddress$,
    address => isWalletAddressInWhitelist(address),
  )

  private transferProphet = new LazyValue(
    () =>
      [
        this.fromChain,
        this.toChain,
        this.fromChainCurrency,
        this.toChainCurrency,
        this.fromTokenCount.read$,
      ] as const,
    args => createTransferProphet(...args),
  )

  @observable direction: "wrap" | "unwrap" = "wrap"
  @action swapWrapDirection(): void {
    this.direction = this.direction === "wrap" ? "unwrap" : "wrap"
  }

  @observable private ethChain: ETHBridgeChain | BridgeChain.Unknown =
    ethBridgeChains[0]
  @action onETHChainUpdated(chain: ETHChain): void {
    let result: undefined | BridgeChain
    switch (chain) {
      case ETHChain.Ethereum:
        result = BridgeChain.Ethereum
        break
      case ETHChain.Goerli:
        result = BridgeChain.Goerli
        break
    }
    if (result && isETHBridgeChain(result)) {
      this.ethChain = result
    } else {
      this.ethChain = BridgeChain.Unknown
    }
  }
  @asyncAction async switchToETHChain(
    newChain: ETHBridgeChain,
    run = runAsyncAction,
  ): Promise<void> {
    const switchToChain = await run(
      waitFor(() => this.authStore.metaMaskModule.switchToChain.value$),
    )

    let targetChain: ETHChain
    switch (newChain) {
      case BridgeChain.Ethereum:
        targetChain = ETHChain.Ethereum
        break
      case BridgeChain.Goerli:
        targetChain = ETHChain.Goerli
        break
      default:
        assertNever(newChain)
    }

    await run(switchToChain(targetChain))
  }

  @computed private get chains(): [from: BridgeChain, to: BridgeChain] {
    return this.direction === "wrap"
      ? [this.ethChain, BridgeChain.Stacks]
      : [BridgeChain.Stacks, this.ethChain]
  }
  @computed get fromChain(): BridgeChain {
    return this.chains[0]
  }
  @computed get toChain(): BridgeChain {
    return this.chains[1]
  }

  @observable private ethChainCurrency = ETHCurrency.USDC
  @computed get fromChainCurrencyCandidates(): BridgeCurrency[] {
    if (isETHBridgeChain(this.fromChain)) {
      return wrappableBridgeCurrencies as OneOrMore<BridgeCurrency>
    }

    if (this.fromChain === BridgeChain.Stacks) {
      return unwrappableBridgeCurrencies as OneOrMore<BridgeCurrency>
    }

    if (this.fromChain === BridgeChain.Unknown) {
      return []
    }

    assertNever(this.fromChain)
  }
  @computed private get chainCurrencies(): [
    from: BridgeCurrency,
    to: BridgeCurrency,
  ] {
    const stxCurrency = getCorrespondingBridgeCurrency(this.ethChainCurrency)
    return this.direction === "wrap"
      ? [this.ethChainCurrency, stxCurrency]
      : [stxCurrency, this.ethChainCurrency]
  }
  @computed get fromChainCurrency(): BridgeCurrency {
    return this.chainCurrencies[0]
  }
  @computed get toChainCurrency(): BridgeCurrency {
    return this.chainCurrencies[1]
  }
  @action setFromChainCurrency(newCurrency: BridgeCurrency): void {
    if (this.direction === "wrap") {
      if (isETHCurrency(newCurrency)) {
        this.ethChainCurrency = newCurrency
      }
    } else {
      const ethChainCurrency = getCorrespondingBridgeCurrency(newCurrency)
      if (isETHCurrency(ethChainCurrency)) {
        this.ethChainCurrency = ethChainCurrency
      }
    }
  }
  @computed get fromChainCurrencyBalance$(): number {
    return this.bridgeCurrencyBalance$(this.fromChainCurrency)
  }

  fromTokenCount = new SuspenseObservable<number>()
  @action setFromTokenCount(newCount: null | undefined | number): void {
    this.fromTokenCount.set(newCount ?? undefined)
  }
  @action setMaxFromTokenCount(): void {
    this.setFromTokenCount(this.fromChainCurrencyBalance$)
  }
  @computed get toTokenCount$(): number {
    return this.transferProphet.value$.targetTokenCount
  }

  @computed get wrapFeeCurrency$(): BridgeCurrency {
    return this.transferProphet.value$.wrapFeeToken
  }

  @computed get wrapFeeTokenCount$(): number {
    return this.transferProphet.value$.wrapFeeTokenCount
  }

  @computed get wrapCostedMilliseconds$(): number {
    return this.transferProphet.value$.wrapCostedMilliseconds
  }

  metamaskTransactionStore = new ConfirmTransactionStoreForGeneral()
  stacksTransactionStore = new ConfirmTransactionStore()

  @asyncAction async execute(
    formData: FormData,
    run = runAsyncAction,
  ): Promise<void> {
    if (
      formData.fromNetwork === BridgeChain.Stacks &&
      isUnwrappableETHBridgeChain(formData.toNetwork) &&
      isUnwrappableBridgeCurrency(formData.fromToken)
    ) {
      const unwrapRestArgs: Parameters<typeof unwrapStacksToken> = [
        contractAssignedChainIdFromBridgeChain(formData.toNetwork),
        formData.fromToken,
        formData.fromAddress,
        formData.toAddress,
        formData.fromAmount,
      ]

      await run(
        this.stacksTransactionStore.run(async () => {
          const txId = await unwrapStacksToken(...unwrapRestArgs)
          return { txId }
        }),
      )
      return
    }

    if (
      isWrappableETHBridgeChain(formData.fromNetwork) &&
      formData.toNetwork === BridgeChain.Stacks &&
      isWrappableBridgeCurrency(formData.fromToken)
    ) {
      const { metaMaskModule } = this.authStore

      const wrapRestArgs: Tail<Tail<Parameters<typeof wrapStacksToken>>> = [
        formData.fromToken,
        formData.toAddress,
        formData.fromAmount,
      ]

      await run(
        this.metamaskTransactionStore.run(async () => {
          const [transfer, signTypedData] = await Promise.all([
            waitFor(() => metaMaskModule.transfer.value$),
            waitFor(() => metaMaskModule.signTypedData.value$),
          ])

          const txHash = await wrapStacksToken(
            transfer,
            signTypedData,
            ...wrapRestArgs,
          )

          return {
            explorerLink: getETHChainExplorerTxLink(
              formData.fromNetwork,
              txHash,
            ),
          }
        }),
      )

      return
    }

    throw new DisplayableError(
      `Unsupported wrap/unwrap request: ${formData.fromToken} ${formData.fromNetwork}->${formData.toNetwork}`,
    )
  }

  private bridgeCurrencyBalance$(currency: BridgeCurrency): number {
    switch (currency) {
      case ETHCurrency.USDC:
        return this.authStore.metaMaskModule.usdcBalance.value$
      case Currency.W_XUSD:
        return this.accountStore.getBalance$(currency)
      default:
        assertNever(currency)
    }
  }
}
