import { FC } from "react"
import { CardDivider } from "../../../../components/Card"
import {
  ChartPanelFrame,
  ChartPlaceholder,
  ChartTitle,
  Legend,
  StyledInfoList,
} from "../../../../components/ChartPanel"
import {
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../components/InfoList"
import { NoteParagraph } from "../../../../components/NoteParagraph/NoteParagraph"
import { PercentNumber } from "../../../../components/PercentNumber"
import { Spensor } from "../../../../components/Spensor"
import { TokenCount } from "../../../../components/TokenCount"
import { TokenName } from "../../../../components/TokenName"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import {
  readResource,
  SuspenseResource,
} from "../../../../utils/SuspenseResource"
import {
  CurvePoint,
  EarningsPreviewChart,
  lineStyles,
} from "./_/EarningsPreviewChart"

export type { CurvePoint } from "./_/EarningsPreviewChart"

export type ChartCurves = {
  autoStakeEarningsCurve: SuspenseResource<CurvePoint[]>
  manualStakeEarningsCurve: SuspenseResource<CurvePoint[]>
}

export interface EarningsPreviewSectionProps {
  className?: string

  alexToken: TokenInfo
  autoAlexToken: TokenInfo

  totalStakedCount: SuspenseResource<number>
  apy: SuspenseResource<number>

  chartCurves?: ChartCurves
}

export const EarningsPreviewSection: FC<
  EarningsPreviewSectionProps
> = props => {
  return (
    <ChartPanelFrame
      className={props.className}
      header={
        <div className="flex flex-col gap-6">
          <StyledInfoList listItemClassName={"flex-1"}>
            <InfoListItem>
              <InfoListItemTitle>Total Stake</InfoListItemTitle>
              <InfoListItemDetail>
                <Spensor>
                  {() => (
                    <span>
                      <TokenCount
                        token={props.autoAlexToken}
                        count={readResource(props.totalStakedCount)}
                      />{" "}
                      <TokenName token={props.alexToken} />
                    </span>
                  )}
                </Spensor>
              </InfoListItemDetail>
            </InfoListItem>

            <InfoListItem>
              <InfoListItemTitle>APY</InfoListItemTitle>
              <InfoListItemDetail>
                <Spensor>
                  {() => <PercentNumber number={readResource(props.apy)} />}
                </Spensor>
              </InfoListItemDetail>
            </InfoListItem>
          </StyledInfoList>

          <CardDivider />

          <div>
            <ChartTitle.Primary>Earnings Illustration</ChartTitle.Primary>
            <ChartTitle.Secondary>
              <TokenName token={props.autoAlexToken} /> automatically compounds
              staking rewards
            </ChartTitle.Secondary>
          </div>
        </div>
      }
    >
      <Spensor fallback={<ChartPlaceholder.Loading />}>
        {() =>
          props.chartCurves == null ? (
            <ChartPlaceholder.Unavailable />
          ) : (
            <EarningsPreviewChart
              alexToken={props.alexToken}
              autoAlexToken={props.autoAlexToken}
              autoStakeEarningsCurve={readResource(
                props.chartCurves.autoStakeEarningsCurve,
              )}
              manualStakeEarningsCurve={readResource(
                props.chartCurves.manualStakeEarningsCurve,
              )}
            />
          )
        }
      </Spensor>

      <Legend.Container>
        <Legend.Item>
          <Legend.Line strokeColor={lineStyles.autoStakeEarningsCurve.color} />
          <Legend.Label
            subtitle={"compounded"}
            color={lineStyles.autoStakeEarningsCurve.color}
          >
            Auto Staking Value
          </Legend.Label>
        </Legend.Item>

        <Legend.Item>
          <Legend.Line
            strokeColor={lineStyles.manualStakeEarningsCurve.color}
            strokeStyle={"dashed"}
          />
          <Legend.Label
            subtitle={"non-compounded"}
            color={lineStyles.manualStakeEarningsCurve.color}
          >
            Manual Staking Value
          </Legend.Label>
        </Legend.Item>
      </Legend.Container>

      <NoteParagraph>
        The graph serves to illustrate an example of future yields which assumes
        a fixed avg APR of 200%. These are NOT actual returns.
      </NoteParagraph>
    </ChartPanelFrame>
  )
}
