import clsx from "clsx"
import IMask from "imask/esm/imask"
import "imask/esm/masked/number"
import {
  createElement,
  FC,
  MutableRefObject,
  ReactNode,
  Ref,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react"
import { TokenInfo } from "../../utils/models/TokenInfo"
import { pickDecimalPart } from "../../utils/numberHelpers"
import { useIsFocusing } from "../../utils/reactHelpers/useIsFocusing"
import { useLatestValueRef } from "../../utils/reactHelpers/useLatestValueRef"
import { ImmutableRefObject, useCombinedRef } from "../../utils/refHelpers"
import {
  readResource,
  safeReadResource,
  SuspenseResource,
} from "../../utils/SuspenseResource"
import { Spensor } from "../Spensor"
import { TokenCount } from "../TokenCount"
import { Truncatable } from "../Truncatable"
import {
  BlockProps,
  BlockTokenLine,
  BlockTokenLineProps,
  DefaultTokenNameArea,
} from "./Block"
import {
  BlockInputContainer,
  defaultInputClassNames,
} from "./BlockInputContainer"

export interface TokenInputProps {
  className?: string
  disabled?: boolean
  error?: boolean
  readonly?: boolean
  token: SuspenseResource<TokenInfo>
  value: SuspenseResource<null | number>
  onChange?: (newValue: null | number) => void
  tokenNameArea?: ReactNode
  topArea?: ReactNode
  bottomArea?: ReactNode
  renderBlock?: (props: BlockProps) => JSX.Element
  renderBlockTokenLine?: (props: BlockTokenLineProps) => JSX.Element
}

export const TokenInput: FC<TokenInputProps> = props => {
  const renderBlockTokenLine =
    props.renderBlockTokenLine ??
    (props => createElement(BlockTokenLine, props))

  const precision = TokenInfo.getPrecision(safeReadResource(props.token))

  const inputClassNames = clsx(
    "text-right",
    defaultInputClassNames({ error: props.error }),
  )

  return (
    <BlockInputContainer
      className={props.className}
      disabled={props.disabled}
      error={props.error}
      readonly={props.readonly}
      renderBlock={props.renderBlock}
    >
      {p => (
        <>
          {props.topArea}
          {renderBlockTokenLine({
            tokenNameArea: props.tokenNameArea ?? (
              <DefaultTokenNameArea token={props.token} />
            ),
            tokenCountArea: props.readonly ? (
              <Spensor fallback={<div className={inputClassNames}>-</div>}>
                {() => (
                  <Truncatable>
                    <TokenCount
                      token={readResource(props.token)}
                      count={safeReadResource(props.value) ?? 0}
                    />
                  </Truncatable>
                )}
              </Spensor>
            ) : (
              <TokenCountInput
                className={inputClassNames}
                disabled={props.disabled}
                placeholder={precision > 0 ? "0.0" : "0"}
                token={props.token}
                inputRef={p.inputRef}
                value={safeReadResource(props.value) ?? undefined}
                onChange={props.onChange}
              />
            ),
          })}
          {props.bottomArea}
        </>
      )}
    </BlockInputContainer>
  )
}

export const TokenCountAreaReadonly: FC<{
  className?: string
  token: SuspenseResource<TokenInfo>
  count: SuspenseResource<null | number>
}> = props => {
  return (
    <Spensor fallback="-">
      {() => {
        const count = readResource(props.count)
        return (
          <Truncatable>
            <TokenCount token={readResource(props.token)} count={count ?? 0} />
          </Truncatable>
        )
      }}
    </Spensor>
  )
}

export const TokenCountInput: FC<{
  className?: string
  token: SuspenseResource<TokenInfo>
  disabled?: boolean
  placeholder?: string
  value?: number
  onChange?: (newValue: null | number) => void
  inputRef?: Ref<HTMLInputElement>
}> = props => {
  const token = safeReadResource(props.token)
  const precision = token ? TokenInfo.getPrecision(token) : 4

  const internalInputRef = useRef<HTMLInputElement>(null)
  const isInputFocusing = useIsFocusing(internalInputRef)
  const isInputFocusingRef = useLatestValueRef(isInputFocusing)

  const valueDecimalPart =
    props.value != null ? pickDecimalPart(props.value, precision) : undefined
  const isValuePrecisionExceed =
    valueDecimalPart == null ? false : valueDecimalPart.length > precision

  const iMaskOptions = useMemo(
    () =>
      ({
        mask: Number,
        scale: isValuePrecisionExceed ? 100 : precision,
        signed: false,
        thousandsSeparator: ",",
        padFractionalZeros: false,
        normalizeZeros: true,
        radix: ".",
        mapToRadix: ["."],
      } as IMask.MaskedNumberOptions),
    [isValuePrecisionExceed, precision],
  )
  const propOnChange = props.onChange
  const { ref: iMaskInputRef } = useIMask(props.value, iMaskOptions, {
    onAccept: useCallback(
      (imask: IMask.InputMask<typeof iMaskOptions>) => {
        const isChangedBecauseOfValueSetting = !isInputFocusingRef.current
        if (!isChangedBecauseOfValueSetting) {
          propOnChange?.(imask.value === "" ? null : imask.typedValue)
        }
      },
      [isInputFocusingRef, propOnChange],
    ),
  })

  const inputRef = useCombinedRef(
    props.inputRef,
    internalInputRef,
    iMaskInputRef as Ref<HTMLInputElement>,
  )

  useAutoSelectAllOnUserDoAnyOperate(internalInputRef, isValuePrecisionExceed)

  return (
    <input
      ref={inputRef}
      inputMode="decimal"
      className={props.className}
      disabled={props.disabled}
      placeholder={props.placeholder}
    />
  )
}

function useAutoSelectAllOnUserDoAnyOperate(
  inputRef: ImmutableRefObject<null | MaskableElement>,
  isValuePrecisionExceed: boolean,
): void {
  useEffect(() => {
    if (!inputRef.current) return
    if (!isValuePrecisionExceed) return

    const el = inputRef.current

    const selectAll = (): void => {
      el.selectionStart = 0
      el.selectionEnd = el.value.length
      setTimeout(() => {
        el.selectionStart = 0
        el.selectionEnd = el.value.length
      })
    }

    el.addEventListener("click", selectAll)
    el.addEventListener("keydown", selectAll)
    el.addEventListener("selectionchange", selectAll)

    return () => {
      el.removeEventListener("click", selectAll)
      el.removeEventListener("keydown", selectAll)
      el.removeEventListener("selectionchange", selectAll)
    }
  }, [inputRef, isValuePrecisionExceed])
}

type MaskableElement = HTMLInputElement | HTMLTextAreaElement
function useIMask<Opts extends IMask.AnyMaskedOptions = IMask.AnyMaskedOptions>(
  value: any,
  opts: Opts,
  callbacks: {
    onAccept?: (maskRef: IMask.InputMask<Opts>, e?: InputEvent) => void
    onComplete?: (maskRef: IMask.InputMask<Opts>, e?: InputEvent) => void
  } = {},
): {
  ref: MutableRefObject<null | MaskableElement>
  maskRef: RefObject<IMask.InputMask<Opts>>
} {
  const ref = useRef<null | MaskableElement>(null)
  const maskRef = useRef<null | IMask.InputMask<Opts>>(null)

  const cbOnAccept = callbacks.onAccept
  const onAccept = useCallback(
    (event?: InputEvent) => {
      if (!maskRef.current) return
      cbOnAccept?.(maskRef.current, event)
    },
    [cbOnAccept],
  )

  const cbOnComplete = callbacks.onComplete
  const onComplete = useCallback(
    (event?: InputEvent) => {
      if (!maskRef.current) return
      cbOnComplete?.(maskRef.current, event)
    },
    [cbOnComplete],
  )

  const destroyMask = useCallback(() => {
    maskRef.current?.destroy()
    maskRef.current = null
  }, [])

  // destroy instance on unmount
  useEffect(() => destroyMask, [destroyMask])

  useEffect(() => {
    const el = ref.current

    if (!el || !opts?.mask) {
      destroyMask()
      return
    }

    if (!maskRef.current) {
      maskRef.current = IMask(el, opts)
    } else {
      maskRef.current.updateOptions(opts)
    }
  }, [destroyMask, opts])

  // onAccept
  useEffect(() => {
    if (!ref.current || !maskRef.current) return

    maskRef.current.on("accept", onAccept)

    if (ref.current.defaultValue !== maskRef.current.value) {
      onAccept()
    }

    return () => {
      maskRef.current?.off("accept", onAccept)
    }
  }, [onAccept])

  // onComplete
  useEffect(() => {
    if (!ref.current || !maskRef.current) return

    maskRef.current.on("complete", onComplete)

    return () => {
      maskRef.current?.off("complete", onComplete)
    }
  }, [onComplete])

  useEffect(() => {
    if (maskRef.current == null) return
    if (value !== maskRef.current.typedValue) {
      maskRef.current.value = value ? String(value) : ""
    }
  }, [value])

  useEffect(() => destroyMask, [destroyMask])

  return {
    ref,
    maskRef,
  }
}
