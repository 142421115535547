import { action, computed, makeObservable } from "mobx"
import { asyncAction, runAsyncAction } from "../utils/asyncAction"
import { Result } from "../utils/Result"
import { SuspenseObservable } from "./SuspenseObservable"

export class ValidationStore<Value, Input, E = Error> {
  constructor(
    private readonly validateFunc: (input: Input) => Promise<Result<Value, E>>,
  ) {
    makeObservable(this)
  }

  // null means idle
  private validation = new SuspenseObservable<Result<Value, E> | null>(null)

  @computed get read$(): Result<Value, E> | null {
    return this.validation.read$
  }

  @action reset(): void {
    this.validation.set(null)
  }

  @asyncAction
  async validate(
    input: Input,
    run = runAsyncAction,
  ): Promise<Result<Value, E>> {
    this.validation.set(undefined)
    const result = await run(this.validateFunc(input))
    if (this.validation.get() === undefined) {
      this.validation.set(result)
    }
    return result
  }
}
