import clsx from "clsx"
import { FC } from "react"
import remarkGfm from "remark-gfm"
import { LoadingIndicator } from "../../../components/LoadingIndicator/LoadingIndicator"
import { MarkdownContent } from "../../../components/MarkdownContent/MarkdownContent"
import { Spensor } from "../../../components/Spensor"
import { useLockdropDetailStore } from "../store/useLockdropDetailStore"
import styles from "./Details.module.scss"

export const WiredLockdropDetails: FC<{ className?: string }> = props => {
  const store = useLockdropDetailStore()
  return (
    <>
      <div className="h-0 relative">
        <div id="details" className="bottom-20 absolute" />
      </div>
      <Spensor fallback={<LoadingIndicator className="mx-auto" />}>
        {() => {
          return (
            <MarkdownContent
              markdown={store.detail$.details}
              plugins={[remarkGfm]}
              className={clsx(
                props.className,
                styles.details,
                "prose dark:prose-invert max-w-none lg:columns-2 gap-x-12",
              )}
            />
          )
        }}
      </Spensor>
    </>
  )
}
