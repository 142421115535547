import clsx from "clsx"
import { noop } from "lodash"
import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { CardPlate } from "../../../../components/Card"
import {
  NoteParagraph,
  WarnIcon,
} from "../../../../components/NoteParagraph/NoteParagraph"
import {
  SuspenseResource,
  suspenseResource,
} from "../../../../utils/SuspenseResource"
import { TokenInfoPresets } from "../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { RectButton } from "../wrappedCommonComponents/RectButton"
import { TradingAccountContent } from "./TradingAccountContent"

function neverResolveSuspenseResource<T>(): SuspenseResource<T> {
  return suspenseResource(() => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    throw new Promise(() => {})
  })
}

export const TradingAccountContent$SwitchNetwork: FC<{
  gapClassName?: string
  onSeeSwitchNetworkGuide: () => void
}> = props => {
  const { $t } = useIntl()
  return (
    <div className={"grid"}>
      <div
        className={clsx(
          "col-start-1 col-span-1 row-start-1 row-span-1",
          "blur",
          "flex flex-col",
          props.gapClassName,
        )}
      >
        <TradingAccountContent
          gapClassName={props.gapClassName}
          totalBalance={neverResolveSuspenseResource<number>()}
          viewingToken={TokenInfoPresets.APower}
          viewingTokenAvailableCount={neverResolveSuspenseResource<number>()}
          viewingTokenLockedCount={neverResolveSuspenseResource<number>()}
          availableTokens={[]}
          onViewingTokenChange={noop}
          onDeposit={noop}
          onWithdraw={noop}
        />
      </div>

      <div
        className={clsx(
          "col-start-1 col-span-1 row-start-1 row-span-1 z-1",
          "min-w-full min-h-full flex flex-col gap-2.5 items-center justify-center",
        )}
      >
        <CardPlate>
          <NoteParagraph className={"text-yellow-500"} icon={<WarnIcon />}>
            {$t(
              defineMessage({
                defaultMessage:
                  "Set your Hiro wallet Browser extension with following Steps",
                description: `Orderbook/Trading Account Panel/Switch to testnet guide`,
              }),
            )}
          </NoteParagraph>
        </CardPlate>

        <RectButton
          Variant={GradientFilledButton}
          className={"flex items-center"}
          onClick={props.onSeeSwitchNetworkGuide}
        >
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.5 8.66797V2.66797L4.5 2.66797L4.5 10.668H6.5L10.5 10.668L10.5 13.3346L15.1667 9.66797L10.5 6.0013V8.66797L6.5 8.66797Z"
              fill="white"
            />
          </svg>
          &nbsp;{" "}
          {$t(
            defineMessage({
              defaultMessage: "Switch to Testnet",
              description: `Orderbook/Trading Account Panel/Switch to testnet guide/"switch to testnet" button text`,
            }),
          )}
        </RectButton>
      </div>
    </div>
  )
}
