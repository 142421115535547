import { computed, makeObservable } from "mobx"
import AuthStore from "../../../stores/authStore/AuthStore"
import { ChainStore } from "../../../stores/chainStore/ChainStore"
import CurrencyStore from "../../../stores/currencyStore/CurrencyStore"
import { LazyValue } from "../../../stores/LazyValue/LazyValue"
import { Currency } from "../../../utils/alexjs/Currency"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { LockdropStatus } from "../components/types"
import * as LockdropDetailStoreServices from "./LockdropDetailStore.services"

export interface LockdropBannerProps
  extends Omit<
    LockdropDetailStoreServices.LockdropDetail,
    "urlSlug" | "snapshotBlockHeights" | "details"
  > {
  snapshotDates: Date[]
  status: LockdropStatus
  currentStepIndex: number
}

export interface LockdropRankingsProps {
  tokenInfo?: TokenInfo
  rankings: LockdropDetailStoreServices.Ranking[]
}

export interface LockdropMyStatusProps
  extends LockdropDetailStoreServices.MyStatus {
  tokenInfo: TokenInfo
  snapshotDates: Date[]
}

export class LockdropDetailStore {
  constructor(
    readonly urlSlug: string,
    readonly currencyStore: Pick<
      CurrencyStore,
      "getTokenInfoForAsset$" | "getTokenInfo$"
    >,
    readonly chainStore: Pick<ChainStore, "estimatedDateForBlock$">,
    readonly authStore: Pick<AuthStore, "stxAddress$">,
  ) {
    makeObservable(this)
  }

  // banner
  private today = new Date()

  private detail = new LazyValue(
    () => this.urlSlug,
    LockdropDetailStoreServices.fetchDetails,
  )

  @computed get detail$(): LockdropDetailStoreServices.LockdropDetail {
    return this.detail.value$
  }

  @computed private get snapshotDates$(): Date[] {
    return this.detail.value$.snapshotBlockHeights.map(
      this.chainStore.estimatedDateForBlock$,
    )
  }

  @computed private get currentStepIndex$(): number {
    const { value$ } = this.detail
    return LockdropDetailStoreServices.getCurrentStepIndex(
      value$.startDate,
      value$.endDate,
      this.today,
      this.snapshotDates$,
    )
  }

  @computed private get status$(): LockdropStatus {
    return LockdropDetailStoreServices.getStatusFromStepIndex(
      this.currentStepIndex$,
    )
  }

  @computed get bannerProps$(): LockdropBannerProps {
    return {
      ...this.detail$,
      snapshotDates: this.snapshotDates$,
      status: this.status$,
      currentStepIndex: this.currentStepIndex$,
    }
  }

  // my status
  private myStatus = new LazyValue(
    () => [this.authStore.stxAddress$] as const,
    LockdropDetailStoreServices.fetchMyStatus,
  )

  @computed get myStatusProps$(): LockdropMyStatusProps {
    return {
      ...this.myStatus.value$,
      tokenInfo: this.currencyStore.getTokenInfo$(Currency.ATALEX),
      snapshotDates: this.snapshotDates$,
    }
  }

  // rankings
  private rankings = new LazyValue(
    () => this.urlSlug,
    LockdropDetailStoreServices.fetchRankings,
  )

  @computed get rankingsProps$(): LockdropRankingsProps {
    return {
      tokenInfo: this.currencyStore.getTokenInfo$(Currency.ATALEX),
      rankings: this.rankings.value$,
    }
  }
}
