import { FC } from "react"
import { BorrowClaimRowData } from "../../../../store/transformerGroups/lend/borrowClaim"
import { AllTokenInfos } from "../../../../types"
import { BaseCellContainer } from "../../cells/BaseCellContainer"
import { OneLineAmountCell } from "../../cells/OneLineAmountCell"
import { OneLineCurrencyCell } from "../../cells/OneLineCurrencyCell"
import { BaseRow } from "../BaseRow"

export const BorrowClaimRow: FC<{
  rowData: BorrowClaimRowData
  allTokenInfos: AllTokenInfos
}> = ({ rowData, allTokenInfos }) => (
  <BaseRow
    rowData={rowData}
    currencyCell={
      <OneLineCurrencyCell
        fromCurrencyAmounts={[rowData.keyTokenCurrencyAmount]}
        toCurrencyAmounts={[
          rowData.collateralCurrencyAmount,
          rowData.tokenCurrencyAmount,
        ]}
        allTokenInfos={allTokenInfos}
      />
    }
    amountCell={
      <OneLineAmountCell
        fromCurrencyAmounts={[rowData.keyTokenCurrencyAmount]}
        toCurrencyAmounts={[
          rowData.collateralCurrencyAmount,
          rowData.tokenCurrencyAmount,
        ]}
        allTokenInfos={allTokenInfos}
      />
    }
    tradeInfoCell={<BaseCellContainer>Collateral Claim</BaseCellContainer>}
  />
)
