import { FC, useMemo } from "react"
import { Navigate, To, useSearchParams } from "react-router-dom"

export const OrderbookIndexPage: FC<{ to: To }> = ({ to }) => {
  const [searchParams] = useSearchParams()
  const search = useMemo(() => searchParams.toString(), [searchParams])

  return (
    <Navigate
      to={search.length !== 0 ? `${to}?${search}` : to}
      replace={true}
    />
  )
}
