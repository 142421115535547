import { memoize } from "lodash"
import { CONTRACT_DEPLOYER, TOKEN_MAP_OVERWRITES } from "../../config"
import { contractEquals } from "../addressHelpers"
import { isEnumValue } from "../enumHelpers"
import { assertNever } from "../types"
import { AMMSwapPool } from "./AMMSwapPool"
import {
  Currency,
  DownstreamCurrency,
  NativeCurrency,
  WrappedCurrency,
} from "./Currency"

const _currencyAssetIdentifierMap: {
  [P in Currency]: `${string}.${string}::${string}`
} = {
  [Currency.ALEX]: `${CONTRACT_DEPLOYER}.age000-governance-token::alex`,
  [Currency.ATALEX]: `${CONTRACT_DEPLOYER}.auto-alex::auto-alex`,
  [Currency.APOWER]: `${CONTRACT_DEPLOYER}.token-apower::apower`,
  [Currency.YIELD_ALEX]: `${CONTRACT_DEPLOYER}.yield-alex-v1::yield-alex-v1`,
  [Currency.KEY_ALEX_AUTOALEX]: `${CONTRACT_DEPLOYER}.key-alex-autoalex-v1::key-alex-autoalex-v1`,
  [Currency.YTP_ALEX]: `${CONTRACT_DEPLOYER}.ytp-alex-v1::ytp-alex-v1`,
  [Currency.AUTO_YTP_ALEX]: `${CONTRACT_DEPLOYER}.auto-ytp-alex::auto-ytp-alex`,
  [Currency.FWP_ALEX_ATALEX]: `${CONTRACT_DEPLOYER}.fwp-alex-autoalex::fwp-alex-autoalex`,
  [Currency.FWP_STX_ALEX_50_50_V1_01]: `${CONTRACT_DEPLOYER}.fwp-wstx-alex-50-50-v1-01::fwp-wstx-alex-50-50-v1-01`,
  [Currency.FWP_STX_ALEX_TRANCHED]: `${CONTRACT_DEPLOYER}.fwp-wstx-alex-tranched-64::fwp-wstx-alex-tranched-64`,
  [Currency.FWP_STX_XBTC_50_50_V1_01]: `${CONTRACT_DEPLOYER}.fwp-wstx-wbtc-50-50-v1-01::fwp-wstx-wbtc-50-50-v1-01`,
  [Currency.FWP_ALEX_BANANA]: `${CONTRACT_DEPLOYER}.fwp-alex-wban::fwp-alex-wban`,
  [Currency.FWP_ALEX_USDA]: `${CONTRACT_DEPLOYER}.fwp-alex-usda::fwp-alex-usda`,
  [Currency.FWP_ALEX_SLIME]: `${CONTRACT_DEPLOYER}.fwp-alex-wslm::fwp-alex-wslm`,
  [Currency.FWP_STX_XUSD]: `${CONTRACT_DEPLOYER}.fwp-wstx-wxusd-50-50-v1-01::fwp-wstx-wxusd-50-50-v1-01`,
  [Currency.FWP_STX_MIA]: `${CONTRACT_DEPLOYER}.fwp-wstx-wmia-50-50-v1-01::fwp-wstx-wmia-50-50-v1-01`,
  [Currency.FWP_STX_NYCC]: `${CONTRACT_DEPLOYER}.fwp-wstx-wnycc-50-50-v1-01::fwp-wstx-wnycc-50-50-v1-01`,
  [Currency.W_STX]: `${CONTRACT_DEPLOYER}.token-wstx::wstx`,
  [Currency.W_USDA]: `${CONTRACT_DEPLOYER}.token-wusda::wusda`,
  [Currency.W_XBTC]: `${CONTRACT_DEPLOYER}.token-wbtc::wbtc`,
  [Currency.W_BANANA]: `${CONTRACT_DEPLOYER}.token-wban::wban`,
  [Currency.W_DIKO]: `${CONTRACT_DEPLOYER}.token-wdiko::wdiko`,
  [Currency.W_SLIME]: `${CONTRACT_DEPLOYER}.token-wslm::wslm`,
  [Currency.W_XUSD]: `${CONTRACT_DEPLOYER}.token-wxusd::wxusd`,
  [Currency.W_MIA]: `${CONTRACT_DEPLOYER}.token-wmia::wmia`,
  [Currency.W_NYCC]: `${CONTRACT_DEPLOYER}.token-wnycc::wnycc`,
  //  AMM POOL Tokens are semi-fungible tokens, we use / at the end to indicate x,y tokens
  [Currency.AMM_SWAP_POOL_WXUSD_WUSDA]: `${CONTRACT_DEPLOYER}.token-amm-swap-pool::amm-swap-pool/token-wxusd,token-wusda`,
  [Currency.AMM_SWAP_POOL_WXUSD_WUSDA]: `${CONTRACT_DEPLOYER}.token-amm-swap-pool::amm-swap-pool/token-wxusd,token-wusda,0.0001e8"`,
  [Currency.AMM_SWAP_POOL_WSTX_ALEX]: `${CONTRACT_DEPLOYER}.token-amm-swap-pool::amm-swap-pool/token-wstx,age000-governance-token,0.99e8"`,
  [Currency.AMM_SWAP_POOL_WSTX_XBTC]: `${CONTRACT_DEPLOYER}.token-amm-swap-pool::amm-swap-pool/token-wstx,token-wbtc,0.99e8"`,
  [Currency.AMM_SWAP_POOL_ALEX_WBAN]: `${CONTRACT_DEPLOYER}.token-amm-swap-pool::amm-swap-pool/token-wstx,token-wban,0.99e8"`,
  [Currency.AMM_SWAP_POOL_ALEX_WUSDA]: `${CONTRACT_DEPLOYER}.token-amm-swap-pool::amm-swap-pool/token-wstx,token-wusda,0.99e8"`,
}

const _downstreamCurrencyAssetIdentifierMap: {
  [P in DownstreamCurrency]:
    | `${string}.${string}::${string}`
    | {
        assetIdentifier: `${string}.${string}::${string}`
        decimals: number
      }
} = {
  [DownstreamCurrency.STX]: {
    assetIdentifier: `${CONTRACT_DEPLOYER}.token-wstx::wstx`,
    decimals: 1e6,
  },
  [DownstreamCurrency.USDA]: {
    assetIdentifier: `${CONTRACT_DEPLOYER}.token-usda::usda`,
    decimals: 1e6,
  },
  [DownstreamCurrency.XBTC]: `${CONTRACT_DEPLOYER}.token-xbtc::xbtc`,
  [DownstreamCurrency.BANANA]: {
    assetIdentifier: `${CONTRACT_DEPLOYER}.token-banana::banana`,
    decimals: 1e6,
  },
  [DownstreamCurrency.DIKO]: {
    assetIdentifier: `${CONTRACT_DEPLOYER}.token-diko::diko`,
    decimals: 1e6,
  },
  [DownstreamCurrency.SLIME]: {
    assetIdentifier: `${CONTRACT_DEPLOYER}.token-slime::slime`,
    decimals: 1e6,
  },
  [DownstreamCurrency.XUSD]: `${CONTRACT_DEPLOYER}.token-xusd::xusd`,
  [DownstreamCurrency.MIA]: {
    decimals: 1e6,
    assetIdentifier: `${CONTRACT_DEPLOYER}.token-mia::mia`,
  },
  [DownstreamCurrency.NYCC]: {
    decimals: 1e6,
    assetIdentifier: `${CONTRACT_DEPLOYER}.token-nycc::nycc`,
  },
  ...TOKEN_MAP_OVERWRITES,
}

export const getAssetIdentifierByCurrency = (
  currency: Currency | DownstreamCurrency,
): string => {
  if (isEnumValue(DownstreamCurrency, currency)) {
    // @ts-ignore
    const downstreamCurrencyResult =
      _downstreamCurrencyAssetIdentifierMap[currency]
    if (typeof downstreamCurrencyResult === "string") {
      return downstreamCurrencyResult
    } else {
      return downstreamCurrencyResult.assetIdentifier
    }
  }

  return _currencyAssetIdentifierMap[currency]
}

export const getNativeAndWrappedCurrencyByAsset = memoize(
  (assetIdentifierOrAssetContractAddress: string): Currency | undefined => {
    return Object.values(Currency).find(k =>
      contractEquals(
        getAssetIdentifierByCurrency(k),
        assetIdentifierOrAssetContractAddress,
      ),
    )
  },
)

export const getNativeAndDownstreamCurrencyByAsset = memoize(
  (
    assetIdentifierOrAssetContractAddress: string,
  ): NativeCurrency | DownstreamCurrency | undefined => {
    const currency = getNativeAndWrappedCurrencyByAsset(
      assetIdentifierOrAssetContractAddress,
    )

    if (currency != null) {
      if (WrappedCurrency.isWrapped(currency)) {
        // ask @kyle for more details
        throw new Error(
          `[getNativeAndDownstreamCurrencyByAsset] Trying to get wrapped currency by contract address, which should never happen in almost all cases`,
        )
      }
      return currency
    }

    return getDownstreamCurrencyByAsset(assetIdentifierOrAssetContractAddress)

    function getDownstreamCurrencyByAsset(
      assetIdentifierOrAssetContractAddress: string,
    ): DownstreamCurrency | undefined {
      return Object.values(DownstreamCurrency).find(k =>
        contractEquals(
          getAssetIdentifierByCurrency(k),
          assetIdentifierOrAssetContractAddress,
        ),
      )
    }
  },
)

export const currencyScale = (
  currency: Currency | DownstreamCurrency,
): number => {
  if (isEnumValue(DownstreamCurrency, currency)) {
    // @ts-ignore
    const downstreamCurrencyResult =
      _downstreamCurrencyAssetIdentifierMap[currency]
    if (typeof downstreamCurrencyResult === "string") {
      return 1e8
    } else {
      return downstreamCurrencyResult.decimals
    }
  }

  return 1e8
}

export type FungibleToken = Currency

export type SwappableCurrency =
  | Currency.W_STX
  | Currency.ALEX
  | Currency.ATALEX
  | Currency.W_XBTC
  | Currency.W_BANANA
  | Currency.W_USDA
  | Currency.W_SLIME
  | Currency.W_XUSD
  | Currency.W_MIA
  | Currency.W_NYCC

export const fwpTokens = [
  Currency.FWP_STX_ALEX_50_50_V1_01,
  Currency.FWP_STX_XBTC_50_50_V1_01,
  Currency.FWP_ALEX_BANANA,
  Currency.FWP_ALEX_USDA,
  Currency.FWP_ALEX_ATALEX,
  Currency.FWP_ALEX_SLIME,
  Currency.FWP_STX_XUSD,
  Currency.FWP_STX_MIA,
  Currency.FWP_STX_NYCC,
] as const

export type FWPToken = typeof fwpTokens[number]

export const isFWPToken = (token: string): token is FWPToken => {
  return fwpTokens.includes(token as any)
}

export const ytpTokens = [Currency.YTP_ALEX] as const
export const yieldTokens = [Currency.YIELD_ALEX] as const
export const autoYTPTokens = [Currency.AUTO_YTP_ALEX] as const
export const keyTokens = [Currency.KEY_ALEX_AUTOALEX] as const
export const collateralTokens = [Currency.ATALEX] as const

export type YTPToken = typeof ytpTokens[number]

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function ytpBreakDown(ytpToken: YTPToken) {
  switch (ytpToken) {
    case Currency.YTP_ALEX:
      return {
        underlying: Currency.ALEX,
        collateral: Currency.ATALEX,
        yieldToken: Currency.YIELD_ALEX,
        keyToken: Currency.KEY_ALEX_AUTOALEX,
        autoYTP: Currency.AUTO_YTP_ALEX,
      } as const
  }
}

export type YieldToken = ReturnType<typeof ytpBreakDown>["yieldToken"]
export type AutoYTPToken = typeof autoYTPTokens[number]
export type KeyToken = typeof keyTokens[number]
export type CollateralToken = typeof collateralTokens[number]

export const isYTPToken = (token: string): token is YTPToken => {
  return ytpTokens.includes(token as any)
}

export const yieldTokenForYTP = (poolToken: YTPToken): YieldToken => {
  switch (poolToken) {
    case Currency.YTP_ALEX:
      return Currency.YIELD_ALEX
    default:
      assertNever(poolToken)
  }
}

export const autoTokenForYTP = (poolToken: YTPToken): AutoYTPToken => {
  switch (poolToken) {
    case Currency.YTP_ALEX:
      return Currency.AUTO_YTP_ALEX
    default:
      assertNever(poolToken)
  }
}

export type DualYieldFWPPool = Currency.FWP_ALEX_USDA

export const simpleAlexPools = [
  Currency.FWP_ALEX_BANANA,
  Currency.FWP_ALEX_USDA,
  Currency.FWP_ALEX_ATALEX,
  Currency.FWP_ALEX_SLIME,
] as const

export type SimpleWeightFWPPool = typeof simpleAlexPools[number]

export const isSimpleAlexPool = (
  token: Currency,
): token is SimpleWeightFWPPool => {
  return simpleAlexPools.includes(token as any)
}

export const isDualYieldPool = (token: FWPToken): token is DualYieldFWPPool => {
  return token === Currency.FWP_ALEX_USDA
}

export type DualYieldToken = Currency.W_DIKO

export const dualYieldToken = (
  token: Currency.FWP_ALEX_USDA,
): DualYieldToken => {
  switch (token) {
    case Currency.FWP_ALEX_USDA:
      return Currency.W_DIKO
    default:
      return assertNever(token)
  }
}

export type LiquidityToken = SwappableCurrency | YieldToken

export type LiquidityPoolToken = FWPToken | YTPToken | AMMSwapPool.PoolTokens

export const liquidityTokenPairs = (
  poolToken: LiquidityPoolToken,
): [Currency.W_STX | Currency.ALEX | Currency.W_XUSD, LiquidityToken] => {
  if (AMMSwapPool.isPoolToken(poolToken)) {
    return AMMSwapPool.breakDown(poolToken)
  }
  switch (poolToken) {
    case Currency.FWP_STX_ALEX_50_50_V1_01:
      return [Currency.W_STX, Currency.ALEX]
    case Currency.FWP_STX_XBTC_50_50_V1_01:
      return [Currency.W_STX, Currency.W_XBTC]
    case Currency.FWP_ALEX_BANANA:
      return [Currency.ALEX, Currency.W_BANANA]
    case Currency.FWP_ALEX_USDA:
      return [Currency.ALEX, Currency.W_USDA]
    case Currency.FWP_ALEX_ATALEX:
      return [Currency.ALEX, Currency.ATALEX]
    case Currency.FWP_ALEX_SLIME:
      return [Currency.ALEX, Currency.W_SLIME]
    case Currency.FWP_STX_XUSD:
      return [Currency.W_STX, Currency.W_XUSD]
    case Currency.FWP_STX_MIA:
      return [Currency.W_STX, Currency.W_MIA]
    case Currency.FWP_STX_NYCC:
      return [Currency.W_STX, Currency.W_NYCC]
    case Currency.YTP_ALEX:
      return [Currency.ALEX, Currency.YIELD_ALEX]
    default:
      assertNever(poolToken)
  }
}
