import { FC } from "react"
import { withClassName } from "../../../../../utils/reactHelpers/withClassName"
import { ReactComponent as Icon } from "./winning-criteria.svg"

export const WinningCriteria: FC = () => (
  <section className="w-full flex flex-row flex-wrap gap-6">
    <Content className={"flex-1"}>
      <div>
        <h1 className="text-xl leading-7 font-medium text-white">
          Winning Criteria
        </h1>
        <h2 className="text-base leading-6 font-medium text-yellow-200">
          The number on your ticket must be drawn as prizes winners.
        </h2>
      </div>
      <p>Here’s an example of a lottery drawing, with two tickets, A and B.</p>
      <ul className="list-disc list-outside pl-6">
        <li>
          Ticket A: Ticket #0012 is drawn as the 1st prize winner. This ticket
          will share the 1st prize pool after the drawing.
        </li>
        <li>
          Ticket B: Ticket #0068 is drawn as 1st prize winner and 3rd prize
          winner. This ticket will share the 1st prize pool and 3rd prize pool
          after the drawing.
        </li>
      </ul>
    </Content>

    <Icon className="max-w-full shrink-0" />
  </section>
)

const Content = withClassName(
  "flex flex-col gap-y-5 text-sm leading-5 font-normal text-gray-400",
  "div",
)
