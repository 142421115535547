import { FC } from "react"
import { LoadingIndicator } from "../../../components/LoadingIndicator/LoadingIndicator"
import { Spensor } from "../../../components/Spensor"
import { useLockdropDetailStore } from "../store/useLockdropDetailStore"
import { LockdropBanner } from "./Banner/Banner"

export const WiredLockdropBanner: FC = () => {
  const store = useLockdropDetailStore()
  return (
    <Spensor fallback={<LoadingIndicator className="mx-auto" />}>
      {() => <LockdropBanner {...store.bannerProps$} />}
    </Spensor>
  )
}
