import clsx from "clsx"
import { FC, ReactNode } from "react"

/**
 * Used as column cell with title in single row table.
 */
export const TitledColumnCell: FC<{
  className?: string
  titleClassName?: string
  detailClassName?: string
  title: ReactNode
  detail: ReactNode
}> = props => (
  <dl className={clsx("flex flex-col", props.className)}>
    <dt
      className={clsx(
        "text-sm leading-5 font-normal text-gray-200/50 whitespace-nowrap",
        props.titleClassName,
      )}
    >
      {props.title}
    </dt>
    <dd
      className={clsx(
        "text-base leading-6 font-medium text-gray-200 flex flex-1 items-center",
        props.detailClassName,
      )}
    >
      {props.detail}
    </dd>
  </dl>
)
