import { CONTRACT_DEPLOYER } from "../../../../config"
import { asSender } from "../../../../generated/smartContractHelpers/asSender"
import pMemoize from "../../../../utils/pMemorize"
import { StakingToken } from "../manualStaking/ManualStakeStore.service"

export const stakeCycleLength = pMemoize(
  () =>
    asSender(CONTRACT_DEPLOYER)
      .contract("alex-reserve-pool")
      .func("get-reward-cycle-length")
      .call({}),
  {
    persistKey: "staking-blocks-per-cycle",
  },
)

export const cycleStartBlockForToken = pMemoize(
  (token: StakingToken) =>
    asSender(CONTRACT_DEPLOYER)
      .contract("alex-reserve-pool")
      .func("get-first-stacks-block-in-reward-cycle")
      .call({
        token,
        "reward-cycle": 0,
      }),
  { persistKey: "stake-first-cycle-block" },
)
