import { FC } from "react"
import { GradientFilledButton } from "../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteFilledButton } from "../../../components/button/variants/WhiteFilledButton"
import { CardPlate } from "../../../components/Card"
import { CardModalContent } from "../../../components/CardModal/CardModal"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../components/InfoList"
import { NoteParagraph } from "../../../components/NoteParagraph/NoteParagraph"
import { PercentNumber } from "../../../components/PercentNumber"
import { TipIcon } from "../../../components/TipIcon/TipIcon"
import { TokenCount } from "../../../components/TokenCount"
import { BlockTopLine } from "../../../components/TokenInput/Block"
import {
  BlockGroup,
  BlockGroupDownArrowIcon,
} from "../../../components/TokenInput/BlockGroup"
import {
  TokenBlock,
  TokenCountPairPlain,
} from "../../../components/TokenInput/TokenBlock"
import { TokenName } from "../../../components/TokenName"
import { TokenInfo } from "../../../utils/models/TokenInfo"

export interface ConfirmCoFarmModalContentProps {
  lpLeftToken: TokenInfo
  lpRightToken: TokenInfo

  lpRightTokenTransformedToken: TokenInfo
  lpRightTokenTransformedTokenEstimateApy: number
  lpRightTokenTransformedTokenStakePrice: number

  coFarmEstimateApr: number
  alexFoundationOffer: TokenCountPairPlain
  fromCycle: number
  toCycle: number
  harvestAssets: TokenCountPairPlain[]
  coFarmAssets: TokenCountPairPlain[]

  onConfirm?: () => void
  onClose?: () => void
}

export const ConfirmCoFarmModalContent: FC<
  ConfirmCoFarmModalContentProps
> = props => (
  <CardModalContent
    onClose={props.onClose}
    title="Confirm Co-Farm LP"
    className="flex flex-col gap-4"
    width={480}
  >
    <BlockGroup
      firstBlock={
        <TokenBlock
          topArea={
            <BlockTopLine className="flex flex-row gap-x-2.5">
              <span>Harvest</span>
            </BlockTopLine>
          }
          tokens={props.harvestAssets}
        />
      }
      icon={<BlockGroupDownArrowIcon />}
      secondBlock={
        <TokenBlock
          topArea={
            <BlockTopLine>
              Co-Farm&nbsp;
              <TipIcon
                inline
                tip={
                  <>
                    Your <TokenName token={props.lpLeftToken} /> in the Co-Farm
                    LP will be protected from impermanent loss by the ALEX Lab
                    Foundation via the pool value.
                  </>
                }
              />
            </BlockTopLine>
          }
          tokens={props.coFarmAssets}
        />
      }
    />
    <CardPlate>
      <InfoList>
        <InfoListItem>
          <InfoListItemTitle>Co-Farm Est APR</InfoListItemTitle>
          <InfoListItemDetail>
            <PercentNumber number={props.coFarmEstimateApr} />
          </InfoListItemDetail>
        </InfoListItem>

        <InfoListItem>
          <InfoListItemTitle>ALEX Lab Foundation provides</InfoListItemTitle>
          <InfoListItemDetail>
            <TokenCount {...props.alexFoundationOffer} />
            <TokenName token={props.alexFoundationOffer.token} />
          </InfoListItemDetail>
        </InfoListItem>

        <InfoListItem>
          <InfoListItemTitle>Cycle numbers</InfoListItemTitle>
          <InfoListItemDetail>
            #{props.fromCycle}-{props.toCycle}
          </InfoListItemDetail>
        </InfoListItem>

        <InfoListItem>
          <InfoListItemTitle>
            <TokenName token={props.lpRightTokenTransformedToken} /> Est APY
          </InfoListItemTitle>
          <InfoListItemDetail>
            <PercentNumber
              number={props.lpRightTokenTransformedTokenEstimateApy}
            />
          </InfoListItemDetail>
        </InfoListItem>

        <InfoListItem>
          <InfoListItemTitle>
            <TokenName token={props.lpRightTokenTransformedToken} /> current
            value
          </InfoListItemTitle>
          <InfoListItemDetail>
            {props.lpRightTokenTransformedTokenStakePrice}
          </InfoListItemDetail>
        </InfoListItem>
      </InfoList>
    </CardPlate>

    <NoteParagraph textSizingClassName={"text-sm"}>
      Co-Farming is not a riskless commitment. In an extreme event, if the
      impermanent loss exceeds the capital of the{" "}
      <TokenName token={props.lpLeftToken} />/
      <TokenName token={props.lpRightToken} /> pool, the{" "}
      <TokenName token={props.lpLeftToken} /> committed will be subject to a
      loss.
    </NoteParagraph>

    <div className={"flex flex-row gap-2.5 pt-4"}>
      <WhiteFilledButton className={"flex-1"} onClick={props.onClose}>
        Cancel
      </WhiteFilledButton>

      <GradientFilledButton className={"flex-1"} onClick={props.onConfirm}>
        Confirm
      </GradientFilledButton>
    </div>
  </CardModalContent>
)
