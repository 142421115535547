import { assertNever } from "../../../../utils/types"
import { BridgeChain } from "../../types/BridgeChain"
import { WrapBridgeNetwork } from "../../types/types"
import { WrapBridgeNetworkPresets } from "../../types/WrapBridgeNetworkPresets/WrapBridgeNetworkPresets"

export function wrapBridgeNetworkFromBridgeChain(
  chain: BridgeChain,
): WrapBridgeNetwork {
  switch (chain) {
    case BridgeChain.Ethereum:
      return WrapBridgeNetworkPresets.Ethereum
    case BridgeChain.Goerli:
      return WrapBridgeNetworkPresets.Goerli
    case BridgeChain.Unknown:
      return WrapBridgeNetworkPresets.Unknown
    case BridgeChain.Stacks:
      return WrapBridgeNetworkPresets.Stacks
    default:
      assertNever(chain)
  }
}

export function wrapBridgeNetworkToBridgeChain(
  network: WrapBridgeNetwork,
): undefined | BridgeChain {
  switch (network.id) {
    case WrapBridgeNetworkPresets.Ethereum.id:
      return BridgeChain.Ethereum
    case WrapBridgeNetworkPresets.Goerli.id:
      return BridgeChain.Goerli
    case WrapBridgeNetworkPresets.Stacks.id:
      return BridgeChain.Stacks
    default:
      return undefined
  }
}
