import { FC } from "react"
import {
  AddStakeSectionInfoList,
  AddStakeSectionInfoListItem,
} from "../components/AddStakeSectionInfoList"

export interface StakeSubmissionSummaryProps {
  startedAtCycleNumber: number
  endedAtCycleNumber: number
  startedAtBlock: number
  endedAtBlock: number
}

export const StakeSubmissionSummary: FC<
  StakeSubmissionSummaryProps
> = props => (
  <AddStakeSectionInfoList>
    <AddStakeSectionInfoListItem
      title={"Cycle numbers"}
      detail={`#${props.startedAtCycleNumber}-#${props.endedAtCycleNumber}`}
    />
    <AddStakeSectionInfoListItem
      title={"Start block"}
      detail={props.startedAtBlock}
    />
    <AddStakeSectionInfoListItem
      title={"End block"}
      detail={props.endedAtBlock}
    />
  </AddStakeSectionInfoList>
)
