import React, { FC } from "react"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { LockdropMyStatusProps } from "../../store/LockdropDetailStore"
import { LockdropMyStatusContainer } from "./MyStatusContainer"
import { ConnectWallet } from "./_/ConnectWallet"
import { Rewards } from "./_/Rewards"
import { SnapshotMultipliers } from "./_/SnapshotMultipliers"
import { Statistics } from "./_/Statistics"

export const LockdropMyStatus: FC<
  LockdropMyStatusProps & {
    loading?: boolean
    rewardTokenAmountInfos?: Array<{ info: TokenInfo; amount: number }>
  }
> = props => {
  if (props.loading) {
    return (
      <LockdropMyStatusContainer>
        <LockdropMyStatus$Loading />
      </LockdropMyStatusContainer>
    )
  }
  return (
    <LockdropMyStatusContainer>
      {props.rewardTokenAmountInfos && (
        <Rewards tokenAmountInfos={props.rewardTokenAmountInfos} />
      )}
      <Statistics
        tokenInfo={props.tokenInfo}
        volume={props.volume}
        ranking={props.ranking}
        rewardsMultiplier={props.rewardsMultiplier}
      />
      {!props.rewardTokenAmountInfos && (
        <SnapshotMultipliers
          multipliers={props.snapshotMultipliers}
          snapshotDates={props.snapshotDates}
        />
      )}
    </LockdropMyStatusContainer>
  )
}

export const LockdropMyStatus$Loading: FC = () => (
  <>
    <Statistics />
    <SnapshotMultipliers />
  </>
)

export const LockdropMyStatus$ConnectWallet: FC<{
  tokenInfo?: TokenInfo
  volume?: number | null
  onConnect?: () => void
}> = ({ tokenInfo, volume, onConnect }) => (
  <>
    <ConnectWallet onConnect={onConnect} />
    <Statistics tokenInfo={tokenInfo} volume={volume} />
  </>
)
