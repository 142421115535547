import clsx from "clsx"
import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { cancel$t, confirm$t } from "../../../../commonIntlMessages"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteFilledButton } from "../../../../components/button/variants/WhiteFilledButton"
import { CardPlate } from "../../../../components/Card"
import {
  ActionRow,
  CardModalContent,
} from "../../../../components/CardModal/CardModal"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../components/InfoList"
import { NoteParagraph } from "../../../../components/NoteParagraph/NoteParagraph"
import { TextTokenCount } from "../../../../components/RichTokenCount"
import { TokenCount } from "../../../../components/TokenCount"
import { TokenName } from "../../../../components/TokenName"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { Colors } from "../designTokens"
import { TokenIconStack } from "../TokenIconStack"
import { TokenNameStack } from "../TokenNameStack"
import { OrderDirection } from "../types"
import { RectButton } from "../wrappedCommonComponents/RectButton"
import { orderDirection$t, orderType$t } from "../_/commonIntlMessages"
import { formFields$t } from "./_/commonIntlMessages"

export const StopLimitOrderConfirmModalContent: FC<{
  orderDirection: OrderDirection
  priceToken: TokenInfo
  tradeToken: TokenInfo
  perTradeTokenStopPrice?: number
  perTradeTokenLimitPrice: number
  totalTradeTokenPrice: number
  tradeTokenCount: number
  onConfirm?: () => void
  onCancel?: () => void
  onClose?: () => void
}> = props => {
  const intl = useIntl()
  const { $t } = intl
  return (
    <CardModalContent
      title={$t(
        defineMessage({
          defaultMessage: "Order Confirm",
          description: "Orderbook/Confirm Create Stop Limit Order Modal/title",
        }),
      )}
      onClose={props.onClose}
    >
      <div className={"flex gap-2.5 items-center"}>
        <TokenIconStack
          size={32}
          tradeToken={props.tradeToken}
          priceToken={props.priceToken}
        />
        <span className={"text-xl font-medium"}>
          <TokenNameStack
            tradeToken={props.tradeToken}
            priceToken={props.priceToken}
          />
        </span>
        <span
          className={clsx(
            "ml-auto",
            "text-sm font-semibold",
            props.orderDirection === "buy"
              ? Colors.buyTextClassName
              : Colors.sellTextClassName,
          )}
        >
          {orderDirection$t.fromOrderDirection(intl, props.orderDirection)} /{" "}
          {$t(orderType$t.stopDashLimit)}
        </span>
      </div>

      <CardPlate>
        <InfoList direction={"column"} listItemDirection={"row"}>
          {props.perTradeTokenStopPrice != null && (
            <InfoListItem>
              <InfoListItemTitle>
                {$t(formFields$t.stopPrice)}
              </InfoListItemTitle>
              <InfoListItemDetail>
                <TextTokenCount
                  token={props.priceToken}
                  count={props.perTradeTokenStopPrice}
                />
              </InfoListItemDetail>
            </InfoListItem>
          )}

          <InfoListItem>
            <InfoListItemTitle>{$t(formFields$t.limitPrice)}</InfoListItemTitle>
            <InfoListItemDetail>
              <TextTokenCount
                token={props.priceToken}
                count={props.perTradeTokenLimitPrice}
              />
            </InfoListItemDetail>
          </InfoListItem>

          <InfoListItem>
            <InfoListItemTitle>{$t(formFields$t.amount)}</InfoListItemTitle>
            <InfoListItemDetail>
              <TextTokenCount
                token={props.tradeToken}
                count={props.tradeTokenCount}
              />
            </InfoListItemDetail>
          </InfoListItem>

          <InfoListItem>
            <InfoListItemTitle>{$t(formFields$t.total)}</InfoListItemTitle>
            <InfoListItemDetail>
              <TextTokenCount
                token={props.priceToken}
                count={props.totalTradeTokenPrice}
              />
            </InfoListItemDetail>
          </InfoListItem>
        </InfoList>
      </CardPlate>

      {props.perTradeTokenStopPrice != null && (
        <NoteParagraph>
          {$t(
            defineMessage({
              defaultMessage:
                "If the last price drops to or below {stopPrice}, an order to {orderDirection, select, buy {buy} other {sell}} {incomingToken} at a price of {limitPrice} will be placed.",
              description:
                "Orderbook/Confirm Create Stop Limit Order Modal/tip text",
            }),
            {
              orderDirection: props.orderDirection,
              stopPrice: (
                <TokenCount
                  token={props.priceToken}
                  count={props.perTradeTokenStopPrice}
                />
              ),
              limitPrice: (
                <TokenCount
                  token={props.priceToken}
                  count={props.perTradeTokenLimitPrice}
                />
              ),
              incomingToken: <TokenName token={props.tradeToken} />,
            },
          )}
        </NoteParagraph>
      )}

      <ActionRow>
        <RectButton
          className={"flex-1"}
          Variant={WhiteFilledButton}
          onClick={props.onCancel}
        >
          {$t(cancel$t)}
        </RectButton>

        <RectButton
          className={"flex-1"}
          Variant={GradientFilledButton}
          onClick={props.onConfirm}
        >
          {$t(confirm$t)}
        </RectButton>
      </ActionRow>
    </CardModalContent>
  )
}
