import { computed, makeObservable } from "mobx"
import { LazyValue } from "../../../stores/LazyValue/LazyValue"
import SpotStore from "./SpotStore"
import { getLiquidityProviderFee } from "./SpotStore.services"

export class LiquidityProviderFeeModule {
  constructor(private readonly store: SpotStore) {
    makeObservable(this)
  }

  private lazyFee = new LazyValue(
    () => ({
      fromCurrency: this.store.fromCurrency.read$,
      toCurrency: this.store.toCurrency.read$,
      ammPools: this.store.availableAMMPools$,
    }),
    ({ fromCurrency, toCurrency, ammPools }) =>
      getLiquidityProviderFee(fromCurrency, toCurrency, ammPools),
  )

  @computed get fee$(): number {
    return this.lazyFee.value$ * this.store.inputtedFromAmount.read$
  }
}
