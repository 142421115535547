import { FC } from "react"
import { NavLink } from "../../../../../components/NavLink"
import { NoteParagraph } from "../../../../../components/NoteParagraph/NoteParagraph"
import { TipIcon } from "../../../../../components/TipIcon/TipIcon"

export const HowLotteryWorkLink: FC = () => (
  <NoteParagraph icon={<TipIcon size={16} tip={null} />}>
    <NavLink
      className={"text-blue-600"}
      to={
        "https://medium.com/alexgobtc/alex-launchpad-v1-1-how-does-the-hybrid-lottery-system-work-5406f437f896"
      }
    >
      How does the transparent lottery process work?
    </NavLink>
  </NoteParagraph>
)
