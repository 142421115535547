import clsx from "clsx"
import { FC, ReactNode } from "react"
import { TokenInfo } from "../../utils/models/TokenInfo"
import { readResource, SuspenseResource } from "../../utils/SuspenseResource"
import { TokenInfoPresets } from "../../utils/TokenInfoPresets/TokenInfoPresets"
import { OpacityButton } from "../button/variants/OpacityButton"
import { Spensor } from "../Spensor"
import { TokenCount } from "../TokenCount"
import { Truncatable } from "../Truncatable"
import { BlockBottomLine } from "./Block"

export const BalanceBottomArea: FC<{
  className?: string
  boxClassName?: string
  token: SuspenseResource<TokenInfo>
  balanceLabel?: string
  balance?: SuspenseResource<number>
  rightSide?: ReactNode
  onPressMax?: SuspenseResource<undefined | (() => void)>
}> = props => {
  return (
    <BlockBottomLine
      className={clsx("flex", props.className)}
      boxClassName={props.boxClassName}
    >
      {props.balance != null && (
        <BalanceCount
          balanceLabel={props.balanceLabel}
          token={props.token}
          balance={props.balance}
        />
      )}

      <Spensor>
        {() => {
          const onPressMax = props.onPressMax && readResource(props.onPressMax)
          return (
            onPressMax && (
              <MaxButton
                className={"mx-1 sm:mx-2.5"}
                onClick={readResource(onPressMax)}
              />
            )
          )
        }}
      </Spensor>

      {props.rightSide != null && (
        <div
          className={clsx("ml-auto whitespace-nowrap", bottomLineTextClassName)}
        >
          {props.rightSide}
        </div>
      )}
    </BlockBottomLine>
  )
}

const bottomLineTextClassName = "text-xs sm:text-base text-gray-500"

export const BalanceCount: FC<{
  balanceLabel?: string
  token?: SuspenseResource<TokenInfo>
  balance: SuspenseResource<number>
}> = props => (
  <span className={clsx("flex items-center", bottomLineTextClassName)}>
    {props.balanceLabel ?? "Balance: "}
    <Spensor fallback={"-"}>
      {() =>
        props.token === undefined ? (
          "Select a token"
        ) : (
          <TokenCount
            token={readResource(props.token)}
            count={readResource(props.balance)}
          />
        )
      }
    </Spensor>
  </span>
)

export const MaxButton: FC<{
  className?: string
  boxClassName?: string
  textClassName?: string
  onClick: () => void
}> = props => (
  <OpacityButton
    className={clsx("flex items-center", props.className)}
    boxClassName={props.boxClassName ?? "px-1 sm:px-2 sm:py-0.5"}
    textClassName={
      props.textClassName ?? "text-blue-600 text-xs sm:text-sm font-medium"
    }
    onClick={props.onClick}
  >
    Max
  </OpacityButton>
)

export const EstimatedUSD: FC<{
  className?: string
  usdCount: SuspenseResource<number>
  error?: boolean
}> = props => (
  <Truncatable
    className={clsx(
      bottomLineTextClassName,
      props.error && "text-red-500",
      props.className,
    )}
  >
    ≈${" "}
    <Spensor fallback={"-"}>
      {() => (
        <TokenCount
          token={TokenInfoPresets.USD}
          count={readResource(props.usdCount)}
        />
      )}
    </Spensor>
  </Truncatable>
)
