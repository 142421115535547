import { FC } from "react"
import { RemoveLiquidityRowData } from "../../../../store/transformerGroups/pool/removeLiquidity"
import { AllTokenInfos } from "../../../../types"
import { BaseCellContainer } from "../../cells/BaseCellContainer"
import { OneLineAmountCell } from "../../cells/OneLineAmountCell"
import { OneLineCurrencyCell } from "../../cells/OneLineCurrencyCell"
import { BaseRow } from "../BaseRow"

export const RemoveLiquidityRow: FC<{
  rowData: RemoveLiquidityRowData
  allTokenInfos: AllTokenInfos
}> = ({ rowData, allTokenInfos }) => (
  <BaseRow
    rowData={rowData}
    currencyCell={
      <OneLineCurrencyCell
        allTokenInfos={allTokenInfos}
        fromCurrencyAmounts={[rowData.lpCurrencyAmount]}
        toCurrencyAmounts={[rowData.currencyAmountX, rowData.currencyAmountY]}
      />
    }
    amountCell={
      <OneLineAmountCell
        allTokenInfos={allTokenInfos}
        fromCurrencyAmounts={[rowData.lpCurrencyAmount]}
        toCurrencyAmounts={[rowData.currencyAmountX, rowData.currencyAmountY]}
      />
    }
    tradeInfoCell={<BaseCellContainer>Remove Liquidity</BaseCellContainer>}
  />
)
