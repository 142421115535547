import { defineContract } from "../smartContractHelpers/codegenImport"
import { age000GovernanceToken } from "./contract_age000-governance-token"
import { age003EmergencyExecute } from "./contract_age003-emergency-execute"
import { age009TokenLock } from "./contract_age009-token-lock"
import { alexLaunchpadV11 } from "./contract_alex-launchpad-v1-1"
import { alexLottery } from "./contract_alex-lottery"
import { alexReservePool } from "./contract_alex-reserve-pool"
import { alexVault } from "./contract_alex-vault"
import { ammSwapPool } from "./contract_amm-swap-pool"
import { autoAlex } from "./contract_auto-alex"
import { autoFwpAlexAutoalexXV101 } from "./contract_auto-fwp-alex-autoalex-x-v1-01"
import { autoFwpWstxAlex120X } from "./contract_auto-fwp-wstx-alex-120x"
import { autoKeyAlexAutoalex } from "./contract_auto-key-alex-autoalex"
import { autoYieldAlex } from "./contract_auto-yield-alex"
import { autoYtpAlex } from "./contract_auto-ytp-alex"
import { autoalexApowerHelper } from "./contract_autoalex-apower-helper"
import { bridgeEndpoint } from "./contract_bridge-endpoint"
import { bridgeHelper } from "./contract_bridge-helper"
import { cofarmApowerHelper } from "./contract_cofarm-apower-helper"
import { collateralRebalancingPoolV1 } from "./contract_collateral-rebalancing-pool-v1"
import { dualFarmDikoHelper } from "./contract_dual-farm-diko-helper"
import { dualFarmingPool } from "./contract_dual-farming-pool"
import { executorDao } from "./contract_executor-dao"
import { fixedWeightPoolV101 } from "./contract_fixed-weight-pool-v1-01"
import { fwpAlexAutoalex } from "./contract_fwp-alex-autoalex"
import { fwpAlexUsda } from "./contract_fwp-alex-usda"
import { fwpAlexWban } from "./contract_fwp-alex-wban"
import { fwpAlexWslm } from "./contract_fwp-alex-wslm"
import { fwpWstxAlex5050V101 } from "./contract_fwp-wstx-alex-50-50-v1-01"
import { fwpWstxAlexTranched64 } from "./contract_fwp-wstx-alex-tranched-64"
import { fwpWstxWbtc5050V101 } from "./contract_fwp-wstx-wbtc-50-50-v1-01"
import { fwpWstxWmia5050V101 } from "./contract_fwp-wstx-wmia-50-50-v1-01"
import { fwpWstxWnycc5050V101 } from "./contract_fwp-wstx-wnycc-50-50-v1-01"
import { fwpWstxWxusd5050V101 } from "./contract_fwp-wstx-wxusd-50-50-v1-01"
import { keyAlexAutoalexV1 } from "./contract_key-alex-autoalex-v1"
import { lotteryClaimHelper } from "./contract_lottery-claim-helper"
import { multisigCrpAlexAutoalexV1 } from "./contract_multisig-crp-alex-autoalex-v1"
import { multisigFwpAlexWban } from "./contract_multisig-fwp-alex-wban"
import { multisigFwpAlexWslm } from "./contract_multisig-fwp-alex-wslm"
import { multisigFwpWstxAlex5050V101 } from "./contract_multisig-fwp-wstx-alex-50-50-v1-01"
import { multisigFwpWstxWbtc5050V101 } from "./contract_multisig-fwp-wstx-wbtc-50-50-v1-01"
import { multisigYtpAlex } from "./contract_multisig-ytp-alex"
import { redeemAutoHelper } from "./contract_redeem-auto-helper"
import { registerUserHelperV11 } from "./contract_register-user-helper-v1-1"
import { simpleWeightPoolAlex } from "./contract_simple-weight-pool-alex"
import { stakingHelper } from "./contract_staking-helper"
import { stxdxRegistry } from "./contract_stxdx-registry"
import { stxdxSenderProxy } from "./contract_stxdx-sender-proxy"
import { stxdxWalletZero } from "./contract_stxdx-wallet-zero"
import { swapHelper } from "./contract_swap-helper"
import { swapHelperV101 } from "./contract_swap-helper-v1-01"
import { swapHelperV102 } from "./contract_swap-helper-v1-02"
import { swapHelperV103 } from "./contract_swap-helper-v1-03"
import { tokenAmmSwapPool } from "./contract_token-amm-swap-pool"
import { tokenApower } from "./contract_token-apower"
import { tokenWban } from "./contract_token-wban"
import { tokenWbtc } from "./contract_token-wbtc"
import { tokenWmia } from "./contract_token-wmia"
import { tokenWnycc } from "./contract_token-wnycc"
import { tokenWslm } from "./contract_token-wslm"
import { tokenWstx } from "./contract_token-wstx"
import { tokenWusda } from "./contract_token-wusda"
import { tokenWxusd } from "./contract_token-wxusd"
import { yieldAlexV1 } from "./contract_yield-alex-v1"
import { yieldTokenPool } from "./contract_yield-token-pool"
import { ytpAlexV1 } from "./contract_ytp-alex-v1"

export const AlexContracts = defineContract({
  ...alexReservePool,
  ...tokenWban,
  ...tokenApower,
  ...age000GovernanceToken,
  ...alexLaunchpadV11,
  ...fixedWeightPoolV101,
  ...simpleWeightPoolAlex,
  ...swapHelper,
  ...fwpWstxAlex5050V101,
  ...fwpWstxWbtc5050V101,
  ...multisigFwpWstxWbtc5050V101,
  ...multisigFwpWstxAlex5050V101,
  ...tokenWstx,
  ...tokenWbtc,
  ...stakingHelper,
  ...multisigFwpAlexWban,
  ...fwpAlexWban,
  ...alexVault,
  ...fwpAlexUsda,
  ...tokenWusda,
  ...dualFarmingPool,
  ...swapHelperV101,
  ...dualFarmDikoHelper,
  ...autoAlex,
  ...fwpAlexAutoalex,
  ...multisigFwpAlexWslm,
  ...fwpAlexWslm,
  ...tokenWslm,
  ...tokenWxusd,
  ...fwpWstxWxusd5050V101,
  ...autoFwpWstxAlex120X,
  ...autoYtpAlex,
  ...autoYieldAlex,
  ...autoKeyAlexAutoalex,
  ...yieldAlexV1,
  ...keyAlexAutoalexV1,
  ...ytpAlexV1,
  ...collateralRebalancingPoolV1,
  ...yieldTokenPool,
  ...multisigCrpAlexAutoalexV1,
  ...multisigYtpAlex,
  ...tokenWmia,
  ...fwpWstxWmia5050V101,
  ...tokenWnycc,
  ...fwpWstxWnycc5050V101,
  ...bridgeHelper,
  ...bridgeEndpoint,
  ...autoalexApowerHelper,
  ...swapHelperV102,
  ...swapHelperV103,
  ...autoFwpAlexAutoalexXV101,
  ...fwpWstxAlexTranched64,
  ...cofarmApowerHelper,
  ...alexLottery,
  ...lotteryClaimHelper,
  ...executorDao,
  ...age003EmergencyExecute,
  ...stxdxRegistry,
  ...stxdxWalletZero,
  ...stxdxSenderProxy,
  ...redeemAutoHelper,
  ...registerUserHelperV11,
  ...age009TokenLock,
  ...ammSwapPool,
  ...tokenAmmSwapPool,
})
