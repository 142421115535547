import { defineMessage } from "react-intl"

export const connectWalletPrompt$t = defineMessage({
  defaultMessage: "Connect your Stacks wallet",
  description: "Common/connect wallet prompt text",
})
export const connectWallet$t = defineMessage({
  defaultMessage: "Connect Wallet",
  description: "Common/connect wallet button text",
})

export const clear$t = defineMessage({
  defaultMessage: "Clear",
  description: "Common/clear form content button text",
})

export const cancel$t = defineMessage({
  defaultMessage: "Cancel",
  description: "Common/cancel button text",
})

export const accept$t = defineMessage({
  defaultMessage: "Accept",
  description: "Common/confirm button text",
})

export const confirm$t = defineMessage({
  defaultMessage: "Confirm",
  description: "Common/confirm button text",
})

export const save$t = defineMessage({
  defaultMessage: "Save",
  description: "Common/save button text",
})

export const copied$t = defineMessage({
  defaultMessage: "Copied",
  description: 'Common/notification text "Copied"',
})
