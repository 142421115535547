import { map, Observable } from "rxjs"
import { ORDER_BOOK_REFRESH_INTERVAL } from "../../../../config"
import { sendPublicRequest } from "../../../../generated/stxdxHelpers/stxdxApi"
import { intervalFetch } from "../../../../utils/wonkaHelpers/intervalFetch"
import { StxDxMarket } from "../OrderbookStore.service/OrderbookStore.service"

export type MarketSummary = {
  highestPrice: number
  lastPrice: number
  lowestPrice: number
  price24hChangePercent: number
  price24hChange: number
  volume24h: number
  amount24h: number
}

export function fetchMarketSummary(
  market: StxDxMarket,
): Observable<MarketSummary> {
  return intervalFetch(ORDER_BOOK_REFRESH_INTERVAL, () =>
    sendPublicRequest("OrderController_getTradeOverview", {
      path: { market: market },
    }),
  ).pipe(
    map(resp => {
      const {
        data: {
          highest_price,
          last_price,
          lowest_price,
          price_24h_change_percent,
          price_24h_change,
          volume_24h,
          amount_24h,
        },
      } = resp
      return {
        highestPrice: Number(highest_price),
        lastPrice: Number(last_price),
        lowestPrice: Number(lowest_price),
        price24hChangePercent: Number(price_24h_change_percent),
        price24hChange: Number(price_24h_change),
        volume24h: Number(volume_24h),
        amount24h: Number(amount_24h),
      }
    }),
  )
}
