import { FC } from "react"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteFilledButton } from "../../../../components/button/variants/WhiteFilledButton"
import { CardPlate } from "../../../../components/Card"
import { CardModalContent } from "../../../../components/CardModal/CardModal"
import { NavLink } from "../../../../components/NavLink"
import { PercentNumber } from "../../../../components/PercentNumber"

export const BetterPricesInSwap: FC<{
  discountPercentage: number
  swapLink: string
  onProceed: () => void
  onClose?: () => void
}> = props => {
  return (
    <CardModalContent
      onClose={props.onClose}
      title={"Better deal on staking"}
      className={"flex flex-col gap-4"}
      width={480}
    >
      <CardPlate className="text-gray-200 text-center">
        You can get a{" "}
        <strong className="text-green-600 text-xl font-bold">
          <PercentNumber number={props.discountPercentage} />
        </strong>{" "}
        discount by
        <br /> <strong>Swapping atALEX</strong> rather than{" "}
        <strong>staking directly</strong>
      </CardPlate>
      <NavLink to={props.swapLink}>
        <GradientFilledButton>Go and Swap</GradientFilledButton>
      </NavLink>
      <WhiteFilledButton onClick={props.onProceed}>
        Close and Proceed
      </WhiteFilledButton>
    </CardModalContent>
  )
}

export const BetterHarvestAndSwap: FC<{
  discountPercentage: number
  onClose: () => void
  onOnlyHarvest: () => void
  onHarvestAndAutoStake: () => void
}> = props => {
  return (
    <CardModalContent
      onClose={props.onClose}
      title={"Better deal on staking"}
      className={"flex flex-col gap-4"}
      width={480}
    >
      <CardPlate className="text-gray-200 text-center">
        You can get a{" "}
        <strong className="text-green-600 text-xl font-bold">
          <PercentNumber number={props.discountPercentage} />
        </strong>{" "}
        discount by
        <br /> <strong>Swapping atALEX</strong> after{" "}
        <strong>Only Harvest</strong> rather than{" "}
        <strong>Autostaking directly</strong>
      </CardPlate>
      <GradientFilledButton onClick={props.onOnlyHarvest}>
        Only Harvest
      </GradientFilledButton>
      <WhiteFilledButton onClick={props.onHarvestAndAutoStake}>
        Harvest and AutoStake
      </WhiteFilledButton>
    </CardModalContent>
  )
}
