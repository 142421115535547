import clsx from "clsx"
import { FC } from "react"
import { TokenInfo } from "../utils/models/TokenInfo"

export interface TokenNameProps {
  className?: string
  token: TokenInfo
}

export const TokenName: FC<TokenNameProps> = props => {
  return (
    <span className={clsx(props.className)}>
      {formatTokenName(props.token)}
    </span>
  )
}
export const formatTokenName = (token: TokenInfo): string => {
  return token.displayName
}
