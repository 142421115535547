import { motion } from "framer-motion"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../../components/InfoList"
import { LoadingIndicator } from "../../../../../components/LoadingIndicator/LoadingIndicator"
import { PercentNumber } from "../../../../../components/PercentNumber"
import { Slider } from "../../../../../components/Slider"
import { Spensor } from "../../../../../components/Spensor"
import { SwitchLabel, ThumbSwitch } from "../../../../../components/ThumbSwitch"
import {
  Block,
  BlockTokenLine,
} from "../../../../../components/TokenInput/Block"
import {
  BlockGroup,
  BlockGroupDownArrowIcon,
} from "../../../../../components/TokenInput/BlockGroup"
import { AnimatePresence } from "../../../../../utils/animateHelpers/AnimatePresence"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { plural } from "../../../../../utils/stringHelpers"
import {
  readResource,
  safeReadResource,
  SuspenseResource,
} from "../../../../../utils/SuspenseResource"
import { AddStakeSectionInfoList } from "../../../components/AddStakeSectionInfoList"
import { DetailPlate } from "../../../components/DetailPlate/DetailPlate"
import { StakeSubmissionSummary } from "../../../manualStakeComponents/StakeSubmissionSummary"
import { OnSwitchFormModeFn, StakeForm } from "../types"
import { AlexTokenInput } from "./AlexTokenInput"
import {
  collapseAnimationProps,
  showhideAnimationProps,
} from "./animationHelpers"
import { CycleCountSlider } from "./CycleCountSlider"

export const renderManualModeForm = (props: {
  gapClassName?: string
  alexToken: TokenInfo
  form: StakeForm.Manual
  customizeEnabled?: boolean
  onCustomizeEnableChanged?: (enabled: boolean) => void
  onSwitchFormMode?: OnSwitchFormModeFn
  isTokenInputShouldBeErrorState?: SuspenseResource<boolean>
}): JSX.Element => {
  return (
    <>
      <AnimatePresence initial={false}>
        {BlockGroup({
          icon: (
            <motion.div animate={{ opacity: 0 }} transition={{ duration: 0.1 }}>
              <BlockGroupDownArrowIcon />
            </motion.div>
          ),
          firstBlock: (
            <AlexTokenInput
              alexToken={props.alexToken}
              alexTokenCount={props.form.alexTokenCount}
              setAlexTokenCount={props.form.setAlexTokenCount}
              alexTokenCountToUSD={props.form.alexTokenCountToUSD}
              alexBalance={props.form.alexBalance}
              isTokenInputShouldBeErrorState={
                props.isTokenInputShouldBeErrorState
              }
            />
          ),
          secondBlock: (
            <motion.div
              layoutId={"blockGroupSecond"}
              transition={{ duration: 0.1 }}
              className={"w-full"}
            >
              <Block className={"w-full"} boxClassName={"p-3 sm:px-6 sm:py-4"}>
                <motion.div
                  key={"ManualModeSecondBlockContent"}
                  {...showhideAnimationProps}
                >
                  <BlockTokenLine
                    tokenNameArea={
                      <span className={"text-base text-gray-200"}>
                        Staking for
                      </span>
                    }
                    tokenCountArea={
                      <div className={"flex flex-col text-right"}>
                        <p className={"text-xl font-medium text-gray-200"}>
                          {props.form.cycleCount}{" "}
                          {plural(props.form.cycleCount, {
                            one: "Cycle",
                            many: "Cycles",
                          })}
                        </p>
                        <p className={"text-sm text-gray-500"}>
                          Estimate{" "}
                          <Spensor fallback={"-"}>
                            {() => {
                              const dayCount = readResource(
                                props.form.estimateDayCount,
                              )
                              return dayCount < 1 ? "<1" : dayCount
                            }}
                          </Spensor>{" "}
                          {plural(
                            Math.max(
                              safeReadResource(props.form.estimateDayCount) ??
                                0,
                              1,
                            ),
                            {
                              one: "day",
                              many: "days",
                            },
                          )}
                        </p>
                      </div>
                    }
                  />
                </motion.div>
              </Block>
            </motion.div>
          ),
        })}
      </AnimatePresence>

      <InfoList direction={"column"} listItemDirection={"row-responsive"}>
        <InfoListItem>
          <InfoListItemTitle>APR</InfoListItemTitle>
          <InfoListItemDetail>
            <Spensor fallback={"-"}>
              {() => <PercentNumber number={readResource(props.form.apr)} />}
            </Spensor>
          </InfoListItemDetail>
        </InfoListItem>

        <InfoListItem>
          <InfoListItemTitle>Staking mode</InfoListItemTitle>
          <InfoListItemDetail>
            <ThumbSwitch
              checked={props.customizeEnabled ?? false}
              onCheckedChange={props.onCustomizeEnableChanged}
              right={<SwitchLabel>Customize</SwitchLabel>}
            />
          </InfoListItemDetail>
        </InfoListItem>
      </InfoList>

      <AnimatePresence initial={false}>
        {props.customizeEnabled && (
          <motion.div {...collapseAnimationProps}>
            {props.form.maxCycleCount == null ? (
              <CycleCountSlider
                value={props.form}
                onChange={props.onSwitchFormMode}
              />
            ) : (
              <Slider
                min={1}
                max={props.form.maxCycleCount}
                value={props.form.cycleCount}
                onChange={v => props.form.setCycleCount?.(v)}
              />
            )}
          </motion.div>
        )}
      </AnimatePresence>

      <DetailPlate
        gapClassName={props.gapClassName}
        title={"Staking Details"}
        collapseAnimationProps={collapseAnimationProps}
      >
        <motion.div key={"ManualPlateContent"} {...showhideAnimationProps}>
          <Spensor
            fallback={
              <AddStakeSectionInfoList
                className={"flex items-center justify-center p-6"}
              >
                <LoadingIndicator />
              </AddStakeSectionInfoList>
            }
          >
            {() => (
              <StakeSubmissionSummary
                startedAtBlock={readResource(props.form.startedAtBlock)}
                endedAtBlock={readResource(props.form.endedAtBlock)}
                startedAtCycleNumber={readResource(
                  props.form.startedAtCycleNumber,
                )}
                endedAtCycleNumber={readResource(props.form.endedAtCycleNumber)}
              />
            )}
          </Spensor>
        </motion.div>
      </DetailPlate>
    </>
  )
}
