import { gql } from "@urql/core"
import { memoize } from "lodash"
import { action, computed, makeObservable, observable } from "mobx"
import { CONTRACT_DEPLOYER } from "../../../config"
import {
  FetchLotteryRecordsQuery,
  FetchLotteryRecordsQueryVariables,
} from "../../../generated/graphql/graphql.generated"
import { currentContractName } from "../../../generated/smartContractHelpers/asSender"
import { LazyValue } from "../../../stores/LazyValue/LazyValue"
import { gqlQuery } from "../../../utils/graphqlHelpers"
import { fromUrqlSource } from "../../../utils/Observable/fromUrqlSource"
import { suspenseResource } from "../../../utils/SuspenseResource"
import type {
  LotteryRecord,
  LotteryRecordsTableProps,
} from "../component/LotteryRecordsTable"
import LaunchPadContractStore from "./LaunchPadContractStore"
import { idoWalkResolution } from "./LaunchPadContractStore.service"

export class ValidateListViewModule implements LotteryRecordsTableProps {
  constructor(private readonly store: LaunchPadContractStore) {
    makeObservable(this)
  }

  @observable currentPage = 0

  recordCountPerPage = 20

  @computed get recordCountTotal(): number {
    return this.requests(0).value$.total
  }

  private requests = memoize(
    (page: number) =>
      new LazyValue(
        () =>
          [
            this.store.idoId,
            page * this.recordCountPerPage,
            this.recordCountPerPage,
          ] as const,
        ([idoId, offset, limit]) =>
          fromUrqlSource(
            gqlQuery<
              FetchLotteryRecordsQuery,
              FetchLotteryRecordsQueryVariables
            >(
              gql`
                query FetchLotteryRecords(
                  $offset: Int!
                  $contractId: String!
                  $idoId: Int!
                  $limit: Int!
                ) {
                  laplace_ido_register_aggregate(
                    where: {
                      ido_id: { _eq: $idoId }
                      contract_call_contract_id: { _eq: $contractId }
                    }
                  ) {
                    aggregate {
                      count
                    }
                  }

                  laplace_ido_register(
                    where: {
                      ido_id: { _eq: $idoId }
                      contract_call_contract_id: { _eq: $contractId }
                    }
                    order_by: { registered_start: asc }
                    offset: $offset
                    limit: $limit
                  ) {
                    registered_start
                    registered_end
                    block_height
                    sender_address
                  }
                }
              `,
              {
                contractId: `${CONTRACT_DEPLOYER}.${currentContractName(
                  "alex-launchpad-v1-1",
                )}`,
                offset,
                idoId,
                limit,
              },
            ),
            ({ data }) => ({
              total: data.laplace_ido_register_aggregate.aggregate!.count,
              records: data.laplace_ido_register.map(n => ({
                lotteryStartId: Number(n.registered_start) / idoWalkResolution,
                lotteryEndId: Number(n.registered_end) / idoWalkResolution,
                block: n.block_height,
                walletAddress: n.sender_address,
              })),
            }),
          ),
      ),
  )

  @computed get records(): LotteryRecord[] {
    return this.requests(this.currentPage).value$.records.map(r => ({
      ...r,
      wonTicketIds: suspenseResource(() => {
        if (!this.store.claimViewModule.claimStarted$) {
          return null
        }
        return this.store.claimViewModule.wonTicketIds$.filter(
          t => t >= r.lotteryStartId && t < r.lotteryEndId,
        )
      }),
    }))
  }

  @action onChangePage(info: { page: number }): void {
    this.currentPage = info.page
  }
}

export default ValidateListViewModule
