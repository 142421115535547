import { bufferCVFromString, FungibleConditionCode } from "@stacks/transactions"
import { asSender } from "../../../generated/smartContractHelpers/asSender"
import { components } from "../../../generated/wrapBridge/types"
import { sendRequest } from "../../../generated/wrapBridgeHelpers/wrapBridgeApi"
import { TransferFn } from "../../../stores/authStore/MetaMaskModule"
import { SignTypedDataFn } from "../../../stores/authStore/MetaMaskModule.service"
import { Currency } from "../../../utils/alexjs/Currency"
import { currencyScale } from "../../../utils/alexjs/currencyHelpers"
import { transfer } from "../../../utils/alexjs/postConditions"
import { DisplayableError } from "../../../utils/error"
import { assertNever } from "../../../utils/types"
import {
  BridgeChain,
  isUnwrappableETHBridgeChain,
  isWrappableETHBridgeChain,
} from "../types/BridgeChain"
import {
  BridgeCurrency,
  isUnwrappableBridgeCurrency,
  isWrappableBridgeCurrency,
  parseBridgeCurrencyServerBigIntAmount,
  toBridgeCurrencyServerBigIntAmount,
  UnwrappableBridgeCurrency,
  WrappableBridgeCurrency,
} from "./utils/BridgeCurrency"
import { ETHCurrency } from "./utils/ETHCurrency"

export interface TransferProphet {
  wrapCostedMilliseconds: number
  wrapFeeToken: BridgeCurrency
  wrapFeeTokenCount: number
  targetTokenCount: number
}
const serializedToTransferProphet = (
  serverData: components["schemas"]["CreateTransferProphetsResponse"],
  targetCurrency: BridgeCurrency,
): TransferProphet => {
  const wrapFeeToken =
    serverData.wrap_fee_token === "usdc"
      ? ETHCurrency.USDC
      : serverData.wrap_fee_token === "xusd"
      ? Currency.W_XUSD
      : assertNever(serverData.wrap_fee_token)

  return {
    wrapCostedMilliseconds: serverData.wrap_costed_milliseconds,
    wrapFeeToken,
    wrapFeeTokenCount: parseBridgeCurrencyServerBigIntAmount(
      serverData.bigint_wrap_fee_token_count,
      wrapFeeToken,
    ),
    targetTokenCount: parseBridgeCurrencyServerBigIntAmount(
      serverData.bigint_target_token_count,
      targetCurrency,
    ),
  }
}

export const createTransferProphet = async (
  fromNetwork: BridgeChain,
  toNetwork: BridgeChain,
  fromCurrency: BridgeCurrency,
  toCurrency: BridgeCurrency,
  amount: number,
): Promise<TransferProphet> => {
  let resp: { data: components["schemas"]["CreateTransferProphetsResponse"] }

  if (
    fromNetwork === BridgeChain.Stacks &&
    isUnwrappableETHBridgeChain(toNetwork) &&
    isUnwrappableBridgeCurrency(fromCurrency)
  ) {
    resp = await sendRequest("TokenBridgeController_createTransferProphet", {
      body: {
        info: {
          source_network: "stacks",
          target_network: "ethereum",
          source_token:
            fromCurrency === Currency.W_XUSD
              ? "xusd"
              : assertNever(fromCurrency),
          bigint_source_token_count: toBridgeCurrencyServerBigIntAmount(
            amount,
            fromCurrency,
          ),
        },
      },
    })
  } else if (
    isWrappableETHBridgeChain(fromNetwork) &&
    toNetwork === BridgeChain.Stacks &&
    isWrappableBridgeCurrency(fromCurrency)
  ) {
    resp = await sendRequest("TokenBridgeController_createTransferProphet", {
      body: {
        info: {
          source_network: "ethereum",
          target_network: "stacks",
          source_token:
            fromCurrency === ETHCurrency.USDC
              ? "usdc"
              : assertNever(fromCurrency),
          bigint_source_token_count: toBridgeCurrencyServerBigIntAmount(
            amount,
            fromCurrency,
          ),
        },
      },
    })
  } else {
    throw new DisplayableError(
      `Unsupported wrap/unwrap request: ${fromCurrency} ${fromNetwork}->${toNetwork}`,
    )
  }

  return serializedToTransferProphet(resp.data, toCurrency)
}

export const wrapStacksToken = async (
  transfer: TransferFn,
  signTypedData: SignTypedDataFn,
  currency: WrappableBridgeCurrency,
  stxAddress: string,
  amount: number,
): Promise<string> => {
  const {
    data: { receive_address: bridgeAddress },
  } = await sendRequest("TokenBridgeController_createTransferPortal", {
    body: {
      info: {
        source_token:
          currency === ETHCurrency.USDC ? "usdc" : assertNever(currency),
        bigint_source_token_count: toBridgeCurrencyServerBigIntAmount(
          amount,
          currency,
        ),
        source_network: "ethereum",
        target_network: "stacks",
      },
    },
  })

  const resp = await transfer(currency, bridgeAddress, amount)

  const signedMessage = await signWrapTokenMessage(
    signTypedData,
    stxAddress,
    resp.hash,
  )

  await sendRequest("SignedMessageController_createSignedMessage", {
    body: {
      txId: resp.hash,
      toAddress: stxAddress,
      signedMessage,
    },
  })

  return resp.hash
}

export const unwrapStacksToken = async (
  contractAssignedTargetChainId: number,
  currency: UnwrappableBridgeCurrency,
  stxAddress: string,
  ethAddress: string,
  amount: number,
): Promise<string> => {
  const {
    data: { receive_address: bridgeAddress },
  } = await sendRequest("TokenBridgeController_createTransferPortal", {
    body: {
      info: {
        source_token:
          currency === Currency.W_XUSD ? "xusd" : assertNever(currency),
        source_network: "stacks",
        target_network: "ethereum",
        bigint_source_token_count: toBridgeCurrencyServerBigIntAmount(
          amount,
          currency,
        ),
      },
    },
  })

  const resp = await asSender(stxAddress)
    .contract("bridge-helper")
    .func("transfer-to-unwrap")
    .call(
      {
        "token-trait": currency,
        "amount-in-fixed": amount * currencyScale(currency),
        recipient: bridgeAddress,
        "chain-id": contractAssignedTargetChainId,
        "settle-address": bufferCVFromString(ethAddress).buffer,
      },
      [transfer(stxAddress, currency, amount, FungibleConditionCode.Equal)],
    )

  return resp.txId
}

export async function signWrapTokenMessage(
  signTypedData: SignTypedDataFn,
  toSTXAddress: string,
  transactionHash: string,
): Promise<string> {
  const typedData = {
    types: {
      Message: [
        { name: "txId", type: "string" },
        { name: "toAddress", type: "string" },
      ],
    },
    domain: {
      name: "Stacks Bridge",
      version: "1",
    },
    message: {
      txId: transactionHash,
      toAddress: toSTXAddress,
    },
  }

  return await signTypedData(
    typedData.domain,
    typedData.types,
    typedData.message,
  )
}
