import { forwardRef, useImperativeHandle, useState } from "react"
import { useContentfulLongText } from "../../generated/i18nHelpers/useContentfulLongText"
import { useLongTextStore } from "../../stores/longTextStore/useLongTextStore"
import { Modal } from "../Modal"
import { NewTokenomicsModalContent } from "./NewTokenomicsModalContent"

export interface WiredNewTokenomicsModalRefValue {
  show: () => void
}

export const WiredNewTokenomicsModal = forwardRef<
  WiredNewTokenomicsModalRefValue,
  // eslint-disable-next-line @typescript-eslint/ban-types
  {}
>((props, ref) => {
  const store = useLongTextStore()

  const markdownContent = useContentfulLongText(
    () => store.newTokenomicsIntro.value$,
  )

  const [dialogVisible, setDialogVisible] = useState(false)

  useImperativeHandle(
    ref,
    () => ({
      show() {
        setDialogVisible(true)
      },
    }),
    [],
  )

  return (
    <Modal visible={dialogVisible} onClose={() => setDialogVisible(false)}>
      <NewTokenomicsModalContent
        markdownContent={markdownContent}
        onClose={() => setDialogVisible(false)}
      />
    </Modal>
  )
})
