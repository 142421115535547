import { sum } from "lodash"
import { computed, makeObservable } from "mobx"
import { CONTRACT_DEPLOYER } from "../../../config"
import { currentContractName } from "../../../generated/smartContractHelpers/asSender"
import AccountStore from "../../../stores/accountStore/AccountStore"
import AuthStore from "../../../stores/authStore/AuthStore"
import { ChainStore } from "../../../stores/chainStore/ChainStore"
import CurrencyStore from "../../../stores/currencyStore/CurrencyStore"
import { LazyValue } from "../../../stores/LazyValue/LazyValue"
import { pMemoizeDecorator } from "../../../stores/LazyValue/pMemoizeDecorator"
import { Currency } from "../../../utils/alexjs/Currency"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { APowerRuleRange } from "../component/types"
import ClaimViewModule from "./ClaimViewModule"
import { IDOStepsModule } from "./IDOStepsModule"
import type {
  LaunchPadDetail,
  LaunchToken,
  PaymentToken,
  TicketToken,
} from "./LaunchPadContractStore.service"
import {
  fetchLaunchPad,
  fetchLaunchPadCurrentRegistered,
} from "./LaunchPadContractStore.service"
import LaunchPadRegisterModule from "./LaunchPadRegisterModule"
import { TokenProfileViewModule } from "./TokenProfileViewModule"
import UserIDOStatusViewModule from "./UserIDOStatusViewModule"
import ValidateListViewModule from "./ValidateListViewModule"

export enum LaunchingStatus {
  Upcoming = "Upcoming",
  Registration = "Registration",
  Claim = "Claim",
  Finished = "Finished",
}

class LaunchPadContractStore {
  constructor(
    readonly idoId: number,
    readonly authStore: Pick<AuthStore, "stxAddress$" | "currentBlockHeight$">,
    readonly accountStore: Pick<AccountStore, "getBalance$" | "transactions$">,
    readonly currencyStore: Pick<CurrencyStore, "getPrice$" | "getTokenInfo$">,
    readonly chainStore: Pick<
      ChainStore,
      "estimatedDateForBlock$" | "currentBlockHeight$"
    >,
  ) {
    makeObservable(this)

    this.steps = new IDOStepsModule(this, this.authStore, this.chainStore)
  }

  steps: IDOStepsModule
  register = new LaunchPadRegisterModule(this)
  tokenProfileViewModule = new TokenProfileViewModule(this)
  userIDOViewModule = new UserIDOStatusViewModule(this)
  claimViewModule = new ClaimViewModule(this)
  validateListViewModule = new ValidateListViewModule(this)
  lotteryListViewModule = new ValidateListViewModule(this)

  private detailFromAPI = new LazyValue(
    () => [this.idoId, this.authStore.currentBlockHeight$] as const,
    ([token]) => fetchLaunchPad(token),
    { decorator: pMemoizeDecorator({ persistKey: "launchPadDetail" }) },
  )

  currentActivation = new LazyValue(
    () => [this.idoId, this.authStore.currentBlockHeight$] as const,
    ([ido]) => fetchLaunchPadCurrentRegistered(ido),
  )

  @computed get totalTicketWon$(): number {
    if (
      this.steps.currentStatus$ === LaunchingStatus.Upcoming ||
      this.steps.currentStatus$ === LaunchingStatus.Registration
    ) {
      return 0
    }
    return this.claimViewModule.wonTicketIds$.length
  }

  @computed get detail$(): LaunchPadDetail {
    return this.detailFromAPI.value$
  }

  @computed get token$(): LaunchToken {
    return this.detail$["ido-token-contract"] as LaunchToken
  }

  @computed get ticketToken$(): TicketToken {
    return Currency.APOWER
  }

  @computed get priceToken$(): PaymentToken {
    return this.detail$["payment-token-contract"] as PaymentToken
  }

  @computed get tokenInfo$(): TokenInfo {
    return this.currencyStore.getTokenInfo$(this.token$)
  }

  @computed get ticketTokenInfo$(): TokenInfo {
    return this.currencyStore.getTokenInfo$(this.ticketToken$)
  }

  @computed get priceTokenInfo$(): TokenInfo {
    return this.currencyStore.getTokenInfo$(this.priceToken$)
  }

  @computed get totalTicketCount$(): number {
    return this.detail$["total-tickets"]
  }

  @computed get currentBlock$(): number {
    return this.authStore.currentBlockHeight$
  }

  @computed get activationProgress$(): number {
    return this.currentActivation.value$ / this.activationThreshold$
  }

  @computed get activationThreshold$(): number {
    return this.detail$["activation-threshold"]
  }

  @computed get idoTokenCountPerTicket$(): number {
    return this.detail$["ido-tokens-per-ticket"]
  }

  @computed get pricePerTicket$(): number {
    return this.detail$["price-per-ticket-in-fixed"] / 1e8
  }

  @computed get apowerRanges$(): APowerRuleRange[] {
    const details = this.detail$["apower-per-ticket-in-fixed"]
    const maxTicket = this.detail$["registration-max-tickets"]
    return details
      .map((a, i) => {
        const prev = sum(details.slice(0, i).map(a => a["tier-threshold"]))
        return {
          start: prev + 1,
          end: Math.min(maxTicket, prev + a["tier-threshold"]),
          amount: a["apower-per-ticket-in-fixed"] / 1e8,
        }
      })
      .filter(a => a.start < maxTicket)
  }

  hasPendingMemPoolTx = new LazyValue(
    () => [this.accountStore.transactions$] as const,
    ([transactions]) =>
      transactions
        .fetchMemPoolTransactions()
        .then(a =>
          a.some(
            v =>
              v["tx_type"] === "contract_call" &&
              v["contract_call"]["contract_id"] ===
                `${CONTRACT_DEPLOYER}.${currentContractName(
                  "alex-launchpad-v1-1",
                )}` &&
              v["contract_call"]["function_name"] === "register",
          ),
        ),
  )

  @computed get hasPendingMemPoolTx$(): boolean {
    return this.hasPendingMemPoolTx.value$
  }
}

export default LaunchPadContractStore
