import clsx from "clsx"
import { groupBy } from "lodash"
import React, { FC } from "react"
import { Outlet } from "react-router-dom"
import { OpacityButton } from "../components/button/variants/OpacityButton"
import { Dropdown, DropdownItem } from "../components/Dropdown"
import { NavLink } from "../components/NavLink"
import { PageStackProvider } from "../components/PageStack/PageStackProvider"
import { ReactComponent as AlexLogo } from "../components/TopNavBar/alexLogo.svg"
import { ReactComponent as AlexLogoText } from "../components/TopNavBar/alexLogoText.svg"
import { MenuButton } from "../components/TopNavBar/MenuButton/MenuButton"
import { navbarButtonPresetStyle } from "../components/TopNavBar/navbarButtonPresetStyle"
import {
  NavBarButtonItem,
  NavBarDropdownItem,
  NavBarDropdownItemData,
  NavBarItemData,
} from "../components/TopNavBar/NavBarItem"
import { usePathGenerator } from "../routes/routes"
import { useAppEnvStore } from "../stores/appEnvStore/useAppEnvStore"

const WiredTopNavBar: FC = () => {
  const appEnvStore = useAppEnvStore()
  const gen = usePathGenerator()
  const tabs = useWiredNavBarItemDataArray()

  return (
    <div
      className={clsx(
        "flex items-center min-h-[5rem] py-5 px-4 sm:px-8 backdrop-blur-lg space-between gap-1",
      )}
      style={{ background: "rgba(17, 24, 39, 0.2)" }}
    >
      <NavLink
        className={"flex items-center cursor-pointer gap-1.5"}
        to={gen.index()}
      >
        <AlexLogo width={32} height={32} />
        <AlexLogoText width={106} height={28} />
      </NavLink>

      <div className={"flex-1"}>
        <div className={"mx-[38px] justify-start hidden lg:flex"}>
          {tabs.map((tab, idx) =>
            tab.type === "dropdown" ? (
              <NavBarDropdownItem key={idx} item={tab} />
            ) : (
              <NavBarButtonItem key={idx} item={tab} />
            ),
          )}
        </div>
      </div>

      <div className={"flex gap-0.5 sm:gap-6 items-center"}>
        <div className="flex items-center gap-2">
          <NavLink to={gen.swap()}>
            <OpacityButton
              {...navbarButtonPresetStyle}
              boxClassName={"px-9 py-2"}
            >
              APP
            </OpacityButton>
          </NavLink>
        </div>
        <Dropdown
          placement={"end"}
          trigger={<MenuButton className="lg:hidden" />}
        >
          {appEnvStore.config$.analyticsTabs.map(tab => (
            <NavLink key={tab.urlSlug} to={gen.analyticsDetail(tab.urlSlug)}>
              <DropdownItem>{tab.title}</DropdownItem>
            </NavLink>
          ))}
        </Dropdown>
      </div>
    </div>
  )
}

const useWiredNavBarItemDataArray = (): NavBarItemData[] => {
  const appEnvStore = useAppEnvStore()

  const rawTabs: NavBarItemData[] = appEnvStore.config$.analyticsTabs.map(
    t => ({
      type: "button",
      name: t.title,
      link: gen => gen.analyticsDetail(t.urlSlug),
    }),
  )

  const { pool: poolTabs, rest: restTabs } = groupBy(rawTabs, t =>
    t.name.toLowerCase().endsWith(" pool") ? "pool" : "rest",
  )

  if (poolTabs != null) {
    return (restTabs ?? ([] as NavBarItemData[])).concat({
      type: "dropdown",
      name: "Pools",
      buttons: poolTabs,
    } as NavBarDropdownItemData)
  } else {
    return restTabs ?? []
  }
}

export const AnalyticsLayout: FC = () => {
  return (
    <PageStackProvider>
      <div className={"h-screen flex flex-col"}>
        <WiredTopNavBar />

        <Outlet />
      </div>
    </PageStackProvider>
  )
}
