import { FC } from "react"
import { BorrowRowData } from "../../../../store/transformerGroups/lend/borrow"
import { AllTokenInfos } from "../../../../types"
import { BaseCellContainer } from "../../cells/BaseCellContainer"
import { OneLineAmountCell } from "../../cells/OneLineAmountCell"
import { OneLineCurrencyCell } from "../../cells/OneLineCurrencyCell"
import { BaseRow } from "../BaseRow"

export const BorrowRow: FC<{
  rowData: BorrowRowData
  allTokenInfos: AllTokenInfos
}> = ({ rowData, allTokenInfos }) => (
  <BaseRow
    rowData={rowData}
    currencyCell={
      <OneLineCurrencyCell
        fromCurrencyAmounts={[rowData.collateralCurrencyAmount]}
        toCurrencyAmounts={[rowData.tokenCurrencyAmount]}
        allTokenInfos={allTokenInfos}
      />
    }
    amountCell={
      <OneLineAmountCell
        fromCurrencyAmounts={[rowData.collateralCurrencyAmount]}
        toCurrencyAmounts={[rowData.tokenCurrencyAmount]}
        allTokenInfos={allTokenInfos}
        fromSuffix="(Collateral)"
        toSuffix="(Borrowed)"
      />
    }
    tradeInfoCell={<BaseCellContainer>Borrow</BaseCellContainer>}
  />
)
