import { last } from "lodash"
import { FC } from "react"
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory"
import { formatPercentNumber } from "../../../../../components/PercentNumber"
import { useElementSize } from "../../../../../utils/reactHelpers/useElementSize"
import { useLazyRef } from "../../../../../utils/reactHelpers/useLazyRef"
import { plural, uuid } from "../../../../../utils/stringHelpers"
import { CalcPositionLabel } from "../../../components/EarningsPreviewChartFrame"

const animateGraph = {
  duration: 500,
  onLoad: { duration: 500 },
}

export const lineStyles = {
  idealEarningsCurve: {
    width: 4,
    color: "#059669",
  },
  estimatedEarningsCurve: {
    width: 2,
    color: "#7E8597",
    opacity: 0.5,
  },
  userSelectedEarningCurve: {
    width: 4,
    color: "#4F46E5",
  },
} as const

export interface CurvePoint {
  cycle: number
  apr: number
}

export interface EarningsPreviewChartProps {
  maxSelectableCycleCount: number
  userSelectCycleCount: number
  idealEarningsCurve: CurvePoint[]
  estimatedEarningsCurve: CurvePoint[]
  gapCurve: CurvePoint[]
}

export const EarningsPreviewChart: FC<EarningsPreviewChartProps> = ({
  idealEarningsCurve,
  estimatedEarningsCurve,
  gapCurve,
  maxSelectableCycleCount,
  userSelectCycleCount,
}) => {
  const [containerRef, containerSize] = useElementSize<HTMLDivElement>()

  const userSelectedEarningCurve = estimatedEarningsCurve.filter(
    obj => obj.cycle <= userSelectCycleCount,
  )

  const minApr = Math.min(...idealEarningsCurve.map(i => i.apr), 0)
  const maxApr = Math.max(...idealEarningsCurve.map(i => i.apr), 0)

  const { current: gradientUuid } = useLazyRef(uuid)

  return (
    <div ref={containerRef} style={{ margin: "0 -40px" }}>
      <svg style={{ height: 0 }}>
        <defs>
          <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id={gradientUuid}>
            <stop stopColor="rgba(78, 79, 245, 0.5)" offset="0%" />
            <stop
              stopColor="rgba(78, 79, 245, 0)"
              stopOpacity="0"
              offset="100%"
            />
          </linearGradient>
        </defs>
      </svg>

      {/* Graph */}
      <VictoryChart
        padding={{ top: 0, right: 32, bottom: 0, left: 32 }}
        domain={{
          x: [1, maxSelectableCycleCount],
          y: [minApr, maxApr + (maxApr - minApr) * 0.2],
        }}
        containerComponent={
          <VictoryVoronoiContainer
            voronoiBlacklist={[
              "estimatedEarningsCurve",
              "userSelectedEarningCurve",
              "idealEarningsCurveScatter",
              "userSelectedEarningCurveScatter",
              ...gapCurve.map((c, idx) => `gapCurve-${idx}`),
            ]}
            labels={({ datum }) =>
              [
                `${datum.cycle} Cycles`,
                `APR=${formatPercentNumber(datum.apr)}`,
              ].join("\n")
            }
            labelComponent={<VictoryTooltip constrainToVisibleArea />}
          />
        }
      >
        {/* X-axis */}
        <VictoryAxis
          padding={{ bottom: 30 }}
          label="Cycles"
          tickFormat={() => ""}
          tickCount={idealEarningsCurve.length - 1}
          axisLabelComponent={
            <CalcPositionLabel
              angle={0}
              dy={-42}
              calcX={props => (props.x ?? 0) * 2 - 34}
              textAnchor={"end"}
              verticalAnchor={"start"}
              style={{ fontSize: 11, fill: "#E5E7EB" }}
            />
          }
          style={{
            axis: {
              stroke: "#7E8597",
              strokeWidth: 1,
            },
            grid: {
              stroke: "#E5E7EB",
              strokeWidth: 0.5,
              opacity: 0.25,
            },
          }}
        />

        {/* Y-axis */}
        <VictoryAxis
          dependentAxis
          label="Reward"
          tickFormat={() => ""}
          axisLabelComponent={
            <CalcPositionLabel
              angle={0}
              dx={30}
              calcY={() => 4}
              textAnchor={"start"}
              verticalAnchor={"start"}
              style={{ fontSize: 11, fill: "#E5E7EB" }}
            />
          }
          style={{
            axis: {
              stroke: "#7E8597",
              strokeWidth: 1,
            },
          }}
        />

        {/* Green line graph */}
        <VictoryLine
          name={"idealEarningsCurve"}
          data={idealEarningsCurve}
          x={"cycle"}
          y={"apr"}
          style={{
            data: {
              strokeWidth: lineStyles.idealEarningsCurve.width,
              stroke: lineStyles.idealEarningsCurve.color,
            },
          }}
          animate={animateGraph}
        />
        {/* Green plotted point */}
        <VictoryScatter
          name={"idealEarningsCurveScatter"}
          data={[last(idealEarningsCurve)]}
          x={"cycle"}
          y={"apr"}
          size={5}
          style={{
            data: {
              strokeWidth: 4,
              stroke: "#059669",
              width: 4,
              height: 4,
              fill: "#fff",
            },
            labels: { fontSize: 10, fontWeight: "bold", fill: "#00AA25" },
          }}
          labels={({ datum }) => [
            `${datum.cycle} Cycles`,
            `APR=${formatPercentNumber(datum.apr)}`,
          ]}
          labelComponent={
            <VictoryLabel
              style={[
                {
                  fontSize: 14,
                  lineHeight: "16px",
                  fill: "#00AA25",
                },
                {
                  fontSize: 10,
                  lineHeight: "12px",
                  fill: "#E5E7EB",
                  opacity: 0.5,
                },
              ]}
              backgroundPadding={[{ bottom: 4 }]}
              dy={-15}
              verticalAnchor={"end"}
            />
          }
        />

        {/* Dashed gray line graph */}
        <VictoryLine
          name={"estimatedEarningsCurve"}
          data={estimatedEarningsCurve}
          x={"cycle"}
          y={"apr"}
          style={{
            data: {
              stroke: lineStyles.estimatedEarningsCurve.color,
              strokeWidth: lineStyles.estimatedEarningsCurve.width,
              strokeDasharray: "10,5",
              opacity: lineStyles.estimatedEarningsCurve.opacity,
            },
          }}
          animate={animateGraph}
        />
        {/* Bar graph */}
        {gapCurve.map((point, idx) => (
          <VictoryArea
            name={`gapCurve-${idx}`}
            key={idx}
            data={[point, { cycle: point.cycle + 1, apr: point.apr }]}
            x={"cycle"}
            y={"apr"}
            style={{
              data: { fill: "#00AA25", opacity: 0.2 },
            }}
            animate={animateGraph}
          />
        ))}

        {/* Blue line graph */}
        <VictoryArea
          name={"userSelectedEarningCurve"}
          data={userSelectedEarningCurve}
          x={"cycle"}
          y={"apr"}
          style={{
            data: {
              strokeWidth: lineStyles.userSelectedEarningCurve.width,
              stroke: lineStyles.userSelectedEarningCurve.color,
              fill: `url(#${gradientUuid})`,
            },
          }}
          animate={animateGraph}
        />
        {/* Blue plotted point */}
        <VictoryScatter
          name={"userSelectedEarningCurveScatter"}
          data={[userSelectedEarningCurve[userSelectedEarningCurve.length - 1]]}
          x={"cycle"}
          y={"apr"}
          size={5}
          style={{
            data: {
              strokeWidth: 4,
              stroke: "#4F46E5",
              width: 4,
              height: 4,
              fill: "#fff",
            },
            labels: { fontSize: 10, fontWeight: "bold", fill: "#E5E7EB" },
          }}
          labels={({ datum }) => [
            `${datum.cycle} Cycles`,
            `APR=${formatPercentNumber(datum.apr)}`,
          ]}
          labelComponent={
            <VictoryLabel
              style={[
                {
                  fontSize: 14,
                  lineHeight: "16px",
                  fill: "#E5E7EB",
                },
                {
                  fontSize: 10,
                  lineHeight: "12px",
                  fill: "#E5E7EB",
                  opacity: 0.5,
                },
              ]}
              backgroundPadding={[{ bottom: 4 }]}
              dy={-15}
              verticalAnchor={"end"}
            />
          }
        />

        {/* Missed reward cycles text */}
        {userSelectCycleCount < maxSelectableCycleCount && (
          <CalcPositionLabel
            style={{ fill: "#047857", fontSize: 10 }}
            calcX={props =>
              (props as any).scale.x(
                last(userSelectedEarningCurve)?.cycle ?? -100,
              ) + 5
            }
            calcY={() =>
              containerSize.height ? containerSize.height - 58 : -100
            }
            text={`${gapCurve.length} missing reward ${plural(gapCurve.length, {
              one: "cycle",
              many: "cycles",
            })}`}
            verticalAnchor={"start"}
          />
        )}
      </VictoryChart>
    </div>
  )
}
