import { FC } from "react"
import { GradientFilledButton } from "../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteFilledButton } from "../../../components/button/variants/WhiteFilledButton"
import { CardModalContent } from "../../../components/CardModal/CardModal"
import { Spensor } from "../../../components/Spensor"
import { TokenCount } from "../../../components/TokenCount"
import { BlockTopTitleLine } from "../../../components/TokenInput/Block"
import {
  BlockGroup,
  BlockGroupDownArrowIcon,
} from "../../../components/TokenInput/BlockGroup"
import {
  TokenBlock,
  TokenCountPair,
} from "../../../components/TokenInput/TokenBlock"
import { TokenName } from "../../../components/TokenName"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { readResource, SuspenseResource } from "../../../utils/SuspenseResource"
import { TokenInfoPresets } from "../../../utils/TokenInfoPresets/TokenInfoPresets"
import { SpotInfo } from "./SpotInfo"

const SpotConfirmation: FC<{
  fromToken: TokenCountPair
  toToken: TokenCountPair
  fromToExchangeRate: number
  fromUnitUSD: number
  liquidityProviderFee: SuspenseResource<number>
  slippage: number
  routes: TokenInfo[]
  minimumReceived: SuspenseResource<number>
  onConfirm?: () => void
  onClose?: () => void
}> = props => {
  return (
    <CardModalContent
      onClose={props.onClose}
      title={"Confirm Swap"}
      className={"flex flex-col gap-4"}
      width={480}
    >
      <BlockGroup
        icon={<BlockGroupDownArrowIcon />}
        firstBlock={
          <TokenBlock
            topArea={<BlockTopTitleLine>From</BlockTopTitleLine>}
            tokens={[props.fromToken]}
          />
        }
        secondBlock={
          <TokenBlock
            topArea={<BlockTopTitleLine>To</BlockTopTitleLine>}
            tokens={[props.toToken]}
          />
        }
      />

      <div className="flex flex-col gap-3">
        <Spensor>
          {() => (
            <div className="flex justify-between">
              <span className="text-xs sm:text-base">Price</span>

              <span className="text-xs sm:text-sm text-gray-200 font-medium">
                <TokenCount
                  token={readResource(props.fromToken.token)}
                  count={1}
                />{" "}
                <TokenName token={readResource(props.fromToken.token)} />
                {" = "}
                <TokenCount
                  token={readResource(props.toToken.token)}
                  count={readResource(props.fromToExchangeRate)}
                />{" "}
                <TokenName token={readResource(props.toToken.token)} />{" "}
                <span className="text-gray-500">
                  (${" "}
                  <TokenCount
                    token={TokenInfoPresets.USD}
                    count={readResource(props.fromUnitUSD)}
                  />
                  )
                </span>
              </span>
            </div>
          )}
        </Spensor>
      </div>

      <SpotInfo
        slippage={props.slippage}
        liquidityProviderFee={props.liquidityProviderFee}
        fromToken={props.fromToken.token}
        routes={props.routes}
        toToken={props.toToken.token}
        minimumReceived={props.minimumReceived}
      />

      <div className={"mt-3.5 flex flex-row gap-2.5"}>
        <WhiteFilledButton className={"flex-1"} onClick={props.onClose}>
          Cancel
        </WhiteFilledButton>

        <GradientFilledButton className={"flex-1"} onClick={props.onConfirm}>
          Confirm
        </GradientFilledButton>
      </div>
    </CardModalContent>
  )
}

export default SpotConfirmation
