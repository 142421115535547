import {
  booleanT,
  defineContract,
  numberT,
  optionalT,
  principalT,
  responseSimpleT,
  tupleT,
} from "../smartContractHelpers/codegenImport"

export const yieldTokenPool = defineContract({
  "yield-token-pool": {
    "add-to-position": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token-trait", type: principalT },
        { name: "token-trait", type: principalT },
        { name: "pool-token-trait", type: principalT },
        { name: "dx", type: numberT },
        { name: "max-dy", type: optionalT(numberT) },
      ],
      output: responseSimpleT(
        tupleT({
          "balance-token": numberT,
          "balance-virtual": numberT,
          "balance-yield-token": numberT,
          supply: numberT,
        }),
      ),
      mode: "public",
    },
    "buy-and-add-to-position": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token-trait", type: principalT },
        { name: "token-trait", type: principalT },
        { name: "pool-token-trait", type: principalT },
        { name: "dx", type: numberT },
      ],
      output: responseSimpleT(
        tupleT({
          "balance-token": numberT,
          "balance-virtual": numberT,
          "balance-yield-token": numberT,
          supply: numberT,
        }),
      ),
      mode: "public",
    },
    "create-and-configure-pool": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token-trait", type: principalT },
        { name: "token-trait", type: principalT },
        { name: "pool-token-trait", type: principalT },
        { name: "multisig-vote", type: principalT },
        { name: "fee-rebate", type: numberT },
        { name: "fee-rate-yield-token", type: numberT },
        { name: "fee-rate-token", type: numberT },
        { name: "small-threshold", type: numberT },
        { name: "min-fee", type: numberT },
        { name: "dx", type: numberT },
        { name: "dy", type: numberT },
      ],
      output: responseSimpleT(
        tupleT({
          "balance-token": numberT,
          "balance-virtual": numberT,
          "balance-yield-token": numberT,
          supply: numberT,
        }),
      ),
      mode: "public",
    },
    "create-pool": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token-trait", type: principalT },
        { name: "token-trait", type: principalT },
        { name: "pool-token-trait", type: principalT },
        { name: "multisig-vote", type: principalT },
        { name: "dx", type: numberT },
        { name: "dy", type: numberT },
      ],
      output: responseSimpleT(
        tupleT({
          "balance-token": numberT,
          "balance-virtual": numberT,
          "balance-yield-token": numberT,
          supply: numberT,
        }),
      ),
      mode: "public",
    },
    "reduce-position": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token-trait", type: principalT },
        { name: "token-trait", type: principalT },
        { name: "pool-token-trait", type: principalT },
        { name: "percent", type: numberT },
      ],
      output: responseSimpleT(tupleT({ dx: numberT, dy: numberT })),
      mode: "public",
    },
    "roll-position": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token-trait", type: principalT },
        { name: "token-trait", type: principalT },
        { name: "pool-token-trait", type: principalT },
        { name: "percent", type: numberT },
        { name: "expiry-to-roll", type: numberT },
      ],
      output: responseSimpleT(
        tupleT({
          "balance-token": numberT,
          "balance-virtual": numberT,
          "balance-yield-token": numberT,
          supply: numberT,
        }),
      ),
      mode: "public",
    },
    "set-approved-contract": {
      input: [
        { name: "owner", type: principalT },
        { name: "approved", type: booleanT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-buy-and-add-buffer": {
      input: [{ name: "new-buffer", type: numberT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-contract-owner": {
      input: [{ name: "owner", type: principalT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-fee-rate-token": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token", type: principalT },
        { name: "fee-rate-token", type: numberT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-fee-rate-yield-token": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token", type: principalT },
        { name: "fee-rate-yield-token", type: numberT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-fee-rebate": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token", type: principalT },
        { name: "fee-rebate", type: numberT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-fee-to-address": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token", type: principalT },
        { name: "fee-to-address", type: principalT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-max-expiry": {
      input: [{ name: "new-max-expiry", type: numberT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-max-in-ratio": {
      input: [{ name: "new-max-in-ratio", type: numberT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-max-out-ratio": {
      input: [{ name: "new-max-out-ratio", type: numberT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-min-fee": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token", type: principalT },
        { name: "min-fee", type: numberT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-oracle-average": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token", type: principalT },
        { name: "new-oracle-average", type: numberT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-oracle-enabled": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token", type: principalT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-small-threshold": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token", type: principalT },
        { name: "small-threshold", type: numberT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "swap-x-for-y": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token-trait", type: principalT },
        { name: "token-trait", type: principalT },
        { name: "dx", type: numberT },
        { name: "min-dy", type: optionalT(numberT) },
      ],
      output: responseSimpleT(tupleT({ dx: numberT, dy: numberT })),
      mode: "public",
    },
    "swap-y-for-x": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token-trait", type: principalT },
        { name: "token-trait", type: principalT },
        { name: "dy", type: numberT },
        { name: "min-dx", type: optionalT(numberT) },
      ],
      output: responseSimpleT(tupleT({ dx: numberT, dy: numberT })),
      mode: "public",
    },
    "get-buy-and-add-buffer": { input: [], output: numberT, mode: "readonly" },
    "get-contract-owner": {
      input: [],
      output: responseSimpleT(principalT),
      mode: "readonly",
    },
    "get-fee-rate-token": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token", type: principalT },
      ],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-fee-rate-yield-token": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token", type: principalT },
      ],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-fee-rebate": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token", type: principalT },
      ],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-fee-to-address": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token", type: principalT },
      ],
      output: responseSimpleT(principalT),
      mode: "readonly",
    },
    "get-max-expiry": { input: [], output: numberT, mode: "readonly" },
    "get-max-in-ratio": { input: [], output: numberT, mode: "readonly" },
    "get-max-out-ratio": { input: [], output: numberT, mode: "readonly" },
    "get-min-fee": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token", type: principalT },
      ],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-oracle-average": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token", type: principalT },
      ],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-oracle-enabled": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token", type: principalT },
      ],
      output: responseSimpleT(booleanT),
      mode: "readonly",
    },
    "get-oracle-instant": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token", type: principalT },
      ],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-oracle-resilient": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token", type: principalT },
      ],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-pool-details": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token", type: principalT },
      ],
      output: responseSimpleT(
        tupleT({
          "balance-token": numberT,
          "balance-virtual": numberT,
          "balance-yield-token": numberT,
          "fee-rate-token": numberT,
          "fee-rate-yield-token": numberT,
          "fee-rebate": numberT,
          "fee-to-address": principalT,
          listed: numberT,
          "min-fee": numberT,
          "oracle-average": numberT,
          "oracle-enabled": booleanT,
          "oracle-resilient": numberT,
          "pool-token": principalT,
          "small-threshold": numberT,
          "total-supply": numberT,
          "underlying-token": principalT,
        }),
      ),
      mode: "readonly",
    },
    "get-position-given-burn": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token", type: principalT },
        { name: "token", type: numberT },
      ],
      output: responseSimpleT(
        tupleT({ dx: numberT, "dy-act": numberT, "dy-vir": numberT }),
      ),
      mode: "readonly",
    },
    "get-position-given-mint": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token", type: principalT },
        { name: "token", type: numberT },
      ],
      output: responseSimpleT(
        tupleT({ dx: numberT, "dy-act": numberT, "dy-vir": numberT }),
      ),
      mode: "readonly",
    },
    "get-price": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token", type: principalT },
      ],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-price-down": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token", type: principalT },
      ],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-small-threshold": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token", type: principalT },
      ],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-t": {
      input: [
        { name: "expiry", type: numberT },
        { name: "listed", type: numberT },
      ],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-token-given-position": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token", type: principalT },
        { name: "dx", type: numberT },
      ],
      output: responseSimpleT(
        tupleT({ "dy-act": numberT, "dy-vir": numberT, token: numberT }),
      ),
      mode: "readonly",
    },
    "get-x-given-price": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token", type: principalT },
        { name: "price", type: numberT },
      ],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-x-given-y": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token", type: principalT },
        { name: "dy", type: numberT },
      ],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-x-given-yield": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token", type: principalT },
        { name: "yield", type: numberT },
      ],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-x-in-given-y-out": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token", type: principalT },
        { name: "dy", type: numberT },
      ],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-y-given-price": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token", type: principalT },
        { name: "price", type: numberT },
      ],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-y-given-x": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token", type: principalT },
        { name: "dx", type: numberT },
      ],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-y-given-yield": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token", type: principalT },
        { name: "yield", type: numberT },
      ],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-y-in-given-x-out": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token", type: principalT },
        { name: "dx", type: numberT },
      ],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-yield": {
      input: [
        { name: "expiry", type: numberT },
        { name: "yield-token", type: principalT },
      ],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "approved-contracts": {
      input: principalT,
      output: optionalT(booleanT),
      mode: "mapEntry",
    },
    "pools-data-map": {
      input: tupleT({ expiry: numberT, "yield-token": principalT }),
      output: optionalT(
        tupleT({
          "balance-token": numberT,
          "balance-virtual": numberT,
          "balance-yield-token": numberT,
          "fee-rate-token": numberT,
          "fee-rate-yield-token": numberT,
          "fee-rebate": numberT,
          "fee-to-address": principalT,
          listed: numberT,
          "min-fee": numberT,
          "oracle-average": numberT,
          "oracle-enabled": booleanT,
          "oracle-resilient": numberT,
          "pool-token": principalT,
          "small-threshold": numberT,
          "total-supply": numberT,
          "underlying-token": principalT,
        }),
      ),
      mode: "mapEntry",
    },
  },
} as const)
