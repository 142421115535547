import { sortBy } from "lodash"
import { interval, merge, Observable, startWith, switchMap } from "rxjs"
import { components } from "../../../../generated/wrapBridge/types"
import { sendRequest } from "../../../../generated/wrapBridgeHelpers/wrapBridgeApi"
import { Currency } from "../../../../utils/alexjs/Currency"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { assertNever } from "../../../../utils/types"
import { BridgeChain } from "../../types/BridgeChain"
import {
  WrapBridgeHistoryRecord,
  WrapBridgeSegment,
  WrapBridgeSegmentStatus,
} from "../../types/types"
import {
  BridgeCurrency,
  parseBridgeCurrencyServerBigIntAmount,
  tokenInfoFromBridgeCurrency,
} from "../utils/BridgeCurrency"
import { ETHCurrency } from "../utils/ETHCurrency"
import { wrapBridgeNetworkFromBridgeChain } from "../utils/WrapBridgeNetwork"

export type ServerOrderRecord =
  components["schemas"]["GetTokenBridgeOrdersResponse"]["orders"][number]
export type TokenBridgeSignMissingOrderWithType =
  components["schemas"]["TokenBridgeSignMissingOrderWithType"]

const serverTokenToBridgeCurrency = (
  serverToken: ServerOrderRecord["source"]["token"],
): BridgeCurrency => {
  switch (serverToken) {
    case "xusd":
      return Currency.W_XUSD
    case "usdc":
      return ETHCurrency.USDC
    default:
      assertNever(serverToken)
  }
}

const serverNetworkToBridgeChain = (
  serverNetwork: ServerOrderRecord["source"]["network"],
): BridgeChain => {
  switch (serverNetwork) {
    case "ethereum":
      return BridgeChain.Ethereum
    case "stacks":
      return BridgeChain.Stacks
    default:
      assertNever(serverNetwork)
  }
}

const getBridgeChainFromServerTransferSegment = (
  serverData: ServerOrderRecord["source"],
): BridgeChain => {
  return serverNetworkToBridgeChain(serverData.network || "ethereum")
}

const transformServerTransferSegment = (
  serverData: ServerOrderRecord["source"],
  context: {
    getTokenInfo: (currency: Currency) => TokenInfo
  },
): WrapBridgeSegment => {
  const sourceCurrency = serverTokenToBridgeCurrency(serverData.token)

  const chain = getBridgeChainFromServerTransferSegment(serverData)

  return {
    token: tokenInfoFromBridgeCurrency(sourceCurrency, context.getTokenInfo),
    address: serverData.address,
    estimatedAmount: parseBridgeCurrencyServerBigIntAmount(
      serverData.estimated_bigint_token_amount,
      sourceCurrency,
    ),
    amount:
      serverData.bigint_token_amount == null
        ? undefined
        : parseBridgeCurrencyServerBigIntAmount(
            serverData.bigint_token_amount,
            sourceCurrency,
          ),
    network: wrapBridgeNetworkFromBridgeChain(chain),
    addressExploreLink: serverData.address_explorer_url,
  }
}

const transformServerTransferSegmentStatus = (
  serverData: ServerOrderRecord["source_status"],
): WrapBridgeSegmentStatus => {
  if (serverData.type === "processed") {
    return {
      type: "success",
      explorerLink: serverData.explorer_url,
    }
  } else if (serverData.type === "failed") {
    return {
      type: "warning",
      message: "Failed",
      explorerLink: serverData.explorer_url,
    }
  } else if (serverData.type == null || serverData.type === "pending") {
    return {
      type: "inProgress",
      message: "In progress",
      explorerLink: serverData.explorer_url,
    }
  } else {
    assertNever(serverData)
  }
}

const transformServerWrappingSegmentStatus = (
  serverData: components["schemas"]["TokenBridgeHistoryOrderWrappingSegmentStatus"],
): WrapBridgeSegmentStatus => {
  if (serverData.type === "processed") {
    return {
      type: "success",
    }
  } else if (serverData.type === "failed") {
    return {
      type: "warning",
      // TODO:
      message: "",
    }
  } else if (serverData.type === "pending" || serverData.type == null) {
    return {
      type: "inProgress",
      // TODO:
      message: "",
    }
  } else {
    assertNever(serverData.type)
  }
}

export const transformServerOrderRecord = (
  serverRecord: ServerOrderRecord,
  context: {
    getTokenInfo: (currency: Currency) => TokenInfo
    onSignMessage: (serverRecord: TokenBridgeSignMissingOrderWithType) => void
  },
): WrapBridgeHistoryRecord => {
  let record: WrapBridgeHistoryRecord

  if (serverRecord.type === "historical_order") {
    const feeCurrency = serverTokenToBridgeCurrency(serverRecord.fee_token)
    const historical: WrapBridgeHistoryRecord.Historical = {
      orderNumber: serverRecord.order_number,
      createdAt: new Date(serverRecord.created_at),
      status: {
        source: transformServerTransferSegmentStatus(
          serverRecord.source_status,
        ),
        wrapping:
          serverRecord.wrapping_status == null
            ? undefined
            : transformServerWrappingSegmentStatus(
                serverRecord.wrapping_status,
              ),
        destination:
          serverRecord.destination_status == null
            ? undefined
            : transformServerTransferSegmentStatus(
                serverRecord.destination_status,
              ),
      },
      source: transformServerTransferSegment(serverRecord.source, context),
      target: transformServerTransferSegment(serverRecord.destination, context),
      fee: parseBridgeCurrencyServerBigIntAmount(
        serverRecord.bigint_fee,
        feeCurrency,
      ),
      feeToken: tokenInfoFromBridgeCurrency(feeCurrency, context.getTokenInfo),
    }
    record = historical
  } else if (serverRecord.type === "sign_missing_order") {
    const signMissing: WrapBridgeHistoryRecord.SignMissing = {
      orderNumber: serverRecord.order_number,
      createdAt: new Date(serverRecord.created_at),
      status: {
        source: transformServerTransferSegmentStatus(
          serverRecord.source_status,
        ),
        wrapping:
          serverRecord.source_status.type === "pending"
            ? undefined
            : {
                type: "warning",
                message: "Pending Sign message",
              },
      },
      source: transformServerTransferSegment(serverRecord.source, context),
      onSignMessage() {
        return context.onSignMessage(serverRecord)
      },
    }
    record = signMissing
  } else {
    assertNever(serverRecord)
  }

  return record
}

export type ServerHistoryRecord =
  | components["schemas"]["TokenBridgeSignMissingOrderWithType"]
  | components["schemas"]["TokenBridgeHistoricalOrderWithType"]
export const getServerHistoryRecords = (
  address0: string,
  address1: string,
  context: {
    signMessageRefreshSignal: Observable<void>
  },
): Observable<ServerHistoryRecord[]> => {
  return merge(interval(30 * 1000), context.signMessageRefreshSignal).pipe(
    startWith(null),
    switchMap(() =>
      sendRequest("TokenBridgeController_getTokenBridgeOrders", {
        query: { address0, address1 },
      }).then(r =>
        sortBy(r.data.orders, o => (o.type === "sign_missing_order" ? 0 : 1)),
      ),
    ),
  )
}
