import { Buffer } from "@stacks/common"
import {
  openSignatureRequestPopup,
  openStructuredDataSignatureRequestPopup,
} from "@stacks/connect"
import { SignatureData } from "@stacks/connect/dist/types/types/signature"
import { serializeCV } from "@stacks/transactions"
import { round, zipObject } from "lodash"
import { catchError, map, Observable, of } from "rxjs"
import {
  ORDER_BOOK_REFRESH_INTERVAL,
  STACK_APP_DETAILS,
  STACK_NETWORK,
} from "../../../../config"
import { AlexContracts } from "../../../../generated/smartContract/contracts_Alex"
import { asSender } from "../../../../generated/smartContractHelpers/asSender"
import { components } from "../../../../generated/stxdx/types"
import {
  sendPublicRequest,
  sendRequest,
} from "../../../../generated/stxdxHelpers/stxdxApi"
import { Currency } from "../../../../utils/alexjs/Currency"
import { transfer } from "../../../../utils/alexjs/postConditions"
import { CancelError } from "../../../../utils/error"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { assertNever } from "../../../../utils/types"
import { intervalFetch } from "../../../../utils/wonkaHelpers/intervalFetch"
import { Bar } from "../../../../vendors/charting_library/charting_library.esm"
import { StxDxOrderType, TradingFormWarningType } from "../../components/types"
import { signatureDomain } from "../constants"
import {
  getAuthSignatureFromWallet,
  saveSignatureForLater,
} from "../modules/OrderbookMyInfoModule.service"
import { getUserBalances } from "./getUserBalances"

export const allStxDxAssets = [
  Currency.W_XUSD,
  Currency.W_XBTC,
  Currency.ALEX,
  Currency.W_STX,
] as const

export type StxDxAsset = typeof allStxDxAssets[number]

export const isStxDxAsset = (currency: Currency): currency is StxDxAsset =>
  allStxDxAssets.includes(currency as any)

export type DepositFormData = {
  stxAddress: string
  userId?: number
  tokens: {
    amount: number
    assetId: number
    asset: Currency
  }[]
}

export async function depositToStxDx(
  data: DepositFormData,
): Promise<{ txId: string }> {
  if (data.userId != null) {
    return await asSender(data.stxAddress)
      .contract("register-user-helper-v1-1")
      .func("transfer-in-many")
      .call(
        {
          "user-id": data.userId,
          amounts: data.tokens.map(t => t.amount * 1e8),
          "asset-ids": data.tokens.map(t => t.assetId),
          assets: data.tokens.map(t => t.asset),
        },
        data.tokens.map(t => transfer(data.stxAddress, t.asset, t.amount)),
      )
  }
  const { signature, publicKey, payload } = await getAuthSignatureFromWallet(
    data.stxAddress,
    60 * 60 * 5,
  )
  saveSignatureForLater(payload, signature)
  return await asSender(data.stxAddress)
    .contract("register-user-helper-v1-1")
    .func("register-and-deposit")
    .call(
      {
        "maker-pubkey": Buffer.from(publicKey, "hex"),
        amounts: data.tokens.map(t => t.amount * 1e8),
        "asset-ids": data.tokens.map(t => t.assetId),
        assets: data.tokens.map(t => t.asset),
      },
      data.tokens.map(t => transfer(data.stxAddress, t.asset, t.amount)),
    )
}

export type WithdrawFormData = {
  stxAddress: string
  tokens: {
    amount: number
    assetId: number
    asset: Currency
  }[]
  userId: number
}

export function withdrawFromStxDx(
  data: WithdrawFormData,
): Promise<{ txId: string }> {
  return asSender(data.stxAddress)
    .contract("stxdx-wallet-zero")
    .func("request-transfer-out-many")
    .call(
      {
        amounts: data.tokens.map(t => t.amount * 1e8),
        "asset-ids": data.tokens.map(t => t.assetId),
        assets: data.tokens.map(t => t.asset),
        "user-id": data.userId,
      },
      [],
    )
}

export type TradeFormData = {
  warning?: { type: TradingFormWarningType }
  side: "buy" | "sell"
  orderType: StxDxOrderType
  outgoingToken: TokenInfo
  incomingToken: TokenInfo
  market: StxDxMarket
  stxAddress: string
  currentHeight: number
  currentUserId: number
  currentUserAuth: string
  currentPrice: number
  price: number
  stopPrice?: number
  size: number
}

const OrderTranscoder =
  AlexContracts["stxdx-sender-proxy"]["match-orders"].input[0].type

const MARKET_PRICE_TOLERANCE_RADIO = 1.1

export const ORDERBOOK_FEE_RATE = 0.001
export const ORDERBOOK_AMOUNT_WITH_FEE_RATE = 1 + ORDERBOOK_FEE_RATE

export async function tradeInStxDx(data: TradeFormData): Promise<void> {
  const size = round(data.size, 8 - stxDxMarketPricePrecision[data.market])
  const shared: Pick<
    components["schemas"]["ConvertOrderRequest"],
    | "market"
    | "maker"
    | "expiration_height"
    | "sender_fee"
    | "side"
    | "size"
    | "risk"
  > = {
    market: data.market,
    maker: String(data.currentUserId),
    expiration_height: String(1e8),
    sender_fee: String(ORDERBOOK_FEE_RATE * 1e8),
    side: data.side === "buy" ? "buy" : "sell",
    size: String(size),
    risk: false,
  }
  const request: components["schemas"]["ConvertOrderRequest"] =
    data.orderType === StxDxOrderType.Limit
      ? {
          ...shared,
          type: "vanilla",
          price: String(
            round(data.price, stxDxMarketPricePrecision[data.market]),
          ),
        }
      : data.orderType === StxDxOrderType.Market
      ? {
          ...shared,
          type: "ioc",
          price: String(
            round(
              data.side === "buy"
                ? data.price * MARKET_PRICE_TOLERANCE_RADIO
                : data.price / MARKET_PRICE_TOLERANCE_RADIO,
              stxDxMarketPricePrecision[data.market],
            ),
          ),
        }
      : data.orderType === StxDxOrderType.StopLimit
      ? {
          ...shared,
          type: "vanilla",
          risk:
            data.side === "sell"
              ? data.stopPrice! < data.currentPrice
              : data.stopPrice! > data.currentPrice,
          price: String(
            round(data.price, stxDxMarketPricePrecision[data.market]),
          ),
          stop_price: String(
            round(data.stopPrice!, stxDxMarketPricePrecision[data.market]),
          ),
        }
      : assertNever(data.orderType)

  const { data: response } = await sendRequest(data.currentUserAuth)(
    "OrderController_convertOrder",
    {
      body: request,
    },
  )

  const clairty = OrderTranscoder.encode({
    "expiration-height": Number(response["expiration-height"]),
    maker: Number(response["maker"]),
    "maker-asset": Number(response["maker-asset"]),
    "maker-asset-data": Number(response["maker-asset-data"]),
    "maximum-fill": Number(response["maximum-fill"]),
    salt: Number(response["salt"]),
    sender: Number(response["sender"]),
    "sender-fee": Number(response["sender-fee"]),
    "taker-asset": Number(response["taker-asset"]),
    "taker-asset-data": Number(response["taker-asset-data"]),
    // type: 1,
    type: Number(response.type),
    risk: response.risk,
    timestamp: Number(response.timestamp),
    stop: Number(response.stop),
  })
  const signature = await new Promise<SignatureData>(
    async (resolve, reject) => {
      await openStructuredDataSignatureRequestPopup({
        stxAddress: data.stxAddress,
        domain: signatureDomain,
        message: clairty,
        appDetails: STACK_APP_DETAILS,
        network: STACK_NETWORK as any,
        onFinish: resolve,
        onCancel: () => reject(new CancelError("User cancelled")),
      })
    },
  )
  await sendRequest(data.currentUserAuth)("OrderController_createOrder", {
    body: {
      order: serializeCV(clairty).toString("hex"),
      signature: signature.signature,
    },
  })
}

export type UserBalance = {
  locked: number
  available: number
}

type UserBalances = {
  [key in StxDxAsset]?: UserBalance
}

export function getUserBalance(
  jwt: string,
  assetIdMap: { [assetId: number]: StxDxAsset },
): Observable<UserBalances> {
  return getUserBalances(jwt).pipe(
    map(data => {
      const balances: UserBalances = {}
      for (const assetId of Object.keys(data)) {
        const assetName = assetIdMap[Number(assetId)]
        const balance = data[assetId]
        if (assetName == null || balance == null) continue
        const locked = Number(balance.locked) / 1e8
        const available = Number(balance.available) / 1e8
        balances[assetName] = {
          locked,
          available,
        }
      }
      return balances
    }),
  )
}

export async function fetchChartDataFor({
  market,
  resolution,
  from,
  to,
  countBack,
}: {
  market: StxDxMarket
  resolution: number // int in minutes, e.g. 1 for 1 minute, 60 for 1 hour
  to: string // unix timestamp in seconds as string or 'now'
  countBack?: number
  from?: number // unix timestamp in seconds
}): Promise<{ bars: Bar[]; meta: { noData: boolean; nextTime?: number } }> {
  const { data: responseData } = await sendPublicRequest(
    "OrderController_getTradingView",
    {
      path: { market },
      query: { resolution, to, countback: countBack, from },
    },
  )
  const columns = ["time", ...responseData.metadata.columns.slice(1)]
  const bars: Bar[] = responseData.series.map<Bar>(s => {
    const [date, ...rest] = s
    const result = zipObject<number>(columns, [
      new Date(Number.parseInt(date!, 10)).valueOf() * 1000,
      ...rest.map(x => Number.parseFloat(x)),
    ])
    result.volume = result.volume! / 1e8
    return result as unknown as Bar
  })
  bars.reverse()
  return {
    bars,
    meta: {
      noData: bars.length === 0,
      nextTime: responseData.metadata.nextTime,
    },
  }
}

export enum StxDxMarket {
  BtcUsd = "BTC-USD",
  AlexUsd = "ALEX-USD",
  StxUsd = "STX-USD",
}

export const allStxDxMarkets = [
  StxDxMarket.BtcUsd,
  StxDxMarket.AlexUsd,
  StxDxMarket.StxUsd,
]

export function isStxDxMarket(input: any): input is StxDxMarket {
  return allStxDxMarkets.includes(input as any)
}

export const stxDxMarketPricePrecision: { [P in StxDxMarket]: number } = {
  [StxDxMarket.AlexUsd]: 4,
  [StxDxMarket.StxUsd]: 3,
  [StxDxMarket.BtcUsd]: 0,
}

export function stxDxTokenPairs(market: StxDxMarket): {
  priceToken: StxDxAsset
  tradeToken: StxDxAsset
} {
  switch (market) {
    case StxDxMarket.AlexUsd:
      return { priceToken: Currency.W_XUSD, tradeToken: Currency.ALEX }
    case StxDxMarket.BtcUsd:
      return { priceToken: Currency.W_XUSD, tradeToken: Currency.W_XBTC }
    case StxDxMarket.StxUsd:
      return { priceToken: Currency.W_XUSD, tradeToken: Currency.W_STX }
    default:
      assertNever(market)
  }
}

export function fetchPriceFor(market: StxDxMarket): Observable<number> {
  return intervalFetch(ORDER_BOOK_REFRESH_INTERVAL, () =>
    sendPublicRequest("OrderController_getPriceTicker", {
      path: { market },
    }),
  ).pipe(map(resp => Number(resp.data.price)))
}

export function fetchPriceTickerFor(
  market: StxDxMarket,
): Observable<{ bid: number; ask: number } | null> {
  return intervalFetch(ORDER_BOOK_REFRESH_INTERVAL, () =>
    sendPublicRequest("OrderController_getOrderBookTicker", {
      path: { market },
    }),
  ).pipe(
    map(resp => ({
      bid: Number(resp.data.bid),
      ask: Number(resp.data.ask),
    })),
    catchError(() => of(null)),
  )
}

export async function signMessage(
  stxAddress: string,
  message: string,
): Promise<SignatureData> {
  return await new Promise<SignatureData>(async (resolve, reject) => {
    await openSignatureRequestPopup({
      stxAddress: stxAddress,
      message,
      appDetails: STACK_APP_DETAILS,
      network: STACK_NETWORK as any,
      onFinish: data => resolve(data),
      onCancel: () => reject(new CancelError("User cancelled")),
    })
  })
}
