import { FC } from "react"
import { CoFarmRowData } from "../../../../store/transformerGroups/farm/coFarm"
import { AllTokenInfos } from "../../../../types"
import { BaseCellContainer } from "../../cells/BaseCellContainer"
import { OneLineAmount } from "../../OneLineAmount"
import { OneLineCurrency } from "../../OneLineCurrency"
import { BaseRow } from "../BaseRow"

export const CoFarmRow: FC<{
  rowData: CoFarmRowData
  allTokenInfos: AllTokenInfos
}> = ({ rowData, allTokenInfos }) => (
  <BaseRow
    rowData={rowData}
    currencyCell={
      <BaseCellContainer>
        <OneLineCurrency
          allTokenInfos={allTokenInfos}
          fromCurrencyAmounts={rowData.coFarmFromCurrencyAmounts}
          toCurrencyAmounts={rowData.coFarmToCurrencyAmounts}
        />
        <OneLineCurrency
          allTokenInfos={allTokenInfos}
          fromCurrencyAmounts={rowData.entitledFromCurrencyAmounts}
          toCurrencyAmounts={rowData.entitledToCurrencyAmounts}
        />
      </BaseCellContainer>
    }
    amountCell={
      <BaseCellContainer>
        <OneLineAmount
          allTokenInfos={allTokenInfos}
          fromCurrencyAmounts={rowData.coFarmFromCurrencyAmounts}
          toCurrencyAmounts={rowData.coFarmToCurrencyAmounts}
        />
        <OneLineAmount
          allTokenInfos={allTokenInfos}
          fromCurrencyAmounts={rowData.entitledFromCurrencyAmounts}
          toCurrencyAmounts={rowData.entitledToCurrencyAmounts}
        />
      </BaseCellContainer>
    }
    tradeInfoCell={
      <BaseCellContainer>
        <p className="whitespace-nowrap">Reward Harvest</p>
        <p className="whitespace-nowrap">Co-Farm</p>
      </BaseCellContainer>
    }
  />
)
