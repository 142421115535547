import { format } from "date-fns"
import { range } from "lodash"
import { FC } from "react"
import { StepsBar } from "../../../../components/StepsBar/StepsBar"

export interface LockdropStepsProps {
  currentStepIndex: number
  startDate: Date
  endDate?: Date
  snapshotDates: (Date | undefined)[]
}

export const LockdropSteps: FC<LockdropStepsProps> = ({
  startDate,
  endDate,
  snapshotDates,
  currentStepIndex,
}) => {
  return (
    <StepsBar
      current={currentStepIndex - 1}
      steps={[
        {
          title: "Start",
          date: startDate,
        },
        ...range(3).map(i => ({
          title: `Snapshot ${i + 1}`,
          date: snapshotDates[i],
        })),
        {
          title: "Airdrop",
          date: endDate,
        },
      ].map(x => ({
        aboveText: null,
        belowText: (
          <BelowText
            title={x.title}
            description={x.date ? formatDate(x.date) : ""}
          />
        ),
      }))}
    />
  )
}

const BelowText: FC<{ title: string; description: string }> = ({
  title,
  description,
}) => (
  <div>
    <p className="text-gray-400 font-semibold text-sm">{title}</p>
    <p className="text-xs leading-4 text-white/50">{description}</p>
  </div>
)

function formatDate(date: Date): string {
  return format(date, "LLL d")
}
