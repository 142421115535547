import clsx from "clsx"
import { CSSProperties, FC } from "react"
import { GoldenBox } from "../../../../../../components/GoldenBox"
import { TokenCount } from "../../../../../../components/TokenCount"
import { TokenName } from "../../../../../../components/TokenName"
import { Tooltip } from "../../../../../../components/Tooltip/Tooltip"
import { TokenInfo } from "../../../../../../utils/models/TokenInfo"
import { FCC } from "../../../../../../utils/reactHelpers/types"
import { assertNever } from "../../../../../../utils/types"
import { ExplorerLink } from "../ExplorerLink/ExplorerLink"
import { ReactComponent as UnknownStatusIcon } from "./default.svg"
import { ReactComponent as LoseStatusIcon } from "./lose.svg"
import { LotteryTicket } from "./types"
import { ReactComponent as WonStatusIcon } from "./won.svg"
import Status = LotteryTicket.Status

const LotteryTicketCardContainer: FCC<{
  className?: string
  bgClassName?: string
  borderClassName?: string
  style?: CSSProperties
}> = props => {
  return (
    <div
      className={clsx(
        "p-2.5 w-[160px] h-[220px] rounded overflow-hidden flex flex-col items-center",
        props.bgClassName ?? "bg-blue-500/20",
        props.borderClassName ?? "border border-blue-300/20",
      )}
      style={props.style}
    >
      {props.children}
    </div>
  )
}

const TicketNumber: FCC<{
  className?: string
  textClassName?: string
  number: number
}> = props => (
  <p
    className={clsx(
      props.className,
      "text-xs leading-4 font-normal",
      props.textClassName ?? "text-gray-100",
    )}
  >
    <Tooltip title={"Lottery Ticket Number"}>No. {props.number}</Tooltip>
  </p>
)

const StatusBadgeContainer: FCC<{ className?: string }> = props => (
  <div
    className={clsx(
      "px-[14px] py-[6px] bg-white/5 rounded-full",
      "text-xs leading-4 font-medium text-gray-200",
      props.className,
    )}
  >
    {props.children}
  </div>
)

const StatusBadge: FC<{
  className?: string
  status: LotteryTicket.Status
}> = props => {
  // prettier-ignore
  const text =
    props.status === Status.WaitToClaim ? 'Wait to claim' :
    props.status === Status.Sending ? 'Sending' :
    props.status === Status.Resending ? 'Resending' :
    props.status === Status.Sent ? 'Sent' :
    assertNever(props.status)

  return (
    <StatusBadgeContainer
      className={clsx("flex flex-row items-center gap-[5px]", props.className)}
    >
      {text}
    </StatusBadgeContainer>
  )
}

export const LotteryTicketCard$Unknown: FC<{
  ticket: LotteryTicket.Unknown
}> = props => (
  <LotteryTicketCardContainer>
    <TicketNumber
      textClassName={"text-gray-400"}
      number={props.ticket.number}
    />

    <UnknownStatusIcon className={"my-auto"} />

    <StatusBadgeContainer>Wait to open</StatusBadgeContainer>
  </LotteryTicketCardContainer>
)

export const LotteryTicketCard$Won: FC<{
  idoToken: TokenInfo
  ticket: LotteryTicket.Won
}> = props => (
  <GoldenBox
    renderContainer={props => (
      <LotteryTicketCardContainer
        className={props.className}
        bgClassName={""}
        borderClassName={"border border-white/10"}
        style={props.style}
      >
        {props.children}
      </LotteryTicketCardContainer>
    )}
  >
    <TicketNumber number={props.ticket.number} />

    <div className={"my-auto flex flex-col items-center"}>
      <WonStatusIcon />

      <p className={"mt-2 text-gray-200 text-center leading-none"}>
        <span className={"text-[14px] leading-[20px] font-bold"}>WIN</span>
        <br />
        <span className={"text-[12px] leading-[16px]"}>
          <TokenCount
            token={props.idoToken}
            count={props.ticket.wonIDOTokenCount}
          />
          &nbsp;
          <TokenName token={props.idoToken} />
        </span>
      </p>
    </div>

    <StatusBadge status={props.ticket.status} />

    {props.ticket.explorerLink && (
      <ExplorerLink className={"mt-2.5"} link={props.ticket.explorerLink} />
    )}
  </GoldenBox>
)

export const LotteryTicketCard$Lose: FC<{
  priceToken: TokenInfo
  ticket: LotteryTicket.Lose
}> = props => (
  <LotteryTicketCardContainer
    bgClassName={"bg-gray-500 bg-opacity-10"}
    borderClassName={"border border-gray-300 border-opacity-10"}
  >
    <TicketNumber className={"text-gray-400"} number={props.ticket.number} />

    <div className={"my-auto flex flex-col items-center"}>
      <LoseStatusIcon />

      <p className={"mt-2 text-gray-200 text-center leading-none"}>
        <span className={"text-sm leading-5 font-bold"}>LOSE</span>
        <br />
        <span className={"text-xs leading-4 font-normal"}>
          <TokenCount
            token={props.priceToken}
            count={props.ticket.returnPriceTokenCount}
          />
          &nbsp;
          <TokenName token={props.priceToken} />
          &nbsp;
          {props.ticket.status === Status.Sent
            ? "refunded"
            : "will be refunded"}
        </span>
      </p>
    </div>

    <StatusBadge status={props.ticket.status} />

    {props.ticket.explorerLink && (
      <ExplorerLink className={"mt-2.5"} link={props.ticket.explorerLink} />
    )}
  </LotteryTicketCardContainer>
)

export interface LotteryTicketCardProps {
  idoToken: TokenInfo
  priceToken: TokenInfo
  lotteryTicket: LotteryTicket
}

export const LotteryTicketCard: FC<LotteryTicketCardProps> = props => {
  switch (props.lotteryTicket.type) {
    case LotteryTicket.Type.Unknown:
      return <LotteryTicketCard$Unknown ticket={props.lotteryTicket} />
    case LotteryTicket.Type.Won:
      return (
        <LotteryTicketCard$Won
          idoToken={props.idoToken}
          ticket={props.lotteryTicket}
        />
      )
    case LotteryTicket.Type.Lose:
      return (
        <LotteryTicketCard$Lose
          priceToken={props.priceToken}
          ticket={props.lotteryTicket}
        />
      )
    default:
      assertNever(props.lotteryTicket)
  }
}
