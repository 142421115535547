import { FC, Suspense, useState } from "react"
import { useMatch } from "react-router-dom"
import { PathGenerator, RoutePath, usePathGenerator } from "../../routes/routes"
import { useAccountStore } from "../../stores/accountStore/useAccountStore"
import { AppConfigs } from "../../stores/appEnvStore/appEnv.services"
import { useAppEnvStore } from "../../stores/appEnvStore/useAppEnvStore"
import { useAuthStore } from "../../stores/authStore/useAuthStore"
import { useCurrencyStore } from "../../stores/currencyStore/useCurrencyStore"
import { useNotifyStore } from "../../stores/notifyStore/useNotifyStore"
import { Currency } from "../../utils/alexjs/Currency"
import { TokenInfo } from "../../utils/models/TokenInfo"
import { useBoolean } from "../../utils/reactHelpers/useBoolean"
import { suspenseResource } from "../../utils/SuspenseResource"
import { OneOrMore } from "../../utils/types"
import { isNotNull } from "../../utils/utils"
import { NavLink } from "../NavLink"
import { Spensor } from "../Spensor"
import { NavBarButtonItemData, NavBarItemData } from "./NavBarItem"
import { NotificationButton } from "./NotificationButton/NotificationButton"
import { TopNavBar, _BalanceBar, _BalanceButton } from "./TopNavBar"
import {
  WiredTrivialButton$Connected,
  WiredTrivialButton$Disconnected,
} from "./TrivialButton/WiredTrivialButton"
import { BalanceBar$Loading } from "./_/BalanceBar"
import { BalanceButtonProps } from "./_/BalanceButton"

const WiredBalanceButton: FC<{
  onClick?: BalanceButtonProps["onClick"]
}> = props => {
  const currencyStore = useCurrencyStore()
  const [token, setToken] = useState<TokenInfo>(
    currencyStore.getTokenInfo$(Currency.W_STX),
  )
  const accStore = useAccountStore()

  const isInOrderBook = useMatch(RoutePath.OrderbookDetail)

  if (isInOrderBook) {
    return null
  }

  const balance = accStore.allTokensWithBalanceForBalanceBar$.find(
    t => t.token.id === token.id,
  )?.balance

  return (
    <_BalanceButton
      availableTokens={accStore.allTokensWithBalanceForBalanceBar$.map(
        t => t.token,
      )}
      balance={balance ?? 0}
      selectedToken={token}
      onSelectToken={setToken}
      onClick={props.onClick}
    />
  )
}

const WiredBalanceBar: FC<{ className?: string }> = props => {
  const accStore = useAccountStore()
  return (
    <_BalanceBar
      className={props.className}
      balanceInfos={accStore.allTokensWithBalanceForBalanceBar$}
    />
  )
}

const WiredNotificationBelt: FC = () => {
  const gen = usePathGenerator()
  const notifyStore = useNotifyStore()
  const isInOrderBook = useMatch(RoutePath.OrderbookDetail)

  if (isInOrderBook) {
    return null
  }

  return (
    <NavLink to={gen.notify()}>
      <Spensor fallback={<NotificationButton className="align-bottom" />}>
        {() => (
          <NotificationButton
            className="align-bottom"
            count={notifyStore.systemMessages.count$}
          />
        )}
      </Spensor>
    </NavLink>
  )
}

export const WiredTopNavBar: FC = () => {
  const [balanceBarVisible, balanceBarCtrl] = useBoolean()
  const { isWalletConnected } = useAuthStore()
  const env = useAppEnvStore()

  return (
    <>
      <TopNavBar
        buttons={suspenseResource(() =>
          getAvailableNavItemFromConfig(env.config$),
        )}
        className={"relative z-1"}
        rightSide={
          <Suspense fallback={<WiredTrivialButton$Disconnected />}>
            <Suspense fallback={null}>
              <WiredBalanceButton onClick={balanceBarCtrl.toggle} />
            </Suspense>
            <WiredTrivialButton$Connected />
            <WiredNotificationBelt />
          </Suspense>
        }
      />
      {balanceBarVisible && (
        <Suspense fallback={isWalletConnected ? <BalanceBar$Loading /> : null}>
          <WiredBalanceBar className={"absolute left-0 right-0"} />
        </Suspense>
      )}
    </>
  )
}

function getAvailableNavItemFromConfig(config: AppConfigs): NavBarItemData[] {
  const buttons: (NavBarItemData | null)[] = [
    config.enableLotteryFeature || config.enableLaunchPadFeature
      ? {
          type: "dropdown",
          name: "LAUNCHPAD",
          buttons: [
            config.enableLotteryFeature
              ? { link: (g: PathGenerator) => g.lottery(), name: "LOTTERY" }
              : null,
            config.enableLaunchPadFeature
              ? { link: (g: PathGenerator) => g.launchpad(), name: "IDO" }
              : null,
          ].filter(isNotNull) as OneOrMore<NavBarButtonItemData>,
        }
      : null,
    config.enableSwapFeature ? { link: g => g.swap(), name: "SWAP" } : null,
    config.enablePoolFeature ? { link: g => g.poolList(), name: "POOL" } : null,
    config.enableStakeFeature ? { link: g => g.stake(), name: "STAKE" } : null,
    config.enableFarmFeature ? { link: g => g.farmList(), name: "FARM" } : null,
    config.enableLendFeature ? { link: g => g.lend(), name: "LEND" } : null,
    config.enableOrderBook
      ? { link: g => g.orderbook(), name: "ORDERBOOK (Testnet)" }
      : {
          link: () => "https://orderbook.alexlab.co/",
          name: "ORDERBOOK (Testnet)",
        },
    config.enableWrapBridgeFeature
      ? { link: g => g.wrapBridge(), name: "BRIDGE (Beta)" }
      : null,
  ]
  return buttons.filter(isNotNull)
}
