import { range } from "lodash"
import { FC } from "react"
import { CardPlate } from "../../../../../components/Card"
import { ordinal } from "../../../../../utils/stringHelpers"
import { ReactComponent as FailedIcon } from "./failed.svg"
import { ReactComponent as PassedIcon } from "./passed.svg"

export const SnapshotMultipliers: FC<{
  multipliers?: (number | null)[]
  snapshotDates?: Date[]
}> = ({ multipliers = [], snapshotDates = [] }) => {
  return (
    <CardPlate>
      <dl>
        {range(3).map(i => {
          return (
            <div className="flex flex-row items-center justify-between">
              <dt className="text-xs leading-4 font-normal shrink">{`My ${ordinal(
                i + 1,
              )} Snapshot Multiplier`}</dt>
              <dd className="text-lg leading-7 font-semibold min-w-[4.5rem] text-right">
                <Multiplier
                  multiplier={multipliers[i]}
                  date={snapshotDates[i]}
                />
              </dd>
            </div>
          )
        })}
      </dl>
    </CardPlate>
  )
}

const today = new Date()

const Multiplier: FC<{ multiplier?: number | null; date?: Date }> = ({
  multiplier,
  date,
}) => {
  if (date == null || date > today) {
    return <span>-</span>
  }
  if (multiplier === 0 || multiplier == null) {
    return (
      <span className="flex flex-row items-center gap-x-1.5">
        <FailedIcon />
        <span className="text-pink-400">failed</span>
      </span>
    )
  }
  return (
    <span className="flex flex-row items-center gap-x-1.5">
      <PassedIcon />
      <span>{`${multiplier}x`}</span>
    </span>
  )
}
