import { action, computed, makeObservable, observable } from "mobx"
import { asSender } from "../../../generated/smartContractHelpers/asSender"
import { ConfirmTransactionStore } from "../../../stores/confirmTransactionDialogStore/ConfirmTransactionStore"
import { Currency } from "../../../utils/alexjs/Currency"
import { transfer } from "../../../utils/alexjs/postConditions"
import { asyncAction, runAsyncAction } from "../../../utils/asyncAction"
import { Result } from "../../../utils/Result"
import {
  RegisterFormError,
  RegisterFormErrorType,
} from "../component/ActionSection/types"
import { determineApower } from "./determineApower"
import LaunchPadContractStore from "./LaunchPadContractStore"

export interface RegisterFormData {
  ticket: number
  apowerAmount: number
  paymentAmount: number
}

class LaunchPadRegisterModule {
  constructor(readonly store: LaunchPadContractStore) {
    makeObservable(this)
  }

  @observable addTx = new ConfirmTransactionStore()

  @computed get formData(): Result<RegisterFormData, RegisterFormError> {
    if (this.ticketToRegister <= 0) {
      return Result.error({
        type: RegisterFormErrorType.EmptyTokenCount,
        message: "Enter an amount",
      })
    }

    if (this.ticketToRegister > this.maxAllowedTicket$) {
      return Result.error({
        type: RegisterFormErrorType.ExceedMaxAllowCount,
        message: `Exceeds maximum of ${this.maxAllowedTicket$} tickets per wallet`,
      })
    }

    if (
      this.willLockedAPowerCount$ >
      this.store.accountStore.getBalance$(Currency.APOWER)
    ) {
      return Result.error({
        type: RegisterFormErrorType.InsufficientAPowerBalance,
        message: "Insufficient balance",
      })
    }

    if (
      this.willLockedPriceTokenCount$ >
      this.store.accountStore.getBalance$(this.store.priceToken$)
    ) {
      return Result.error({
        type: RegisterFormErrorType.InsufficientPriceTokenBalance,
        message: "Insufficient balance",
      })
    }

    return Result.ok({
      ticket: this.ticketToRegister,
      paymentAmount: this.willLockedPriceTokenCount$,
      apowerAmount: this.willLockedAPowerCount$,
    })
  }

  @asyncAction async submitForm(
    formData: RegisterFormData,
    run = runAsyncAction,
  ): Promise<void> {
    try {
      const me = this.store.authStore.stxAddress$
      const { txId: transactionId } = await run(
        asSender(me)
          .contract("alex-launchpad-v1-1")
          .func("register")
          .call(
            {
              "ido-id": this.store.idoId,
              tickets: formData.ticket,
              "payment-token": this.store.priceToken$,
            },
            [
              transfer(me, this.store.priceToken$, formData.paymentAmount),
              transfer(me, Currency.APOWER, formData.apowerAmount),
            ],
          ),
      )
      this.addTx.successRunning(transactionId)
      await run(this.store.hasPendingMemPoolTx.triggerUpdate())
    } catch (e) {
      this.addTx.errorRunning(e as Error)
    }
  }

  @observable ticketToRegister = 0

  @action setTicketToRegister(amount: number): void {
    this.ticketToRegister = amount
  }

  @computed get willLockedAPowerCount$(): number {
    return (
      determineApower(
        this.ticketToRegister,
        this.store.detail$["apower-per-ticket-in-fixed"],
      ) / 1e8
    )
  }

  @computed get maxAllowedTicket$(): number {
    return this.store.detail$["registration-max-tickets"]
  }

  @computed get willLockedPriceTokenCount$(): number {
    return this.store.pricePerTicket$ * this.ticketToRegister
  }
}

export default LaunchPadRegisterModule
