import { FC } from "react"
import {
  CardInset,
  CardInsetDescription,
  CardInsetDivider,
  CardInsetTitle,
} from "../../../../../components/Card"
import { TokenCount } from "../../../../../components/TokenCount"
import { TokenIcon, TokenIconProps } from "../../../../../components/TokenIcon"
import { TokenName } from "../../../../../components/TokenName"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { ReactComponent as CongratsIcon } from "./congrats.svg"

export const Rewards: FC<{
  tokenAmountInfos: { info: TokenInfo; amount: number }[]
}> = props => {
  return (
    <CardInset className="flex flex-col items-center gap-y-2">
      <CongratsIcon />
      <CardInsetTitle className="text-lg leading-7 font-normal text-yellow-200">
        Congratulations!
      </CardInsetTitle>
      {props.tokenAmountInfos.length ? (
        <>
          <CardInsetDivider />
          <p className="text-xs leading-4 font-normal text-white/50">
            You will receive tokens:
          </p>
          <ul className="flex flex-col items-start w-full gap-y-2">
            {props.tokenAmountInfos.map(x => (
              <li
                key={x.info.id}
                className="flex flex-row items-center gap-x-1.5"
              >
                <TokenIcon token={x.info} size={TokenIconProps.sizes.small} />
                <TokenCount token={x.info} count={x.amount} />
                <TokenName token={x.info} />
              </li>
            ))}
          </ul>
        </>
      ) : (
        <CardInsetDescription className="text-xs leading-4 font-normal text-gray-200">
          Rewards will be sent directly to your wallet, please check.
        </CardInsetDescription>
      )}
    </CardInset>
  )
}
