import { FC, useState } from "react"
import { DetailCollapseButton } from "../../../../components/button/DetailCollapseButton"
import { HeadlessButton } from "../../../../components/button/HeadlessButton"
import { LoadableButton } from "../../../../components/button/LoadableButton"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { RedFilledButton } from "../../../../components/button/variants/RedFilledButton"
import { Card, CardTitle } from "../../../../components/Card"
import { SlippageSettingsButton } from "../../../../components/SlippageModalContent/WiredSlippageSettingsButton/WiredSlippageSettingsButton"
import { Spensor } from "../../../../components/Spensor"
import {
  BalanceBottomArea,
  EstimatedUSD,
} from "../../../../components/TokenInput/BalanceBottomArea"
import {
  BlockGroup,
  BlockGroupDownArrowIcon,
} from "../../../../components/TokenInput/BlockGroup"
import { TokenInput } from "../../../../components/TokenInput/TokenInput"
import { TokenNameSelectTrigger } from "../../../../components/TokenInput/TokenNameSelect"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import {
  readResource,
  safeReadResource,
  SuspenseResource,
} from "../../../../utils/SuspenseResource"
import { MaxSTXWarning } from "../../../Pool/components/ChangeLiquiditySection/ChangeLiquiditySection"
import { SwapFormErrorType } from "../../types"
import { SpotInfo, SpotInfoProps } from "../SpotInfo"
import { UnitPriceDescribe } from "./UnitPriceDescribe"

interface SpotFormProps extends SpotInfoProps {
  onSlippage?: () => void
  onClear?: () => void
  onConnectWallet?: () => void
  onConfirm?: () => void

  isMaxSTX: SuspenseResource<boolean>

  formError: SuspenseResource<SwapFormErrorType | undefined>

  fromToken: SuspenseResource<TokenInfo>
  fromAmount: number
  fromOnChange?: (newValue: number | null) => void
  fromBalance: SuspenseResource<number>
  fromUSD: SuspenseResource<number>
  onPressMax: SuspenseResource<undefined | (() => void)>
  fromShowSelectTokenModal?: () => void

  toToken: SuspenseResource<TokenInfo>
  toAmount: SuspenseResource<number>
  toBalance: SuspenseResource<number>
  toUSD: SuspenseResource<number>

  fromToExchangeRate: SuspenseResource<number>
  fromUnitUSD: SuspenseResource<number>
  toFromExchangeRate: SuspenseResource<number>
  toUnitUSD: SuspenseResource<number>

  toShowSelectTokenModal?: () => void

  exchangeToken?: () => void
}

export const SpotForm: FC<SpotFormProps> = props => {
  const [showSpotInfo, setShowSpotInfo] = useState(false)

  return (
    <Card className={"md:w-[30rem] mx-auto flex flex-col gap-3"}>
      <div className="flex flex-row items-center gap-2">
        <CardTitle className="mr-auto">Swap</CardTitle>
        {props.onClear && (
          <HeadlessButton className={"text-blue-600"} onClick={props.onClear}>
            Clear
          </HeadlessButton>
        )}
        <SlippageSettingsButton onClick={props.onSlippage} />
      </div>

      <BlockGroup
        icon={<BlockGroupDownArrowIcon onClick={props.exchangeToken} />}
        firstBlock={
          <TokenInput
            tokenNameArea={
              <TokenNameSelectTrigger
                token={safeReadResource(props.fromToken)}
                onClick={props.fromShowSelectTokenModal}
              />
            }
            token={props.fromToken}
            value={props.fromAmount}
            onChange={props.fromOnChange}
            error={
              safeReadResource(props.formError) ===
              SwapFormErrorType.InsufficientTokenBalance
            }
            bottomArea={
              <BalanceBottomArea
                token={props.fromToken}
                balance={props.fromBalance}
                rightSide={<EstimatedUSD usdCount={props.fromUSD} />}
                onPressMax={props.onPressMax}
              />
            }
          />
        }
        secondBlock={
          <TokenInput
            readonly={true}
            tokenNameArea={
              <TokenNameSelectTrigger
                token={safeReadResource(props.toToken)}
                onClick={props.toShowSelectTokenModal}
              />
            }
            token={props.toToken}
            value={safeReadResource(props.toAmount) ?? 0}
            bottomArea={
              safeReadResource(props.toToken) == null ? null : (
                <BalanceBottomArea
                  token={safeReadResource(props.toToken)!}
                  balance={props.toBalance}
                  rightSide={<EstimatedUSD usdCount={props.toUSD} />}
                />
              )
            }
          />
        }
      />

      <Spensor>
        {() => (
          <div className="flex items-center">
            <span className="flex items-center">
              <DetailCollapseButton
                show={showSpotInfo}
                onClick={() => setShowSpotInfo(s => !s)}
              />
              &nbsp;
              <span className={"text-sm text-gray-100"}>Details</span>
            </span>
            <UnitPriceDescribe
              className={"ml-auto"}
              fromToken={props.fromToken}
              toToken={props.toToken}
              fromToExchangeRate={props.fromToExchangeRate}
              fromUnitUSD={props.fromUnitUSD}
              toFromExchangeRate={props.toFromExchangeRate}
              toUnitUSD={props.toUnitUSD}
            />
          </div>
        )}
      </Spensor>

      {showSpotInfo && (
        <SpotInfo
          slippage={props.slippage}
          liquidityProviderFee={props.liquidityProviderFee}
          fromToken={props.fromToken}
          routes={props.routes}
          toToken={props.toToken}
          minimumReceived={props.minimumReceived}
        />
      )}

      {safeReadResource(props.isMaxSTX) && <MaxSTXWarning />}

      <Spensor
        fallback={
          <LoadableButton Variant={GradientFilledButton} loading={true}>
            Loading...
          </LoadableButton>
        }
      >
        {() => {
          const error = readResource(props.formError)
          if (error === SwapFormErrorType.TokenNotSelected) {
            return (
              <GradientFilledButton disabled={true}>
                Select Token
              </GradientFilledButton>
            )
          }
          if (error === SwapFormErrorType.WalletNotConnected) {
            return (
              <GradientFilledButton onClick={props.onConnectWallet}>
                Connect Wallet
              </GradientFilledButton>
            )
          }
          if (error === SwapFormErrorType.AmountIsEmpty) {
            return (
              <GradientFilledButton disabled={true}>
                Enter Amount
              </GradientFilledButton>
            )
          }
          if (error === SwapFormErrorType.InsufficientTokenBalance) {
            return (
              <RedFilledButton disabled={true}>
                Insufficient Balance
              </RedFilledButton>
            )
          }
          if (
            error === SwapFormErrorType.ErrorMaxInRatio ||
            error === SwapFormErrorType.ErrorMaxOutRatio
          ) {
            return (
              <RedFilledButton disabled={true}>
                Exceeds Liquidity Limit
              </RedFilledButton>
            )
          }
          return (
            <GradientFilledButton onClick={props.onConfirm}>
              Swap
            </GradientFilledButton>
          )
        }}
      </Spensor>
    </Card>
  )
}
