import { FC } from "react"
import { APowerTip } from "../../../components/APowerTip/APowerTip"
import { TokenCount } from "../../../components/TokenCount"
import { TokenIcon } from "../../../components/TokenIcon"
import { TokenInfoPresets } from "../../../utils/TokenInfoPresets/TokenInfoPresets"
import { ClaimTokenInfo } from "../../Stake/types"
import { TruncateTokenName } from "./TruncateTokenName"

export const ClaimTokenInfoList: FC<{ infos: ClaimTokenInfo[] }> = props => {
  return (
    <ul>
      {props.infos.map((c, idx) => (
        <li key={idx} className={"flex items-center gap-1.5"}>
          <TokenIcon token={c.token} size={16} />
          <TokenCount token={c.token} count={c.count} />
          <TruncateTokenName token={c.token} />
          {TokenInfoPresets.isAPower(c.token) ? <APowerTip /> : null}
        </li>
      ))}
    </ul>
  )
}
