import { action, computed, makeObservable, observable } from "mobx"
import AuthStore from "../../../stores/authStore/AuthStore"
import CurrencyStore from "../../../stores/currencyStore/CurrencyStore"
import { oneOf } from "../../../utils/arrayHelpers"
import { asyncAction, runAsyncAction } from "../../../utils/asyncAction"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { Result } from "../../../utils/Result"
import {
  safeReadResource,
  suspenseResource,
  SuspenseResource,
} from "../../../utils/SuspenseResource"
import { assertNever } from "../../../utils/types"
import type { UnitPriceDescription } from "../../Spot/components/SpotForm/UnitPriceDescribe"
import type { WrapBridgeConfirmationData } from "../components/WrapBridgePanel/WrapBridgeConfirmation/WrapBridgeConfirmation"
import {
  BridgeChain,
  ethBridgeChains,
  isETHBridgeChain,
  isUnwrappableETHBridgeChain,
  isWrappableETHBridgeChain,
} from "../types/BridgeChain"
import type { WrapBridgeNetwork } from "../types/types"
import { FormError, FormErrorType } from "../types/types"
import {
  BridgeCurrency,
  isUnwrappableBridgeCurrency,
  isWrappableBridgeCurrency,
  tokenInfoFromBridgeCurrency,
  tokenInfoToBridgeCurrency,
} from "./utils/BridgeCurrency"
import {
  wrapBridgeNetworkFromBridgeChain,
  wrapBridgeNetworkToBridgeChain,
} from "./utils/WrapBridgeNetwork"
import { FormData, WrapFormModule } from "./WrapFormModule"

export class WrapFormViewModule {
  constructor(
    private readonly currencyStore: Pick<
      CurrencyStore,
      "getTokenInfo$" | "getPrice$"
    >,
    private readonly authStore: Pick<
      AuthStore,
      "metaMaskModule" | "stxAddress$"
    >,
    private readonly module: WrapFormModule,
  ) {
    makeObservable(this)
  }

  @computed
  get availableETHNetworks$(): WrapBridgeNetwork[] {
    return ethBridgeChains.map(c => wrapBridgeNetworkFromBridgeChain(c))
  }
  @asyncAction async switchToETHNetwork(
    newNetwork: WrapBridgeNetwork,
    run = runAsyncAction,
  ): Promise<void> {
    const chain = wrapBridgeNetworkToBridgeChain(newNetwork)
    if (chain != null && oneOf(...ethBridgeChains)(chain)) {
      await run(this.module.switchToETHChain(chain))
    }
  }

  @computed get fromNetwork(): WrapBridgeNetwork {
    return wrapBridgeNetworkFromBridgeChain(this.module.fromChain)
  }
  @computed get toNetwork(): WrapBridgeNetwork {
    return wrapBridgeNetworkFromBridgeChain(this.module.toChain)
  }

  private get getAddressMap$(): [string, string] {
    if (this.module.fromChain === BridgeChain.Stacks) {
      return [
        this.authStore.stxAddress$,
        this.authStore.metaMaskModule.connectedWalletAddress.value$,
      ]
    }

    if (
      isETHBridgeChain(this.module.fromChain) ||
      this.module.fromChain === BridgeChain.Unknown
    ) {
      return [
        this.authStore.metaMaskModule.connectedWalletAddress.value$,
        this.authStore.stxAddress$,
      ]
    }

    assertNever(this.module.fromChain)
  }
  @computed get fromAddress$(): string {
    return this.getAddressMap$[0]
  }
  @computed get toAddress$(): string {
    return this.getAddressMap$[1]
  }

  @computed
  get availableFromTokens$(): TokenInfo[] {
    return this.module.fromChainCurrencyCandidates.map(c =>
      this.tokenInfoFromBridgeCurrency$(c),
    )
  }
  @computed get fromToken$(): TokenInfo {
    return this.tokenInfoFromBridgeCurrency$(this.module.fromChainCurrency)
  }
  @computed get fromTokenCountToUSD$(): number {
    return this.unitFromTokenUSD$ * this.module.fromTokenCount.read$
  }
  @action setFromToken(newToken: TokenInfo): void {
    const currency = tokenInfoToBridgeCurrency(newToken)
    if (currency != null) {
      this.module.setFromChainCurrency(currency)
    }
  }
  @computed get toToken$(): TokenInfo {
    return this.tokenInfoFromBridgeCurrency$(this.module.toChainCurrency)
  }
  @computed get toTokenCountToUSD$(): number {
    return this.unitToTokenUSD$ * this.module.toTokenCount$
  }

  @computed get unitTokenPrices$(): UnitPriceDescription {
    return {
      fromUnitUSD: this.unitFromTokenUSD$,
      fromToExchangeRate: 1,
      toUnitUSD: this.unitToTokenUSD$,
      toFromExchangeRate: 1,
    }
  }

  @computed get wrapFeeToken$(): TokenInfo {
    return this.tokenInfoFromBridgeCurrency$(this.module.wrapFeeCurrency$)
  }

  @observable confirmingFormData:
    | undefined
    | {
        confirmationData: SuspenseResource<WrapBridgeConfirmationData>
        executionFormData: FormData
      }

  @computed get formData$(): Result<
    {
      confirmationData: SuspenseResource<WrapBridgeConfirmationData>
      executionFormData: FormData
    },
    FormError
  > {
    let executionFormData: Pick<
      FormData,
      "fromNetwork" | "toNetwork" | "fromToken" | "toToken"
    >
    if (this.module.fromChain === BridgeChain.Stacks) {
      if (
        !isUnwrappableBridgeCurrency(this.module.fromChainCurrency) ||
        !isWrappableBridgeCurrency(this.module.toChainCurrency)
      ) {
        return Result.error({ type: FormErrorType.UnsupportedSourceToken })
      }

      if (!isUnwrappableETHBridgeChain(this.module.toChain)) {
        return Result.error({ type: FormErrorType.UnsupportedTargetChain })
      }

      executionFormData = {
        fromNetwork: this.module.fromChain,
        fromToken: this.module.fromChainCurrency,
        toNetwork: this.module.toChain,
        toToken: this.module.toChainCurrency,
      }
    } else if (isWrappableETHBridgeChain(this.module.fromChain)) {
      if (this.module.toChain !== BridgeChain.Stacks) {
        return Result.error({ type: FormErrorType.UnsupportedSourceChain })
      }

      if (
        !isWrappableBridgeCurrency(this.module.fromChainCurrency) ||
        !isUnwrappableBridgeCurrency(this.module.toChainCurrency)
      ) {
        return Result.error({ type: FormErrorType.UnsupportedSourceToken })
      }

      executionFormData = {
        fromNetwork: this.module.fromChain,
        fromToken: this.module.fromChainCurrency,
        toNetwork: this.module.toChain,
        toToken: this.module.toChainCurrency,
      }
    } else {
      return Result.error({ type: FormErrorType.UnsupportedSourceToken })
    }

    if (this.module.fromTokenCount.get() == null) {
      return Result.error({
        type: FormErrorType.AmountIsEmpty,
      })
    }

    if (
      this.module.fromTokenCount.read$ > this.module.fromChainCurrencyBalance$
    ) {
      return Result.error({ type: FormErrorType.InsufficientTokenBalance })
    }

    if (safeReadResource(suspenseResource(() => this.fromAddress$)) == null) {
      return Result.error({
        type: FormErrorType.SourceWalletNotConnected,
      })
    }

    if (safeReadResource(suspenseResource(() => this.toAddress$)) == null) {
      return Result.error({
        type: FormErrorType.TargetWalletNotConnected,
      })
    }

    return Result.ok({
      confirmationData: suspenseResource(() => ({
        fromNetwork: this.fromNetwork,
        fromToken: this.fromToken$,
        fromAmount: this.module.fromTokenCount.read$,
        fromAddress: this.fromAddress$,
        toNetwork: this.toNetwork,
        toToken: this.toToken$,
        toAmount: this.module.toTokenCount$,
        toAddress: this.toAddress$,
        toTokenAmountPerFromToken: this.unitTokenPrices$.fromToExchangeRate,
        fee: this.module.wrapFeeTokenCount$,
        feeToken: this.wrapFeeToken$,
        wrapCostedMilliseconds: this.module.wrapCostedMilliseconds$,
      })),
      executionFormData: {
        ...(executionFormData as any),
        fromAddress: this.fromAddress$,
        fromAmount: this.module.fromTokenCount.read$,
        toAddress: this.toAddress$,
        fee: this.module.wrapFeeTokenCount$,
      },
    })
  }

  @computed get openConfirmModal$(): undefined | (() => void) {
    const formData = this.formData$

    if (formData.type === "error") return

    return action(() => {
      this.confirmingFormData = formData.payload
    })
  }

  @action closeConfirmModal(): void {
    this.confirmingFormData = undefined
  }

  @computed private get unitFromTokenUSD$(): number {
    /**
     * TODO: how to get ERC20 token price
     */
    return 1
  }

  @computed private get unitToTokenUSD$(): number {
    /**
     * TODO: how to get ERC20 token price
     */
    return 1
  }

  private tokenInfoFromBridgeCurrency$(currency: BridgeCurrency): TokenInfo {
    return tokenInfoFromBridgeCurrency(currency, c =>
      this.currencyStore.getTokenInfo$(c),
    )
  }
}
