import clsx from "clsx"
import { ReactNode } from "react"
import { Card, CardTitle } from "../../../../../../components/Card"
import { FCC } from "../../../../../../utils/reactHelpers/types"

export const IDODetailSidePanelFrame: FCC<{
  className?: string
  titleRightSide?: ReactNode
}> = props => (
  <Card className={clsx("flex flex-col gap-6", props.className)}>
    <div className="flex flex-row items-center justify-between">
      <CardTitle>Total Raise</CardTitle>
      {props.titleRightSide}
    </div>

    {props.children}
  </Card>
)
