import { noop } from "lodash"
import { createContext, useContext, useEffect, useMemo, useState } from "react"
import { FCC } from "../../utils/reactHelpers/types"
import { createUseCheckNested } from "../../utils/reactHelpers/useCheckNested"
import { usePersistFn } from "../../utils/reactHelpers/usePersistFn"
import { LayoutContainer } from "./LayoutContainer"

interface RootLayoutContainerConfig {
  fullWidth: boolean
  topExtraPadding?: number
}

const RootLayoutContainerContext = createContext<{
  setConfig: (config: RootLayoutContainerConfig) => void
  reset: () => void
}>({
  setConfig: noop,
  reset: noop,
})

export interface RootLayoutContainerProviderProps {
  className?: string
  topNavHeight: number
  topExtraPadding: number
}

export const RootLayoutContainerProvider: FCC<
  RootLayoutContainerProviderProps
> = props => {
  const getInitialConfig = usePersistFn(
    (): RootLayoutContainerConfig => ({
      fullWidth: false,
    }),
  )

  const [config, setConfig] = useState(getInitialConfig)

  const providerValue = useMemo(
    () => ({
      setConfig,
      reset: () => setConfig(getInitialConfig),
    }),
    [getInitialConfig],
  )

  return (
    <RootLayoutContainerContext.Provider value={providerValue}>
      <LayoutContainer
        className={props.className}
        paddingHorizontal={config.fullWidth ? {} : undefined}
        paddingTop={
          props.topNavHeight + (config.topExtraPadding ?? props.topExtraPadding)
        }
        sizingClassName={config.fullWidth ? "w-full" : undefined}
      >
        {props.children}
      </LayoutContainer>
    </RootLayoutContainerContext.Provider>
  )
}

export interface RootLayoutContainerProps {
  fullWidth?: RootLayoutContainerConfig["fullWidth"]
  topExtraPadding?: RootLayoutContainerConfig["topExtraPadding"]
}

const CheckNested = createUseCheckNested("RootLayoutContainer")
export const RootLayoutContainer: FCC<RootLayoutContainerProps> = props => {
  const ctxValue = useContext(RootLayoutContainerContext)
  const { setConfig } = ctxValue

  useEffect(() => {
    setConfig({
      fullWidth: props.fullWidth ?? false,
      topExtraPadding: props.topExtraPadding,
    })
  }, [props.fullWidth, props.topExtraPadding, setConfig])

  useEffect(() => ctxValue.reset, [ctxValue.reset])

  CheckNested.useThrowNested()

  return <CheckNested.Provider>{props.children}</CheckNested.Provider>
}
