import clsx from "clsx"
import { FC, useMemo } from "react"
import { PathGenerator, usePathGenerator } from "../../routes/routes"
import { first } from "../../utils/arrayHelpers"
import { usePersistFn } from "../../utils/reactHelpers/usePersistFn"
import { OneOrMore } from "../../utils/types"
import { Dropdown, DropdownItem } from "../Dropdown"
import { NavLink, useCheckIsActive } from "../NavLink"
import { NavButton, NavButtonContainer } from "./NavButton/NavButton"
import styles from "./TopNavBar.module.scss"

export interface NavBarButtonItemData {
  type?: "button"
  name: string
  link: (pathGenerator: PathGenerator) => string
}

export interface NavBarDropdownItemData {
  type: "dropdown"
  name: string
  buttons: OneOrMore<NavBarButtonItemData>
}

export type NavBarItemData = NavBarButtonItemData | NavBarDropdownItemData

export const NavBarButtonItem: FC<{
  item: NavBarButtonItemData
}> = props => {
  const gen = usePathGenerator()

  const isHighlighted = useIsHighlightedButton()

  const { item } = props

  const link = item.link(gen)

  const isJumpingOutside =
    link.startsWith("http://") || link.startsWith("https://")

  return (
    <NavLink key={link} to={link}>
      {({ isActive }) => (
        <NavButtonContainer>
          <NavButton active={isActive} highlighted={isHighlighted(item)}>
            {item.name}

            {isJumpingOutside && (
              <svg
                className={"inline ml-[6px] opacity-30"}
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.36328 12V10.5L2.36328 10.5V1.5L7.36328 1.5V0H0.863281V12L7.36328 12ZM9.36328 7V9L12.8633 6L9.36328 3L9.36328 5H3.86328L3.86328 7H9.36328Z"
                  fill="#C4C4C4"
                />
              </svg>
            )}
          </NavButton>
        </NavButtonContainer>
      )}
    </NavLink>
  )
}

export const NavBarDropdownItem: FC<{
  item: NavBarDropdownItemData
}> = props => {
  const gen = usePathGenerator()

  const checkIsActive = useCheckIsActive()

  const firstButton = first(props.item.buttons)

  const isDropdownItemActive = useMemo(
    () => props.item.buttons.some(b => checkIsActive(b.link(gen))),
    [checkIsActive, gen, props.item.buttons],
  )

  return (
    <Dropdown
      placement={"start"}
      trigger={
        <NavLink to={firstButton.link(gen)} key={firstButton.link(gen)}>
          <NavButtonContainer>
            <NavButton active={isDropdownItemActive}>
              {props.item.name}
            </NavButton>

            <span style={{ padding: 6 }}>
              <svg
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M7.06798 8.50014L2.70626 4.17073L3.41337 3.46362L7.06826 7.1185L10.7226 3.46419L11.4297 4.17129L7.06798 8.50014Z" />
              </svg>
            </span>
          </NavButtonContainer>
        </NavLink>
      }
    >
      {props.item.buttons.map(b => (
        <NavLink key={b.link(gen)} to={b.link(gen)}>
          {({ isActive }) => (
            <DropdownItem
              contentContainerClassName={clsx(
                "flex items-center justify-between gap-2.5",
                isActive && styles.dropdownItemActive,
              )}
            >
              {b.name}
            </DropdownItem>
          )}
        </NavLink>
      ))}
    </Dropdown>
  )
}

const useIsHighlightedButton = (): ((
  button: NavBarButtonItemData,
) => boolean) => {
  return usePersistFn(
    _button =>
      // temporarily don't highlight any menu item
      false,
  )
}
