import clsx from "clsx"
import { format } from "date-fns"
import { FC, Fragment, useMemo } from "react"
import { Column, useTable } from "react-table"
import { CardModalContent } from "../../../../../components/CardModal/CardModal"
import { EmptyState } from "../../../../../components/EmptyState/EmptyState"
import { LoadingIndicator } from "../../../../../components/LoadingIndicator/LoadingIndicator"
import {
  Pagination,
  PaginationInfo,
} from "../../../../../components/Pagination"
import { Spensor } from "../../../../../components/Spensor"
import {
  Table,
  TableBodyRow,
  TableHeadRow,
  Tbody,
  Td,
  Th,
  Thead,
} from "../../../../../components/table/StyledTable"
import { TokenCount } from "../../../../../components/TokenCount"
import { TokenCountPairPlain } from "../../../../../components/TokenInput/TokenBlock"
import { TokenName } from "../../../../../components/TokenName"
import { arrayJoin } from "../../../../../utils/arrayHelpers"
import { customReactTableRender } from "../../../../../utils/customReactTableRender"
import {
  readResource,
  SuspenseResource,
} from "../../../../../utils/SuspenseResource"

export interface RebalanceRecord {
  time: Date
  myCollateral: TokenCountPairPlain[]
  estimateValue: TokenCountPairPlain
}

export interface CollateralRebalanceDetailModalContentProps {
  records: SuspenseResource<RebalanceRecord[]>
  paginationInfo: SuspenseResource<PaginationInfo>
  onPageNumChange?: (num: number) => void
  onClose?: () => void
}

export const CollateralRebalanceDetailModalContent: FC<
  CollateralRebalanceDetailModalContentProps
> = props => (
  <CardModalContent
    title={
      <span className="text-xl leading-7 font-medium text-gray-200">
        Collateral Rebalance Detail
      </span>
    }
    onClose={props.onClose}
    className={clsx("flex flex-col min-h-[80vh]")}
    width={"80vw"}
  >
    <Spensor
      fallback={
        <div className="w-full grow flex items-center justify-center">
          <LoadingIndicator className="mx-auto" size={72} />
        </div>
      }
    >
      {() => <DataTable records={readResource(props.records)} />}
    </Spensor>

    <Spensor>
      {() => (
        <Pagination
          {...readResource(props.paginationInfo)}
          onChange={i => props.onPageNumChange?.(i.page)}
        />
      )}
    </Spensor>
  </CardModalContent>
)

const DataTable: FC<{
  records: RebalanceRecord[]
}> = props => {
  const tableInstance = useTable({
    columns: useTableSchema(),
    data: props.records,
  })
  const { getTableProps, headerGroups, rows, getTableBodyProps, prepareRow } =
    tableInstance

  return (
    <div className="flex-1 w-full flex flex-col overflow-auto">
      <Table {...getTableProps()}>
        <Thead>
          {headerGroups.map(headerGroup => (
            <TableHeadRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <Th
                  {...column.getHeaderProps()}
                  className="text-sm leading-5 font-normal text-gray-500"
                >
                  {customReactTableRender(column as any, "Header")}
                </Th>
              ))}
            </TableHeadRow>
          ))}
        </Thead>

        <Tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <TableBodyRow {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                ))}
              </TableBodyRow>
            )
          })}
        </Tbody>
      </Table>

      {props.records.length === 0 && <EmptyState className={"m-auto"} />}
    </div>
  )
}

const useTableSchema = (): Column<RebalanceRecord>[] =>
  useMemo(
    () => [
      {
        Header: "Time",
        accessor: "time",
        Cell: props => <>{format(props.cell.value, "PPP, HH:mm")}</>,
      },
      {
        Header: "My Collateral",
        accessor: "myCollateral",
        Cell: props => (
          <>
            {arrayJoin(
              idx => (
                <Fragment key={`sep-${idx}`}>&nbsp;/&nbsp;</Fragment>
              ),
              props.cell.value.map((i, idx) => (
                <Fragment key={idx}>
                  <TokenCount token={i.token} count={i.count} />
                  &nbsp;
                  <TokenName token={i.token} />
                </Fragment>
              )),
            )}
          </>
        ),
      },
      {
        Header: "Est Value",
        accessor: "estimateValue",
        Cell: props => (
          <>
            <TokenCount
              token={props.cell.value.token}
              count={props.cell.value.count}
            />
            &nbsp;
            <TokenName token={props.cell.value.token} />
          </>
        ),
      },
    ],
    [],
  )
