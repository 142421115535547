import { action, makeObservable, observable } from "mobx"
import type { TransactionStateForGeneral } from "../../components/TransactionStateDialog/TransactionStateDialog"
import { CancelError } from "../../utils/error"

export class ConfirmTransactionStoreForGeneral {
  constructor() {
    makeObservable(this)
  }

  @observable running?: TransactionStateForGeneral

  @action closeRunning(): void {
    this.running = undefined
  }

  @action startRunning(msg?: string): void {
    this.running = {
      type: "running",
      msg,
    }
  }

  @action successRunning(explorerLink?: string): void {
    this.running = {
      type: "success",
      explorerLink,
    }
  }

  @action errorRunning(e: Error): void {
    if (e instanceof CancelError) {
      this.running = undefined
    } else {
      this.running = { type: "error", error: (e as Error).message }
    }
  }

  async run(
    fn: () => Promise<void | { explorerLink?: string }>,
  ): Promise<void> {
    try {
      this.startRunning()
      const resp = await fn()
      this.successRunning(resp?.explorerLink)
    } catch (e) {
      this.errorRunning(e as Error)
    }
  }
}
