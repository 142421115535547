import { StacksMainnet, StacksMocknet } from "@stacks/network"
import config from "./config.json"
import { Currency } from "./utils/alexjs/Currency"

export const ENV_NAME = (process.env.REACT_APP_ENV_NAME || config.ENV_NAME) as
  | "dev"
  | "prod"

export const STX_DX_API_HOST = config.STX_DX_API_HOST
export const WRAP_BRIDGE_API_HOST = config.WRAP_BRIDGE_API_HOST
export const IS_MAIN_NET = config.IS_MAIN_NET
export const IS_IN_MAINTENANCE_MODE = config.IS_IN_MAINTENANCE_MODE
export const IS_WRAP_BRIDGE_STANDALONE = config.IS_WRAP_BRIDGE_STANDALONE
export const API_HOST = config.API_HOST
export const ESTIMATED_BLOCK_DURATION = config.ESTIMATED_BLOCK_DURATION
export const REFRESH_FOR_NEW_BLOCK = config.REFRESH_FOR_NEW_BLOCK
export const PERSIST_KEY_PREFIX = config.PERSIST_KEY_PREFIX

export const STX_DX_API_HOST_WEBSOCKET = config.STX_DX_API_HOST.replace(
  /^https/,
  "wss",
)

// we need this in test envs
export const ALLOW_CONTRACT_ARGUMENTATION =
  process.env.REACT_APP_ALLOW_CONTRACT_ARGUMENTATION !== "false"

export const DISABLE_POST_CONDITION =
  // ALLOW_CONTRACT_ARGUMENTATION &&
  Boolean(
    new URLSearchParams(window.location.search).get("disablePostCondition"),
  ) || Boolean(new URLSearchParams(window.location.search).get("noPC"))

export const LAPLACE_GRAPHQL_API_HOST =
  (ALLOW_CONTRACT_ARGUMENTATION &&
    new URLSearchParams(window.location.search).get("asHasura")) ||
  config.LAPLACE_GRAPHQL_API_HOST

export const CONTRACT_DEPLOYER =
  (ALLOW_CONTRACT_ARGUMENTATION &&
    new URLSearchParams(window.location.search).get("asDeployer")) ||
  config.CONTRACT_DEPLOYER

export const CURRENCY_CONTRACT_OVERWRITES =
  config.CURRENCY_CONTRACT_OVERWRITES as { [key: string]: string }

export const STACK_NETWORK = IS_MAIN_NET
  ? new StacksMainnet({
      url: API_HOST,
    })
  : new StacksMocknet({
      url: API_HOST,
    })

export const STACK_APP_DETAILS = {
  name: "Alex app",
  icon: "https://alexgo.io/wp-content/themes/alex/img/logo_tm.png",
}

export const TG_SUB_URL = (txId: string): string =>
  `https://t.me/stacks_tx_notification_bot?start=${txId.replace(/^0x/, "")}`

export const EXPLORER_TX_URL = (txId: string): string =>
  `${config.EXPLORER_URL}/txid/${txId}`

export const EXPLORER_ADDR_URL = (address: string): string =>
  `${config.EXPLORER_URL}/address/${address}`

export const TOKEN_MAP_OVERWRITES: Partial<{
  [P in Currency]: `${string}.${string}::${string}`
}> = config.TOKEN_MAP_OVERWRITES as any

export const ORDER_BOOK_REFRESH_INTERVAL = 30 * 1000
