import { FC } from "react"
import { AutoStakingAPowerDistributionRowData } from "../../../../store/transformerGroups/stake/autoStakingAPowerDistribution"
import { AllTokenInfos } from "../../../../types"
import { BaseCellContainer } from "../../cells/BaseCellContainer"
import { OneLineAmountCell } from "../../cells/OneLineAmountCell"
import { OneLineCurrencyCell } from "../../cells/OneLineCurrencyCell"
import { BaseRow } from "../BaseRow"

export const AutoStakingAPowerDistributionRow: FC<{
  rowData: AutoStakingAPowerDistributionRowData
  allTokenInfos: AllTokenInfos
}> = ({ rowData, allTokenInfos }) => (
  <BaseRow
    rowData={rowData}
    currencyCell={
      <OneLineCurrencyCell
        allTokenInfos={allTokenInfos}
        fromCurrencyAmounts={[rowData.apowerCurrencyAmount]}
      />
    }
    amountCell={
      <OneLineAmountCell
        allTokenInfos={allTokenInfos}
        fromCurrencyAmounts={[rowData.apowerCurrencyAmount]}
      />
    }
    tradeInfoCell={
      <BaseCellContainer>
        Cycle #{rowData.cycle} Reward Harvest
      </BaseCellContainer>
    }
  />
)
