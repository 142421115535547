import { computed, makeObservable } from "mobx"
import { createTransformer } from "mobx-utils"
import AccountStore from "../../../../stores/accountStore/AccountStore"
import AuthStore from "../../../../stores/authStore/AuthStore"
import { ChainStore } from "../../../../stores/chainStore/ChainStore"
import CurrencyStore from "../../../../stores/currencyStore/CurrencyStore"
import { LazyValue } from "../../../../stores/LazyValue/LazyValue"
import type { IdoInfo } from "../../component/IDOListLargeCard/IDOListLargeCard"
import LaunchPadContentfulStore from "../LaunchPadContentfulStore"
import { LaunchingStatus } from "../LaunchPadContractStore"
import {
  getIDOAnalysisData,
  getIDOFromContentful,
} from "./LaunchpadListStore.service"

class LaunchpadListStore {
  constructor(
    readonly authStore: Pick<AuthStore, "stxAddress$" | "currentBlockHeight$">,
    readonly accountStore: Pick<AccountStore, "getBalance$" | "transactions$">,
    readonly currencyStore: Pick<CurrencyStore, "getPrice$" | "getTokenInfo$">,
    readonly chainStore: Pick<
      ChainStore,
      "estimatedDateForBlock$" | "currentBlockHeight$"
    >,
  ) {
    makeObservable(this)
  }

  private idos = new LazyValue(() => null, getIDOFromContentful)

  private analysisDataLazyValues = new Map<
    string,
    LazyValue<ReturnType<typeof getIDOAnalysisData>, any>
  >()

  getIdoAnalysisData$ = createTransformer(
    (
      idoTokenId: string,
    ): { participantsCount: number; allTimeHigh: number } | undefined => {
      if (!this.analysisDataLazyValues.has(idoTokenId)) {
        this.analysisDataLazyValues.set(
          idoTokenId,
          new LazyValue(
            () => [],
            () => getIDOAnalysisData(idoTokenId),
          ),
        )
      }
      return this.analysisDataLazyValues.get(idoTokenId)!.value$
    },
  )

  @computed({ keepAlive: true }) get idoStores$(): LaunchPadContentfulStore[] {
    return this.idos.value$.map(
      a =>
        new LaunchPadContentfulStore(
          { type: "fullDetail", detail: a },
          this.authStore,
          this.accountStore,
          this.currencyStore,
          this.chainStore,
        ),
    )
  }

  @computed get idoInfos$(): IdoInfo[] {
    return this.idoStores$.map(ido => {
      if (ido.isLegacyAlexIdo) {
        return {
          ...ido.contentfulDetail$,
          endedAt: ido.contentfulDetail$.finishedDate,
          allTimeHighTimes: ido.contentfulDetail$.finishedAllTimeHigh,
          participateCount: ido.contentfulDetail$.finishedParticipateWallets,
          filledPercent: ido.contentfulDetail$.finishedFillRate,
          type: "finished",
        }
      }

      if (ido.contractStore$ == null) {
        return {
          ...ido.contentfulDetail$,
          startedAt: ido.contentfulDetail$.estimateValidationStart,
          type: "upcoming",
        }
      }

      const commonData = {
        idoToken: ido.contractStore$.tokenInfo$,
        priceToken: ido.contractStore$.priceTokenInfo$,
        totalRaisePriceTokenCount:
          ido.contractStore$.totalTicketCount$ *
          ido.contractStore$.pricePerTicket$,
        pricePerIdoToken:
          ido.contractStore$.pricePerTicket$ /
          ido.contractStore$.idoTokenCountPerTicket$,
      }

      if (
        ido.contractStore$.steps.currentStatus$ === LaunchingStatus.Finished
      ) {
        return {
          type: "finished",
          endedAt: ido.contractStore$.steps.willFinishedAt$,
          ...commonData,
          filledPercent:
            ido.contractStore$.currentActivation.value$ /
            ido.contractStore$.totalTicketCount$,
          allTimeHighTimes: this.getIdoAnalysisData$(
            ido.contentfulDetail$.idoToken.id,
          )?.allTimeHigh,
          participateCount: this.getIdoAnalysisData$(
            ido.contentfulDetail$.idoToken.id,
          )?.participantsCount,
        }
      }

      if (
        ido.contractStore$.steps.currentStatus$ === LaunchingStatus.Upcoming
      ) {
        return {
          type: "upcoming",
          startedAt: ido.contractStore$.steps.registrationStartedAt$,
          ...commonData,
        }
      }

      return {
        type: "normal",
        ...commonData,
      }
    })
  }
}

export default LaunchpadListStore
