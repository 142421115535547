import clsx from "clsx"
import { FC } from "react"
import styles from "./RedGradientAlert.module.scss"
import { AlertCommonProps } from "./types"

export interface RedGradientAlertProps extends AlertCommonProps {
  className?: string
  withInteractionEffect?: boolean
}

export const RedGradientAlert: FC<RedGradientAlertProps> = props => (
  <div
    className={clsx(
      "p-3 sm:p-4",
      "rounded-lg",
      "text-sm leading-5 font-normal",
      styles.RedGradientAlert,
      props.withInteractionEffect && styles.withInteractionEffect,
      props.className,
    )}
  >
    {props.children}
  </div>
)
