/* eslint-disable @typescript-eslint/no-var-requires */

import { WrapBridgeNetwork } from "../types"

export namespace WrapBridgeNetworkPresets {
  export const Unknown: WrapBridgeNetwork = {
    id: "unknown",
    name: "Unknown",
    icon: require("./_/eth.svg").default,
  }
  export const Ethereum: WrapBridgeNetwork = {
    id: "ethereum",
    name: "Ethereum (ERC 20)",
    icon: require("./_/eth.svg").default,
  }
  export const Goerli: WrapBridgeNetwork = {
    id: "goerli",
    name: "Goerli (ERC 20)",
    icon: require("./_/eth.svg").default,
  }
  export const Stacks: WrapBridgeNetwork = {
    id: "stacks",
    name: "Stacks Chain",
    icon: require("./_/stacks.svg").default,
  }
}
