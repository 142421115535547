import { FungibleConditionCode } from "@stacks/transactions"
import { asSender } from "../../../../generated/smartContractHelpers/asSender"
import { AMMSwapPool } from "../../../../utils/alexjs/AMMSwapPool"
import { Currency } from "../../../../utils/alexjs/Currency"
import {
  autoTokenForYTP,
  isSimpleAlexPool,
  isYTPToken,
  LiquidityPoolToken,
  liquidityTokenPairs,
  yieldTokenForYTP,
} from "../../../../utils/alexjs/currencyHelpers"
import { AlexVault, transfer } from "../../../../utils/alexjs/postConditions"

export async function addLiquidity(
  stxAddress: string,
  poolToken: LiquidityPoolToken,
  amountA: number,
  dxDy: number,
  slippagePercentage: number,
): Promise<string> {
  const [tokenA, tokenB] = liquidityTokenPairs(poolToken)
  const maxDy = (amountA * (1 + slippagePercentage)) / dxDy
  const postConditions = [
    transfer(stxAddress, tokenA, amountA),
    transfer(stxAddress, tokenB, maxDy, FungibleConditionCode.LessEqual),
    transfer(AlexVault, poolToken, 0, FungibleConditionCode.GreaterEqual),
  ]
  if (isYTPToken(poolToken)) {
    const result = await asSender(stxAddress)
      .contract("collateral-rebalancing-pool-v1")
      .func("add-to-yield-token-pool-and-mint-auto")
      .call(
        {
          "max-dy": maxDy * 1e8,
          "token-trait": Currency.ALEX,
          dx: amountA * 1e8,
          "yield-token-trait": yieldTokenForYTP(poolToken),
          "pool-token-trait": poolToken,
          "auto-token-trait": autoTokenForYTP(poolToken),
        },
        [
          transfer(stxAddress, tokenA, amountA),
          transfer(stxAddress, tokenB, maxDy, FungibleConditionCode.LessEqual),
          transfer(
            stxAddress,
            poolToken,
            0,
            FungibleConditionCode.GreaterEqual,
          ),
        ],
      )
    return result.txId
  }
  if (AMMSwapPool.isPoolToken(poolToken)) {
    const result = await asSender(stxAddress)
      .contract("amm-swap-pool")
      .func("add-to-position")
      .call(
        {
          factor: AMMSwapPool.getFactor(poolToken),
          "token-x-trait": tokenA,
          "token-y-trait": tokenB,
          dx: amountA * 1e8,
          "max-dy": maxDy * 1e8,
        },
        postConditions,
      )
    return result.txId
  }
  if (isSimpleAlexPool(poolToken)) {
    const result = await asSender(stxAddress)
      .contract("simple-weight-pool-alex")
      .func("add-to-position")
      .call(
        {
          "token-x-trait": tokenA,
          "token-y-trait": tokenB,
          dx: amountA * 1e8,
          "max-dy": maxDy * 1e8,
          "pool-token-trait": poolToken,
        },
        postConditions,
      )
    return result.txId
  }
  const result = await asSender(stxAddress)
    .contract("fixed-weight-pool-v1-01")
    .func("add-to-position")
    .call(
      {
        // tokenA, tokenB, 0.5e8, 0.5e8, poolToken, amountA * 1e8, maxDy * 1e8
        "token-x-trait": tokenA,
        "token-y-trait": tokenB,
        "weight-x": 0.5e8,
        "weight-y": 0.5e8,
        "pool-token-trait": poolToken,
        dx: amountA * 1e8,
        "max-dy": maxDy * 1e8,
      },
      postConditions,
    )
  return result.txId
}
