import { FC } from "react"
import { plural } from "../../../../../../utils/stringHelpers"
import { ManualStakingRowData } from "../../../../store/transformerGroups/stake/manualStaking"
import { AllTokenInfos } from "../../../../types"
import { BaseCellContainer } from "../../cells/BaseCellContainer"
import { OneLineAmountCell } from "../../cells/OneLineAmountCell"
import { OneLineCurrencyCell } from "../../cells/OneLineCurrencyCell"
import { BaseRow } from "../BaseRow"

export const ManualStakingRow: FC<{
  rowData: ManualStakingRowData
  allTokenInfos: AllTokenInfos
}> = ({ rowData, allTokenInfos }) => (
  <BaseRow
    rowData={rowData}
    currencyCell={
      <OneLineCurrencyCell
        allTokenInfos={allTokenInfos}
        fromCurrencyAmounts={[rowData.principalCurrencyAmount]}
      />
    }
    amountCell={
      <OneLineAmountCell
        allTokenInfos={allTokenInfos}
        fromCurrencyAmounts={[rowData.principalCurrencyAmount]}
      />
    }
    tradeInfoCell={
      <BaseCellContainer>
        Staking {rowData.period}{" "}
        {plural(rowData.period, { one: "Cycle", many: "Cycles" })}
      </BaseCellContainer>
    }
  />
)
