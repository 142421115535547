import clsx from "clsx"
import { FC } from "react"

const Segment: FC<{
  className?: string
  boxClassName?: string
  textClassName?: string
  title: string
  onClick?: () => void
  isActive?: boolean
}> = props => (
  <button
    className={clsx(
      "flex justify-center items-center rounded",
      props.boxClassName ?? "px-3 sm:px-5 min-h-[48px]",
      props.textClassName ?? "font-medium text-sm leading-tight sm:text-lg",
      props.isActive
        ? "text-gray-200 bg-white bg-opacity-20 backdrop-blur-2xl hover:bg-opacity-10 active:bg-opacity-5"
        : "text-gray-500 bg-black bg-opacity-0 hover:bg-opacity-10 active:bg-opacity-30 cursor-pointer",
      props.className,
    )}
    onClick={props.onClick}
  >
    {props.title}
  </button>
)

export const SegmentControl: FC<{
  className?: string
  boxClassName?: string
  segmentBoxClassName?: string
  segmentTextClassName?: string
  controls: { title: string; isActive?: boolean; onClick?: () => void }[]
}> = props => {
  return (
    <div
      className={clsx(
        "flex justify-between bg-black bg-opacity-20 rounded-lg",
        props.boxClassName ?? "p-2",
        props.className,
      )}
    >
      {props.controls.map(c => (
        <Segment
          key={c.title}
          boxClassName={props.segmentBoxClassName}
          textClassName={props.segmentTextClassName}
          title={c.title}
          onClick={c.onClick}
          isActive={c.isActive}
        />
      ))}
    </div>
  )
}
