import { noop } from "lodash"
import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { HeadlessButton } from "../../../../components/button/HeadlessButton"
import { Dropdown, DropdownItem } from "../../../../components/Dropdown"
import { Spensor } from "../../../../components/Spensor"
import { oneOf } from "../../../../utils/arrayHelpers"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import {
  readResource,
  safeReadResource,
  SuspenseResource,
} from "../../../../utils/SuspenseResource"
import { TradingFormError, TradingFormErrorType } from "../types"
import { AvailableOutgoingTokenCount } from "./_/AvailableOutgoingTokenCount"
import { formFields$t } from "./_/commonIntlMessages"
import { LabelTokenInput, LabelTokenInputReadonly } from "./_/LabelTokenInput"
import { PercentSlider } from "./_/PercentSlider"

interface MarketOrderFormProps {
  formError: SuspenseResource<undefined | TradingFormError>
  outgoingToken: TokenInfo
  maxAvailableOutgoingTokenCount: SuspenseResource<number>
  priceToken: TokenInfo
  tradeToken: TokenInfo
  pieceToken: TokenInfo
  pieceTokenCount: SuspenseResource<number>
  pieceTokenCountPercentage: SuspenseResource<number>
  onPieceTokenChange: (token: TokenInfo) => void
  onPieceTokenCountChange: (count: null | number) => void
  onPieceTokenCountPercentageChange: (percentage: number) => void
}

export const MarketOrderForm: FC<MarketOrderFormProps> = props => {
  const { $t } = useIntl()
  return (
    <>
      <AvailableOutgoingTokenCount
        token={props.outgoingToken}
        tokenCount={props.maxAvailableOutgoingTokenCount}
      />

      <LabelTokenInputReadonly
        label={$t(formFields$t.price)}
        token={props.priceToken}
      >
        <span className={"text-base leading-6 font-semibold text-gray-200"}>
          {$t(
            defineMessage({
              defaultMessage: "Market Price",
              description:
                'Orderbook/Create Order Panel/"Market Order" form "Price" field value',
            }),
          )}
        </span>
      </LabelTokenInputReadonly>

      <LabelTokenInput
        label={
          $t(formFields$t.amount)
          // <PieceTokenSelector
          //   priceToken={props.priceToken}
          //   tradeToken={props.tradeToken}
          //   pieceToken={props.pieceToken}
          //   onPieceTokenChange={props.onPieceTokenChange}
          // />
        }
        token={props.pieceToken}
        disabled={safeReadResource(props.maxAvailableOutgoingTokenCount) === 0}
        value={props.pieceTokenCount}
        onChange={props.onPieceTokenCountChange}
        error={
          props.priceToken.id === props.tradeToken.id
            ? oneOf(TradingFormErrorType.InsufficientPriceTokenBalance)(
                safeReadResource(props.formError)?.type,
              )
            : oneOf(
                TradingFormErrorType.InsufficientTradeTokenBalance,
                TradingFormErrorType.TradeTokenCountTooSmall,
              )(safeReadResource(props.formError)?.type)
        }
      />

      <Spensor
        fallback={<PercentSlider disabled={true} value={0} onChange={noop} />}
      >
        {() => (
          <PercentSlider
            value={readResource(props.pieceTokenCountPercentage)}
            onChange={props.onPieceTokenCountPercentageChange}
            disabled={
              safeReadResource(props.maxAvailableOutgoingTokenCount) === 0
            }
          />
        )}
      </Spensor>
    </>
  )
}

const PieceTokenSelector: FC<{
  priceToken: TokenInfo
  tradeToken: TokenInfo
  pieceToken: TokenInfo
  onPieceTokenChange: (token: TokenInfo) => void
}> = props => {
  const pieceTokenType =
    props.pieceToken.id === props.tradeToken.id ? "amount" : "total"

  const { $t } = useIntl()

  return (
    <Dropdown
      triggerContainerClassName={"w-fit-content"}
      triggerMethod={"click"}
      dismissMethod={"click"}
      trigger={
        <HeadlessButton className={"flex items-center text-gray-200"}>
          {pieceTokenType === "amount"
            ? $t(formFields$t.amount)
            : $t(formFields$t.total)}
          &nbsp;
          <DropdownArrowIcon />
        </HeadlessButton>
      }
    >
      <DropdownItem
        selected={pieceTokenType === "amount"}
        onClick={() => props.onPieceTokenChange(props.tradeToken)}
      >
        {$t(formFields$t.amount)}
      </DropdownItem>
      <DropdownItem
        selected={pieceTokenType === "total"}
        onClick={() => props.onPieceTokenChange(props.priceToken)}
      >
        {$t(formFields$t.total)}
      </DropdownItem>
    </Dropdown>
  )
}

const DropdownArrowIcon: FC = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.20451 8.50136L1.8428 4.17196L2.54991 3.46484L6.2048 7.11972L9.85911 3.46541L10.5662 4.17251L6.20451 8.50136Z"
      fill="#C4C4C4"
    />
  </svg>
)
