import { FC } from "react"
import { BuyLotteryRowData } from "../../../../store/transformerGroups/lottery/buyLottery"
import { AllTokenInfos } from "../../../../types"
import { BaseCellContainer } from "../../cells/BaseCellContainer"
import { OneLineAmount } from "../../OneLineAmount"
import { OneLineCurrency } from "../../OneLineCurrency"
import { OneLineTokenAmount } from "../../OneLineTokenAmount"
import { BaseRow } from "../BaseRow"

export const BuyLotteryRow: FC<{
  rowData: BuyLotteryRowData
  allTokenInfos: AllTokenInfos
}> = ({ rowData, allTokenInfos }) => (
  <BaseRow
    rowData={rowData}
    currencyCell={
      <BaseCellContainer>
        <OneLineCurrency
          fromCurrencyAmounts={[rowData.tokenCurrencyAmount]}
          allTokenInfos={allTokenInfos}
        />
        {Boolean(rowData.bonusTickets) && (
          <OneLineCurrency
            fromCurrencyAmounts={[rowData.bonusCurrencyAmount]}
            allTokenInfos={allTokenInfos}
          />
        )}
        <p>→&nbsp;Lottery</p>
      </BaseCellContainer>
    }
    amountCell={
      <BaseCellContainer>
        <OneLineAmount
          allTokenInfos={allTokenInfos}
          fromCurrencyAmounts={[rowData.tokenCurrencyAmount]}
        />
        <p className="whitespace-nowrap">
          →&nbsp;{rowData.tickets}&nbsp;Lottery
        </p>
        {Boolean(rowData.bonusTickets) && (
          <>
            <p className="whitespace-nowrap">
              <OneLineTokenAmount
                currencyAmount={rowData.bonusCurrencyAmount}
                allTokenInfos={allTokenInfos}
              />
            </p>
            <p className="whitespace-nowrap">
              →&nbsp;{rowData.bonusTickets}&nbsp;Lottery
            </p>
          </>
        )}
      </BaseCellContainer>
    }
    tradeInfoCell={
      <BaseCellContainer>Round #{rowData.lotteryId}</BaseCellContainer>
    }
  />
)
