import { action, makeObservable, observable } from "mobx"
import AccountStore from "../../../stores/accountStore/AccountStore"
import AuthStore from "../../../stores/authStore/AuthStore"
import { ChainStore } from "../../../stores/chainStore/ChainStore"
import CurrencyStore from "../../../stores/currencyStore/CurrencyStore"
import { ChartDatafeedModule } from "./modules/ChartDatafeedModule"
import { OrderbookAccountSettingModule } from "./modules/OrderbookAccountSettingModule"
import { OrderbookDepositModule } from "./modules/OrderbookDepositModule"
import { OrderbookInfoModule } from "./modules/OrderbookInfoModule"
import { OrderbookLongTextModule } from "./modules/OrderbookLongTextModule"
import { OrderbookMyHistoryModule } from "./modules/OrderbookMyHistoryModule"
import { OrderbookMyInfoModule } from "./modules/OrderbookMyInfoModule"
import { OrderbookOrderbookModule } from "./modules/OrderbookOrderbookModule"
import { OrderbookTradeModule } from "./modules/OrderbookTradeModule"
import { OrderbookWithdrawModule } from "./modules/OrderbookWithdrawModule"
import { StxDxMarket } from "./OrderbookStore.service/OrderbookStore.service"

export class OrderbookStore {
  constructor(
    readonly chainStore: ChainStore,
    readonly authStore: AuthStore,
    readonly currency: CurrencyStore,
    readonly account: AccountStore,
  ) {
    makeObservable(this)
  }
  destroy(): void {
    this.accountSetting.destroy()
  }

  @observable market: StxDxMarket = StxDxMarket.BtcUsd

  @action setMarket(market: StxDxMarket): void {
    this.market = market
    this.trade = new OrderbookTradeModule(this)
  }

  longText = new OrderbookLongTextModule()
  info = new OrderbookInfoModule(this)
  myInfo = new OrderbookMyInfoModule(this)
  orderbook = new OrderbookOrderbookModule(this)
  deposit = new OrderbookDepositModule(this)
  withdraw = new OrderbookWithdrawModule(this)
  @observable.ref trade = new OrderbookTradeModule(this)
  myHistory = new OrderbookMyHistoryModule(this)
  accountSetting = new OrderbookAccountSettingModule(this)
  chartDatafeed = new ChartDatafeedModule(this)
}
