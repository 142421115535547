import { gql } from "@urql/core"
import { unwrapResponse } from "clarity-codegen"
import { Observable, of } from "rxjs"
import { CONTRACT_DEPLOYER } from "../../../../config"
import {
  GetAmmPoolLiquidityInfoQuery,
  GetAmmPoolLiquidityInfoQueryVariables,
  GetPoolLiquidityInfoQuery,
  GetPoolLiquidityInfoQueryVariables,
  GetYtpLiquidityInfoQuery,
  GetYtpLiquidityInfoQueryVariables,
} from "../../../../generated/graphql/graphql.generated"
import {
  asSender,
  contractAddr,
} from "../../../../generated/smartContractHelpers/asSender"
import {
  isYTPToken,
  LiquidityPoolToken,
  YTPToken,
} from "../../../../utils/alexjs/currencyHelpers"
import { first, hasAny } from "../../../../utils/arrayHelpers"
import { gqlQuery } from "../../../../utils/graphqlHelpers"
import { fromUrqlSource } from "../../../../utils/Observable/fromUrqlSource"
import pMemoize from "../../../../utils/pMemorize"
import { optionally } from "../../../../utils/utils"

export type LiquidityInfo = {
  liquidity?: number
  volume24h?: number
  volume7d?: number
  apr?: number
  tokenXPercentage?: number
  tokenYPercentage?: number
  totalSupply?: number
}

export function getAMMPoolLiquidityInfo(
  poolId: number,
  tokenXPrice: number,
): Observable<LiquidityInfo> {
  return fromUrqlSource(
    gqlQuery(
      gql<GetAmmPoolLiquidityInfoQuery, GetAmmPoolLiquidityInfoQueryVariables>`
        query GetAMMPoolLiquidityInfo($poolId: numeric!) {
          laplace_latest_amm_pool_stats(where: { pool_id: { _eq: $poolId } }) {
            liquidity
            apr_7d
            volume_7d
            volume_24h
            total_supply
          }
        }
      `,
      { poolId },
    ),
    resp => {
      if (!hasAny(resp.data.laplace_latest_amm_pool_stats)) {
        console.error(new Error(`Pool ${poolId} not found from AMM API`))
        return {}
      }

      const pool = first(resp.data.laplace_latest_amm_pool_stats)

      return {
        liquidity: optionally(pool.liquidity, a => (a / 1e8) * tokenXPrice),
        apr: optionally(pool.apr_7d, a => a / 1e8),
        volume24h: optionally(pool.volume_24h, a => (a / 1e8) * tokenXPrice),
        volume7d: optionally(pool.volume_7d, a => (a / 1e8) * tokenXPrice),
        totalSupply: optionally(pool.total_supply, a => a / 1e8),
      }
    },
  )
}

export function getPoolLiquidityInfo(
  token: LiquidityPoolToken,
  tokenXPrice: number,
): Observable<LiquidityInfo> {
  return fromUrqlSource(
    gqlQuery(
      gql<GetPoolLiquidityInfoQuery, GetPoolLiquidityInfoQueryVariables>`
        query GetPoolLiquidityInfo($token: String!) {
          laplace_latest_pool_stats(where: { pool_token: { _eq: $token } }) {
            liquidity
            apr_7d
            volume_7d
            volume_24h
            total_supply
          }
        }
      `,
      { token },
    ),
    resp => {
      if (!hasAny(resp.data.laplace_latest_pool_stats)) {
        console.error(new Error(`Pool ${token} not found from API`))
        return {}
      }

      const pool = first(resp.data.laplace_latest_pool_stats)

      return {
        liquidity: optionally(pool.liquidity, a => (a / 1e8) * tokenXPrice),
        apr: optionally(pool.apr_7d, a => a / 1e8),
        volume24h: optionally(pool.volume_24h, a => (a / 1e8) * tokenXPrice),
        volume7d: optionally(pool.volume_7d, a => (a / 1e8) * tokenXPrice),
        totalSupply: optionally(pool.total_supply, a => a / 1e8),
      }
    },
  )
}

export const currentExpiry = pMemoize(
  async (pool: YTPToken): Promise<number> => {
    return await asSender(CONTRACT_DEPLOYER)
      .contract("collateral-rebalancing-pool-v1")
      .func("get-expiry")
      .call({ "pool-token": pool })
      .then(unwrapResponse)
  },
)

export function getYTPPoolInfo(token: LiquidityPoolToken): Observable<{
  tokenXBalance: number
  tokenYBalance: number
} | null> {
  if (!isYTPToken(token)) {
    return of(null)
  }
  return fromUrqlSource(
    gqlQuery(
      gql<GetYtpLiquidityInfoQuery, GetYtpLiquidityInfoQueryVariables>`
        query GetYTPLiquidityInfo($ytpToken: String!) {
          laplace_latest_yield_token_pool(
            where: { pool_token: { _eq: $ytpToken } }
            order_by: { expiry: desc }
            limit: 1
          ) {
            balance_token
            balance_yield_token
          }
        }
      `,
      { ytpToken: contractAddr(token) },
    ),
    resp => {
      if (!hasAny(resp.data.laplace_latest_yield_token_pool)) {
        throw new Error(`Pool ${token} not found from API`)
      }
      const pool = first(resp.data.laplace_latest_yield_token_pool)
      return {
        tokenXBalance: pool.balance_token ?? 0,
        tokenYBalance: pool.balance_yield_token ?? 0,
      }
    },
  )
}
