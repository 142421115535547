import {
  ContractCallTransaction,
  MempoolTransaction,
  Transaction,
} from "@stacks/stacks-blockchain-api-types"
import {
  ParameterObjOfDescriptor,
  ReturnTypeOfDescriptor,
} from "clarity-codegen"
import { AlexContracts } from "../../generated/smartContract/contracts_Alex"
import { Currency, DownstreamCurrency } from "../../utils/alexjs/Currency"
import { TokenInfo } from "../../utils/models/TokenInfo"
import type {
  ALL_TRANSACTIONS_FILTER,
  SYSTEM_MESSAGE_FILTER,
} from "./constants"
import type { CollectionFilter } from "./store/TransactionsModule"

export type TransactionFilter =
  | typeof ALL_TRANSACTIONS_FILTER
  | CollectionFilter

export type NotifyFilter = TransactionFilter | typeof SYSTEM_MESSAGE_FILTER

/**
 * generate using [contractName, functionName] tuple
 */
export type NotifyTransactionFilter = (
  value: Transaction | MempoolTransaction,
) => boolean

// row data types
export enum NotifyTransactionStatus {
  Pending = "pending",
  Confirmed = "confirmed",
  Failed = "failed",
}

export enum NotifyTransactionType {
  // swap
  Swap = "Swap",

  // pool
  AddLiquidity = "Add Liquidity",
  RemoveLiquidity = "Remove Liquidity",

  // stake
  ManualStaking = "Staking",
  StakingHarvest = "Staking Harvest",

  StakingHarvestAndMintAtAlex = "Staking Harvest and Mint atALEX",
  AutoStaking = "atALEX Staking",
  AutoStakingAPowerDistribution = "atALEX APower Distribution",

  // farm
  Farm = "LP Farm",
  FarmHarvest = "LP Harvest",
  CoFarm = "Co-Farm",
  CoFarmAutoHarvest = "Co-Farm Auto Harvest",
  CoFarmAPowerDistribution = "Co-Farm APower Distribution",
  CoFarmClaim = "Co-Farm Claim",

  // lend
  Deposit = "Deposit",
  DepositClaim = "Deposit Claim",
  DepositRollover = "Deposit Rollover",
  DepositSell = "Deposit Sell",

  Borrow = "Borrow",
  BorrowClaim = "Borrow Claim",
  BorrowRollover = "Borrow Rollover",

  // lottery
  BuyLottery = "Buy Lottery",
  LotteryWon = "Lottery Won",
}

export interface BaseRowData {
  id: string
  status: NotifyTransactionStatus
  time: Date
  type: NotifyTransactionType
}

export interface Transfer {
  currency: Currency | DownstreamCurrency
  recipient?: string
  sender?: string
  amount: number
}

export type CurrencyAmount = CurrencyAmount.All
export type AllTokenInfos = { [key in Currency]?: TokenInfo }
export namespace CurrencyAmount {
  interface Base {
    currency: Currency
  }

  export type Unknown = Base
  export function isUnknown(value: All): value is Unknown {
    return (
      !Boolean((value as Confirmed).amount) &&
      !Boolean((value as Minimal).minAmount) &&
      !Boolean((value as Maximum).maxAmount)
    )
  }
  export interface Confirmed extends Base {
    amount: number
    minAmount?: number
    maxAmount?: number
  }
  export function isConfirmed(value: All): value is Confirmed {
    return Boolean((value as Confirmed).amount)
  }

  export interface Minimal extends Base {
    minAmount: number
  }
  export function isMinimal(value: All): value is Minimal {
    return (
      Boolean((value as Minimal).minAmount) &&
      !Boolean((value as Confirmed).amount)
    )
  }

  export interface Maximum extends Base {
    maxAmount: number
  }
  export function isMaximum(value: All): value is Maximum {
    return (
      Boolean((value as Maximum).maxAmount) &&
      !Boolean((value as Confirmed).amount)
    )
  }

  export type All = Unknown | Confirmed | Minimal | Maximum
}

/**
 * Transformer group represents a group of transformer functions for a specific contract call transaction.
 * Each Transformer group has a list of contracts and a function name to match the contract call transaction.
 * It also has confirmed / failed / pending transformer logic to deal with different transaction status.
 */
export interface TransformerGroup<
  ContractNames extends Readonly<Array<keyof typeof AlexContracts>>,
  FunctionName extends keyof typeof AlexContracts[ContractNames[number]],
  Row extends BaseRowData = BaseRowData,
  Context extends Record<string, unknown> = { address: string },
> {
  contracts: ContractNames
  functionName: FunctionName
  type: Row["type"]
  /**
   * `filterFn` will generate automatically based on the contracts and function name.
   * However, you can override it if you want to customize the filter logic.
   */
  filterFn?: NotifyTransactionFilter
  confirmedTransformer: (
    helpers: {
      getTx: () => ContractCallTransaction
      getArgs: GetArgs<ContractNames, FunctionName>
      getResult: GetResult<ContractNames, FunctionName>
      getTransfers: () => Transfer[]
    },
    context?: Context,
  ) => GetRowReturn<Row> | undefined
  pendingOrFailedTransformer: (
    args: ReturnType<GetArgs<ContractNames, FunctionName>>,
    tx: ContractCallTransaction | MempoolTransaction,
  ) => GetRowReturn<Row> | undefined
}

/**
 * TransformerCollection is a collection of TransformerGroup corresponding to a specific notify tabbar.
 */
export interface TransformerCollection {
  [key: string]: TransformerGroup<any, any>
}

export interface ExporterCollection {
  [key: string]: Exporter<any>
}

export interface PaginationInfo {
  pageIndex: number
  setPageIndex: (index: number) => void
  pageSize: number
  totalRows: number
}

export type ContractCallsTuple<
  ContractNames extends Readonly<Array<keyof typeof AlexContracts>> = any,
  FunctionName extends keyof typeof AlexContracts[ContractNames[number]] = any,
> = [ContractNames, FunctionName]

// helpers
export type GetArgs<
  ContractNames extends Readonly<Array<keyof typeof AlexContracts>>,
  FunctionName extends keyof typeof AlexContracts[ContractNames[number]],
> = () => ParameterObjOfDescriptor<
  typeof AlexContracts[ContractNames[number]][FunctionName]
>

export type GetResult<
  ContractNames extends Readonly<Array<keyof typeof AlexContracts>>,
  FunctionName extends keyof typeof AlexContracts[ContractNames[number]],
> = () => ReturnTypeOfDescriptor<
  typeof AlexContracts[ContractNames[number]][FunctionName]
>

export type GetRowReturn<Row extends BaseRowData = BaseRowData> = Omit<
  Row,
  "id" | "status" | "time" | "type"
>

export type CsvRow = [
  string, // id
  string, // type
  string, // status
  string, // amount
  string, // tradeInfo
  string, // time
]
export type Exporter<Row extends BaseRowData = BaseRowData> = (
  row: Row,
  allTokenInfos: AllTokenInfos,
) => CsvRow

export namespace SystemMessages {
  export enum Types {
    StakingHarvest = "Staking Harvest",
    FarmingHarvest = "Farming Harvest",
    DepositClaim = "Deposit Claim",
    BorrowClaim = "Borrow Claim",
  }

  export interface Data {
    type: Types
    token: TokenInfo
  }
}
