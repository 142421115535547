import { FC } from "react"
import { GradientFilledButton } from "../../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteFilledButton } from "../../../../../components/button/variants/WhiteFilledButton"
import { CardPlate } from "../../../../../components/Card"
import { CardModalContent } from "../../../../../components/CardModal/CardModal"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../../components/InfoList"
import { PercentNumber } from "../../../../../components/PercentNumber"
import { TextTokenCount } from "../../../../../components/RichTokenCount"
import { TokenCount } from "../../../../../components/TokenCount"
import {
  BlockTopLine,
  BlockTopTitleLine,
} from "../../../../../components/TokenInput/Block"
import {
  BlockGroup,
  BlockGroupDownArrowIcon,
} from "../../../../../components/TokenInput/BlockGroup"
import {
  TokenBlock,
  TokenCountPairPlain,
} from "../../../../../components/TokenInput/TokenBlock"
import { TokenName } from "../../../../../components/TokenName"

export interface ConfirmSellDepositModalContentProps {
  yieldAssets: TokenCountPairPlain
  depositAssets: TokenCountPairPlain
  liquidityProviderFee: TokenCountPairPlain
  slippage: number

  yieldTokenPrice: {
    toCrypto: TokenCountPairPlain
    toUSD: number
  }

  onConfirm?: () => void
  onClose?: () => void
}

export const ConfirmSellDepositModalContent: FC<
  ConfirmSellDepositModalContentProps
> = props => (
  <CardModalContent
    onClose={props.onClose}
    title={"Confirm Sell"}
    className={"flex flex-col gap-4"}
    width={480}
  >
    <BlockGroup
      firstBlock={
        <TokenBlock
          topArea={<BlockTopLine>From</BlockTopLine>}
          tokens={[props.yieldAssets]}
        />
      }
      icon={<BlockGroupDownArrowIcon />}
      secondBlock={
        <TokenBlock
          topArea={<BlockTopTitleLine>To</BlockTopTitleLine>}
          tokens={[props.depositAssets]}
        />
      }
    />

    <InfoList>
      <InfoListItem>
        <InfoListItemTitle>Price</InfoListItemTitle>
        <InfoListItemDetail>
          <TextTokenCount
            token={props.yieldAssets.token}
            count={props.yieldAssets.count}
          />
          &nbsp;
          <span className={"text-gray-500"}>
            (≈$ {props.yieldTokenPrice.toUSD.toFixed(2)})
          </span>
          &nbsp; = &nbsp;
          <TextTokenCount
            token={props.yieldTokenPrice.toCrypto.token}
            count={props.yieldTokenPrice.toCrypto.count}
          />
        </InfoListItemDetail>
      </InfoListItem>
    </InfoList>

    <CardPlate>
      <InfoList listItemDirection={"row-responsive"}>
        <InfoListItem>
          <InfoListItemTitle>Liquidity Provider Fee</InfoListItemTitle>
          <InfoListItemDetail>
            <TokenCount
              token={props.liquidityProviderFee.token}
              count={props.liquidityProviderFee.count}
            />
            &nbsp;
            <TokenName token={props.liquidityProviderFee.token} />
          </InfoListItemDetail>
        </InfoListItem>

        <InfoListItem>
          <InfoListItemTitle>Slippage Tolerance</InfoListItemTitle>
          <InfoListItemDetail>
            <PercentNumber number={props.slippage} />
          </InfoListItemDetail>
        </InfoListItem>
      </InfoList>
    </CardPlate>

    <div className={"flex flex-row gap-2.5"}>
      <WhiteFilledButton className={"flex-1"} onClick={props.onClose}>
        Cancel
      </WhiteFilledButton>

      <GradientFilledButton className={"flex-1"} onClick={props.onConfirm}>
        Confirm
      </GradientFilledButton>
    </div>
  </CardModalContent>
)
