import clsx from "clsx"
import { FC } from "react"
import { Card } from "../../../components/Card"
import { PercentNumber } from "../../../components/PercentNumber"
import { Spensor } from "../../../components/Spensor"
import { TitledColumnCell } from "../../../components/TitledColumnCell"
import { TokenCount } from "../../../components/TokenCount"
import { TokenIcon } from "../../../components/TokenIcon"
import { TokenName } from "../../../components/TokenName"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { readResource, SuspenseResource } from "../../../utils/SuspenseResource"
import {
  getListItemContainerClassName,
  getListSectionClassName,
} from "../../Farm/components/ListContainer"

const MyLiquidityRow: FC<{
  className?: string
  poolToken: TokenInfo
  balance: number
  share: SuspenseResource<number>
  tokenA: {
    balance: number
    token: TokenInfo
  }
  tokenB: {
    balance: number
    token: TokenInfo
  }
}> = props => {
  return (
    <div className={clsx("flex flex-col gap-5", props.className)}>
      <Card
        className={clsx(
          "flex flex-row gap-4 group items-stretch",
          "hover:bg-black/10 hover:!bg-none hover:!backdrop-filter-none hover:!drop-shadow-none",
          "active:bg-black/20 active:!bg-none active:!backdrop-filter-none active:!drop-shadow-none",
          getListItemContainerClassName({}),
          props.className,
        )}
        boxClassName={"px-4 py-2"}
        roundClassName={"rounded-lg"}
      >
        <TitledColumnCell
          className={clsx(getListSectionClassName({}))}
          title={"My LP"}
          detail={
            <div className="flex flex-row items-center gap-2.5">
              <TokenIcon token={props.poolToken} />{" "}
              <TokenCount token={props.poolToken} count={props.balance} />
            </div>
          }
        />

        <TitledColumnCell
          className={clsx(getListSectionClassName({}))}
          title={"My Pool Share"}
          detail={
            props.balance ? (
              <Spensor fallback="-">
                {() => <PercentNumber number={readResource(props.share)} />}
              </Spensor>
            ) : (
              "-"
            )
          }
        />

        <TitledColumnCell
          className={clsx(getListSectionClassName({}))}
          title={"Pooled"}
          detail={
            props.balance ? (
              <span>
                ≈{" "}
                <TokenCount
                  token={props.tokenA.token}
                  count={props.tokenA.balance}
                />{" "}
                <TokenName token={props.tokenA.token} /> +{" "}
                <TokenCount
                  token={props.tokenB.token}
                  count={props.tokenB.balance}
                />{" "}
                <TokenName token={props.tokenB.token} />
              </span>
            ) : (
              "-"
            )
          }
        />
      </Card>
    </div>
  )
}

export default MyLiquidityRow
