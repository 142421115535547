import { action, computed, makeObservable, observable } from "mobx"
import { createTransformer } from "mobx-utils"
import { ConfirmTransactionStore } from "../../../../stores/confirmTransactionDialogStore/ConfirmTransactionStore"
import { SuspenseObservable } from "../../../../stores/SuspenseObservable"
import { Currency } from "../../../../utils/alexjs/Currency"
import { asyncAction, runAsyncAction } from "../../../../utils/asyncAction"
import { Result } from "../../../../utils/Result"
import {
  WithdrawFormError,
  WithdrawFormErrorType,
} from "../../components/types"
import { OrderbookStore } from "../OrderbookStore"
import type {
  StxDxAsset,
  WithdrawFormData,
} from "../OrderbookStore.service/OrderbookStore.service"
import {
  allStxDxAssets,
  withdrawFromStxDx,
} from "../OrderbookStore.service/OrderbookStore.service"

export class OrderbookWithdrawModule {
  constructor(readonly store: OrderbookStore) {
    makeObservable(this)
  }

  txStore = new ConfirmTransactionStore()

  @observable selectingFromToken?: StxDxAsset

  @observable tokens: {
    token: StxDxAsset
    amount: SuspenseObservable<number>
  }[] = [
    {
      token: Currency.W_XUSD,
      amount: new SuspenseObservable<number>(),
    },
  ]

  @action reset(): void {
    this.tokens = [
      {
        token: Currency.W_XUSD,
        amount: new SuspenseObservable<number>(),
      },
    ]
  }

  @computed get canDelete(): boolean {
    return this.tokens.length > 1
  }

  @computed get canAddMoreToken(): boolean {
    return this.tokens.length < allStxDxAssets.length
  }

  @action addToken(): void {
    const existing = this.tokens.map(t => t.token)
    const notIncluded = allStxDxAssets.filter(a => !existing.includes(a))[0]
    if (notIncluded == null) {
      return
    }
    this.tokens.push({
      token: notIncluded,
      amount: new SuspenseObservable<number>(),
    })
  }

  @action deleteToken(token: StxDxAsset): void {
    this.tokens = this.tokens.filter(t => t.token !== token)
  }

  @observable withdrawing = false
  @observable confirming = false

  @computed get formData$(): Result<WithdrawFormData, WithdrawFormError> {
    if (this.tokens.some(t => !t.amount.get())) {
      return Result.error({
        type: WithdrawFormErrorType.AmountIsEmpty,
        message: "Input Amount",
      })
    }
    if (
      this.tokens.some(t => t.amount.read$ > this.availableBalance$(t.token))
    ) {
      return Result.error({
        type: WithdrawFormErrorType.ExceedMaxAmount,
        message: "Exceed max amount",
      })
    }
    return Result.ok({
      stxAddress: this.store.authStore.stxAddress$,
      userId: this.store.myInfo.registeredUserId$,
      tokens: this.tokens.map(t => ({
        asset: t.token,
        assetId: this.store.info.currencyAssetId$(t.token),
        amount: t.amount.read$,
      })),
    })
  }

  @asyncAction
  async withdraw(form: WithdrawFormData, run = runAsyncAction): Promise<void> {
    try {
      this.confirming = false
      this.withdrawing = false
      const { txId } = await run(withdrawFromStxDx(form))
      this.txStore.successRunning(txId)
    } catch (e) {
      this.txStore.errorRunning(e as Error)
    }
  }

  availableBalance$ = createTransformer(
    (currency: StxDxAsset) =>
      this.store.myInfo.stxDxBalance$(currency)?.available ?? 0,
  )

  @computed get maxWithdrawAmount$(): number {
    return this.store.myInfo.selectedTokenAvailableBalance$
  }
}
