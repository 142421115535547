import { FC } from "react"
import { HeadlessButton } from "../../../../../../components/button/HeadlessButton"
import {
  CardInset,
  CardInsetDivider,
  CardInsetTitle,
} from "../../../../../../components/Card"
import { NoteParagraph } from "../../../../../../components/NoteParagraph/NoteParagraph"
import { BlockTimeCountdownText } from "../../../../../../components/TimeCountdown/BlockTimeCountdownText"
import { TokenIcon } from "../../../../../../components/TokenIcon"
import { TokenName } from "../../../../../../components/TokenName"
import { TokenInfo } from "../../../../../../utils/models/TokenInfo"
import { withClassName } from "../../../../../../utils/reactHelpers/withClassName"
import { TokenInfoPresets } from "../../../../../../utils/TokenInfoPresets/TokenInfoPresets"
import {
  PlainVerticalInfoListContainer,
  PlainVerticalInfoListContainerGroup,
  PlainVerticalInfoListItem,
} from "../../../InfoList"
import { TokenCountRange } from "../../../TokenCountRange"
import { TokenIDOPrices } from "../../../types"
import { PurpleBlock } from "../PurpleBlock"
import { ReactComponent as UpcomingIcon } from "./upcomingImage.svg"

export const ActionSectionContent$Upcoming: FC<{
  priceToken: TokenInfo
  idoPrices: TokenIDOPrices
  validateStepStartedAt: Date
  validateStepStartedAtBlock: number
  currentBlock: number
  onLearnMoreAPowerRule?: () => void
}> = props => {
  const { idoPrices } = props

  return (
    <CardInset className={"flex flex-col items-center gap-6"}>
      <UpcomingIcon />

      <WidthLimitedContainer className={"flex flex-col items-center gap-1"}>
        <CardInsetTitle className={"text-center"}>
          Countdown to start of IDO
        </CardInsetTitle>

        <PlainVerticalInfoListContainerGroup className={"w-full py-6"}>
          <PlainVerticalInfoListContainer>
            <PlainVerticalInfoListItem
              title={
                <>
                  <TokenName token={TokenInfoPresets.APower} /> Per
                  Ticket&nbsp;&nbsp;({" "}
                  <HeadlessButton
                    className={"inline text-blue-600"}
                    onClick={props.onLearnMoreAPowerRule}
                  >
                    Details
                  </HeadlessButton>{" "}
                  )
                </>
              }
              detail={
                <>
                  <TokenIcon
                    className={"mr-2"}
                    token={TokenInfoPresets.APower}
                    size={24}
                  />
                  <TokenCountRange
                    token={TokenInfoPresets.APower}
                    range={idoPrices.aPowerCountPerTicket}
                  />
                  &nbsp;
                  <TokenName token={TokenInfoPresets.APower} />
                </>
              }
            />
          </PlainVerticalInfoListContainer>

          <PlainVerticalInfoListContainer>
            <PlainVerticalInfoListItem
              title={
                <>
                  <TokenName token={props.priceToken} /> Per Ticket
                </>
              }
              detail={
                <>
                  <TokenIcon
                    className={"mr-2"}
                    token={props.priceToken}
                    size={24}
                  />
                  <TokenCountRange
                    token={props.priceToken}
                    range={idoPrices.pricePerTicket}
                  />
                  &nbsp;
                  <TokenName token={props.priceToken} />
                </>
              }
            />
          </PlainVerticalInfoListContainer>
        </PlainVerticalInfoListContainerGroup>
      </WidthLimitedContainer>

      <WidthLimitedContainer>
        <PurpleBlock
          primaryText={
            <>
              Validate will start in{" "}
              <BlockTimeCountdownText
                targetBlock={props.validateStepStartedAtBlock}
                currentBlock={props.currentBlock}
                time={props.validateStepStartedAt}
                estimateSuffix={<> (Estimate)</>}
              />
            </>
          }
          secondaryText={<>open at: Block {props.validateStepStartedAtBlock}</>}
        />
      </WidthLimitedContainer>

      <CardInsetDivider className={"w-full"} />

      <div className={"text-sm leading-5 flex flex-col gap-[5px]"}>
        <NoteParagraph>
          <TokenName token={props.priceToken} /> submitted with validation will
          be refunded if the lottery is not won. The{" "}
          <TokenName token={TokenInfoPresets.APower} /> submitted with{""}
          validation will be burnt, win or lose.
        </NoteParagraph>
      </div>
    </CardInset>
  )
}

const WidthLimitedContainer = withClassName("w-full sm:px-[32px]", "div")
