import { action } from "mobx"
import React, { FC, Suspense, useEffect } from "react"
import { defineMessage, useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import { ChartContainer } from "../../components/ChartContainer/ChartContainer"
import { usePreventGlobalWiredDisclaimerModal } from "../../components/DisclaimerModalContent/WiredDisclaimerModal"
import { RootLayoutContainer } from "../../components/LayoutContainer/RootLayoutContainer"
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator"
import { suspenseResource } from "../../utils/SuspenseResource"
import { assertNever } from "../../utils/types"
import { ResolutionString } from "../../vendors/charting_library/charting_library.esm"
import { OrderListOrderType } from "./components/OrderbookList/types"
import { TradingAccountFrame } from "./components/TradingAccount/TradingAccountFrame"
import { isStxDxMarket } from "./store/OrderbookStore.service/OrderbookStore.service"
import { useOrderbookStore } from "./store/useOrderbookStore"
import { WiredAccountSettingDialog } from "./wiredComponents/WiredAccountSettingDialog"
import { WiredCreateOrder } from "./wiredComponents/WiredCreateOrder"
import WiredLoginModal from "./wiredComponents/WiredLoginModal"
import {
  WiredMyTradesOrderListContent,
  WiredOrderbookListContent,
  WiredOrderbookListFrame,
  WiredTradesOrderListContent,
} from "./wiredComponents/WiredOrderbookList"
import { WiredOrderHistory } from "./wiredComponents/WiredOrderHistory"
import { WiredSummaryBar } from "./wiredComponents/WiredSummaryBar"
import { WiredTradingAccountContent } from "./wiredComponents/WiredTradingAccountContent"

const OrderbookPageContent: FC = () => {
  const store = useOrderbookStore()

  return (
    <div
      className={"grid gap-2.5 m-2.5"}
      style={{
        gridTemplateColumns: "320px 1fr 320px",
      }}
    >
      <WiredSummaryBar className="col-start-1 col-span-3 row-start-1 row-span-1" />
      <WiredOrderbookListFrame
        className={
          "col-start-1 col-span-1 row-start-2 row-span-2 max-h-[850px]"
        }
      >
        {(() => {
          if (
            store.orderbook.showingOrderType ===
            OrderListOrderType.OrderbookOrders
          ) {
            return <WiredOrderbookListContent />
          } else if (
            store.orderbook.showingOrderType === OrderListOrderType.TradeOrders
          ) {
            return <WiredTradesOrderListContent />
          } else if (
            store.orderbook.showingOrderType ===
            OrderListOrderType.MyTradeOrders
          ) {
            return <WiredMyTradesOrderListContent />
          } else {
            assertNever(store.orderbook.showingOrderType)
          }
        })()}
      </WiredOrderbookListFrame>

      <ChartContainer
        key={store.market}
        className={"col-start-2 col-span-1 row-start-2 row-span-2"}
        datafeed={store.chartDatafeed}
        interval={"15" as ResolutionString}
        symbol={store.market}
      />

      <WiredCreateOrder
        className={"col-start-3 col-span-1 row-start-2 row-span-1"}
      />

      <TradingAccountFrame
        className={"col-start-3 col-span-1 row-start-3 row-span-1 gap-4"}
        onSettingClick={action(
          () => (store.accountSetting.showSettingModal = true),
        )}
        hasRegistered={suspenseResource(() => store.myInfo.hasRegistered$)}
      >
        <Suspense fallback={<LoadingIndicator className="m-auto" />}>
          <WiredTradingAccountContent gapClassName={"gap-4"} />
        </Suspense>
        <WiredAccountSettingDialog />
      </TradingAccountFrame>

      <WiredOrderHistory
        className={
          "flex flex-col gap-5 col-start-1 col-span-4 row-start-4 row-span-1"
        }
      />
      <Suspense>
        <WiredLoginModal />
      </Suspense>
    </div>
  )
}

export const OrderbookPage: FC = () => {
  usePreventGlobalWiredDisclaimerModal()

  const { $t } = useIntl()
  const store = useOrderbookStore()
  const market = useParams().market

  if (!isStxDxMarket(market)) {
    throw new Error(
      $t(
        defineMessage({
          description: "Orderbook/Market not found",
          defaultMessage: "Invalid market: {market}",
        }),
        { market },
      ),
    )
  }

  useEffect(() => {
    if (market !== store.market) {
      store.setMarket(market)
    }
  }, [market, store, store.market])

  return (
    <RootLayoutContainer fullWidth={true} topExtraPadding={10}>
      <Suspense>
        <OrderbookPageContent />
      </Suspense>
    </RootLayoutContainer>
  )
}
