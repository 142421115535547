import {
  AddressTransactionWithTransfers,
  ContractCallTransaction,
  MempoolContractCallTransaction,
  MempoolTransaction,
  Transaction,
} from "@stacks/stacks-blockchain-api-types"
import { deserializeCV } from "@stacks/transactions"
import {
  OpenCallFunctionDescriptor,
  ParameterObjOfDescriptor,
  ReturnTypeOfDescriptor,
} from "clarity-codegen"
import { CONTRACT_DEPLOYER } from "../../../config"
import { AlexContracts } from "../../../generated/smartContract/contracts_Alex"
import { FLOAT_RATIO } from "../constants"
import {
  ContractCallsTuple,
  GetArgs,
  GetResult,
  NotifyTransactionFilter,
  TransformerGroup,
} from "../types"

export function getAmountFromContract(before: number): number
export function getAmountFromContract(before: undefined): undefined
export function getAmountFromContract(before?: number): number | undefined
export function getAmountFromContract(before?: number): number | undefined {
  return before ? before / FLOAT_RATIO : undefined
}

export function isContractCallTransaction(
  input: Transaction | MempoolTransaction,
): input is ContractCallTransaction | MempoolContractCallTransaction {
  return Boolean((input as ContractCallTransaction).contract_call)
}

export function isMempoolTransaction(
  input: Transaction | MempoolTransaction,
): input is MempoolTransaction {
  return !Boolean((input as Transaction).block_height)
}

export function isTransactionWithTransfers(
  input: AddressTransactionWithTransfers | MempoolTransaction,
): input is AddressTransactionWithTransfers {
  return Boolean((input as AddressTransactionWithTransfers).tx)
}

export function filterBuilder<
  ContractNames extends Readonly<Array<keyof typeof AlexContracts>>,
  FunctionName extends keyof typeof AlexContracts[ContractNames[number]],
>(
  contractCallsTuple: ContractCallsTuple<ContractNames, FunctionName>,
): NotifyTransactionFilter {
  return function (tx) {
    if (!isContractCallTransaction(tx)) {
      return false
    }
    const [contractCalls, functionName] = contractCallsTuple
    return (
      contractCalls.some(
        contractName =>
          tx.contract_call.contract_id ===
          `${CONTRACT_DEPLOYER}.${contractName}`,
      ) && tx.contract_call.function_name === functionName
    )
  }
}
export function getFilterFromGroup<
  ContractNames extends Readonly<Array<keyof typeof AlexContracts>>,
  FunctionName extends keyof typeof AlexContracts[ContractNames[number]],
>(
  group: TransformerGroup<ContractNames, FunctionName>,
): NotifyTransactionFilter {
  if (group.filterFn) {
    return group.filterFn
  }
  return filterBuilder([group.contracts, group.functionName])
}

// contract helpers
export function getContractCallHelpers<
  ContractName extends keyof typeof AlexContracts,
  FunctionName extends keyof typeof AlexContracts[ContractName],
>(
  contractName: ContractName,
  functionName: FunctionName,
  tx: ContractCallTransaction,
): {
  getArgs: GetArgs<[ContractName], FunctionName>
  getResult: GetResult<[ContractName], FunctionName>
} {
  const functionDescriptor = getContractCallFunctionDescriptor(
    contractName,
    functionName,
  )

  return {
    getArgs: () => getArgsFromFunctionDescriptor(functionDescriptor, tx),
    getResult: () => getResultFromFunctionDescriptor(functionDescriptor, tx),
  }
}

export function getContractArgs<
  ContractName extends keyof typeof AlexContracts,
  FunctionName extends keyof typeof AlexContracts[ContractName],
>(
  contractName: ContractName,
  functionName: FunctionName,
  tx: ContractCallTransaction | MempoolContractCallTransaction,
): ParameterObjOfDescriptor<typeof AlexContracts[ContractName][FunctionName]> {
  return getArgsFromFunctionDescriptor(
    getContractCallFunctionDescriptor(contractName, functionName),
    tx,
  )
}

export function getContractCallTupleFromTx<
  ContractName extends keyof typeof AlexContracts,
  FunctionName extends keyof typeof AlexContracts[ContractName],
>(
  tx: ContractCallTransaction | MempoolContractCallTransaction,
): [ContractName, FunctionName] {
  return [
    getContractName<ContractName>(tx),
    getContractCallFunctionName<ContractName, FunctionName>(tx),
  ]
}

function getContractName<ContractName extends keyof typeof AlexContracts>(
  tx: ContractCallTransaction | MempoolContractCallTransaction,
): ContractName {
  return tx.contract_call.contract_id.replace(
    CONTRACT_DEPLOYER + ".",
    "",
  ) as any
}

function getContractCallFunctionName<
  ContractName extends keyof typeof AlexContracts,
  FunctionName extends keyof typeof AlexContracts[ContractName],
>(tx: ContractCallTransaction | MempoolContractCallTransaction): FunctionName {
  return tx.contract_call.function_name as any
}

function getContractCallFunctionDescriptor<
  ContractName extends keyof typeof AlexContracts,
  FunctionName extends keyof typeof AlexContracts[ContractName],
>(
  contractName: ContractName,
  functionName: FunctionName,
): typeof AlexContracts[ContractName][FunctionName] extends OpenCallFunctionDescriptor
  ? OpenCallFunctionDescriptor
  : never {
  return AlexContracts[contractName][functionName] as any
}

function getArgsFromFunctionDescriptor<
  ContractName extends keyof typeof AlexContracts,
  FunctionName extends keyof typeof AlexContracts[ContractName],
>(
  functionDescriptor: OpenCallFunctionDescriptor,
  tx: ContractCallTransaction | MempoolContractCallTransaction,
): ParameterObjOfDescriptor<typeof AlexContracts[ContractName][FunctionName]> {
  const args = functionDescriptor.input.reduce(
    (acc, arg, index) => ({
      ...acc,
      [arg.name]: arg.type.decode(
        deserializeCV(tx.contract_call.function_args![index]!.hex),
      ),
    }),
    {} as Record<string, any>,
  )
  return args as any
}

function getResultFromFunctionDescriptor<
  ContractName extends keyof typeof AlexContracts,
  FunctionName extends keyof typeof AlexContracts[ContractName],
>(
  functionDescriptor: OpenCallFunctionDescriptor,
  tx: ContractCallTransaction,
): ReturnTypeOfDescriptor<typeof AlexContracts[ContractName][FunctionName]> {
  return functionDescriptor.output.decode(deserializeCV(tx.tx_result.hex))
}
