import { FC, ReactNode } from "react"
import { useAPowerRatio } from "../../../components/APowerTip/APowerTip"
import { MayDollarSignTokenName } from "../../../components/MayDollarSignTokenName"
import { TokenName } from "../../../components/TokenName"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { readResource } from "../../../utils/SuspenseResource"
import { TokenInfoPresets } from "../../../utils/TokenInfoPresets/TokenInfoPresets"
import { CopywritingProviderValue } from "../components/CopywritingProvider"

export class StakingCopywriting implements CopywritingProviderValue {
  constructor(private token: TokenInfo) {
    this.aPowerAlertContent = <APowerAlertContent token={token} />

    this.addStaking = {
      panelTitle: (
        <>
          Add $<TokenName token={token} /> Staking
        </>
      ),
      cycleTip: this.cycleTip,
    }
  }

  private cycleTip =
    "Staking locks up the set amount in the contract for the selected number of reward cycles."

  aPowerAlertContent: ReactNode

  myStaking = {
    panelTitle: "My Staking",
    activeStaking: "Active staking",
    averageAPRTip:
      "The average APR is calculated based on your Staking cycles.",
    principalClaim: "Principal to claim",
    cycleTip: this.cycleTip,
    toBeStaked: {
      finishedStatus: "Staked",
      currentState: "Staking",
      upcomingStatus: "To be staked",
    },
  }

  addStaking: CopywritingProviderValue["addStaking"]

  principalTokenInfoUpdater = (tokenInfo: TokenInfo): TokenInfo => tokenInfo
}

const APowerAlertContent: FC<{ token: TokenInfo }> = props => {
  const { displayableRatio } = useAPowerRatio()

  return (
    <>
      The amount of <TokenName token={TokenInfoPresets.APower} /> (IDO access)
      received for <MayDollarSignTokenName token={props.token} /> staking will
      be {readResource(displayableRatio)}x the amount of{" "}
      <MayDollarSignTokenName token={props.token} /> rewarded.
    </>
  )
}
