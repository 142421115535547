import { optionalResponse } from "clarity-codegen"
import { Currency } from "../../../../../utils/alexjs/Currency"
import {
  BaseRowData,
  CurrencyAmount,
  NotifyTransactionType,
  TransformerGroup,
} from "../../../types"
import { getAmountFromContract } from "../../TransactionsModule.service"

const contracts = [
  "fixed-weight-pool-v1-01",
  "swap-helper",
  "swap-helper-v1-01",
  "swap-helper-v1-02",
  "swap-helper-v1-03",
  "amm-swap-pool",
] as const
const functionName = "swap-helper" as const

export interface SwapRowData extends BaseRowData {
  type: NotifyTransactionType.Swap
  fromCurrencyAmount: CurrencyAmount.Confirmed
  toCurrencyAmount:
    | CurrencyAmount.Minimal
    | CurrencyAmount.Confirmed
    | CurrencyAmount.Unknown
}

export const swapTransformerGroup: TransformerGroup<
  typeof contracts,
  typeof functionName,
  SwapRowData
> = {
  contracts,
  functionName,
  type: NotifyTransactionType.Swap,
  confirmedTransformer: helpers => {
    const args = helpers.getArgs()
    const result = helpers.getResult()

    const dy = optionalResponse(result)
    if (!dy) {
      throw new Error("Expected a response")
    }
    return {
      fromCurrencyAmount: {
        currency: args["token-x-trait"] as Currency,
        amount: getAmountFromContract(args.dx),
      },
      toCurrencyAmount: {
        currency: args["token-y-trait"] as Currency,
        amount: getAmountFromContract(dy),
      },
    }
  },
  pendingOrFailedTransformer: args => {
    return {
      fromCurrencyAmount: {
        currency: args["token-x-trait"] as Currency,
        amount: getAmountFromContract(args.dx),
      },
      toCurrencyAmount: {
        currency: args["token-y-trait"] as Currency,
        minAmount: getAmountFromContract(args["min-dy"]),
      },
    }
  },
}

export const swapATransformerGroup: TransformerGroup<
  ["amm-swap-pool"],
  "swap-helper-a",
  SwapRowData
> = {
  contracts: ["amm-swap-pool"],
  functionName: "swap-helper-a",
  type: NotifyTransactionType.Swap,
  confirmedTransformer: helpers => {
    const args = helpers.getArgs()
    const result = helpers.getResult()

    const dy = optionalResponse(result)
    if (!dy) {
      throw new Error("Expected a response")
    }
    return {
      fromCurrencyAmount: {
        currency: args["token-x-trait"] as Currency,
        amount: getAmountFromContract(args.dx),
      },
      toCurrencyAmount: {
        currency: args["token-z-trait"] as Currency,
        amount: getAmountFromContract(dy),
      },
    }
  },
  pendingOrFailedTransformer: args => {
    return {
      fromCurrencyAmount: {
        currency: args["token-x-trait"] as Currency,
        amount: getAmountFromContract(args.dx),
      },
      toCurrencyAmount: {
        currency: args["token-z-trait"] as Currency,
        minAmount: getAmountFromContract(args["min-dz"]),
      },
    }
  },
}

export const swapBTransformerGroup: TransformerGroup<
  ["amm-swap-pool"],
  "swap-helper-b",
  SwapRowData
> = {
  contracts: ["amm-swap-pool"],
  functionName: "swap-helper-b",
  type: NotifyTransactionType.Swap,
  confirmedTransformer: helpers => {
    const args = helpers.getArgs()
    const result = helpers.getResult()

    const dy = optionalResponse(result)
    if (!dy) {
      throw new Error("Expected a response")
    }
    return {
      fromCurrencyAmount: {
        currency: args["token-x-trait"] as Currency,
        amount: getAmountFromContract(args.dx),
      },
      toCurrencyAmount: {
        currency: args["token-w-trait"] as Currency,
        amount: getAmountFromContract(dy),
      },
    }
  },
  pendingOrFailedTransformer: args => {
    return {
      fromCurrencyAmount: {
        currency: args["token-x-trait"] as Currency,
        amount: getAmountFromContract(args.dx),
      },
      toCurrencyAmount: {
        currency: args["token-w-trait"] as Currency,
        minAmount: getAmountFromContract(args["min-dw"]),
      },
    }
  },
}

export const swapCTransformerGroup: TransformerGroup<
  ["amm-swap-pool"],
  "swap-helper-c",
  SwapRowData
> = {
  contracts: ["amm-swap-pool"],
  functionName: "swap-helper-c",
  type: NotifyTransactionType.Swap,
  confirmedTransformer: helpers => {
    const args = helpers.getArgs()
    const result = helpers.getResult()

    const dy = optionalResponse(result)
    if (!dy) {
      throw new Error("Expected a response")
    }
    return {
      fromCurrencyAmount: {
        currency: args["token-x-trait"] as Currency,
        amount: getAmountFromContract(args.dx),
      },
      toCurrencyAmount: {
        currency: args["token-v-trait"] as Currency,
        amount: getAmountFromContract(dy),
      },
    }
  },
  pendingOrFailedTransformer: args => {
    return {
      fromCurrencyAmount: {
        currency: args["token-x-trait"] as Currency,
        amount: getAmountFromContract(args.dx),
      },
      toCurrencyAmount: {
        currency: args["token-v-trait"] as Currency,
        minAmount: getAmountFromContract(args["min-dv"]),
      },
    }
  },
}
