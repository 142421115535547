import { action, computed, makeObservable, observable } from "mobx"
import { Subject } from "rxjs"
import type { PaginationInfo } from "../../../../components/Pagination"
import { sendRequest } from "../../../../generated/wrapBridgeHelpers/wrapBridgeApi"
import AccountStore from "../../../../stores/accountStore/AccountStore"
import AuthStore from "../../../../stores/authStore/AuthStore"
import CurrencyStore from "../../../../stores/currencyStore/CurrencyStore"
import { LazyValue } from "../../../../stores/LazyValue/LazyValue"
import { asyncAction, runAsyncAction } from "../../../../utils/asyncAction"
import { DisplayableError } from "../../../../utils/error"
import { isPromiseLike } from "../../../../utils/promiseHelpers"
import { waitFor } from "../../../../utils/waitFor"
import { WrapBridgeHistoryRecord } from "../../types/types"
import { signWrapTokenMessage } from "../WrapFormModule.service"
import type { TokenBridgeSignMissingOrderWithType } from "./getHistoryRecords.service"
import {
  getServerHistoryRecords,
  transformServerOrderRecord,
} from "./getHistoryRecords.service"

export class HistoryModule {
  constructor(
    private readonly currencyStore: Pick<CurrencyStore, "getTokenInfo$">,
    private readonly accountStore: Pick<AccountStore, "getBalance$">,
    private readonly authStore: Pick<
      AuthStore,
      "metaMaskModule" | "stxAddress$"
    >,
  ) {
    makeObservable(this)
  }

  private onSignedSignal = new Subject<void>()

  @computed get fetchRecordsArguments$(): [addr1: string, addr2: string] {
    return [
      this.authStore.stxAddress$,
      this.authStore.metaMaskModule.connectedWalletAddress.value$,
    ]
  }

  private _serverRecords = new LazyValue(
    () => this.fetchRecordsArguments$,
    ([address0, address1]) =>
      getServerHistoryRecords(address0, address1, {
        signMessageRefreshSignal: this.onSignedSignal,
      }),
  )

  @computed get isReadyToLoadRecords(): boolean {
    try {
      void this.fetchRecordsArguments$
      return true
    } catch (e) {
      if (isPromiseLike(e)) {
        return false
      } else {
        throw e
      }
    }
  }

  @observable currentPage = 0

  readonly recordCountPerPage = 30

  @computed get paginationInfo$(): PaginationInfo {
    return {
      recordCountPerPage: this.recordCountPerPage,
      currentPage: this.currentPage,
      recordCountTotal: this._serverRecords.value$.length,
    }
  }

  @computed get records$(): WrapBridgeHistoryRecord[] {
    return this._serverRecords.value$
      .slice(
        this.paginationInfo$.currentPage *
          this.paginationInfo$.recordCountPerPage,
        this.paginationInfo$.recordCountPerPage,
      )
      .map(r =>
        transformServerOrderRecord(r, {
          getTokenInfo: c => this.currencyStore.getTokenInfo$(c),
          onSignMessage: serverRecord => this.signMessage(serverRecord),
        }),
      )
  }

  @action onChangePage(pageNumber: number): void {
    this.currentPage = pageNumber
  }

  @computed get signMissingRecordCount$(): number {
    return this._serverRecords.value$.filter(
      r => r.type === "sign_missing_order",
    ).length
  }

  @asyncAction async signMessage(
    serverRecord: TokenBridgeSignMissingOrderWithType,
    run = runAsyncAction,
  ): Promise<void> {
    const { metaMaskModule } = this.authStore

    if (serverRecord.source_status.transaction_hash == null) {
      throw new DisplayableError("Transaction is not ready to be sign")
    }

    const signTypedData = await run(
      waitFor(() => metaMaskModule.signTypedData.value$),
    )
    const stxAddress = this.authStore.stxAddress$

    const signature = await run(
      signWrapTokenMessage(
        signTypedData,
        stxAddress,
        serverRecord.source_status.transaction_hash,
      ),
    )

    await run(
      sendRequest("SignedMessageController_createSignedMessage", {
        body: {
          txId: serverRecord.source_status.transaction_hash,
          toAddress: stxAddress,
          signedMessage: signature,
        },
      }),
    )

    this.onSignedSignal.next()
  }
}
