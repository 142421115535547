import { FC } from "react"
import {
  CardInset,
  CardInsetDivider,
  CardInsetTitle,
} from "../../../../../components/Card"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../../components/InfoList"
import { ReactComponent as OopsIcon } from "./_/oops.svg"

export const MyLotteryPanel$Lose: FC = () => {
  return (
    <>
      <CardInset className={"flex flex-col gap-4"}>
        <div className={"flex flex-col items-center gap-4"}>
          <OopsIcon />
          <div className={"text-center"}>
            <CardInsetTitle>Oops...maybe next time</CardInsetTitle>
          </div>
        </div>

        <CardInsetDivider />

        <InfoList
          className={"self-center justify-center min-h-[80px]"}
          direction={"column"}
          listItemDirection={"row"}
        >
          <InfoListItem className={"items-center gap-2"}>
            <InfoListItemDetail className={"text-5xl"}>0</InfoListItemDetail>
            <InfoListItemTitle>Lottery Won</InfoListItemTitle>
          </InfoListItem>
        </InfoList>
      </CardInset>
    </>
  )
}
