"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.hashSha256Async = exports.hashSha256 = void 0;
const sha256_1 = require("@noble/hashes/sha256");
function hashSha256(input) {
    return Buffer.from((0, sha256_1.sha256)(input));
}
exports.hashSha256 = hashSha256;
function hashSha256Async(input) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const isSubtleCryptoAvailable = typeof crypto !== 'undefined' && typeof crypto.subtle !== 'undefined';
            if (isSubtleCryptoAvailable) {
                // Use the W3C Web Crypto API if available (running in a web browser).
                const buffer = typeof input === 'string' ? Buffer.from(input) : input;
                const hash = yield crypto.subtle.digest('SHA-256', buffer);
                return Buffer.from(hash);
            }
            else {
                // Otherwise try loading the Node.js `crypto` module (running in Node.js, or an older browser with a polyfill).
                const nodeCrypto = require('crypto');
                if (!nodeCrypto.createHash) {
                    throw new Error('`crypto` module does not contain `createHash`');
                }
                return Promise.resolve(nodeCrypto.createHash('sha256').update(input).digest());
            }
        }
        catch (error) {
            console.log(error);
            console.log('Crypto lib not found. Neither the global `crypto.subtle` Web Crypto API, ' +
                'nor the or the Node.js `require("crypto").createHash` module is available. ' +
                'Falling back to JS implementation.');
            return Promise.resolve(hashSha256(input));
        }
    });
}
exports.hashSha256Async = hashSha256Async;
