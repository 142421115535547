import { FC } from "react"
import { GradientFilledButton } from "../button/variants/GradientFilledButton/GradientFilledButton"
import { Modal } from "../Modal"
import { ReactComponent as CloseIcon } from "./close.svg"
import { ReactComponent as XverseIcon } from "./xverse.svg"

export const ConnectXverseWalletDialog: FC<{
  onClose?: () => void
  onOpen?: () => void
  visible?: boolean
}> = ({ visible = false, onClose }) => {
  return (
    <Modal
      visible={visible}
      ContentLimiter={props => (
        <div {...props} className="w-full absolute bottom-0" />
      )}
    >
      <section
        className="backdrop-blur-lg flex flex-col gap-4 p-6 pb-14 rounded-t-2xl absolute bottom-0 left-0 right-0"
        style={{
          background:
            "linear-gradient(152.97deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%)",
        }}
      >
        <h1 className="flex flex-row items-center justify-between">
          <span className="text-base font-semibold text-gray-200 grow">
            Connect Wallet
          </span>

          <span onClick={onClose}>
            <CloseIcon />
          </span>
        </h1>
        <GradientFilledButton
          roundedClassName="rounded-lg"
          onClick={() => {
            // open deep link scheme first, if failed, redirect to xverse website
            // TODO: can extract this helper
            setTimeout(() => {
              window.location.href = "https://www.xverse.app/"
            }, 30)
            window.location.href = `xverse://browser?url=${encodeURIComponent(
              window.location.href,
            )}`
          }}
        >
          <div className="flex flex-row space-between items-center">
            <span className="text-left font-bold grow">Xverse Wallet</span>
            <XverseIcon />
          </div>
        </GradientFilledButton>
        <div className="text-sm leading-5 font-normal text-center text-gray-400">
          <p>ALEX requires Xverse Wallet to run on mobile.</p>
          <p>
            Please install the app then revisit &quot;
            <span className="font-bold text-white">alexlab.app</span>&quot; to
            access ALEX.
          </p>
        </div>
      </section>
    </Modal>
  )
}
