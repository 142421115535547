import { TooltipProvider } from "@radix-ui/react-tooltip"
import * as Sentry from "@sentry/react"
import { Connect } from "@stacks/connect-react"
import { FC, Suspense } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { BrowserRouter } from "react-router-dom"
import styles from "./App.module.scss"
import { AppRoutes$Default } from "./AppRoutes$Default"
import { AppRoutes$StackBridgeStandalone } from "./AppRoutes$StackBridgeStandalone"
import { BrowserUpdateBanner } from "./components/BrowserUpdateBanner"
import { DialogProvider } from "./components/dialog/DialogProvider"
import GlobalErrorBoundaryPage from "./components/GlobalErrorBoundaryPage"
import { GlobalLoadingBoundary } from "./components/LoadingBoundary/GlobalLoadingBoundary"
import { LoadingIndicator } from "./components/LoadingIndicator/LoadingIndicator"
import { MessageProvider } from "./components/message/MessageProvider"
import { wiredRecommendedMessageProviderProps } from "./components/message/wiredRecommendedMessageProviderProps"
import { BreakpointsProvider } from "./components/Themed/breakpoints"
import { ColorsProvider } from "./components/Themed/color"
import { defaultThemeBreakpoints } from "./components/Themed/defaults/defaultThemeBreakpoints"
import { defaultThemePalette } from "./components/Themed/defaults/defaultThemePalette"
import { defaultThemeSpacing } from "./components/Themed/defaults/defaultThemeSpacing"
import { SpacingProvider } from "./components/Themed/spacing"
import { WiredIntlProvider } from "./components/WiredIntlProvider"
import { IS_WRAP_BRIDGE_STANDALONE } from "./config"
import { LendIndexStoreProvider } from "./pages/Lend/store/useLendStore"
import SyncTransactionsOnIdle from "./stores/accountStore/SyncTransactionsOnIdle"
import { AccountStoreProvider } from "./stores/accountStore/useAccountStore"
import { AppEnvStoreProvider } from "./stores/appEnvStore/useAppEnvStore"
import {
  AuthStoreProvider,
  useAuthStore,
} from "./stores/authStore/useAuthStore"
import { ChainStoreProvider } from "./stores/chainStore/useChainStore"
import { CurrencyStoreProvider } from "./stores/currencyStore/useCurrencyStore"
import { LongTextStoreProvider } from "./stores/longTextStore/useLongTextStore"
import { NotifyStoreProvider } from "./stores/notifyStore/useNotifyStore"
import { FCC } from "./utils/reactHelpers/types"

declare module "./components/Themed/spacing" {
  interface ThemeSpacing {
    spacing(spacing: keyof typeof defaultThemeSpacing): void
  }
}
declare module "./components/Themed/color" {
  interface ThemeColor {
    color(color: keyof typeof defaultThemePalette): void
  }
}
declare module "./components/Themed/breakpoints" {
  interface ThemeBreakpoints {
    breakpoint(
      breakpoint: BreakpointsKeysFromDefinition<typeof defaultThemeBreakpoints>,
    ): void
  }
}

const StoreContexts: FCC = props => (
  <AppEnvStoreProvider>
    <LongTextStoreProvider>
      <ChainStoreProvider>
        <AuthStoreProvider>
          <CurrencyStoreProvider>
            <AccountStoreProvider>
              <NotifyStoreProvider>
                <LendIndexStoreProvider>
                  <Suspense fallback={null}>{props.children}</Suspense>
                </LendIndexStoreProvider>
              </NotifyStoreProvider>
            </AccountStoreProvider>
          </CurrencyStoreProvider>
        </AuthStoreProvider>
      </ChainStoreProvider>
    </LongTextStoreProvider>
  </AppEnvStoreProvider>
)

const UIContexts: FCC = props => (
  <HelmetProvider>
    <BreakpointsProvider breakpoints={defaultThemeBreakpoints}>
      <SpacingProvider spacings={defaultThemeSpacing}>
        <ColorsProvider colors={defaultThemePalette}>
          <MessageProvider {...wiredRecommendedMessageProviderProps}>
            <GlobalLoadingBoundary loadingIndicator={<LoadingIndicator />}>
              <TooltipProvider>
                <DialogProvider>{props.children}</DialogProvider>
              </TooltipProvider>
            </GlobalLoadingBoundary>
          </MessageProvider>
        </ColorsProvider>
      </SpacingProvider>
    </BreakpointsProvider>
  </HelmetProvider>
)

const StackConnectProvider: FCC = props => {
  const authStore = useAuthStore()
  return (
    <Connect authOptions={authStore.getAuthOptions()}>{props.children}</Connect>
  )
}

const App: FC = () => {
  return (
    <WiredIntlProvider>
      <GlobalErrorBoundaryPage>
        <UIContexts>
          <StoreContexts>
            <StackConnectProvider>
              <Helmet>
                {/*reset the classname of the body tag*/}
                <body className={styles.defaultBackground} />
              </Helmet>

              <BrowserUpdateBanner />

              <BrowserRouter>
                {IS_WRAP_BRIDGE_STANDALONE ? (
                  <AppRoutes$StackBridgeStandalone />
                ) : (
                  <AppRoutes$Default />
                )}
              </BrowserRouter>

              <SyncTransactionsOnIdle />
            </StackConnectProvider>
          </StoreContexts>
        </UIContexts>
      </GlobalErrorBoundaryPage>
    </WiredIntlProvider>
  )
}

export default Sentry.withProfiler(App)
