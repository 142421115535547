import clsx from "clsx"
import { FC } from "react"
import { LayoutMeasurer } from "../../../../components/LayoutMeasurer"
import { TokenCountPair } from "../../../../components/TokenInput/TokenBlock"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { CoFarmPoolCard } from "./_/CoFarmPoolCard"
import { CoFarmWithAlexCard } from "./_/CoFarmWithAlexCard/CoFarmWithAlexCard"
import { FlexArrow } from "./_/FlexArrow"
import { MaturedTokensCard } from "./_/MaturedTokensCard"
import { TransformToWalletCard } from "./_/TransformToWalletCard"

export const CoFarmPipeDiagram: FC<{
  className?: string
  lpToken: TokenInfo
  lpTokenCount: number
  lpLeftToken: TokenInfo
  lpLeftTokenCount: number
  lpRightToken: TokenInfo
  lpRightTokenCount: number
  lpLeftTokenTransformedLPToken: TokenInfo
  lpLeftTokenTransformedLPTokenCount: number
  lpLeftTokenTransformedLPTokenAPR: number
  lpLeftTokenTransformedLPTokenEndCycle: number
  lpRightTokenTransformedToken: TokenInfo
  lpRightTokenTransformedTokenCount: number
  lpRightTokenTransformedTokenAPY: number
  lpRightTokenRewards: TokenCountPair[]
}> = props => {
  return (
    <div className={clsx("grid grid-cols-24 gap-x-8", props.className)}>
      <MaturedTokensCard
        className={"col-span-full row-start-1 row-span-1"}
        lpToken={props.lpToken}
        lpTokenCount={props.lpTokenCount}
        lpLeftToken={props.lpLeftToken}
        lpLeftTokenCount={props.lpLeftTokenCount}
        lpRightToken={props.lpRightToken}
        lpRightTokenCount={props.lpRightTokenCount}
      />

      <div
        className={
          "col-start-6 col-span-2 row-start-2 row-span-1 justify-self-center"
        }
        style={{
          marginTop: -25,
        }}
      >
        <FlexArrow height={80} />
      </div>

      <CoFarmWithAlexCard
        className={"col-span-17 row-start-3 row-span-1"}
        lpLeftToken={props.lpLeftToken}
        lpLeftTokenCount={props.lpLeftTokenCount}
        lpRightToken={props.lpRightToken}
        lpRightTokenCount={props.lpRightTokenCount}
      />

      <div
        className={
          "col-start-6 col-span-2 row-start-4 row-span-1 justify-self-center"
        }
        style={{
          marginTop: -25,
        }}
      >
        <FlexArrow height={80} />
      </div>

      <div
        className={
          "col-start-18 col-span-2 row-start-2 row-span-3 justify-self-center flex"
        }
        style={{
          marginTop: -25,
        }}
      >
        <LayoutMeasurer className={"h-full"}>
          {l => (
            <FlexArrow
              height={l.height}
              linearGradient={
                <linearGradient
                  x1="50%"
                  y1="0%"
                  x2="50%"
                  y2="100%"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="white" />
                  <stop offset="1" stopColor="white" stopOpacity="0.22" />
                </linearGradient>
              }
            />
          )}
        </LayoutMeasurer>

        <div className={"relative flex items-center"} style={{ marginTop: 25 }}>
          <p className={"absolute"}>
            <span
              className={"text-sm font-medium text-gray-400 whitespace-nowrap"}
            >
              Auto Staking
            </span>
          </p>
        </div>
      </div>

      <CoFarmPoolCard
        className={"col-start-1 col-span-12 row-start-5 row-span-1"}
        coFarmPoolToken={props.lpLeftTokenTransformedLPToken}
        coFarmPoolTokenCount={props.lpLeftTokenTransformedLPTokenCount}
        coFarmPoolTokenAPR={props.lpLeftTokenTransformedLPTokenAPR}
        endCycle={props.lpLeftTokenTransformedLPTokenEndCycle}
      />

      <TransformToWalletCard
        className={"col-start-13 col-span-12 row-start-5 row-span-1"}
        originToken={props.lpRightToken}
        transformToToken={props.lpRightTokenTransformedToken}
        transformToTokenCount={props.lpRightTokenTransformedTokenCount}
        transformToTokenAPY={props.lpRightTokenTransformedTokenAPY}
        harvestableRewards={props.lpRightTokenRewards}
      />
    </div>
  )
}
