import clsx from "clsx"
import { FC, ReactNode } from "react"
import { btnPresets } from "../../../../components/button/Button"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteFilledButton } from "../../../../components/button/variants/WhiteFilledButton"
import { Card, CardNested } from "../../../../components/Card"
import { Divider } from "../../../../components/Divider"
import {
  createInfoListItemDetail,
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../components/InfoList"
import { NavLink } from "../../../../components/NavLink"
import { PercentNumber } from "../../../../components/PercentNumber"
import { Spensor } from "../../../../components/Spensor"
import { TokenIcon } from "../../../../components/TokenIcon"
import { TokenName } from "../../../../components/TokenName"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import {
  readResource,
  SuspenseResource,
} from "../../../../utils/SuspenseResource"
import { CollapsableCardScrollableRow } from "../CollapsableCardScrollableRow"

export interface LendPool {
  token: TokenInfo
  depositApr: SuspenseResource<number>
  borrowApr: SuspenseResource<number>
  borrowCollateral: TokenInfo[]
  depositPageLink: string
  borrowPageLink: string
}

export const PoolEntriesPanel: FC<{
  className?: string
  lendPools: LendPool[]
}> = props => {
  return (
    <Card className={clsx(props.className)} boxClassName={"py-3 sm:py-6"}>
      {props.lendPools.map((p, idx) => (
        <CollapsableCardScrollableRow
          contentContainerBoxClassName={"px-3 sm:px-6"}
        >
          <PoolRow key={idx} pool={p} />
        </CollapsableCardScrollableRow>
      ))}
    </Card>
  )
}

const PoolRow: FC<{
  pool: LendPool
}> = props => (
  <CardNested
    className={"flex flex-row items-stretch gap-10"}
    boxClassName={"p-6"}
  >
    <div className={"flex-1 flex items-center"}>
      <TokenIcon token={props.pool.token} size={48} />
      &nbsp;
      <TokenName token={props.pool.token} className={"text-2xl"} />
    </div>

    <Divider className={"border-gray-500"} direction={"vertical"} />

    <PoolRowActionSection
      className={"flex-1"}
      content={
        <InfoList
          className={"flex-1 justify-center"}
          direction={"column"}
          listItemDirection={"row"}
          InfoListItemDetail={createInfoListItemDetail({
            className: "text-xl",
            alignSelf: "left",
          })}
        >
          <InfoListItem>
            <InfoListItemTitle>Deposit APR:&nbsp;</InfoListItemTitle>
            <InfoListItemDetail>
              <Spensor>
                {() => (
                  <PercentNumber number={readResource(props.pool.depositApr)} />
                )}
              </Spensor>
            </InfoListItemDetail>
          </InfoListItem>
        </InfoList>
      }
      buttons={
        <NavLink to={props.pool.depositPageLink}>
          <GradientFilledButton
            {...btnPresets.small}
            className={clsx(
              "w-[fit-content] min-w-[150px]",
              btnPresets.small.className,
            )}
          >
            Deposit
          </GradientFilledButton>
        </NavLink>
      }
    />

    <Divider className={"border-gray-500"} direction={"vertical"} />

    <PoolRowActionSection
      className={"flex-1"}
      content={
        <InfoList
          direction={"column"}
          listItemDirection={"row"}
          InfoListItemDetail={createInfoListItemDetail({
            className: "text-xl",
            alignSelf: "left",
          })}
        >
          <InfoListItem>
            <InfoListItemTitle>Borrow APR:&nbsp;</InfoListItemTitle>
            <InfoListItemDetail>
              <Spensor fallback="--">
                {() => (
                  <PercentNumber number={readResource(props.pool.borrowApr)} />
                )}
              </Spensor>
            </InfoListItemDetail>
          </InfoListItem>
          <InfoListItem>
            <InfoListItemTitle>Collateral:&nbsp;</InfoListItemTitle>
            <InfoListItemDetail className={"flex-1"}>
              <ul className={"flex flex-wrap gap-2"}>
                {props.pool.borrowCollateral.map((t, idx) => (
                  <li key={idx} className={"flex items-center"}>
                    <TokenIcon size={24} token={t} />
                    &nbsp;
                    <TokenName className={"text-sm"} token={t} />
                  </li>
                ))}
              </ul>
            </InfoListItemDetail>
          </InfoListItem>
        </InfoList>
      }
      buttons={
        <NavLink to={props.pool.borrowPageLink}>
          <WhiteFilledButton
            {...btnPresets.small}
            className={clsx(
              "w-[fit-content] min-w-[150px]",
              btnPresets.small.className,
            )}
          >
            Borrow
          </WhiteFilledButton>
        </NavLink>
      }
    />
  </CardNested>
)

const PoolRowActionSection: FC<{
  className?: string
  content: ReactNode
  buttons: ReactNode
}> = props => (
  <div className={clsx("flex flex-col gap-5", props.className)}>
    {props.content}
    {props.buttons}
  </div>
)
