import { FC } from "react"
import { AllTokenInfos, CurrencyAmount } from "../../types"
import { OneLineTokenCount } from "./OneLineTokenCount"
import { OneLineTokenName } from "./OneLineTokenName"

export interface ExchangeRatioProps {
  fromCurrencyAmount: CurrencyAmount.Confirmed
  toCurrencyAmount: CurrencyAmount.Confirmed
  allTokenInfos: AllTokenInfos
}

export const ExchangeRatio: FC<ExchangeRatioProps> = props => {
  const ratio = props.toCurrencyAmount.amount / props.fromCurrencyAmount.amount
  return (
    <>
      <span className="whitespace-nowrap">
        <OneLineTokenCount
          currency={props.toCurrencyAmount.currency}
          count={ratio}
          allTokenInfos={props.allTokenInfos}
        />
        &nbsp;
        <OneLineTokenName
          currency={props.toCurrencyAmount.currency}
          allTokenInfos={props.allTokenInfos}
        />
        &nbsp;
      </span>
      <span className="whitespace-nowrap">
        per&nbsp;
        <OneLineTokenName
          currency={props.fromCurrencyAmount.currency}
          allTokenInfos={props.allTokenInfos}
        />
      </span>
    </>
  )
}
