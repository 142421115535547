import clsx from "clsx"
import { FC } from "react"
import { GradientText } from "../../../components/GradientText"
import { VideoIntro } from "../../../components/PageStack/VideoIntro/VideoIntro"

export const NewBrandHeader: FC<{ className?: string }> = props => (
  <div className={clsx("flex flex-col items-center gap-2.5", props.className)}>
    <GradientText
      className={"font-semibold text-7xl leading-tight text-center"}
      gradientStyle={"linear-gradient(90.83deg, #FFFFFF 0.71%, #F97316 99.29%)"}
    >
      Bridge
    </GradientText>

    <div
      className={"text-base leading-6 font-normal text-gray-400 text-center"}
    >
      Bridge is a cross-chain bridge between USDC (ERC20) and xUSD
      <br />
      designed for high performance and speed
    </div>

    <div>
      <VideoIntro
        iconSize={20}
        textClassName={"text-gray-100 text-sm leading-5 font-normal"}
        link={"https://medium.com/alexgobtc/what-is-stacks-bridge-29be1fc71ede"}
      >
        How does the bridge work?
      </VideoIntro>
    </div>
  </div>
)
