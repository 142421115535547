import { optionalResponse } from "clarity-codegen"
import { sum } from "lodash"
import { Currency } from "../../../../../utils/alexjs/Currency"
import { getTransferAmounts } from "../../../../../utils/transferHelpers"
import {
  BaseRowData,
  CurrencyAmount,
  NotifyTransactionType,
  TransformerGroup,
} from "../../../types"
import { getAmountFromContract } from "../../TransactionsModule.service"

const contracts = ["staking-helper", "dual-farming-pool"] as const
const functionName = "claim-staking-reward" as const

export interface StakingHarvestRowData extends BaseRowData {
  type: NotifyTransactionType.StakingHarvest
  principalCurrencyAmount: CurrencyAmount.Unknown | CurrencyAmount.Confirmed
  rewards: CurrencyAmount[]
}

export const stakingHarvestTransformerGroup: TransformerGroup<
  typeof contracts,
  typeof functionName,
  StakingHarvestRowData
> = {
  contracts,
  functionName,
  type: NotifyTransactionType.StakingHarvest,
  confirmedTransformer: helpers => {
    const tx = helpers.getTx()
    const args = helpers.getArgs()
    const result = helpers.getResult()
    const transfers = helpers.getTransfers()

    const tokenTrait = args.token as Currency
    if (tokenTrait !== Currency.ALEX) {
      return undefined
    }
    const principalAmount = getAmountFromContract(
      sum(
        (optionalResponse(result) ?? []).map(
          r => optionalResponse(r)?.["to-return"] ?? 0,
        ),
      ),
    )
    const transferAmounts = getTransferAmounts(transfers, tx.sender_address)
    const rewards = [
      {
        currency: Currency.ALEX,
        amount: transferAmounts[Currency.ALEX]?.in,
      },
      {
        currency: Currency.APOWER,
        amount: transferAmounts[Currency.APOWER]?.in,
      },
    ]
    return {
      principalCurrencyAmount: {
        currency: tokenTrait,
        amount: principalAmount,
      },
      rewards,
    }
  },
  pendingOrFailedTransformer: args => {
    const tokenTrait = args.token as Currency
    if (tokenTrait !== Currency.ALEX) {
      return undefined
    }
    const rewards = [
      {
        currency: Currency.ALEX,
      },
      {
        currency: Currency.APOWER,
      },
    ]
    return {
      principalCurrencyAmount: {
        currency: tokenTrait,
      },
      rewards,
    }
  },
}
