import { useConnect } from "@stacks/connect-react"
import { action } from "mobx"
import { FC, useState } from "react"
import { defineMessage, useIntl } from "react-intl"
import {
  ErrorIcon,
  MessageItem,
  SuccessIcon,
} from "../../../components/message/MessageItem"
import { useMessage } from "../../../components/message/MessageProvider"
import { Modal } from "../../../components/Modal"
import { PercentNumber } from "../../../components/PercentNumber"
import { Spensor } from "../../../components/Spensor"
import { TokenCount } from "../../../components/TokenCount"
import { TokenName } from "../../../components/TokenName"
import { CancelError } from "../../../utils/error"
import { Result } from "../../../utils/Result"
import { suspenseResource } from "../../../utils/SuspenseResource"
import { CreateOrderFrame } from "../components/CreateOrder/CreateOrderFrame"
import { LimitOrderForm } from "../components/CreateOrder/LimitOrderForm"
import { MarketOrderForm } from "../components/CreateOrder/MarketOrderForm"
import { StopLimitOrderConfirmModalContent } from "../components/CreateOrder/StopLimitOrderConfirmModalContent"
import { StopLimitOrderForm } from "../components/CreateOrder/StopLimitOrderForm"
import { StxDxOrderType } from "../components/types"
import { useOrderbookStore } from "../store/useOrderbookStore"

const orderCreatedNotificationTitle = defineMessage({
  defaultMessage: `{orderType, select, other {Limit} market {Market} stopLimit {Stop-limit}} {orderDirection, select, other {Buy} sell {Sell}} Order Created`,
  description: "Orderbook/order created notification title",
})

const orderCreatedNotificationBody = defineMessage({
  defaultMessage:
    "Submitted {orderDirection, select, other {buy} sell {sell}} {orderType, select, other {limit} market {market} stopLimit {stop-limit}} order for {receivingTokenCount} {receivingTokenName} by using {sendingTokenName}，{executedPercentage} is executed",
  description: "Orderbook/order created notification body",
})

const orderCreateFailedNotificationTitle = defineMessage({
  defaultMessage: `{orderType, select, other {Limit} market {Market} stopLimit {Stop-limit}} {orderDirection, select, other {Buy} sell {Sell}} Order Create Failed`,
  description: "Orderbook/order create failed notification title",
})

const WiredLimitOrderForm: FC = () => {
  const store = useOrderbookStore()
  return (
    <LimitOrderForm
      formError={suspenseResource(() =>
        store.trade.formData$.type === "error"
          ? store.trade.formData$.payload
          : store.trade.formData$.payload.warning,
      )}
      outgoingToken={store.trade.outgoingTokenInfo$}
      maxAvailableOutgoingTokenCount={suspenseResource(
        () => store.trade.outgoingTokenBalance,
      )}
      priceToken={store.currency.getTokenInfo$(store.trade.priceToken)}
      tradeToken={store.currency.getTokenInfo$(store.trade.tradeToken)}
      perTradeTokenPrice={suspenseResource(
        () => store.trade.perTradeTokenPrice$,
      )}
      tradeTokenCount={suspenseResource(
        () => store.trade.getTradeAmount() ?? null,
      )}
      onTradeTokenCountChange={count =>
        count == null
          ? store.trade.emptyTradeAmount()
          : store.trade.setTradeAmount(count)
      }
      totalTradeTokenPrice={suspenseResource(
        () => store.trade.priceTokenCount$,
      )}
      tradeTokenCountPercentage={suspenseResource(
        () => store.trade.tradePercentage$,
      )}
      onPerTradeTokenPriceChange={a => {
        store.trade.setCustomPrice(a ?? undefined)
      }}
      onTotalTradeTokenPriceChange={t => store.trade.setTotal(t ?? undefined)}
      onTradeTokenCountPercentageChange={p => store.trade.setPercentage(p)}
      perTradeTokenPricePrecision={store.trade.priceTokenPrecision$}
    />
  )
}

const WiredMarketOrderForm: FC = () => {
  const store = useOrderbookStore()
  const [inputMode, setInputMode] = useState<"amount" | "total">("amount")

  const priceToken = store.currency.getTokenInfo$(store.trade.priceToken)
  const tradeToken = store.currency.getTokenInfo$(store.trade.tradeToken)
  return (
    <MarketOrderForm
      formError={suspenseResource(() =>
        store.trade.formData$.type === "error"
          ? store.trade.formData$.payload
          : store.trade.formData$.payload.warning,
      )}
      outgoingToken={store.trade.outgoingTokenInfo$}
      maxAvailableOutgoingTokenCount={suspenseResource(
        () => store.trade.outgoingTokenBalance,
      )}
      priceToken={priceToken}
      tradeToken={tradeToken}
      pieceToken={inputMode === "amount" ? tradeToken : priceToken}
      pieceTokenCount={suspenseResource(() =>
        inputMode === "amount"
          ? store.trade.getTradeAmount() ?? 0
          : store.trade.priceTokenCount$,
      )}
      onPieceTokenCountPercentageChange={p => store.trade.setPercentage(p)}
      onPieceTokenCountChange={count =>
        inputMode === "amount"
          ? count == null
            ? store.trade.emptyTradeAmount()
            : store.trade.setTradeAmount(count)
          : store.trade.setTotal(count ?? undefined)
      }
      onPieceTokenChange={token =>
        setInputMode(token === tradeToken ? "amount" : "total")
      }
      pieceTokenCountPercentage={store.trade.tradePercentage$}
    />
  )
}

const WiredStopLimitOrderForm: FC = () => {
  const store = useOrderbookStore()
  return (
    <StopLimitOrderForm
      formError={suspenseResource(() =>
        store.trade.formData$.type === "error"
          ? store.trade.formData$.payload
          : store.trade.formData$.payload.warning,
      )}
      outgoingToken={store.trade.outgoingTokenInfo$}
      maxAvailableOutgoingTokenCount={suspenseResource(
        () => store.trade.outgoingTokenBalance,
      )}
      priceToken={store.currency.getTokenInfo$(store.trade.priceToken)}
      tradeToken={store.currency.getTokenInfo$(store.trade.tradeToken)}
      perTradeTokenLimitPrice={suspenseResource(
        () => store.trade.perTradeTokenPrice$,
      )}
      tradeTokenCount={suspenseResource(
        () => store.trade.getTradeAmount() ?? null,
      )}
      onTradeTokenCountChange={count =>
        count == null
          ? store.trade.emptyTradeAmount()
          : store.trade.setTradeAmount(count)
      }
      totalTradeTokenPrice={suspenseResource(
        () => store.trade.priceTokenCount$,
      )}
      tradeTokenCountPercentage={suspenseResource(
        () => store.trade.tradePercentage$,
      )}
      onPerTradeTokenLimitPriceChange={a => {
        store.trade.setCustomPrice(a ?? undefined)
      }}
      onTotalTradeTokenPriceChange={t => store.trade.setTotal(t ?? undefined)}
      onTradeTokenCountPercentageChange={p => store.trade.setPercentage(p)}
      perTradeTokenStopPrice={suspenseResource(
        () => store.trade.stopPrice.read$,
      )}
      onPerTradeTokenStopPriceChange={p =>
        store.trade.stopPrice.set(p ?? undefined)
      }
      perTradeTokenPricePrecision={store.trade.priceTokenPrecision$}
    />
  )
}

const WiredStopLimitOrderConfirmModal: FC = () => {
  const store = useOrderbookStore()
  const { $t } = useIntl()
  const message = useMessage()

  return (
    <Modal
      visible={store.trade.confirming}
      onClose={action(() => (store.trade.confirming = false))}
    >
      <Spensor>
        {() => (
          <StopLimitOrderConfirmModalContent
            orderDirection={store.trade.side}
            priceToken={store.currency.getTokenInfo$(store.trade.priceToken)}
            tradeToken={store.currency.getTokenInfo$(store.trade.tradeToken)}
            perTradeTokenLimitPrice={store.trade.perTradeTokenPrice$}
            totalTradeTokenPrice={store.trade.priceTokenCount$}
            tradeTokenCount={store.trade.tradeAmount$}
            onClose={action(() => (store.trade.confirming = false))}
            onCancel={action(() => (store.trade.confirming = false))}
            onConfirm={async () => {
              const data = Result.maybeValue(store.trade.formData$)
              if (data == null) return
              try {
                await store.trade.trade(data)
                message.show(({ close }) => ({
                  message: (
                    <MessageItem
                      icon={<SuccessIcon />}
                      title={$t(orderCreatedNotificationTitle, {
                        orderType: data.orderType,
                        orderDirection: data.side,
                      })}
                      onClose={close}
                    />
                  ),
                }))
              } catch (e) {
                console.error(e)
                message.show(({ close }) => ({
                  message: (
                    <MessageItem
                      icon={<ErrorIcon />}
                      title={$t(orderCreateFailedNotificationTitle, {
                        orderType: data.orderType,
                        orderDirection: data.side,
                      })}
                      content={(e as Error).message}
                      onClose={close}
                    />
                  ),
                }))
              }
            }}
          />
        )}
      </Spensor>
    </Modal>
  )
}

export const WiredCreateOrder: FC<{ className?: string }> = props => {
  const store = useOrderbookStore()
  const { $t } = useIntl()
  const { doAuth } = useConnect()
  const message = useMessage()

  return (
    <>
      <CreateOrderFrame
        className={props.className}
        orderDirection={store.trade.side}
        feeRates={store.trade.feeRates$}
        onChangeOrderDirection={side => store.trade.setSide(side)}
        orderType={store.trade.orderType}
        onChangeOrderType={type => {
          store.trade.setOrderType(type)
        }}
        onSubmit={async () => {
          const data = Result.maybeValue(store.trade.formData$)
          if (data == null) return

          try {
            await store.trade.trade(data)
            message.show(({ close }) => ({
              message: (
                <MessageItem
                  icon={<SuccessIcon />}
                  title={$t(orderCreatedNotificationTitle, {
                    orderType: data.orderType,
                    orderDirection: data.side,
                  })}
                  content={$t(orderCreatedNotificationBody, {
                    orderType: data.orderType,
                    orderDirection: data.side,
                    receivingTokenCount: (
                      <TokenCount
                        token={data.incomingToken}
                        count={data.size}
                      />
                    ),
                    receivingTokenName: (
                      <TokenName token={data.incomingToken} />
                    ),
                    sendingTokenName: <TokenName token={data.outgoingToken} />,
                    executedPercentage: <PercentNumber number={0} />,
                  })}
                  onClose={close}
                />
              ),
            }))
          } catch (e) {
            if (e instanceof CancelError) {
              return
            }
            console.error(e)
            message.show(({ close }) => ({
              message: (
                <MessageItem
                  icon={<ErrorIcon />}
                  title={$t(orderCreateFailedNotificationTitle, {
                    orderType: data.orderType,
                    orderDirection: data.side,
                  })}
                  content={(e as Error).message}
                  onClose={close}
                />
              ),
            }))
          }
        }}
        onConnectWallet={doAuth}
        onDeposit={() => store.trade.onDeposit()}
        onReplacePrice={() => store.trade.onReplacePrice()}
        formError={suspenseResource(() =>
          store.trade.formData$.type === "error"
            ? store.trade.formData$.payload
            : store.trade.formData$.payload.warning,
        )}
      >
        {store.trade.orderType === StxDxOrderType.Limit && (
          <WiredLimitOrderForm />
        )}
        {store.trade.orderType === StxDxOrderType.Market && (
          <WiredMarketOrderForm />
        )}
        {store.trade.orderType === StxDxOrderType.StopLimit && (
          <WiredStopLimitOrderForm />
        )}
      </CreateOrderFrame>
      <WiredStopLimitOrderConfirmModal />
    </>
  )
}
