import clsx from "clsx"
import { format } from "date-fns"
import { FC, useMemo } from "react"
import { Column, useTable } from "react-table"
import { CardModalContent } from "../../../components/CardModal/CardModal"
import {
  Table,
  TableBodyRow,
  TableHeadRow,
  Tbody,
  Td,
  Th,
  Thead,
} from "../../../components/table/StyledTable"
import { TokenCount } from "../../../components/TokenCount"
import { TokenName } from "../../../components/TokenName"
import { customReactTableRender } from "../../../utils/customReactTableRender"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { getPrecisionFloor } from "../../../utils/numberHelpers"
import { TokenInfoPresets } from "../../../utils/TokenInfoPresets/TokenInfoPresets"
import { ReactComponent as WarningIcon } from "./warning.svg"

export interface DataComparisonRowData {
  platform: string
  maxLTV: number
  loanableTokenLimit: number
  liquidationPrice?: number
  liquidated: boolean
  remainingValues: [number, number]
}

export interface DataComparisonProps {
  containerClassName?: string
  onClose?: () => void
  remainingValueDates: [Date, Date]
  dateRange: [Date, Date]
  initialPrice: number
  data: DataComparisonRowData[]
}

const tokenX = TokenInfoPresets.MockXBTC
const tokenY = TokenInfoPresets.MockXUSD

export const DataComparison: FC<DataComparisonProps> = props => {
  return (
    <CardModalContent
      title={
        <div className="text-xl leading-7 font-medium text-gray-200 pb-1">
          Data Comparison
        </div>
      }
      onClose={props.onClose}
      className={clsx(
        props.containerClassName,
        "flex flex-col max-h-[80vh] max-w-[80vw]",
      )}
    >
      <p className="text-sm leading-5 font-normal text-gray-500">
        This market data is taken from{" "}
        {format(props.dateRange[0], "LLL dd yyyy")} to{" "}
        {format(props.dateRange[1], "LLL dd yyyy")}, and assumes 1{" "}
        <TokenName token={tokenX} /> @&nbsp; $
        <TokenCount token={tokenX} count={props.initialPrice} />
        &nbsp;is submitted as collateral and <TokenName token={tokenY} />{" "}
        borrowed against it.
      </p>

      <DataTable
        data={props.data}
        remainingValueDates={props.remainingValueDates}
      />

      <div className="flex flex-row gap-x-2.5">
        <WarningIcon />
        <div className="text-sm leading-5 font-normal text-gray-500">
          <p>DISCLAIMER:</p>
          <ul className="list-decimal list-inside">
            <li>
              Remaining Value is the sum, in USD, of{" "}
              <TokenName token={tokenX} /> and <TokenName token={tokenY} />{" "}
              after dynamic rebalancing
            </li>
            <li>
              Remaining Value is equal to the current xBTC price net of
              Liquidation Fee
            </li>
            <li>
              We don&lsquo;t consider execution slippage in above simulations
            </li>
          </ul>
        </div>
      </div>
    </CardModalContent>
  )
}

const DataTable: FC<{
  data: DataComparisonRowData[]
  remainingValueDates: [Date, Date]
}> = props => {
  const tableInstance = useTable({
    columns: useTableSchema({
      remainingValueDates: props.remainingValueDates,
    }),
    data: props.data,
  })
  const { getTableProps, headerGroups, rows, getTableBodyProps, prepareRow } =
    tableInstance

  return (
    <div className="w-full overflow-auto">
      <Table {...getTableProps()}>
        <Thead>
          {headerGroups.map(headerGroup => (
            <TableHeadRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <Th
                  {...column.getHeaderProps()}
                  className="text-xs leading-4 font-bold text-white bg-gray-800 border border-gray-600"
                >
                  {customReactTableRender(column as any, "Header")}
                </Th>
              ))}
            </TableHeadRow>
          ))}
        </Thead>

        <Tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <TableBodyRow {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <Td
                    {...cell.getCellProps()}
                    className="text-xs leading-4 font-normal text-white bg-gray-800 border border-gray-600"
                  >
                    {cell.render("Cell")}
                  </Td>
                ))}
              </TableBodyRow>
            )
          })}
        </Tbody>
      </Table>
    </div>
  )
}

const useTableSchema = (context: {
  remainingValueDates: [Date, Date]
}): Column<DataComparisonRowData>[] =>
  useMemo(
    () => [
      {
        accessor: "platform",
        Header: "Platform",
      },
      {
        accessor: data =>
          `$${formatTokenCount(tokenY, data.loanableTokenLimit)} (${
            data.maxLTV
          }%LTV)`,
        Header: "Loanable Token Limit (Max LTV)",
      },
      {
        accessor: "liquidationPrice",
        Header: "Liquidation Price (xBTC)",
        Cell: props => (
          <>
            {props.value ? (
              <>
                $<TokenCount token={tokenX} count={props.value} />
              </>
            ) : (
              "-"
            )}
          </>
        ),
      },
      {
        accessor: data =>
          `$${formatTokenCount(tokenY, data.remainingValues[0])}${
            data.liquidated ? ` (liquidated)` : ""
          }`,
        Header: `Remaining Value ${format(
          context.remainingValueDates[0],
          "LLL d",
        )}`,
      },
      {
        accessor: data =>
          data.liquidated
            ? "-"
            : `$${formatTokenCount(tokenY, data.remainingValues[1])}`,
        Header: `Remaining Value ${format(
          context.remainingValueDates[1],
          "LLL d",
        )}`,
      },
    ],
    [context.remainingValueDates],
  )

function formatTokenCount(token: TokenInfo, count: number): string {
  const precision = TokenInfo.getPrecision(token)
  const precisionFloor = getPrecisionFloor(precision)

  if (count === 0) return "0"
  if (count < precisionFloor) return `<${precisionFloor}`

  // according to @fiftyeightandeight
  // we need to always round up when display number
  const scale = Math.pow(10, precision)
  const roundedUp = Math.ceil(count * scale) / scale
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: precision,
  }).format(roundedUp)
}
