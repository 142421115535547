import { zipObject } from "lodash"
import { computed, makeObservable } from "mobx"
import NotifyStore from "../../../stores/notifyStore/NotifyStore"
import { ALL_TRANSACTIONS_FILTER, TRANSACTION_FILTERS } from "../constants"
import * as types from "../types"
import { TransactionFilter } from "../types"
import { CollectionModule } from "./CollectionModule"
import { SYSTEM_MESSAGE_FILTER } from "./SystemMessagesModule"
import { coFarmTransformerGroup } from "./transformerGroups/farm/coFarm"
import { coFarmAPowerDistributionTransformerGroup } from "./transformerGroups/farm/coFarmAPowerDistribution"
import { coFarmAutoHarvestTransformerGroup } from "./transformerGroups/farm/coFarmAutoHarvest"
import { coFarmClaimTransformerGroup } from "./transformerGroups/farm/coFarmClaim"
import { farmTransformerGroup } from "./transformerGroups/farm/farm"
import { farmHarvestTransformerGroup } from "./transformerGroups/farm/farmHarvest"
import { borrowTransformerGroup } from "./transformerGroups/lend/borrow"
import { borrowClaimTransformerGroup } from "./transformerGroups/lend/borrowClaim"
import { borrowRolloverTransformerGroup } from "./transformerGroups/lend/borrowRollover"
import { depositTransformerGroup } from "./transformerGroups/lend/deposit"
import { depositClaimTransformerGroup } from "./transformerGroups/lend/depositClaim"
import { depositRolloverTransformerGroup } from "./transformerGroups/lend/depositRollover"
import { depositSellTransformerGroup } from "./transformerGroups/lend/depositSell"
import { buyLotteryTransformerGroup } from "./transformerGroups/lottery/buyLottery"
import { lotteryWonTransformerGroup } from "./transformerGroups/lottery/lotteryWon"
import { addLiquidityTransformerGroup } from "./transformerGroups/pool/addLiquidity"
import { removeLiquidityTransformerGroup } from "./transformerGroups/pool/removeLiquidity"
import { autoStakingTransformerGroup } from "./transformerGroups/stake/autoStaking"
import { autoStakingAPowerDistributionTransformerGroup } from "./transformerGroups/stake/autoStakingAPowerDistribution"
import { manualStakingTransformerGroup } from "./transformerGroups/stake/manualStaking"
import { stakingHarvestTransformerGroup } from "./transformerGroups/stake/stakingHarvest"
import { StakingHarvestAndMintAtAlexTransformerGroup } from "./transformerGroups/stake/stakingHarvestAndMintAtAlex"
import {
  swapATransformerGroup,
  swapBTransformerGroup,
  swapCTransformerGroup,
  swapTransformerGroup,
} from "./transformerGroups/swap/swap"

/**
 * defined collections here
 */
const collections = {
  swap: {
    swap: swapTransformerGroup,
    swapA: swapATransformerGroup,
    swapB: swapBTransformerGroup,
    swapC: swapCTransformerGroup,
  },
  pool: {
    addLiquidity: addLiquidityTransformerGroup,
    removeLiquidity: removeLiquidityTransformerGroup,
  },
  stake: {
    manualStaking: manualStakingTransformerGroup,
    stakingHarvest: stakingHarvestTransformerGroup,
    stakingHarvestAndMintAtAlex: StakingHarvestAndMintAtAlexTransformerGroup,
    autoStaking: autoStakingTransformerGroup,
    autoStakingAPowerDistribution:
      autoStakingAPowerDistributionTransformerGroup,
  },
  farm: {
    farm: farmTransformerGroup,
    farmHarvest: farmHarvestTransformerGroup,
    coFarm: coFarmTransformerGroup,
    coFarmAutoHarvest: coFarmAutoHarvestTransformerGroup,
    coFarmAPowerDistribution: coFarmAPowerDistributionTransformerGroup,
    coFarmClaim: coFarmClaimTransformerGroup,
  },
  lend: {
    deposit: depositTransformerGroup,
    depositClaim: depositClaimTransformerGroup,
    depositRollover: depositRolloverTransformerGroup,
    depositSell: depositSellTransformerGroup,
    borrow: borrowTransformerGroup,
    borrowClaim: borrowClaimTransformerGroup,
    borrowRollover: borrowRolloverTransformerGroup,
  },
  lottery: {
    buyLottery: buyLotteryTransformerGroup,
    lotteryWon: lotteryWonTransformerGroup,
  },
} as const

export type CollectionFilter = keyof typeof collections

export const collectionFilters: CollectionFilter[] = Object.keys(
  collections,
) as any

export class TransactionsModule {
  constructor(readonly store: NotifyStore) {
    makeObservable(this)
  }

  private collections = collections

  @computed get filter(): TransactionFilter | undefined {
    return this.store.filter === SYSTEM_MESSAGE_FILTER
      ? undefined
      : this.store.filter
  }

  @computed get rowData$(): types.BaseRowData[] {
    return this.filter ? this.collectionModules$[this.filter].rows$ : []
  }

  @computed get rowToExportData$(): types.BaseRowData[] {
    return this.filter ? this.collectionModules$[this.filter].rowsToExport$ : []
  }

  @computed get pagination$(): types.PaginationInfo | undefined {
    return this.filter
      ? this.collectionModules$[this.filter].pagination$
      : undefined
  }

  // helpers
  @computed private get collectionModules$(): {
    [key in TransactionFilter]: CollectionModule
  } {
    return zipObject<CollectionModule>(TRANSACTION_FILTERS, [
      this.allTransactionsModule$,
      ...this.collectionNames.map(
        name =>
          new CollectionModule(
            name,
            this.store.accountStore,
            this.store.authStore,
            this.store.currencyStore,
            this.collections[name]!,
          ),
      ),
    ]) as any
  }

  @computed private get collectionNames(): CollectionFilter[] {
    return collectionFilters
  }

  /**
   * automatically merge all collections
   */
  @computed private get allTransactionsModule$(): CollectionModule {
    const collection: types.TransformerCollection =
      this.collectionNames.reduce<types.TransformerCollection>(
        (acc, curr) => ({
          ...acc,
          ...this.collections[curr],
        }),
        {},
      )
    return new CollectionModule(
      ALL_TRANSACTIONS_FILTER,
      this.store.accountStore,
      this.store.authStore,
      this.store.currencyStore,
      collection,
    )
  }
}
