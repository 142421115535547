import { FC } from "react"
import { GradientFilledButton } from "../../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { CardInset } from "../../../../../components/Card"

export const ConnectWallet: FC<{
  onConnect?: () => void
}> = props => {
  return (
    <CardInset className="flex flex-col gap-y-6">
      <div className="text-lg leading-7 font-normal text-gray-200 text-center">
        Connect wallet to view your Ranking
      </div>
      <GradientFilledButton
        boxClassName={"py-2 px-15"}
        onClick={props.onConnect}
      >
        Connect Wallet
      </GradientFilledButton>
    </CardInset>
  )
}
