import { FC } from "react"
import { TokenCount } from "../../../components/TokenCount"
import { TokenIcon } from "../../../components/TokenIcon"
import { TokenName } from "../../../components/TokenName"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import {
  PlainVerticalInfoListContainer,
  PlainVerticalInfoListItem,
} from "./InfoList"
import { TokenIDOPrices, TokenIDORaiseNumbers } from "./types"

export const TokenIDOSummary: FC<{
  className?: string
  idoToken: TokenInfo
  priceToken: TokenInfo
  idoPrices: TokenIDOPrices
  idoNumbers: TokenIDORaiseNumbers
}> = props => {
  const { idoToken, priceToken, idoPrices } = props
  return (
    <PlainVerticalInfoListContainer className={props.className}>
      <PlainVerticalInfoListItem
        title="Total Raise"
        detail={
          <>
            <TokenIcon className={"mr-2"} token={priceToken} size={24} />
            <TokenCount
              token={priceToken}
              count={props.idoNumbers.maxRaisePriceTokenCount}
            />
            &nbsp;
            <TokenName token={priceToken} />
          </>
        }
      />

      <PlainVerticalInfoListItem
        title="Token Per Ticket"
        detail={
          <>
            <TokenIcon className={"mr-2"} token={idoToken} size={24} />
            <TokenCount
              token={idoToken}
              count={idoPrices.idoTokenCountPerTicket}
            />
            &nbsp;
            <TokenName token={idoToken} />
          </>
        }
      />

      <PlainVerticalInfoListItem
        title={
          <>
            Price Per <TokenName token={idoToken} />
          </>
        }
        detail={
          <>
            <TokenIcon className={"mr-2"} token={priceToken} size={24} />
            &nbsp;
            <TokenCount token={priceToken} count={idoPrices.pricePerToken} />
            &nbsp;
            <TokenName token={priceToken} />
          </>
        }
      />
    </PlainVerticalInfoListContainer>
  )
}
