import { FC } from "react"
import { TokenIcon } from "../../../components/TokenIcon"
import { TokenInfo } from "../../../utils/models/TokenInfo"

export interface TokenIconStackProps {
  size: number
  tradeToken: TokenInfo
  priceToken: TokenInfo
}

export const TokenIconStack: FC<TokenIconStackProps> = props => {
  const tokens = [props.tradeToken, props.priceToken]

  return (
    <ul className={"flex relative"}>
      {tokens.map((t, idx) => (
        <li
          key={idx}
          className={"relative"}
          style={{
            marginLeft: idx === 0 ? 0 : 0 - props.size / 4,
            zIndex: tokens.length - idx,
          }}
        >
          <TokenIcon displayClassName={"block"} token={t} size={props.size} />
        </li>
      ))}
    </ul>
  )
}
