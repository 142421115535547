import React, { FC } from "react"
import { useParams } from "react-router-dom"
import { useAppEnvStore } from "../../stores/appEnvStore/useAppEnvStore"
import { FullPageIframe } from "./components/FullPageIframe"

export const AnalyticsDetailPage: FC = () => {
  const appEnvStore = useAppEnvStore()
  const { urlSlug } = useParams<"urlSlug">()
  const matchTab = appEnvStore.config$.analyticsTabs.find(
    t => t.urlSlug === urlSlug,
  )

  if (matchTab == null) {
    throw new Error(`No tab found for ${urlSlug}`)
  }

  return <FullPageIframe iframe={matchTab.iframeUrl} />
}
