import { FC } from "react"
import { BlockTimeCountdown } from "../../../components/TimeCountdown/BlockTimeCountdown"
import { Tooltip } from "../../../components/Tooltip/Tooltip"

export const StepEndCountdown: FC<{
  className?: string
  currentStepNumber: number
  currentBlock: number
  currentStepEndedAt: Date
  endBlock: number
}> = props => (
  <Tooltip
    title={`Current Block: ${props.currentBlock}\nStep ${props.currentStepNumber} Ended At: Block ${props.endBlock}`}
  >
    <BlockTimeCountdown
      prefix={"Step Countdown"}
      currentBlock={props.currentBlock}
      targetBlock={props.endBlock}
      time={props.currentStepEndedAt}
    />
  </Tooltip>
)
