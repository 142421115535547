import clsx from "clsx"
import { FC } from "react"
import { TokenInfo } from "../utils/models/TokenInfo"
import { readResource, SuspenseResource } from "../utils/SuspenseResource"
import { Spensor } from "./Spensor"
import { TokenCount } from "./TokenCount"
import { TokenIcon } from "./TokenIcon"
import { TokenName } from "./TokenName"

export const IconTokenCount: FC<{
  className?: string
  /**
   * @default true
   */
  inline?: boolean
  /**
   * @default 24
   */
  iconSize?: number
  tokenNameClassName?: string
  token: TokenInfo
  count: SuspenseResource<number>
}> = props => (
  <span
    className={clsx(
      "items-center",
      props.inline ?? true ? "inline-flex" : "flex",
      props.className,
    )}
  >
    <TokenIcon size={props.iconSize ?? 24} token={props.token} />
    &nbsp;
    <Spensor fallback={"-"}>
      {() => (
        <>
          <TokenCount token={props.token} count={readResource(props.count)} />
          &nbsp;
          <TokenName className={props.tokenNameClassName} token={props.token} />
        </>
      )}
    </Spensor>
  </span>
)

export const TextTokenCount: FC<{
  className?: string
  tokenNameClassName?: string
  token: TokenInfo
  count: SuspenseResource<number>
}> = props => (
  <Spensor fallback={"-"}>
    {() => (
      <span className={clsx("inline-flex items-center", props.className)}>
        <TokenCount token={props.token} count={readResource(props.count)} />
        &nbsp;
        <TokenName className={props.tokenNameClassName} token={props.token} />
      </span>
    )}
  </Spensor>
)
