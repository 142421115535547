import clsx from "clsx"
import { FCC } from "../../utils/reactHelpers/types"
import {
  safeReadResource,
  SuspenseResource,
} from "../../utils/SuspenseResource"
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator"
import { formatTimeCountdownText } from "../TimeCountdown/TimeCountdownText"
import { Tooltip } from "../Tooltip/Tooltip"

export type BlockStatusProps = {
  className?: string
  networkType: "mainnet" | "testnet"
  status: SuspenseResource<"normal" | "busy" | "stuck">
  block: number
  cycleBlockStart: number
  cycleBlockEnd: number
  estimatedCycleEndDate: Date
}

const displayMap = {
  normal: "Normal",
  busy: "Slow",
  stuck: "Congested",
} as const

const BlockStatus: FCC<BlockStatusProps> = props => {
  const status = safeReadResource(props.status)
  const textColorClasses =
    status === "stuck"
      ? "text-red-500"
      : status === "busy"
      ? "text-yellow-400"
      : "text-green-500"
  return (
    <Tooltip
      className={props.className}
      title={
        <div className="text-xs leading-4 font-normal text-gray-200">
          <span className="text-gray-500">Current Cycle</span>
          <br />
          Block: {props.cycleBlockStart} ~ {props.cycleBlockEnd}
          <br />
          Countdown Est: {formatTimeCountdownText(props.estimatedCycleEndDate)}
          <br />
          <span className={textColorClasses}>
            {props.networkType === "mainnet" ? "Mainnet" : "Testnet"} :{" "}
            {status ? displayMap[status] : "--"}
          </span>
        </div>
      }
    >
      <div
        className={clsx(
          "flex items-center text-xs leading-4 font-semibold gap-1 w-fit-content",
          textColorClasses,
        )}
      >
        <div className="w-4 h-4 flex items-center justify-center relative">
          {status == null && <LoadingIndicator size={14} />}
          <div className="w-1.5 h-1.5 rounded-full bg-current absolute" />
        </div>
        Block {props.block}
      </div>
    </Tooltip>
  )
}

export default BlockStatus
