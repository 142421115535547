import { ComponentType } from "react"
import { FCC } from "../../utils/reactHelpers/types"
import { ButtonVariantProps } from "./ButtonVariant"
import { WhiteFilledButton } from "./variants/WhiteFilledButton"
import * as btnPresets from "./_/defaultStyles"

export { btnPresets }

export interface ButtonProps extends ButtonVariantProps {
  Variant?: ComponentType<ButtonVariantProps>
}
export const Button: FCC<ButtonProps> = props => {
  const { Variant = WhiteFilledButton, ...restProps } = props
  return <Variant {...restProps}>{props.children}</Variant>
}
