import clsx from "clsx"
import { FC, useMemo } from "react"
import { Column, useTable } from "react-table"
import { GreenBadge } from "../../../../components/Badge"
import { CardModalContent } from "../../../../components/CardModal/CardModal"
import { LoadingIndicator } from "../../../../components/LoadingIndicator/LoadingIndicator"
import { Spensor } from "../../../../components/Spensor"
import {
  Table,
  TableBodyRow,
  TableHeadRow,
  Tbody,
  Td,
  Th,
  Thead,
} from "../../../../components/table/StyledTable"
import { TipIcon } from "../../../../components/TipIcon/TipIcon"
import { TokenCount } from "../../../../components/TokenCount"
import { TokenName } from "../../../../components/TokenName"
import { customReactTableRender } from "../../../../utils/customReactTableRender"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import {
  readResource,
  SuspenseResource,
} from "../../../../utils/SuspenseResource"
import { TokenInfoPresets } from "../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { ApowerDistributionCycle } from "../../store/autoStaking/AutoStakeStore.service"
import { ReactComponent as EmptyIcon } from "./empty.svg"

export interface AutoAlexApowerDistributionContentProps {
  cycles: SuspenseResource<ApowerDistributionCycle[]>
  currentCycleNumber: SuspenseResource<number>
  autoAlexToken: SuspenseResource<TokenInfo>
  containerClassName?: string
  onClose?: () => void
}

export const AutoAlexApowerDistributionContent: FC<
  AutoAlexApowerDistributionContentProps
> = props => (
  <CardModalContent
    title={
      <span className="text-xl leading-7 font-medium text-gray-200">
        My Auto-Staking APower
      </span>
    }
    onClose={props.onClose}
    className={clsx(props.containerClassName, "flex flex-col")}
    width={"80vw"}
    minHeight={"80vh"}
  >
    <Spensor
      fallback={
        <div className="w-full grow flex items-center justify-center">
          <LoadingIndicator className="mx-auto" size={72} />
        </div>
      }
    >
      {() => (
        <DataTable
          cycles={readResource(props.cycles)}
          currentCycleNumber={readResource(props.currentCycleNumber)}
          autoAlexToken={readResource(props.autoAlexToken)}
        />
      )}
    </Spensor>
  </CardModalContent>
)

const DataTable: FC<{
  cycles: ApowerDistributionCycle[]
  currentCycleNumber: number
  autoAlexToken: TokenInfo
}> = props => {
  const tableInstance = useTable({
    columns: useTableSchema({
      currentCycleNumber: props.currentCycleNumber,
      apowerToken: TokenInfoPresets.APower,
      autoAlexToken: props.autoAlexToken,
    }),
    data: props.cycles,
  })
  const { getTableProps, headerGroups, rows, getTableBodyProps, prepareRow } =
    tableInstance
  return (
    <div className="flex flex-col flex-1 w-full overflow-auto">
      <Table {...getTableProps()}>
        <Thead>
          {headerGroups.map(headerGroup => (
            <TableHeadRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <Th
                  {...column.getHeaderProps()}
                  className="text-sm leading-5 font-normal text-gray-500"
                >
                  {customReactTableRender(column as any, "Header")}
                </Th>
              ))}
            </TableHeadRow>
          ))}
        </Thead>

        <Tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <TableBodyRow {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                ))}
              </TableBodyRow>
            )
          })}
        </Tbody>
      </Table>
      {props.cycles.length === 0 && (
        <div className="grow w-full flex flex-col gap-y-4 items-center justify-center">
          <EmptyIcon />
          <span className="text-base leading-6 font-normal text-gray-600">
            No data
          </span>
        </div>
      )}
    </div>
  )
}

const useTableSchema = (context: {
  currentCycleNumber: number
  apowerToken: TokenInfo
  autoAlexToken: TokenInfo
}): Column<ApowerDistributionCycle>[] =>
  useMemo(
    () => [
      {
        Header: "Cycle",
        accessor: "cycle",
        Cell: props => (
          <span className="flex flex-row items-center gap-x-2.5">
            <span className="text-lg leading-6 font-bold text-gray-200">
              #{props.cell.value}
            </span>
            {props.cell.value === context.currentCycleNumber ? (
              <GreenBadge>Current</GreenBadge>
            ) : null}
          </span>
        ),
      },
      {
        Header: (
          <span className="flex flex-row gap-x-1 items-center">
            Eligible <TokenName token={context.autoAlexToken} />
            <TipIcon tip="Your eligible atALEX amount when snapshot was taken" />
          </span>
        ),
        accessor: "eligibleAutoAlex",
        Cell: props => (
          <>
            {props.cell.value ? (
              <TokenCount
                token={context.autoAlexToken}
                count={props.cell.value}
              />
            ) : (
              "-"
            )}
          </>
        ),
      },
      {
        Header: (
          <span className="flex flex-row gap-x-1 items-center">
            Snapshot Block
            <TipIcon tip="The snapshot blocks are randomly chosen each cycle" />
          </span>
        ),
        accessor: "snapshotBlockHeight",
        Cell: props => (
          <>
            {props.cell.value ? `#${props.cell.value}` : "Waiting for snapshot"}
          </>
        ),
      },
      {
        Header: (
          <span className="flex flex-row gap-x-1 items-center">
            <TokenName token={context.apowerToken} /> Rewards
            <TipIcon tip="The calculated amount of APower rewards each cycle." />
          </span>
        ),
        accessor: "apowerRewards",
        Cell: props => (
          <>
            {props.cell.value ? (
              <TokenCount
                count={props.cell.value}
                token={context.apowerToken}
              />
            ) : (
              "-"
            )}
          </>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: props => <>{props.cell.value ?? "-"}</>,
      },
    ],
    [context.autoAlexToken, context.apowerToken, context.currentCycleNumber],
  )
