import { last, range } from "lodash"
import { action, computed, makeObservable, observable } from "mobx"
import { ESTIMATED_BLOCK_DURATION } from "../../../../../config"
import { asSender } from "../../../../../generated/smartContractHelpers/asSender"
import type { AppEnvStore } from "../../../../../stores/appEnvStore/AppEnvStore"
import { ConfirmTransactionStore } from "../../../../../stores/confirmTransactionDialogStore/ConfirmTransactionStore"
import { SuspenseObservable } from "../../../../../stores/SuspenseObservable"
import { transfer } from "../../../../../utils/alexjs/postConditions"
import { asyncAction, runAsyncAction } from "../../../../../utils/asyncAction"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { isPromiseLike } from "../../../../../utils/promiseHelpers"
import { Result } from "../../../../../utils/Result"
import {
  FormError,
  FormErrorType,
} from "../../../manualStakeComponents/AddStakeSection/types"
import type { CurvePoint } from "../../../manualStakeComponents/EarningsPreviewSection/EarningsPreviewSection"
import StakeChainModule from "../../shared/StakeChainModule"
import type ManualStakeStore from "../ManualStakeStore"

type AddStakeFormData = { amount: number; cycles: number }

class AddStakeViewModule {
  constructor(
    readonly store: ManualStakeStore,
    readonly appEnvStore: Pick<AppEnvStore, "config$">,
    readonly stakeChainModule: Pick<
      StakeChainModule,
      "getCycleNumberFromBlockHeight"
    >,
  ) {
    makeObservable(this)
  }

  @computed get balance$(): number {
    return this.store.accountStore.getBalance$(this.store.token)
  }

  @observable amountToStake = new SuspenseObservable<number>()
  @action setAmountToStake(amount?: number): void {
    // in case of MAX, we want to collect all of it
    const precision = TokenInfo.getPrecision(this.store.tokenInfo$)
    if (
      this.amountToStake.get()?.toFixed(precision) ===
      amount?.toFixed(precision)
    ) {
      return
    }
    this.amountToStake.set(amount)
  }

  @computed get amountToUSD(): number {
    return (
      this.store.currencyStore.getPrice$(this.store.token) *
      this.amountToStake.read$
    )
  }

  @computed get lastCircle(): number {
    return this.store.nextCycle$ + this.cyclesToStake - 1
  }

  @computed get startedAt$(): Date {
    return this.store.nextCycleDate$
  }

  @computed get startedAtCycleNumber$(): number {
    return this.store.nextCycle$
  }

  @computed get endedAtCycleNumber$(): number {
    return this.startedAtCycleNumber$ + this.cyclesToStake - 1
  }

  @computed get startedAtBlock$(): number {
    return this.store.nextCycleBlock$
  }

  @computed get endedAtBlock$(): number {
    return (
      this.startedAtBlock$ +
      this.cyclesToStake * this.store.blocksPerCycle.value$ -
      1
    )
  }

  @computed get estimateDayCount$(): number {
    return Math.round(
      (this.store.blocksPerCycle.value$ *
        this.cyclesToStake *
        ESTIMATED_BLOCK_DURATION) /
        (24 * 60 * 60 * 1000),
    )
  }

  @observable confirming = false

  addStake = new ConfirmTransactionStore()

  @computed get stakeData(): Result<AddStakeFormData, FormError> {
    if (!this.store.authStore.isWalletConnected) {
      return Result.error({
        type: FormErrorType.WalletNotConnected,
        message: "Connect Wallet",
      })
    }

    try {
      if (this.amountToStake.read$ === 0) {
        throw Promise.reject()
      }
    } catch (e) {
      if (isPromiseLike(e)) {
        return Result.error({
          type: FormErrorType.AmountIsEmpty,
          message: "Enter an amount",
        })
      }

      throw e
    }

    if (Number(this.amountToStake.read$) === 0) {
      return Result.error({
        type: FormErrorType.LessThanMinimizeAmount,
        message: "Insufficient amount",
      })
    }

    if (this.amountToStake.read$ > this.balance$) {
      return Result.error<FormError>({
        type: FormErrorType.InsufficientTokenBalance,
        message: "Insufficient balance",
      })
    }

    return Result.ok({
      amount: this.amountToStake.read$,
      cycles: this.cyclesToStake,
    })
  }

  @asyncAction async stake(
    data: AddStakeFormData,
    run = runAsyncAction,
  ): Promise<void> {
    this.confirming = false
    try {
      const senderAddress = this.store.authStore.stxAddress$
      const { txId } = await run(
        asSender(senderAddress)
          .contract("alex-reserve-pool")
          .func("stake-tokens")
          .call(
            {
              "amount-token": data.amount * 1e8,
              "lock-period": data.cycles,
              "token-trait": this.store.token,
            },
            [transfer(senderAddress, this.store.token, data.amount)],
          ),
      )
      this.addStake.successRunning(txId)
    } catch (e) {
      this.addStake.errorRunning(e as Error)
    }
  }

  @action clear(): void {
    this.cyclesToStake = this.maxSelectableCycleCount$
    this.amountToStake.set()
  }

  @observable private cyclesToStake = this.maxSelectableCycleCount$

  @computed get userSelectedCycleCount(): number {
    return this.cyclesToStake
  }

  @action selectCycleCount(count: number): void {
    this.cyclesToStake = Math.min(count, this.maxSelectableCycleCount$)
  }

  @computed get dynamicStakingCycleNumberLimit$(): undefined | number {
    return this.store.v1StakeEndCycle$
  }

  @computed get maxSelectableCycleCount$(): number {
    const standardLimit = 32

    if (this.dynamicStakingCycleNumberLimit$ != null) {
      return Math.min(
        standardLimit,
        Math.max(
          this.dynamicStakingCycleNumberLimit$ - this.store.currentCycle$,
          0,
        ),
      )
    }

    return standardLimit
  }

  @computed get missedCycleCount(): number {
    const cycleCountPerRound = this.cyclesToStake + 1
    return Math.floor(this.maxSelectableCycleCount$ / cycleCountPerRound)
  }

  @computed get idealEarningsCurve(): CurvePoint[] {
    return range(0, this.maxSelectableCycleCount$).map(idx => ({
      cycle: idx + 1,
      apr: this.store.idealEarningPreview[idx] ?? 0,
    }))
  }

  @computed get estimatedEarningsCurve(): CurvePoint[] {
    const selectedCurveApr = this.idealEarningsCurve
      .slice(0, this.cyclesToStake - 1)
      .map(c => c.apr)

    const restCurveApr = range(
      this.cyclesToStake - 1,
      this.idealEarningsCurve.length + 1,
    ).fill(
      this.store.idealEarningPreview[this.cyclesToStake - 1] ??
        last(this.store.idealEarningPreview) ??
        0,
    )

    return [...selectedCurveApr, ...restCurveApr].map((apr, idx) => ({
      cycle: idx + 1,
      apr,
    }))
  }

  @computed get gapCurve(): CurvePoint[] {
    return range(1, this.missedCycleCount + 1)
      .map(i => i * (this.cyclesToStake + 1) - 1)
      .map(
        cycle =>
          this.estimatedEarningsCurve[cycle - 1] ??
          last(this.estimatedEarningsCurve)!,
      )
  }
}

export default AddStakeViewModule
