import { FC } from "react"
import {
  CardInset,
  CardInsetDivider,
  CardInsetTitle,
} from "../../../../../components/Card"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../../components/InfoList"
import { TextTokenCount } from "../../../../../components/RichTokenCount"
import { TokenCountPairPlain } from "../../../../../components/TokenInput/TokenBlock"
import { ReactComponent as CongratsIcon } from "./_/congrats.svg"

export const MyLotteryPanel$Congrats: FC<{
  wonPrize: TokenCountPairPlain
  wonFirstLottery: number
  wonSecondLottery: number
  wonThirdLottery: number
}> = props => {
  return (
    <>
      <CardInset className={"flex flex-col gap-4"}>
        <div className={"flex flex-col items-center gap-4"}>
          <CongratsIcon />
          <div className={"text-center"}>
            <CardInsetTitle>Congratulations!</CardInsetTitle>
            <CardInsetTitle>You won:</CardInsetTitle>
          </div>
        </div>

        <div className={"text-center"}>
          <TextTokenCount
            className={"text-4xl text-yellow-200"}
            tokenNameClassName={"text-2xl"}
            token={props.wonPrize.token}
            count={props.wonPrize.count}
          />
        </div>

        <CardInsetDivider />

        <InfoList
          gapClassName={"gap-2.5"}
          direction={"row"}
          listItemDirection={"column"}
          listItemClassName={"flex-1 text-center"}
        >
          <InfoListItem>
            <InfoListItemTitle>First Prize</InfoListItemTitle>
            <InfoListItemDetail>
              <LotteryCount count={props.wonFirstLottery} />
            </InfoListItemDetail>
          </InfoListItem>
          <InfoListItem>
            <InfoListItemTitle>Second Prize</InfoListItemTitle>
            <InfoListItemDetail>
              <LotteryCount count={props.wonSecondLottery} />
            </InfoListItemDetail>
          </InfoListItem>
          <InfoListItem>
            <InfoListItemTitle>Third Prize</InfoListItemTitle>
            <InfoListItemDetail>
              <LotteryCount count={props.wonThirdLottery} />
            </InfoListItemDetail>
          </InfoListItem>
        </InfoList>
      </CardInset>
    </>
  )
}

const LotteryCount: FC<{
  count: number
}> = props => {
  return (
    <span className={"inline-flex items-center text-gray-200"}>
      <span className={"text-lg"}>{props.count}</span>&nbsp;
      <span className={"text-xs"}>Lottery</span>
    </span>
  )
}
