import { when } from "mobx"
import { isPromiseLike } from "./promiseHelpers"

export function waitUntilExist$<T>(value$: () => T | null | undefined): T {
  const value = value$()
  if (value == null) {
    throw waitUntil(() => value$() != null)
  }
  return value
}

export async function waitUntil(retrieve$: () => boolean): Promise<void> {
  return new Promise((resolve, reject) => {
    const dispose = when(
      () => {
        try {
          return retrieve$()
        } catch (e) {
          if (isPromiseLike(e)) {
            return false
          } else {
            reject(e)
            dispose?.()
            return false
          }
        }
      },
      () => {
        resolve()
      },
    )
  })
}

export async function waitFor<T>(retrieve$: () => T): Promise<T> {
  return new Promise((resolve, reject) => {
    const dispose = when(
      () => {
        try {
          resolve(retrieve$())
          return true
        } catch (e) {
          if (isPromiseLike(e)) {
            return false
          } else {
            reject(e)
            dispose?.()
            return false
          }
        }
      },
      () => null,
    )
  })
}

export function safelyGet<T>(action$: () => T): T | undefined {
  let result: undefined | T
  try {
    result = action$()
  } catch (err) {
    if (isPromiseLike(err)) {
      result = undefined
    } else {
      throw err
    }
  }
  return result
}
