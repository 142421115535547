import { FC } from "react"
import { GradientFilledButton } from "../../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteFilledButton } from "../../../../../components/button/variants/WhiteFilledButton"
import { CardPlate } from "../../../../../components/Card"
import { CardModalContent } from "../../../../../components/CardModal/CardModal"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../../components/InfoList"
import { NoteParagraph } from "../../../../../components/NoteParagraph/NoteParagraph"
import { PercentNumber } from "../../../../../components/PercentNumber"
import {
  BlockTopLine,
  BlockTopTitleLine,
} from "../../../../../components/TokenInput/Block"
import {
  BlockGroup,
  BlockGroupDownArrowIcon,
} from "../../../../../components/TokenInput/BlockGroup"
import {
  TokenBlock,
  TokenCountPairPlain,
} from "../../../../../components/TokenInput/TokenBlock"
import {
  BorrowInfoListContent,
  BorrowInfoListContentProps,
} from "../BorrowInfoListContent"

export interface ConfirmDepositModalContentProps
  extends BorrowInfoListContentProps {
  collateralAssets: TokenCountPairPlain
  borrowedAssets: TokenCountPairPlain

  ltv: number

  onConfirm?: () => void
  onClose?: () => void
}

export const ConfirmBorrowModalContent: FC<
  ConfirmDepositModalContentProps
> = props => (
  <CardModalContent
    onClose={props.onClose}
    title={"Confirm Borrow"}
    className={"flex flex-col gap-4"}
    width={480}
  >
    <BlockGroup
      firstBlock={
        <TokenBlock
          topArea={<BlockTopLine>Collateral</BlockTopLine>}
          tokens={[props.collateralAssets]}
        />
      }
      icon={<BlockGroupDownArrowIcon />}
      secondBlock={
        <TokenBlock
          topArea={<BlockTopTitleLine>Receive</BlockTopTitleLine>}
          tokens={[props.borrowedAssets]}
        />
      }
    />

    <CardPlate className={"flex flex-col gap-2"}>
      <InfoList listItemDirection={"row-responsive"}>
        <InfoListItem>
          <InfoListItemTitle>LTV</InfoListItemTitle>
          <InfoListItemDetail>
            <PercentNumber number={props.ltv} />
          </InfoListItemDetail>
        </InfoListItem>

        <BorrowInfoListContent
          interestToken={props.interestToken}
          expirationDate={props.expirationDate}
          expirationBlockHeight={props.expirationBlockHeight}
          estimateAbsoluteInterest={props.estimateAbsoluteInterest}
          liquidityProviderFee={props.liquidityProviderFee}
          crpInfo={props.crpInfo}
          slippage={props.slippage}
        />
      </InfoList>
    </CardPlate>

    <NoteParagraph>
      Your collateral will be actively managed by the CRP Pool.
      <br />
      You can claim your excess collateral upon maturity.
    </NoteParagraph>

    <div className={"flex flex-row gap-2.5"}>
      <WhiteFilledButton className={"flex-1"} onClick={props.onClose}>
        Cancel
      </WhiteFilledButton>

      <GradientFilledButton className={"flex-1"} onClick={props.onConfirm}>
        Confirm
      </GradientFilledButton>
    </div>
  </CardModalContent>
)
