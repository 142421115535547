import { FC } from "react"
import { TipIcon } from "../../../../../components/TipIcon/TipIcon"
import { TokenCount } from "../../../../../components/TokenCount"
import { TokenIcon, TokenIconProps } from "../../../../../components/TokenIcon"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { withClassName } from "../../../../../utils/reactHelpers/withClassName"
import { ReactComponent as AmountIcon } from "./amount.svg"
import { ReactComponent as RankingIcon } from "./ranking.svg"
import { ReactComponent as RewardIcon } from "./reward.svg"

export const Statistics: FC<{
  tokenInfo?: TokenInfo
  volume?: number | null
  ranking?: number | null
  rewardsMultiplier?: number | null
}> = props => (
  <dl className="flex flex-col gap-y-3">
    <Row>
      <Dt>
        <AmountIcon />
        <DtText>
          <span>Eligible Amount</span>
        </DtText>
      </Dt>
      {props.tokenInfo ? (
        <Dd className="flex flex-row gap-x-2">
          <TokenIcon
            token={props.tokenInfo}
            size={TokenIconProps.sizes.small}
          />
          <span>
            {props.volume == null ? (
              "-"
            ) : (
              <TokenCount token={props.tokenInfo} count={props.volume} />
            )}
          </span>
        </Dd>
      ) : (
        <Dd>-</Dd>
      )}
    </Row>
    <Row>
      <Dt>
        <RankingIcon />
        <DtText>My Ranking</DtText>
      </Dt>
      <Dd>{props.ranking ?? "-"}</Dd>
    </Row>
    <Row>
      <Dt>
        <RewardIcon />
        <DtText>
          <span>My Rewards Multiplier</span>
          <TipIcon tip="The final multiplier for a wallet will be the average of the 3 snapshots" />
        </DtText>
      </Dt>
      <Dd>
        {props.rewardsMultiplier == null
          ? "-"
          : props.rewardsMultiplier === 0
          ? "-"
          : props.rewardsMultiplier.toFixed(2) + "x"}
      </Dd>
    </Row>
  </dl>
)

const Row = withClassName("flex flex-row justify-between", "div")
const Dt = withClassName("flex flex-row gap-x-2.5 items-center shrink", "dt")
const DtText = withClassName(
  "text-base leading-6 font-normal flex flex-row items-center gap-x-1",
  "span",
)
const Dd = withClassName(
  "min-w-[4.5rem] flex flex-row items-center justify-end",
  "dd",
)
