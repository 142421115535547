import { FC, useRef } from "react"
import {
  WiredNewTokenomicsModal,
  WiredNewTokenomicsModalRefValue,
} from "../../../components/NewTokenomicsModalContent/WiredNewTokenomicsModal"
import { NewTokenomics } from "./NewTokenomics"

export interface WiredNewTokenomicsProps {
  className?: string
}

export const WiredNewTokenomics: FC<WiredNewTokenomicsProps> = props => {
  const modalRef = useRef<WiredNewTokenomicsModalRefValue>(null)

  return (
    <>
      <NewTokenomics
        className={props.className}
        onClick={() => modalRef.current?.show()}
      />
      <WiredNewTokenomicsModal ref={modalRef} />
    </>
  )
}
