import { FC } from "react"
import { useAPowerRatio } from "../../../components/APowerTip/APowerTip"
import { MayDollarSignTokenName } from "../../../components/MayDollarSignTokenName"
import { TokenName } from "../../../components/TokenName"
import { useCurrencyStore } from "../../../stores/currencyStore/useCurrencyStore"
import { Currency } from "../../../utils/alexjs/Currency"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { readResource } from "../../../utils/SuspenseResource"
import { TokenInfoPresets } from "../../../utils/TokenInfoPresets/TokenInfoPresets"
import { CopywritingProviderValue } from "../../Stake/components/CopywritingProvider"

export class FarmingCopywriting implements CopywritingProviderValue {
  private cycleTip =
    "Farming locks up the set amount in the contract for the selected number of reward cycles."

  aPowerAlertContent = (<APowerAlertContent />)

  myStaking = {
    panelTitle: "My Farming",
    activeStaking: "Active farming LP",
    averageAPRTip:
      "The average APR is calculated based on your Farming cycles.",
    principalClaim: "LP to claim",
    cycleTip: this.cycleTip,
    toBeStaked: {
      finishedStatus: "Farmed",
      currentState: "Farming",
      upcomingStatus: "To be farmed",
    },
  }

  addStaking = {
    panelTitle: "Add LP Staking",
    cycleTip: this.cycleTip,
  }

  principalTokenInfoUpdater = (tokenInfo: TokenInfo): TokenInfo => ({
    ...tokenInfo,
    displayName: "LP",
  })
}

const APowerAlertContent: FC = () => {
  const { displayableRatio } = useAPowerRatio()
  const currency = useCurrencyStore()

  return (
    <>
      The amount of <TokenName token={TokenInfoPresets.APower} /> (IDO access)
      received for yield farming will be {readResource(displayableRatio)}x the
      amount of{" "}
      <MayDollarSignTokenName token={currency.getTokenInfo$(Currency.ALEX)} />{" "}
      rewarded.
    </>
  )
}
