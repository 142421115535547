import { FC } from "react"
import { useMessage } from "../../../components/message/MessageProvider"
import { TokenNameSelect } from "../../../components/TokenInput/TokenNameSelect"
import { alertError } from "../../../utils/error"
import { Result } from "../../../utils/Result"
import {
  safeReadResource,
  suspenseResource,
} from "../../../utils/SuspenseResource"
import { useWrapBridgeStore } from "../store/useWrapBridgeStore"
import { FormErrorType } from "../types/types"
import { WiredChainConnector } from "./WiredChainConnector"
import {
  WrapBridgeNetworkComponent,
  WrapBridgeNetworkSelectComponent,
} from "./WrapBridgePanel/WrapBridgeNetwork/WrapBridgeNetworkComponent"
import {
  FromInput,
  SideFrame,
} from "./WrapBridgePanel/WrapBridgePanel/WrapBridgePanel"

export const WiredFromForm: FC = () => {
  const store = useWrapBridgeStore()
  const message = useMessage()

  const errorType = safeReadResource(
    suspenseResource(
      () => Result.maybeError(store.wrapFormViewModule.formData$)?.type,
    ),
  )

  return (
    <SideFrame
      network={
        store.wrapForm.direction === "wrap" ? (
          <WrapBridgeNetworkSelectComponent
            network={store.wrapFormViewModule.fromNetwork}
            availableNetworks={store.wrapFormViewModule.availableETHNetworks$}
            onChange={network =>
              store.wrapFormViewModule
                .switchToETHNetwork(network)
                .catch(alertError("Switch chain failed", message))
            }
          />
        ) : (
          <WrapBridgeNetworkComponent
            network={store.wrapFormViewModule.fromNetwork}
          />
        )
      }
      address={<WiredChainConnector chain={store.wrapForm.fromChain} />}
    >
      <FromInput
        error={[
          FormErrorType.LessThanMinimizeAmount,
          FormErrorType.InsufficientTokenBalance,
          FormErrorType.UnsupportedSourceToken,
        ].includes(errorType!)}
        disabled={[
          FormErrorType.UnsupportedSourceToken,
          FormErrorType.UnsupportedSourceChain,
        ].includes(errorType!)}
        token={suspenseResource(() => store.wrapFormViewModule.fromToken$)}
        value={store.wrapForm.fromTokenCount.get() ?? null}
        onChange={newV => store.wrapForm.setFromTokenCount(newV)}
        onPressMax={() => store.wrapForm.setMaxFromTokenCount()}
        tokenNameArea={
          <TokenNameSelect
            availableTokens={store.wrapFormViewModule.availableFromTokens$}
            onChange={token => store.wrapFormViewModule.setFromToken(token)}
            selectedToken={store.wrapFormViewModule.fromToken$}
          />
        }
        balance={suspenseResource(
          () => store.wrapForm.fromChainCurrencyBalance$,
        )}
        usdCount={suspenseResource(
          () => store.wrapFormViewModule.fromTokenCountToUSD$,
        )}
      />
    </SideFrame>
  )
}
