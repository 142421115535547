import { FC } from "react"
import {
  CardInset,
  CardInsetDescription,
  CardInsetTitle,
} from "../../../../../components/Card"
import { NoteParagraph } from "../../../../../components/NoteParagraph/NoteParagraph"
import { TokenName } from "../../../../../components/TokenName"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { TokenInfoPresets } from "../../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { HowLotteryWorkLink } from "./HowLotteryWorkLink"
import { LotteryTicketCard } from "./LotteryTicketCard/LotteryTicketCard"
import { LotteryTicket } from "./LotteryTicketCard/types"

export const ActionSectionContent$ClaimForRegistered: FC<{
  idoToken: TokenInfo
  priceToken: TokenInfo
  lotteryTickets: LotteryTicket[]
}> = props => {
  return (
    <CardInset className={"flex flex-col items-center gap-6"}>
      <div className={"flex flex-col items-center gap-2.5"}>
        <CardInsetTitle>Your Lottery Ticket</CardInsetTitle>

        <CardInsetDescription className={"text-center"}>
          The lottery drawing is currently in progress! Thank you for your
          patience.
        </CardInsetDescription>
      </div>

      <ul className={"flex flex-wrap justify-center gap-2.5"}>
        {props.lotteryTickets.map((t, idx) => (
          <li key={idx}>
            <LotteryTicketCard
              idoToken={props.idoToken}
              priceToken={props.priceToken}
              lotteryTicket={t}
            />
          </li>
        ))}
      </ul>

      <NoteParagraph>
        The <TokenName token={props.priceToken} /> submitting with your lottery
        ticket will be refunded if you draw a losing ticket, and will be swapped
        for IDO tokens if you win. The{" "}
        <TokenName token={TokenInfoPresets.APower} /> submitted is burnt either
        win or lose.
      </NoteParagraph>

      <HowLotteryWorkLink />
    </CardInset>
  )
}
