import { FC, Fragment, useState } from "react"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { CardInset } from "../../../../components/Card"
import { CardModalContent } from "../../../../components/CardModal/CardModal"
import { Checkbox } from "../../../../components/Checkbox"
import { MarkdownContent } from "../../../../components/MarkdownContent/MarkdownContent"
import { NavLink } from "../../../../components/NavLink"
import { arrayJoin } from "../../../../utils/arrayHelpers"
import { TosItem } from "../types"

export interface DisclaimerModalContentProps {
  ToSs: [TosItem, TosItem]
  onAccept?: () => void
}

export const DisclaimerModalContent: FC<
  DisclaimerModalContentProps
> = props => {
  const [isRead, setIsRead] = useState(false)

  return (
    <CardModalContent
      layoutClassName={"flex flex-col"}
      gapClassName={"gap-6"}
      title={"Disclaimer"}
      width={640}
      height={520}
    >
      <CardInset
        className={
          "p-4 flex-1 flex flex-col gap-[1em] text-xs leading-4 font-normal text-gray-400 overflow-auto"
        }
      >
        <p className={"uppercase"}>PLEASE READ CAREFULLY:</p>

        {props.ToSs.map(tos => (
          <div key={tos.name}>
            <h3 className={"mb-[1em]"}>
              <span className={"text-yellow-500 font-bold uppercase"}>
                {tos.name}
              </span>{" "}
              (
              <NavLink className={"text-blue-500"} to={tos.link}>
                {tos.link}
              </NavLink>
              )
            </h3>

            <MarkdownContent markdown={tos.briefIntro} />
          </div>
        ))}
      </CardInset>

      <p>
        <label
          className={
            "flex flex-row items-center text-sm leading-5 font-normal text-gray-200"
          }
        >
          <Checkbox
            className={"mr-3"}
            onChange={({ checked }) => setIsRead(checked)}
          />
          <span>
            I accept the{" "}
            {arrayJoin(
              ({ index }) => (
                <Fragment key={`separator-${index}`}> and </Fragment>
              ),
              props.ToSs.map((tos, idx) => (
                <Fragment key={idx}>
                  ({idx + 1}){" "}
                  <NavLink to={tos.link} className={"text-blue-600"}>
                    {tos.name}
                  </NavLink>
                </Fragment>
              )),
            )}
          </span>
        </label>
      </p>

      <GradientFilledButton disabled={!isRead} onClick={props.onAccept}>
        Accept
      </GradientFilledButton>
    </CardModalContent>
  )
}
