import { FC } from "react"
import {
  ChartPanelFrame,
  ChartPlaceholder,
  Legend,
  StyledInfoList,
} from "../../../../../components/ChartPanel"
import {
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../../components/InfoList"
import { NoteParagraph } from "../../../../../components/NoteParagraph/NoteParagraph"
import { PercentNumber } from "../../../../../components/PercentNumber"
import { Spensor } from "../../../../../components/Spensor"
import { TokenCount } from "../../../../../components/TokenCount"
import { TokenCountPair } from "../../../../../components/TokenInput/TokenBlock"
import { TokenName } from "../../../../../components/TokenName"
import {
  readResource,
  SuspenseResource,
} from "../../../../../utils/SuspenseResource"
import { BorrowChart, BorrowChartProps, lineStyles } from "./_/BorrowChart"

export interface BorrowChartPanelPropsChartData {
  data: BorrowChartProps["data"]
}

export interface BorrowChartPanelProps {
  className?: string
  totalCRPVolume: [TokenCountPair, TokenCountPair]
  totalCRPVolumeIntrinsicValue: TokenCountPair
  ltv: SuspenseResource<number>
  apr: SuspenseResource<number>
  chartData?: SuspenseResource<BorrowChartPanelPropsChartData>
}

export const BorrowChartPanel: FC<BorrowChartPanelProps> = props => {
  return (
    <ChartPanelFrame
      className={props.className}
      header={
        <StyledInfoList listItemClassName={"flex-1"}>
          <InfoListItem>
            <InfoListItemTitle>Current CRP Pool Volume</InfoListItemTitle>
            <InfoListItemDetail className={"flex flex-col gap-1"}>
              {props.totalCRPVolume.map((v, idx) => (
                <p key={idx}>
                  <Spensor fallback={"-"}>
                    {() => (
                      <TokenCount
                        token={v.token}
                        count={readResource(v.count)}
                      />
                    )}
                  </Spensor>
                  &nbsp;
                  <TokenName token={v.token} />
                </p>
              ))}
              <p className={"text-base text-gray-500"}>
                ≈&nbsp;
                <Spensor fallback={"-"}>
                  {() => (
                    <TokenCount
                      token={props.totalCRPVolumeIntrinsicValue.token}
                      count={readResource(
                        props.totalCRPVolumeIntrinsicValue.count,
                      )}
                    />
                  )}
                </Spensor>
                &nbsp;
                <TokenName token={props.totalCRPVolumeIntrinsicValue.token} />
              </p>
            </InfoListItemDetail>
          </InfoListItem>

          <InfoListItem>
            <InfoListItemTitle>LTV</InfoListItemTitle>
            <InfoListItemDetail>
              <Spensor fallback={"-"}>
                {() => <PercentNumber number={readResource(props.ltv)} />}
              </Spensor>
            </InfoListItemDetail>
          </InfoListItem>

          <InfoListItem>
            <InfoListItemTitle>APR</InfoListItemTitle>
            <InfoListItemDetail>
              <Spensor fallback={"-"}>
                {() => <PercentNumber number={readResource(props.apr)} />}
              </Spensor>
            </InfoListItemDetail>
          </InfoListItem>
        </StyledInfoList>
      }
    >
      <Spensor fallback={<ChartPlaceholder.Loading />}>
        {() =>
          props.chartData == null ? (
            <ChartPlaceholder.Unavailable />
          ) : (
            <BorrowChart {...readResource(props.chartData)} />
          )
        }
      </Spensor>

      <Legend.Container>
        <Legend.Item>
          <Legend.Circle fill={lineStyles.firstTokenBar.color} />
          <Legend.Label>
            <TokenName token={props.totalCRPVolume[1].token} />
          </Legend.Label>
        </Legend.Item>

        <Legend.Item>
          <Legend.Circle fill={lineStyles.secondTokenBar.color} />
          <Legend.Label>
            <TokenName token={props.totalCRPVolume[0].token} />
          </Legend.Label>
        </Legend.Item>
      </Legend.Container>

      <NoteParagraph>
        The above chart is based on the (UTC 00:00) snapshot.
      </NoteParagraph>
    </ChartPanelFrame>
  )
}
