import { action } from "mobx"
import React, { FC, Suspense } from "react"
import { useParams } from "react-router-dom"
import { CardModalContent } from "../../components/CardModal/CardModal"
import { CollapsableCard } from "../../components/CollapsableCard/CollapsableCard"
import { useConnect } from "../../components/ConnectWallet/ConnectProvider"
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator"
import { Modal } from "../../components/Modal"
import { NavLink } from "../../components/NavLink"
import { GradientSecondaryPageTitle } from "../../components/PageStack/GradientSecondaryPageTitle"
import { PageStackPage } from "../../components/PageStack/PageStackPage"
import { PinnacleLayoutMode } from "../../components/PinnacleLayoutMode"
import { WiredSlippageModal } from "../../components/SlippageModalContent/WiredSlippageSettingsButton/WiredSlippageSettingsButton"
import { Spensor } from "../../components/Spensor"
import { TokenName } from "../../components/TokenName"
import { WiredTransactionStateDialog } from "../../components/TransactionStateDialog/WiredTransactionStateDialog"
import { usePathGenerator } from "../../routes/routes"
import { useChainStore } from "../../stores/chainStore/useChainStore"
import { useCurrencyStore } from "../../stores/currencyStore/useCurrencyStore"
import { Currency } from "../../utils/alexjs/Currency"
import { isYTPToken } from "../../utils/alexjs/currencyHelpers"
import { Result } from "../../utils/Result"
import { suspenseResource } from "../../utils/SuspenseResource"
import WiredLoadingOrConnectWallet from "../Auth/components/WiredLoadingOrConnectWallet"
import { AddBorrowPanel } from "./components/borrowPage/AddBorrowPanel/AddBorrowPanel"
import { BorrowChartPanel } from "./components/borrowPage/BorrowChartPanel/BorrowChartPanel"
import { ConfirmBorrowModalContent } from "./components/borrowPage/ConfirmBorrowModalContent/ConfirmBorrowModalContent"
import { LendStoreProvider, useLendStore } from "./store/useLendStore"
import { WiredMyBorrowsPanel } from "./WiredMyBorrowsPanel"

const WiredAddBorrowPanel: FC = () => {
  const store = useLendStore()
  const currency = useCurrencyStore()
  const chain = useChainStore()
  const { addBorrow } = store
  const [connect] = useConnect()
  const collateralTokenInfo = currency.getTokenInfo$(store.collateralToken)
  const underlyingToken = currency.getTokenInfo$(store.underlyingToken)
  return (
    <>
      <AddBorrowPanel
        borrowToken={underlyingToken}
        collateralToken={collateralTokenInfo}
        borrowTokenCount={addBorrow.uTokenAmount.get() ?? null}
        setBorrowTokenCount={a => addBorrow.uTokenAmount.set(a ?? undefined)}
        availableBorrowTokenCount={suspenseResource(
          () => addBorrow.borrowableBalance$,
        )}
        collateralTokenCount={suspenseResource(
          () => addBorrow.collateralAmount$,
        )}
        collateralTokenBalance={suspenseResource(
          () => addBorrow.collateralBalance$,
        )}
        ltv={suspenseResource(() => addBorrow.ltv$)}
        interestToken={underlyingToken}
        estimateAbsoluteInterest={suspenseResource(
          () => addBorrow.estimatedInterest$,
        )}
        expirationDate={suspenseResource(() =>
          chain.estimatedDateForBlock$(store.currentExpiry$),
        )}
        expirationBlockHeight={suspenseResource(() => store.currentExpiry$)}
        liquidityProviderFee={suspenseResource(
          () => addBorrow.liquidityProviderFee$,
        )}
        crpInfo={[
          {
            token: underlyingToken,
            percentage: suspenseResource(
              () => addBorrow.collateralBreakdown$.underlying,
            ),
          },
          {
            token: collateralTokenInfo,
            percentage: suspenseResource(
              () => addBorrow.collateralBreakdown$.collateral,
            ),
          },
        ]}
        slippage={addBorrow.slippageStore.slippagePercentage}
        error={suspenseResource(() => Result.maybeError(addBorrow.formData$))}
        onClear={() => addBorrow.uTokenAmount.set(undefined)}
        onConnectWallet={() => connect()}
        onClickSlippage={() => addBorrow.slippageStore.showSlippagePopModal()}
        onSubmit={action(() => (addBorrow.showConfirmation = true))}
      />
      <WiredSlippageModal slippagePercent={addBorrow.slippageStore} />
      <Modal
        visible={addBorrow.showConfirmation}
        onClose={action(() => (addBorrow.showConfirmation = false))}
      >
        <Spensor
          fallback={
            <CardModalContent width={480}>
              <LoadingIndicator className="mx-auto m-10" />
            </CardModalContent>
          }
        >
          {() => (
            <ConfirmBorrowModalContent
              collateralAssets={{
                token: collateralTokenInfo,
                count: addBorrow.collateralAmount$,
              }}
              borrowedAssets={{
                token: underlyingToken,
                count: addBorrow.uTokenAmount.read$,
              }}
              ltv={addBorrow.ltv$}
              interestToken={underlyingToken}
              liquidityProviderFee={suspenseResource(
                () => addBorrow.liquidityProviderFee$,
              )}
              estimateAbsoluteInterest={suspenseResource(
                () => addBorrow.estimatedInterest$,
              )}
              expirationDate={suspenseResource(() =>
                chain.estimatedDateForBlock$(store.currentExpiry$),
              )}
              expirationBlockHeight={suspenseResource(
                () => store.currentExpiry$,
              )}
              crpInfo={[
                {
                  token: underlyingToken,
                  percentage: suspenseResource(
                    () => addBorrow.collateralBreakdown$.underlying,
                  ),
                },
                {
                  token: collateralTokenInfo,
                  percentage: suspenseResource(
                    () => addBorrow.collateralBreakdown$.collateral,
                  ),
                },
              ]}
              slippage={addBorrow.slippageStore.slippagePercentage}
              onClose={action(() => (addBorrow.showConfirmation = false))}
              onConfirm={async () => {
                const form = Result.maybeValue(addBorrow.formData$)
                if (form == null) {
                  return
                }
                await addBorrow.addBorrow(form)
              }}
            />
          )}
        </Spensor>
      </Modal>
      <WiredTransactionStateDialog store={addBorrow.txStore} />
    </>
  )
}

const WiredBorrowChartPanel: FC = () => {
  const store = useLendStore()
  const currency = useCurrencyStore()
  return (
    <BorrowChartPanel
      apr={suspenseResource(() => store.borrowAPR$)}
      ltv={suspenseResource(() => store.ltv$)}
      totalCRPVolume={[
        {
          token: currency.getTokenInfo$(store.underlyingToken),
          count: suspenseResource(
            () => store.currentExpiryDetail$?.balanceY ?? 0,
          ),
        },
        {
          token: currency.getTokenInfo$(store.collateralToken),
          count: suspenseResource(
            () => store.currentExpiryDetail$?.balanceX ?? 0,
          ),
        },
      ]}
      totalCRPVolumeIntrinsicValue={{
        token: currency.getTokenInfo$(store.underlyingToken),
        count: suspenseResource(() => {
          if (store.currentExpiryDetail$ == null) {
            return 0
          }
          return (
            store.currentExpiryDetail$.balanceY +
            store.myBorrow.collateralPricedInUnderlying$ *
              store.currentExpiryDetail$.balanceX
          )
        }),
      }}
      chartData={suspenseResource(() => store.borrowChartData.value$)}
    />
  )
}

const PageContent: FC = () => {
  return (
    <PinnacleLayoutMode.Container>
      <PinnacleLayoutMode.Top>
        <Suspense
          fallback={
            <CollapsableCard title="Borrowed">
              <WiredLoadingOrConnectWallet />
            </CollapsableCard>
          }
        >
          <WiredMyBorrowsPanel />
        </Suspense>
      </PinnacleLayoutMode.Top>

      <PinnacleLayoutMode.BottomLeft>
        <Suspense>
          <WiredAddBorrowPanel />
        </Suspense>
      </PinnacleLayoutMode.BottomLeft>

      <PinnacleLayoutMode.BottomRight>
        <WiredBorrowChartPanel />
      </PinnacleLayoutMode.BottomRight>
    </PinnacleLayoutMode.Container>
  )
}

const BorrowPageImpl: FC = () => {
  const currency = useCurrencyStore()
  const store = useLendStore()
  const g = usePathGenerator()

  return (
    <PageStackPage
      title={
        <>
          <GradientSecondaryPageTitle
            gradientStyle={
              "linear-gradient(90.83deg, #FFFFFF 0.71%, #EC4899 99.29%)"
            }
            returnFallbackURL={g.lend()}
          >
            Borrow{" "}
            <TokenName token={currency.getTokenInfo$(store.underlyingToken)} />
          </GradientSecondaryPageTitle>
          <NavLink
            className={"text-blue-600"}
            to={
              "https://medium.com/alexgobtc/introducing-lend-borrow-8457f8064a56#f730"
            }
          >
            How does Borrow work?
          </NavLink>
        </>
      }
    >
      <PageContent />
    </PageStackPage>
  )
}

export const BorrowPage: FC = () => {
  const currency = useParams().token as Currency
  if (!isYTPToken(currency)) {
    throw new Error(`Invalid token ${currency}`)
  }
  return (
    <LendStoreProvider pool={currency}>
      <Suspense>
        <BorrowPageImpl />
      </Suspense>
    </LendStoreProvider>
  )
}
