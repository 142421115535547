import { sum } from "lodash"
import { FC, ReactElement, ReactNode } from "react"
import { Button } from "../../../../../../components/button/Button"
import { CardDivider, CardNested } from "../../../../../../components/Card"
import { PercentNumber } from "../../../../../../components/PercentNumber"
import { TipIcon } from "../../../../../../components/TipIcon/TipIcon"
import { TokenIconProps } from "../../../../../../components/TokenIcon"
import { TokenInfo } from "../../../../../../utils/models/TokenInfo"
import {
  ClaimTokenInfoVerticalList,
  StakingSummaryList,
  StakingSummaryListItem,
  TitleText,
} from "../../../../components/StakingSummaryList"
import { ClaimTokenInfo } from "../../../../types"
import { ReactComponent as HarvestAllIcon } from "./harvestAll.svg"

export const MyStakingSummary: FC<{
  activeFarming: {
    title: ReactNode
    token: TokenInfo
    count: number
  }
  averageAPR: {
    title: ReactNode
    apr: number
    tip?: ReactNode
  }
  rewardClaim: {
    title: ReactNode
    tokens: ClaimTokenInfo[]
    tipArea?: ReactElement
  }
  principalClaim: {
    title: ReactNode
    tokens: ClaimTokenInfo[]
  }
  onHarvestAll?: () => void
}> = props => {
  return (
    <>
      <StakingSummaryList className={"py-4"} gapClassName={"gap-4"}>
        <StakingSummaryListItem
          title={
            <TitleText className={"leading-[16px] sm:leading-[36px]"}>
              {props.activeFarming.title}
            </TitleText>
          }
          detail={
            <ClaimTokenInfoVerticalList
              tokenIconSize={TokenIconProps.defaultSize}
              countTextClassName={
                "text-[24px] sm:text-[30px] leading-[24px] sm:leading-[36px]"
              }
              infos={[props.activeFarming]}
            />
          }
        />

        <StakingSummaryListItem
          title={
            <span className={"flex flex-row items-center gap-2.5"}>
              <TitleText className={"leading-[16px] sm:leading-[36px]"}>
                {props.averageAPR.title}
              </TitleText>

              {props.averageAPR.tip && <TipIcon tip={props.averageAPR.tip} />}
            </span>
          }
          detail={
            <span
              className={
                "text-[24px] sm:text-[30px] leading-[24px] sm:leading-[36px]"
              }
            >
              <PercentNumber number={props.averageAPR.apr} />
            </span>
          }
        />
      </StakingSummaryList>

      <CardNested className={"mt-2.5"}>
        <StakingSummaryList gapClassName={"gap-2.5"}>
          <StakingSummaryListItem
            title={
              <span className={"flex flex-row items-center gap-2.5"}>
                <TitleText className={"leading-[16px] sm:leading-[36px]"}>
                  {props.rewardClaim.title}
                </TitleText>
                {props.rewardClaim.tipArea}
              </span>
            }
            detail={
              <ClaimTokenInfoVerticalList
                countTextClassName={
                  "leading-[24px] sm:leading-[36px] text-[24px]"
                }
                infos={props.rewardClaim.tokens}
              />
            }
          />

          <CardDivider />

          <StakingSummaryListItem
            title={
              <TitleText className={"leading-[16px] sm:leading-[36px]"}>
                {props.principalClaim.title}
              </TitleText>
            }
            detail={
              <ClaimTokenInfoVerticalList
                countTextClassName={"leading-[32px] text-[24px]"}
                infos={props.principalClaim.tokens}
              />
            }
          />
        </StakingSummaryList>

        <Button
          className={
            "w-full mt-6 flex flex-row gap-2.5 items-center justify-center"
          }
          disabled={
            !sum(props.rewardClaim.tokens.map(t => t.count)) &&
            !sum(props.principalClaim.tokens.map(t => t.count))
          }
          onClick={props.onHarvestAll}
        >
          <HarvestAllIcon fill={"currentColor"} />
          Harvest All
        </Button>
      </CardNested>
    </>
  )
}
