import { isEqual, memoize, noop } from "lodash"
import { distinctUntilChanged, Observable, shareReplay, switchMap } from "rxjs"
import { accountWebsocket } from "../websockets"

export type AssetBalance = {
  available: string
  balance: string
  incoming: string
  locked: string
  withdraw: string
}

export type AssetBalances = { [assetId: string]: AssetBalance }

export const getUserBalances = memoize(
  (jwt: string): Observable<AssetBalances> =>
    accountWebsocket.pipe(
      switchMap(
        socket =>
          new Observable<AssetBalances>(sink => {
            socket.emit("watch", { token: jwt })
            socket.onAny((name: string, data: AssetBalances) => {
              if (name === "balance") {
                sink.next(data)
              }
            })
            return noop
          }),
      ),
      shareReplay(1),
      distinctUntilChanged(isEqual),
    ),
)
