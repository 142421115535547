import { FC } from "react"
import { useMessage } from "../../../components/message/MessageProvider"
import { DefaultTokenNameArea } from "../../../components/TokenInput/Block"
import { alertError } from "../../../utils/error"
import { suspenseResource } from "../../../utils/SuspenseResource"
import { useWrapBridgeStore } from "../store/useWrapBridgeStore"
import { WiredChainConnector } from "./WiredChainConnector"
import {
  WrapBridgeNetworkComponent,
  WrapBridgeNetworkSelectComponent,
} from "./WrapBridgePanel/WrapBridgeNetwork/WrapBridgeNetworkComponent"
import {
  SideFrame,
  ToInput,
} from "./WrapBridgePanel/WrapBridgePanel/WrapBridgePanel"

export const WiredToForm: FC = () => {
  const store = useWrapBridgeStore()
  const message = useMessage()

  return (
    <SideFrame
      network={
        store.wrapForm.direction === "wrap" ? (
          <WrapBridgeNetworkComponent
            network={store.wrapFormViewModule.toNetwork}
          />
        ) : (
          <WrapBridgeNetworkSelectComponent
            network={store.wrapFormViewModule.toNetwork}
            availableNetworks={store.wrapFormViewModule.availableETHNetworks$}
            onChange={network =>
              store.wrapFormViewModule
                .switchToETHNetwork(network)
                .catch(alertError("Switch chain failed", message))
            }
          />
        )
      }
      address={<WiredChainConnector chain={store.wrapForm.toChain} />}
    >
      <ToInput
        token={suspenseResource(() => store.wrapFormViewModule.toToken$)}
        value={suspenseResource(() => store.wrapForm.toTokenCount$)}
        tokenNameArea={
          <DefaultTokenNameArea
            token={suspenseResource(() => store.wrapFormViewModule.toToken$)}
          />
        }
        usdCount={suspenseResource(
          () => store.wrapFormViewModule.toTokenCountToUSD$,
        )}
      />
    </SideFrame>
  )
}
