import clsx from "clsx"
import { FC } from "react"
import { PercentNumber } from "../../../../components/PercentNumber"
import { Spensor } from "../../../../components/Spensor"
import { TitledColumnCell } from "../../../../components/TitledColumnCell"
import { TokenCount } from "../../../../components/TokenCount"
import { TokenIcon } from "../../../../components/TokenIcon"
import { readResource } from "../../../../utils/SuspenseResource"
import { ListButton } from "../ListButton"
import {
  getListSectionClassName,
  ListItemContainer,
  ListSection,
} from "../ListContainer"
import { ReactComponent as IntoIcon } from "../MyFarmsPanel/into.svg"
import { TruncateTokenName } from "../TruncateTokenName"
import { RecommendedFarm } from "./AllFarmsPanel"
import styles from "./RecommendedFarmList.module.scss"

export const RecommendedFarmListItem: FC<{
  className?: string
  farm: RecommendedFarm
  onClick?: () => void
}> = props => {
  return (
    <ListItemContainer
      className={clsx(
        "group px-4 py-2 rounded-lg",
        styles.RecommendedFarmListItemBackground,
        props.className,
      )}
    >
      <ListSection
        type={"head"}
        className={"flex flex-row items-center gap-2.5"}
      >
        <TokenIcon token={props.farm.liquidityPoolToken} />

        <div>
          <TitledColumnCell
            title={"Suggest"}
            detail={
              <TruncateTokenName
                className={
                  "text-base leading-6 font-medium text-gray-200 truncate"
                }
                token={props.farm.liquidityPoolToken}
              />
            }
          />
        </div>
      </ListSection>

      <Spensor>
        {() => (
          <TitledColumnCell
            className={getListSectionClassName({})}
            title={"Your Balance"}
            detail={
              <>
                <TokenCount
                  token={props.farm.liquidityPoolToken}
                  count={readResource(props.farm.myBalanceCount)}
                />
                &nbsp;LP
              </>
            }
          />
        )}
      </Spensor>

      <TitledColumnCell
        className={getListSectionClassName({})}
        title={"APR"}
        detail={
          props.farm.apr != null ? (
            <PercentNumber number={props.farm.apr} />
          ) : (
            "--"
          )
        }
      />

      <ListSection />

      <ListSection className={"flex items-center justify-center"}>
        <ListButton className={"invisible group-hover:visible"}>
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="#111827"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M10.5 5.25H6.75V1.5H5.25V5.25H1.5V6.75H5.25V10.5H6.75V6.75H10.5V5.25Z" />
          </svg>
          Stake LP
        </ListButton>
      </ListSection>

      <ListSection type={"tail"} className={"flex items-center justify-end"}>
        <IntoIcon className={"opacity-40"} />
      </ListSection>
    </ListItemContainer>
  )
}
