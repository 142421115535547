import clsx from "clsx"
import { FC, SVGProps } from "react"
import { PlainIconButton } from "../../../../components/button/variants/PlainIconButton"
import { Card, CardTitle } from "../../../../components/Card"
import { FCC } from "../../../../utils/reactHelpers/types"

export const MyLotteryPanelFrame: FCC<{
  className?: string
  onOpenHistory?: () => void
  showHistoryEntry?: boolean
}> = props => {
  return (
    <Card className={clsx("flex flex-col gap-4", props.className)}>
      <div className={"flex gap-2.5 items-center justify-between"}>
        <CardTitle>My Lottery</CardTitle>

        {props.showHistoryEntry && (
          <PlainIconButton
            className={"text-sm"}
            icon={HistoryIcon}
            onClick={props.onOpenHistory}
          >
            History
          </PlainIconButton>
        )}
      </div>

      {props.children}
    </Card>
  )
}

const HistoryIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <svg
    width={16}
    height={17}
    fill="#fff"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M15 8.001a7 7 0 01-14 0h1.167A5.84 5.84 0 008 13.835 5.84 5.84 0 0013.833 8 5.84 5.84 0 008 2.168a5.8 5.8 0 00-4.141 1.737L5.06 5.107 1 5.897l.761-4.091 1.271 1.272A6.963 6.963 0 018 1a7 7 0 017 7zm-7.583-3.5v4.667H11.5V8.001H8.583v-3.5H7.417z" />
  </svg>
)
