import clsx from "clsx"
import { FC, Suspense } from "react"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { CardInsetDivider, CardInsetTitle } from "../../../../components/Card"
import { useConnect } from "../../../../components/ConnectWallet/ConnectProvider"
import { LoadingIndicator } from "../../../../components/LoadingIndicator/LoadingIndicator"
import { NavLink } from "../../../../components/NavLink"
import { NoteParagraph } from "../../../../components/NoteParagraph/NoteParagraph"
import { TipIcon } from "../../../../components/TipIcon/TipIcon"
import { TokenCount } from "../../../../components/TokenCount"
import { TokenName } from "../../../../components/TokenName"
import { usePathGenerator } from "../../../../routes/routes"
import { useAccountStore } from "../../../../stores/accountStore/useAccountStore"
import { useAuthStore } from "../../../../stores/authStore/useAuthStore"
import { Currency } from "../../../../utils/alexjs/Currency"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { plural } from "../../../../utils/stringHelpers"
import { TokenInfoPresets } from "../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { assertNever } from "../../../../utils/types"
import { LaunchingStatus } from "../../store/LaunchPadContractStore"
import { useLaunchPadContractStore } from "../../store/useLaunchPadStores"
import {
  HorizontalInfoListContainer,
  HorizontalInfoListItem,
} from "../InfoList"
import { MyIDOFrame } from "./components"
import { ReactComponent as CongratsIcon } from "./congrats.svg"
import { ReactComponent as FinishedIcon } from "./finished.svg"
import { ReactComponent as OopsIcon } from "./oppps.svg"

export const WiredUserIDOStatusSection: FC<{ className?: string }> = (props: {
  className?: string
}) => {
  const authStore = useAuthStore()
  const [connect] = useConnect()

  return (
    <MyIDOFrame className={props.className}>
      <Suspense fallback={<LoadingIndicator />}>
        {!authStore.isWalletConnected ? (
          <UserIDOStatusSectionContent$ConnectWallet onConnect={connect} />
        ) : (
          <WiredUserIDOStatusSectionContent />
        )}
      </Suspense>
    </MyIDOFrame>
  )
}

const WiredUserIDOStatusSectionContent: FC = () => {
  const store = useLaunchPadContractStore()
  const authStore = useAuthStore()
  const accountStore = useAccountStore()

  const module = store.userIDOViewModule
  const status = store.tokenProfileViewModule.status

  if (status.type === LaunchingStatus.Upcoming) {
    return (
      <UserIDOStatusSectionContent$Upcoming
        myAPowerCount={accountStore.getBalance$(Currency.APOWER)}
      />
    )
  }

  if (status.type === LaunchingStatus.Registration) {
    if (!module.hasValidated) {
      return (
        <UserIDOStatusSectionContent$OpenPoolNotRegistered
          aPowerBalanceCount={accountStore.getBalance$(Currency.APOWER)}
          priceToken={store.priceTokenInfo$}
        />
      )
    } else {
      return (
        <UserIDOStatusSectionContent$OpenPoolAfterRegistered
          registeredTicketCount={module.registeredTicketCount}
          priceToken={store.priceTokenInfo$}
          lockedPriceTokenCount={module.lockedPriceToken$}
        />
      )
    }
  }

  if (
    status.type === LaunchingStatus.Claim ||
    status.type === LaunchingStatus.Finished
  ) {
    if (
      !module.hasValidated ||
      (status.type === LaunchingStatus.Finished && !status.success)
    ) {
      return <UserIDOStatusSectionContent$DidNotJoin />
    }

    return (
      <UserIDOStatusSectionContent$LotteryFinished
        wonLotteryCount={module.lotteryWon$}
        idoToken={store.tokenInfo$}
        priceToken={store.priceTokenInfo$}
        returnedPriceTokenCount={module.returnedPriceTokenCount$}
        allocatedIDOTokenCount={module.allocatedTokenCount$}
        addressExplorerLink={authStore.addressExplorerLink$}
      />
    )
  }

  assertNever(status.type)
}

export const UserIDOStatusSectionContent$ConnectWallet: FC<{
  onConnect?: () => void
}> = props => {
  return (
    <>
      <CardInsetTitle>Please connect wallet first</CardInsetTitle>
      <GradientFilledButton
        className={"min-w-[7.5rem]"}
        onClick={props.onConnect}
      >
        Connect Wallet
      </GradientFilledButton>
    </>
  )
}

export const UserIDOStatusSectionContent$Upcoming: FC<{
  myAPowerCount: number
}> = props => {
  const g = usePathGenerator()

  return (
    <div className={"flex flex-col gap-6 items-center w-full"}>
      <CardInsetTitle>Validation not Started</CardInsetTitle>

      <HorizontalInfoListContainer>
        <HorizontalInfoListItem
          title={
            <>
              My&nbsp;
              <TokenName token={TokenInfoPresets.APower} />
            </>
          }
          detail={
            <>
              <TokenCount
                token={TokenInfoPresets.APower}
                count={props.myAPowerCount}
              />
            </>
          }
        />
      </HorizontalInfoListContainer>

      <CardInsetDivider />

      <NoteParagraph>
        Go to{" "}
        <NavLink className={"text-blue-600"} to={g.farmList()}>
          Farm
        </NavLink>{" "}
        or{" "}
        <NavLink className={"text-blue-600"} to={g.stake()}>
          Stake
        </NavLink>{" "}
        to get more <TokenName token={TokenInfoPresets.APower} />.{" "}
        <NavLink
          className={"text-blue-600"}
          to={
            "https://medium.com/alexgobtc/what-is-alex-staking-power-and-how-do-i-use-it-1b3de3797fa2"
          }
        >
          Learn more
        </NavLink>
      </NoteParagraph>
    </div>
  )
}

export const UserIDOStatusSectionContent$DidNotJoin: FC = () => {
  return (
    <>
      <FinishedIcon />
      <CardInsetTitle>Validation Finished</CardInsetTitle>
    </>
  )
}

export const UserIDOStatusSectionContent$OpenPoolNotRegistered: FC<{
  aPowerBalanceCount: number
  priceToken: TokenInfo
}> = props => {
  return (
    <>
      <div className={"w-full"}>
        <CardInsetTitle className={"mb-2"}>Not Validated</CardInsetTitle>

        <p
          className={
            "text-sm leading-5 font-normal text-gray-500 flex items-center justify-center"
          }
        >
          My&nbsp;
          <TokenName token={TokenInfoPresets.APower} />
          &nbsp;Balance:&nbsp;
          <TokenCount
            token={TokenInfoPresets.IDOLotteryTicket}
            count={props.aPowerBalanceCount}
          />
          <TipIcon
            className={"ml-2.5"}
            tip={
              <>
                This is the total balance of{" "}
                <TokenName token={TokenInfoPresets.APower} /> in your wallet.
                You can use <TokenName token={TokenInfoPresets.APower} /> to
                participate IDO.
              </>
            }
          />
        </p>
      </div>

      <OpenPoolLotteryInfoGroup
        lotteryCount={0}
        lockedToken={{
          token: props.priceToken,
          count: 0,
        }}
      />
    </>
  )
}

export const UserIDOStatusSectionContent$OpenPoolAfterRegistered: FC<{
  registeredTicketCount: number
  priceToken: TokenInfo
  lockedPriceTokenCount: number
}> = props => {
  return (
    <>
      <div className={"w-full"}>
        <CardInsetTitle className={"mb-2"}>Validated Tickets</CardInsetTitle>
      </div>

      <OpenPoolLotteryInfoGroup
        lotteryCount={props.registeredTicketCount}
        lockedToken={{
          token: props.priceToken,
          count: props.lockedPriceTokenCount,
        }}
      />
    </>
  )
}

export const UserIDOStatusSectionContent$LotteryFinished: FC<{
  idoToken: TokenInfo
  priceToken: TokenInfo
  wonLotteryCount: number
  allocatedIDOTokenCount: number
  returnedPriceTokenCount: number
  addressExplorerLink: string
}> = props => {
  return (
    <>
      <div className={"flex flex-col items-center gap-4"}>
        {props.allocatedIDOTokenCount > 0 ? (
          <>
            <CongratsIcon />
            <CardInsetTitle>Congratulations!</CardInsetTitle>
          </>
        ) : (
          <>
            <OopsIcon />
            <CardInsetTitle>Oops...maybe next time</CardInsetTitle>
          </>
        )}
      </div>

      <CardInsetDivider />

      <p
        className={clsx(
          props.allocatedIDOTokenCount > 0 ? "text-yellow-200" : "",
          "flex flex-row items-center",
        )}
      >
        <span className={"text-5xl leading-none font-normal"}>
          <TokenCount token={props.idoToken} count={props.wonLotteryCount} />
          &nbsp;
        </span>
        <span
          className={clsx(
            "text-lg leading-7 font-normal",
            props.allocatedIDOTokenCount > 0 ? "" : "text-gray-500",
          )}
        >
          {plural(props.wonLotteryCount, {
            one: "Lottery",
            many: "Lotteries",
          })}{" "}
          Won
        </span>
      </p>

      <HorizontalInfoListContainer>
        <HorizontalInfoListItem
          detailTextClassName={"text-lg leading-7 font-normal"}
          title={"Total Allocation"}
          detail={
            <>
              <TokenCount
                token={props.idoToken}
                count={props.allocatedIDOTokenCount}
              />
              &nbsp;
              <span className={"text-xs leading-4 font-normal"}>
                <TokenName token={props.idoToken} />
              </span>
            </>
          }
        />
        <HorizontalInfoListItem
          detailTextClassName={"text-lg leading-7 font-normal"}
          title={"Returned"}
          detail={
            <>
              <TokenCount
                token={props.priceToken}
                count={props.returnedPriceTokenCount}
              />
              &nbsp;
              <span className={"text-xs leading-4 font-normal"}>
                <TokenName token={props.priceToken} />
              </span>
            </>
          }
        />
      </HorizontalInfoListContainer>
    </>
  )
}

interface OpenPoolLotteryInfoGroupProps {
  className?: string
  lotteryCount: number
  lockedToken: {
    token: TokenInfo
    count: number
  }
}

const OpenPoolLotteryInfoGroup: FC<OpenPoolLotteryInfoGroupProps> = props => {
  return (
    <HorizontalInfoListContainer className={props.className}>
      <HorizontalInfoListItem
        title={`My ${plural(props.lotteryCount, {
          one: "Lottery",
          many: "Lotteries",
        })}`}
        detail={props.lotteryCount}
      />

      <HorizontalInfoListItem
        title={
          <p className={"flex flex-row items-center"}>
            Locked&nbsp;
            <TokenName token={props.lockedToken.token} />
            &nbsp;
            <TipIcon
              className={"inline-block"}
              tip={
                <>
                  The Locked <TokenName token={props.lockedToken.token} /> will
                  automatically swapped when ticket won, or returned to your
                  wallet when lose.
                </>
              }
            />
          </p>
        }
        detail={props.lockedToken.count}
      />
    </HorizontalInfoListContainer>
  )
}
