import clsx from "clsx"
import React, { FC, useId } from "react"
import { Card } from "../../../../components/Card"
import { GradientText } from "../../../../components/GradientText"
import { LoadingIndicator } from "../../../../components/LoadingIndicator/LoadingIndicator"
import {
  Table,
  TableBodyRow,
  TableHeadRow,
  Tbody,
  Thead,
} from "../../../../components/table/StyledTable"
import { TokenCount } from "../../../../components/TokenCount"
import { Truncatable } from "../../../../components/Truncatable"
import { trunc } from "../../../../utils/addressHelpers"
import { LockdropRankingsProps } from "../../store/LockdropDetailStore"

export const LockdropRankings: FC<
  LockdropRankingsProps & { className?: string; loading?: boolean }
> = props => {
  return (
    <Card
      className={clsx(props.className, "max-h-[80vh] flex-1")}
      boxClassName="p-6 flex flex-col gap-y-4"
    >
      <div>
        <GradientText
          className="text-2xl leading-8 font-medium"
          gradientStyle={
            "linear-gradient(90.83deg, #FFFFFF 0.71%, #FBBF24 99.29%)"
          }
        >
          Rankings
        </GradientText>
      </div>
      <div className="overflow-y-auto">
        {props.loading ? (
          <LoadingIndicator className="mx-auto" />
        ) : (
          <Table>
            <Thead>
              <TableHeadRow className="bg-gray-800">
                <Th>#Ranking</Th>
                <Th>Wallet</Th>
                <Th className="hidden sm:table-cell">Volume (atALEX)</Th>
                <Th className="hidden sm:table-cell">Rewards Multiplier</Th>
              </TableHeadRow>
            </Thead>

            <Tbody>
              {props.rankings.map(row => (
                <TableBodyRow key={row.address}>
                  <Td>
                    <Ranking ranking={row.ranking} />
                  </Td>
                  <Td>
                    <Truncatable title={row.address}>
                      {row.name || trunc(row.address)}
                    </Truncatable>
                  </Td>
                  <Td className="hidden sm:table-cell">
                    {props.tokenInfo ? (
                      <TokenCount token={props.tokenInfo} count={row.volume} />
                    ) : (
                      ""
                    )}
                  </Td>
                  <Td className="hidden sm:table-cell">
                    {row.multiplier ? row.multiplier.toFixed(2) + "x" : "-"}
                  </Td>
                </TableBodyRow>
              ))}
            </Tbody>
          </Table>
        )}
      </div>
    </Card>
  )
}

const Th: FC<{ children: React.ReactChild; className?: string }> = ({
  children,
  className,
}) => (
  <th className={clsx(className, "text-xs font-bold text-white py-3 px-2.5")}>
    {children}
  </th>
)

const Td: FC<{ children: React.ReactChild; className?: string }> = ({
  children,
  className,
}) => (
  <td className={clsx(className, "text-xs text-white py-2 px-2.5")}>
    {children}
  </td>
)

const crownLinearGradients = [
  "linear-gradient(321.91deg, #D36500 -8.18%, #FFC42E 35.37%, #FFDF8D 49.26%, #FFFFFF 63.9%)",
  "linear-gradient(318.89deg, #0D467A -7.79%, #9CC4FF 69.68%, #FFFFFF 100%)",
  "linear-gradient(318.89deg, #745437 -7.79%, #AA9664 69.68%, #DBDBDB 100%)",
]

const Ranking: FC<{ ranking: number }> = ({ ranking }) => {
  const linearGradient = crownLinearGradients[ranking - 1]
  return (
    <span className="flex flex-row items-center gap-x-2.5">
      <span
        className="inline-flex items-center justify-center w-6 h-6 rounded-full bg-white/5"
        style={{
          background: linearGradient,
        }}
      >
        {ranking}
      </span>
      {linearGradient && <CrownIcon ranking={ranking} />}
    </span>
  )
}

// TODO: we can generate css linear gradient from svg linearGradient, but maybe not worth it.
const CrownIcon: FC<{ ranking: number }> = ({ ranking }) => {
  const id = useId()
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_20013992_46589)">
        <path
          d="M14.6667 12.6663V14.6663H1.33333V12.6663H14.6667ZM14.6667 2.66634C13.6667 2.66634 12.892 3.78967 13.6013 4.79567C13.958 5.30167 13.872 5.60634 13.6313 5.96167C13.1213 6.71301 12.3833 7.33301 11.3333 7.33301C9.65267 7.33301 8.764 5.94434 8.57933 4.76767C8.51533 4.35834 8.47933 4.05167 8.87 3.67167C9.138 3.41167 9.33333 3.06901 9.33333 2.66634C9.33333 1.93034 8.736 1.33301 8 1.33301C7.264 1.33301 6.66667 1.93034 6.66667 2.66634C6.66667 3.06901 6.862 3.41167 7.13 3.67167C7.52067 4.05167 7.484 4.35834 7.42067 4.76767C7.236 5.94434 6.34733 7.33301 4.66667 7.33301C3.61667 7.33301 2.87867 6.71301 2.36867 5.96101C2.12733 5.60634 2.04133 5.30167 2.39867 4.79567C3.108 3.78967 2.33333 2.66634 1.33333 2.66634C0.597333 2.66634 0 3.26367 0 3.99967C0 4.53101 0.309333 4.99634 0.762667 5.20501C1.31267 5.45834 1.33333 6.08301 1.33333 6.65234V11.333H14.6667V6.65234C14.6667 6.08301 14.6873 5.45767 15.2373 5.20501C15.6907 4.99634 16 4.53101 16 3.99967C16 3.26367 15.4027 2.66634 14.6667 2.66634Z"
          fill={`url(#${id}_${ranking})`}
        />
      </g>
      <defs>
        <linearGradient
          id={`${id}_1`}
          x1="16"
          y1="16.6108"
          x2="8.15176"
          y2="4.5946"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D36500" />
          <stop offset="0.604167" stopColor="#FFC42E" />
          <stop offset="0.796875" stopColor="#FFDF8D" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id={`${id}_2`}
          x1="16"
          y1="15.6108"
          x2="3.1976"
          y2="-1.99252"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0D467A" />
          <stop offset="0.71875" stopColor="#9CC4FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id={`${id}_3`}
          x1="16"
          y1="16.6108"
          x2="3.1976"
          y2="-0.992517"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#745437" />
          <stop offset="0.71875" stopColor="#AA9664" />
          <stop offset="1" stopColor="#DBDBDB" />
        </linearGradient>
        <clipPath id="clip0_20013992_46589">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
