import { gql } from "@urql/core"
import { Observable } from "rxjs"
import {
  FetchAppConfigQuery,
  FetchAppConfigQueryVariables,
} from "../../generated/graphql/graphql.generated"
import { AMMSwapPool } from "../../utils/alexjs/AMMSwapPool"
import {
  FWPToken,
  isFWPToken,
  isYTPToken,
  LiquidityPoolToken,
  SwappableCurrency,
} from "../../utils/alexjs/currencyHelpers"
import { gqlQuery } from "../../utils/graphqlHelpers"
import { fromUrqlSource } from "../../utils/Observable/fromUrqlSource"
import { isNotNull } from "../../utils/utils"

export interface AppConfigs {
  commonSwapCoins: SwappableCurrency[]
  allSwapCoins: SwappableCurrency[]
  farmingPool: FWPToken[]
  pools: LiquidityPoolToken[]
  analyticsTabs: AnalyticsTabs[]
  stakingBanner: {
    title: string
    image: string
    url: string
  }[]
  enableLendFeature: boolean
  enableCoFarmFeature: boolean
  enableBlockIndicator: boolean
  enableOrderBook: boolean
  enableSwapFeature: boolean
  enableLotteryFeature: boolean
  enableLaunchPadFeature: boolean
  enablePoolFeature: boolean
  enableStakeFeature: boolean
  enableFarmFeature: boolean
  enableWrapBridgeFeature: boolean

  stakingCycleLimitBlockHeight: undefined | number
}

export interface AnalyticsTabs {
  urlSlug: string
  title: string
  iframeUrl: string
}

export function getAppConfig(env: "dev" | "prod"): Observable<AppConfigs> {
  return fromUrqlSource(
    gqlQuery<FetchAppConfigQuery, FetchAppConfigQueryVariables>(
      gql`
        query FetchAppConfig($env: String!) {
          appConfigCollection(where: { env: $env }, limit: 1) {
            items {
              env
              commonSwapCoinsCollection {
                items {
                  id
                }
              }
              allSwapCoinsCollection {
                items {
                  id
                }
              }
              poolsCollection {
                items {
                  id
                }
              }
              farmingsCollection {
                items {
                  id
                }
              }
              stakingBannerCollection {
                items {
                  image {
                    url
                  }
                  title
                  link
                }
              }
              enableLendFeature
              enableCoFarmFeature
              enableBlockIndicator
              enableOrderBook
              enableSwapFeature
              enableLotteryFeature
              enableLaunchPadFeature
              enablePoolFeature
              enableStakeFeature
              enableFarmFeature
              enableWrapBridgeFeature
              v1StakingCycleLimitBlockHeight
            }
          }
          analyticsTabsCollection(
            where: { enabled: true }
            order: [sort_DESC]
          ) {
            items {
              urlSlug
              title
              iframeLink
            }
          }
        }
      `,
      {
        env,
      },
    ),
    result => {
      const item = result.data.appConfigCollection?.items[0]
      if (item == null) {
        throw new Error(`No app config found for env: ${env}`)
      }
      return {
        commonSwapCoins: item.commonSwapCoinsCollection!.items.map(
          i => i!.id! as SwappableCurrency,
        ),
        allSwapCoins: item.allSwapCoinsCollection!.items.map(
          i => i!.id! as SwappableCurrency,
        ),
        pools: item
          .poolsCollection!.items.map(i => i!.id!)
          .filter(
            (a): a is LiquidityPoolToken =>
              isFWPToken(a) || isYTPToken(a) || AMMSwapPool.isPoolToken(a),
          ),
        farmingPool: item
          .farmingsCollection!.items.map(i => i!.id!)
          .filter(isFWPToken),
        analyticsTabs:
          result.data.analyticsTabsCollection?.items.filter(isNotNull).map(
            (i): AnalyticsTabs => ({
              title: i.title!,
              iframeUrl: i.iframeLink!,
              urlSlug: i.urlSlug!,
            }),
          ) ?? [],
        stakingBanner:
          item.stakingBannerCollection?.items.map(i => ({
            title: i!.title!,
            image: i!.image!.url!,
            url: i!.link!,
          })) ?? [],
        enableLendFeature: !!item.enableLendFeature,
        enableCoFarmFeature: !!item.enableCoFarmFeature,
        enableBlockIndicator: !!item.enableBlockIndicator,
        enableOrderBook: !!item.enableOrderBook,
        enableSwapFeature: !!item.enableSwapFeature,
        enableLotteryFeature: !!item.enableLotteryFeature,
        enableLaunchPadFeature: !!item.enableLaunchPadFeature,
        enablePoolFeature: !!item.enablePoolFeature,
        enableStakeFeature: !!item.enableStakeFeature,
        enableFarmFeature: !!item.enableFarmFeature,
        enableWrapBridgeFeature: !!item.enableWrapBridgeFeature,
        stakingCycleLimitBlockHeight:
          item.v1StakingCycleLimitBlockHeight ?? undefined,
      }
    },
  )
}
