import { isPromiseLike } from "./promiseHelpers"
import { assertNever } from "./types"

const isWrappedSuspenseResourceSymbol = Symbol("isWrappedSuspenseResource")

type WrappedSuspenseResource<T> = {
  __type: T
  __THIS_OBJECT_MAY_BE_SUSPENSE_RESOURCE__: true
  __SHOULD_ONLY_READ_THIS_OBJECT_BY_READ_RESOURCE_FUNCTION__: true
}

const isWrappedSuspenseResource = <T>(
  res: SuspenseResource<T>,
): res is WrappedSuspenseResource<T> => {
  if (res == null) return false
  return (res as any)[isWrappedSuspenseResourceSymbol]
}

export type SuspenseResource<T> = T | WrappedSuspenseResource<T>

export type UnboxSuspenseResource<T> = T extends SuspenseResource<infer R>
  ? R
  : never
export type UnboxSuspenseResourceCollection<T> = {
  [K in keyof T]: UnboxSuspenseResource<T[K]>
}

export type SuspenseResourceFactory<T> = () => T

export function suspenseResource<T>(
  factory: SuspenseResourceFactory<T>,
): SuspenseResource<T> {
  return {
    [isWrappedSuspenseResourceSymbol]: true,
    factory,
  } as any
}

export function readResource<T>(resource: SuspenseResource<T>): T {
  if (isWrappedSuspenseResource(resource)) {
    return (resource as any).factory()
  }
  return resource
}

export function safeReadResource<T>(
  resource?: SuspenseResource<T>,
): undefined | T {
  if (resource == null) return resource
  const result = readResourceToValueOrPromise(resource)
  return result.type === "value" ? result.value : undefined
}

type ValueResultFromResource<T> = {
  type: "value"
  value: T
}
type PromiseResultFromResource<T> = {
  __internalType?: T
  type: "promise"
  promise: PromiseLike<void>
}
function readResourceToValueOrPromise<T>(
  resource: SuspenseResource<T>,
): ValueResultFromResource<T> | PromiseResultFromResource<T> {
  if (!isWrappedSuspenseResource(resource)) {
    return { type: "value", value: resource }
  }

  let result: undefined | T
  try {
    result = readResource<T>(resource)
  } catch (err) {
    if (isPromiseLike(err)) {
      return { type: "promise", promise: err }
    }
    throw err
  }
  return { type: "value", value: result }
}

export function fromPromise<T>(promise: PromiseLike<T>): SuspenseResource<T> {
  let state: "pending" | "resolved" | "rejected" = "pending"
  let value: T
  let caughtErr: any

  void Promise.resolve(promise)
    .then(val => {
      state = "resolved"
      value = val
    })
    .catch(err => {
      state = "rejected"
      caughtErr = err
    })

  return suspenseResource(() => {
    if (state === "resolved") return value
    if (state === "rejected") throw caughtErr
    if (state === "pending") throw promise
    assertNever(state)
  })
}
