import clsx from "clsx"
import { format } from "date-fns"
import { FC, ReactNode } from "react"
import { LinkedButton } from "../../../../components/button/LinkedButton"
import { RoundedButton } from "../../../../components/button/RoundedButton"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteBorderButton } from "../../../../components/button/variants/WhiteBorderButton"
import { Card } from "../../../../components/Card"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../components/InfoList"
import { NavLink } from "../../../../components/NavLink"
import { PercentNumber } from "../../../../components/PercentNumber"
import { IconTokenCount } from "../../../../components/RichTokenCount"
import { longFormatDuration } from "../../../../components/TimeCountdown/helpers"
import { TimeCountdown } from "../../../../components/TimeCountdown/TimeCountdown"
import { TokenName } from "../../../../components/TokenName"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { withClassName } from "../../../../utils/reactHelpers/withClassName"
import { LaunchingStatus } from "../../store/LaunchPadContractStore"
import { TokenNameCard } from "../TokenNameCard"
import { TokenIDOStatus, TokenMetaInfo } from "../types"
import HotBadgeIcon from "./hotBadge.png"

export type IdoInfo = IdoInfo.Normal | IdoInfo.Upcoming | IdoInfo.Finished
export namespace IdoInfo {
  export type Normal = TiledInfoArea$NormalProps & { type: "normal" }
  export type Upcoming = TiledInfoArea$UpcomingProps & { type: "upcoming" }
  export type Finished = TiledInfoArea$FinishedProps & { type: "finished" }
}

export interface IDOListLargeCardProps extends ActionAreaProps {
  className?: string
  isHotProject?: boolean
  featureImageUrl: string
  idoTokenMeta: TokenMetaInfo
  idoStatus: TokenIDOStatus
  idoInfo?: IdoInfo
}

export const IDOListLargeCard: FC<IDOListLargeCardProps> = props => {
  return (
    <Card
      className={clsx(
        "grid grid-cols-12 grid-rows-1 overflow-hidden",
        props.className,
      )}
      boxClassName={""}
    >
      <Information className={"col-span-12 lg:col-span-5 flex flex-col"}>
        <TokenNameCard meta={props.idoTokenMeta} idoStatus={props.idoStatus} />

        <SummaryPart className={"flex-1 justify-between gap-5"}>
          {props.idoInfo &&
            (props.idoInfo.type === "finished" ? (
              <TiledInfoArea$Finished {...props.idoInfo} />
            ) : props.idoInfo.type === "upcoming" ? (
              <TiledInfoArea$Upcoming {...props.idoInfo} />
            ) : (
              <TiledInfoArea$Normal {...props.idoInfo} />
            ))}

          <ActionArea
            idoStatus={props.idoStatus}
            dashboardPageLink={props.dashboardPageLink}
            detailPageLink={props.detailPageLink}
          />
        </SummaryPart>
      </Information>

      <FeatureImage
        className={"order-first lg:order-none col-span-12 lg:col-span-7"}
        hot={props.isHotProject}
        imageUrl={props.featureImageUrl}
        link={props.detailPageLink ?? undefined}
      />
    </Card>
  )
}

interface TiledInfoArea$NormalProps {
  idoToken: TokenInfo
  priceToken: TokenInfo
  totalRaisePriceTokenCount: number
  pricePerIdoToken: number
}
const TiledInfoArea$Normal: FC<TiledInfoArea$NormalProps> = props => (
  <InfoList
    direction={"row-responsive"}
    listItemDirection={"column"}
    listItemClassName={"flex-1 gap-1"}
  >
    <InfoGroup
      title={<>Total Raise</>}
      detail={
        <IconTokenCount
          iconSize={24}
          token={props.priceToken}
          count={props.totalRaisePriceTokenCount}
        />
      }
    />

    <InfoGroup
      title={
        <>
          Price Per <TokenName token={props.idoToken} />
        </>
      }
      detail={
        <IconTokenCount
          iconSize={24}
          token={props.priceToken}
          count={props.pricePerIdoToken}
        />
      }
    />
  </InfoList>
)

interface TiledInfoArea$UpcomingProps {
  idoToken?: TokenInfo
  priceToken?: TokenInfo
  totalRaisePriceTokenCount?: number
  pricePerIdoToken?: number
  startedAt?: Date
}
const TiledInfoArea$Upcoming: FC<TiledInfoArea$UpcomingProps> = props => (
  <>
    <InfoList
      direction={"row-responsive"}
      listItemDirection={"column"}
      listItemClassName={"flex-1 gap-1"}
    >
      <InfoGroup
        title={<>Total Raise</>}
        detail={
          props.totalRaisePriceTokenCount == null ||
          props.priceToken == null ? (
            "TBD"
          ) : (
            <IconTokenCount
              iconSize={24}
              token={props.priceToken}
              count={props.totalRaisePriceTokenCount}
            />
          )
        }
      />

      <InfoGroup
        title={
          <>
            Price Per{" "}
            {props.idoToken ? <TokenName token={props.idoToken} /> : "Token"}
          </>
        }
        detail={
          props.pricePerIdoToken == null || props.priceToken == null ? (
            "TBD"
          ) : (
            <IconTokenCount
              iconSize={24}
              token={props.priceToken}
              count={props.pricePerIdoToken}
            />
          )
        }
      />
    </InfoList>

    {props.startedAt && (
      <InfoList
        direction={"column"}
        listItemDirection={"column"}
        listItemClassName={"gap-1"}
      >
        <InfoGroup
          title={<>Start in</>}
          detail={
            <TimeCountdown
              className={clsx(
                "justify-center capitalize",
                "sm:text-xl sm:leading-7 sm:font-medium",
                "text-sm leading-5 font-medium",
              )}
              icon={null}
              prefix={<span className={"text-gray-200"}>Est.</span>}
              time={props.startedAt}
              durationFormatter={d =>
                longFormatDuration(d, { delimiter: " : " })
              }
            />
          }
        />
      </InfoList>
    )}
  </>
)

interface TiledInfoArea$FinishedProps {
  idoToken?: TokenInfo
  priceToken?: TokenInfo
  totalRaisePriceTokenCount?: number
  pricePerIdoToken?: number
  filledPercent?: number
  allTimeHighTimes?: number
  participateCount?: number
  endedAt?: Date
}
const TiledInfoArea$Finished: FC<TiledInfoArea$FinishedProps> = props => (
  <InfoList
    direction={"column"}
    listItemDirection={"column"}
    listItemClassName={"flex-1 gap-1"}
  >
    <div className={"w-full flex gap-2.5"}>
      {props.totalRaisePriceTokenCount != null && props.priceToken != null && (
        <InfoGroup
          title={<>Total Raise</>}
          detail={
            <IconTokenCount
              iconSize={24}
              token={props.priceToken}
              count={props.totalRaisePriceTokenCount}
            />
          }
        />
      )}

      {props.pricePerIdoToken != null &&
        props.priceToken != null &&
        props.idoToken != null && (
          <InfoGroup
            title={
              <>
                Price Per <TokenName token={props.idoToken} />
              </>
            }
            detail={
              <IconTokenCount
                iconSize={24}
                token={props.priceToken}
                count={props.pricePerIdoToken}
              />
            }
          />
        )}
    </div>

    <div className={"w-full flex gap-2.5"}>
      {props.filledPercent != null && (
        <InfoGroup
          title={"Filled"}
          detail={<PercentNumber number={props.filledPercent} />}
        />
      )}

      {props.allTimeHighTimes != null && (
        <InfoGroup
          title={"All Time High"}
          detail={`${Number(props.allTimeHighTimes.toFixed(2))}x`}
        />
      )}
    </div>

    <div className={"w-full flex gap-2.5"}>
      {props.participateCount != null && (
        <InfoGroup title={"Participate"} detail={props.participateCount} />
      )}

      {props.endedAt && (
        <InfoGroup
          title={"End Time"}
          detail={format(props.endedAt, "MMM dd, yyyy")}
        />
      )}
    </div>
  </InfoList>
)

interface ActionAreaProps {
  idoStatus: TokenIDOStatus

  /**
   * `undefined` means disable the button, `null` means do not render the button
   */
  detailPageLink: null | undefined | string

  /**
   * `undefined` means disable the button, `null` means do not render the button
   */
  dashboardPageLink: null | undefined | string
}
const ActionArea: FC<ActionAreaProps> = props => (
  <p className={"flex flex-col sm:flex-row gap-3"}>
    {props.detailPageLink !== null && (
      <LinkedButton
        className={"flex-1"}
        link={props.detailPageLink}
        Variant={btnProps => (
          <WrappedRectFilledButton
            Variant={
              props.idoStatus.type === LaunchingStatus.Upcoming
                ? GradientFilledButton
                : WhiteBorderButton
            }
            {...btnProps}
          />
        )}
      >
        Project Details
      </LinkedButton>
    )}

    {props.dashboardPageLink !== null && (
      <LinkedButton
        className={"flex-1"}
        link={props.dashboardPageLink}
        Variant={btnProps => (
          <WrappedRectFilledButton
            Variant={
              props.idoStatus.type === LaunchingStatus.Upcoming
                ? WhiteBorderButton
                : GradientFilledButton
            }
            {...btnProps}
          />
        )}
      >
        IDO Dashboard
      </LinkedButton>
    )}
  </p>
)

const FeatureImage: FC<{
  className?: string
  imageUrl: string
  hot?: boolean
  link?: string
}> = props => {
  const content = (
    <div
      className={clsx(
        "min-h-[160px] aspect-video lg:aspect-auto",
        "bg-no-repeat bg-center bg-cover",
        "flex items-start justify-end",
        props.className,
      )}
      style={{
        backgroundImage: props.imageUrl && `url(${props.imageUrl})`,
      }}
    >
      {props.hot && (
        <img src={HotBadgeIcon} alt="Hot" width={108} height={108} />
      )}
    </div>
  )

  if (props.link) {
    return (
      <NavLink className={"contents"} to={props.link}>
        {content}
      </NavLink>
    )
  } else {
    return content
  }
}

const WrappedRectFilledButton: typeof RoundedButton = props => {
  const extraClassName =
    props.Variant !== WhiteBorderButton
      ? "border-2 border-transparent bg-origin-border"
      : ""

  return (
    <RoundedButton
      {...props}
      className={clsx(extraClassName, "whitespace-nowrap", props.className)}
    />
  )
}

const InfoGroup: FC<{
  className?: string
  title: ReactNode
  detail: ReactNode
}> = props => (
  <InfoListItem className={props.className}>
    <InfoListItemTitle className={"text-gray-400"}>
      {props.title}
    </InfoListItemTitle>
    <InfoListItemDetail className={"font-semibold text-gray-200"}>
      {props.detail}
    </InfoListItemDetail>
  </InfoListItem>
)

const Information = withClassName(
  "p-6 min-h-[400px] flex flex-col gap-6",
  "div",
)

const SummaryPart = withClassName("flex flex-col gap-7.5", "div")
