import { reaction } from "mobx"
import { FC, useEffect, useState } from "react"
import { AlertDialogModalContent } from "../../../components/dialog/AlertDialogModalContent"
import { useDialog } from "../../../components/dialog/useDialog"
import { Modal } from "../../../components/Modal"
import { useAuthStore } from "../../../stores/authStore/useAuthStore"
import { defer } from "../../../utils/promiseHelpers"
import {
  argumentSignature,
  getAuthSignatureFromWallet,
  getTokenFromLocalStorage,
  signAuthInfo,
} from "../store/modules/OrderbookMyInfoModule.service"
import { useOrderbookStore } from "../store/useOrderbookStore"

const WiredLoginModal: FC = () => {
  const dialog = useDialog()
  const [confirm, setConfirm] = useState<null | defer.Deferred<void>>(null)
  const authStore = useAuthStore()
  const store = useOrderbookStore()
  useEffect(() => {
    let dispose: (() => void) | undefined
    void (async () => {
      if (!store.myInfo.hasRegistered$) {
        return
      }
      async function refresh(): Promise<void> {
        const { payload, signature } = await getAuthSignatureFromWallet(
          authStore.stxAddress$,
        )
        const result = await signAuthInfo(payload, signature)
        store.myInfo.saveAuthJWT(result)
      }

      if (argumentSignature) {
        await refresh()
        return
      }
      const token = await getTokenFromLocalStorage()
      if (token != null) {
        store.myInfo.saveAuthJWT(token)
      }
      dispose = reaction(
        () => store.myInfo.jwtTokenExpired,
        async expired => {
          if (!expired) {
            return
          }
          const deferred = defer()
          setConfirm(deferred)
          await deferred.promise
          setConfirm(null)
          await refresh()
        },
        {
          fireImmediately: true,
        },
      )
    })()
    return () => {
      dispose?.()
    }
  }, [authStore.stxAddress$, dialog, store.myInfo.hasRegistered$, store])
  if (confirm == null) {
    return null
  }
  return (
    <Modal visible={true}>
      <AlertDialogModalContent
        message="Please sign with your wallet to verify your ownership"
        onConfirm={() => confirm?.resolve()}
        confirmText="Sign with Wallet"
        titleText="Sign in required"
      />
    </Modal>
  )
}

export default WiredLoginModal
