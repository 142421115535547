import clsx from "clsx"
import { FC } from "react"
import { CollapsableCard } from "../../../components/CollapsableCard/CollapsableCard"
import { LoadingIndicator } from "../../../components/LoadingIndicator/LoadingIndicator"
import { NavLink } from "../../../components/NavLink"
import { PercentNumber } from "../../../components/PercentNumber"
import { Spensor } from "../../../components/Spensor"
import { TipIcon } from "../../../components/TipIcon/TipIcon"
import { TokenIcon } from "../../../components/TokenIcon"
import { usePathGenerator } from "../../../routes/routes"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { withClassName } from "../../../utils/reactHelpers/withClassName"
import { readResource, SuspenseResource } from "../../../utils/SuspenseResource"
import { LiquidityNumber } from "../../Farm/components/LiquidityNumber"
import { ListButton } from "../../Farm/components/ListButton"
import {
  ListItemContainer,
  ListSection,
} from "../../Farm/components/ListContainer"
import { ReactComponent as IntoIcon } from "../../Farm/components/MyFarmsPanel/into.svg"
import { TruncateTokenName } from "../../Farm/components/TruncateTokenName"
import { LiquidityInfo } from "../store/detail/PoolDetailStore.services"

export const LiquidityTipIcon: FC = () => (
  <TipIcon tip="By adding liquidity you'll earn fees from all trades on this pair proportional to your share of the pool. Fees are added to the pool, accrue in real time and can be claimed by withdrawing your liquidity." />
)

export const AllPoolListRowHeader: FC = () => {
  return (
    <ListItemContainer
      className={
        "p-4 border-b border-gray-500/30 text-sm leading-5 font-normal text-gray-500"
      }
    >
      <HeaderListSection type={"head"}>Trading Pair</HeaderListSection>
      <HeaderListSection>Liquidity</HeaderListSection>
      <HeaderListSection>Volume (24h)</HeaderListSection>
      <HeaderListSection>Volume (7d)</HeaderListSection>
      <HeaderListSection>
        Fee Rebate <LiquidityTipIcon />
      </HeaderListSection>
      <HeaderListSection />
      <HeaderListSection type={"tail"} />
    </ListItemContainer>
  )
}

const HeaderListSection = withClassName(
  "flex flex-row items-center gap-2.5",
  ListSection,
)

export interface AllPoolListInfo extends LiquidityInfo {
  poolTokenInfo: TokenInfo
}

export const AllPoolListRow: FC<{
  className?: string
  pool: AllPoolListInfo
}> = props => {
  return (
    <ListItemContainer
      className={clsx(
        "group p-4 border-b border-gray-500/30 text-gray-200 hover:bg-black/10 active:bg-black/20",
        props.className,
      )}
    >
      <ListSection
        type={"head"}
        className={"flex flex-row items-center gap-2.5"}
      >
        <TokenIcon token={props.pool.poolTokenInfo} />

        <TruncateTokenName
          className={"text-sm leading-5 font-medium truncate"}
          token={props.pool.poolTokenInfo}
        />
      </ListSection>

      <ListSection className={"uppercase"}>
        $&nbsp;
        {props.pool.liquidity != null ? (
          <LiquidityNumber count={props.pool.liquidity} />
        ) : (
          "--"
        )}
      </ListSection>

      <ListSection className={"uppercase"}>
        $&nbsp;
        {props.pool.volume24h != null ? (
          <LiquidityNumber count={props.pool.volume24h} />
        ) : (
          "--"
        )}
      </ListSection>

      <ListSection className={"uppercase"}>
        $&nbsp;
        {props.pool.volume7d != null ? (
          <LiquidityNumber count={props.pool.volume7d} />
        ) : (
          "--"
        )}
      </ListSection>

      <ListSection>
        {props.pool.apr != null ? (
          <PercentNumber number={props.pool.apr} />
        ) : (
          "--"
        )}
      </ListSection>

      <ListSection className={"flex items-center justify-center"}>
        <ListButton className={"invisible group-hover:visible"}>
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="#111827"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M10.5 5.25H6.75V1.5H5.25V5.25H1.5V6.75H5.25V10.5H6.75V6.75H10.5V5.25Z" />
          </svg>
          Liquidity
        </ListButton>
      </ListSection>

      <ListSection type={"tail"} className={"flex items-center justify-end"}>
        <IntoIcon className={"opacity-40"} />
      </ListSection>
    </ListItemContainer>
  )
}

export const AllPoolsSection: FC<{
  pools: SuspenseResource<AllPoolListInfo[]>
}> = props => {
  const gen = usePathGenerator()
  return (
    <CollapsableCard title={"All Pools"}>
      <Spensor fallback={<LoadingIndicator className={"m-auto"} />}>
        {() => (
          <div className="w-full overflow-x-auto">
            <div className={"min-w-[900px]"}>
              <AllPoolListRowHeader />
              {readResource(props.pools).map(pool => (
                <NavLink
                  key={pool.poolTokenInfo.displayName}
                  to={gen.poolDetail(pool.poolTokenInfo)}
                >
                  <AllPoolListRow className="w-full" pool={pool} />
                </NavLink>
              ))}
            </div>
          </div>
        )}
      </Spensor>
    </CollapsableCard>
  )
}
