export type RoutePath = typeof RoutePath[keyof typeof RoutePath]

const FarmPrefix = "farm"
const LaunchpadPrefix = "launchpad"
const LotteryPrefix = "lottery"
const PoolPrefix = "pool"
const AnalyticsPrefix = "analytics"
const LockdropPrefix = "lockdrop"
const LendPrefix = "lend"
const OrderbookPrefix = "orderbook"

export namespace RoutePath {
  export const Faucet = "faucet"
  export const Stake = "stake"
  export const DepositBorrow = "deposit-borrow"
  export const Swap = "swap"
  export const Liquidity = "liquidity"
  export const Margin = "margin"
  export const Notify = "notify"

  export const Launchpad = `${LaunchpadPrefix}` as const
  export const LaunchpadDashboard = `${LaunchpadPrefix}/:urlSlug/` as const
  export const LaunchpadDetail = `${LaunchpadPrefix}/:urlSlug/details` as const
  export const LaunchpadValidateList =
    `${LaunchpadPrefix}/:urlSlug/validate-info` as const
  export const LaunchpadLotteryList =
    `${LaunchpadPrefix}/:urlSlug/lottery-info` as const

  export const Lottery = `${LotteryPrefix}` as const

  export const Pool = `${PoolPrefix}` as const
  export const PoolDetail = `${PoolPrefix}/:token` as const

  export const Farm = `${FarmPrefix}` as const
  export const FarmDetail = `${FarmPrefix}/:token` as const
  export const CoFarmDetail = `${FarmPrefix}/:token/co-farm` as const

  export const Admin = `_admin` as const
  export const Sandbox = `_sandbox` as const
  export const TransactionDetail = `_tx/:txId` as const

  export const TokenList = "token-list" as const

  export const Analytics = `${AnalyticsPrefix}` as const
  export const AnalyticsDetail = `${AnalyticsPrefix}/:urlSlug` as const

  export const LockdropDetail = `${LockdropPrefix}/:urlSlug` as const

  export const CRPSimulator = "crp-simulator" as const

  export const Lend = `${LendPrefix}` as const
  export const LendDetailDeposit = `${LendPrefix}/:token/deposit` as const
  export const LendDetailBorrow = `${LendPrefix}/:token/borrow` as const

  export const Orderbook = OrderbookPrefix
  export const OrderbookDetail = `${OrderbookPrefix}/:market` as const

  export const WrapBridge = "bridge" as const
}

export const RoutePathDefault = RoutePath.Swap
