import { FC } from "react"
import { GradientFilledButton } from "../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteFilledButton } from "../../../components/button/variants/WhiteFilledButton"
import { CardModalContent } from "../../../components/CardModal/CardModal"
import { NavLink } from "../../../components/NavLink"
import { NoteParagraph } from "../../../components/NoteParagraph/NoteParagraph"
import { BlockTopLine } from "../../../components/TokenInput/Block"
import { TokenBlock } from "../../../components/TokenInput/TokenBlock"
import { TokenInfo } from "../../../utils/models/TokenInfo"

export interface ConfirmClaimCoFarmModalContentProps {
  claimPrincipalToken: TokenInfo
  claimPrincipalAmount: number
  onConfirm?: () => void
  onClose?: () => void
}

export const ConfirmClaimCoFarmModalContent: FC<
  ConfirmClaimCoFarmModalContentProps
> = props => (
  <CardModalContent
    onClose={props.onClose}
    title="Confirm Co-Farm LP"
    className="flex flex-col gap-4"
    width={480}
  >
    <TokenBlock
      topArea={
        <BlockTopLine className="flex flex-row gap-x-2.5">
          <span>Eligible STX to claim</span>
        </BlockTopLine>
      }
      tokens={[
        {
          token: props.claimPrincipalToken,
          count: props.claimPrincipalAmount,
        },
      ]}
    />
    <div className="flex flex-col gap-1">
      <NoteParagraph textSizingClassName={"text-sm"}>
        You will receive the amount of STX you committed to Co-Farm{" "}
      </NoteParagraph>
      <NoteParagraph textSizingClassName={"text-sm"}>
        ALEX rewards will automatically be sent to your wallet address after
        each cycle.{" "}
        <NavLink
          className="text-blue-600"
          to="https://medium.com/alexgobtc/co-farming-with-alex-571000bc6d5a"
        >
          more rules {">"}
        </NavLink>
      </NoteParagraph>
    </div>
    <div className={"flex flex-row gap-2.5 pt-4"}>
      <WhiteFilledButton className={"flex-1"} onClick={props.onClose}>
        Cancel
      </WhiteFilledButton>

      <GradientFilledButton className={"flex-1"} onClick={props.onConfirm}>
        Confirm
      </GradientFilledButton>
    </div>
  </CardModalContent>
)
