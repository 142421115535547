import { action, makeObservable, observable } from "mobx"
import type { TransactionStateForStacks } from "../../components/TransactionStateDialog/TransactionStateDialog"
import { EXPLORER_TX_URL, TG_SUB_URL } from "../../config"
import { CancelError } from "../../utils/error"

export class ConfirmTransactionStore {
  constructor() {
    makeObservable(this)
  }

  @observable running?: TransactionStateForStacks

  @action closeRunning(): void {
    this.running = undefined
  }

  @action startRunning(msg?: string): void {
    this.running = {
      type: "running",
      msg,
    }
  }

  @action successRunning(transactionId: string): void {
    this.running = {
      type: "success",
      explorerLink: EXPLORER_TX_URL(transactionId),
      telegramSubscribeLink: TG_SUB_URL(transactionId),
    }
  }

  @action errorRunning(e: Error): void {
    if (e instanceof CancelError) {
      this.running = undefined
    } else {
      this.running = { type: "error", error: (e as Error).message }
    }
  }

  async run(fn: () => Promise<{ txId: string }>): Promise<void> {
    try {
      this.startRunning()
      const { txId } = await fn()
      this.successRunning(txId)
    } catch (e) {
      this.errorRunning(e as Error)
    }
  }
}
