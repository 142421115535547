import { FC } from "react"
import { useParams } from "react-router-dom"
import { PageStackPage } from "../../components/PageStack/PageStackPage"
import { WiredLockdropBanner } from "./components/WiredBanner"
import { WiredLockdropDetails } from "./components/WiredDetails"
import { WiredLockdropMyStatus } from "./components/WiredMyStatus"
import { WiredLockdropRankings } from "./components/WiredRankings"
import { LockdropDetailStoreProvider } from "./store/useLockdropDetailStore"

const PageContent: FC = () => {
  return (
    <section className="flex flex-col gap-8">
      <WiredLockdropBanner />
      <div className="flex flex-col lg:flex-row gap-8 flex-wrap">
        <WiredLockdropMyStatus />
        <WiredLockdropRankings className="grow" />
      </div>
      <WiredLockdropDetails />
    </section>
  )
}

const LockdropDetailPage: FC = () => {
  const urlSlug = useParams<"urlSlug">().urlSlug!
  return (
    <LockdropDetailStoreProvider urlSlug={urlSlug}>
      <PageStackPage>
        <PageContent />
      </PageStackPage>
    </LockdropDetailStoreProvider>
  )
}

export default LockdropDetailPage
