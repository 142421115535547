import { action } from "mobx"
import React, { FC } from "react"
import { Modal } from "../../../components/Modal"
import {
  SelectTokenModalContent,
  TokenCandidate,
} from "../../../components/SelectTokenModalContent/SelectTokenModalContent"
import { Spensor } from "../../../components/Spensor"
import { WiredTransactionStateDialog } from "../../../components/TransactionStateDialog/WiredTransactionStateDialog"
import { usePathGenerator } from "../../../routes/routes"
import { Result } from "../../../utils/Result"
import { suspenseResource } from "../../../utils/SuspenseResource"
import { TokenInfoPresets } from "../../../utils/TokenInfoPresets/TokenInfoPresets"
import { DepositConfirmModalContent } from "../components/DepositConfirmModalContent"
import { DepositModalContent } from "../components/DepositModalContent/DepositModalContent"
import {
  allStxDxAssets,
  StxDxAsset,
} from "../store/OrderbookStore.service/OrderbookStore.service"
import { useOrderbookStore } from "../store/useOrderbookStore"

export const WiredDepositDialog: FC = () => {
  const store = useOrderbookStore()
  const onClose = action(() => (store.deposit.depositing = false))
  const onCloseConfirm = action(() => (store.deposit.confirming = false))
  const { currency, account } = store

  return (
    <>
      <Modal
        visible={store.deposit.depositing && !store.deposit.confirming}
        onClose={onClose}
      >
        <Spensor>
          {() => (
            <DepositModalContent
              maxSTXWarning={suspenseResource(
                () => store.deposit.maxSTXWarning,
              )}
              onSelectCurrency={action(
                from =>
                  (store.deposit.selectingFromToken =
                    TokenInfoPresets.toCurrency(from) as StxDxAsset),
              )}
              onClear={() => store.deposit.reset()}
              tokens={store.deposit.tokens.map(token => ({
                token: currency.getTokenInfo$(token.token),
                onPressMax: () =>
                  token.amount.set(account.getBalance$(token.token)),
                tokenCount: suspenseResource(() => token.amount.get() ?? null),
                isError: suspenseResource(
                  () => token.amount.read$ > account.getBalance$(token.token),
                ),
                onDelete: store.deposit.canDelete
                  ? () => store.deposit.deleteToken(token.token)
                  : undefined,
                onTokenCountChange: newCount =>
                  token.amount.set(newCount ?? undefined),
                tokenBalanceCount: suspenseResource(() =>
                  account.getBalance$(token.token),
                ),
              }))}
              onAdd={
                store.deposit.canAddMoreToken
                  ? () => store.deposit.addToken()
                  : undefined
              }
              onClose={onClose}
              onSubmit={action(() => (store.deposit.confirming = true))}
              error={suspenseResource(() =>
                Result.maybeError(store.deposit.formData$),
              )}
            />
          )}
        </Spensor>
      </Modal>
      <WiredSelectTokenModal />
      <Modal visible={store.deposit.confirming} onClose={onCloseConfirm}>
        <Spensor>
          {() => (
            <DepositConfirmModalContent
              onCancel={onCloseConfirm}
              onClose={onCloseConfirm}
              onConfirm={async () => {
                const form = Result.maybeValue(store.deposit.formData$)
                if (form != null) {
                  await store.deposit.deposit(form)
                }
              }}
              tokens={store.deposit.tokens.map(token => ({
                token: store.currency.getTokenInfo$(token.token),
                count: token.amount.read$,
              }))}
            />
          )}
        </Spensor>
      </Modal>
      <WiredTransactionStateDialog store={store.deposit.txStore} />
    </>
  )
}

const WiredSelectTokenModal: FC = () => {
  const g = usePathGenerator()
  const store = useOrderbookStore()
  const { currency, account } = store

  const existingOnes = store.deposit.tokens.map(t => t.token)

  const commonTokens = allStxDxAssets.map((c): TokenCandidate => {
    return {
      disabled: existingOnes.includes(c),
      tokenInfo: currency.getTokenInfo$(c),
      balance: suspenseResource(() => account.getBalance$(c)),
    }
  })

  const allTokens = allStxDxAssets.map((c): TokenCandidate => {
    return {
      disabled: existingOnes.includes(c),
      tokenInfo: currency.getTokenInfo$(c),
      balance: suspenseResource(() => account.getBalance$(c)),
    }
  })

  return (
    <Modal
      visible={store.deposit.selectingFromToken != null}
      onClose={action(() => store.deposit.selectingFromToken == null)}
    >
      <SelectTokenModalContent
        commonBasesTokens={commonTokens}
        allTokens={allTokens}
        tokenListLink={g.tokenList()}
        onSelect={action(currency => {
          const fromToken = store.deposit.selectingFromToken
          if (fromToken == null) return
          const pair = store.deposit.tokens.find(a => a.token === fromToken)
          if (pair == null) return
          pair.token = TokenInfoPresets.toCurrency(currency) as StxDxAsset
          store.deposit.selectingFromToken = undefined
        })}
        onClose={action(() => (store.deposit.selectingFromToken = undefined))}
      />
    </Modal>
  )
}
