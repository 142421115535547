import clsx from "clsx"
import { values } from "lodash"
import { defineMessage, MessageDescriptor, useIntl } from "react-intl"
import { connectWallet$t } from "../../../../commonIntlMessages"
import { HeadlessButton } from "../../../../components/button/HeadlessButton"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import {
  NoteParagraph,
  WarnIcon,
} from "../../../../components/NoteParagraph/NoteParagraph"
import { TextTokenCount } from "../../../../components/RichTokenCount"
import { SegmentControl } from "../../../../components/SegmentControl"
import { TabBar } from "../../../../components/TabBar"
import { oneOf } from "../../../../utils/arrayHelpers"
import { FCC } from "../../../../utils/reactHelpers/types"
import {
  readResource,
  safeReadResource,
  SuspenseResource,
} from "../../../../utils/SuspenseResource"
import { normal } from "../buttonPresets"
import {
  OrderDirection,
  StxDxOrderType,
  TradingFormError,
  TradingFormErrorType,
  TradingFormWarningType,
} from "../types"
import { CompactCard } from "../wrappedCommonComponents/CompactCard"
import { CompactCardPlate } from "../wrappedCommonComponents/CompactCardPlate"
import { GreenFilledButton } from "../wrappedCommonComponents/GreenFilledButton"
import { PinkFilledButton } from "../wrappedCommonComponents/PinkFilledButton"
import { RectButton } from "../wrappedCommonComponents/RectButton"
import { orderDirection$t, orderType$t } from "../_/commonIntlMessages"
import { TipIcon } from "../../../../components/TipIcon/TipIcon"
import { PercentNumber } from "../../../../components/PercentNumber"
import { ExclamationOutlinedIcon } from "../../../../components/TipIcon/ExclamationOutlinedIcon"
import { Spensor } from "../../../../components/Spensor"

export interface CreateOrderFrameProps {
  className?: string

  orderDirection: OrderDirection
  onChangeOrderDirection?: (newMode: OrderDirection) => void

  orderType: StxDxOrderType
  onChangeOrderType: (newType: StxDxOrderType) => void

  feeRates: SuspenseResource<{
    maker: number
    taker: number
  }>

  formError?: SuspenseResource<TradingFormError | undefined>
  onConnectWallet: () => void
  onDeposit?: () => void
  onReplacePrice?: () => void
  onSubmit: () => void
}

export const CreateOrderFrame: FCC<CreateOrderFrameProps> = props => {
  const intl = useIntl()
  const { $t } = intl

  const error = safeReadResource(props.formError)
  const formErrorContent = useFormErrorContent({
    formError: error,
    onDeposit: props.onDeposit,
    onReplacePrice: props.onReplacePrice,
  })

  return (
    <CompactCard className={clsx("flex flex-col gap-4", props.className)}>
      <SegmentControl
        className={"w-full"}
        boxClassName={"p-1"}
        segmentBoxClassName={"flex-1 min-h-[32px]"}
        segmentTextClassName={"text-sm leading-5 font-medium capitalize"}
        controls={[
          {
            title: $t(orderDirection$t.buy),
            isActive: props.orderDirection === "buy",
            onClick: () => props.onChangeOrderDirection?.("buy"),
          },
          {
            title: $t(orderDirection$t.sell),
            isActive: props.orderDirection === "sell",
            onClick: () => props.onChangeOrderDirection?.("sell"),
          },
        ]}
      />

      <TabBar
        className={"mt-2.5 mb-2"}
        tabClassName={"px-4 pb-3 text-sm leading-5 font-semibold"}
        tabs={[
          { label: $t(orderType$t.limit), value: StxDxOrderType.Limit },
          { label: $t(orderType$t.market), value: StxDxOrderType.Market },
          { label: $t(orderType$t.stopLimit), value: StxDxOrderType.StopLimit },
        ]}
        selectedTab={{
          tabValue: props.orderType,
          onChange: tab => props.onChangeOrderType?.(tab.value),
        }}
      >
        <div className={"flex flex-col gap-2.5"}>{props.children}</div>
      </TabBar>

      {formErrorContent != null && (
        <CompactCardPlate>
          <NoteParagraph className={"text-red-500"} icon={<WarnIcon />}>
            {formErrorContent}
          </NoteParagraph>
        </CompactCardPlate>
      )}

      {error?.type === TradingFormErrorType.ConnectWalletRequired ? (
        <RectButton
          Variant={GradientFilledButton}
          {...normal}
          onClick={props.onConnectWallet}
        >
          {$t(connectWallet$t)}
        </RectButton>
      ) : (
        <RectButton
          Variant={
            props.orderDirection === "buy"
              ? GreenFilledButton
              : PinkFilledButton
          }
          {...normal}
          disabled={
            error != null && oneOf(...values(TradingFormErrorType))(error)
          }
          onClick={props.onSubmit}
        >
          {orderDirection$t.fromOrderDirection(intl, props.orderDirection)}
        </RectButton>
      )}

      <div
        className={
          "flex justify-center text-xs leading-4 font-medium text-gray-400 px-2.5 pb-2.5"
        }
      >
        Fees Details&nbsp;
        <TipIcon
          Icon={ExclamationOutlinedIcon}
          tip={
            <span className={"text-xs leading-4 font-medium text-gray-200/70"}>
              <Spensor fallback={"..."}>
                {() =>
                  $t(
                    defineMessage({
                      defaultMessage:
                        "Maker {makerFeeRate} / Taker {makerFeeRate}",
                      description:
                        "Orderbook/Create Order Panel/fee rate tooltip",
                    }),
                    {
                      makerFeeRate: (
                        <span className={"font-semibold text-gray-200"}>
                          <PercentNumber
                            number={readResource(props.feeRates).maker}
                            precision={3}
                          />
                        </span>
                      ),
                      takerFeeRate: (
                        <span className={"font-semibold text-gray-200"}>
                          <PercentNumber
                            number={readResource(props.feeRates).taker}
                            precision={3}
                          />
                        </span>
                      ),
                    },
                  )
                }
              </Spensor>
            </span>
          }
        />
      </div>
    </CompactCard>
  )
}

const useFormErrorContent = (props: {
  formError?: TradingFormError
  onDeposit?: () => void
  onReplacePrice?: () => void
}): null | undefined | JSX.Element => {
  const { $t } = useIntl()

  if (props.formError?.type === TradingFormErrorType.TotalPriceTooSmall) {
    return (
      <>
        {$t(
          defineMessage({
            defaultMessage: "Total value cannot be less than {minTotalPrice}.",
            description: "Orderbook/Create Order Panel/error messages",
          }),
          {
            minTotalPrice: (
              <TextTokenCount
                token={props.formError.priceToken}
                count={props.formError.minimumPriceTokenCount}
              />
            ),
          },
        )}
      </>
    )
  }

  if (
    props.formError?.type ===
      TradingFormErrorType.InsufficientPriceTokenBalance ||
    props.formError?.type === TradingFormErrorType.InsufficientTradeTokenBalance
  ) {
    return (
      <>
        {$t(
          defineMessage({
            defaultMessage: "Insufficient Balance {depositButton}",
            description: "Orderbook/Create Order Panel/error messages",
          }),
          {
            depositButton: (
              <HeadlessButton
                className={"inline text-blue-600"}
                onClick={props.onDeposit}
              >
                {$t(
                  defineMessage({
                    defaultMessage: "Deposit >",
                    description:
                      "Orderbook/Create Order Panel/deposit button belongs error messages",
                  }),
                )}
              </HeadlessButton>
            ),
          },
        )}
      </>
    )
  }

  let warningMessage: undefined | MessageDescriptor
  if (props.formError?.type === TradingFormWarningType.AbnormalBuyingPrice) {
    warningMessage = defineMessage({
      defaultMessage:
        "Limit Price is higher than the lowest selling order price. {replaceButton}",
      description: "Orderbook/Create Order Panel/warning messages",
    })
  } else if (
    props.formError?.type === TradingFormWarningType.AbnormalSellingPrice
  ) {
    warningMessage = defineMessage({
      defaultMessage:
        "Limit Price is lower than the highest buying order price. {replaceButton}",
      description: "Orderbook/Create Order Panel/warning messages",
    })
  }
  if (warningMessage != null) {
    return (
      <>
        {$t(warningMessage, {
          replaceButton: (
            <HeadlessButton
              className={"inline text-blue-600"}
              onClick={props.onReplacePrice}
            >
              {$t(
                defineMessage({
                  defaultMessage: "Replace",
                  description:
                    "Orderbook/Create Order Panel/replace button belongs error messages",
                }),
              )}
            </HeadlessButton>
          ),
        })}
      </>
    )
  }

  return null
}
