import { FC } from "react"
import { useParams } from "react-router-dom"
import { PageStackPage } from "../../../components/PageStack/PageStackPage"
import { SecondaryPageTitle } from "../../../components/PageStack/SecondaryPageTitle"
import { TokenName } from "../../../components/TokenName"
import { usePathGenerator } from "../../../routes/routes"
import { useAppEnvStore } from "../../../stores/appEnvStore/useAppEnvStore"
import { useCurrencyStore } from "../../../stores/currencyStore/useCurrencyStore"
import { Currency, isCurrency } from "../../../utils/alexjs/Currency"
import { isFWPToken } from "../../../utils/alexjs/currencyHelpers"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { CopywritingProvider } from "../../Stake/components/CopywritingProvider"
import { WiredCoFarmStakePageContent } from "../../Stake/components/WiredCoFarmStakePageContent"
import { WiredStakePageContent } from "../../Stake/components/WiredStakePageContent"
import { ManualStakeStoreProvider } from "../../Stake/store/manualStaking/useManualStakeStore"
import { CoFarmStoreProvider } from "../stores/useCoFarmStore"
import { FarmingCopywriting } from "../_/FarmingCopywriting"

const FarmDetailPageImpl: FC<{ token: TokenInfo }> = props => {
  const gen = usePathGenerator()

  const appEnv = useAppEnvStore()

  return (
    <PageStackPage
      title={
        <SecondaryPageTitle returnFallbackURL={gen.farmList()}>
          <span>Farm LP:</span> <TokenName token={props.token} />
        </SecondaryPageTitle>
      }
    >
      {props.token.id === Currency.FWP_STX_ALEX_50_50_V1_01 &&
      appEnv.config$.enableCoFarmFeature ? (
        <CoFarmStoreProvider token={Currency.FWP_STX_ALEX_50_50_V1_01}>
          <WiredCoFarmStakePageContent />
        </CoFarmStoreProvider>
      ) : (
        <WiredStakePageContent />
      )}
    </PageStackPage>
  )
}

export const FarmDetailPage: FC = () => {
  const currency = useParams().token

  if (
    !isCurrency(currency) ||
    (!isFWPToken(currency) && currency !== Currency.ALEX)
  ) {
    throw new Error(`Invalid token ${currency}`)
  }

  const token = useCurrencyStore().getTokenInfo$(currency)

  return (
    <ManualStakeStoreProvider token={currency}>
      <CopywritingProvider value={new FarmingCopywriting()}>
        <FarmDetailPageImpl token={token} />
      </CopywritingProvider>
    </ManualStakeStoreProvider>
  )
}
