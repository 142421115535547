import { gql } from "@urql/core"
import { Observable } from "rxjs"
import {
  GetFarmsListQuery,
  GetFarmsListQueryVariables,
} from "../../../generated/graphql/graphql.generated"
import { Currency } from "../../../utils/alexjs/Currency"
import {
  FWPToken,
  liquidityTokenPairs,
} from "../../../utils/alexjs/currencyHelpers"
import { median } from "../../../utils/arrayHelpers"
import { gqlQuery } from "../../../utils/graphqlHelpers"
import { fromUrqlSource } from "../../../utils/Observable/fromUrqlSource"
import { optionally } from "../../../utils/utils"
import type { FarmListItem } from "./FarmStore"

export function getFarmsList(
  pools: FWPToken[],
  stxPrice: number,
  alexPrice: number,
): Observable<FarmListItem[]> {
  return fromUrqlSource(
    gqlQuery<GetFarmsListQuery, GetFarmsListQueryVariables>(
      gql`
        query GetFarmsList($tokenNames: [String!]!) {
          laplace_latest_pool_stats(
            where: { pool_token: { _in: $tokenNames } }
          ) {
            pool_token
            liquidity
            apr_7d
          }
          laplace_latest_alex_reserve_pools(
            where: { token_name: { _in: $tokenNames } }
          ) {
            token_name
            earning_preview
          }
        }
      `,
      { tokenNames: pools },
    ),
    ({ data }) =>
      pools.map((pool): FarmListItem => {
        const f = data.laplace_latest_alex_reserve_pools?.find(
          d => d.token_name === pool,
        )
        const l = data.laplace_latest_pool_stats.find(
          d => d.pool_token === pool,
        )
        const tokenXPrice =
          liquidityTokenPairs(pool)[0] === Currency.ALEX ? alexPrice : stxPrice
        return {
          token: pool,
          liquidity: optionally(l?.liquidity, e => (e / 1e8) * tokenXPrice),
          apr: f?.earning_preview ? median(f.earning_preview) : undefined,
          fees: optionally(l?.apr_7d, a => a / 1e8),
        }
      }),
  )
}
