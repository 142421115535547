import { observer } from "mobx-react-lite"
import { FC, ReactNode, Suspense, useMemo } from "react"
import { SuspenseResource } from "../utils/SuspenseResource"

export const RenderChildrenFn: FC<{
  suspenseTag?: string
  children: () => ReactNode
}> = props => {
  const WrappedComp = useMemo(() => {
    const Component = (p: { children: () => any }): any => <>{p.children()}</>
    Component.displayName = "RenderChildrenFn(" + props.suspenseTag + ")"
    return observer(Component)
  }, [props.suspenseTag])

  if (props.suspenseTag != null) {
    return <WrappedComp>{props.children}</WrappedComp>
  } else {
    return <>{props.children()}</>
  }
}

export const Spensor: FC<{
  spensorTag?: string
  children: () => ReactNode
  fallback?: ReactNode
}> = props => {
  return (
    <Suspense fallback={props.fallback ?? null}>
      <RenderChildrenFn suspenseTag={props.spensorTag}>
        {props.children}
      </RenderChildrenFn>
    </Suspense>
  )
}

export type SuspenseProps<T extends Record<string, any>> = {
  [P in keyof T]: SuspenseResource<T[P]>
}
