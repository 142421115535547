import { format } from "date-fns"
import { NavLink } from "../../../components/NavLink"
import { StepsBar } from "../../../components/StepsBar/StepsBar"
import { FCC } from "../../../utils/reactHelpers/types"

export interface IDOStepsProps {
  className?: string
  currentStepNumber: number
  validateTime?: Date
  validateListLink?: string
  lotteryTime?: Date
  lotteryListLink?: string
  allocationTime?: Date
}

export const InCardIDOStepsContainer: FCC = props => (
  <div className={"overflow-x-auto p-3 sm:p-6 -m-3 sm:-m-6"}>
    {props.children}
  </div>
)

export const IDOSteps: FCC<IDOStepsProps> = props => (
  <StepsBar
    className={props.className}
    current={props.currentStepNumber}
    steps={[
      {
        aboveText: (
          <AboveText
            currentStep={props.currentStepNumber}
            step={0}
            keepText={true}
          >
            Upcoming
          </AboveText>
        ),
        belowText: <BelowText>Preparation</BelowText>,
      },
      {
        aboveText: (
          <AboveText currentStep={props.currentStepNumber} step={1}>
            {props.validateTime ? formatDate(props.validateTime) : "-"}
          </AboveText>
        ),
        belowText: (
          <BelowText
            link={
              props.currentStepNumber >= 1 ? props.validateListLink : undefined
            }
          >
            Validate
          </BelowText>
        ),
      },
      {
        aboveText: (
          <AboveText currentStep={props.currentStepNumber} step={2}>
            {props.lotteryTime ? formatDate(props.lotteryTime) : "-"}
          </AboveText>
        ),
        belowText: (
          <BelowText
            link={
              props.currentStepNumber >= 2 ? props.lotteryListLink : undefined
            }
          >
            Lottery
          </BelowText>
        ),
      },
      {
        aboveText: (
          <AboveText currentStep={props.currentStepNumber} step={3}>
            {props.allocationTime ? formatDate(props.allocationTime) : "-"}
          </AboveText>
        ),
        belowText: <BelowText>Allocation</BelowText>,
      },
    ]}
  />
)

const AboveText: FCC<{
  currentStep: number
  step: number
  keepText?: boolean
}> = props => {
  if (props.currentStep === props.step && !props.keepText) {
    return <>Current</>
  }

  return <>{props.children}</>
}

const BelowText: FCC<{
  link?: string
}> = props => (
  <div>
    <p>{props.children}</p>
    {props.link && (
      <p className={"text-xs leading-4 font-normal text-blue-600"}>
        <NavLink to={props.link} target="_blank">
          Details
        </NavLink>
      </p>
    )}
  </div>
)

function formatDate(date: Date): string {
  return format(date, "LLL d")
}
