import { action } from "mobx"
import React, { FC, Suspense } from "react"
import { useParams } from "react-router-dom"
import { CollapsableCard } from "../../components/CollapsableCard/CollapsableCard"
import { useConnect } from "../../components/ConnectWallet/ConnectProvider"
import { Modal } from "../../components/Modal"
import { NavLink } from "../../components/NavLink"
import { GradientSecondaryPageTitle } from "../../components/PageStack/GradientSecondaryPageTitle"
import { PageStackPage } from "../../components/PageStack/PageStackPage"
import { PinnacleLayoutMode } from "../../components/PinnacleLayoutMode"
import { WiredSlippageModal } from "../../components/SlippageModalContent/WiredSlippageSettingsButton/WiredSlippageSettingsButton"
import { Spensor } from "../../components/Spensor"
import { TokenName } from "../../components/TokenName"
import { WiredTransactionStateDialog } from "../../components/TransactionStateDialog/WiredTransactionStateDialog"
import { usePathGenerator } from "../../routes/routes"
import { useChainStore } from "../../stores/chainStore/useChainStore"
import { useCurrencyStore } from "../../stores/currencyStore/useCurrencyStore"
import { Currency } from "../../utils/alexjs/Currency"
import { isYTPToken } from "../../utils/alexjs/currencyHelpers"
import { Result } from "../../utils/Result"
import { suspenseResource } from "../../utils/SuspenseResource"
import WiredLoadingOrConnectWallet from "../Auth/components/WiredLoadingOrConnectWallet"
import { AddDepositPanel } from "./components/depositPage/AddDepositPanel/AddDepositPanel"
import { ConfirmDepositModalContent } from "./components/depositPage/ConfirmDepositModalContent/ConfirmDepositModalContent"
import { DepositChartPanel } from "./components/depositPage/DepositChartPanel/DepositChartPanel"
import { LendStoreProvider, useLendStore } from "./store/useLendStore"
import { WiredMyDepositsPanel } from "./WiredMyDepositsPanel"

const WiredAddDepositPanel: FC = () => {
  const store = useLendStore()
  const currency = useCurrencyStore()
  const token = currency.getTokenInfo$(store.underlyingToken)
  const yToken = currency.getTokenInfo$(store.yieldToken)
  const chain = useChainStore()
  const { addDeposit } = store
  const [connect] = useConnect()
  return (
    <>
      <AddDepositPanel
        depositToken={token}
        yieldToken={yToken}
        depositTokenCount={addDeposit.amount.get() ?? null}
        setDepositTokenCount={amount => {
          addDeposit.amount.set(amount ?? undefined)
          addDeposit.validation.reset()
        }}
        depositTokenBalance={suspenseResource(() => addDeposit.balance$)}
        yieldTokenCount={suspenseResource(() => addDeposit.yieldTokenAmount$)}
        estimateAbsoluteInterest={suspenseResource(
          () => addDeposit.estimateAbsoluteInterest$,
        )}
        estimateClaimable={suspenseResource(() => addDeposit.yieldTokenAmount$)}
        expirationBlockHeight={suspenseResource(() => store.currentExpiry$)}
        liquidityProviderFee={suspenseResource(
          () => addDeposit.liquidityProviderFee$,
        )}
        expirationDate={suspenseResource(() =>
          chain.estimatedDateForBlock$(store.currentExpiry$),
        )}
        slippage={addDeposit.slippageStore.slippagePercentage}
        onClickSlippage={() => addDeposit.slippageStore.showSlippagePopModal()}
        onSubmit={() => addDeposit.startValidating()}
        error={suspenseResource(() => Result.maybeError(addDeposit.formData$))}
        onConnectWallet={() => connect()}
        onClear={
          addDeposit.amount.get() == null
            ? undefined
            : () => addDeposit.amount.set(undefined)
        }
      />
      <WiredSlippageModal slippagePercent={addDeposit.slippageStore} />
      <Spensor>
        {() => (
          <Modal
            visible={addDeposit.showConfirmation}
            onClose={action(() => (addDeposit.showConfirmation = false))}
          >
            <ConfirmDepositModalContent
              depositToken={token}
              depositTokenCount={addDeposit.amount.read$}
              estimateAbsoluteInterest={addDeposit.estimateAbsoluteInterest$}
              liquidityProviderFee={addDeposit.liquidityProviderFee$}
              estimateClaimable={addDeposit.yieldTokenAmount$}
              expirationBlockHeight={store.currentExpiry$}
              expirationDate={chain.estimatedDateForBlock$(
                store.currentExpiry$,
              )}
              slippage={addDeposit.slippageStore.slippagePercentage}
              yieldToken={yToken}
              yieldTokenCount={addDeposit.yieldTokenAmount$}
              onClose={action(() => (addDeposit.showConfirmation = false))}
              onConfirm={async () => {
                const form = Result.maybeValue(addDeposit.formData$)
                if (form == null) {
                  return
                }
                await addDeposit.addDeposit(form)
              }}
            />
          </Modal>
        )}
      </Spensor>
      <WiredTransactionStateDialog store={addDeposit.txStore} />
    </>
  )
}

const WiredDepositChartPanel: FC = () => {
  const store = useLendStore()
  const currency = useCurrencyStore()
  const token = currency.getTokenInfo$(store.underlyingToken)
  return (
    <DepositChartPanel
      depositToken={token}
      totalDepositTokenCount={store.poolTotalDeposit$}
      apr={store.depositAPR$}
      chartCurves={suspenseResource(() => store.depositChartData.value$)}
    />
  )
}

const PageContent: FC = () => {
  return (
    <PinnacleLayoutMode.Container>
      <PinnacleLayoutMode.Top>
        <Suspense
          fallback={
            <CollapsableCard title="Deposited">
              <WiredLoadingOrConnectWallet />
            </CollapsableCard>
          }
        >
          <WiredMyDepositsPanel />
        </Suspense>
      </PinnacleLayoutMode.Top>

      <PinnacleLayoutMode.BottomLeft>
        <Suspense>
          <WiredAddDepositPanel />
        </Suspense>
      </PinnacleLayoutMode.BottomLeft>

      <PinnacleLayoutMode.BottomRight>
        <WiredDepositChartPanel />
      </PinnacleLayoutMode.BottomRight>
    </PinnacleLayoutMode.Container>
  )
}

const DepositPageImpl: FC = () => {
  const currency = useCurrencyStore()
  const store = useLendStore()
  const g = usePathGenerator()

  return (
    <PageStackPage
      title={
        <>
          <GradientSecondaryPageTitle
            gradientStyle={
              "linear-gradient(90.83deg, #FFFFFF 0.71%, #10B981 99.29%)"
            }
            returnFallbackURL={g.lend()}
          >
            Deposit{" "}
            <TokenName token={currency.getTokenInfo$(store.underlyingToken)} />
          </GradientSecondaryPageTitle>
          <NavLink
            className={"text-blue-600"}
            to={
              "https://medium.com/alexgobtc/introducing-lend-borrow-8457f8064a56#dc3b"
            }
          >
            How does Deposit work?
          </NavLink>
        </>
      }
    >
      <PageContent />
    </PageStackPage>
  )
}

export const DepositPage: FC = () => {
  const currency = useParams().token as Currency
  if (!isYTPToken(currency)) {
    throw new Error(`Invalid token ${currency}`)
  }
  return (
    <LendStoreProvider pool={currency}>
      <Suspense>
        <DepositPageImpl />
      </Suspense>
    </LendStoreProvider>
  )
}
