import { FC } from "react"
import { ordinal } from "../../../../../../utils/stringHelpers"
import { LotteryWonRowData } from "../../../../store/transformerGroups/lottery/lotteryWon"
import { AllTokenInfos } from "../../../../types"
import { BaseCellContainer } from "../../cells/BaseCellContainer"
import { OneLineCurrencyCell } from "../../cells/OneLineCurrencyCell"
import { OneLineTokenAmount } from "../../OneLineTokenAmount"
import { BaseRow } from "../BaseRow"

export const LotteryWonRow: FC<{
  rowData: LotteryWonRowData
  allTokenInfos: AllTokenInfos
}> = ({ rowData, allTokenInfos }) => (
  <BaseRow
    rowData={rowData}
    currencyCell={
      <OneLineCurrencyCell
        fromCurrencyAmounts={[rowData.winningCurrencyAmount]}
        allTokenInfos={allTokenInfos}
      />
    }
    amountCell={
      <BaseCellContainer>
        <p className="whitespace-nowrap">
          {rowData.winningTickets != null ? rowData.winningTickets + " " : ""}
          Lottery
        </p>
        <p className="whitespace-nowrap">
          <OneLineTokenAmount
            currencyAmount={rowData.winningCurrencyAmount}
            allTokenInfos={allTokenInfos}
            prefix="→"
          />
        </p>
      </BaseCellContainer>
    }
    tradeInfoCell={
      <BaseCellContainer>
        <p className="whitespace-nowrap">Round #{rowData.lotteryId}</p>
        {rowData.prize != null && (
          <p className="whitespace-nowrap">{ordinal(rowData.prize!)} Prize</p>
        )}
      </BaseCellContainer>
    }
  />
)
