/**
 * https://medium.com/teads-engineering/generating-uuids-at-scale-on-the-web-2877f529d2a2
 */
export const uuid = (): string => {
  const url = URL.createObjectURL(new Blob())
  return url.substring(url.lastIndexOf("/") + 1)
}

export const plural = (
  count: number,
  plural: Partial<Record<Intl.LDMLPluralRule, string>> & {
    one: string
    many: string
  },
  options: Partial<
    Intl.PluralRulesOptions & {
      locales?: string | string[]
    }
  > = {},
): string => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { locales, ...constructOptions } = options

  const rule = new Intl.PluralRules(options.locales ?? "en-US", {
    ...constructOptions,
    type: constructOptions.type ?? "cardinal",
  })

  const res = rule.select(count)
  if (res === "zero" || res === "one") {
    return plural["one"]
  } else {
    return plural[res] ?? plural["many"]
  }
}

export const ordinal = (num: number): string => {
  const rule = new Intl.PluralRules("en-US", { type: "ordinal" })

  switch (rule.select(num)) {
    case "one":
      return `${num}st`
    case "two":
      return `${num}nd`
    case "few":
      return `${num}rd`
    default:
      return `${num}th`
  }
}

/**
 * format record identifier as:
 *
 * `xxxxxx...xxxxxx`, or `xxxxxxxxxxxxxxx`
 */
export const simplifyIdentifier = (id: string): string => {
  const partLength = 6
  if (id.length < partLength + 3 + partLength) {
    return id
  } else {
    return id.slice(0, partLength) + "..." + id.slice(-partLength)
  }
}
