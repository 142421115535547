import clsx from "clsx"
import { FC } from "react"
import { Outlet } from "react-router-dom"
import WiredBlockStatus from "../../components/BlockStatus/WiredBlockStatus"
import { ConnectProvider } from "../../components/ConnectWallet/ConnectProvider"
import {
  WiredDisclaimerModal,
  WiredDisclaimerModalPreventProvider,
} from "../../components/DisclaimerModalContent/WiredDisclaimerModal"
import { RootLayoutContainerProvider } from "../../components/LayoutContainer/RootLayoutContainer"
import { PageStackProvider } from "../../components/PageStack/PageStackProvider"
import { TopNavBarHeight } from "../../components/TopNavBar/TopNavBar"
import { WiredTopNavBar } from "../../components/TopNavBar/WiredTopNavBar"
import { WiredUnSupportAlertModal } from "../../components/UnSupportAlertModal/WiredUnSupportAlertModal"
import { WiredNewTokenomics } from "../components/NewTokenomics/WiredNewTokenomics"
import styles from "./Layout.module.scss"

export const Layout: FC = () => {
  return (
    <ConnectProvider>
      <PageStackProvider>
        <WiredDisclaimerModalPreventProvider>
          <div className={"fixed top-0 left-0 right-0 z-1"}>
            <WiredTopNavBar />
          </div>

          <RootLayoutContainerProvider
            className={"pb-8 sm:py-[8.75rem] grow"}
            topNavHeight={TopNavBarHeight}
            topExtraPadding={16}
          >
            <Outlet />
          </RootLayoutContainerProvider>

          <div
            className={clsx(
              styles["widgets-container"],
              "sm:fixed bottom-0 left-0 right-0 mt-16 p-5 flex items-end",
            )}
          >
            <WiredNewTokenomics className={"ml-auto"} />
          </div>

          <WiredUnSupportAlertModal />
          <WiredDisclaimerModal />
          <WiredBlockStatus />
        </WiredDisclaimerModalPreventProvider>
      </PageStackProvider>
    </ConnectProvider>
  )
}
