import { gql } from "@urql/core"
import { isEqual, range, sortBy } from "lodash"
import { Observable } from "rxjs"
import {
  FetchLockdropDetailQuery,
  FetchLockdropDetailQueryVariables,
  FetchLockdropMyStatusQuery,
  FetchLockdropMyStatusQueryVariables,
  FetchLockdropRankingsQuery,
} from "../../../generated/graphql/graphql.generated"
import {
  parseTokenFragment,
  TokenFragment,
} from "../../../stores/currencyStore/CurrencyStore.service"
import { gqlQuery } from "../../../utils/graphqlHelpers"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { fromUrqlSource } from "../../../utils/Observable/fromUrqlSource"
import { isNotNull } from "../../../utils/utils"
import { LockdropStatus } from "../components/types"

export interface LockdropDetail {
  urlSlug: string
  title: string
  description?: string
  name: string
  startDate: Date
  endDate: Date
  details?: string
  uncoverSnapshots: boolean[]
  uncoverEndDate: boolean
  snapshotBlockHeights: number[]
  rewardTokens: TokenInfo[]
}

export interface Ranking {
  address: string
  name?: string
  volume: number
  ranking: number
  multiplier: number
}

export interface MyStatus {
  volume?: number | null
  ranking?: number | null
  rewardsMultiplier?: number | null
  snapshotMultipliers: (number | null)[]
}

// fetchers
export const fetchDetails = (urlSlug: string): Observable<LockdropDetail> =>
  fromUrqlSource(
    gqlQuery<FetchLockdropDetailQuery, FetchLockdropDetailQueryVariables>(
      gql`
        ${TokenFragment}
        query FetchLockdropDetail($urlSlug: String!) {
          public_dbt_autoalex_lockdrop_snapshot_config(
            order_by: { rank: asc }
          ) {
            block_height
            rank
          }
          lockdropCollection(where: { urlSlug: $urlSlug }, limit: 1) {
            items {
              urlSlug
              title
              description
              name
              startDate
              endDate
              details
              uncoverSnapshot1
              uncoverSnapshot2
              uncoverSnapshot3
              uncoverEndDate
              rewardTokensCollection {
                items {
                  ...TokenFragment
                }
              }
            }
          }
        }
      `,
      { urlSlug },
    ),
    ({ data }) => {
      const lockdrop = data.lockdropCollection?.items?.[0]
      if (lockdrop == null) {
        throw new Error(`No Lockdrop data for urlSlug: ${urlSlug}`)
      }
      const autoAlexSnapshotConfig =
        data.public_dbt_autoalex_lockdrop_snapshot_config
      const snapshotBlockHeights = autoAlexSnapshotConfig.map(snapshot => {
        if (snapshot.block_height == null) {
          throw new Error(`No snapshot block height for rank: ${snapshot.rank}`)
        }
        return snapshot.block_height
      })

      return {
        urlSlug: lockdrop.urlSlug!,
        title: lockdrop.title!,
        description: lockdrop.description!,
        name: lockdrop.name!,
        startDate: new Date(lockdrop.startDate!),
        endDate: new Date(lockdrop.endDate!),
        details: lockdrop.details!,
        snapshotBlockHeights,
        rewardTokens: transformLockdropRewardTokens(lockdrop),
        uncoverSnapshots: [
          lockdrop.uncoverSnapshot1!,
          lockdrop.uncoverSnapshot2!,
          lockdrop.uncoverSnapshot3!,
        ],
        uncoverEndDate: lockdrop.uncoverEndDate!,
      }
    },
  )

export const fetchRankings = (_urlSlug: string): Observable<Ranking[]> =>
  fromUrqlSource(
    gqlQuery<FetchLockdropRankingsQuery>(gql`
      query FetchLockdropRankings {
        public_dbt_dim_balance_autoalex_lockdrop2_rank_view(
          where: { total_autoalex_balance: { _gte: "650" } }
          limit: 10
          order_by: { rank_no: asc }
        ) {
          address
          name
          rank_no
          total_autoalex_balance
          current_multiplier
        }
      }
    `),
    ({ data }) => {
      return data.public_dbt_dim_balance_autoalex_lockdrop2_rank_view.map(
        transformRanking,
      )
    },
  )

export const fetchMyStatus = ([address]: readonly [
  string,
]): Observable<MyStatus> =>
  fromUrqlSource(
    gqlQuery<FetchLockdropMyStatusQuery, FetchLockdropMyStatusQueryVariables>(
      gql`
        query FetchLockdropMyStatus($address: String!) {
          public_dbt_dim_balance_autoalex_lockdrop2_rank_view(
            where: { address: { _eq: $address } }
          ) {
            address
            rank_no
            current_multiplier
            total_autoalex_balance
          }
          public_dbt_dim_balance_autoalex_lockdrop2_rank_snapshot_view(
            where: { address: { _eq: $address } }
            order_by: { snapshot_rank: asc }
          ) {
            multiplier
            snapshot_rank
            total_autoalex_balance
            balance_block_height
            target_block_height
            address
          }
        }
      `,
      { address },
    ),
    ({ data }) => {
      const result = data.public_dbt_dim_balance_autoalex_lockdrop2_rank_view[0]
      if (!result) {
        return {
          snapshotMultipliers: [],
          volume: 0,
          rewardsMultiplier: 0,
        }
      }

      const multipliers =
        data.public_dbt_dim_balance_autoalex_lockdrop2_rank_snapshot_view.reduce<{
          [rankIndex: number]: number
        }>((acc, curr) => {
          if (curr.snapshot_rank) {
            acc[curr.snapshot_rank] = curr.multiplier!
          }
          return acc
        }, {})

      return {
        snapshotMultipliers: range(1, 4).map(
          rankIndex => multipliers[rankIndex] ?? null,
        ),
        volume: result.total_autoalex_balance,
        ranking:
          result.total_autoalex_balance && result.total_autoalex_balance > 650
            ? Number(result.rank_no)
            : undefined,
        rewardsMultiplier: result.current_multiplier,
      }
    },
  )

// transformers
type LockdropData = NonNullable<
  FetchLockdropDetailQuery["lockdropCollection"]
>["items"][number]

const transformLockdropRewardTokens = (
  lockdrop: NonNullable<LockdropData>,
): TokenInfo[] => {
  return lockdrop
    .rewardTokensCollection!.items.filter(isNotNull)
    .map(parseTokenFragment)
    .map((t, index) => {
      return {
        ...t,
        id: `lockdrop-reward-${index}`,
      }
    })
}

type Rankings =
  FetchLockdropRankingsQuery["public_dbt_dim_balance_autoalex_lockdrop2_rank_view"]

const transformRanking = (ranking: Rankings[number]): Ranking => {
  return {
    address: ranking.address!,
    name: ranking.name!,
    volume: ranking.total_autoalex_balance!,
    ranking: Number(ranking.rank_no!),
    multiplier: ranking.current_multiplier!,
  }
}

export const transformSnapshotMultipliers = (
  data: FetchLockdropMyStatusQuery["public_dbt_dim_balance_autoalex_lockdrop2_rank_snapshot_view"],
): (number | null)[] => {
  const multipliers = data.reduce<{
    [rankIndex: number]: number
  }>((acc, curr) => {
    if (curr.snapshot_rank) {
      acc[curr.snapshot_rank] = curr.multiplier!
    }
    return acc
  }, {})

  return range(1, 4).map(rankIndex => multipliers[rankIndex] ?? null)
}

// helpers
/**
 * https://www.notion.so/alexgo-io/Lockdrop-in-atALEX-97d8b6fcafc3492d9e29eeb4007de03e#5fbc89ba28ba4549a63df1e049ac0e58
 *
 * In short: Upcoming < startDate < Live < 3rdSnapshot < Allocation < endDate < Ended
 * @see getCurrentStepIndex
 */
export const getStatusFromStepIndex = (stepIndex: number): LockdropStatus => {
  switch (stepIndex) {
    case 1:
      return LockdropStatus.Upcoming
    case 5:
      return LockdropStatus.Allocation
    case 6:
      return LockdropStatus.Ended
    default:
      return LockdropStatus.Live
  }
}

const LAST_STEP = 6
const SNAPSHOT_COUNT = 3

/**
 * 1     |     2     |     3     |     4     |     5    |     6
 *   startDate   snapshot1   snapshot2   snapshot3   endDate
 */
export const getCurrentStepIndex = (
  startDate: Date,
  endDate: Date,
  today: Date,
  snapshotDates: Date[] = [],
): number => {
  if (!isInOrder([startDate, ...snapshotDates, endDate])) {
    throw new Error("Dates are not in order.")
  }

  // find first date later than today
  const steps = [
    startDate,
    ...range(SNAPSHOT_COUNT).map(i => snapshotDates[i] ?? endDate),
    endDate,
  ]
  const firstLaterDate = steps.findIndex(d => d > today)

  if (firstLaterDate === -1 && snapshotDates.length !== SNAPSHOT_COUNT) {
    throw new Error("Snapshot dates are not filled.")
  }
  return firstLaterDate === -1 ? LAST_STEP : firstLaterDate + 1 // step number = index + 1
}

const isInOrder = (dates: Date[]): boolean => {
  return isEqual(sortBy(dates), dates)
}
