import { sum } from "lodash"
import { FC, ReactElement, ReactNode } from "react"
import { Button } from "../../../../../../components/button/Button"
import { RoundedButton } from "../../../../../../components/button/RoundedButton"
import { GoldenFilledButton } from "../../../../../../components/button/variants/GoldenFilledButton"
import { CardDivider, CardNested } from "../../../../../../components/Card"
import { NavLink } from "../../../../../../components/NavLink"
import { PercentNumber } from "../../../../../../components/PercentNumber"
import { TipIcon } from "../../../../../../components/TipIcon/TipIcon"
import { TokenCount } from "../../../../../../components/TokenCount"
import { TokenIconProps } from "../../../../../../components/TokenIcon"
import { Tooltip } from "../../../../../../components/Tooltip/Tooltip"
import { TokenInfo } from "../../../../../../utils/models/TokenInfo"
import {
  ClaimTokenInfoVerticalList,
  StakingSummaryList,
  StakingSummaryListItem,
  TitleText,
} from "../../../../components/StakingSummaryList"
import { ClaimTokenInfo } from "../../../../types"
import { ReactComponent as CoFarmButtonIcon } from "./coFarmButtonIcon.svg"
import { ReactComponent as EllipsisIcon } from "./ellipsis.svg"
import { ReactComponent as HarvestAllIcon } from "./harvestAll.svg"

export const MyStakingSummary: FC<{
  /**
   * e.g. fwp-stx-alex-50-50, left side token is STX
   */
  lpTokenLeftSideToken: TokenInfo
  activeCoFarming: {
    token: TokenInfo
    count: number
  }
  activeFarming: {
    title: ReactNode
    token: TokenInfo
    count: number
  }
  averageAPR: {
    title: ReactNode
    apr: number
    tip?: ReactNode
  }
  rewardClaim: {
    title: ReactNode
    tokens: ClaimTokenInfo[]
    tipArea?: ReactElement
  }
  principalClaim: {
    title: ReactNode
    tokens: ClaimTokenInfo[]
  }
  coFarmLink: string
  enableCoFarm: boolean
  onHarvestCoFarm?: () => void
  onHarvestAll?: () => void
}> = props => {
  const hasPendingReturns =
    sum(props.principalClaim.tokens.map(t => t.count)) > 0
  const hasPendingRewards = sum(props.rewardClaim.tokens.map(t => t.count)) > 0

  return (
    <>
      <StakingSummaryList className={"py-4"} gapClassName={"gap-4"}>
        <StakingSummaryListItem
          title={
            <TitleText className={"leading-[16px] sm:leading-[36px]"}>
              {props.activeFarming.title}
            </TitleText>
          }
          detail={
            <div className={"flex flex-col"}>
              <ClaimTokenInfoVerticalList
                tokenIconSize={TokenIconProps.defaultSize}
                countTextClassName={
                  "text-[24px] sm:text-[30px] leading-[24px] sm:leading-[36px]"
                }
                infos={[props.activeFarming]}
              />
              {props.activeCoFarming.count > 0 && (
                <div className={"text-sm font-normal text-gray-400 self-end"}>
                  Including{" "}
                  <TokenCount
                    token={props.activeCoFarming.token}
                    count={props.activeCoFarming.count}
                  />{" "}
                  Co-Farm LP{" "}
                  <TipIcon
                    inline
                    tip={
                      "Co-Farm LP will auto harvest the rewards to your wallet every cycle"
                    }
                  />
                </div>
              )}
            </div>
          }
        />

        <StakingSummaryListItem
          title={
            <span className={"flex flex-row items-center gap-2.5"}>
              <TitleText className={"leading-[16px] sm:leading-[36px]"}>
                {props.averageAPR.title}
              </TitleText>

              {props.averageAPR.tip && <TipIcon tip={props.averageAPR.tip} />}
            </span>
          }
          detail={
            <span
              className={
                "text-[24px] sm:text-[30px] leading-[24px] sm:leading-[36px]"
              }
            >
              <PercentNumber number={props.averageAPR.apr} />
            </span>
          }
        />
      </StakingSummaryList>

      <CardNested className={"mt-2.5"}>
        <StakingSummaryList gapClassName={"gap-2.5"}>
          <StakingSummaryListItem
            title={
              <span className={"flex flex-row items-center gap-2.5"}>
                <TitleText className={"leading-[16px] sm:leading-[36px]"}>
                  {props.rewardClaim.title}
                </TitleText>
                {props.rewardClaim.tipArea}
              </span>
            }
            detail={
              <ClaimTokenInfoVerticalList
                countTextClassName={
                  "leading-[24px] sm:leading-[36px] text-[24px]"
                }
                infos={props.rewardClaim.tokens}
              />
            }
          />

          <CardDivider />

          <StakingSummaryListItem
            title={
              <TitleText className={"leading-[16px] sm:leading-[36px]"}>
                {props.principalClaim.title}
              </TitleText>
            }
            detail={
              <ClaimTokenInfoVerticalList
                countTextClassName={"leading-[32px] text-[24px]"}
                infos={props.principalClaim.tokens}
              />
            }
          />
        </StakingSummaryList>

        <div className={"mt-6 flex gap-2.5"}>
          {(props.enableCoFarm && hasPendingReturns) ||
          props.onHarvestCoFarm != null ? (
            <>
              {props.onHarvestCoFarm != null ? (
                <RoundedButton
                  className="flex-1 flex flex-col items-center"
                  Variant={GoldenFilledButton}
                  onClick={props.onHarvestCoFarm}
                >
                  <div
                    className={
                      "flex-1 flex flex-row gap-2.5 items-center justify-center text-white text-base font-semibold"
                    }
                  >
                    <CoFarmButtonIcon
                      fill={"currentColor"}
                      width={24}
                      height={24}
                    />
                    Claim your Co-Farm LP
                  </div>
                </RoundedButton>
              ) : (
                <NavLink className={"flex-1"} to={props.coFarmLink}>
                  <RoundedButton Variant={GoldenFilledButton}>
                    <div
                      className={
                        "flex flex-row gap-2.5 items-center justify-center text-white text-base font-semibold"
                      }
                    >
                      <CoFarmButtonIcon
                        fill={"currentColor"}
                        width={24}
                        height={24}
                      />
                      Co-Farming with your LP tokens
                    </div>
                    <div className={"text-xs text-white/50"}>
                      Farm with protection
                    </div>
                  </RoundedButton>
                </NavLink>
              )}
              <Tooltip title={"Harvest"}>
                <RoundedButton
                  className={
                    "flex flex-row gap-2.5 min-w-[64px] min-h-[64px] aspect-square items-center justify-center"
                  }
                  disabled={
                    !sum(props.rewardClaim.tokens.map(t => t.count)) &&
                    !sum(props.principalClaim.tokens.map(t => t.count))
                  }
                  onClick={props.onHarvestAll}
                >
                  <EllipsisIcon width={16} height={16} />
                </RoundedButton>
              </Tooltip>
            </>
          ) : (
            <Button
              className={
                "w-full mt-6 flex flex-row gap-2.5 items-center justify-center"
              }
              disabled={!hasPendingRewards && !hasPendingReturns}
              onClick={props.onHarvestAll}
            >
              <HarvestAllIcon fill={"currentColor"} />
              Harvest All
            </Button>
          )}
        </div>
      </CardNested>
    </>
  )
}
