import clsx from "clsx"
import { FC } from "react"
import { HeadlessButton } from "../../../components/button/HeadlessButton"
import iconSrc from "./_/icon.svg"

export interface NewTokenomicsProps {
  className?: string
  onClick?: () => void
}

export const NewTokenomics: FC<NewTokenomicsProps> = props => {
  return (
    <HeadlessButton
      className={clsx(
        props.className,
        "p-2 flex flex-row items-center gap-2.5 w-fit-content rounded-full",
      )}
      style={{
        background: "linear-gradient(102.57deg, #2D2D2D 0%, #000000 100%)",
      }}
      onClick={props.onClick}
    >
      <img src={iconSrc} />

      <span className={"text-left text-xs text-white mr-2.5"}>
        New ALEX <br />
        Tokenomics
      </span>
    </HeadlessButton>
  )
}
