import { FC } from "react"
import { APowerRatioProvider } from "../../components/APowerTip/APowerTip"
import { PageStackPage } from "../../components/PageStack/PageStackPage"
import { PrimaryPageTitle } from "../../components/PageStack/PrimaryPageTitle"
import { useCurrencyStore } from "../../stores/currencyStore/useCurrencyStore"
import { Currency } from "../../utils/alexjs/Currency"
import {
  dualYieldToken,
  isDualYieldPool,
} from "../../utils/alexjs/currencyHelpers"
import {
  safeReadResource,
  suspenseResource,
} from "../../utils/SuspenseResource"
import { isNotNull } from "../../utils/utils"
import { CopywritingProvider } from "../Stake/components/CopywritingProvider"
import { WiredAPowerAlert } from "../Stake/components/WiredAPowerAlert"
import { StakeCycleStatus } from "../Stake/types"
import { AllFarmsPanel } from "./components/AllFarmsPanel/AllFarmsPanel"
import { MyFarmsPanel } from "./components/MyFarmsPanel/MyFarmsPanel"
import { FarmStoreProvider, useFarmStore } from "./stores/useFarmStore"
import { FarmingCopywriting } from "./_/FarmingCopywriting"

const WiredMyFarmsPanel: FC<{ className?: string }> = props => {
  const store = useFarmStore()
  return (
    <MyFarmsPanel
      className={props.className}
      farms={suspenseResource(() =>
        store.myFarms$.map(f => ({
          cycleStatus: StakeCycleStatus.InProgress,
          aprs: [
            { percentage: f.myStaking.stakingAPR$, token: f.alexTokenInfo$ },
            f.dualYield
              ? {
                  token: f.dualYield.tokenInfo$,
                  percentage:
                    f.myStaking.stakingAPR$ * (f.dualYield.aprMultiplier$ - 1),
                }
              : undefined,
          ].filter(isNotNull),
          liquidityPoolToken: f.tokenInfo$,
          stackedCount:
            f.myStaking.activeStaking +
            (f.token === Currency.FWP_STX_ALEX_50_50_V1_01
              ? store.coFarmStore.nextCycleBalance$
              : 0),
          reward: f.rewardInfos$,
        })),
      )}
    />
  )
}

const WiredAllFarmsPanel: FC<{ className?: string }> = props => {
  const store = useFarmStore()
  const currency = useCurrencyStore()

  return (
    <AllFarmsPanel
      className={props.className}
      recommendedFarms={suspenseResource(() =>
        store.allPoolTokens$
          .map(pool => ({
            liquidityPoolToken: currency.getTokenInfo$(pool),
            myBalanceCount: suspenseResource(() => store.getBalance$(pool)),
            apr: store.aprForPool$(pool),
          }))
          .filter(f => safeReadResource(f.myBalanceCount)! > 0),
      )}
      otherFarms={suspenseResource(() =>
        store.allPoolTokens$.map(pool => ({
          isDualYield: isDualYieldPool(pool),
          liquidityPoolToken: currency.getTokenInfo$(pool),
          cycleStatus: StakeCycleStatus.InProgress,
          liquidityCount: store.poolStats$(pool)?.liquidity,
          rewardTokens: [
            currency.getTokenInfo$(Currency.ALEX),
            isDualYieldPool(pool)
              ? currency.getTokenInfo$(dualYieldToken(pool))
              : null,
            currency.getTokenInfo$(Currency.APOWER),
          ].filter(isNotNull),
          totalApr: store.aprForPool$(pool),
          tradingFee: store.poolStats$(pool)?.fees,
        })),
      )}
    />
  )
}

const PageContent: FC = () => {
  const store = useFarmStore()
  return (
    <APowerRatioProvider
      ratio={suspenseResource(() => store.apowerMultiplier$)}
    >
      <CopywritingProvider value={new FarmingCopywriting()}>
        <div className={"flex flex-col gap-5"}>
          <WiredAPowerAlert />
          <WiredMyFarmsPanel />
          <WiredAllFarmsPanel />
        </div>
      </CopywritingProvider>
    </APowerRatioProvider>
  )
}

export const FarmListPage: FC = () => {
  return (
    <FarmStoreProvider>
      <PageStackPage
        title={
          <PrimaryPageTitle subtitle={"Stake LP Tokens to earn more rewards."}>
            Farm
          </PrimaryPageTitle>
        }
      >
        <PageContent />
      </PageStackPage>
    </FarmStoreProvider>
  )
}
