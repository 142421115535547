import { FC } from "react"
import {
  FinishedBadge,
  ProgressingBadge,
  UpcomingBadge,
} from "../../../components/Badge"
import { assertNever } from "../../../utils/types"
import { StakeCycleStatus } from "../types"

export const CycleStatusBadge: FC<{
  status: StakeCycleStatus
}> = props => {
  switch (props.status) {
    case StakeCycleStatus.Upcoming:
      return <UpcomingBadge>Upcoming</UpcomingBadge>
    case StakeCycleStatus.InProgress:
      return <ProgressingBadge>Current</ProgressingBadge>
    case StakeCycleStatus.Finished:
      return <FinishedBadge>Finished</FinishedBadge>
    default:
      assertNever(props.status)
  }
}
