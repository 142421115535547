import { createContext, FC, useCallback, useContext, useMemo } from "react"
import { FCC } from "../../utils/reactHelpers/types"
import {
  readResource,
  suspenseResource,
  SuspenseResource,
} from "../../utils/SuspenseResource"
import { Spensor } from "../Spensor"
import { TipIcon } from "../TipIcon/TipIcon"

const APowerRatioContext = createContext<SuspenseResource<number>>(0.3)

export const APowerRatioProvider: FCC<{
  ratio: SuspenseResource<number>
}> = props => {
  return (
    <APowerRatioContext.Provider value={props.ratio}>
      {props.children}
    </APowerRatioContext.Provider>
  )
}

export const useAPowerRatio = (): {
  ratio: SuspenseResource<number>
  displayableRatio: SuspenseResource<string>
} => {
  const ratio = useContext(APowerRatioContext)

  return useMemo(
    () => ({
      ratio,
      displayableRatio: suspenseResource(() => readResource(ratio).toString()),
    }),
    [ratio],
  )
}

export const APowerTip: FC<{
  className?: string
}> = props => {
  const { displayableRatio } = useAPowerRatio()

  const getCopy = useCallback(
    (ratio: string) =>
      `Gain APower to increase your access to IDOs on the ALEX Launchpad. \nThe amount of APower received will be ${ratio}x the amount of $ALEX.`,
    [],
  )

  return (
    <Spensor
      fallback={<TipIcon className={props.className} tip={getCopy("...")} />}
    >
      {() => (
        <TipIcon
          className={props.className}
          tip={getCopy(readResource(displayableRatio))}
        />
      )}
    </Spensor>
  )
}
