import { unwrapResponse } from "clarity-codegen"
import { memoize } from "lodash"
import { computed, makeObservable } from "mobx"
import { createTransformer } from "mobx-utils"
import { asSender } from "../../generated/smartContractHelpers/asSender"
import { contractEquals } from "../../utils/addressHelpers"
import { AMMSwapPool } from "../../utils/alexjs/AMMSwapPool"
import {
  Currency,
  DownstreamCurrency,
  WrappedCurrency,
} from "../../utils/alexjs/Currency"
import {
  currencyScale,
  getAssetIdentifierByCurrency,
  liquidityTokenPairs,
} from "../../utils/alexjs/currencyHelpers"
import { hasAny } from "../../utils/arrayHelpers"
import { TokenInfo } from "../../utils/models/TokenInfo"
import { AppEnvStore } from "../appEnvStore/AppEnvStore"
import AuthStore from "../authStore/AuthStore"
import CurrencyStore from "../currencyStore/CurrencyStore"
import { LazyValue } from "../LazyValue/LazyValue"
import { fetchBalanceForAccount } from "./AccountStore.services"
import AccountTransactions from "./AccountTransactions"

class AccountStore {
  constructor(
    readonly appEnv: Pick<AppEnvStore, "config$">,
    readonly authService: Pick<
      AuthStore,
      "stxAddress$" | "currentBlockHeight$" | "signOut"
    >,
    readonly currencyStore: Pick<
      CurrencyStore,
      | "allAssetIdentifiers$"
      | "getTokenInfoForAsset$"
      | "getTokenInfo$"
      | "ammPoolInfo"
    >,
  ) {
    makeObservable(this)
  }

  private balances = new LazyValue(
    () =>
      [
        this.authService.stxAddress$,
        this.authService.currentBlockHeight$,
      ] as const,
    async ([stxAddress]) => await fetchBalanceForAccount(stxAddress),
  )

  #getBalanceForAsset$ = createTransformer((assetIdentifier: string) => {
    if (
      assetIdentifier ===
        getAssetIdentifierByCurrency(DownstreamCurrency.STX) ||
      assetIdentifier === getAssetIdentifierByCurrency(Currency.W_STX)
    ) {
      const { balance, locked } = this.balances.value$.stx
      return (
        (Number(balance) - Number(locked)) /
        currencyScale(DownstreamCurrency.STX)
      )
    }
    const tokens = this.balances.value$.fungible_tokens
    const foundKey = Object.keys(tokens).find(k =>
      contractEquals(assetIdentifier, k),
    )
    if (foundKey == null) {
      return 0
    }
    const tokenInfo = this.currencyStore.getTokenInfoForAsset$(assetIdentifier)
    const scale = Math.pow(10, tokenInfo?.scale ?? 8)
    return Number(tokens[foundKey]?.balance ?? null) / scale
  })

  ammPoolTokenBalance = memoize(
    (poolId: number) =>
      new LazyValue(
        () => this.authService.stxAddress$,
        async stxAddress =>
          asSender(stxAddress)
            .contract("token-amm-swap-pool")
            .func("get-balance")
            .call({ "token-id": poolId, who: stxAddress })
            .then(unwrapResponse)
            .then(a => a / 1e8),
      ),
  )

  getBalance$ = createTransformer((currency: Currency) => {
    if (AMMSwapPool.isPoolToken(currency)) {
      const [tokenX, tokenY] = liquidityTokenPairs(currency)
      const poolId = this.currencyStore.ammPoolInfo(
        tokenX as AMMSwapPool.SwapTokens,
        tokenY as AMMSwapPool.SwapTokens,
        AMMSwapPool.getFactor(currency),
      ).value$["pool-id"]
      return this.ammPoolTokenBalance(poolId).value$
    }
    return this.#getBalanceForAsset$(
      getAssetIdentifierByCurrency(WrappedCurrency.safeUnwrap(currency)),
    )
  })

  @computed get allTokensWithBalanceForBalanceBar$(): {
    token: TokenInfo
    balance: number
  }[] {
    const { allSwapCoins, pools } = this.appEnv.config$
    const res = [...allSwapCoins, ...pools]
      .filter(currency => this.getBalance$(currency) > 0)
      .map(currency => ({
        token: this.currencyStore.getTokenInfo$(currency),
        balance: this.getBalance$(currency),
      }))

    if (hasAny(res)) {
      return res
    } else {
      return [
        { token: this.currencyStore.getTokenInfo$(Currency.W_STX), balance: 0 },
      ]
    }
  }

  async updateBalance(): Promise<void> {
    await this.balances.triggerUpdate()
  }

  @computed({ keepAlive: true }) get transactions$(): AccountTransactions {
    return new AccountTransactions(this.authService.stxAddress$)
  }

  async signOut(): Promise<void> {
    await this.transactions$.clear()
    await this.authService.signOut()
  }
}

export default AccountStore
