import clsx from "clsx"
import { FC } from "react"
import { Card } from "../../../components/Card"
import { CollapsableCard } from "../../../components/CollapsableCard/CollapsableCard"
import { NavLink } from "../../../components/NavLink"
import { PercentNumber } from "../../../components/PercentNumber"
import { Spensor } from "../../../components/Spensor"
import { TitledColumnCell } from "../../../components/TitledColumnCell"
import { TokenCount } from "../../../components/TokenCount"
import { TokenIcon } from "../../../components/TokenIcon"
import { usePathGenerator } from "../../../routes/routes"
import { readResource, SuspenseResource } from "../../../utils/SuspenseResource"
import { ListButton } from "../../Farm/components/ListButton"
import {
  getListItemContainerClassName,
  getListSectionClassName,
  ListSection,
} from "../../Farm/components/ListContainer"
import { ReactComponent as IntoIcon } from "../../Farm/components/MyFarmsPanel/into.svg"
import { TruncateTokenName } from "../../Farm/components/TruncateTokenName"
import { AllPoolListInfo, LiquidityTipIcon } from "./AllPoolsSection"

interface MyPoolListInfo extends AllPoolListInfo {
  myBalance: number
}

const MyPoolListItem: FC<{
  className?: string
  pool: MyPoolListInfo
}> = props => {
  return (
    <Card
      className={clsx(
        "flex flex-row gap-4 group hover:bg-black/10 hover:!bg-none hover:!backdrop-filter-none hover:!drop-shadow-none active:bg-black/20 active:!bg-none active:!backdrop-filter-none active:!drop-shadow-none",
        getListItemContainerClassName({}),
        props.className,
      )}
      boxClassName={"px-4 py-2"}
      roundClassName={"rounded-lg"}
    >
      <ListSection
        className={"flex flex-row items-center gap-2.5"}
        type={"head"}
      >
        <TokenIcon token={props.pool.poolTokenInfo} />

        <TruncateTokenName
          className={"text-base leading-6 font-medium text-gray-200 truncate"}
          token={props.pool.poolTokenInfo}
        />
      </ListSection>

      <TitledColumnCell
        className={clsx(getListSectionClassName({}), "self-start")}
        title={"My LP"}
        detail={
          <TokenCount
            token={props.pool.poolTokenInfo}
            count={props.pool.myBalance}
          />
        }
      />

      <TitledColumnCell
        className={clsx(getListSectionClassName({}), "self-start")}
        title={
          <div className="flex items-center gap-1">
            Fee Rebate
            <LiquidityTipIcon />
          </div>
        }
        detail={
          props.pool.apr ? <PercentNumber number={props.pool.apr} /> : "N/A"
        }
      />

      <ListSection className={"flex items-center justify-center"}>
        <ListButton className={"invisible group-hover:visible"}>
          View
        </ListButton>
      </ListSection>

      <ListSection className={"flex items-center justify-end"} type={"tail"}>
        <IntoIcon className={"opacity-40"} />
      </ListSection>
    </Card>
  )
}

export default MyPoolListItem

export const MyPoolsSection: FC<{
  pools: SuspenseResource<MyPoolListInfo[]>
}> = props => {
  const gen = usePathGenerator()
  return (
    <Spensor>
      {() => {
        const pools = readResource(props.pools)
        if (pools.length === 0) {
          return null
        }
        return (
          <CollapsableCard title={"My Liquidity"}>
            <div className="w-full flex flex-col gap-3">
              {pools.map(pool => (
                <NavLink
                  key={pool.poolTokenInfo.displayName}
                  to={gen.poolDetail(pool.poolTokenInfo)}
                >
                  <MyPoolListItem className="w-full" pool={pool} />
                </NavLink>
              ))}
            </div>
          </CollapsableCard>
        )
      }}
    </Spensor>
  )
}
