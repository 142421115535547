import clsx from "clsx"
import { FC, useState } from "react"
import { DetailCollapseButton } from "../../../../../components/button/DetailCollapseButton"
import { CardPlate } from "../../../../../components/Card"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../../components/InfoList"
import { TextTokenCount } from "../../../../../components/RichTokenCount"
import { Spensor } from "../../../../../components/Spensor"
import { formatTimeCountdownText } from "../../../../../components/TimeCountdown/TimeCountdownText"
import { TipIcon } from "../../../../../components/TipIcon/TipIcon"
import { TokenCount } from "../../../../../components/TokenCount"
import { TokenName } from "../../../../../components/TokenName"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import {
  readResource,
  SuspenseResource,
} from "../../../../../utils/SuspenseResource"
import {
  UnitPriceDescribe,
  UnitPriceDescribeProps,
} from "../../../../Spot/components/SpotForm/UnitPriceDescribe"

export const WrapBridgePanelDetails: FC<
  {
    className?: string
    fees: SuspenseResource<number>
    feeToken: SuspenseResource<TokenInfo>
    wrapCostedMilliseconds: SuspenseResource<number>
  } & UnitPriceDescribeProps
> = props => {
  const [showDetail, setShowDetail] = useState(false)

  return (
    <div className={clsx("flex flex-col gap-4", props.className)}>
      <div className={clsx("flex items-center")}>
        <span className="flex items-center">
          <DetailCollapseButton
            show={showDetail}
            onClick={() => setShowDetail(s => !s)}
          />
          &nbsp;
          <span className={"text-sm text-gray-100"}>Details</span>
        </span>
        <UnitPriceDescribe
          className={"ml-auto"}
          fromToken={props.fromToken}
          toToken={props.toToken}
          fromToExchangeRate={props.fromToExchangeRate}
          fromUnitUSD={props.fromUnitUSD}
          toFromExchangeRate={props.toFromExchangeRate}
          toUnitUSD={props.toUnitUSD}
        />
      </div>

      {showDetail && (
        <CardPlate>
          <InfoList direction={"column"} listItemDirection={"row-responsive"}>
            <InfoListItem>
              <InfoListItemTitle>
                Fee{" "}
                <TipIcon
                  inline
                  tip={
                    <>
                      <p>For each wrap and unwrap a 0.3% fee is paid</p>
                      <ul className={"list-disc list-inside"}>
                        <li>0.2% to Tokensoft</li>
                        <li>0.1% to ALEX Protocol</li>
                        <Spensor>
                          {() => (
                            <li>
                              Minimum unwrap Fees is{" "}
                              <TextTokenCount
                                token={readResource(props.feeToken)}
                                count={3}
                              />
                            </li>
                          )}
                        </Spensor>
                      </ul>
                    </>
                  }
                />
              </InfoListItemTitle>
              <InfoListItemDetail>
                <Spensor fallback={"-"}>
                  {() => (
                    <>
                      <TokenCount
                        token={readResource(props.feeToken)}
                        count={readResource(props.fees)}
                      />{" "}
                      <TokenName token={readResource(props.feeToken)} />
                    </>
                  )}
                </Spensor>
              </InfoListItemDetail>
            </InfoListItem>

            <InfoListItem>
              <InfoListItemTitle>Time to wrap</InfoListItemTitle>
              <InfoListItemDetail>
                <Spensor>
                  {() => (
                    <>
                      {formatTimeCountdownText(
                        new Date(
                          Date.now() +
                            readResource(props.wrapCostedMilliseconds),
                        ),
                      )}
                    </>
                  )}
                </Spensor>
              </InfoListItemDetail>
            </InfoListItem>
          </InfoList>
        </CardPlate>
      )}
    </div>
  )
}
