import {
  MempoolTransaction,
  Transaction,
} from "@stacks/stacks-blockchain-api-types"
import { decodeSpecifiedContractCallTransaction } from "clarity-codegen"
import { FC, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { PageStackPage } from "../../components/PageStack/PageStackPage"
import { PrimaryPageTitle } from "../../components/PageStack/PrimaryPageTitle"
import { AlexContracts } from "../../generated/smartContract/contracts_Alex"
import { fetchTransaction } from "../../utils/alexjs/fetchTransaction"

const Content: FC = () => {
  const txId = useParams<"txId">().txId
  if (txId == null) {
    throw new Error("txId is required, please use it as /_tx/:txId")
  }
  const [tx, setTx] = useState<Transaction | MempoolTransaction>()
  useEffect(() => {
    fetchTransaction(txId)
      .then(setTx)
      .catch(e => alert(e.message))
  }, [txId])
  if (tx?.tx_type !== "contract_call") {
    return (
      <div>
        <textarea>{JSON.stringify(tx)}</textarea>
      </div>
    )
  }
  const contractName = tx.contract_call.contract_id.split(".")[1]!
  const functionName = tx.contract_call.function_name
  try {
    const result = decodeSpecifiedContractCallTransaction(
      AlexContracts,
      contractName as "age000-governance-token",
      functionName as "mint",
      tx as any,
    )
    return (
      <pre className="whitespace-pre-wrap">
        {contractName}.{functionName}
        {"\n\n"}
        {JSON.stringify(result, null, 2)}
      </pre>
    )
  } catch (e) {
    return (
      <div>
        Unknown contract call {contractName}.{functionName}
      </div>
    )
  }
}

const TransactionDetailPage: FC = () => {
  return (
    <PageStackPage
      title={<PrimaryPageTitle>Transaction Detail</PrimaryPageTitle>}
    >
      <Content />
    </PageStackPage>
  )
}

export default TransactionDetailPage
