import { FC } from "react"
import { CoFarmAutoHarvestRowData } from "../../../../store/transformerGroups/farm/coFarmAutoHarvest"
import { AllTokenInfos } from "../../../../types"
import { BaseCellContainer } from "../../cells/BaseCellContainer"
import { OneLineAmountCell } from "../../cells/OneLineAmountCell"
import { OneLineCurrencyCell } from "../../cells/OneLineCurrencyCell"
import { BaseRow } from "../BaseRow"

export const CoFarmAutoHarvestRow: FC<{
  rowData: CoFarmAutoHarvestRowData
  allTokenInfos: AllTokenInfos
}> = ({ rowData, allTokenInfos }) => (
  <BaseRow
    rowData={rowData}
    currencyCell={
      <OneLineCurrencyCell
        allTokenInfos={allTokenInfos}
        toCurrencyAmounts={rowData.rewardCurrencyAmounts}
      />
    }
    amountCell={
      <OneLineAmountCell
        allTokenInfos={allTokenInfos}
        toCurrencyAmounts={rowData.rewardCurrencyAmounts}
      />
    }
    tradeInfoCell={
      <BaseCellContainer>
        <p className="whitespace-nowrap">Reward Harvest</p>
      </BaseCellContainer>
    }
  />
)
