import { gql } from "@urql/core"
import { unwrapResponse } from "clarity-codegen"
import { Observable } from "rxjs"
import { CONTRACT_DEPLOYER } from "../../config"
import {
  FetchAllTokenInfosQuery,
  FetchLatestPricesQuery,
  FetchLatestPricesQueryVariables,
  TokenFragmentFragment,
} from "../../generated/graphql/graphql.generated"
import {
  asSender,
  contractAddr,
} from "../../generated/smartContractHelpers/asSender"
import { currentExpiry } from "../../pages/Pool/store/detail/PoolDetailStore.services"
import { AMMSwapPool } from "../../utils/alexjs/AMMSwapPool"
import { Currency, isCurrency } from "../../utils/alexjs/Currency"
import {
  isSimpleAlexPool,
  isYTPToken,
  LiquidityPoolToken,
  LiquidityToken,
  liquidityTokenPairs,
  yieldTokenForYTP,
} from "../../utils/alexjs/currencyHelpers"
import { gqlQuery } from "../../utils/graphqlHelpers"
import { TokenInfo } from "../../utils/models/TokenInfo"
import { fromUrqlSource } from "../../utils/Observable/fromUrqlSource"
import { isNotNull } from "../../utils/utils"

export async function getDxDyOnFixedWeightPool(
  fwp: LiquidityPoolToken,
): Promise<{
  x: LiquidityToken
  dx: number
  y: LiquidityToken
  dy: number
}> {
  const [tokenA, tokenB] = liquidityTokenPairs(fwp)
  const unit = 1000 * 1e8
  if (isYTPToken(fwp)) {
    const expiry = await currentExpiry(fwp)
    const response = await asSender(CONTRACT_DEPLOYER)
      .contract("yield-token-pool")
      .func("get-position-given-burn")
      .call({
        "yield-token": yieldTokenForYTP(fwp),
        token: unit,
        expiry,
      })
    const result = unwrapResponse(response)
    return {
      x: tokenA,
      y: tokenB,
      dx: result.dx / unit,
      dy: result["dy-act"] / unit,
    }
  }
  if (AMMSwapPool.isPoolToken(fwp)) {
    const response = await asSender(CONTRACT_DEPLOYER)
      .contract("amm-swap-pool")
      .func("get-position-given-burn")
      .call({
        factor: AMMSwapPool.getFactor(fwp),
        "token-x": tokenA,
        "token-y": tokenB,
        token: unit,
      })
    const { dx, dy } = unwrapResponse(response)
    return {
      x: tokenA,
      y: tokenB,
      dx: dx / unit,
      dy: dy / unit,
    }
  }
  if (isSimpleAlexPool(fwp)) {
    const response = await asSender(CONTRACT_DEPLOYER)
      .contract("simple-weight-pool-alex")
      .func("get-position-given-burn")
      .call({
        "token-x": tokenA,
        "token-y": tokenB,
        token: unit,
      })
    const { dx, dy } = unwrapResponse(response)
    return {
      x: tokenA,
      y: tokenB,
      dx: dx / unit,
      dy: dy / unit,
    }
  }
  const { dx, dy } = unwrapResponse(
    await asSender(CONTRACT_DEPLOYER)
      .contract("fixed-weight-pool-v1-01")
      .func("get-position-given-burn")
      .call({
        // tokenA, tokenB, 0.5e8, 0.5e8, unit
        "token-x": tokenA,
        "token-y": tokenB,
        "weight-x": 0.5e8,
        "weight-y": 0.5e8,
        token: unit,
      }),
  )
  return {
    x: tokenA,
    y: tokenB,
    dx: dx / unit,
    dy: dy / unit,
  }
}

export function fetchLatestPrices(): Observable<
  Partial<{
    [currency in Currency]: number
  }>
> {
  return fromUrqlSource(
    gqlQuery(
      gql<FetchLatestPricesQuery, FetchLatestPricesQueryVariables>`
        query FetchLatestPrices {
          laplace_current_token_price {
            avg_price_usd
            token
          }
          laplace_latest_yield_token_pool(
            order_by: { expiry: desc }
            limit: 1
          ) {
            yield_token
            yield_token_price
          }
        }
      `,
      {},
    ),
    res => {
      const result: Partial<{ [currency in Currency]: number }> = {}
      res.data.laplace_current_token_price.forEach(
        ({ avg_price_usd, token }) => {
          if (avg_price_usd != null && isCurrency(token)) {
            result[token] = avg_price_usd
          }
        },
      )
      res.data.laplace_latest_yield_token_pool.forEach(
        ({ yield_token_price, yield_token }) => {
          if (yield_token === contractAddr(Currency.YIELD_ALEX)) {
            result[Currency.YIELD_ALEX] =
              (yield_token_price / 1e8) * result[Currency.ALEX]!
          }
        },
      )
      return result
    },
  )
}

export const TokenFragment = gql`
  fragment TokenFragment on Token {
    id
    name
    description
    precision
    scale
    iconsCollection {
      items {
        url
      }
    }
  }
`

export function parseTokenFragment(item: TokenFragmentFragment): TokenInfo {
  if (item.id == null) {
    throw new Error(`currency ${item.name} has no id`)
  }
  return {
    id: item.id,
    displayName: item.name ?? "Untitled",
    icons: item.iconsCollection?.items
      .map(i => i?.url)
      .filter(isNotNull) as any,
    description: item.description ?? undefined,
    precision: item.precision ?? undefined,
    scale: item.scale ?? undefined,
  }
}

export function getTokensFromCMS(): Observable<Array<TokenInfo>> {
  return fromUrqlSource(
    gqlQuery<FetchAllTokenInfosQuery>(gql`
      ${TokenFragment}
      query FetchAllTokenInfos {
        tokenCollection {
          items {
            ...TokenFragment
          }
        }
      }
    `),
    ({ data }) =>
      data
        .tokenCollection!.items.filter(isNotNull)
        .map(parseTokenFragment)
        .filter(a => isCurrency(a.id)),
  )
}
