import { createContext, FC, PropsWithChildren, useContext } from "react"
import { useAuthStore } from "../../../stores/authStore/useAuthStore"
import { useChainStore } from "../../../stores/chainStore/useChainStore"
import { useCurrencyStore } from "../../../stores/currencyStore/useCurrencyStore"
import { useCreation } from "../../../utils/reactHelpers/useCreation"
import { LockdropDetailStore } from "./LockdropDetailStore"

const LockdropDetailStoreContext = createContext<LockdropDetailStore | null>(
  null,
)

export const LockdropDetailStoreProvider: FC<
  PropsWithChildren<{
    urlSlug: string
  }>
> = ({ urlSlug, children }) => {
  const currencyStore = useCurrencyStore()
  const chainStore = useChainStore()
  const authStore = useAuthStore()

  const store = useCreation(
    () =>
      new LockdropDetailStore(urlSlug, currencyStore, chainStore, authStore),
    [urlSlug, currencyStore, chainStore, authStore],
  )
  return (
    <LockdropDetailStoreContext.Provider value={store}>
      {children}
    </LockdropDetailStoreContext.Provider>
  )
}

export const useLockdropDetailStore = (): LockdropDetailStore => {
  const store = useContext(LockdropDetailStoreContext)
  if (store == null) {
    throw new Error(
      "useLockdropDetailStore must be used within a LockdropDetailStoreProvider",
    )
  }
  return store
}
