import { combineLatestWith, from, map, Observable, of, switchMap } from "rxjs"
import { components } from "../../../../../generated/stxdx/types"
import { isNotNull } from "../../../../../utils/utils"
import {
  Order,
  OrderListOrderType,
} from "../../../components/OrderbookList/types"
import { OrderDirection, StxDxOrderStatus } from "../../../components/types"
import { StxDxMarket } from "../../OrderbookStore.service/OrderbookStore.service"
import {
  getOrderHistory,
  ServerOrderData,
} from "../OrderbookMyHistoryModule.service"
import {
  getOrderbookSummaryFor,
  refreshOrderHistorySignal,
} from "../_/orderbookHelpers"

const getAllOpenOrders = (
  market: StxDxMarket,
  jwt: undefined | string,
): Observable<ServerOrderData[]> => {
  if (jwt === undefined) {
    return of([])
  }

  return refreshOrderHistorySignal(jwt).pipe(
    switchMap(() =>
      from(
        getOrderHistory({
          market,
          auth: jwt,
          status: StxDxOrderStatus.Matching,
        }),
      ),
    ),
  )
}

export const fetchOrderbook = (
  market: StxDxMarket,
  jwt: undefined | string,
): Observable<{
  latestPrice: number
  buy: Order.OrderbookOrder[]
  sell: Order.OrderbookOrder[]
}> => {
  return getOrderbookSummaryFor(market).pipe(
    combineLatestWith(getAllOpenOrders(market, jwt)),
    map(([summary, openOrders]) => {
      const { bids, asks, last_price } = summary

      const normalizedBids = bids
        .map(normalizeOrderBookPriceSize)
        .filter(isNotNull)
      const normalizedAsks = asks
        .map(normalizeOrderBookPriceSize)
        .filter(isNotNull)

      const largestTotalPriceInBids = Math.max(
        ...normalizedBids.map(b => b.price * b.size),
      )
      const largestTotalPriceInAsks = Math.max(
        ...normalizedAsks.map(b => b.price * b.size),
      )
      return {
        latestPrice: Number(last_price),
        buy: normalizedBids.map(o =>
          transformOrderFromNormalizedOrderBookPriceSize(
            o,
            "buy",
            largestTotalPriceInBids,
            openOrders,
          ),
        ),
        sell: normalizedAsks.map(o =>
          transformOrderFromNormalizedOrderBookPriceSize(
            o,
            "sell",
            largestTotalPriceInAsks,
            openOrders,
          ),
        ),
      }
    }),
  )
}

interface NormalizedOrderBookPriceSize {
  price: number
  size: number
}

function normalizeOrderBookPriceSize(
  record: components["schemas"]["OrderBookPriceSize"],
): null | NormalizedOrderBookPriceSize {
  const price = Number(record.price)
  const size = Number(record.size)
  if (Number.isNaN(price) || Number.isNaN(size)) {
    return null
  }
  return { price, size }
}

function transformOrderFromNormalizedOrderBookPriceSize(
  record: NormalizedOrderBookPriceSize,
  direction: OrderDirection,
  largestTotalPriceInRecords: number,
  openOrders: ServerOrderData[],
): Order.OrderbookOrder {
  const { price, size } = record
  const priceTokenCountTotal = price * size
  return {
    type: OrderListOrderType.OrderbookOrders,
    isBelongsToCurrentUser: openOrders.some(
      o => o.priceTokenCountPerTradeToken === price,
    ),
    tradeTokenCount: size,
    priceTokenCountPerTradeToken: price,
    priceTokenCountTotal,
    volumePercentage: priceTokenCountTotal / largestTotalPriceInRecords,
    orderDirection: direction,
  }
}
