import { FungibleConditionCode } from "@stacks/transactions"
import { unwrapResponse } from "clarity-codegen"
import { CONTRACT_DEPLOYER } from "../../../config"
import { asSender } from "../../../generated/smartContractHelpers/asSender"
import { AMMSwapPool } from "../../../utils/alexjs/AMMSwapPool"
import { SwappableCurrency } from "../../../utils/alexjs/currencyHelpers"
import { AlexVault, transfer } from "../../../utils/alexjs/postConditions"

export async function getLiquidityProviderFee(
  tokenX: SwappableCurrency,
  tokenY: SwappableCurrency,
  ammPools: AMMSwapPool.PoolTokens[],
): Promise<number> {
  const ammRoute = AMMSwapPool.getRoute(tokenX, tokenY, ammPools)
  if (ammRoute.length === 0) {
    const result = await asSender(CONTRACT_DEPLOYER)
      .contract("swap-helper-v1-03")
      .func("fee-helper")
      .call({
        "token-x": tokenX,
        "token-y": tokenY,
      })
      .then(unwrapResponse)
    return result / 1e8
  }
  if (ammRoute.length === 1) {
    const result = await asSender(CONTRACT_DEPLOYER)
      .contract("amm-swap-pool")
      .func("fee-helper")
      .call({
        "token-x": tokenX,
        "token-y": tokenY,
        factor: AMMSwapPool.getFactor(ammRoute[0]!.pool),
      })
      .then(unwrapResponse)
    return result / 1e8
  }
  if (ammRoute.length === 2) {
    const result = await asSender(CONTRACT_DEPLOYER)
      .contract("amm-swap-pool")
      .func("fee-helper-a")
      .call({
        "token-x": tokenX,
        "token-y": ammRoute[0]!.neighbour,
        "token-z": ammRoute[1]!.neighbour,
        "factor-x": AMMSwapPool.getFactor(ammRoute[0]!.pool),
        "factor-y": AMMSwapPool.getFactor(ammRoute[1]!.pool),
      })
      .then(unwrapResponse)
    return result / 1e8
  }
  if (ammRoute.length === 3) {
    const result = await asSender(CONTRACT_DEPLOYER)
      .contract("amm-swap-pool")
      .func("fee-helper-b")
      .call({
        "token-x": tokenX,
        "token-y": ammRoute[0]!.neighbour,
        "token-z": ammRoute[1]!.neighbour,
        "token-w": ammRoute[2]!.neighbour,
        "factor-x": AMMSwapPool.getFactor(ammRoute[0]!.pool),
        "factor-y": AMMSwapPool.getFactor(ammRoute[1]!.pool),
        "factor-z": AMMSwapPool.getFactor(ammRoute[2]!.pool),
      })
      .then(unwrapResponse)
    return result / 1e8
  }
  if (ammRoute.length === 4) {
    const result = await asSender(CONTRACT_DEPLOYER)
      .contract("amm-swap-pool")
      .func("fee-helper-c")
      .call({
        "token-x": tokenX,
        "token-y": ammRoute[0]!.neighbour,
        "token-z": ammRoute[1]!.neighbour,
        "token-w": ammRoute[2]!.neighbour,
        "token-v": ammRoute[3]!.neighbour,
        "factor-x": AMMSwapPool.getFactor(ammRoute[0]!.pool),
        "factor-y": AMMSwapPool.getFactor(ammRoute[1]!.pool),
        "factor-z": AMMSwapPool.getFactor(ammRoute[2]!.pool),
        "factor-w": AMMSwapPool.getFactor(ammRoute[3]!.pool),
      })
      .then(unwrapResponse)
    return result / 1e8
  }
  throw new Error("Too many AMM pools in route")
}

export const getYAmountFromOneX = async (
  stxAddress: string,
  tokenX: SwappableCurrency,
  tokenY: SwappableCurrency,
  fromAmount: number,
  ammPools: AMMSwapPool.PoolTokens[],
): Promise<number> => {
  const amount = Math.floor(fromAmount * 1e8)
  const ammRoute = AMMSwapPool.getRoute(tokenX, tokenY, ammPools)
  if (ammRoute.length === 0) {
    return await asSender(stxAddress)
      .contract("swap-helper-v1-03")
      .func("get-helper")
      .call({
        "token-x": tokenX,
        "token-y": tokenY,
        dx: amount,
      })
      .then(unwrapResponse)
      .then(a => a / amount)
  }
  if (ammRoute.length === 1) {
    return await asSender(stxAddress)
      .contract("amm-swap-pool")
      .func("get-helper")
      .call({
        "token-x": tokenX,
        "token-y": ammRoute[0]!.neighbour,
        dx: amount,
        factor: AMMSwapPool.getFactor(ammRoute[0]!.pool),
      })
      .then(unwrapResponse)
      .then(a => a / amount)
  }
  if (ammRoute.length === 2) {
    return await asSender(stxAddress)
      .contract("amm-swap-pool")
      .func("get-helper-a")
      .call({
        "token-x": tokenX,
        "token-y": ammRoute[0]!.neighbour,
        "token-z": ammRoute[1]!.neighbour,
        "factor-x": AMMSwapPool.getFactor(ammRoute[0]!.pool),
        "factor-y": AMMSwapPool.getFactor(ammRoute[1]!.pool),
        dx: amount,
      })
      .then(unwrapResponse)
      .then(a => a / amount)
  }
  if (ammRoute.length === 3) {
    return await asSender(stxAddress)
      .contract("amm-swap-pool")
      .func("get-helper-b")
      .call({
        "token-x": tokenX,
        "token-y": ammRoute[0]!.neighbour,
        "token-z": ammRoute[1]!.neighbour,
        "token-w": ammRoute[2]!.neighbour,
        "factor-x": AMMSwapPool.getFactor(ammRoute[0]!.pool),
        "factor-y": AMMSwapPool.getFactor(ammRoute[1]!.pool),
        "factor-z": AMMSwapPool.getFactor(ammRoute[2]!.pool),
        dx: amount,
      })
      .then(unwrapResponse)
      .then(a => a / amount)
  }
  if (ammRoute.length === 4) {
    return await asSender(stxAddress)
      .contract("amm-swap-pool")
      .func("get-helper-c")
      .call({
        "token-x": tokenX,
        "token-y": ammRoute[0]!.neighbour,
        "token-z": ammRoute[1]!.neighbour,
        "token-w": ammRoute[2]!.neighbour,
        "token-v": ammRoute[3]!.neighbour,
        "factor-x": AMMSwapPool.getFactor(ammRoute[0]!.pool),
        "factor-y": AMMSwapPool.getFactor(ammRoute[1]!.pool),
        "factor-z": AMMSwapPool.getFactor(ammRoute[2]!.pool),
        "factor-w": AMMSwapPool.getFactor(ammRoute[3]!.pool),
        dx: amount,
      })
      .then(unwrapResponse)
      .then(a => a / amount)
  }
  throw new Error("Too many AMM pools in route")
}

export async function runSpot(
  stxAddress: string,
  currencyX: SwappableCurrency,
  currencyY: SwappableCurrency,
  fromAmount: number,
  minDy: number,
  middleSteps: SwappableCurrency[],
  ammPools: AMMSwapPool.PoolTokens[],
): Promise<string> {
  const ammRoute = AMMSwapPool.getRoute(currencyX, currencyY, ammPools)
  if (ammRoute.length === 0) {
    return await asSender(stxAddress)
      .contract("swap-helper-v1-03")
      .func("swap-helper")
      .call(
        {
          "token-x-trait": currencyX,
          "token-y-trait": currencyY,
          dx: fromAmount * 1e8,
          "min-dy": minDy * 1e8,
        },
        [
          transfer(
            stxAddress,
            currencyX,
            fromAmount,
            FungibleConditionCode.LessEqual,
          ),
          ...middleSteps.flatMap(middle => [
            transfer(AlexVault, middle, 0, FungibleConditionCode.GreaterEqual),
            transfer(stxAddress, middle, 0, FungibleConditionCode.GreaterEqual),
          ]),
          transfer(
            AlexVault,
            currencyY,
            minDy,
            FungibleConditionCode.GreaterEqual,
          ),
        ],
      )
      .then(a => a.txId)
  }
  if (ammRoute.length === 1) {
    return await asSender(stxAddress)
      .contract("amm-swap-pool")
      .func("swap-helper")
      .call(
        {
          "token-x-trait": currencyX,
          "token-y-trait": ammRoute[0]!.neighbour,
          factor: AMMSwapPool.getFactor(ammRoute[0]!.pool),
          dx: fromAmount * 1e8,
          "min-dy": minDy * 1e8,
        },
        [
          transfer(
            stxAddress,
            currencyX,
            fromAmount,
            FungibleConditionCode.LessEqual,
          ),
          transfer(
            AlexVault,
            currencyY,
            minDy,
            FungibleConditionCode.GreaterEqual,
          ),
        ],
      )
      .then(a => a.txId)
  }
  if (ammRoute.length === 2) {
    return await asSender(stxAddress)
      .contract("amm-swap-pool")
      .func("swap-helper-a")
      .call(
        {
          "token-x-trait": currencyX,
          "token-y-trait": ammRoute[0]!.neighbour,
          "token-z-trait": ammRoute[1]!.neighbour,
          "factor-x": AMMSwapPool.getFactor(ammRoute[0]!.pool),
          "factor-y": AMMSwapPool.getFactor(ammRoute[1]!.pool),
          dx: fromAmount * 1e8,
          "min-dz": minDy * 1e8,
        },
        [
          transfer(
            stxAddress,
            currencyX,
            fromAmount,
            FungibleConditionCode.LessEqual,
          ),
          transfer(
            AlexVault,
            ammRoute[0]!.neighbour,
            0,
            FungibleConditionCode.GreaterEqual,
          ),
          transfer(
            stxAddress,
            ammRoute[0]!.neighbour,
            0,
            FungibleConditionCode.GreaterEqual,
          ),
          transfer(
            AlexVault,
            currencyY,
            minDy,
            FungibleConditionCode.GreaterEqual,
          ),
        ],
      )
      .then(a => a.txId)
  }
  if (ammRoute.length === 3) {
    return await asSender(stxAddress)
      .contract("amm-swap-pool")
      .func("swap-helper-b")
      .call(
        {
          "token-x-trait": currencyX,
          "token-y-trait": ammRoute[0]!.neighbour,
          "token-z-trait": ammRoute[1]!.neighbour,
          "token-w-trait": ammRoute[2]!.neighbour,
          "factor-x": AMMSwapPool.getFactor(ammRoute[0]!.pool),
          "factor-y": AMMSwapPool.getFactor(ammRoute[1]!.pool),
          "factor-z": AMMSwapPool.getFactor(ammRoute[2]!.pool),
          dx: fromAmount * 1e8,
          "min-dw": minDy * 1e8,
        },
        [
          transfer(
            stxAddress,
            currencyX,
            fromAmount,
            FungibleConditionCode.LessEqual,
          ),
          transfer(
            AlexVault,
            ammRoute[0]!.neighbour,
            0,
            FungibleConditionCode.GreaterEqual,
          ),
          transfer(
            stxAddress,
            ammRoute[0]!.neighbour,
            0,
            FungibleConditionCode.GreaterEqual,
          ),
          transfer(
            AlexVault,
            ammRoute[1]!.neighbour,
            0,
            FungibleConditionCode.GreaterEqual,
          ),
          transfer(
            stxAddress,
            ammRoute[1]!.neighbour,
            0,
            FungibleConditionCode.GreaterEqual,
          ),
          transfer(
            AlexVault,
            currencyY,
            minDy,
            FungibleConditionCode.GreaterEqual,
          ),
        ],
      )
      .then(a => a.txId)
  }
  if (ammRoute.length === 4) {
    return await asSender(stxAddress)
      .contract("amm-swap-pool")
      .func("swap-helper-c")
      .call(
        {
          "token-x-trait": currencyX,
          "token-y-trait": ammRoute[0]!.neighbour,
          "token-z-trait": ammRoute[1]!.neighbour,
          "token-w-trait": ammRoute[2]!.neighbour,
          "token-v-trait": ammRoute[3]!.neighbour,
          "factor-x": AMMSwapPool.getFactor(ammRoute[0]!.pool),
          "factor-y": AMMSwapPool.getFactor(ammRoute[1]!.pool),
          "factor-z": AMMSwapPool.getFactor(ammRoute[2]!.pool),
          "factor-w": AMMSwapPool.getFactor(ammRoute[3]!.pool),
          dx: fromAmount * 1e8,
          "min-dv": minDy * 1e8,
        },
        [
          transfer(
            stxAddress,
            currencyX,
            fromAmount,
            FungibleConditionCode.LessEqual,
          ),
          transfer(
            AlexVault,
            ammRoute[0]!.neighbour,
            0,
            FungibleConditionCode.GreaterEqual,
          ),
          transfer(
            stxAddress,
            ammRoute[0]!.neighbour,
            0,
            FungibleConditionCode.GreaterEqual,
          ),
          transfer(
            AlexVault,
            ammRoute[1]!.neighbour,
            0,
            FungibleConditionCode.GreaterEqual,
          ),
          transfer(
            stxAddress,
            ammRoute[1]!.neighbour,
            0,
            FungibleConditionCode.GreaterEqual,
          ),
          transfer(
            AlexVault,
            ammRoute[2]!.neighbour,
            0,
            FungibleConditionCode.GreaterEqual,
          ),
          transfer(
            stxAddress,
            ammRoute[2]!.neighbour,
            0,
            FungibleConditionCode.GreaterEqual,
          ),
          transfer(
            AlexVault,
            currencyY,
            minDy,
            FungibleConditionCode.GreaterEqual,
          ),
        ],
      )
      .then(a => a.txId)
  }

  throw new Error("Too many AMM pools in route")
}

export async function getRoute(
  from: SwappableCurrency,
  to: SwappableCurrency,
  pools: AMMSwapPool.PoolTokens[],
): Promise<SwappableCurrency[]> {
  const ammRoute = AMMSwapPool.getRoute(from, to, pools)
  if (ammRoute.length > 0) {
    return [from, ...ammRoute.map(a => a.neighbour)]
  }
  const result = await asSender(CONTRACT_DEPLOYER)
    .contract("swap-helper-v1-03")
    .func("route-helper")
    .call({
      "token-x": from,
      "token-y": to,
    })
    .then(unwrapResponse)
  return result.map(x => x as SwappableCurrency)
}
