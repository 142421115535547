/**
 * Decimal adjustment of a number.
 *
 * from {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/floor#decimal_adjustment}
 *
 * @param {String} type  The type of adjustment.
 * @param {Number} value The number.
 * @param {Integer} exp The exponent (the 10 logarithm of the adjustment base).
 * @returns {Number} The adjusted value.
 */
export function decimalAdjust(
  type: "ceil" | "floor" | "round",
  value: number,
  exp?: number,
): number {
  // If the exp is undefined or zero...
  if (exp == null || +exp === 0) return Math[type](value)

  // If the value is not a number or the exp is not an integer...
  if (isNaN(value) || exp % 1 !== 0) return NaN

  // Shift (transform `111` to `Math.[method].(Number(111e+-exp))`)
  let splitValue = value.toString().split("e")
  value = Math[type](
    Number(
      splitValue[0] +
        "e" +
        (splitValue[1] ? Number(splitValue[1]) - exp : -exp),
    ),
  )

  // Shift back, same as above
  splitValue = value.toString().split("e")
  return Number(
    splitValue[0] + "e" + (splitValue[1] ? Number(splitValue[1]) + exp : exp),
  )
}

export function closeTo(a: number, b: number, epsilon = 0.0001): boolean {
  return Math.abs(a - b) < epsilon
}

/**
 * Math.pow(0.1, 4)          // -> 0.00010000000000000002
 * Math.pow(10, -4)          // -> 0.00009999999999999999
 * getPrecisionEdgeNumber(4) // -> 0.0001
 */
export function getPrecisionFloor(precision: number): number {
  return 1 / Math.pow(10, precision)
}

/**
 * simplifyWithUnit(1_000_000_000) // => [1, 'm']
 */
export function simplifyWithUnit(
  num: number,
  fractionDigits = 2,
): [formatted: number, unit: string] {
  if (num < 1_000) {
    return [Number(num.toFixed(fractionDigits)), ""]
  }

  if (num < 1_000_000) {
    return [Number((num / 1_000).toFixed(fractionDigits)), "k"]
  }

  if (num < 1_000_000_000) {
    return [Number((num / 1_000_000).toFixed(fractionDigits)), "m"]
  }

  if (num < 1_000_000_000_000) {
    return [Number((num / 1_000_000_000).toFixed(fractionDigits)), "b"]
  }

  if (num < 1_000_000_000_000_000) {
    return [Number((num / 1_000_000_000_000).toFixed(fractionDigits)), "t"]
  }

  if (num < 1_000_000_000_000_000_000) {
    return [Number((num / 1_000_000_000_000_000).toFixed(fractionDigits)), "p"]
  }

  if (num < 1_000_000_000_000_000_000_000) {
    return [
      Number((num / 1_000_000_000_000_000_000).toFixed(fractionDigits)),
      "e",
    ]
  }

  throw new Error(`Unsupported number ${num}`)
}
export namespace simplifyWithUnit {
  // JavaScript does not support normal numbers greater than 1_000_000_000_000_000_000_000
  export const maxNumber = 1_000_000_000_000_000_000_000
}

/**
 * Sometimes we need to round up/down when displaying numbers
 * But we also need to avoid the [floating point math issue](https://0.30000000000000004.com/)
 *
 * BigNumber.js can also do this work, but it might take too much compute
 * resource, and this function might be called in a significant frequency in
 * single render, so we need to use a more efficient implementation
 *
 * This is the benchmark: https://jsbench.me/4dl6hzi1c5/1, (the BigNumber.js
 * solution is 78.15% slower than current solution in my test (Firefox 103.0,
 * Macbook Pro M1 Pro)
 *
 * For example:
 * 0.07 * 10 ** 5 // => 7000.000000000001
 * 0.0041 * 10 ** 5 // => 410.00000000000006
 *
 * Because 0.07 is stored as `0.070000000000000006661338147750939...`, we can
 * get this result by code `0.07.toPrecision(32)`
 *
 * See also: https://github.com/camsong/blog/issues/9
 */
export function roundNumber(
  num: number,
  options: {
    /**
     * @default 2
     */
    precision?: number
    /**
     * @default Math.round
     */
    rounder?: (n: number) => number
  },
): number {
  const precision = options.precision ?? 2
  const rounder = options.rounder ?? Math.round

  /**
   * the precision in ALEX contract is 1e8, so we can expect the maximum
   * precision is 8
   */
  const expectedMaximumPrecision = 8

  if (precision > expectedMaximumPrecision) {
    throw new Error(`[roundNumber] precision is too large: ${precision}`)
  }

  const scale = Math.pow(10, precision)

  const scaleUpResult = rounder(
    Number((num * scale).toFixed(expectedMaximumPrecision + 2 - precision)),
  )

  return scaleUpResult / scale
}

export const pickDecimalPart = (
  num: number,
  precision: number,
): undefined | string => {
  /**
   * The idea is transform number to string, and return the decimal part
   *
   * Because of `String(0.00000001) // => 1e-8`, it's not a good way; so we
   * have chosen the `toFixed` method, but it's not perfect:
   *
   * * `(0.000000008).toFixed(50)` // => '0.00000000**8**00000000000000049825273166223885135117655'
   * * `(0.000000009).toFixed(50)` // => '0.00000000**8**99999999999999952655855742873411418081275'
   * * `(0.000000008).toFixed(8)` // => '0.00000001'
   *
   * It will round the number, and it still exists the floating point issue.
   */

  /**
   * the precision in ALEX contract is 1e8, so we can expect the maximum
   * precision is 8
   */
  const expectedMaximumPrecision = 8

  if (precision > expectedMaximumPrecision) {
    throw new Error(`[isPrecisionExceed] precision is too large: ${precision}`)
  }

  const isInt = num === parseInt(String(num), 10)
  if (isInt) return undefined

  const rawNumStr = num.toFixed(
    // to avoid the rounding issue, we need to add 1 to the precision
    precision + 1,
  )

  const [, decimals] = rawNumStr.split(".")
  if (decimals == null) return undefined

  const isAllZero = decimals.split("").every(char => char === "0")
  if (isAllZero) {
    return decimals.slice(0, precision)
  }

  return (
    decimals
      // remove the trailing zeros, because `(0.1).toFixed(3) // => 0.100`
      .replace(/0*$/, "")
      .slice(0, precision)
  )
}
