import { FC } from "react"
import { AlertDialogModalContent } from "../../../components/dialog/AlertDialogModalContent"
import { Modal } from "../../../components/Modal"
import { NavLink } from "../../../components/NavLink"
import { Spensor } from "../../../components/Spensor"
import { useWrapBridgeStore } from "../store/useWrapBridgeStore"

const WiredWhiteListCheck: FC = () => {
  const store = useWrapBridgeStore()
  return (
    <Spensor>
      {() => {
        if (store.wrapForm.isWalletInWhitelist.value$) {
          return null
        }
        return (
          <Modal visible={true}>
            <AlertDialogModalContent
              titleText="Your address is not whitelisted"
              message={
                <span>
                  Please visit{" "}
                  <NavLink
                    to="https://orderbook.alexlab.co/"
                    className="text-blue-600"
                  >
                    orderbook.alexlab.co
                  </NavLink>{" "}
                  to sign up
                </span>
              }
              confirmText="Sign Up"
              onConfirm={() => {
                window.open("https://orderbook.alexlab.co/", "_blank")
              }}
            />
          </Modal>
        )
      }}
    </Spensor>
  )
}

export default WiredWhiteListCheck
