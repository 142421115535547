import { useAccountStore } from "../../../../stores/accountStore/useAccountStore"
import { useAppEnvStore } from "../../../../stores/appEnvStore/useAppEnvStore"
import { useAuthStore } from "../../../../stores/authStore/useAuthStore"
import { useChainStore } from "../../../../stores/chainStore/useChainStore"
import { useCurrencyStore } from "../../../../stores/currencyStore/useCurrencyStore"
import { createStore } from "../../../../utils/createStore"
import { FCC } from "../../../../utils/reactHelpers/types"
import { useCreation } from "../../../../utils/reactHelpers/useCreation"
import ManualStakeStore, { stakeStoreCache } from "./ManualStakeStore"
import { StakingToken } from "./ManualStakeStore.service"

const StakeStoreContext = createStore<ManualStakeStore>("ManualStakeStore")

export const ManualStakeStoreProvider: FCC<{ token: StakingToken }> = props => {
  const appEnvStore = useAppEnvStore()
  const chainStore = useChainStore()
  const authStore = useAuthStore()
  const currencyStore = useCurrencyStore()
  const accountStore = useAccountStore()
  const store = useCreation(
    () =>
      stakeStoreCache[props.token] ??
      new ManualStakeStore(
        props.token,
        appEnvStore,
        authStore,
        currencyStore,
        accountStore,
        chainStore,
      ),
    [
      accountStore,
      appEnvStore,
      authStore,
      chainStore,
      currencyStore,
      props.token,
    ],
  )
  return (
    <StakeStoreContext.Provider store={store}>
      {props.children}
    </StakeStoreContext.Provider>
  )
}

export const useManualStakeStore = StakeStoreContext.useStore
