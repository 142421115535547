import {
  booleanT,
  bufferT,
  defineContract,
  numberT,
  optionalT,
  principalT,
  responseSimpleT,
  stringT,
  tupleT,
} from "../smartContractHelpers/codegenImport"

export const bridgeHelper = defineContract({
  "bridge-helper": {
    "set-approved-chain": {
      input: [
        {
          name: "chain-details",
          type: tupleT({ "buff-length": numberT, name: stringT }),
        },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-approved-recipient": {
      input: [
        { name: "recipient", type: principalT },
        { name: "approved", type: booleanT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-approved-token": {
      input: [
        { name: "token", type: principalT },
        { name: "approved", type: booleanT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-contract-owner": {
      input: [{ name: "owner", type: principalT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "transfer-to-unwrap": {
      input: [
        { name: "token-trait", type: principalT },
        { name: "amount-in-fixed", type: numberT },
        { name: "recipient", type: principalT },
        { name: "chain-id", type: numberT },
        { name: "settle-address", type: bufferT },
      ],
      output: responseSimpleT(
        tupleT({ chain: stringT, "settle-address": bufferT }),
      ),
      mode: "public",
    },
    "transfer-to-wrap": {
      input: [
        { name: "token-trait", type: principalT },
        { name: "amount-in-fixed", type: numberT },
        { name: "recipient", type: principalT },
        { name: "chain-id", type: numberT },
        { name: "tx-id", type: bufferT },
      ],
      output: responseSimpleT(tupleT({ chain: stringT, "tx-id": bufferT })),
      mode: "public",
    },
    "buff-slice": {
      input: [
        { name: "bytes", type: bufferT },
        { name: "start", type: numberT },
        { name: "end", type: numberT },
      ],
      output: bufferT,
      mode: "readonly",
    },
    "get-approved-chain-or-fail": {
      input: [{ name: "chain-id", type: numberT }],
      output: responseSimpleT(
        tupleT({ "buff-length": numberT, name: stringT }),
      ),
      mode: "readonly",
    },
    "get-contract-owner": {
      input: [],
      output: responseSimpleT(principalT),
      mode: "readonly",
    },
    "approved-chains": {
      input: numberT,
      output: optionalT(tupleT({ "buff-length": numberT, name: stringT })),
      mode: "mapEntry",
    },
    "approved-recipients": {
      input: principalT,
      output: optionalT(booleanT),
      mode: "mapEntry",
    },
    "approved-tokens": {
      input: principalT,
      output: optionalT(booleanT),
      mode: "mapEntry",
    },
    "approved-wrappers": {
      input: principalT,
      output: optionalT(booleanT),
      mode: "mapEntry",
    },
  },
} as const)
