import { FC } from "react"
import { GradientFilledButton } from "../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { CardModalContent } from "../../../components/CardModal/CardModal"
import { withClassName } from "../../../utils/reactHelpers/withClassName"

export const SwitchRegisterMultiTimesModalContent: FC<{
  onConfirm?: () => void
}> = props => (
  <CardModalContent className={"w-[480px] text-gray-200"} title={"IMPORTANT!"}>
    <p className={"mb-3"}>
      If you have validated and sent out an active pending transaction.{" "}
      <YellowStrong>Do Not Validate again</YellowStrong>!
    </p>

    <p className={"mb-4"}>
      As for long-pending transactions, this is due to high congestion on the
      Stacks Mempool at the moment. There is no need to use a very high gas fee
      repeatedly.
    </p>

    <GradientFilledButton className={"w-full"} onClick={props.onConfirm}>
      OK
    </GradientFilledButton>
  </CardModalContent>
)

export const WhiteStrong = withClassName("text-gray-200 font-bold", "span")

export const YellowStrong = withClassName("text-yellow-400 font-bold", "span")
