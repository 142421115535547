import { FC } from "react"
import {
  CardDivider,
  CardInset,
  CardInsetDescription,
  CardInsetTitle,
} from "../../../../components/Card"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../components/InfoList"
import { NavLink } from "../../../../components/NavLink"
import { TextTokenCount } from "../../../../components/RichTokenCount"
import { TokenName } from "../../../../components/TokenName"
import { TokenInfo } from "../../../../utils/models/TokenInfo"

export const MyLotteryPanel$NotJoin: FC<{
  alexToken: TokenInfo
  alexTokenBalance: number
  swapAlexLink: string
}> = props => {
  return (
    <>
      <CardInset className={"flex flex-col gap-6"}>
        <div className={"text-center"}>
          <CardInsetTitle>Not Join</CardInsetTitle>
          <CardInsetDescription>
            My <TokenName token={props.alexToken} /> Balance:{" "}
            <TextTokenCount
              token={props.alexToken}
              count={props.alexTokenBalance}
            />
            .{" "}
            <NavLink
              className={"text-blue-600"}
              target={"_blank"}
              to={props.swapAlexLink}
            >
              Swap More &gt;
            </NavLink>
          </CardInsetDescription>
        </div>

        <CardDivider className={"w-full"} />

        <InfoList
          className={"m-auto"}
          direction={"row"}
          listItemDirection={"column"}
        >
          <InfoListItem className={"gap-2 items-center"}>
            <InfoListItemDetail className={"text-5xl"}>0</InfoListItemDetail>
            <InfoListItemTitle>My Lottery Tickets</InfoListItemTitle>
          </InfoListItem>
        </InfoList>
      </CardInset>
    </>
  )
}
