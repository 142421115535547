import { FC } from "react"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { CardModalContent } from "../../../../components/CardModal/CardModal"
import { NoteParagraph } from "../../../../components/NoteParagraph/NoteParagraph"
import { Spensor } from "../../../../components/Spensor"
import { TokenCount } from "../../../../components/TokenCount"
import {
  BlockGroup,
  BlockGroupDownArrowIcon,
} from "../../../../components/TokenInput/BlockGroup"
import { TokenBlock } from "../../../../components/TokenInput/TokenBlock"
import { TokenName } from "../../../../components/TokenName"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import {
  readResource,
  SuspenseResource,
} from "../../../../utils/SuspenseResource"
import {
  AddStakeSectionInfoList,
  AddStakeSectionInfoListItem,
} from "../../components/AddStakeSectionInfoList"

export interface ConfirmStakeModalContentProps {
  alexToken: TokenInfo
  alexTokenCount: number
  autoAlexToken: TokenInfo
  autoAlexTokenCount: number
  stakePrice: SuspenseResource<number>
  startStakeCycleNumber: number
  onConfirm?: () => void
  onClose?: () => void
}

export const ConfirmStakeModalContent: FC<
  ConfirmStakeModalContentProps
> = props => (
  <CardModalContent
    onClose={props.onClose}
    title={"Confirm Auto Staking"}
    className={"flex flex-col gap-4"}
    width={480}
  >
    <BlockGroup
      icon={<BlockGroupDownArrowIcon />}
      firstBlock={
        <TokenBlock
          tokens={[{ token: props.alexToken, count: props.alexTokenCount }]}
        />
      }
      secondBlock={
        <TokenBlock
          tokens={[
            { token: props.autoAlexToken, count: props.autoAlexTokenCount },
          ]}
        />
      }
    />

    <AddStakeSectionInfoList>
      <AddStakeSectionInfoListItem
        title={"Stake Price"}
        detail={
          <>
            1 <TokenName token={props.autoAlexToken} /> ={" "}
            <Spensor fallback={"-"}>
              {() => (
                <TokenCount
                  token={props.alexToken}
                  count={readResource(props.stakePrice)}
                />
              )}
            </Spensor>{" "}
            <TokenName token={props.alexToken} />
          </>
        }
      />
      <AddStakeSectionInfoListItem
        title={"Start Cycle"}
        detail={`Cycle#${props.startStakeCycleNumber}`}
      />
    </AddStakeSectionInfoList>

    <NoteParagraph>
      <TokenName token={props.autoAlexToken} /> auto-stakes your{" "}
      <TokenName token={props.alexToken} />, growing your rewards automatically{" "}
      over time. You can swap <TokenName token={props.autoAlexToken} /> any time{" "}
      with other assets.
    </NoteParagraph>

    <GradientFilledButton className={"mt-3.5"} onClick={props.onConfirm}>
      Confirm
    </GradientFilledButton>
  </CardModalContent>
)
