import { FC, ReactNode } from "react"
import { HeadlessButton } from "../../../../../components/button/HeadlessButton"
import { LinkedButton } from "../../../../../components/button/LinkedButton"
import { GradientFilledButton } from "../../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { CardSubTitle } from "../../../../../components/Card"
import { TokenName } from "../../../../../components/TokenName"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { TokenInfoPresets } from "../../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { LaunchingStatus } from "../../../store/LaunchPadContractStore"
import { IDOProgress } from "../../IDOProgress/IDOProgress"
import { IDOSucceedNumbersList } from "../../IDOSucceedNumbersList"
import { IDOSuccessIcon } from "../../IDOSuccessIcon/IDOSuccessIcon"
import {
  TokenIDORaiseNumbers,
  TokenIDOStatus,
  TokenIDOSuccessInfo,
} from "../../types"
import { BottomActionsSection } from "./utilComponents/BottomActionsSection"
import { IDODetailSidePanelFrame } from "./utilComponents/IDODetailSidePanelFrame"

export interface IDODetailSidePanel$SucceedProps {
  className?: string
  idoToken: TokenInfo
  priceToken: TokenInfo
  idoNumbers: TokenIDORaiseNumbers
  idoStatus: TokenIDOStatus
  idoSuccessSummary: TokenIDOSuccessInfo
  titleRightSide?: ReactNode
  dashboardLink?: null | string
  prepareAPowerLink?: null | string
}

export const IDODetailSidePanel$Succeed: FC<
  IDODetailSidePanel$SucceedProps
> = props => {
  return (
    <IDODetailSidePanelFrame
      className={props.className}
      titleRightSide={props.titleRightSide}
    >
      <div className={"flex flex-col items-center gap-4"}>
        <IDOSuccessIcon />

        <CardSubTitle>
          $<TokenName token={props.idoToken} /> Token launch has been finalised
        </CardSubTitle>
      </div>

      <IDOSucceedNumbersList
        className={"w-full"}
        idoToken={props.idoToken}
        tokenIDOSuccessSummary={props.idoSuccessSummary}
      />

      <IDOProgress
        priceToken={props.priceToken}
        idoNumbers={props.idoNumbers}
        isFinished={props.idoStatus.type === LaunchingStatus.Finished}
      />

      <BottomActionsSection>
        {props.dashboardLink !== null && (
          <LinkedButton
            Variant={GradientFilledButton}
            link={props.dashboardLink}
          >
            IDO Dashboard
          </LinkedButton>
        )}
        {props.prepareAPowerLink !== null && (
          <LinkedButton
            className={"text-blue-600"}
            Variant={HeadlessButton}
            link={props.prepareAPowerLink}
          >
            Prepare <TokenName token={TokenInfoPresets.APower} /> to join more
            IDO
          </LinkedButton>
        )}
      </BottomActionsSection>
    </IDODetailSidePanelFrame>
  )
}
