import {
  AMMSwapRoute,
  resolveAmmRoute,
} from "../../pages/Spot/store/ammRouteResolver"
import { assertNever } from "../types"
import { Currency } from "./Currency"
import {
  getAssetIdentifierByCurrency,
  SwappableCurrency,
} from "./currencyHelpers"

export namespace AMMSwapPool {
  export type SwapTokens = SwappableCurrency

  export const getRoute = (
    tokenX: SwappableCurrency,
    tokenY: SwappableCurrency,
    ammPools: PoolTokens[],
  ): AMMSwapRoute[] => resolveAmmRoute(tokenX, tokenY, ammPools)

  export const ammTokens = [
    Currency.AMM_SWAP_POOL_WXUSD_WUSDA,
    Currency.AMM_SWAP_POOL_WSTX_ALEX,
    Currency.AMM_SWAP_POOL_WSTX_XBTC,
    Currency.AMM_SWAP_POOL_ALEX_WBAN,
    Currency.AMM_SWAP_POOL_ALEX_WUSDA,
  ] as const
  export type PoolTokens = typeof ammTokens[number]
  export const isPoolToken = (token: string): token is PoolTokens =>
    ammTokens.includes(token as PoolTokens)

  export const fromXY = (
    x: Currency,
    y: Currency,
    factor: number,
  ): PoolTokens => {
    if (x === Currency.W_XUSD && y === Currency.W_USDA && factor === 0.0001e8) {
      return Currency.AMM_SWAP_POOL_WXUSD_WUSDA
    }
    throw new Error(`Invalid AMM token: ${x} ${y}`)
  }

  export function breakDown(
    poolToken: AMMSwapPool.PoolTokens,
  ): [
    Currency.W_STX | Currency.ALEX | Currency.W_XUSD,
    AMMSwapPool.SwapTokens,
  ] {
    switch (poolToken) {
      case Currency.AMM_SWAP_POOL_WXUSD_WUSDA:
        return [Currency.W_XUSD, Currency.W_USDA]
      case Currency.AMM_SWAP_POOL_WSTX_ALEX:
        return [Currency.W_STX, Currency.ALEX]
      case Currency.AMM_SWAP_POOL_WSTX_XBTC:
        return [Currency.W_STX, Currency.W_XBTC]
      case Currency.AMM_SWAP_POOL_ALEX_WBAN:
        return [Currency.ALEX, Currency.W_BANANA]
      case Currency.AMM_SWAP_POOL_ALEX_WUSDA:
        return [Currency.ALEX, Currency.W_USDA]
      default:
        assertNever(poolToken)
    }
  }

  export function getFactor(poolToken: PoolTokens): number {
    switch (poolToken) {
      case Currency.AMM_SWAP_POOL_WXUSD_WUSDA:
        return 0.0001e8
      case Currency.AMM_SWAP_POOL_WSTX_ALEX:
      case Currency.AMM_SWAP_POOL_WSTX_XBTC:
      case Currency.AMM_SWAP_POOL_ALEX_WBAN:
      case Currency.AMM_SWAP_POOL_ALEX_WUSDA:
        return 1e8
      default:
        assertNever(poolToken)
    }
  }

  export const getAssetIdentifier = (poolToken: PoolTokens): string =>
    getAssetIdentifierByCurrency(poolToken).split("/")[0]!
}
