import { FC } from "react"
import { AllTokenInfos, CurrencyAmount } from "../../../types"
import { ExchangeRatio } from "../ExchangeRatio"
import { BaseCellContainer } from "./BaseCellContainer"

export const OneLineExchangeRatioCell: FC<{
  confirmed: boolean
  fromCurrencyAmount: CurrencyAmount
  toCurrencyAmount: CurrencyAmount
  allTokenInfos: AllTokenInfos
}> = props => (
  <BaseCellContainer>
    {props.confirmed ? (
      <ExchangeRatio
        allTokenInfos={props.allTokenInfos}
        fromCurrencyAmount={
          props.fromCurrencyAmount as CurrencyAmount.Confirmed
        }
        toCurrencyAmount={props.toCurrencyAmount as CurrencyAmount.Confirmed}
      />
    ) : (
      "Wait to Confirm"
    )}
  </BaseCellContainer>
)
