import { FC, useCallback, useState } from "react"
import { AlertLayoutAsBanner } from "../../../components/alert/AlertLayoutAsBanner"
import { NavLink } from "../../../components/NavLink"
import { Spensor } from "../../../components/Spensor"
import { TokenIcon } from "../../../components/TokenIcon"
import { TokenName } from "../../../components/TokenName"
import { usePathGenerator } from "../../../routes/routes"
import { TokenInfoPresets } from "../../../utils/TokenInfoPresets/TokenInfoPresets"

export const WiredAPowerAlert: FC<{
  className?: string
}> = props => {
  const g = usePathGenerator()

  const [, rerenderComponent] = useState(0)

  const storageKey = `Launchpad-WiredAPowerBanner-isClosed`
  const isClosed = localStorage.getItem(storageKey)
  const onClose = useCallback(() => {
    localStorage.setItem(storageKey, "true")
    rerenderComponent(n => n + 1)
  }, [storageKey])

  return isClosed ? null : (
    <Spensor>
      {() => (
        <AlertLayoutAsBanner
          className={props.className}
          icon={<TokenIcon token={TokenInfoPresets.APower} size={24} />}
          onClose={onClose}
        >
          You must first earn <TokenName token={TokenInfoPresets.APower} />,
          either through <NavLink to={g.stake()}>$ALEX Staking</NavLink> or{" "}
          <NavLink to={g.farmList()}>Yield Farming</NavLink>, in order to
          validate IDO tickets and participate.
        </AlertLayoutAsBanner>
      )}
    </Spensor>
  )
}
