import { Dictionary } from "lodash"
import { FC } from "react"
import { CardModalContent } from "../../../../components/CardModal/CardModal"
import { LoadingIndicator } from "../../../../components/LoadingIndicator/LoadingIndicator"
import { NavLink } from "../../../../components/NavLink"
import { Spensor } from "../../../../components/Spensor"
import { TokenCount } from "../../../../components/TokenCount"
import { TokenName } from "../../../../components/TokenName"
import { trunc } from "../../../../utils/addressHelpers"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { withClassName } from "../../../../utils/reactHelpers/withClassName"
import {
  readResource,
  SuspenseResource,
} from "../../../../utils/SuspenseResource"
import { RoundPrize } from "../types"
import { CopperCrown } from "../_/CopperCrown"
import { GoldenCrown } from "../_/GoldenCrown"
import { SilverCrown } from "../_/SilverCrown"
import { ReactComponent as ExplorerIcon } from "./explorer.svg"

export interface WinnerListModalContentProps {
  onClose?: () => void
  lotteryId: number
  winnerList: SuspenseResource<{
    prizes: RoundPrize[]
    winners: Dictionary<string[]>
  }>
  tokenInfo: TokenInfo
  explorerLinks: SuspenseResource<string[]>
}

export const WinnerListModalContent: FC<
  WinnerListModalContentProps
> = props => (
  <CardModalContent
    width="80vw"
    minHeight="80vh"
    title={`Winner List - Round #${props.lotteryId}`}
    onClose={props.onClose}
  >
    <Spensor fallback={<LoadingIndicator className="m-auto" size={72} />}>
      {() => (
        <div className="overflow-y-auto min-h-full">
          <PrizeTable
            prizes={readResource(props.winnerList).prizes}
            winners={readResource(props.winnerList).winners}
            tokenInfo={props.tokenInfo}
            explorerLinks={readResource(props.explorerLinks)}
          />
        </div>
      )}
    </Spensor>
  </CardModalContent>
)

const prizeIcons = [GoldenCrown, SilverCrown, CopperCrown]

const PrizeTable: FC<{
  prizes: RoundPrize[]
  winners: Dictionary<string[]>
  explorerLinks: string[]
  tokenInfo: TokenInfo
}> = props => (
  <section
    className="grid place-content-stretch"
    style={{
      gridTemplateColumns:
        "max-content minmax(max-content, 1fr) repeat(2, minmax(max-content, 1.5fr)) max-content",
      gridTemplateRows: "repeat(4, max-content)",
    }}
  >
    <TableCell className="col-span-2 text-sm leading-5 font-normal text-gray-500">
      Prize
    </TableCell>
    <TableCell className="col-span-3 text-sm leading-5 font-normal text-gray-500">
      Winner Address
    </TableCell>
    {([3, 2, 1] as const).map(i => {
      const prize = props.prizes[3 - i] // TODO: refactor this
      const winner = props.winners[i]
      const explorerLink = props.explorerLinks[3 - i]
      if (!prize || !winner || !explorerLink) {
        throw new Error("Prize or winner or explorerLink is null")
      }
      return (
        <PrizeRow
          key={i}
          prize={prize}
          winner={winner}
          explorerLink={explorerLink}
          tokenInfo={props.tokenInfo}
          Icon={prizeIcons[3 - i]!} // TODO: refactor this
        />
      )
    })}
  </section>
)

const PrizeRow: FC<{
  prize: RoundPrize
  winner: string[]
  tokenInfo: TokenInfo
  Icon: FC<any>
  explorerLink: string
}> = ({ prize, winner, tokenInfo, Icon, explorerLink }) => {
  const middle = Math.ceil(winner.length / 2)
  return (
    <>
      <TableCell className="flex flex-row gap-x-2.5">
        <Icon />
        <span>{prize.type} Prize</span>
      </TableCell>
      <TableCell className="flex flex-col">
        <span>
          <TokenCount count={prize.prizeTokenCount} token={tokenInfo} />
          &nbsp;
          <TokenName token={tokenInfo} />
        </span>
        <span>{prize.winningTicketsCount} Winning Tickets</span>
      </TableCell>
      <TableCell className="flex flex-col">
        {winner.slice(0, middle).map((address, i) => (
          <span key={i}>{trunc(address, { head: 5, tail: 4 })}</span>
        ))}
      </TableCell>
      <TableCell className="flex flex-col">
        {winner.slice(middle, winner.length).map((address, i) => (
          <span key={i}>{trunc(address, { head: 5, tail: 4 })}</span>
        ))}
      </TableCell>
      <TableCell>
        <NavLink
          to={explorerLink}
          className="flex flex-row flex-nowrap gap-x-2.5 items-center justify-center"
        >
          <ExplorerIcon />
          <span className="text-sm leading-5 font-normal text-blue-600">
            View in explorer
          </span>
        </NavLink>
      </TableCell>
    </>
  )
}

const TableCell = withClassName("border-b border-gray-500/30 py-4 pr-8", "div")
