import { action, computed, makeObservable, observable } from "mobx"
import { createTransformer } from "mobx-utils"
import { ConfirmTransactionStore } from "../../../../stores/confirmTransactionDialogStore/ConfirmTransactionStore"
import { SlippageStore } from "../../../../stores/slippageStore/SlippageStore"
import { Currency } from "../../../../utils/alexjs/Currency"
import type { LiquidityToken } from "../../../../utils/alexjs/currencyHelpers"
import { liquidityTokenPairs } from "../../../../utils/alexjs/currencyHelpers"
import { asyncAction, runAsyncAction } from "../../../../utils/asyncAction"
import { closeTo } from "../../../../utils/numberHelpers"
import { Result } from "../../../../utils/Result"
import { AddLiquidityFormError, AddLiquidityFormErrorType } from "../types"
import { addLiquidity } from "./AddLiquidityModule.services"
import PoolDetailStore from "./PoolDetailStore"

export type AddLiquidityFormData = {
  amountA: number
  dxDy: number
  slippage: number
}

export class AddLiquidityModule {
  constructor(readonly store: PoolDetailStore) {
    makeObservable(this)
  }

  slippageStore = new SlippageStore()

  @computed get tokenA(): LiquidityToken {
    return liquidityTokenPairs(this.store.poolToken)[0]
  }

  @computed get tokenB(): LiquidityToken {
    return liquidityTokenPairs(this.store.poolToken)[1]
  }

  @observable amountAToAdd = 0

  @action clear(): void {
    this.amountAToAdd = 0
  }

  @computed get dxDy$(): number {
    const { dx, dy } = this.store.currencyStore.fetchPoolBreakdown$(
      this.store.poolToken,
    )
    return dx / dy
  }

  @computed get amountBToAdd$(): number {
    const dxDy = this.dxDy$
    const amount = this.amountAToAdd / dxDy
    // we need this for the token info component
    return Math.floor(amount * 1e8) / 1e8
  }

  @action setAmountAToAdd(amount: number): void {
    this.amountAToAdd = amount
  }

  @action setAmountBToAdd(amount: number): void {
    this.amountAToAdd = amount * this.dxDy$
  }

  balance$ = createTransformer((token: LiquidityToken) =>
    this.store.accountStore.getBalance$(token),
  )

  @computed get tokenAEstUSD$(): number {
    return this.store.currencyStore.getPrice$(this.tokenA) * this.amountAToAdd
  }

  @computed get tokenBEstUSD$(): number {
    return this.store.currencyStore.getPrice$(this.tokenB) * this.amountBToAdd$
  }

  @computed get maxAmountBWithInSlippage$(): number {
    return (
      this.store.accountStore.getBalance$(this.tokenB) *
      (1 - this.slippageStore.slippagePercentage)
    )
  }

  @computed get tokenBInRiskOfSlippage$(): boolean {
    return this.amountBToAdd$ > this.maxAmountBWithInSlippage$
  }

  @action adjustAmountToAvoidSlippage(): void {
    this.setAmountAToAdd(this.maxAmountBWithInSlippage$ * this.dxDy$)
  }

  @computed get formData$(): Result<
    AddLiquidityFormData,
    AddLiquidityFormError
  > {
    if (this.amountAToAdd === 0) {
      return Result.error<AddLiquidityFormError>({
        type: AddLiquidityFormErrorType.EmptyTokenCount,
      })
    }
    const insufficientA = this.balance$(this.tokenA) < this.amountAToAdd
    const insufficientB = this.balance$(this.tokenB) < this.amountBToAdd$
    if (insufficientA || insufficientB) {
      return Result.error<AddLiquidityFormError>({
        type: AddLiquidityFormErrorType.InsufficientTokenBalance,
        tokenA: insufficientA,
        tokenB: insufficientB,
      })
    }
    let dxDy: number
    try {
      dxDy = this.dxDy$
    } catch (e) {
      return Result.error<AddLiquidityFormError>({
        type: AddLiquidityFormErrorType.LoadingDxDy,
      })
    }
    return Result.ok({
      amountA: this.amountAToAdd,
      slippage: this.slippageStore.slippagePercentage,
      dxDy,
    })
  }

  @computed get isMaxSTX$(): boolean {
    const aIsMax =
      this.tokenA === Currency.W_STX &&
      closeTo(this.balance$(Currency.W_STX), this.amountAToAdd)
    const bIsMax =
      this.tokenB === Currency.W_STX &&
      closeTo(this.balance$(Currency.W_STX), this.amountBToAdd$)
    return aIsMax || bIsMax
  }

  @observable confirming = false
  addTransaction = new ConfirmTransactionStore()

  @asyncAction
  async addLiquidity(
    form: AddLiquidityFormData,
    run = runAsyncAction,
  ): Promise<void> {
    this.confirming = false
    try {
      const txId = await run(
        addLiquidity(
          this.store.authStore.stxAddress$,
          this.store.poolToken,
          form.amountA,
          form.dxDy,
          form.slippage,
        ),
      )
      this.addTransaction.successRunning(txId)
    } catch (e) {
      this.addTransaction.errorRunning(e as Error)
    }
  }
}
