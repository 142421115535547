import { FC } from "react"
import { GradientFilledButton } from "../../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteFilledButton } from "../../../../../components/button/variants/WhiteFilledButton"
import { CardPlate } from "../../../../../components/Card"
import { CardModalContent } from "../../../../../components/CardModal/CardModal"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../../components/InfoList"
import { NoteParagraph } from "../../../../../components/NoteParagraph/NoteParagraph"
import { TipIcon } from "../../../../../components/TipIcon/TipIcon"
import { TokenExchangeRoute } from "../../../../../components/TokenExchangeRoute"
import { BlockTopTitleLine } from "../../../../../components/TokenInput/Block"
import {
  BlockGroup,
  BlockGroupDownArrowIcon,
} from "../../../../../components/TokenInput/BlockGroup"
import { TokenBlock } from "../../../../../components/TokenInput/TokenBlock"
import { TokenName } from "../../../../../components/TokenName"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { readResource } from "../../../../../utils/SuspenseResource"
import { DepositInfoListContent } from "../DepositInfoListContent"

export interface ConfirmRolloverModalContentProps {
  depositToken: TokenInfo
  prevCycleYieldToken: TokenInfo
  nextCycleYieldToken: TokenInfo
  prevCycleYieldTokenCount: number
  nextCycleYieldTokenCount: number
  tokenSwapRoute: TokenInfo[]
  expirationDate: Date
  expirationBlockHeight: number
  estimateApr: number
  estimateClaimableValue: number
  slippage: number
  liquidityProviderFee: number
  onEditSlippage?: () => void
  onConfirm?: () => void
  onClose?: () => void
}

export const ConfirmRolloverModalContent: FC<
  ConfirmRolloverModalContentProps
> = props => (
  <CardModalContent
    onClose={props.onClose}
    title={"Confirm Rollover"}
    className={"flex flex-col gap-4"}
    width={480}
  >
    <BlockGroup
      firstBlock={
        <TokenBlock
          tokens={[
            {
              token: props.prevCycleYieldToken,
              count: props.prevCycleYieldTokenCount,
            },
          ]}
        />
      }
      icon={<BlockGroupDownArrowIcon />}
      secondBlock={
        <TokenBlock
          topArea={
            <BlockTopTitleLine>
              Rollover (receive){" "}
              <TipIcon
                inline
                tip={
                  "Rollover extends your deposit period through the next reward cycle."
                }
              />
            </BlockTopTitleLine>
          }
          tokens={[
            {
              token: props.nextCycleYieldToken,
              count: props.nextCycleYieldTokenCount,
            },
          ]}
        />
      }
    />

    <CardPlate className={"flex flex-col gap-2"}>
      <InfoList listItemDirection={"row-responsive"}>
        <InfoListItem>
          <InfoListItemTitle>Route</InfoListItemTitle>
          <InfoListItemDetail>
            <TokenExchangeRoute tokens={readResource(props.tokenSwapRoute)} />
          </InfoListItemDetail>
        </InfoListItem>

        <DepositInfoListContent
          depositToken={props.depositToken}
          expirationBlockHeight={props.expirationBlockHeight}
          expirationDate={props.expirationDate}
          estimateAbsoluteInterest={props.estimateApr}
          estimateClaimableValue={props.estimateClaimableValue}
          liquidityProviderFee={props.liquidityProviderFee}
          slippage={props.slippage}
          onEditSlippage={props.onEditSlippage}
        />
      </InfoList>
    </CardPlate>

    <NoteParagraph>
      Your <TokenName token={props.nextCycleYieldToken} /> will roll over to the
      next deposit period (est 7 days), to continue generating yield.
    </NoteParagraph>

    <div className={"mt-3.5 flex flex-row gap-2.5"}>
      <WhiteFilledButton className={"flex-1"} onClick={props.onClose}>
        Cancel
      </WhiteFilledButton>

      <GradientFilledButton className={"flex-1"} onClick={props.onConfirm}>
        Confirm
      </GradientFilledButton>
    </div>
  </CardModalContent>
)
