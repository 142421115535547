import React, { FC, useCallback, useRef, useState } from "react"
import { usePathGenerator } from "../../../routes/routes"
import { suspenseResource } from "../../../utils/SuspenseResource"
import { SummaryBar } from "../components/SummaryBar"
import {
  WiredDailyTestNetworkSetupGuideModal,
  WiredDailyTestNetworkSetupGuideModalRefValue,
} from "../components/TestNetworkSetupGuideModalContent/WiredDailyTestNetworkSetupGuideModal"
import {
  TokenPairOption,
  TokenPairSelector,
} from "../components/TokenPairSelector"
import {
  allStxDxMarkets,
  StxDxMarket,
  stxDxTokenPairs,
} from "../store/OrderbookStore.service/OrderbookStore.service"
import { useOrderbookStore } from "../store/useOrderbookStore"

const WiredTokenPairSelector: FC = () => {
  const [query, setQuery] = useState<string | null>(null)
  const store = useOrderbookStore()
  const g = usePathGenerator()
  const currency = store.currency
  const getInfo = useCallback(
    (market: StxDxMarket): TokenPairOption => ({
      priceToken: currency.getTokenInfo$(stxDxTokenPairs(market).priceToken),
      tradeToken: currency.getTokenInfo$(stxDxTokenPairs(market).tradeToken),
      tradeTokenLastPrice: suspenseResource(
        () => store.info.currentPriceValue(market).value$,
      ),
      tradeToken24HourChangePercentage: suspenseResource(
        () => store.info.marketSummary(market).value$.price24hChangePercent,
      ),
      link: g.orderbookDetail(market),
    }),
    [currency, g, store.info],
  )
  return (
    <TokenPairSelector
      query={query}
      onQueryChange={setQuery}
      selectedOption={getInfo(store.market)}
      tokenPairOptions={allStxDxMarkets
        .map(getInfo)
        .filter(
          i =>
            !query ||
            (i.tradeToken.displayName + "/" + i.priceToken.displayName)
              .toLowerCase()
              .includes(query.toLowerCase()),
        )}
    />
  )
}

export const WiredSummaryBar: FC<{ className?: string }> = props => {
  const store = useOrderbookStore()

  const dialogRef = useRef<WiredDailyTestNetworkSetupGuideModalRefValue>(null)

  return (
    <>
      <SummaryBar
        className={props.className}
        tradeToken={store.currency.getTokenInfo$(store.trade.tradeToken)}
        priceToken={store.currency.getTokenInfo$(store.trade.priceToken)}
        currentPrice={suspenseResource(() => store.info.currentPrice$)}
        currentPriceDeltaPercentage={suspenseResource(
          () => store.info.currentPriceDeltaPercentage$,
        )}
        tokenPairSelector={<WiredTokenPairSelector />}
        onShowNetworkSetupGuide={() => {
          dialogRef.current?.show?.()
        }}
        priceChangeIn24Hours={suspenseResource(() => ({
          priceBefore24Hours: store.info.currentMarketSummary$.price24hChange,
          priceDeltaPercentage:
            store.info.currentMarketSummary$.price24hChangePercent,
        }))}
        priceHighestIn24Hours={suspenseResource(
          () => store.info.currentMarketSummary$.highestPrice,
        )}
        priceLowestIn24Hours={suspenseResource(
          () => store.info.currentMarketSummary$.lowestPrice,
        )}
        priceTokenVolumeIn24Hours={suspenseResource(
          () => store.info.currentMarketSummary$.amount24h,
        )}
        tradeTokenVolumeIn24Hours={suspenseResource(
          () => store.info.currentMarketSummary$.volume24h,
        )}
      />
      <WiredDailyTestNetworkSetupGuideModal ref={dialogRef} />
    </>
  )
}
