import { action, computed, makeObservable, observable } from "mobx"
import { EXPLORER_TX_URL } from "../../../../config"
import { sendRequest } from "../../../../generated/stxdxHelpers/stxdxApi"
import { LazyValue } from "../../../../stores/LazyValue/LazyValue"
import { SuspenseObservable } from "../../../../stores/SuspenseObservable"
import { isNotNull } from "../../../../utils/utils"
import {
  FundHistoryRecord,
  FundHistoryRecordDirection,
  FundHistoryRecordStatus,
  OpenOrderRecord,
  OrdersHistoryRecord,
  OrdersHistoryRecordStatus,
  StxDxOrderStatus,
  TradeHistoryRecord,
} from "../../components/types"
import { OrderbookStore } from "../OrderbookStore"
import { stxDxTokenPairs } from "../OrderbookStore.service/OrderbookStore.service"
import {
  getMyFundsHistory,
  getOrderHistory,
  getTradeHistory,
  ServerOrderData,
} from "./OrderbookMyHistoryModule.service"

export class OrderbookMyHistoryModule {
  constructor(readonly store: OrderbookStore) {
    makeObservable(this)
  }

  refresh = new SuspenseObservable<number>()

  @observable filterByCurrentMarket = false
  @action changeFilterByCurrentMarket = (newValue: boolean): void => {
    this.filterByCurrentMarket = newValue
  }

  #myOrders = new LazyValue(
    () =>
      [
        this.store.myInfo.authJWT$,
        this.filterByCurrentMarket ? this.store.market : undefined,
      ] as const,
    ([auth, market]) => getOrderHistory({ auth, market }),
  )

  #myTrades = new LazyValue(
    () =>
      [
        this.store.myInfo.authJWT$,
        this.filterByCurrentMarket ? this.store.market : undefined,
        this.store.chainStore.currentBlockHash$,
      ] as const,
    ([auth, market]) => getTradeHistory({ auth, market }),
  )

  #myFunds = new LazyValue(
    () =>
      [
        this.store.myInfo.authJWT$,
        this.store.myInfo.registeredUserId$,
        this.store.chainStore.currentBlockHash$,
      ] as const,
    ([auth, uid]) => getMyFundsHistory({ auth, uid }),
  )

  @computed get myPendingOrders$(): OpenOrderRecord[] {
    if (!this.store.myInfo.hasRegistered$) {
      return []
    }
    return this.#myOrders.value$
      .filter(o => o.status === StxDxOrderStatus.Matching)
      .map(
        order =>
          ({
            ...order,
            tradeToken: this.store.currency.getTokenInfo$(
              stxDxTokenPairs(order.market).tradeToken,
            ),
            priceToken: this.store.currency.getTokenInfo$(
              stxDxTokenPairs(order.market).priceToken,
            ),
            onCancel: async () => {
              await sendRequest(this.store.myInfo.authJWT$)(
                "OrderController_cancelOrder",
                {
                  path: {
                    order_hash: order.orderHash,
                  },
                },
              )
            },
          } as OpenOrderRecord),
      )
  }

  @computed get myNonPendingOrders$(): OrdersHistoryRecord[] {
    if (!this.store.myInfo.hasRegistered$) {
      return []
    }
    return this.#myOrders.value$
      .filter(o => o.status !== StxDxOrderStatus.Matching)
      .map(
        (order: ServerOrderData) =>
          ({
            ...order,
            executedTradeTokenCount:
              order.filledPercentage * order.expectedTradeTokenCount,
            status:
              //prettier-ignore
              order.status === StxDxOrderStatus.Expired ? OrdersHistoryRecordStatus.Expired :
                order.status === StxDxOrderStatus.Canceled ? OrdersHistoryRecordStatus.Cancelled :
                  OrdersHistoryRecordStatus.Normal,
            tradeToken: this.store.currency.getTokenInfo$(
              stxDxTokenPairs(order.market).tradeToken,
            ),
            priceToken: this.store.currency.getTokenInfo$(
              stxDxTokenPairs(order.market).priceToken,
            ),
          } as OrdersHistoryRecord),
      )
  }

  @computed get myTrades$(): TradeHistoryRecord[] {
    if (!this.store.myInfo.hasRegistered$) {
      return []
    }
    return this.#myTrades.value$.map(trade => ({
      ...trade,
      tradeToken: this.store.currency.getTokenInfo$(
        stxDxTokenPairs(trade.market).tradeToken,
      ),
      priceToken: this.store.currency.getTokenInfo$(
        stxDxTokenPairs(trade.market).priceToken,
      ),
    }))
  }

  @computed get myFunds$(): FundHistoryRecord[] {
    if (!this.store.myInfo.hasRegistered$) {
      return []
    }
    return this.#myFunds.value$
      .map(fund => {
        if (!(fund.assetId in this.store.info.allAssetIdMaps)) {
          return null
        }
        return {
          status: fund.status as FundHistoryRecordStatus,
          createdAt: fund.createdAt,
          direction: fund.type as FundHistoryRecordDirection,
          explorerLink: EXPLORER_TX_URL(fund.txId),
          transactionId: fund.txId,
          tokenCount: fund.amount,
          token: this.store.currency.getTokenInfo$(
            this.store.info.allAssetIdMaps[fund.assetId]!,
          ),
        }
      })
      .filter(isNotNull)
  }

  @action notifyMayOrderCreated(): void {
    // We re switching to user balance ws channel
    // We no longer need to keep track of new orders manually
    // this.refreshNewOrder.set(this.refreshNewOrder.read$ + 1)
  }
}
