import clsx from "clsx"
import { FC, ReactNode } from "react"
import { defineMessage, useIntl } from "react-intl"
import { Button } from "../../../../components/button/Button"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { OpacityButton } from "../../../../components/button/variants/OpacityButton"
import { WhiteFilledButton } from "../../../../components/button/variants/WhiteFilledButton"
import { CardDivider, CardPlate } from "../../../../components/Card"
import { Dropdown, DropdownItem } from "../../../../components/Dropdown"
import {
  createInfoListItemTitle,
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemDetailProps,
  InfoListItemTitle,
} from "../../../../components/InfoList"
import { NoteParagraph } from "../../../../components/NoteParagraph/NoteParagraph"
import { PercentNumber } from "../../../../components/PercentNumber"
import { TextTokenCount } from "../../../../components/RichTokenCount"
import { Spensor } from "../../../../components/Spensor"
import { TokenCount } from "../../../../components/TokenCount"
import { TokenName } from "../../../../components/TokenName"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import {
  readResource,
  SuspenseResource,
} from "../../../../utils/SuspenseResource"
import { TokenInfoPresets } from "../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { normal } from "../buttonPresets"
import { Colors } from "../designTokens"
import { RectButton } from "../wrappedCommonComponents/RectButton"

const StyledInfoListTitle = createInfoListItemTitle({
  textClassName: "text-xs leading-4 font-medium text-gray-400",
})

export const TradingAccountContent: FC<{
  gapClassName?: string

  totalBalance: SuspenseResource<number>
  totalBalancePnlInTokenCount?: SuspenseResource<number>
  totalBalancePnlInPercentage?: SuspenseResource<number>

  viewingToken: TokenInfo
  viewingTokenAvailableCount: SuspenseResource<number>
  viewingTokenLockedCount: SuspenseResource<number>

  availableTokens: {
    token: TokenInfo
    availableCount: SuspenseResource<number>
  }[]
  onViewingTokenChange: (newToken: TokenInfo) => void

  onDeposit: () => void
  onWithdraw: SuspenseResource<(() => void) | null>
}> = props => {
  const { $t } = useIntl()
  return (
    <>
      <InfoList
        gapClassName={props.gapClassName}
        direction={"column"}
        listItemDirection={"row-responsive"}
        InfoListItemTitle={StyledInfoListTitle}
      >
        <InfoListItem>
          <InfoListItemTitle>
            {$t(
              defineMessage({
                defaultMessage: "Total Balance",
                description: `Orderbook/Trading Account Panel/"total balance" field label`,
              }),
            )}
          </InfoListItemTitle>
          <SpensorInfoListItemDetail>
            {() => (
              <>
                ~${" "}
                <TokenCount
                  token={TokenInfoPresets.USD}
                  count={readResource(props.totalBalance)}
                />
              </>
            )}
          </SpensorInfoListItemDetail>
        </InfoListItem>

        {props.totalBalancePnlInTokenCount != null &&
          props.totalBalancePnlInPercentage != null && (
            <InfoListItem>
              <InfoListItemTitle className={"self-start"}>
                {$t(
                  defineMessage({
                    defaultMessage: "Today’s PNL",
                    description: `Orderbook/Trading Account Panel/"today's pnl" field label`,
                  }),
                )}
              </InfoListItemTitle>
              <SpensorInfoListItemDetail className={"text-right"}>
                {() => {
                  const percentage = readResource(
                    props.totalBalancePnlInPercentage!,
                  )
                  const count = readResource(props.totalBalancePnlInTokenCount!)
                  return (
                    <>
                      <p>
                        ~$ {count >= 0 ? "" : "-"}
                        <TokenCount
                          token={TokenInfoPresets.USD}
                          count={Math.abs(count)}
                        />
                      </p>
                      <p
                        className={clsx(
                          percentage > 0
                            ? Colors.profitTextClassName
                            : Colors.lossTextClassName,
                        )}
                      >
                        {percentage > 0 ? "↑" : "↓"}
                        &nbsp;
                        <PercentNumber number={Math.abs(percentage)} />
                      </p>
                    </>
                  )
                }}
              </SpensorInfoListItemDetail>
            </InfoListItem>
          )}
      </InfoList>

      <CardDivider className={"w-full"} />

      <InfoList
        direction={"column"}
        listItemDirection={"row-responsive"}
        InfoListItemTitle={StyledInfoListTitle}
      >
        <InfoListItem>
          <InfoListItemTitle>
            {$t(
              defineMessage({
                defaultMessage: "Asset",
                description: `Orderbook/Trading Account Panel/"asset" field label`,
              }),
            )}
          </InfoListItemTitle>
          <InfoListItemDetail>
            <Dropdown
              contentContainerClassName={"min-w-[300px]"}
              triggerMethod={"hover"}
              dismissMethod={["hover-outside", "click"]}
              trigger={
                <Button
                  className={"flex items-center"}
                  boxClassName={"py-[6px] px-[12px]"}
                  Variant={OpacityButton}
                >
                  <TokenName token={props.viewingToken} />
                  &nbsp;
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M6.20454 8.50136L1.84283 4.17196L2.54994 3.46484L6.20483 7.11972L9.85914 3.46541L10.5663 4.17251L6.20454 8.50136Z" />
                  </svg>
                </Button>
              }
            >
              {props.availableTokens.map((t, idx) => (
                <DropdownItem
                  key={idx}
                  contentContainerClassName={
                    "flex-1 flex flex-row items-center"
                  }
                  selected={TokenInfo.isIdentical(t.token, props.viewingToken)}
                  onClick={() => props.onViewingTokenChange(t.token)}
                >
                  <TokenName token={t.token} />
                  &nbsp;
                  <span className={"ml-auto text-gray-500"}>
                    <Spensor fallback={"-"}>
                      {() => (
                        <TokenCount
                          token={t.token}
                          count={readResource(t.availableCount)}
                        />
                      )}
                    </Spensor>
                  </span>
                </DropdownItem>
              ))}
            </Dropdown>
          </InfoListItemDetail>
        </InfoListItem>

        <InfoListItem>
          <InfoListItemTitle>
            {$t(
              defineMessage({
                defaultMessage: "Available",
                description: `Orderbook/Trading Account Panel/"available" field label`,
              }),
            )}
          </InfoListItemTitle>
          <SpensorInfoListItemDetail>
            {() => (
              <>
                <TextTokenCount
                  token={props.viewingToken}
                  count={readResource(props.viewingTokenAvailableCount)}
                />
              </>
            )}
          </SpensorInfoListItemDetail>
        </InfoListItem>

        <Spensor>
          {() =>
            readResource(props.viewingTokenLockedCount) > 0 && (
              <InfoListItem>
                <InfoListItemTitle>
                  {$t(
                    defineMessage({
                      defaultMessage: "Locked asset",
                      description: `Orderbook/Trading Account Panel/"locked asset" field label`,
                    }),
                  )}
                </InfoListItemTitle>
                <InfoListItemDetail>
                  <TextTokenCount
                    token={props.viewingToken}
                    count={readResource(props.viewingTokenLockedCount)}
                  />
                </InfoListItemDetail>
              </InfoListItem>
            )
          }
        </Spensor>
      </InfoList>

      <Spensor>
        {() =>
          readResource(props.viewingTokenAvailableCount) <= 0 && (
            <CardPlate boxClassName={"p-2.5"}>
              <NoteParagraph
                textSizingClassName={"text-xs leading-4 font-normal"}
              >
                {$t(
                  defineMessage({
                    defaultMessage:
                      "Please transfer assets into your trading account to start trading.",
                    description: `Orderbook/Trading Account Panel/tip text`,
                  }),
                )}
              </NoteParagraph>
            </CardPlate>
          )
        }
      </Spensor>

      <div className={"flex gap-2.5"}>
        <RectButton
          className={"flex-1"}
          Variant={GradientFilledButton}
          {...normal}
          onClick={props.onDeposit}
        >
          {$t(
            defineMessage({
              defaultMessage: "Deposit",
              description: `Orderbook/Trading Account Panel/"Deposit" button text`,
            }),
          )}
        </RectButton>

        <Spensor>
          {() => {
            const onClick = readResource(props.onWithdraw)
            if (onClick == null) {
              return null
            }
            return (
              <RectButton
                className={"flex-1"}
                {...normal}
                Variant={WhiteFilledButton}
                onClick={onClick}
              >
                {$t(
                  defineMessage({
                    defaultMessage: "Withdraw",
                    description: `Orderbook/Trading Account Panel/"Withdraw" button text`,
                  }),
                )}
              </RectButton>
            )
          }}
        </Spensor>
      </div>
    </>
  )
}

const SpensorInfoListItemDetail: FC<
  InfoListItemDetailProps & {
    children: () => ReactNode
  }
> = props => (
  <InfoListItemDetail {...props}>
    <Spensor fallback={"-"}>{props.children}</Spensor>
  </InfoListItemDetail>
)
