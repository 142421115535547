import { values } from "lodash"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { TokenInfoPresets } from "../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { assertNever } from "../../../../utils/types"

export enum ETHCurrency {
  USDC = "usdc",
}

export function isETHCurrency(input: string): input is ETHCurrency {
  return values(ETHCurrency).includes(input as any)
}

export function decimalsForETHCurrency(currency: ETHCurrency): number {
  switch (currency) {
    case ETHCurrency.USDC:
      return 18
    default:
      assertNever(currency)
  }
}

export function tokenInfoFromETHCurrency(currency: ETHCurrency): TokenInfo {
  switch (currency) {
    case ETHCurrency.USDC:
      return TokenInfoPresets.USDC
    default:
      assertNever(currency)
  }
}

export function tokenInfoToETHCurrency(
  info: TokenInfo,
): undefined | ETHCurrency {
  switch (info.id) {
    case TokenInfoPresets.USDC.id:
      return ETHCurrency.USDC
    default:
      return undefined
  }
}
