import { AMMSwapPool } from "../../../../../utils/alexjs/AMMSwapPool"
import { Currency } from "../../../../../utils/alexjs/Currency"
import { getTransferAmounts } from "../../../../../utils/transferHelpers"
import {
  BaseRowData,
  CurrencyAmount,
  NotifyTransactionType,
  TransformerGroup,
} from "../../../types"
import { getAmountFromContract } from "../../TransactionsModule.service"

const contracts = [
  "simple-weight-pool-alex",
  "fixed-weight-pool-v1-01",
  "amm-swap-pool",
] as const
const functionName = "add-to-position" as const

export interface AddLiquidityRowData extends BaseRowData {
  type: NotifyTransactionType.AddLiquidity
  lpCurrencyAmount: CurrencyAmount.Unknown | CurrencyAmount.Confirmed
  currencyAmountX: CurrencyAmount.Unknown | CurrencyAmount.Confirmed
  currencyAmountY:
    | CurrencyAmount.Unknown
    | CurrencyAmount.Confirmed
    | CurrencyAmount.Maximum
}

export const addLiquidityTransformerGroup: TransformerGroup<
  typeof contracts,
  typeof functionName,
  AddLiquidityRowData
> = {
  contracts,
  functionName,
  type: NotifyTransactionType.AddLiquidity,
  confirmedTransformer: helpers => {
    const tx = helpers.getTx()
    const args = helpers.getArgs()
    const transfers = helpers.getTransfers()
    const transferAmounts = getTransferAmounts(transfers, tx.sender_address)

    const traitX = args["token-x-trait"] as Currency
    const traitY = args["token-y-trait"] as Currency
    const lpTrait =
      "pool-token-trait" in args
        ? (args["pool-token-trait"] as Currency)
        : AMMSwapPool.fromXY(traitX, traitY, args["factor"])

    return {
      currencyAmountX: {
        currency: traitX,
        amount: transferAmounts[traitX]!.out,
      },
      currencyAmountY: {
        currency: traitY,
        amount: transferAmounts[traitY]!.out,
      },
      lpCurrencyAmount: {
        currency: lpTrait,
        amount: transferAmounts[lpTrait]!.in,
      },
    }
  },
  pendingOrFailedTransformer: args => {
    const traitX = args["token-x-trait"] as Currency
    const traitY = args["token-y-trait"] as Currency
    return {
      currencyAmountX: {
        currency: traitX,
        amount: getAmountFromContract(args.dx),
      },
      currencyAmountY: {
        currency: traitY,
        maxAmount: getAmountFromContract(args["max-dy"]),
      },
      lpCurrencyAmount: {
        currency:
          "pool-token-trait" in args
            ? (args["pool-token-trait"] as Currency)
            : AMMSwapPool.fromXY(traitX, traitY, args["factor"]),
      },
    }
  },
}
