import { ReactNode } from "react"
import { defineMessage, IntlFormatters } from "react-intl"
import { assertNever } from "../../../../../utils/types"
import {
  FundHistoryRecordDirection,
  FundHistoryRecordStatus,
  StxDxOrderStatus,
} from "../../types"

export namespace fields$t {
  export const actions = defineMessage({
    defaultMessage: "Actions",
    description: "Orderbook/OrderHistory/field title",
  })

  export const status = defineMessage({
    defaultMessage: "Status",
    description: "Orderbook/OrderHistory/field title",
  })

  export const price = defineMessage({
    defaultMessage: "Price",
    description: "Orderbook/OrderHistory/field title",
  })

  export const averagePrice = defineMessage({
    defaultMessage: "Average Price",
    description: "Orderbook/OrderHistory/field title",
  })

  export const amount = defineMessage({
    defaultMessage: "Amount",
    description: "Orderbook/OrderHistory/field title",
  })

  export const total = defineMessage({
    defaultMessage: "Total",
    description: "Orderbook/OrderHistory/field title",
  })

  export const fee = defineMessage({
    defaultMessage: "Fee",
    description: "Orderbook/OrderHistory/field title",
  })

  export const filled = defineMessage({
    defaultMessage: "Filled",
    description: "Orderbook/OrderHistory/field title",
  })

  export const executed = defineMessage({
    defaultMessage: "Executed",
    description: "Orderbook/OrderHistory/field title",
  })

  export const triggerCondition = defineMessage({
    defaultMessage: "Trigger Condition",
    description: "Orderbook/OrderHistory/field title",
  })

  export const transactionHash = defineMessage({
    defaultMessage: "Transactions",
    description: "Orderbook/OrderHistory/field title, transactions hash",
  })

  export const createDate = defineMessage({
    defaultMessage: "Date",
    description: "Orderbook/OrderHistory/field title, create date",
  })
}

export namespace orderStatus$t {
  export const matching = defineMessage({
    defaultMessage: "Matching",
    description: "Orderbook/OrderHistory/order status",
  })

  export const matched = defineMessage({
    defaultMessage: "Matched",
    description: "Orderbook/OrderHistory/order status",
  })

  export const settled = defineMessage({
    defaultMessage: "Settled",
    description: "Orderbook/OrderHistory/order status",
  })

  export const cancelled = defineMessage({
    defaultMessage: "Cancelled",
    description: "Orderbook/OrderHistory/order status",
  })

  export const expired = defineMessage({
    defaultMessage: "Expired",
    description: "Orderbook/OrderHistory/order status",
  })

  export const fromStxDxOrderStatus = (
    { $t }: IntlFormatters<ReactNode>,
    status: StxDxOrderStatus,
  ): ReactNode => {
    switch (status) {
      case StxDxOrderStatus.Matching:
        return $t(matching)
      case StxDxOrderStatus.Matched:
        return $t(matched)
      case StxDxOrderStatus.Settled:
        return $t(settled)
      case StxDxOrderStatus.Canceled:
        return $t(cancelled)
      case StxDxOrderStatus.Expired:
        return $t(expired)
      default:
        return assertNever(status)
    }
  }
}

export namespace fundStatus$t {
  export const pending = defineMessage({
    defaultMessage: "Pending",
    description: "Orderbook/OrderHistory/fund order status",
  })

  export const failed = defineMessage({
    defaultMessage: "Failed",
    description: "Orderbook/OrderHistory/fund order status",
  })

  export const confirmed = defineMessage({
    defaultMessage: "Confirmed",
    description: "Orderbook/OrderHistory/fund order status",
  })

  export const fromFundHistoryRecordStatus = (
    { $t }: IntlFormatters<ReactNode>,
    direction: FundHistoryRecordStatus,
  ): ReactNode => {
    switch (direction) {
      case FundHistoryRecordStatus.Pending:
        return $t(pending)
      case FundHistoryRecordStatus.Failed:
        return $t(failed)
      case FundHistoryRecordStatus.Confirmed:
        return $t(confirmed)
      default:
        assertNever(direction)
    }
  }
}

export namespace fundHistoryDirection$t {
  export const deposit = defineMessage({
    defaultMessage: "Deposit",
    description: "Orderbook/OrderHistory/fund order direction",
  })

  export const withdraw = defineMessage({
    defaultMessage: "Withdraw",
    description: "Orderbook/OrderHistory/fund order direction",
  })

  export const fromFundHistoryRecordDirection = (
    { $t }: IntlFormatters<ReactNode>,
    direction: FundHistoryRecordDirection,
  ): ReactNode => {
    switch (direction) {
      case FundHistoryRecordDirection.Deposit:
        return $t(deposit)
      case FundHistoryRecordDirection.Withdraw:
        return $t(withdraw)
      default:
        assertNever(direction)
    }
  }
}
