import clsx from "clsx"
import { FC } from "react"
import { TokenInfo } from "../../utils/models/TokenInfo"
import { Dropdown, DropdownItem } from "../Dropdown"
import { TokenIcon, TokenIconProps } from "../TokenIcon"
import { TokenName } from "../TokenName"

export interface TokenNameSelectProps {
  className?: string
  disabled?: boolean
  availableTokens: TokenInfo[]
  selectedToken?: TokenInfo
  tokenSize?: number
  textClassName?: string
  onChange?: (token: TokenInfo) => void
}

export const TokenNameSelect: FC<TokenNameSelectProps> = props => {
  const trigger = (
    <TokenNameSelectTrigger
      className={props.className}
      token={props.selectedToken}
      tokenSize={props.tokenSize}
      textClassName={props.textClassName}
    />
  )

  if (props.disabled) return trigger

  return (
    <Dropdown trigger={trigger} placement={"start"}>
      {props.availableTokens.map((t, idx) => (
        <DropdownItem
          key={idx}
          onClick={() => {
            props.onChange?.(t)
          }}
        >
          <div className={"flex items-center"}>
            <TokenIcon
              className={"mr-2.5"}
              token={t}
              size={props.tokenSize ?? TokenIconProps.sizes.normal}
            />
            <TokenName className={props.textClassName} token={t} />
          </div>
        </DropdownItem>
      ))}
    </Dropdown>
  )
}

export const TokenNameSelectTrigger: FC<{
  className?: string
  textClassName?: string
  tokenSize?: number
  token?: TokenInfo
  onClick?: () => void
  hideArrow?: boolean
}> = props => {
  const tokenNameClassNames =
    props.textClassName ?? "my-0.5 text-lg sm:text-xl leading-7 font-medium"

  return (
    <div
      className={clsx(
        "px-1 sm:px-4 py-2 flex gap-1.5 sm:gap-2.5 text-gray-200 rounded-md cursor-pointer items-center",
        "bg-white bg-opacity-5",
        "hover:bg-black hover:bg-opacity-5",
        "active:bg-black active:bg-opacity-15",
        props.className,
      )}
      onClick={props.onClick}
    >
      {props.token ? (
        <>
          <TokenIcon
            token={props.token}
            size={props.tokenSize ?? TokenIconProps.sizes.normal}
          />
          <TokenName className={tokenNameClassNames} token={props.token} />
        </>
      ) : (
        <span className={tokenNameClassNames}>Select a token</span>
      )}

      {!props.hideArrow && (
        <svg
          width={18}
          height={18}
          className={"text-gray-200"}
          fill="currentColor"
        >
          <path
            d="M9.307 12.75L2.764 6.256l1.061-1.06 5.482 5.482 5.482-5.482 1.06 1.06-6.542 6.494z"
            opacity={0.5}
          />
        </svg>
      )}
    </div>
  )
}
