import {
  booleanT,
  defineContract,
  listT,
  numberT,
  optionalT,
  principalT,
  responseSimpleT,
  tupleT,
} from "../smartContractHelpers/codegenImport"

export const collateralRebalancingPoolV1 = defineContract({
  "collateral-rebalancing-pool-v1": {
    "add-to-position": {
      input: [
        { name: "token-trait", type: principalT },
        { name: "collateral-trait", type: principalT },
        { name: "expiry", type: numberT },
        { name: "yield-token-trait", type: principalT },
        { name: "key-token-trait", type: principalT },
        { name: "dx", type: numberT },
      ],
      output: responseSimpleT(
        tupleT({ "key-token": numberT, "yield-token": numberT }),
      ),
      mode: "public",
    },
    "add-to-position-and-switch": {
      input: [
        { name: "token-trait", type: principalT },
        { name: "collateral-trait", type: principalT },
        { name: "expiry", type: numberT },
        { name: "yield-token-trait", type: principalT },
        { name: "key-token-trait", type: principalT },
        { name: "dx", type: numberT },
        { name: "min-dy", type: optionalT(numberT) },
      ],
      output: responseSimpleT(tupleT({ dx: numberT, dy: numberT })),
      mode: "public",
    },
    "add-to-yield-token-pool-and-mint-auto": {
      input: [
        { name: "yield-token-trait", type: principalT },
        { name: "token-trait", type: principalT },
        { name: "pool-token-trait", type: principalT },
        { name: "auto-token-trait", type: principalT },
        { name: "dx", type: numberT },
        { name: "max-dy", type: optionalT(numberT) },
      ],
      output: responseSimpleT(numberT),
      mode: "public",
    },
    "buy-to-key-token-and-mint-auto": {
      input: [
        { name: "token-trait", type: principalT },
        { name: "collateral-trait", type: principalT },
        { name: "yield-token-trait", type: principalT },
        { name: "key-token-trait", type: principalT },
        { name: "auto-token-trait", type: principalT },
        { name: "dx", type: numberT },
        { name: "min-dy", type: optionalT(numberT) },
      ],
      output: responseSimpleT(numberT),
      mode: "public",
    },
    "buy-to-yield-token-pool-and-mint-auto": {
      input: [
        { name: "yield-token-trait", type: principalT },
        { name: "token-trait", type: principalT },
        { name: "pool-token-trait", type: principalT },
        { name: "auto-token-trait", type: principalT },
        { name: "dx", type: numberT },
      ],
      output: responseSimpleT(numberT),
      mode: "public",
    },
    "create-and-configure-pool": {
      input: [
        { name: "token-trait", type: principalT },
        { name: "collateral-trait", type: principalT },
        { name: "expiry", type: numberT },
        { name: "yield-token-trait", type: principalT },
        { name: "key-token-trait", type: principalT },
        { name: "multisig-vote", type: principalT },
        { name: "ltv-0", type: numberT },
        { name: "conversion-ltv", type: numberT },
        { name: "bs-vol", type: numberT },
        { name: "moving-average", type: numberT },
        { name: "token-to-maturity", type: numberT },
        { name: "fee-rebate", type: numberT },
        { name: "fee-rate-x", type: numberT },
        { name: "fee-rate-y", type: numberT },
        { name: "dx", type: numberT },
      ],
      output: responseSimpleT(
        tupleT({ "key-token": numberT, "yield-token": numberT }),
      ),
      mode: "public",
    },
    "create-margin-position": {
      input: [
        { name: "token-trait", type: principalT },
        { name: "collateral-trait", type: principalT },
        { name: "expiry", type: numberT },
        { name: "yield-token-trait", type: principalT },
        { name: "key-token-trait", type: principalT },
        { name: "dx", type: numberT },
        { name: "min-dy", type: optionalT(numberT) },
      ],
      output: responseSimpleT(numberT),
      mode: "public",
    },
    "create-pool": {
      input: [
        { name: "token-trait", type: principalT },
        { name: "collateral-trait", type: principalT },
        { name: "expiry", type: numberT },
        { name: "yield-token-trait", type: principalT },
        { name: "key-token-trait", type: principalT },
        { name: "multisig-vote", type: principalT },
        { name: "ltv-0", type: numberT },
        { name: "conversion-ltv", type: numberT },
        { name: "bs-vol", type: numberT },
        { name: "moving-average", type: numberT },
        { name: "token-to-maturity", type: numberT },
        { name: "dx", type: numberT },
      ],
      output: responseSimpleT(
        tupleT({ "key-token": numberT, "yield-token": numberT }),
      ),
      mode: "public",
    },
    "mint-auto": {
      input: [
        { name: "pool-token-trait", type: principalT },
        { name: "auto-token-trait", type: principalT },
        { name: "dx", type: numberT },
      ],
      output: responseSimpleT(numberT),
      mode: "public",
    },
    "redeem-auto": {
      input: [
        { name: "pool-token-trait", type: principalT },
        { name: "auto-token-trait", type: principalT },
        { name: "percent", type: numberT },
      ],
      output: responseSimpleT(
        tupleT({ "auto-to-reduce": numberT, "pool-to-reduce": numberT }),
      ),
      mode: "public",
    },
    "redeem-auto-and-reduce-from-yield-token-pool": {
      input: [
        { name: "yield-token-trait", type: principalT },
        { name: "token-trait", type: principalT },
        { name: "pool-token-trait", type: principalT },
        { name: "auto-token-trait", type: principalT },
        { name: "percent", type: numberT },
      ],
      output: responseSimpleT(tupleT({ dx: numberT, dy: numberT })),
      mode: "public",
    },
    "reduce-position-key": {
      input: [
        { name: "token-trait", type: principalT },
        { name: "collateral-trait", type: principalT },
        { name: "expiry", type: numberT },
        { name: "key-token-trait", type: principalT },
        { name: "percent", type: numberT },
      ],
      output: responseSimpleT(tupleT({ dx: numberT, dy: numberT })),
      mode: "public",
    },
    "reduce-position-key-many": {
      input: [
        { name: "token-trait", type: principalT },
        { name: "collateral-trait", type: principalT },
        { name: "key-token-trait", type: principalT },
        { name: "percent", type: numberT },
        { name: "expiries", type: listT(numberT) },
      ],
      output: responseSimpleT(
        listT(responseSimpleT(tupleT({ dx: numberT, dy: numberT }))),
      ),
      mode: "public",
    },
    "reduce-position-yield": {
      input: [
        { name: "token-trait", type: principalT },
        { name: "collateral-trait", type: principalT },
        { name: "expiry", type: numberT },
        { name: "yield-token-trait", type: principalT },
        { name: "percent", type: numberT },
      ],
      output: responseSimpleT(tupleT({ dx: numberT, dy: numberT })),
      mode: "public",
    },
    "reduce-position-yield-many": {
      input: [
        { name: "token-trait", type: principalT },
        { name: "collateral-trait", type: principalT },
        { name: "yield-token-trait", type: principalT },
        { name: "percent", type: numberT },
        { name: "expiries", type: listT(numberT) },
      ],
      output: responseSimpleT(
        listT(responseSimpleT(tupleT({ dx: numberT, dy: numberT }))),
      ),
      mode: "public",
    },
    "roll-auto": {
      input: [
        { name: "pool-token-trait", type: principalT },
        { name: "token-trait", type: principalT },
        { name: "collateral-trait", type: principalT },
        { name: "yield-token-trait", type: principalT },
        { name: "key-token-trait", type: principalT },
        { name: "auto-pool-trait", type: principalT },
        { name: "auto-key-trait", type: principalT },
      ],
      output: responseSimpleT(tupleT({ loaned: numberT, returned: numberT })),
      mode: "public",
    },
    "roll-auto-key": {
      input: [
        { name: "token-trait", type: principalT },
        { name: "collateral-trait", type: principalT },
        { name: "yield-token-trait", type: principalT },
        { name: "key-token-trait", type: principalT },
        { name: "auto-token-trait", type: principalT },
      ],
      output: responseSimpleT(tupleT({ loaned: numberT, returned: numberT })),
      mode: "public",
    },
    "roll-auto-pool": {
      input: [
        { name: "yield-token-trait", type: principalT },
        { name: "token-trait", type: principalT },
        { name: "collateral-trait", type: principalT },
        { name: "pool-token-trait", type: principalT },
        { name: "auto-token-trait", type: principalT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "roll-auto-yield": {
      input: [
        { name: "yield-token-trait", type: principalT },
        { name: "token-trait", type: principalT },
        { name: "collateral-trait", type: principalT },
        { name: "auto-token-trait", type: principalT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "roll-borrow": {
      input: [
        { name: "token-trait", type: principalT },
        { name: "collateral-trait", type: principalT },
        { name: "expiry", type: numberT },
        { name: "yield-token-trait", type: principalT },
        { name: "key-token-trait", type: principalT },
        { name: "expiry-to-roll", type: numberT },
        { name: "min-dx", type: optionalT(numberT) },
      ],
      output: responseSimpleT(numberT),
      mode: "public",
    },
    "roll-borrow-many": {
      input: [
        { name: "token-trait", type: principalT },
        { name: "collateral-trait", type: principalT },
        { name: "yield-token-trait", type: principalT },
        { name: "key-token-trait", type: principalT },
        { name: "expiry-to-roll", type: numberT },
        { name: "expiries", type: listT(numberT) },
      ],
      output: responseSimpleT(listT(responseSimpleT(numberT))),
      mode: "public",
    },
    "roll-deposit": {
      input: [
        { name: "token-trait", type: principalT },
        { name: "collateral-trait", type: principalT },
        { name: "expiry", type: numberT },
        { name: "yield-token-trait", type: principalT },
        { name: "expiry-to-roll", type: numberT },
        { name: "percent", type: numberT },
        { name: "min-dy", type: optionalT(numberT) },
      ],
      output: responseSimpleT(tupleT({ dx: numberT, dy: numberT })),
      mode: "public",
    },
    "roll-deposit-many": {
      input: [
        { name: "token-trait", type: principalT },
        { name: "collateral-trait", type: principalT },
        { name: "yield-token-trait", type: principalT },
        { name: "expiry-to-roll", type: numberT },
        { name: "percent", type: numberT },
        { name: "expiries", type: listT(numberT) },
      ],
      output: responseSimpleT(
        listT(responseSimpleT(tupleT({ dx: numberT, dy: numberT }))),
      ),
      mode: "public",
    },
    "set-approved-contract": {
      input: [
        { name: "owner", type: principalT },
        { name: "approved", type: booleanT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-approved-pair": {
      input: [
        { name: "auto-token", type: principalT },
        { name: "pool-token", type: principalT },
        { name: "new-activation-block", type: numberT },
        { name: "new-bounty-in-fixed", type: numberT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-bounty-in-fixed": {
      input: [
        { name: "auto-token", type: principalT },
        { name: "new-bounty-in-fixed", type: numberT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-capacity-multiplier": {
      input: [{ name: "new-capacity-multiplier", type: numberT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-contract-owner": {
      input: [{ name: "owner", type: principalT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-expiry-cycle-length": {
      input: [{ name: "new-expiry-cycle-length", type: numberT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-fee-rate-x": {
      input: [
        { name: "token", type: principalT },
        { name: "collateral", type: principalT },
        { name: "expiry", type: numberT },
        { name: "fee-rate-x", type: numberT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-fee-rate-y": {
      input: [
        { name: "token", type: principalT },
        { name: "collateral", type: principalT },
        { name: "expiry", type: numberT },
        { name: "fee-rate-y", type: numberT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-fee-rebate": {
      input: [
        { name: "token", type: principalT },
        { name: "collateral", type: principalT },
        { name: "expiry", type: numberT },
        { name: "fee-rebate", type: numberT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-fee-to-address": {
      input: [
        { name: "token", type: principalT },
        { name: "collateral", type: principalT },
        { name: "expiry", type: numberT },
        { name: "fee-to-address", type: principalT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-max-in-ratio": {
      input: [{ name: "new-max-in-ratio", type: numberT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-max-out-ratio": {
      input: [{ name: "new-max-out-ratio", type: numberT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-shortfall-coverage": {
      input: [{ name: "new-shortfall-coverage", type: numberT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-strike-multiplier": {
      input: [{ name: "new-strike-multiplier", type: numberT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "swap-x-for-y": {
      input: [
        { name: "token-trait", type: principalT },
        { name: "collateral-trait", type: principalT },
        { name: "expiry", type: numberT },
        { name: "dx", type: numberT },
        { name: "min-dy", type: optionalT(numberT) },
      ],
      output: responseSimpleT(tupleT({ dx: numberT, dy: numberT })),
      mode: "public",
    },
    "swap-y-for-x": {
      input: [
        { name: "token-trait", type: principalT },
        { name: "collateral-trait", type: principalT },
        { name: "expiry", type: numberT },
        { name: "dy", type: numberT },
        { name: "min-dx", type: optionalT(numberT) },
      ],
      output: responseSimpleT(tupleT({ dx: numberT, dy: numberT })),
      mode: "public",
    },
    "get-activation-block-or-default": {
      input: [{ name: "pool-token", type: principalT }],
      output: numberT,
      mode: "readonly",
    },
    "get-approved-pair": {
      input: [{ name: "auto-token", type: principalT }],
      output: optionalT(principalT),
      mode: "readonly",
    },
    "get-auto-total-supply-or-default": {
      input: [{ name: "auto-token", type: principalT }],
      output: numberT,
      mode: "readonly",
    },
    "get-bounty-in-fixed-or-default": {
      input: [{ name: "auto-token", type: principalT }],
      output: numberT,
      mode: "readonly",
    },
    "get-capacity-multiplier": {
      input: [],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-contract-owner": {
      input: [],
      output: responseSimpleT(principalT),
      mode: "readonly",
    },
    "get-expiry": {
      input: [{ name: "pool-token", type: principalT }],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-expiry-cycle": {
      input: [
        { name: "pool-token", type: principalT },
        { name: "stacks-height", type: numberT },
      ],
      output: optionalT(numberT),
      mode: "readonly",
    },
    "get-expiry-cycle-length": { input: [], output: numberT, mode: "readonly" },
    "get-fee-rate-x": {
      input: [
        { name: "token", type: principalT },
        { name: "collateral", type: principalT },
        { name: "expiry", type: numberT },
      ],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-fee-rate-y": {
      input: [
        { name: "token", type: principalT },
        { name: "collateral", type: principalT },
        { name: "expiry", type: numberT },
      ],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-fee-rebate": {
      input: [
        { name: "token", type: principalT },
        { name: "collateral", type: principalT },
        { name: "expiry", type: numberT },
      ],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-fee-to-address": {
      input: [
        { name: "token", type: principalT },
        { name: "collateral", type: principalT },
        { name: "expiry", type: numberT },
      ],
      output: responseSimpleT(principalT),
      mode: "readonly",
    },
    "get-first-stacks-block-in-expiry-cycle": {
      input: [
        { name: "pool-token", type: principalT },
        { name: "expiry-cycle", type: numberT },
      ],
      output: numberT,
      mode: "readonly",
    },
    "get-invariant": {
      input: [
        { name: "balance-x", type: numberT },
        { name: "balance-y", type: numberT },
        { name: "weight-x", type: numberT },
        { name: "weight-y", type: numberT },
      ],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-last-expiry": {
      input: [{ name: "pool-token", type: principalT }],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-ltv": {
      input: [
        { name: "token", type: principalT },
        { name: "collateral", type: principalT },
        { name: "expiry", type: numberT },
      ],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-max-in-ratio": { input: [], output: numberT, mode: "readonly" },
    "get-max-out-ratio": { input: [], output: numberT, mode: "readonly" },
    "get-pool-details": {
      input: [
        { name: "token", type: principalT },
        { name: "collateral", type: principalT },
        { name: "expiry", type: numberT },
      ],
      output: responseSimpleT(
        tupleT({
          "balance-x": numberT,
          "balance-y": numberT,
          "bs-vol": numberT,
          "conversion-ltv": numberT,
          "fee-rate-x": numberT,
          "fee-rate-y": numberT,
          "fee-rebate": numberT,
          "fee-to-address": principalT,
          "key-supply": numberT,
          "key-token": principalT,
          "ltv-0": numberT,
          "moving-average": numberT,
          strike: numberT,
          "token-to-maturity": numberT,
          "weight-x": numberT,
          "weight-y": numberT,
          "yield-supply": numberT,
          "yield-token": principalT,
        }),
      ),
      mode: "readonly",
    },
    "get-pool-total-supply-or-default": {
      input: [{ name: "pool-token", type: principalT }],
      output: numberT,
      mode: "readonly",
    },
    "get-pool-value-in-collateral": {
      input: [
        { name: "token", type: principalT },
        { name: "collateral", type: principalT },
        { name: "expiry", type: numberT },
      ],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-pool-value-in-token": {
      input: [
        { name: "token", type: principalT },
        { name: "collateral", type: principalT },
        { name: "expiry", type: numberT },
      ],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-position-given-burn-key": {
      input: [
        { name: "token", type: principalT },
        { name: "collateral", type: principalT },
        { name: "expiry", type: numberT },
        { name: "shares", type: numberT },
      ],
      output: responseSimpleT(tupleT({ dx: numberT, dy: numberT })),
      mode: "readonly",
    },
    "get-position-given-mint": {
      input: [
        { name: "token", type: principalT },
        { name: "collateral", type: principalT },
        { name: "expiry", type: numberT },
        { name: "shares", type: numberT },
      ],
      output: responseSimpleT(
        tupleT({ dx: numberT, "dx-weighted": numberT, "dy-weighted": numberT }),
      ),
      mode: "readonly",
    },
    "get-shortfall-coverage": {
      input: [],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-spot": {
      input: [
        { name: "token", type: principalT },
        { name: "collateral", type: principalT },
      ],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-strike-multiplier": {
      input: [],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-token-given-position": {
      input: [
        { name: "token", type: principalT },
        { name: "collateral", type: principalT },
        { name: "expiry", type: numberT },
        { name: "dx", type: numberT },
      ],
      output: responseSimpleT(
        tupleT({ "key-token": numberT, "yield-token": numberT }),
      ),
      mode: "readonly",
    },
    "get-weight-x": {
      input: [
        { name: "token", type: principalT },
        { name: "collateral", type: principalT },
        { name: "expiry", type: numberT },
      ],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-x-given-price": {
      input: [
        { name: "token", type: principalT },
        { name: "collateral", type: principalT },
        { name: "expiry", type: numberT },
        { name: "price", type: numberT },
      ],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-x-given-y": {
      input: [
        { name: "token", type: principalT },
        { name: "collateral", type: principalT },
        { name: "expiry", type: numberT },
        { name: "dy", type: numberT },
      ],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-y-given-price": {
      input: [
        { name: "token", type: principalT },
        { name: "collateral", type: principalT },
        { name: "expiry", type: numberT },
        { name: "price", type: numberT },
      ],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-y-given-x": {
      input: [
        { name: "token", type: principalT },
        { name: "collateral", type: principalT },
        { name: "expiry", type: numberT },
        { name: "dx", type: numberT },
      ],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "activation-block": {
      input: principalT,
      output: optionalT(numberT),
      mode: "mapEntry",
    },
    "approved-contracts": {
      input: principalT,
      output: optionalT(booleanT),
      mode: "mapEntry",
    },
    "approved-pair": {
      input: principalT,
      output: optionalT(principalT),
      mode: "mapEntry",
    },
    "auto-total-supply": {
      input: principalT,
      output: optionalT(numberT),
      mode: "mapEntry",
    },
    "bounty-in-fixed": {
      input: principalT,
      output: optionalT(numberT),
      mode: "mapEntry",
    },
    "pool-expiry": {
      input: principalT,
      output: optionalT(numberT),
      mode: "mapEntry",
    },
    "pool-total-supply": {
      input: principalT,
      output: optionalT(numberT),
      mode: "mapEntry",
    },
    "pools-data-map": {
      input: tupleT({
        expiry: numberT,
        "token-x": principalT,
        "token-y": principalT,
      }),
      output: optionalT(
        tupleT({
          "balance-x": numberT,
          "balance-y": numberT,
          "bs-vol": numberT,
          "conversion-ltv": numberT,
          "fee-rate-x": numberT,
          "fee-rate-y": numberT,
          "fee-rebate": numberT,
          "fee-to-address": principalT,
          "key-supply": numberT,
          "key-token": principalT,
          "ltv-0": numberT,
          "moving-average": numberT,
          strike: numberT,
          "token-to-maturity": numberT,
          "weight-x": numberT,
          "weight-y": numberT,
          "yield-supply": numberT,
          "yield-token": principalT,
        }),
      ),
      mode: "mapEntry",
    },
  },
} as const)
