import { FC, ReactNode } from "react"
import { LinkedButton } from "../../../../../components/button/LinkedButton"
import { GradientFilledButton } from "../../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteFilledButton } from "../../../../../components/button/variants/WhiteFilledButton"
import { TokenName } from "../../../../../components/TokenName"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { TokenInfoPresets } from "../../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { LaunchingStatus } from "../../../store/LaunchPadContractStore"
import { IDOProgress } from "../../IDOProgress/IDOProgress"
import { InCardIDOStepsContainer } from "../../IDOSteps"
import {
  TokenIDOPrices,
  TokenIDORaiseNumbers,
  TokenIDOStatus,
} from "../../types"
import { BottomActionsSection } from "./utilComponents/BottomActionsSection"
import { IDODetailSidePanelFrame } from "./utilComponents/IDODetailSidePanelFrame"
import { IDOProgramDescribe } from "./utilComponents/IDOProgramDescribe"
import { TokenIconAndNumbersSection } from "./utilComponents/TokenIconAndNumbersSection"

export interface IDODetailSidePanel$ValidateProps {
  className?: string
  idoToken: TokenInfo
  priceToken: TokenInfo
  idoNumbers: TokenIDORaiseNumbers
  idoPrices: TokenIDOPrices
  idoStatus: TokenIDOStatus
  idoSteps: ReactNode
  titleRightSide?: ReactNode
  dashboardLink?: null | string
  prepareAPowerLink?: null | string
}

export const IDODetailSidePanel$Validate: FC<
  IDODetailSidePanel$ValidateProps
> = props => {
  return (
    <IDODetailSidePanelFrame
      className={props.className}
      titleRightSide={props.titleRightSide}
    >
      <TokenIconAndNumbersSection
        idoToken={props.idoToken}
        priceToken={props.priceToken}
        maxRaiseIdoTokenCount={props.idoNumbers.maxRaiseIdoTokenCount}
        maxRaiseIdoTokenPrice={
          props.idoPrices.pricePerToken * props.idoNumbers.maxRaiseIdoTokenCount
        }
      />

      <IDOProgramDescribe />

      <InCardIDOStepsContainer>{props.idoSteps}</InCardIDOStepsContainer>

      <IDOProgress
        priceToken={props.priceToken}
        idoNumbers={props.idoNumbers}
        isFinished={props.idoStatus.type === LaunchingStatus.Finished}
      />

      <BottomActionsSection>
        {props.dashboardLink !== null && (
          <LinkedButton
            Variant={GradientFilledButton}
            link={props.dashboardLink}
          >
            IDO Dashboard
          </LinkedButton>
        )}
        {props.prepareAPowerLink !== null && (
          <LinkedButton
            Variant={WhiteFilledButton}
            link={props.prepareAPowerLink}
          >
            Prepare <TokenName token={TokenInfoPresets.APower} />
          </LinkedButton>
        )}
      </BottomActionsSection>
    </IDODetailSidePanelFrame>
  )
}
