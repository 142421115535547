"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SECP256K1Client = void 0;
const hmac_1 = require("@noble/hashes/hmac");
const sha256_1 = require("@noble/hashes/sha256");
const secp = require("@noble/secp256k1");
const ecdsa_sig_formatter_1 = require("ecdsa-sig-formatter");
const errors_1 = require("../errors");
// required to use noble secp https://github.com/paulmillr/noble-secp256k1
secp.utils.hmacSha256Sync = (key, ...msgs) => {
    const h = hmac_1.hmac.create(sha256_1.sha256, key);
    msgs.forEach(msg => h.update(msg));
    return h.digest();
};
class SECP256K1Client {
    static derivePublicKey(privateKey, compressed = true) {
        if (privateKey.length === 66) {
            privateKey = privateKey.slice(0, 64);
        }
        if (privateKey.length < 64) {
            // backward compatibly accept too short private keys
            privateKey = privateKey.padStart(64, '0');
        }
        return Buffer.from(secp.getPublicKey(privateKey, compressed)).toString('hex');
    }
    static signHash(signingInputHash, privateKey, format = 'jose') {
        // make sure the required parameters are provided
        if (!signingInputHash || !privateKey) {
            throw new errors_1.MissingParametersError('a signing input hash and private key are all required');
        }
        const derSignature = Buffer.from(secp.signSync(signingInputHash, privateKey.slice(0, 64), { der: true, canonical: false }));
        if (format === 'der')
            return derSignature.toString('hex');
        if (format === 'jose')
            return (0, ecdsa_sig_formatter_1.derToJose)(derSignature, 'ES256');
        throw Error('Invalid signature format');
    }
    static loadSignature(joseSignature) {
        // create and return the DER-formatted signature buffer
        return (0, ecdsa_sig_formatter_1.joseToDer)(joseSignature, 'ES256');
    }
    static verifyHash(signingInputHash, derSignatureBuffer, publicKey) {
        // make sure the required parameters are provided
        if (!signingInputHash || !derSignatureBuffer || !publicKey) {
            throw new errors_1.MissingParametersError('a signing input hash, der signature, and public key are all required');
        }
        return secp.verify(derSignatureBuffer, signingInputHash, publicKey, { strict: false });
    }
}
exports.SECP256K1Client = SECP256K1Client;
SECP256K1Client.algorithmName = 'ES256K';
