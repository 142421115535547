import clsx from "clsx"
import { FC, ReactNode } from "react"
import { OpacityButton } from "../../../components/button/variants/OpacityButton"
import { Card, CardTitle } from "../../../components/Card"
import { ResponsiveImage } from "../../../components/ResponsiveImage"
import { Spensor } from "../../../components/Spensor"
import { TipIcon } from "../../../components/TipIcon/TipIcon"
import { TokenCount } from "../../../components/TokenCount"
import { TokenName } from "../../../components/TokenName"
import { readResource, SuspenseResource } from "../../../utils/SuspenseResource"
import { TokenInfoPresets } from "../../../utils/TokenInfoPresets/TokenInfoPresets"
import { CRPSimulatorDayData } from "../types"
import { CRPSimulatorChart } from "./Chart"
import { ReactComponent as ExpandIcon } from "./expand.svg"
import loadingImageSrc from "./loadingImage@3x.png?responsive"
import { ReactComponent as WarningIcon } from "./warning.svg"

export interface PerformancePanelProps {
  initialLTV: SuspenseResource<number>
  interestRate: number
  endDatePosition?: {
    xValue: number
    yValue: number
    yPrice: number
  }
  data: CRPSimulatorDayData[]
  onExpand?: () => void
  className?: string
  liquidations: Array<{
    platformName: string
    date: string
  }>
}

const tokenX = TokenInfoPresets.MockXBTC
const tokenY = TokenInfoPresets.MockXUSD

export const PerformancePanel: FC<PerformancePanelProps> = props => {
  return (
    <Container onExpand={props.onExpand} className={props.className}>
      <div className="flex flex-col gap-y-6">
        <dl className="flex flex-row gap-x-6">
          <DlItem title="Start Date LTV">
            <Spensor fallback="-">
              {() => `${(readResource(props.initialLTV) * 100).toFixed(0)}%`}
            </Spensor>
          </DlItem>
          <DlItem
            title="Interest Rate"
            tip="The interest rate is an estimated value and only based on assumptions."
          >
            {`${(props.interestRate * 100).toFixed(0)}%`}
          </DlItem>
          <DlItem
            title="End Date Position"
            tip="The value of your collateral balance on the last day of the selected data range."
          >
            {props.endDatePosition ? (
              <>
                <TokenCount
                  token={tokenX}
                  count={
                    props.endDatePosition.yValue /
                      props.endDatePosition.yPrice || 0
                  }
                />
                &nbsp;
                <TokenName token={tokenX} />
                &nbsp;+&nbsp;
                <TokenCount
                  token={tokenY}
                  count={props.endDatePosition.xValue}
                />
                &nbsp;
                <TokenName token={tokenY} />
              </>
            ) : (
              "-"
            )}
          </DlItem>
        </dl>

        <CRPSimulatorChart
          className="w-[40rem] h-[25rem]"
          data={props.data}
          liquidations={props.liquidations}
        />
      </div>

      <div className="flex flex-row items-start gap-x-2.5 px-10">
        <WarningIcon className="flex-none" />
        <p className="text-sm leading-5 font-normal text-gray-500">
          DISCLAIMER: The simulation provided is for illustrative purposes only
          with certain assumptions and ALEX makes no representation that the
          simulation represents the actual performance.
        </p>
      </div>
    </Container>
  )
}

export const PerformancePanelLoading: FC<{
  onExpand?: () => void
  className?: string
}> = ({ onExpand, className }) => (
  <Container onExpand={onExpand} className={className}>
    <div className="flex flex-row items-center justify-around">
      <ResponsiveImage image={loadingImageSrc} width={480} height={480} />
    </div>
  </Container>
)

const Container: FC<{
  children: ReactNode
  onExpand?: () => void
  className?: string
}> = ({ children, onExpand, className }) => (
  <Card className={clsx("min-w-[43rem] flex flex-col gap-y-4", className)}>
    <CardTitle className="flex flex-row items-center">
      <span className="grow text-xl leading-7 font-medium text-gray-200">
        Performance
      </span>
      <OpacityButton
        onClick={onExpand}
        className="flex flex-row items-center gap-x-2.5"
        boxClassName="px-7 py-1.5"
      >
        <span className="text-sm leading-5 font-normal text-gray-200">
          Comparison
        </span>
        <ExpandIcon />
      </OpacityButton>
    </CardTitle>

    {children}
  </Card>
)

const DlItem: FC<{ children: ReactNode; title: string; tip?: string }> = ({
  children,
  title,
  tip,
}) => (
  <div className="flex flex-col items-start">
    <dt className="flex flex-row items-center gap-x-1.5">
      <span className="text-xs leading-4 font-normal text-gray-200/50">
        {title}
      </span>
      {tip && <TipIcon tip={tip} />}
    </dt>
    <dd className="text-sm leading-5 font-normal text-gray-200">{children}</dd>
  </div>
)
