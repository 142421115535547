import { FC } from "react"
import { withClassName } from "../../utils/reactHelpers/withClassName"
import { assertNever } from "../../utils/types"
import { GradientFilledButton } from "../button/variants/GradientFilledButton/GradientFilledButton"
import { CardSubTitle } from "../Card"
import { CardModalContent, CardModalContentProps } from "../CardModal/CardModal"
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator"
import { NavLink } from "../NavLink"
import { ReactComponent as BroadcastedIcon } from "./broadcasted.svg"
import { ReactComponent as ConfirmIcon } from "./confirm.svg"
import { ReactComponent as ExplorerIcon } from "./explorer.svg"
import { ReactComponent as FailIcon } from "./fail.svg"
import { ReactComponent as SubscribeIcon } from "./subscribe.svg"
import { ReactComponent as TickIcon } from "./tick.svg"

export type TransactionStateForGeneral =
  | { type: "running"; msg?: string }
  | { type: "success"; explorerLink?: string }
  | { type: "error"; error: string; explorerLink?: string }
export const TransactionStateDialogContentForGeneral: FC<{
  transactionState: TransactionStateForGeneral
  onClose: () => void
}> = props => {
  const state = props.transactionState
  switch (state.type) {
    case "running":
      return (
        <TransactionStateDialog$ConfirmTransaction
          onClose={props.onClose}
          msg={state.msg}
        />
      )
    case "success":
      return (
        <TransactionStateDialog$BroadcastedForGeneral
          explorerLink={state.explorerLink}
          onClose={props.onClose}
        />
      )
    case "error":
      return (
        <TransactionStateDialog$Failed
          message={state.error}
          explorerLink={state.explorerLink}
          onClose={props.onClose}
        />
      )
    default:
      assertNever(state)
  }
}

export type TransactionStateForStacks =
  | { type: "running"; msg?: string }
  | { type: "success"; explorerLink: string; telegramSubscribeLink: string }
  | { type: "error"; error: string; explorerLink?: string }
export const TransactionStateDialogContentForStacks: FC<{
  transactionState: TransactionStateForStacks
  onClose: () => void
}> = props => {
  const state = props.transactionState
  switch (state.type) {
    case "running":
      return (
        <TransactionStateDialog$ConfirmTransaction
          onClose={props.onClose}
          msg={state.msg}
        />
      )
    case "success":
      return (
        <TransactionStateDialog$BroadcastedForStacks
          explorerLink={state.explorerLink}
          telegramSubscribeLink={state.telegramSubscribeLink}
          onClose={props.onClose}
        />
      )
    case "error":
      return (
        <TransactionStateDialog$Failed
          message={state.error}
          explorerLink={state.explorerLink}
          onClose={props.onClose}
        />
      )
    default:
      assertNever(state)
  }
}

const ModalContentContainer = withClassName(
  "items-center",
  (props: CardModalContentProps) => <CardModalContent {...props} width={480} />,
)

export const TransactionStateDialog$ConfirmTransaction: FC<{
  msg?: string
  onClose?: () => void
}> = props => (
  <ModalContentContainer title={"Confirm Transaction"} onClose={props.onClose}>
    <div className={"flex p-6"}>
      <LoadingIndicator />
    </div>

    <CardSubTitle className={"text-center"}>
      {props.msg ?? (
        <>
          Please confirm the transaction
          <br />
          in your wallet app...
        </>
      )}
    </CardSubTitle>
  </ModalContentContainer>
)

export const TransactionStateDialog$BroadcastedForStacks: FC<{
  explorerLink: string
  telegramSubscribeLink: string
  onClose: () => void
}> = props => (
  <ModalContentContainer
    onClose={props.onClose}
    title={
      <div className="flex flex-row items-center justify-center gap-x-2.5">
        <TickIcon />
        <span>Transaction broadcasted.</span>
      </div>
    }
  >
    <div className="flex flex-col items-center gap-y-4">
      <p className="flex flex-row items-center justify-center gap-x-2.5">
        <ConfirmIcon />
        <span className="text-3xl leading-9 font-light text-gray-200">
          10-30mins
        </span>
      </p>
      <p className="text-sm leading-5 font-normal text-gray-400 text-center">
        This Transaction takes at least{" "}
        <em className="text-green-500 font-bold not-italic">1 Stacks block</em>{" "}
        to confirm, that is approximately around{" "}
        <em className="text-green-500 font-bold not-italic">10-30mins</em>.
      </p>
      <p className={"text-sm leading-5 font-normal text-center"}>
        <ViewInExplorerLink explorerLink={props.explorerLink} />
      </p>
      <p className={"text-sm leading-5 font-normal text-center"}>
        <TelegramSubscribeLink subscribeLink={props.telegramSubscribeLink} />
      </p>
    </div>

    <GradientFilledButton
      className={"block w-full mt-4"}
      onClick={props.onClose}
    >
      OK
    </GradientFilledButton>
  </ModalContentContainer>
)

export const TransactionStateDialog$BroadcastedForGeneral: FC<{
  explorerLink?: string
  onClose: () => void
}> = props => (
  <ModalContentContainer onClose={props.onClose}>
    <BroadcastedIcon />

    <div>
      <CardSubTitle>Transaction broadcasted.</CardSubTitle>
      {props.explorerLink != null && (
        <p className={"mt-2.5 text-sm leading-5 font-normal text-center"}>
          <ViewInExplorerLink explorerLink={props.explorerLink} />
        </p>
      )}
    </div>

    <GradientFilledButton
      className={"block w-full mt-[30px]"}
      onClick={props.onClose}
    >
      OK
    </GradientFilledButton>
  </ModalContentContainer>
)

export const TransactionStateDialog$Failed: FC<{
  message?: string
  explorerLink?: string
  onClose?: () => void
}> = props => (
  <ModalContentContainer onClose={props.onClose}>
    <FailIcon />

    <div>
      <CardSubTitle>{props.message ?? "Transaction failed."}</CardSubTitle>
      {props.explorerLink != null && (
        <p
          className={
            "mt-2.5 text-sm leading-5 font-normal text-gray-100 text-center"
          }
        >
          <ViewInExplorerLink explorerLink={props.explorerLink} />
        </p>
      )}
    </div>

    <GradientFilledButton
      className={"block w-full mt-[54px]"}
      onClick={props.onClose}
    >
      OK
    </GradientFilledButton>
  </ModalContentContainer>
)

const ViewInExplorerLink: FC<{ explorerLink: string }> = props => (
  <NavLink
    className={"text-blue-600"}
    target={"_blank"}
    to={props.explorerLink}
  >
    <ExplorerIcon className={"inline mr-2"} />
    View in explorer
  </NavLink>
)

const TelegramSubscribeLink: FC<{ subscribeLink: string }> = props => (
  <NavLink
    className={"text-blue-600"}
    target={"_blank"}
    to={props.subscribeLink}
  >
    <SubscribeIcon className={"inline mr-2"} />
    Turn on TG Notification
  </NavLink>
)
