import { memoize } from "lodash"
import { Observable } from "rxjs"
import { io, Socket } from "socket.io-client"
import { STX_DX_API_HOST_WEBSOCKET } from "../../../config"

const socketForPath = memoize(
  (path: string): Observable<Socket> =>
    new Observable<Socket>(sink => {
      const socket = io(path, {
        transports: ["websocket"],
      })
      socket.on("connect", function () {
        sink.next(socket)
      })
      socket.on("exception", function (data) {
        sink.error(data)
      })
      socket.on("disconnect", function () {
        sink.complete()
      })
      return () => {
        socket.disconnect()
      }
    }),
)

export const orderbookWebsocket = socketForPath(
  STX_DX_API_HOST_WEBSOCKET + "/orderbook",
)
export const accountWebsocket = socketForPath(
  STX_DX_API_HOST_WEBSOCKET + "/account",
)
