import {
  createContext,
  useContext,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from "react"

import { values } from "lodash"
import { isShallowEqual } from "../utils/isShallowEqual"
import { PropsWithChildren } from "../utils/reactHelpers/types"
import { useOnElementResize } from "../utils/reactHelpers/useOnElementResize"
import { usePersistFn } from "../utils/reactHelpers/usePersistFn"

export type SimpleSize = {
  width: number
  height: number
}

export type ComplexSize<T> = SimpleSize & {
  data: T
}

interface SizeTunerContextValue<T> {
  getFinalSize: (compId: string, data: T) => SimpleSize
  updateSize: (compId: string, size: null | ComplexSize<T>) => void
}

const SizeTunerContext = createContext<null | SizeTunerContextValue<unknown>>(
  null,
)

export type Transformer<T> = (
  allSize: ComplexSize<T>[],
  inquiringItemData: T,
) => SimpleSize

export function createSizeTuner<T>(): {
  SizeTuner: typeof SizeTuner<T>
  SizeConsumer: typeof SizeConsumer<T>
} {
  return { SizeTuner, SizeConsumer }
}

function SizeTuner<T>(
  props: PropsWithChildren<{
    transformer?: Transformer<T>
  }>,
): JSX.Element {
  const { transformer = defaultTransformer } = props

  const [allSizeMap, setAllSizeMap] = useState<Record<string, ComplexSize<T>>>(
    {},
  )
  const allSize = useMemo(() => values(allSizeMap), [allSizeMap])

  const getFinalSize: SizeTunerContextValue<T>["getFinalSize"] = usePersistFn(
    (id, data) => transformer(allSize, data),
  )

  const updateSize: SizeTunerContextValue<T>["updateSize"] = usePersistFn(
    (id, size) => {
      setAllSizeMap(allSize => {
        if (size == null) {
          if (allSize[id] == null) return allSize
          const { [id]: _, ..._updated } = allSize
          return _updated
        } else {
          if (isShallowEqual(allSize[id], size)) return allSize
          return { [id]: size, ...allSize }
        }
      })
    },
  )

  const value: SizeTunerContextValue<T> = useMemo(
    () => ({ getFinalSize, updateSize }),
    [getFinalSize, updateSize],
  )

  return (
    <SizeTunerContext.Provider value={value as SizeTunerContextValue<unknown>}>
      {props.children}
    </SizeTunerContext.Provider>
  )
}

function SizeConsumer<T>(
  props: PropsWithChildren<{
    className?: string
    containerClassName?: string
    data: T
  }>,
): JSX.Element {
  const ctx = useContext(SizeTunerContext)
  if (ctx == null) {
    throw new Error("SizeConsumer should be use in subtree of SizeTuner")
  }

  const compId = useId()
  const innerContainerRef = useRef<HTMLDivElement>(null)

  const { getFinalSize, updateSize } = ctx
  useEffect(() => () => updateSize(compId, null), [compId, updateSize])

  useOnElementResize(innerContainerRef, { immediately: true }, () => {
    if (innerContainerRef.current == null) return
    const rect = innerContainerRef.current.getBoundingClientRect()
    ctx.updateSize(compId, {
      width: rect.width,
      height: rect.height,
      data: props.data,
    })
  })

  return (
    <div className={props.className} style={getFinalSize(compId, props.data)}>
      <div
        ref={innerContainerRef}
        className={props.containerClassName}
        style={{ position: "absolute" }}
      >
        {props.children}
      </div>
    </div>
  )
}

const defaultTransformer: Transformer<unknown> = allSize => {
  return {
    width: Math.max(...allSize.map(s => s.width), 0),
    height: Math.max(...allSize.map(s => s.height), 0),
  }
}
