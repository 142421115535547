import BigNumber from "bignumber.js"
import { uniq } from "lodash"
import { Currency, WrappedCurrency } from "../../../../utils/alexjs/Currency"
import { currencyScale } from "../../../../utils/alexjs/currencyHelpers"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { assertNever } from "../../../../utils/types"
import {
  decimalsForETHCurrency,
  ETHCurrency,
  isETHCurrency,
  tokenInfoFromETHCurrency,
  tokenInfoToETHCurrency,
} from "./ETHCurrency"

const bridgeCurrencyMap = [[ETHCurrency.USDC, Currency.W_XUSD]] as const
const bridgeCurrencies = uniq(bridgeCurrencyMap.flatMap(m => m))
export type BridgeCurrency = typeof bridgeCurrencies[number]
export function isBridgeCurrency(a: string): a is BridgeCurrency {
  return bridgeCurrencies.includes(a as any)
}

export const wrappableBridgeCurrencies = bridgeCurrencyMap.flatMap(m => m[0])
export type WrappableBridgeCurrency = typeof wrappableBridgeCurrencies[number]
export function isWrappableBridgeCurrency(
  c: BridgeCurrency,
): c is WrappableBridgeCurrency {
  return wrappableBridgeCurrencies.includes(c as any)
}

export const unwrappableBridgeCurrencies = bridgeCurrencyMap.flatMap(m => m[1])
export type UnwrappableBridgeCurrency =
  typeof unwrappableBridgeCurrencies[number]
export function isUnwrappableBridgeCurrency(
  c: BridgeCurrency,
): c is UnwrappableBridgeCurrency {
  return unwrappableBridgeCurrencies.includes(c as any)
}

export function tokenInfoFromBridgeCurrency(
  currency: BridgeCurrency,
  tokenInfoFromCurrency: (currency: Currency) => TokenInfo,
): TokenInfo {
  if (isETHCurrency(currency)) {
    return tokenInfoFromETHCurrency(currency)
  }

  return tokenInfoFromCurrency(currency)
}

export function tokenInfoToBridgeCurrency(
  tokenInfo: TokenInfo,
): undefined | BridgeCurrency {
  const ethCurrency = tokenInfoToETHCurrency(tokenInfo)
  if (ethCurrency != null) return ethCurrency
  if (isBridgeCurrency(tokenInfo.id)) return tokenInfo.id
  return undefined
}

export function getCorrespondingBridgeCurrency(
  input: BridgeCurrency,
): BridgeCurrency {
  const mapping = bridgeCurrencyMap.find(map => map.includes(input))!
  return mapping[0] === input ? mapping[1] : mapping[0]
}

export const getBridgeCurrencyScale = (currency: BridgeCurrency): number => {
  return isWrappableBridgeCurrency(currency)
    ? Math.pow(10, decimalsForETHCurrency(currency))
    : isUnwrappableBridgeCurrency(currency)
    ? currencyScale(
        WrappedCurrency.isWrapped(currency)
          ? WrappedCurrency.unwrap(currency)
          : currency,
      )
    : assertNever(currency)
}

export const parseBridgeCurrencyServerBigIntAmount = (
  amount: string,
  currency: BridgeCurrency,
): number => {
  return parseE18String(amount)
}
export const toBridgeCurrencyServerBigIntAmount = (
  amount: number,
  currency: BridgeCurrency,
): string => {
  return toE18String(amount)
}
const parseE18String = (amount: string): number => {
  return new BigNumber(amount).div(1e18).toNumber()
}
const toE18String = (amount: BigNumber | number): string => {
  if (typeof amount === "number") {
    amount = new BigNumber(amount)
  }
  return amount.multipliedBy(1e18).toString(10)
}
