import AccountStore from "../../../stores/accountStore/AccountStore"
import AuthStore from "../../../stores/authStore/AuthStore"
import CurrencyStore from "../../../stores/currencyStore/CurrencyStore"
import { HistoryModule } from "./HistoryModule/HistoryModule"
import { WrapFormModule } from "./WrapFormModule"
import { WrapFormViewModule } from "./WrapFormViewModule"

export class WrapBridgeStore {
  history: HistoryModule
  wrapForm: WrapFormModule
  wrapFormViewModule: WrapFormViewModule

  constructor(
    private readonly currencyStore: Pick<
      CurrencyStore,
      "getTokenInfo$" | "getPrice$"
    >,
    private readonly accountStore: Pick<AccountStore, "getBalance$">,
    private readonly authStore: Pick<
      AuthStore,
      "metaMaskModule" | "stxAddress$"
    >,
  ) {
    this.history = new HistoryModule(currencyStore, accountStore, authStore)
    this.wrapForm = new WrapFormModule(accountStore, authStore)
    this.wrapFormViewModule = new WrapFormViewModule(
      currencyStore,
      authStore,
      this.wrapForm,
    )
  }

  destroy(): void {
    this.wrapForm.destroy()
  }
}
