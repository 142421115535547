import { sum } from "lodash"
import { action, computed, makeObservable, observable } from "mobx"
import { computedFn } from "mobx-utils"
import { asSender } from "../../../../generated/smartContractHelpers/asSender"
import { ConfirmTransactionStore } from "../../../../stores/confirmTransactionDialogStore/ConfirmTransactionStore"
import { LazyValue } from "../../../../stores/LazyValue/LazyValue"
import { SuspenseObservable } from "../../../../stores/SuspenseObservable"
import TimerValue from "../../../../stores/TimerValue"
import { Currency } from "../../../../utils/alexjs/Currency"
import { OrderbookStore } from "../OrderbookStore"
import type { StxDxAsset } from "../OrderbookStore.service/OrderbookStore.service"
import {
  allStxDxMarkets,
  getUserBalance,
  stxDxTokenPairs,
  UserBalance,
} from "../OrderbookStore.service/OrderbookStore.service"
import {
  fetchPNLInfo,
  isWalletAddressInWhitelist,
} from "./OrderbookMyInfoModule.service"

export class OrderbookMyInfoModule {
  constructor(readonly store: OrderbookStore) {
    makeObservable(this)
  }

  userId = new LazyValue(
    () =>
      [
        this.store.authStore.stxAddress$,
        this.store.chainStore.currentBlockHash$,
      ] as const,
    ([stxAddress]) =>
      asSender(stxAddress).contract("stxdx-registry").func("get-user-id").call({
        user: stxAddress,
      }),
  )

  @computed get userId$(): number | undefined {
    return this.userId.value$
  }

  @computed get registeredUserId$(): number {
    if (this.userId$ == null) {
      throw new Promise(() => null)
    }
    return this.userId$
  }

  private authJWT = new SuspenseObservable<string>()
  @action saveAuthJWT(token: string): void {
    this.authJWT.set(token)
  }

  timerEveryMinute = new TimerValue(60 * 1000)

  @computed get jwtTokenExpired(): boolean {
    const token = this.authJWT.get()
    if (token == null) {
      return true
    }
    const exp = JSON.parse(atob(token.split(".")[1]!)).exp
    return exp < new Date(this.timerEveryMinute.value).getTime() / 1000 + 120 // 2 minutes buffer time
  }

  @computed get authJWT$(): string {
    if (this.jwtTokenExpired) {
      throw new Promise(() => null)
    }
    return this.authJWT.read$
  }

  #userBalances = new LazyValue(
    () => [this.authJWT$, this.store.info.allAssetIdMaps] as const,
    ([jwt, assetMap]) => getUserBalance(jwt, assetMap),
  )

  stxDxBalance$ = computedFn((asset: StxDxAsset): UserBalance | undefined => {
    return this.#userBalances.value$[asset]
  })

  isWalletInWhitelist = new LazyValue(
    () =>
      [
        this.store.authStore.stxAddress$,
        this.store.chainStore.currentBlockHash$,
      ] as const,
    ([address]) => isWalletAddressInWhitelist(address),
  )

  @computed get isUsingRightNetwork$(): boolean {
    /**
     * Currently @stacks/connect is not able to provide current network info,
     * we need to keep following up on this feature
     *
     * https://github.com/hirosystems/connect/issues/263
     */
    return true
  }

  @computed get hasRegistered$(): boolean {
    return this.userId$ != null
  }

  @computed get canRegister$(): boolean {
    return this.store.account.getBalance$(Currency.W_STX) > 10
  }

  txStore = new ConfirmTransactionStore()

  @observable showingAgreement = false
  @action showAgreement(): void {
    this.showingAgreement = true
  }

  @observable showingSwitchNetworkGuide = false
  @action showSwitchNetworkGuide = (): void => {
    this.showingSwitchNetworkGuide = true
  }
  @action hideSwitchNetworkGuide = (): void => {
    this.showingSwitchNetworkGuide = false
  }

  @action startDeposit(currency?: StxDxAsset): void {
    this.showingAgreement = false
    if (currency != null) {
      this.store.myInfo.currency.set(currency)
    }
    this.store.deposit.depositing = true
  }

  @action startWithdraw(currency?: StxDxAsset): void {
    if (currency != null) {
      this.store.myInfo.currency.set(currency)
    }
    this.store.withdraw.withdrawing = true
  }

  currency = new SuspenseObservable<StxDxAsset>(Currency.W_XUSD)

  @computed get selectedTokenAvailableBalance$(): number {
    return this.stxDxBalance$(this.currency.read$)?.available ?? 0
  }

  @computed get selectedTokenLockedBalance$(): number {
    return this.stxDxBalance$(this.currency.read$)?.locked ?? 0
  }

  @computed get totalBalanceInUSD$(): number {
    return (
      sum(
        allStxDxMarkets.map(market => {
          const amount =
            this.stxDxBalance$(stxDxTokenPairs(market).tradeToken)?.available ??
            0
          const price = this.store.info.currentPriceValue(market).value$
          return amount * price
        }),
      ) + (this.stxDxBalance$(Currency.W_XUSD)?.available ?? 0)
    )
  }

  todayPNL = new LazyValue(
    () => [this.registeredUserId$, this.authJWT$] as const,
    ([uid, token]) => fetchPNLInfo(uid, token),
  )
}
