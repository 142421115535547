import { FC } from "react"
import {
  CardInset,
  CardInsetDivider,
  CardInsetTitle,
} from "../../../../../components/Card"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { LotteryTicket } from "../../types"
import { LotteryTicketCardGrid } from "../../_/LotteryTicketCardGrid"
import { PurpleBlock } from "../../_/PurpleBlock"
import { ReactComponent as AfterRegisteredIcon } from "./_/afterRegisteredImage.svg"

export const ActionPanel$DrawForJoined: FC<{
  startedAtBlock: number
  prizeToken: TokenInfo
  lotteryTickets: LotteryTicket[]
}> = props => {
  return (
    <CardInset className={"flex flex-col items-center gap-6"}>
      <div className={"flex flex-col items-center gap-4"}>
        <AfterRegisteredIcon />

        <CardInsetTitle>
          You have joined this round, Lottery in drawing
        </CardInsetTitle>

        <PurpleBlock
          primaryText={"Lottery drawing"}
          secondaryText={<>Draw at: Block {props.startedAtBlock}</>}
        />
      </div>

      <CardInsetDivider />

      <CardInsetTitle>My Lottery Ticket</CardInsetTitle>

      <LotteryTicketCardGrid
        prizeToken={props.prizeToken}
        lotteryTickets={props.lotteryTickets}
      />
    </CardInset>
  )
}
