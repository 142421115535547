import { ComponentType, FC } from "react"
import { Button } from "../../../../components/button/Button"
import { ButtonVariantProps } from "../../../../components/button/ButtonVariant"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { Spensor } from "../../../../components/Spensor"
import {
  readResource,
  SuspenseResource,
} from "../../../../utils/SuspenseResource"

export const SubmitButton: FC<{
  className?: string
  wrapDirection: "wrap" | "unwrap"
  SubmitButtonVariant?: ComponentType<ButtonVariantProps>
  onSubmit: SuspenseResource<undefined | (() => void)>
}> = props => {
  const { SubmitButtonVariant = GradientFilledButton } = props

  const text = props.wrapDirection === "wrap" ? "Wrap" : "Unwrap"

  return (
    <Spensor
      fallback={
        <Button
          className={props.className}
          Variant={SubmitButtonVariant}
          disabled={true}
        >
          {text}
        </Button>
      }
    >
      {() => (
        <Button
          className={props.className}
          Variant={SubmitButtonVariant}
          disabled={readResource(props.onSubmit) == null}
          onClick={readResource(props.onSubmit)}
        >
          {text}
        </Button>
      )}
    </Spensor>
  )
}
