import clsx from "clsx"
import { FC, ReactNode } from "react"
import { GrayBadge, GreenBadge, YellowBadge } from "../../../components/Badge"
import { OpacityButton } from "../../../components/button/variants/OpacityButton"
import {
  Card,
  CardDivider,
  CardInset,
  CardInsetDescription,
  CardTitle,
} from "../../../components/Card"
import { PercentNumber } from "../../../components/PercentNumber"
import { IconTokenCount } from "../../../components/RichTokenCount"
import { Spensor } from "../../../components/Spensor"
import { TokenCount } from "../../../components/TokenCount"
import { TokenCountPairPlain } from "../../../components/TokenInput/TokenBlock"
import { TokenName } from "../../../components/TokenName"
import { readResource, SuspenseResource } from "../../../utils/SuspenseResource"
import { LotteryStage, LotteryStageType } from "./types"
import { Burn } from "./_/Burn"
import { CopperCrown } from "./_/CopperCrown"
import { GoldenCrown } from "./_/GoldenCrown"
import { SilverCrown } from "./_/SilverCrown"

export const PrizePoolPanel: FC<{
  className?: string
  roundNumber: SuspenseResource<number>
  lotteryStage: SuspenseResource<LotteryStage>
  totalLotteryPrize: SuspenseResource<TokenCountPairPlain>
  alexOfferedLotteryPrize: SuspenseResource<TokenCountPairPlain>
  onClickRules: () => void
}> = props => {
  return (
    <Card className={clsx("flex flex-col gap-6", props.className)}>
      <div className={"flex flex-row items-center"}>
        <CardTitle className={"flex items-center"}>
          Prize Pool&nbsp;
          <Spensor>
            {() => (
              <StatusBadge stageType={readResource(props.lotteryStage).type} />
            )}
          </Spensor>
        </CardTitle>

        <span className={"ml-auto text-lg text-gray-400"}>
          Round #
          <Spensor fallback="--">
            {() => readResource(props.roundNumber)}
          </Spensor>
        </span>
      </div>

      <div className={"flex flex-col gap-2.5"}>
        <Spensor>
          {() => (
            <IconTokenCount
              iconSize={32}
              className={"text-2xl font-medium text-yellow-200"}
              token={readResource(props.totalLotteryPrize).token}
              count={readResource(props.totalLotteryPrize).count}
            />
          )}
        </Spensor>

        <Spensor>
          {() => (
            <CardInsetDescription>
              ({" "}
              <TokenCount
                token={readResource(props.alexOfferedLotteryPrize).token}
                count={readResource(props.alexOfferedLotteryPrize).count}
              />{" "}
              <TokenName
                token={readResource(props.alexOfferedLotteryPrize).token}
              />{" "}
              from ALEX Lab Foundation )
            </CardInsetDescription>
          )}
        </Spensor>

        <CardDivider className={"w-full"} />
      </div>

      <CardInset className={"flex flex-col gap-4"} boxClassName={"p-4"}>
        <ul className={"flex flex-col gap-2.5"}>
          <li>
            <PoolRow
              Icon={GoldenCrown}
              title={"1st Prize Pool"}
              detail={<PercentNumber number={0.5} />}
            />
          </li>
          <li>
            <PoolRow
              Icon={SilverCrown}
              title={"2nd Prize Pool"}
              detail={<PercentNumber number={0.3} />}
            />
          </li>
          <li>
            <PoolRow
              Icon={CopperCrown}
              title={"3rd Prize Pool"}
              detail={<PercentNumber number={0.2} />}
            />
          </li>
          <li>
            <PoolRow
              Icon={Burn}
              title={"Burn"}
              detail={
                <>
                  Winning Amount &times; <PercentNumber number={0.2} />
                </>
              }
            />
          </li>
        </ul>
      </CardInset>

      <div>
        <OpacityButton
          className={"text-base font-medium min-w-[240px]"}
          onClick={props.onClickRules}
        >
          Lottery Rules
        </OpacityButton>
      </div>
    </Card>
  )
}

const StatusBadge: FC<{ stageType: LotteryStage["type"] }> = props => (
  <>
    {props.stageType === LotteryStageType.Finished ? (
      <GrayBadge className={"uppercase"}>Finished</GrayBadge>
    ) : props.stageType === LotteryStageType.Drawing ? (
      <YellowBadge className={"uppercase"}>Drawing</YellowBadge>
    ) : (
      <GreenBadge className={"uppercase"}>Live</GreenBadge>
    )}
  </>
)

const PoolRow: FC<{
  Icon: (props: { width: number; height: number }) => null | JSX.Element
  title: ReactNode
  detail: ReactNode
}> = props => {
  return (
    <div className={"flex gap-2.5 items-center"}>
      <props.Icon width={24} height={24} />
      <div className={"text-sm text-white"}>{props.title}</div>
      <div className={"text-base font-bold text-gray-200 ml-auto"}>
        {props.detail}
      </div>
    </div>
  )
}
