import { FC } from "react"
import { TokenCount } from "../../../../components/TokenCount"
import { Currency } from "../../../../utils/alexjs/Currency"
import { AllTokenInfos } from "../../types"

export interface OneLineTokenCountProps {
  currency: Currency
  count: number
  allTokenInfos: AllTokenInfos
}

export const OneLineTokenCount: FC<OneLineTokenCountProps> = ({
  currency,
  count,
  allTokenInfos,
}) => {
  if (!allTokenInfos[currency]) {
    throw new Error("Token info not found for currency: " + currency)
  }
  return <TokenCount token={allTokenInfos[currency]!} count={count} />
}
