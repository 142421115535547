import clsx from "clsx"
import { FC } from "react"
import LogoImgSrc from "./_/logo.svg"

export const NewBrandFooter: FC<{ className?: string }> = props => (
  <div
    className={clsx(
      "flex items-center justify-center gap-[15px]",
      props.className,
    )}
  >
    <span className={"text-gray-500"}>Powered by ALEX</span>
    <img width={120} src={LogoImgSrc} alt={"ALEX Logo"} />
  </div>
)
