import { FC } from "react"
import { Card, CardDescription, CardTitle } from "../../../components/Card"
import { NavLink } from "../../../components/NavLink"
import { SystemMessages } from "../types"

interface Message {
  title: string
  description: string
  link: string
  linkText: string
}

export interface SystemMessageListProps {
  data: Array<SystemMessages.Data & { link: string }>
}

export const SystemMessageList: FC<SystemMessageListProps> = props => {
  return (
    <div className="flex flex-col gap-y-2.5">
      {props.data
        .map(datum => ({ ...parseData(datum), link: datum.link }))
        .map(message => (
          <NavLink to={message.link}>
            <Card key={message.title} className="flex flex-col gap-y-2">
              <div className="flex flex-wrap">
                <CardTitle className="grow">{message.title}</CardTitle>
              </div>
              <CardDescription>
                <span>{message.description}</span>
                &nbsp;
                <span className="text-blue-600">{message.linkText}</span>
              </CardDescription>
            </Card>
          </NavLink>
        ))}
    </div>
  )
}

function parseData(data: SystemMessages.Data): Omit<Message, "link"> {
  if (data.type === SystemMessages.Types.FarmingHarvest) {
    return parseFarmingData(data)
  }
  if (data.type === SystemMessages.Types.StakingHarvest) {
    return parseStakingData(data)
  }
  if (data.type === SystemMessages.Types.DepositClaim) {
    return parseDepositClaimData(data)
  }
  if (data.type === SystemMessages.Types.BorrowClaim) {
    return parseBorrowClaimData(data)
  }
  throw new Error("Invalid data")
}

function parseFarmingData(_data: SystemMessages.Data): Omit<Message, "link"> {
  return {
    title: "New Farming rewards to harvest",
    description: "You have received rewards from LP farming.",
    linkText: "Harvest now.",
  }
}

function parseStakingData(_data: SystemMessages.Data): Omit<Message, "link"> {
  return {
    title: "New Staking rewards to harvest",
    description: "You have received rewards from ALEX staking.",
    linkText: "Harvest now.",
  }
}

function parseDepositClaimData(
  _data: SystemMessages.Data,
): Omit<Message, "link"> {
  return {
    title: "Maturity Deposit to roll over",
    description: "",
    linkText: "Click for details.",
  }
}

function parseBorrowClaimData(
  _data: SystemMessages.Data,
): Omit<Message, "link"> {
  return {
    title: "Matured Borrow Redemption to claim/rollover",
    description: "",
    linkText: "Click for details.",
  }
}
