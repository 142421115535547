import clsx from "clsx"
import { FCC } from "../../../utils/reactHelpers/types"
import { withClassName } from "../../../utils/reactHelpers/withClassName"
import { GradientText } from "../../GradientText"
import styles from "./NavButton.module.scss"

export const NavButton: FCC<{
  className?: string
  active?: boolean
  highlighted?: boolean
}> = props => (
  <div
    className={clsx(
      "text-base leading-6 font-medium cursor-pointer whitespace-nowrap",
      "text-gray-100 hover:text-gray-300 active:text-gray-400 ",
      props.active && styles.active,
      props.className,
    )}
  >
    {props.highlighted ? (
      <HighlightedNavButtonContent>
        {props.children}
      </HighlightedNavButtonContent>
    ) : (
      props.children
    )}
  </div>
)

const HighlightedNavButtonContent: FCC = props => {
  return (
    <span className={"hover:opacity-80 active:opacity-60"}>
      <GradientText
        gradientStyle={
          "linear-gradient(90deg, #EEEEEE 0.12%, #93C5FD 49.08%, #FB923C 100.12%)"
        }
      >
        {props.children}
      </GradientText>
      ✨
    </span>
  )
}

export const NavButtonContainer = withClassName(
  "px-3 h-full flex items-center",
  "div",
)
