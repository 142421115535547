import { FC, SVGProps } from "react"
import { PlainIconButton } from "../../../../components/button/variants/PlainIconButton"
import {
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../components/InfoList"
import { PercentNumber } from "../../../../components/PercentNumber"
import { Spensor } from "../../../../components/Spensor"
import { longFormatDuration } from "../../../../components/TimeCountdown/helpers"
import { TimeCountdownText } from "../../../../components/TimeCountdown/TimeCountdownText"
import { TipIcon } from "../../../../components/TipIcon/TipIcon"
import { TokenCount } from "../../../../components/TokenCount"
import { TokenName } from "../../../../components/TokenName"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import {
  readResource,
  SuspenseResource,
} from "../../../../utils/SuspenseResource"

export interface DepositInfoListContentProps {
  depositToken: TokenInfo
  expirationDate: SuspenseResource<Date>
  expirationBlockHeight: SuspenseResource<number>
  estimateAbsoluteInterest: SuspenseResource<number>
  estimateClaimableValue: SuspenseResource<number>
  liquidityProviderFee: SuspenseResource<number>
  slippage: number
  onEditSlippage?: () => void
}

export const DepositInfoListContent: FC<
  DepositInfoListContentProps
> = props => {
  return (
    <>
      <InfoListItem>
        <InfoListItemTitle>
          Maturity{" "}
          <TipIcon
            inline
            tip={
              "The expiration is the maturity block of your deposits. You are guaranteed to withdraw the principal and interest when maturity is reached."
            }
          />
        </InfoListItemTitle>
        <InfoListItemDetail>
          Block #
          <Spensor fallback="--">
            {() => readResource(props.expirationBlockHeight)}
          </Spensor>{" "}
          (Est.{" "}
          <Spensor fallback="--">
            {() => (
              <TimeCountdownText
                time={readResource(props.expirationDate)}
                format={d =>
                  longFormatDuration(d, {
                    includes: ["days", "hours", "minutes"],
                  })
                }
              />
            )}
          </Spensor>
          )
        </InfoListItemDetail>
      </InfoListItem>

      <InfoListItem>
        <InfoListItemTitle>Est. Interest</InfoListItemTitle>
        <InfoListItemDetail>
          <Spensor fallback="--">
            {() => (
              <TokenCount
                token={props.depositToken}
                count={readResource(props.estimateAbsoluteInterest)}
              />
            )}
          </Spensor>
          &nbsp;
          <TokenName token={props.depositToken} />
        </InfoListItemDetail>
      </InfoListItem>

      <InfoListItem>
        <InfoListItemTitle>Est. Claimable Value</InfoListItemTitle>
        <InfoListItemDetail>
          <Spensor fallback="--">
            {() => (
              <TokenCount
                token={props.depositToken}
                count={readResource(props.estimateClaimableValue)}
              />
            )}
          </Spensor>
          &nbsp;
          <TokenName token={props.depositToken} />
        </InfoListItemDetail>
      </InfoListItem>

      <InfoListItem>
        <InfoListItemTitle>Liquidity Provider Fee</InfoListItemTitle>
        <InfoListItemDetail className={"flex items-center"}>
          <Spensor fallback="--">
            {() => (
              <TokenCount
                token={props.depositToken}
                count={readResource(props.liquidityProviderFee)}
              />
            )}
          </Spensor>
          &nbsp;
          <TokenName token={props.depositToken} />
        </InfoListItemDetail>
      </InfoListItem>

      <InfoListItem>
        <InfoListItemTitle>Slippage Tolerance</InfoListItemTitle>
        <InfoListItemDetail className={"flex items-center"}>
          <PercentNumber number={props.slippage} />

          {props.onEditSlippage && (
            <>
              &nbsp;
              <PlainIconButton icon={EditIcon} onClick={props.onEditSlippage} />
            </>
          )}
        </InfoListItemDetail>
      </InfoListItem>
    </>
  )
}

export const EditIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M4.75164 15.0427L0.000976562 16L0.959643 11.25L4.75164 15.0427ZM1.90231 10.3067L5.69431 14.0993L16.001 3.79267L12.2083 0L1.90231 10.3067Z" />
  </svg>
)
