import { FC } from "react"
import {
  CardInset,
  CardInsetDivider,
  CardInsetSubTitle,
  CardInsetTitle,
} from "../../../../../components/Card"
import { TokenCount } from "../../../../../components/TokenCount"
import { TokenName } from "../../../../../components/TokenName"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { TokenInfoPresets } from "../../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { IDOFailedIcon } from "../../IDOFailedIcon/IDOFailedIcon"
import {
  HorizontalInfoListContainer,
  HorizontalInfoListItem,
} from "../../InfoList"
import { ExplorerLink } from "./ExplorerLink/ExplorerLink"
import { PurpleBlock } from "./PurpleBlock"

export const ActionSectionContent$FinishedFailed: FC<{
  priceToken: TokenInfo
  userAssets?: {
    registeredTicketCount: number
    lockedAPowerCount: number
    lockedPriceTokenCount: number
    returnPriceTokenExplorerLink?: string
  }
}> = props => {
  return (
    <CardInset className={"flex flex-col items-center gap-6"}>
      <div className={"flex flex-col items-center gap-4"}>
        <IDOFailedIcon />

        <CardInsetTitle>The project has failed to launch</CardInsetTitle>

        <CardInsetSubTitle>
          Please look forward to other projects
        </CardInsetSubTitle>
      </div>

      {props.userAssets && (
        <>
          <CardInsetDivider />

          <HorizontalInfoListContainer>
            <HorizontalInfoListItem
              title={"Validated Tickets"}
              detail={props.userAssets.registeredTicketCount}
            />

            <HorizontalInfoListItem
              title={
                <>
                  Validated&nbsp;
                  <TokenName token={TokenInfoPresets.APower} />
                </>
              }
              detail={
                <TokenCount
                  token={TokenInfoPresets.APower}
                  count={props.userAssets.lockedAPowerCount}
                />
              }
            />

            <HorizontalInfoListItem
              title={
                <>
                  Locked&nbsp;
                  <TokenName token={props.priceToken} />
                </>
              }
              detail={
                <TokenCount
                  token={props.priceToken}
                  count={props.userAssets.lockedPriceTokenCount}
                />
              }
            />
          </HorizontalInfoListContainer>
        </>
      )}

      {props.userAssets && (
        <PurpleBlock
          className={"mt-4"}
          primaryText={
            <>
              Your locked <TokenName token={props.priceToken} /> and{" "}
              <TokenName token={TokenInfoPresets.APower} /> will return to your
              wallet.
            </>
          }
          bottom={
            props.userAssets.returnPriceTokenExplorerLink && (
              <ExplorerLink
                link={props.userAssets.returnPriceTokenExplorerLink}
              />
            )
          }
        />
      )}
    </CardInset>
  )
}
