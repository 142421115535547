import {
  ParameterObjOfDescriptor,
  ReturnTypeOfDescriptor,
  UnboxResponse,
  unwrapResponse,
} from "clarity-codegen"
import { CONTRACT_DEPLOYER } from "../../../config"
import {
  asSender,
  Contracts,
} from "../../../generated/smartContractHelpers/asSender"
import { Currency } from "../../../utils/alexjs/Currency"

export type LaunchToken = Currency.ALEX | Currency.W_BANANA
export type TicketToken = Currency.APOWER
export type PaymentToken = Currency.W_STX | Currency.ALEX

export type LaunchPadDetail = NonNullable<
  UnboxResponse<
    ReturnTypeOfDescriptor<Contracts["alex-launchpad-v1-1"]["get-ido"]>
  >
>

export type WalkParameters = UnboxResponse<
  ReturnTypeOfDescriptor<
    Contracts["alex-launchpad-v1-1"]["get-offering-walk-parameters"]
  >
>

export type CreateIdoOptions = ParameterObjOfDescriptor<
  Contracts["alex-launchpad-v1-1"]["create-pool"]
>["offering"]

export async function fetchLaunchPad(idoId: number): Promise<LaunchPadDetail> {
  const result = await asSender(CONTRACT_DEPLOYER)
    .contract("alex-launchpad-v1-1")
    .func("get-ido")
    .call({ "ido-id": idoId })
    .then(unwrapResponse)
  if (result == null) {
    throw new Error(`IdoID ${idoId} not found`)
  }
  return result
}

export async function fetchLaunchPadCurrentRegistered(
  idoId: number,
): Promise<number> {
  return await asSender(CONTRACT_DEPLOYER)
    .contract("alex-launchpad-v1-1")
    .func("get-total-tickets-registered")
    .call({ "ido-id": idoId })
}

export const idoWalkResolution = 1e5

export async function fetchUserSubscription(
  stxAddress: string,
  idoId: number,
): Promise<{ start: number; end: number } | undefined> {
  return await asSender(stxAddress)
    .contract("alex-launchpad-v1-1")
    .func("get-offering-ticket-bounds")
    .call({ "ido-id": idoId, owner: stxAddress })
    .then(a =>
      a == null
        ? undefined
        : {
            start: a.start / idoWalkResolution,
            end: a.end / idoWalkResolution,
          },
    )
}
