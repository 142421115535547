import { FC } from "react"
import { GradientFilledButton } from "../../../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { CardDivider } from "../../../../../../components/Card"
import { CardModalContent } from "../../../../../../components/CardModal/CardModal"
import { NoteParagraph } from "../../../../../../components/NoteParagraph/NoteParagraph"
import { TokenCount } from "../../../../../../components/TokenCount"
import { TokenIcon } from "../../../../../../components/TokenIcon"
import { TokenName } from "../../../../../../components/TokenName"
import { TokenInfo } from "../../../../../../utils/models/TokenInfo"
import { TokenInfoPresets } from "../../../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { ReactComponent as CanOnlyValidateOnceIcon } from "./canOnlyRegisterOnce.svg"

export const RegisterConfirmModalContent: FC<{
  lotteryTicketCount: number
  aPowerCount: number
  priceToken: TokenInfo
  priceTokenCount: number
  onConfirm?: () => void
  onClose?: () => void
}> = props => {
  return (
    <CardModalContent onClose={props.onClose} title={"Validate"}>
      <p className={"text-gray-200 text-center"}>
        Please confirm your validation details
      </p>

      <CardDivider />

      <RegisterConfirmModalTokenLine
        token={TokenInfoPresets.IDOLotteryTicket}
        count={props.lotteryTicketCount}
      />

      <CardDivider />

      <RegisterConfirmModalTokenLine
        token={TokenInfoPresets.APower}
        count={props.aPowerCount}
      />
      <RegisterConfirmModalTokenLine
        token={props.priceToken}
        count={props.priceTokenCount}
      />

      <CardDivider />

      <NoteParagraph
        textColorClassName={"text-yellow-500"}
        icon={<CanOnlyValidateOnceIcon />}
      >
        Lottery validation can only be submitted once per wallet
      </NoteParagraph>

      <GradientFilledButton
        className={"block w-full"}
        onClick={props.onConfirm}
      >
        Confirm
      </GradientFilledButton>
    </CardModalContent>
  )
}

const RegisterConfirmModalTokenLine: FC<{
  token: TokenInfo
  count: number
}> = props => (
  <p className={"text-gray-200 flex items-center"}>
    <TokenIcon className={"mr-2.5"} token={props.token} />

    <TokenName
      className={"text-xl leading-7 font-medium mr-auto"}
      token={props.token}
    />

    <span className={"text-3xl leading-9 font-medium"}>
      <TokenCount token={props.token} count={props.count} />
    </span>
  </p>
)
