import { FC } from "react"
import { useConnect } from "../../../components/ConnectWallet/ConnectProvider"
import { LoadingIndicator } from "../../../components/LoadingIndicator/LoadingIndicator"
import { Spensor } from "../../../components/Spensor"
import { useAuthStore } from "../../../stores/authStore/useAuthStore"
import { useLockdropDetailStore } from "../store/useLockdropDetailStore"
import {
  LockdropMyStatus,
  LockdropMyStatus$ConnectWallet,
  LockdropMyStatus$Loading,
} from "./MyStatus/MyStatus"
import { LockdropMyStatusContainer } from "./MyStatus/MyStatusContainer"

export const WiredLockdropMyStatus: FC = () => {
  const authStore = useAuthStore()
  const store = useLockdropDetailStore()
  const [connect] = useConnect()
  return (
    <Spensor
      fallback={
        <LockdropMyStatusContainer>
          {authStore.isWalletConnected ? (
            <LockdropMyStatus$Loading />
          ) : (
            <>
              <Spensor fallback={<LoadingIndicator className="mx-auto" />}>
                {() => <LockdropMyStatus$ConnectWallet onConnect={connect} />}
              </Spensor>
            </>
          )}
        </LockdropMyStatusContainer>
      }
    >
      {() => <LockdropMyStatus {...store.myStatusProps$} />}
    </Spensor>
  )
}
