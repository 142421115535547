import { TokenInfo } from "../../../utils/models/TokenInfo"

export type WrapBridgeNetwork = {
  id: string
  icon: string
  name: string
}

export type WrapBridgeSegment = {
  network: WrapBridgeNetwork
  estimatedAmount: number
  amount?: number
  token: TokenInfo
  address: string
  addressExploreLink: string
}

export type WrapBridgeSegmentStatus =
  | {
      type: "warning"
      message: string
      explorerLink?: string
    }
  | {
      type: "inProgress"
      message: string
      explorerLink?: string
    }
  | {
      type: "success"
      explorerLink?: string
    }

export type WrapBridgeHistoryRecord =
  | WrapBridgeHistoryRecord.Historical
  | WrapBridgeHistoryRecord.SignMissing
export namespace WrapBridgeHistoryRecord {
  export interface Common {
    orderNumber: string
    source: WrapBridgeSegment
    createdAt: Date
  }

  export interface Historical extends Common {
    target: WrapBridgeSegment
    fee: number
    feeToken: TokenInfo
    status: {
      source: WrapBridgeSegmentStatus
      wrapping?: WrapBridgeSegmentStatus
      destination?: WrapBridgeSegmentStatus
    }
  }

  export interface SignMissing extends Common {
    status: {
      source: WrapBridgeSegmentStatus
      wrapping?: WrapBridgeSegmentStatus
    }
    onSignMessage: () => void
  }

  export function isSignMissing(r: WrapBridgeHistoryRecord): r is SignMissing {
    return !("target" in r)
  }
}

export enum FormErrorType {
  InsufficientTokenBalance = "InsufficientTokenBalance",
  LessThanMinimizeAmount = "LessThanMinimizeAmount",
  AmountIsEmpty = "AmountIsEmpty",
  SourceWalletNotConnected = "SourceWalletNotConnected",
  TargetWalletNotConnected = "TargetWalletNotConnected",

  UnsupportedSourceToken = "UnsupportedSourceToken",

  UnsupportedSourceChain = "UnsupportedSourceChain",
  UnsupportedTargetChain = "UnsupportedTargetChain",
}

export type FormError = {
  type: FormErrorType
  message?: string
}
