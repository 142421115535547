import clsx from "clsx"
import { FC, ReactNode } from "react"
import { Link } from "react-router-dom"
import { usePathGenerator } from "../../routes/routes"
import { FCC } from "../../utils/reactHelpers/types"
import { readResource, SuspenseResource } from "../../utils/SuspenseResource"
import { Dropdown, DropdownDivider, DropdownItem } from "../Dropdown"
import { NavLink } from "../NavLink"
import { Spensor } from "../Spensor"
import { ReactComponent as AboutIcon } from "./about.svg"
import { ReactComponent as AlexLogo } from "./alexLogo.svg"
import { ReactComponent as AlexLogoText } from "./alexLogoText.svg"
import { ReactComponent as AnalyticsIcon } from "./analytics.svg"
import { ReactComponent as BugIcon } from "./bug.svg"
import { ReactComponent as DiscordIcon } from "./discord.svg"
import { MenuButton } from "./MenuButton/MenuButton"
import {
  NavBarButtonItem,
  NavBarDropdownItem,
  NavBarItemData,
} from "./NavBarItem"
import { ReactComponent as TokenIcon } from "./token.svg"
import { BalanceBar, BalanceBarProps } from "./_/BalanceBar"
import { BalanceButton, BalanceButtonProps } from "./_/BalanceButton"

const AutoHideBalanceComps: FCC = props => {
  return <div className={"hidden md:block"}>{props.children}</div>
}

export const _BalanceButton: FC<BalanceButtonProps> = props => {
  return (
    <AutoHideBalanceComps>
      <BalanceButton
        {...props}
        className={`cursor-pointer ${props.className}`}
      />
    </AutoHideBalanceComps>
  )
}

export const _BalanceBar: FC<BalanceBarProps> = props => {
  return (
    <AutoHideBalanceComps>
      <BalanceBar {...props} />
    </AutoHideBalanceComps>
  )
}

export const TopNavBarHeight = 80

export interface TopNavBarProps {
  buttons: SuspenseResource<NavBarItemData[]>
  className?: string
  rightSide: ReactNode
}

export const TopNavBar: FC<TopNavBarProps> = props => {
  const gen = usePathGenerator()

  return (
    <div
      className={clsx(
        "flex items-center min-h-[5rem] py-5 px-4 sm:px-8 backdrop-blur-lg bg-gray-900/50 space-between gap-1",
        props.className,
      )}
    >
      <Link
        className={"flex items-center cursor-pointer gap-1.5"}
        to={gen.index()}
      >
        <AlexLogo width={32} height={32} />
        <AlexLogoText className="hidden md:block" width={106} height={28} />
      </Link>

      <div className={"flex-1"}>
        <div className={"mx-[38px] justify-start hidden lg:flex"}>
          <Spensor>
            {() => (
              <>
                {readResource(props.buttons).map((b, idx) =>
                  // prettier-ignore
                  b.type === 'dropdown' ? <NavBarDropdownItem key={idx} item={b} /> :
                  <NavBarButtonItem key={idx} item={b} />,
                )}
              </>
            )}
          </Spensor>
        </div>
      </div>

      <div className={"flex gap-2 sm:gap-6 items-center"}>
        {props.rightSide && (
          <div className="flex items-center gap-2">{props.rightSide}</div>
        )}

        <Dropdown
          placement={"end"}
          trigger={({ isOpen }) => <MenuButton isOpen={isOpen} />}
          dismissMethod={["hover-outside", "click"]}
        >
          <Spensor>
            {() => (
              <>
                {readResource(props.buttons)
                  .map(b => (b.type === "dropdown" ? b.buttons : b))
                  .flat()
                  .map(b => (
                    <NavLink
                      key={b.link(gen)}
                      className={"lg:hidden"}
                      to={b.link(gen)}
                    >
                      <DropdownItem>{b.name}</DropdownItem>
                    </NavLink>
                  ))}
              </>
            )}
          </Spensor>
          <DropdownDivider className={"lg:hidden"} />

          <NavLink to="https://alexgo.io/">
            <DropdownItem icon={<AboutIcon className={"fill-current"} />}>
              About
            </DropdownItem>
          </NavLink>
          <NavLink to="https://discord.gg/alexgo">
            <DropdownItem icon={<DiscordIcon className={"fill-current"} />}>
              Discord
            </DropdownItem>
          </NavLink>
          <NavLink to={gen.analytics()} target="_blank">
            <DropdownItem icon={<AnalyticsIcon className={"fill-current"} />}>
              Analytics
            </DropdownItem>
          </NavLink>
          <NavLink to="https://immunefi.com/bounty/alex/" target="_blank">
            <DropdownItem icon={<BugIcon className={"fill-current"} />}>
              Bug Bounty
            </DropdownItem>
          </NavLink>
          <NavLink to={gen.tokenList()}>
            <DropdownItem icon={<TokenIcon className={"fill-current"} />}>
              Token List
            </DropdownItem>
          </NavLink>
        </Dropdown>
      </div>
    </div>
  )
}
