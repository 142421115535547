import { FC, ReactNode } from "react"
import { createStore } from "../../utils/createStore"
import { useCreation } from "../../utils/reactHelpers/useCreation"
import { ChainStore } from "./ChainStore"

const { Provider, useStore } = createStore<ChainStore>("ChainStore")

export const ChainStoreProvider: FC<{
  children: ReactNode
}> = props => {
  const store = useCreation(() => new ChainStore(), [])
  return <Provider store={store}>{props.children}</Provider>
}

export const useChainStore = useStore
