import clsx from "clsx"
import { FC } from "react"
import { APowerTip } from "../../../../components/APowerTip/APowerTip"
import { GreenBadge } from "../../../../components/Badge"
import { PercentNumber } from "../../../../components/PercentNumber"
import { TokenIcon } from "../../../../components/TokenIcon"
import { withClassName } from "../../../../utils/reactHelpers/withClassName"
import { TokenInfoPresets } from "../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { CycleStatusBadge } from "../../../Stake/components/CycleStatusBadge"
import { StakeCycleStatus } from "../../../Stake/types"
import { LiquidityNumber } from "../LiquidityNumber"
import { ListButton } from "../ListButton"
import { ListItemContainer, ListSection } from "../ListContainer"
import { ReactComponent as IntoIcon } from "../MyFarmsPanel/into.svg"
import { TruncateTokenName } from "../TruncateTokenName"
import { OtherFarm } from "./AllFarmsPanel"

export const OtherFarmListRowHeader: FC = () => {
  return (
    <ListItemContainer
      className={
        "p-4 border-b border-gray-500/30 text-sm leading-5 font-normal text-gray-500"
      }
    >
      <HeaderListSection type={"head"}>Trading Pair</HeaderListSection>
      <HeaderListSection>Liquidity</HeaderListSection>
      <HeaderListSection>Farm APR</HeaderListSection>
      <HeaderListSection>Fee Rebate</HeaderListSection>
      <HeaderListSection>Reward</HeaderListSection>
      <HeaderListSection />
      <HeaderListSection type={"tail"} />
    </ListItemContainer>
  )
}

const HeaderListSection = withClassName(
  "flex flex-row items-center gap-2.5",
  ListSection,
)

export const OtherFarmListRow: FC<{
  className?: string
  farm: OtherFarm
  onClick?: () => void
}> = props => {
  return (
    <ListItemContainer
      className={clsx(
        "group p-4 border-b border-gray-500/30 text-gray-200 hover:bg-black/10 active:bg-black/20",
        props.className,
      )}
    >
      <ListSection
        type={"head"}
        className={"flex flex-row items-center gap-2.5"}
      >
        <TokenIcon token={props.farm.liquidityPoolToken} />

        <TruncateTokenName
          className={"text-sm leading-5 font-medium truncate"}
          token={props.farm.liquidityPoolToken}
        />

        {props.farm.cycleStatus === StakeCycleStatus.Finished && (
          <CycleStatusBadge status={props.farm.cycleStatus} />
        )}

        {props.farm.isDualYield && <GreenBadge>Dual Yield</GreenBadge>}
      </ListSection>

      <ListSection className={"uppercase"}>
        $&nbsp;
        {props.farm.liquidityCount != null ? (
          <LiquidityNumber count={props.farm.liquidityCount} />
        ) : (
          "--"
        )}
      </ListSection>

      <ListSection>
        {props.farm.totalApr != null ? (
          <PercentNumber number={props.farm.totalApr} />
        ) : (
          "--"
        )}
      </ListSection>

      <ListSection>
        {props.farm.tradingFee != null ? (
          <PercentNumber number={props.farm.tradingFee} />
        ) : (
          "--"
        )}
      </ListSection>

      <ListSection>
        <ul>
          {props.farm.rewardTokens.map((token, idx) => (
            <li key={idx} className={"flex items-center gap-1.5"}>
              <TokenIcon token={token} size={16} />
              <TruncateTokenName token={token} />
              {TokenInfoPresets.isAPower(token) ? <APowerTip /> : null}
            </li>
          ))}
        </ul>
      </ListSection>

      <ListSection className={"flex items-center justify-center"}>
        <ListButton className={"invisible group-hover:visible"}>
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="#111827"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M10.5 5.25H6.75V1.5H5.25V5.25H1.5V6.75H5.25V10.5H6.75V6.75H10.5V5.25Z" />
          </svg>
          Stake LP
        </ListButton>
      </ListSection>

      <ListSection type={"tail"} className={"flex items-center justify-end"}>
        <IntoIcon className={"opacity-40"} />
      </ListSection>
    </ListItemContainer>
  )
}
