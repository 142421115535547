import React, { createContext, useContext, useEffect, useId } from "react"
import { FCC } from "../../utils/reactHelpers/types"
import { useCreation } from "../../utils/reactHelpers/useCreation"

export interface LoadingController {
  show(): void
  hide(): void
  cleanup(): void
}

export type LoadingControllerFactory = (
  componentId: unknown,
) => LoadingController

const LoadingControllerFactoryContext =
  createContext<null | LoadingControllerFactory>(null)

export const LoadingControllerFactoryProvider: FCC<{
  factory: LoadingControllerFactory
}> = props => (
  <LoadingControllerFactoryContext.Provider value={props.factory}>
    {props.children}
  </LoadingControllerFactoryContext.Provider>
)

export const useLoading = (): LoadingController => {
  const ctrlFactory = useContext(LoadingControllerFactoryContext)

  if (ctrlFactory == null) {
    throw new Error(
      "[useLoading] should be used in a LoadingControllerProvider subtree",
    )
  }

  const compId = useId()

  const ctrl = useCreation(() => ctrlFactory(compId), [compId, ctrlFactory])

  useEffect(() => () => ctrl.cleanup(), [ctrl])

  return ctrl
}
