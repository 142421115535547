import { FC } from "react"
import { GradientFilledButton } from "../../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteFilledButton } from "../../../../../components/button/variants/WhiteFilledButton"
import { CardPlate } from "../../../../../components/Card"
import { CardModalContent } from "../../../../../components/CardModal/CardModal"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../../components/InfoList"
import { NoteParagraph } from "../../../../../components/NoteParagraph/NoteParagraph"
import { PercentNumber } from "../../../../../components/PercentNumber"
import { Spensor } from "../../../../../components/Spensor"
import {
  readResource,
  SuspenseResource,
} from "../../../../../utils/SuspenseResource"
import {
  BorrowInfoListContent,
  BorrowInfoListContentProps,
} from "../BorrowInfoListContent"
import { ReactComponent as WarningIcon } from "./warning.svg"

export interface BorrowRolloverModalContentProps
  extends BorrowInfoListContentProps {
  flashLoanFee: SuspenseResource<number>
  onConfirm?: () => void
  onClose?: () => void
}

export const BorrowRolloverModalContent: FC<
  BorrowRolloverModalContentProps
> = props => (
  <CardModalContent
    onClose={props.onClose}
    title={"Rollover"}
    className={"flex flex-col gap-4"}
    width={480}
  >
    <CardPlate>
      <InfoList direction="column" listItemDirection="row-responsive">
        <InfoListItem>
          <InfoListItemTitle className="flex items-center gap-2">
            <WarningIcon />
            Flash Loan Fee
          </InfoListItemTitle>
          <InfoListItemDetail>
            <Spensor fallback="--">
              {() => (
                <PercentNumber number={readResource(props.flashLoanFee)} />
              )}
            </Spensor>
          </InfoListItemDetail>
        </InfoListItem>
      </InfoList>
    </CardPlate>

    <CardPlate>
      <InfoList direction={"column"} listItemDirection={"row-responsive"}>
        <BorrowInfoListContent
          interestToken={props.interestToken}
          expirationDate={props.expirationDate}
          expirationBlockHeight={props.expirationBlockHeight}
          estimateAbsoluteInterest={props.estimateAbsoluteInterest}
          crpInfo={props.crpInfo}
          slippage={props.slippage}
          onEditSlippage={props.onEditSlippage}
          liquidityProviderFee={props.liquidityProviderFee}
        />
      </InfoList>
    </CardPlate>

    <NoteParagraph>
      Your borrowing will rollover to the next maturity block (Est. 7 days).
    </NoteParagraph>

    <div className={"flex flex-row gap-2.5"}>
      <WhiteFilledButton className={"flex-1"} onClick={props.onClose}>
        Cancel
      </WhiteFilledButton>

      <GradientFilledButton className={"flex-1"} onClick={props.onConfirm}>
        Confirm
      </GradientFilledButton>
    </div>
  </CardModalContent>
)
