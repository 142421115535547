import { FC, ReactNode } from "react"
import { defineMessage, useIntl } from "react-intl"
import { cancel$t } from "../../../../commonIntlMessages"
import { GreenBadge } from "../../../../components/Badge"
import { btnPresets } from "../../../../components/button/Button"
import { SmartLoadableButton } from "../../../../components/button/LoadableButton"
import { OpacityButton } from "../../../../components/button/variants/OpacityButton"
import { EmptyState } from "../../../../components/EmptyState/EmptyState"
import {
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../components/InfoList"
import { LoadingIndicator } from "../../../../components/LoadingIndicator/LoadingIndicator"
import { Pagination, PaginationInfo } from "../../../../components/Pagination"
import { PercentNumber } from "../../../../components/PercentNumber"
import { TextTokenCount } from "../../../../components/RichTokenCount"
import { Spensor } from "../../../../components/Spensor"
import { TokenCount } from "../../../../components/TokenCount"
import { simplifyIdentifier } from "../../../../utils/stringHelpers"
import {
  readResource,
  SuspenseResource,
} from "../../../../utils/SuspenseResource"
import { assertNever } from "../../../../utils/types"
import { OpenOrderRecord, StxDxOrderType } from "../types"
import { fields$t, orderStatus$t } from "./_/commonIntlMessages"
import {
  ActionsCell,
  CreateDateCell,
  FieldCell,
  RecordRowContainer,
  TokenPairCell,
} from "./_/RecordRow"
import { TriggerConditionText } from "./_/TriggerConditionText"

export const OpenOrdersTabContent: FC<{
  records: SuspenseResource<OpenOrderRecord[]>
  pagination?: SuspenseResource<PaginationInfo>
  onChangePage?: (page: number) => void
}> = props => {
  return (
    <>
      <Spensor fallback={<LoadingIndicator className={"m-auto"} />}>
        {() =>
          readResource(props.records).length <= 0 ? (
            <EmptyState className={"m-auto"} />
          ) : (
            <ul className={"flex flex-col gap-1"}>
              {readResource(props.records).map((r, idx) => (
                <li key={idx}>
                  {
                    // prettier-ignore
                    r.orderType === StxDxOrderType.Limit ? (<LimitRow record={r} />) :
                  r.orderType === StxDxOrderType.Market ? (<MarketRow record={r} />) :
                  r.orderType === StxDxOrderType.StopLimit ? (<StopLimitRow record={r} />) :
                  assertNever(r)
                  }
                </li>
              ))}
            </ul>
          )
        }
      </Spensor>

      <Spensor>
        {() =>
          props.pagination != null ? (
            <Pagination
              {...readResource(props.pagination)}
              onChange={i => props.onChangePage?.(i.page)}
            />
          ) : null
        }
      </Spensor>
    </>
  )
}

const LimitRow: FC<{ record: OpenOrderRecord.Limit }> = props => (
  <RowTemplate
    record={props.record}
    price={
      <TokenCount
        token={props.record.priceToken}
        count={props.record.priceTokenCountPerTradeToken}
      />
    }
    total={
      <TextTokenCount
        token={props.record.priceToken}
        count={props.record.priceTokenTotalCount}
      />
    }
    triggerCondition={"-"}
  />
)

const MarketRow: FC<{ record: OpenOrderRecord.Market }> = props => (
  <RowTemplate
    record={props.record}
    price={"Market"}
    total={"-"}
    triggerCondition={"-"}
  />
)

const StopLimitRow: FC<{ record: OpenOrderRecord.StopLimit }> = props => (
  <RowTemplate
    record={props.record}
    price={
      <TokenCount
        token={props.record.priceToken}
        count={props.record.priceTokenCountPerTradeToken}
      />
    }
    total={
      <TextTokenCount
        token={props.record.priceToken}
        count={props.record.priceTokenTotalCount}
      />
    }
    triggerCondition={
      <TriggerConditionText
        priceToken={props.record.priceToken}
        triggerCondition={props.record.triggerCondition}
      />
    }
  />
)

const RowTemplate: FC<{
  record: OpenOrderRecord
  price: ReactNode
  total: ReactNode
  triggerCondition: ReactNode
}> = props => {
  const intl = useIntl()
  const { $t } = intl

  const { record } = props

  return (
    <RecordRowContainer>
      <TokenPairCell
        clickToCopyRecordIdentifier={$t(
          defineMessage({
            defaultMessage: "Order Hash: {orderHash} (click to copy)",
            description: "Orderbook/OrderHistory/Open Orders",
          }),
          {
            orderHash: simplifyIdentifier(record.orderHash),
          },
        )}
        recordIdentifier={record.orderHash}
        tradeToken={record.tradeToken}
        priceToken={record.priceToken}
        orderDirection={record.orderDirection}
        orderType={record.orderType}
      />

      <FieldCell columnId={"price"}>
        <InfoListItemTitle>{$t(fields$t.price)}</InfoListItemTitle>
        <InfoListItemDetail>{props.price}</InfoListItemDetail>
      </FieldCell>

      <FieldCell columnId={"amount"}>
        <InfoListItemTitle>{$t(fields$t.amount)}</InfoListItemTitle>
        <InfoListItemDetail>
          <TextTokenCount
            token={props.record.tradeToken}
            count={props.record.expectedTradeTokenCount}
          />
        </InfoListItemDetail>
      </FieldCell>

      <FieldCell columnId={"total"}>
        <InfoListItemTitle>{$t(fields$t.total)}</InfoListItemTitle>
        <InfoListItemDetail>{props.total}</InfoListItemDetail>
      </FieldCell>

      <FieldCell columnId={"filled"}>
        <InfoListItemTitle>{$t(fields$t.filled)}</InfoListItemTitle>
        <InfoListItemDetail>
          <TextTokenCount
            token={props.record.priceToken}
            count={props.record.filledPriceTokenCount}
          />{" "}
          (<PercentNumber number={props.record.filledPercentage} />)
        </InfoListItemDetail>
      </FieldCell>

      <FieldCell columnId={"triggerCondition"}>
        <InfoListItemTitle>{$t(fields$t.triggerCondition)}</InfoListItemTitle>
        <InfoListItemDetail>{props.triggerCondition}</InfoListItemDetail>
      </FieldCell>

      <CreateDateCell createdAt={props.record.createdAt} />

      <ActionsCell>
        {props.record.onCancel != null ? (
          <SmartLoadableButton
            {...btnPresets.small}
            Variant={OpacityButton}
            onClick={props.record.onCancel}
          >
            {$t(cancel$t)}
          </SmartLoadableButton>
        ) : (
          <GreenBadge>
            {orderStatus$t.fromStxDxOrderStatus(intl, props.record.status)}
          </GreenBadge>
        )}
      </ActionsCell>
    </RecordRowContainer>
  )
}
