import { pick } from "lodash"

/**
 * simplifyDurationWithUnit({ years: 10, days: 10 })
 * // => { years: 11 }
 * // can be formatted as `< 11 years`
 */
export function simplifyDurationWithUnit(duration: Duration): Duration {
  let unitIncludes: (keyof Duration)[] = []
  if (duration.years) {
    unitIncludes = ["years"]
  } else if (duration.months) {
    unitIncludes = ["months"]
  } else if (duration.weeks) {
    unitIncludes = ["weeks"]
  } else if (duration.days) {
    unitIncludes = ["days"]
  } else if (duration.minutes) {
    unitIncludes = ["minutes"]
  } else if (duration.seconds) {
    unitIncludes = ["seconds"]
  }
  return pick(duration, unitIncludes)
}

export function isInvalidDate(date: Date): boolean {
  // https://thewebdev.info/2021/02/14/how-to-detect-an-invalid-date-date-instance-in-javascript/
  return isNaN(date.getTime())
}
