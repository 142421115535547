import { FC, RefObject } from "react"
import { Spensor } from "../../../components/Spensor"
import { scrollIntoView } from "../../../utils/domHelpers/scrollIntoView"
import { readResource } from "../../../utils/SuspenseResource"
import { useWrapBridgeStore } from "../store/useWrapBridgeStore"
import { WrapBridgeSignBanner } from "./WrapBridgePanel/WrapBridgeSignBanner/WrapBridgeSignBanner"

export const WiredWrapBridgeSignBanner: FC<{
  historyOrderRef: RefObject<HTMLDivElement>
}> = props => {
  const store = useWrapBridgeStore()

  return (
    <Spensor>
      {() => {
        const count = readResource(store.history.signMissingRecordCount$)

        if (!count) return null

        return (
          <WrapBridgeSignBanner
            pendingOrderCount={count}
            onSign={() => {
              if (props.historyOrderRef.current != null) {
                scrollIntoView(props.historyOrderRef.current)
              }
            }}
          />
        )
      }}
    </Spensor>
  )
}
