import { memoize } from "lodash"
import { FC, ReactNode } from "react"
import { IntlProvider } from "react-intl"
import { FCC } from "../utils/reactHelpers/types"
import { useAsync } from "../utils/useAsync"

const throwMissingTranslation = process.env.REACT_APP_I18N_LOG_MISSING

const defaultLocale = "en-US"

export const WiredIntlProvider: FCC = props => {
  return (
    <ImportLanguage language={navigator.language}>
      {messages => (
        <IntlProvider
          locale={messages == null ? defaultLocale : navigator.language}
          messages={messages}
          defaultLocale={defaultLocale}
          defaultRichTextElements={{
            bold: chunks => <strong className={"font-bold"}>{chunks}</strong>,
          }}
          onError={err => {
            if (err.code === "MISSING_TRANSLATION") {
              if (throwMissingTranslation) {
                console.error(err)
              }
            } else {
              throw err
            }
          }}
        >
          {props.children}
        </IntlProvider>
      )}
    </ImportLanguage>
  )
}

const languages = [
  {
    regex: /^zh-TW\b/,
    content: memoize(() =>
      import("../generated/i18n/compiled/zh-TW.json").then(a => a.default),
    ),
  },
  {
    regex: /^zh\b/,
    content: memoize(() =>
      import("../generated/i18n/compiled/zh-CN.json").then(a => a.default),
    ),
  },
  {
    regex: /.*/,
    content: memoize(() =>
      import("../generated/i18n/compiled/en-US.json").then(a => a.default),
    ),
  },
]

const ImportLanguage: FC<{
  language: string
  children: (messages: undefined | Record<string, string>) => ReactNode
}> = props => {
  const [messagesLoadState] = useAsync(
    () => languages.find(a => a.regex.test(props.language))!.content(),
    [props.language],
  )

  if (messagesLoadState.loading || messagesLoadState.error) {
    return <>{props.children(undefined)}</>
  } else {
    return <>{props.children(messagesLoadState.value)}</>
  }
}
