import { FC } from "react"
import {
  FinishedBadge,
  ProgressingBadge,
  UpcomingBadge,
} from "../../../../components/Badge"
import { LockdropStatus } from "../types"

export const LockdropBadge: FC<{ status: LockdropStatus }> = ({ status }) => {
  switch (status) {
    case LockdropStatus.Upcoming:
      return <UpcomingBadge>upcoming</UpcomingBadge>
    case LockdropStatus.Live:
      return <ProgressingBadge>live</ProgressingBadge>
    case LockdropStatus.Allocation:
      return <ProgressingBadge>allocation</ProgressingBadge>
    case LockdropStatus.Ended:
      return <FinishedBadge>ended</FinishedBadge>
  }
}
