import { FC } from "react"
import { TokenInfo } from "../utils/models/TokenInfo"
import { pickDecimalPart, roundNumber } from "../utils/numberHelpers"

export interface TokenCountProps {
  token: TokenInfo
  count: number
}

export const TokenCount: FC<TokenCountProps> = props => {
  return (
    <span title={String(props.count)}>
      {formatTokenCount(props.token, props.count)}
    </span>
  )
}

export function formatTokenCount(token: TokenInfo, count: number): string {
  const precision = TokenInfo.getPrecision(token)
  const formatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: precision,
  })

  if (count === 0) return "0"

  const decimalPart = pickDecimalPart(count, precision)

  if (decimalPart == null) return formatter.format(count)

  const roundDownCount = roundDownTokenCount(count, precision)
  const formatted = formatter.format(roundDownCount)

  /**
   * `formatter(maximumFractionDigits: 2).format(0.001) // => "0"`, it's not
   * we expected, so we have to construct by ourselves
   */
  const intPart = formatted.split(".")[0]!
  return `${intPart}.${decimalPart}…`
}

export const roundDownTokenCount = (
  tokenCount: number,
  precision: number,
): number => {
  return roundNumber(tokenCount, {
    precision,
    rounder: Math.floor,
  })
}
