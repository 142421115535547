import clsx from "clsx"
import { format } from "date-fns"
import { CSSProperties, FC } from "react"
import { TokenCount } from "../../../../../components/TokenCount"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { FCC } from "../../../../../utils/reactHelpers/types"
import { Colors } from "../../designTokens"
import { Order } from "../types"
import { Column1Cell, Column2Cell, Column3Cell } from "./Cells"
import styles from "./OrderListItem.module.scss"

export const ProgressBarOrderListItemContainer: FCC<{
  className?: string
  order: Order.OrderbookOrder
  onClick: () => void
}> = props => {
  const bgColor =
    props.order.orderDirection === "buy"
      ? "rgba(6, 78, 59, 0.5)"
      : "rgba(190, 24, 93, 0.5)"

  return (
    <OrderListItemContainer
      className={props.className}
      style={
        {
          "--OrderListItem-bgColor": bgColor,
          "--OrderListItem-bgSize": `${
            Math.max(0, Math.min(props.order.volumePercentage, 1)) * 100
          }%`,
        } as any
      }
      onClick={props.onClick}
    >
      {props.children}
    </OrderListItemContainer>
  )
}

export const OrderListItemContainer: FCC<{
  className?: string
  style?: CSSProperties
  onClick?: () => void
}> = props => {
  return (
    <li
      className={clsx(
        "flex",
        props.onClick != null && styles.OrderListItemBg,
        props.className,
      )}
      style={props.style}
      onClick={props.onClick}
    >
      {props.children}
    </li>
  )
}

export const PriceCell: FC<{
  priceToken: TokenInfo
  order: Order
  isBelongsCurrentUser?: boolean
}> = props => (
  <Column1Cell
    className={clsx(
      "flex items-center",
      props.order.orderDirection === "buy"
        ? Colors.buyTextClassName
        : Colors.sellTextClassName,
    )}
  >
    <TokenCount
      token={props.priceToken}
      count={props.order.priceTokenCountPerTradeToken}
    />
    {props.isBelongsCurrentUser && (
      <svg
        className={"ml-auto"}
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 4L7 8V0L1 4Z" fill="white" />
      </svg>
    )}
  </Column1Cell>
)

export const AmountCell: FC<{
  tradeToken: TokenInfo
  order: Order
}> = props => (
  <Column2Cell className={"text-gray-200"}>
    <TokenCount token={props.tradeToken} count={props.order.tradeTokenCount} />
  </Column2Cell>
)

export const TotalPriceCell: FC<{
  priceToken: TokenInfo
  order: Order.OrderbookOrder
}> = props => (
  <Column3Cell className={"text-gray-200"}>
    <TokenCount
      token={props.priceToken}
      count={props.order.priceTokenCountTotal}
    />
  </Column3Cell>
)

export const CreateTimeCell: FC<{
  order: Order.TradeOrder | Order.MyTradeOrder
}> = props => (
  <Column3Cell className={"text-gray-500"}>
    {format(props.order.createdAt, "HH:mm:ss")}
  </Column3Cell>
)
