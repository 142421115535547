export type Bound = "top" | "right" | "bottom" | "left"

export interface OversteppedDistances {
  top?: number
  right?: number
  bottom?: number
  left?: number
}

export const getOversteppedViewportDistances = (
  el: HTMLElement,
  padding?: { top?: number; bottom?: number; left?: number; right?: number },
): OversteppedDistances => {
  const bounding = el.getBoundingClientRect()

  const distances: OversteppedDistances = {}

  const topDistance = bounding.top - (padding?.top ?? 0)
  if (topDistance < 0) {
    distances.top = -topDistance
  }

  const leftDistance = bounding.left - (padding?.left ?? 0)
  if (leftDistance < 0) {
    distances.left = -leftDistance
  }

  const bottomDistance =
    bounding.bottom -
    ((window.innerHeight || document.documentElement.clientHeight) -
      (padding?.bottom ?? 0))
  if (bottomDistance > 0) {
    distances.bottom = bottomDistance
  }

  const rightDistance =
    bounding.right -
    ((window.innerWidth || document.documentElement.clientWidth) -
      (padding?.right ?? 0))
  if (rightDistance > 0) {
    distances.right = rightDistance
  }

  return distances
}

export const getSuggestedKeepInViewportSize = (
  el: HTMLElement,
  padding?: { top?: number; bottom?: number; left?: number; right?: number },
): { width: number; height: number } => {
  const distances = getOversteppedViewportDistances(el, padding)
  const { width, height } = el.getBoundingClientRect()

  return {
    width: width - (distances.left ?? 0) - (distances.right ?? 0),
    height: height - (distances.top ?? 0) - (distances.bottom ?? 0),
  }
}

export const isElementInViewport = (
  el: HTMLElement,
  padding?: { top?: number; bottom?: number; left?: number; right?: number },
): boolean => {
  return Object.keys(getOversteppedViewportDistances(el, padding)).length === 0
}
