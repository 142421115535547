import { FC } from "react"
import { GradientFilledButton } from "../../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteFilledButton } from "../../../../../components/button/variants/WhiteFilledButton"
import { CardPlate } from "../../../../../components/Card"
import { CardModalContent } from "../../../../../components/CardModal/CardModal"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../../components/InfoList"
import { NoteParagraph } from "../../../../../components/NoteParagraph/NoteParagraph"
import { TokenCount } from "../../../../../components/TokenCount"
import { BlockTopLine } from "../../../../../components/TokenInput/Block"
import {
  TokenBlock,
  TokenCountPairPlain,
} from "../../../../../components/TokenInput/TokenBlock"
import { TokenName } from "../../../../../components/TokenName"

export interface ConfirmClaimModalContentProps {
  claimAssets: TokenCountPairPlain[]
  borrowedAssets: TokenCountPairPlain
  onConfirm?: () => void
  onClose?: () => void
}

export const ConfirmClaimModalContent: FC<
  ConfirmClaimModalContentProps
> = props => (
  <CardModalContent
    onClose={props.onClose}
    title={"Confirm Claim"}
    className={"flex flex-col gap-4"}
    width={480}
  >
    <TokenBlock
      topArea={<BlockTopLine>You will claim</BlockTopLine>}
      tokens={props.claimAssets}
    />

    <CardPlate>
      <InfoList direction={"column"} listItemDirection={"row-responsive"}>
        <InfoListItem>
          <InfoListItemTitle>Your Borrowed Amount</InfoListItemTitle>
          <InfoListItemDetail>
            <TokenCount
              token={props.borrowedAssets.token}
              count={props.borrowedAssets.count}
            />
            &nbsp;
            <TokenName token={props.borrowedAssets.token} />
          </InfoListItemDetail>
        </InfoListItem>
      </InfoList>
    </CardPlate>

    <NoteParagraph>
      Your claim amount reflects the dynamic CRP balance.
    </NoteParagraph>

    <div className={"flex flex-row gap-2.5"}>
      <WhiteFilledButton className={"flex-1"} onClick={props.onClose}>
        Cancel
      </WhiteFilledButton>

      <GradientFilledButton className={"flex-1"} onClick={props.onConfirm}>
        Confirm
      </GradientFilledButton>
    </div>
  </CardModalContent>
)
