import { useMemo, useState } from "react"

export interface BooleanController {
  on(): void
  off(): void
  toggle(): void
}

export const useBoolean = (
  initialValue = false,
): [value: boolean, controller: BooleanController] => {
  const [value, setValue] = useState(initialValue)

  const controller: BooleanController = useMemo(
    () => ({
      on() {
        setValue(true)
      },
      off() {
        setValue(false)
      },
      toggle() {
        setValue(val => !val)
      },
    }),
    [],
  )

  return useMemo(() => [value, controller], [controller, value])
}
