import { FC } from "react"
import { GradientFilledButton } from "../../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteFilledButton } from "../../../../../components/button/variants/WhiteFilledButton"
import { CardPlate } from "../../../../../components/Card"
import { CardModalContent } from "../../../../../components/CardModal/CardModal"
import { InfoList } from "../../../../../components/InfoList"
import { NoteParagraph } from "../../../../../components/NoteParagraph/NoteParagraph"
import { TipIcon } from "../../../../../components/TipIcon/TipIcon"
import { TokenCount } from "../../../../../components/TokenCount"
import { BlockTopTitleLine } from "../../../../../components/TokenInput/Block"
import {
  BlockGroup,
  BlockGroupDownArrowIcon,
} from "../../../../../components/TokenInput/BlockGroup"
import { TokenBlock } from "../../../../../components/TokenInput/TokenBlock"
import { TokenName } from "../../../../../components/TokenName"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { DepositInfoListContent } from "../DepositInfoListContent"

export interface ConfirmDepositModalContentProps {
  depositToken: TokenInfo
  yieldToken: TokenInfo
  depositTokenCount: number
  yieldTokenCount: number
  expirationDate: Date
  expirationBlockHeight: number
  estimateAbsoluteInterest: number
  liquidityProviderFee: number
  estimateClaimable: number
  slippage: number
  onConfirm?: () => void
  onClose?: () => void
}

export const ConfirmDepositModalContent: FC<
  ConfirmDepositModalContentProps
> = props => (
  <CardModalContent
    onClose={props.onClose}
    title={"Confirm Deposit"}
    className={"flex flex-col gap-4"}
    width={480}
  >
    <BlockGroup
      firstBlock={
        <TokenBlock
          tokens={[
            { token: props.depositToken, count: props.depositTokenCount },
          ]}
        />
      }
      icon={<BlockGroupDownArrowIcon />}
      secondBlock={
        <TokenBlock
          topArea={
            <BlockTopTitleLine>
              Receive{" "}
              <TipIcon
                inline
                tip={
                  <>
                    <TokenName token={props.yieldToken} /> functions like a{" "}
                    certificate of deposit that pays a fixed interest to its{" "}
                    holder at a pre-defined maturity date
                  </>
                }
              />
            </BlockTopTitleLine>
          }
          tokens={[{ token: props.yieldToken, count: props.yieldTokenCount }]}
        />
      }
    />

    <CardPlate className={"flex flex-col gap-2"}>
      <InfoList listItemDirection={"row-responsive"}>
        <DepositInfoListContent
          depositToken={props.depositToken}
          expirationBlockHeight={props.expirationBlockHeight}
          expirationDate={props.expirationDate}
          estimateAbsoluteInterest={props.estimateAbsoluteInterest}
          estimateClaimableValue={props.estimateClaimable}
          slippage={props.slippage}
          liquidityProviderFee={props.liquidityProviderFee}
        />
      </InfoList>
    </CardPlate>

    <NoteParagraph>
      You can trade your{" "}
      <TokenCount token={props.yieldToken} count={props.yieldTokenCount} />{" "}
      <TokenName token={props.yieldToken} /> at any time.
      <br />
      Or you can wait to receive the guaranteed amount of the principal and{" "}
      interest when maturity is reached.
    </NoteParagraph>

    <div className={"mt-3.5 flex flex-row gap-2.5"}>
      <WhiteFilledButton className={"flex-1"} onClick={props.onClose}>
        Cancel
      </WhiteFilledButton>

      <GradientFilledButton className={"flex-1"} onClick={props.onConfirm}>
        Confirm
      </GradientFilledButton>
    </div>
  </CardModalContent>
)
