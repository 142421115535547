import { FC } from "react"
import { defineMessages, useIntl } from "react-intl"
import { AlertLayoutAsBanner } from "../../../../../components/alert/AlertLayoutAsBanner"
import { RedGradientAlert } from "../../../../../components/alert/RedGradientAlert"
import { btnPresets } from "../../../../../components/button/Button"
import { WhiteFilledButton } from "../../../../../components/button/variants/WhiteFilledButton"
import { ReactComponent as WarnIcon } from "./assets/warn.svg"

export const WrapBridgeSignBanner: FC<{
  pendingOrderCount: number
  onSign: () => void
}> = props => {
  const { $t } = useIntl()

  const { pendingOrderCount } = props

  return (
    <AlertLayoutAsBanner icon={<WarnIcon />} Alert={RedGradientAlert}>
      <div className="flex items-center gap-4 text-white">
        <span>{$t(i18n.havePendingTxs, { count: pendingOrderCount })}</span>
        <WhiteFilledButton
          {...btnPresets.small}
          className="px-6"
          onClick={props.onSign}
        >
          Sign
        </WhiteFilledButton>
      </div>
    </AlertLayoutAsBanner>
  )
}

const i18n = defineMessages({
  havePendingTxs: {
    defaultMessage: `
    You have <bold>{count, plural,
      =0 {no pending orders}
      one {# pending order}
      other {# pending orders}
    }</bold> to sign on Ethereum.`,
    description: "WrapBridge/sign banner",
  },
})
