import { FC } from "react"
import {
  FailedBadge,
  FinishedBadge,
  ProgressingBadge,
  UpcomingBadge,
} from "../../../components/Badge"
import { assertNever } from "../../../utils/types"
import { LaunchingStatus } from "../store/LaunchPadContractStore"
import { TokenIDOStatus } from "./types"

export const IDOStatusBadge: FC<{ status: TokenIDOStatus }> = props => {
  const { status } = props
  switch (status.type) {
    case LaunchingStatus.Upcoming:
      return <UpcomingBadge>upcoming</UpcomingBadge>
    case LaunchingStatus.Registration:
    case LaunchingStatus.Claim:
      return <ProgressingBadge>live</ProgressingBadge>
    case LaunchingStatus.Finished:
      return status.success ? (
        <FinishedBadge>finished</FinishedBadge>
      ) : (
        <FailedBadge>failed</FailedBadge>
      )
    default:
      assertNever(status)
  }
}
