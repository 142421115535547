import { FC } from "react"
import { TokenName } from "../../../components/TokenName"
import { TokenInfo } from "../../../utils/models/TokenInfo"

export interface TokenNameStackProps {
  tradeToken: TokenInfo
  priceToken: TokenInfo
}

export const TokenNameStack: FC<TokenNameStackProps> = props => {
  return (
    <>
      <TokenName token={props.tradeToken} />/
      <TokenName token={props.priceToken} />
    </>
  )
}
