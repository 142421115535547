import { FC } from "react"
import { simplifyWithUnit } from "../../../utils/numberHelpers"

/**
 * https://www.notion.so/alexgo-io/Yield-Farming-Mainnet-1a48695cf8e5423c84a2209f57c8b1df#6c5570ac69114c03bc01deca17babffb
 */
export const LiquidityNumber: FC<{
  count?: number
}> = props => {
  if (props.count == null) {
    return <>N/A</>
  }

  const [formattedNumber, unit] = simplifyWithUnit(props.count)

  return (
    <>
      {formattedNumber.toFixed(2)}
      {unit.toUpperCase()}
    </>
  )
}
