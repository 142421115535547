import clsx from "clsx"
import { defineMessage, useIntl } from "react-intl"
import { PlainIconButton } from "../../../../components/button/variants/PlainIconButton"
import { FCC } from "../../../../utils/reactHelpers/types"
import {
  safeReadResource,
  SuspenseResource,
} from "../../../../utils/SuspenseResource"
import { CompactCard } from "../wrappedCommonComponents/CompactCard"
import { ReactComponent as SettingIcon } from "./_/setting.svg"

export const TradingAccountFrame: FCC<{
  className?: string
  onSettingClick?: () => void
  hasRegistered?: SuspenseResource<boolean>
}> = props => {
  const { $t } = useIntl()
  return (
    <CompactCard className={clsx("flex flex-col", props.className)}>
      <div className="flex flex-row items-center">
        <span className={"text-sm leading-5 font-semibold text-gray-200 grow"}>
          {$t(
            defineMessage({
              defaultMessage: "Trading account",
              description: `Orderbook/Trading Account Panel/title`,
            }),
          )}
        </span>
        {safeReadResource(props.hasRegistered) && (
          <PlainIconButton
            icon={<SettingIcon />}
            onClick={props.onSettingClick}
          />
        )}
      </div>

      {props.children}
    </CompactCard>
  )
}
