import { FC } from "react"
import { IS_MAIN_NET } from "../../../config"
import { usePathGenerator } from "../../../routes/routes"
import { LaunchingStatus } from "../store/LaunchPadContractStore"
import {
  useLaunchPadContentfulStore,
  useLaunchPadContractStore,
} from "../store/useLaunchPadStores"
import { IDOSteps } from "./IDOSteps"

export const WiredIDOSteps: FC<{
  className?: string
}> = props => {
  const store = useLaunchPadContractStore()
  const contentfulStore = useLaunchPadContentfulStore()
  const g = usePathGenerator()

  // prettier-ignore
  const current =
    store.steps.currentStatus$ === LaunchingStatus.Upcoming ? 0 :
    store.steps.currentStatus$ === LaunchingStatus.Registration ? 1 :
    store.steps.currentStatus$ === LaunchingStatus.Claim ? 2 :
    null

  if (current == null) return null

  return (
    <IDOSteps
      className={props.className}
      currentStepNumber={current}
      validateTime={store.steps.idoStepsPlan$[LaunchingStatus.Registration]}
      validateListLink={g.launchpadValidateList(contentfulStore.urlSlug)}
      lotteryTime={store.steps.idoStepsPlan$[LaunchingStatus.Claim]}
      lotteryListLink={
        IS_MAIN_NET
          ? undefined
          : g.launchpadLotteryList(contentfulStore.urlSlug)
      }
      allocationTime={store.steps.idoStepsPlan$[LaunchingStatus.Finished]}
    />
  )
}
