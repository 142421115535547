import clsx from "clsx"
import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { readResource, SuspenseResource } from "../../utils/SuspenseResource"
import { CardDivider } from "../Card"
import { CardModalContent } from "../CardModal/CardModal"
import { ScopedLoadingBoundary } from "../LoadingBoundary/ScopedLoadingBoundary"
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator"
import { MarkdownContent } from "../MarkdownContent/MarkdownContent"
import { RenderChildrenFn } from "../Spensor"
import styles from "./NewTokenomicsModalContent.module.scss"

export const NewTokenomicsModalContent: FC<{
  markdownContent: SuspenseResource<string>
  onClose: () => void
}> = props => {
  const { $t } = useIntl()

  return (
    <CardModalContent
      className={"flex items-center justify-center"}
      width={800}
      title={$t(
        defineMessage({
          defaultMessage: "New ALEX Tokenomics",
          description: "Common/New ALEX tokenomics introduction modal title",
        }),
      )}
      onClose={props.onClose}
    >
      <ScopedLoadingBoundary
        className={"w-full min-h-[300px]"}
        mask={false}
        loadingIndicator={<LoadingIndicator />}
      >
        <RenderChildrenFn>
          {() => (
            <MarkdownContent
              className={clsx(
                "prose dark:prose-invert max-w-none",
                styles.markdown,
              )}
              components={{
                hr: () => <CardDivider className={"w-full"} />,
              }}
              markdown={readResource(props.markdownContent)}
            />
          )}
        </RenderChildrenFn>
      </ScopedLoadingBoundary>
    </CardModalContent>
  )
}
