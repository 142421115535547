import { FC } from "react"
import { TokenCount } from "../../../components/TokenCount"
import { TokenName } from "../../../components/TokenName"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { CoFarmSection } from "./CoFarmSection"

export const CoFarmDisclaimer: FC<{
  className?: string
  lpLeftToken: TokenInfo
  lpLeftTokenCount: number
  rewardToken: TokenInfo
}> = props => (
  <CoFarmSection className={props.className} title={"Disclaimer"}>
    <ul className={"list-disc list-inside text-sm text-gray-400"}>
      <li>
        You are committing{" "}
        <TokenCount token={props.lpLeftToken} count={props.lpLeftTokenCount} />{" "}
        <TokenName token={props.lpLeftToken} />.
      </li>

      <li>The ALEX Lab Foundation will provide the other half and co-farm.</li>

      <li>
        At the end of farming, you will be able to claim your{" "}
        <TokenCount token={props.lpLeftToken} count={props.lpLeftTokenCount} />{" "}
        <TokenName token={props.lpLeftToken} /> subject to the pool value
        exceeding the amount.
      </li>

      <li>
        <TokenName token={props.rewardToken} /> rewards will be automatically
        sent to your wallet address after each cycle.
      </li>
    </ul>
  </CoFarmSection>
)
