import clsx from "clsx"
import { sumBy } from "lodash"
import { FC } from "react"
import { Card } from "../../../../components/Card"
import { CollapsableCard } from "../../../../components/CollapsableCard/CollapsableCard"
import { NavLink } from "../../../../components/NavLink"
import { PercentNumber } from "../../../../components/PercentNumber"
import { Spensor } from "../../../../components/Spensor"
import { TitledColumnCell } from "../../../../components/TitledColumnCell"
import { TokenCount } from "../../../../components/TokenCount"
import { TokenIcon } from "../../../../components/TokenIcon"
import { TokenName } from "../../../../components/TokenName"
import { Tooltip } from "../../../../components/Tooltip/Tooltip"
import { usePathGenerator } from "../../../../routes/routes"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import {
  readResource,
  SuspenseResource,
} from "../../../../utils/SuspenseResource"
import { useAsyncFnFactory } from "../../../../utils/useAsync"
import { CycleStatusBadge } from "../../../Stake/components/CycleStatusBadge"
import { LoadMoreButton } from "../../../Stake/components/LoadMoreButton/LoadMoreButton"
import { ClaimTokenInfo, StakeCycleStatus } from "../../../Stake/types"
import { ClaimTokenInfoList } from "../ClaimTokenInfoList"
import { ListButton } from "../ListButton"
import {
  getListItemContainerClassName,
  getListSectionClassName,
  ListContainer,
  ListSection,
} from "../ListContainer"
import { TruncateTokenName } from "../TruncateTokenName"
import { ReactComponent as IntoIcon } from "./into.svg"

export interface MyFarm {
  liquidityPoolToken: TokenInfo
  aprs: {
    token: TokenInfo
    percentage: number
  }[]
  stackedCount: number
  reward: ClaimTokenInfo[]
  cycleStatus: StakeCycleStatus
}

export const MyFarmsPanel: FC<{
  className?: string
  farms: SuspenseResource<MyFarm[]>
  isHasMore?: boolean
  onLoadMore?: () => Promise<void>
}> = props => {
  const gen = usePathGenerator()

  const propOnLoadMore = props.onLoadMore
  const [loadMoreReq, loadMore] = useAsyncFnFactory(
    () => async () => propOnLoadMore?.(),
    [propOnLoadMore],
  )

  return (
    <Spensor>
      {() => {
        if (readResource(props.farms).length === 0) {
          return null
        }
        return (
          <CollapsableCard className={props.className} title={"My Farms"}>
            <div className={"flex flex-col items-center gap-4"}>
              <div className={"w-full overflow-x-auto"}>
                <ListContainer className={"min-w-[900px]"}>
                  {readResource(props.farms).map((f, i) => (
                    <NavLink key={i} to={gen.farmDetail(f.liquidityPoolToken)}>
                      <MyFarmListItem className={"w-full"} farm={f} />
                    </NavLink>
                  ))}
                </ListContainer>
              </div>

              {props.isHasMore && (
                <LoadMoreButton
                  loading={loadMoreReq.loading}
                  onLoadMore={loadMore}
                />
              )}
            </div>
          </CollapsableCard>
        )
      }}
    </Spensor>
  )
}

const MyFarmListItem: FC<{
  className?: string
  farm: MyFarm
  onClick?: () => void
}> = props => {
  return (
    <Card
      className={clsx(
        "flex flex-row gap-4 group hover:bg-black/10 hover:!bg-none hover:!backdrop-filter-none hover:!drop-shadow-none active:bg-black/20 active:!bg-none active:!backdrop-filter-none active:!drop-shadow-none",
        getListItemContainerClassName({}),
        props.className,
      )}
      boxClassName={"px-4 py-2"}
      roundClassName={"rounded-lg"}
    >
      <ListSection
        className={"flex flex-row items-center gap-2.5"}
        type={"head"}
      >
        <TokenIcon token={props.farm.liquidityPoolToken} />

        <TruncateTokenName
          className={"text-base leading-6 font-medium text-gray-200 truncate"}
          token={props.farm.liquidityPoolToken}
        />

        {props.farm.cycleStatus === StakeCycleStatus.Finished && (
          <CycleStatusBadge status={props.farm.cycleStatus} />
        )}
      </ListSection>

      <TitledColumnCell
        className={clsx(getListSectionClassName({}), "self-start")}
        title={"Staked"}
        detail={
          <>
            <TokenCount
              token={props.farm.liquidityPoolToken}
              count={props.farm.stackedCount}
            />
            &nbsp;LP
          </>
        }
      />

      <TitledColumnCell
        className={clsx(getListSectionClassName({}), "self-start")}
        title={"APR"}
        detail={
          <Tooltip
            title={
              <ul>
                {props.farm.aprs.map(a => (
                  <li>
                    <TokenName token={a.token} /> APR:{" "}
                    <PercentNumber number={a.percentage} />
                  </li>
                ))}
              </ul>
            }
          >
            <PercentNumber number={sumBy(props.farm.aprs, a => a.percentage)} />
          </Tooltip>
        }
      />

      <TitledColumnCell
        className={clsx(getListSectionClassName({}), "self-start")}
        title={"Rewards to claim"}
        detail={<ClaimTokenInfoList infos={props.farm.reward} />}
      />

      <ListSection className={"flex items-center justify-center"}>
        <ListButton className={"invisible group-hover:visible"}>
          View
        </ListButton>
      </ListSection>

      <ListSection className={"flex items-center justify-end"} type={"tail"}>
        <IntoIcon className={"opacity-40"} />
      </ListSection>
    </Card>
  )
}
