import { isEqual, memoize } from "lodash"
import {
  catchError,
  combineLatestWith,
  distinctUntilChanged,
  from,
  map,
  Observable,
  of,
  scan,
  shareReplay,
} from "rxjs"
import { components } from "../../../../../generated/stxdx/types"
import { sendPublicRequest } from "../../../../../generated/stxdxHelpers/stxdxApi"
import {
  Order,
  OrderListOrderType,
} from "../../../components/OrderbookList/types"
import { StxDxMarket } from "../../OrderbookStore.service/OrderbookStore.service"
import { getOrderbookSummaryFor } from "../_/orderbookHelpers"

export const fetchTradeOrders = memoize(
  (market: StxDxMarket): Observable<components["schemas"]["MarketTrade"][]> => {
    return from(
      sendPublicRequest("OrderController_getMarketTrades", {
        path: { market },
      }),
    ).pipe(
      map(resp => resp.data.trades),
      combineLatestWith(
        getOrderbookSummaryFor(market).pipe(
          map(r => r.trades),
          catchError(() => of([])),
          scan(
            (acc, curr) => [...acc, ...curr],
            [] as components["schemas"]["MarketTrade"][],
          ),
          distinctUntilChanged(isEqual),
        ),
      ),
      map(([apiTrades, wsTrades]): components["schemas"]["MarketTrade"][] => {
        return [...wsTrades, ...apiTrades]
      }),
      shareReplay(1),
    )
  },
)

export const transformTrade =
  (currentUserId?: number) =>
  (f: components["schemas"]["MarketTrade"]): Order.TradeOrder => ({
    type: OrderListOrderType.TradeOrders,
    isBelongsToCurrentUser:
      f.left_maker === currentUserId || f.right_maker === currentUserId,
    createdAt: new Date(f.timestamp),
    tradeTokenCount: Number(f.size),
    priceTokenCountPerTradeToken: Number(f.price),
    orderDirection: f.left_side,
  })
