import { gql } from "@urql/core"
import { unwrapResponse } from "clarity-codegen"
import { range } from "lodash"
import { computed, makeObservable } from "mobx"
import { CONTRACT_DEPLOYER, EXPLORER_TX_URL } from "../../../config"
import {
  FetchIdoClaimTxsQuery,
  FetchIdoClaimTxsQueryVariables,
} from "../../../generated/graphql/graphql.generated"
import { asSender } from "../../../generated/smartContractHelpers/asSender"
import { LazyValue } from "../../../stores/LazyValue/LazyValue"
import { pMemoizeDecorator } from "../../../stores/LazyValue/pMemoizeDecorator"
import { gqlQuery } from "../../../utils/graphqlHelpers"
import { fromUrqlSource } from "../../../utils/Observable/fromUrqlSource"
import { LotteryTicket } from "../component/ActionSection/types"
import { getAllWonTicketIds } from "./IDOLCGAlgorithm"
import LaunchPadContractStore from "./LaunchPadContractStore"
import Status = LotteryTicket.Status

class ClaimViewModule {
  constructor(readonly store: LaunchPadContractStore) {
    makeObservable(this)
  }

  @computed get claimStarted$(): boolean {
    const regEnd = this.store.detail$["registration-end-height"]
    const currentBlock = this.store.authStore.currentBlockHeight$
    return currentBlock > regEnd + 1
  }

  private wonTicketIds = new LazyValue(
    () => [this.store.idoId] as const,
    async ([idoId]): Promise<number[]> => {
      const result = await asSender(CONTRACT_DEPLOYER)
        .contract("alex-launchpad-v1-1")
        .func("get-offering-walk-parameters")
        .call({
          "ido-id": idoId,
        })
        .then(unwrapResponse)
      return getAllWonTicketIds(result)
    },
    { decorator: pMemoizeDecorator({ persistKey: "ido-result" }) },
  )

  @computed get wonTicketIds$(): number[] {
    const activation = this.store.tokenProfileViewModule.currentActivation
    return this.wonTicketIds.value$.filter(id => id <= activation)
  }

  wonTxs = new LazyValue(
    () =>
      [
        this.store.idoId,
        this.store.authStore.stxAddress$,
        this.store.authStore.currentBlockHeight$,
      ] as const,
    ([idoId, myAddress]) => {
      return fromUrqlSource(
        gqlQuery(
          gql<FetchIdoClaimTxsQuery, FetchIdoClaimTxsQueryVariables>`
            query FetchIDOClaimTxs($contractName: String!, $idoId: String!) {
              laplace_contract_calls(
                where: {
                  function_name: { _eq: "claim" }
                  contract_name: { _eq: $contractName }
                  arg1: { _eq: $idoId }
                }
                order_by: { block_height: desc }
              ) {
                tx_id
                function_args
              }
            }
          `,
          {
            contractName: "alex-launchpad-v1-1",
            idoId: String(idoId),
          },
        ),
        res =>
          res.data.laplace_contract_calls.flatMap(r => {
            const addresses = r.function_args[1].value.map(
              (v: any) => v.value,
            ) as string[]
            return addresses
              .filter(a => a === myAddress)
              .map(() => r.tx_id.replace(/^\\/, "0"))
          }),
      )
    },
  )

  refundTxs = new LazyValue(
    () =>
      [
        this.store.idoId,
        this.store.authStore.stxAddress$,
        this.store.pricePerTicket$,
        this.store.authStore.currentBlockHeight$,
      ] as const,
    ([idoId, myAddress, pricePerTicket]) => {
      return fromUrqlSource(
        gqlQuery(
          gql<FetchIdoClaimTxsQuery, FetchIdoClaimTxsQueryVariables>`
            query FetchIdoRefundTxs($contractName: String!, $idoId: String!) {
              laplace_contract_calls(
                where: {
                  function_name: { _eq: "refund" }
                  contract_name: { _eq: $contractName }
                  arg1: { _eq: $idoId }
                }
                order_by: { block_height: desc }
              ) {
                tx_id
                function_args
              }
            }
          `,
          {
            contractName: "alex-launchpad-v1-1",
            idoId: String(idoId),
          },
        ),
        res =>
          res.data.laplace_contract_calls.flatMap(r => {
            const addresses = r.function_args[1].value.map((v: any) => ({
              amount: v.value.amount.value / pricePerTicket / 1e8,
              recipient: v.value.recipient.value,
            })) as { amount: number; recipient: string }[]
            return addresses
              .filter(a => a.recipient === myAddress)
              .flatMap((a): string[] =>
                range(0, a.amount).map(() => r.tx_id.replace(/^\\/, "0")),
              )
          }),
      )
    },
  )

  @computed get tickets(): LotteryTicket[] {
    if (!this.claimStarted$) {
      return this.store.userIDOViewModule.registeredTicketIds.map(id => ({
        type: LotteryTicket.Type.Unknown,
        number: id,
      }))
    }
    const myTickets = this.store.userIDOViewModule.registeredTicketIds
    const wonTxIds = this.wonTxs.value$
    const wonTickets = myTickets.filter(t => this.wonTicketIds$.includes(t))
    const refundTxIds = this.refundTxs.value$
    const refundTickets = myTickets.filter(t => !this.wonTicketIds$.includes(t))

    return this.store.userIDOViewModule.registeredTicketIds.map(
      (ticketId): LotteryTicket => {
        if (this.wonTicketIds$.includes(ticketId)) {
          const txId = wonTxIds[wonTickets.indexOf(ticketId)]
          return {
            type: LotteryTicket.Type.Won,
            number: ticketId,
            status: txId != null ? Status.Sent : Status.WaitToClaim,
            wonIDOTokenCount:
              this.store.tokenProfileViewModule.idoTokenCountPerTicket,
            explorerLink: txId != null ? EXPLORER_TX_URL(txId) : undefined,
          }
        } else {
          const txId = refundTxIds[refundTickets.indexOf(ticketId)]
          return {
            type: LotteryTicket.Type.Lose,
            number: ticketId,
            status: txId != null ? Status.Sent : Status.WaitToClaim,
            returnPriceTokenCount:
              this.store.tokenProfileViewModule.pricePerTicket,
            explorerLink: txId != null ? EXPLORER_TX_URL(txId) : undefined,
          }
        }
      },
    )
  }
}

export default ClaimViewModule
