import { FC } from "react"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteFilledButton } from "../../../../components/button/variants/WhiteFilledButton"
import { CardModalContent } from "../../../../components/CardModal/CardModal"
import { TokenCount } from "../../../../components/TokenCount"
import { BlockTopTitleLine } from "../../../../components/TokenInput/Block"
import { BlockGroup } from "../../../../components/TokenInput/BlockGroup"
import { TokenBlock } from "../../../../components/TokenInput/TokenBlock"
import { TokenName } from "../../../../components/TokenName"
import { NotEmptyArray } from "../../../../utils/arrayHelpers"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { TokenInfoPresets } from "../../../../utils/TokenInfoPresets/TokenInfoPresets"
import {
  AddStakeSectionInfoList,
  AddStakeSectionInfoListItem,
} from "../../components/AddStakeSectionInfoList"
import { ClaimTokenInfo } from "../../types"

export interface ConfirmHarvestAndMintAtAlexModalContentProps {
  harvestTokens: NotEmptyArray<ClaimTokenInfo>
  mintedTokens: NotEmptyArray<ClaimTokenInfo>
  alexToken: TokenInfo
  atAlexToken: TokenInfo
  intrinsicAlexValue: number
  onConfirm?: () => void
  onClose?: () => void
}

export const ConfirmHarvestAndMintAtAlexModalContent: FC<
  ConfirmHarvestAndMintAtAlexModalContentProps
> = props => (
  <CardModalContent
    onClose={props.onClose}
    title={"Confirm Harvest"}
    className={"flex flex-col gap-4"}
    width={480}
  >
    <BlockGroup
      firstBlock={
        <TokenBlock
          topArea={<BlockTopTitleLine>Harvest</BlockTopTitleLine>}
          tokens={props.harvestTokens}
        />
      }
      secondBlock={
        <TokenBlock
          topArea={<BlockTopTitleLine>Auto Stake (receive)</BlockTopTitleLine>}
          tokens={props.mintedTokens}
        />
      }
    />

    <AddStakeSectionInfoList>
      <AddStakeSectionInfoListItem
        title={"Stake Price"}
        detail={
          <span>
            1 <TokenName token={props.atAlexToken} /> ={" "}
            {props.intrinsicAlexValue} <TokenName token={props.alexToken} />
          </span>
        }
      />
      <AddStakeSectionInfoListItem
        title={"Total Claim"}
        detail={
          <ul className={"text-right"}>
            {props.mintedTokens.map((group, idx) => (
              <li key={idx}>
                <ClaimCountInfo token={group.token} count={group.count} />
              </li>
            ))}
          </ul>
        }
      />
    </AddStakeSectionInfoList>

    <div className={"mt-3.5 flex flex-row gap-2.5"}>
      <WhiteFilledButton className={"flex-1"} onClick={props.onClose}>
        Cancel
      </WhiteFilledButton>

      <GradientFilledButton className={"flex-1"} onClick={props.onConfirm}>
        Confirm
      </GradientFilledButton>
    </div>
  </CardModalContent>
)

const ClaimCountInfo: FC<{
  token: TokenInfo
  count: number
  countToUSD?: number
}> = props => (
  <>
    <TokenCount token={props.token} count={props.count} />
    &nbsp;
    <TokenName token={props.token} />
    {props.countToUSD != null && !Number.isNaN(props.countToUSD) && (
      <span className={"text-gray-500 ml-[5px]"}>
        ($&nbsp;
        <TokenCount token={TokenInfoPresets.USD} count={props.countToUSD} />)
      </span>
    )}
  </>
)
