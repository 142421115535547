import { openStructuredDataSignatureRequestPopup } from "@stacks/connect"
import { SignatureData } from "@stacks/connect/dist/types/types/signature"
import { serializeCV } from "@stacks/transactions"
import { stringT, tupleT } from "clarity-codegen"
import { from, Observable, of } from "rxjs"
import {
  ALLOW_CONTRACT_ARGUMENTATION,
  STACK_APP_DETAILS,
  STACK_NETWORK,
} from "../../../../config"
import { numberT } from "../../../../generated/smartContractHelpers/codegenImport"
import {
  sendPublicRequest,
  sendRequest,
} from "../../../../generated/stxdxHelpers/stxdxApi"
import publicTestUsers from "../../../../generated/whitelist.json"
import { CancelError } from "../../../../utils/error"
import { signatureDomain } from "../constants"

const walletWhitelist: Promise<string[]> = fetch(
  "https://still-wave-a807-production.reily.workers.dev/v1/table/474f84ab0c8444ef84feae17dee513e8",
)
  .then(r => r.json())
  .then((a: any[]) => a.flatMap((b: any) => b.Address.split(",")))

export function isWalletAddressInWhitelist(
  walletAddress: string,
): Observable<boolean> {
  if (publicTestUsers.includes(walletAddress)) {
    return of(true)
  }
  return from(
    walletWhitelist.then(whitelist => whitelist.includes(walletAddress)),
  )
}
export async function fetchPNLInfo(
  uid: number,
  auth: string,
): Promise<{ count: number; percentage: number }> {
  const {
    data: { today_pnl, today_pnl_percent },
  } = await sendRequest(auth)("AccountController_getAccountTodayPnlById", {
    path: { uid },
  })
  return {
    count: Number(today_pnl) / 1e8,
    percentage: Number(today_pnl_percent),
  }
}

const AuthPayloadCodec = tupleT({
  address: stringT,
  expiration: numberT,
})

const AuthInfoJWTTokenKey = "AuthInfoJWTToken_Key"
const AuthInfoJWTSignatureKey = "AuthInfoJWTSignature_Key"

export function clearLocalSavedJWT(): void {
  window.localStorage.removeItem(AuthInfoJWTTokenKey)
}

export async function getTokenFromLocalStorage(): Promise<string | null> {
  const savedSign = window.localStorage.getItem(AuthInfoJWTSignatureKey)
  if (savedSign != null) {
    const { payload, signature } = JSON.parse(savedSign)
    const result = await signAuthInfo(payload, signature)
    window.localStorage.removeItem(AuthInfoJWTSignatureKey)
    return result
  }
  return window.localStorage.getItem(AuthInfoJWTTokenKey)
}

export function saveSignatureForLater(
  payload: string,
  signature: string,
): void {
  window.localStorage.setItem(
    AuthInfoJWTSignatureKey,
    JSON.stringify({ signature, payload }),
  )
}

export const argumentSignature = ALLOW_CONTRACT_ARGUMENTATION
  ? new URLSearchParams(window.location.search).get("signature")
  : null

export async function getAuthSignatureFromWallet(
  address: string,
  duration = 600,
): Promise<{ payload: string; signature: string; publicKey: string }> {
  const expireTimestamp = Math.floor(duration + Date.now() / 1000)
  const clairty = AuthPayloadCodec.encode({
    address,
    expiration: expireTimestamp,
  })
  if (argumentSignature) {
    return {
      payload: serializeCV(clairty).toString("hex"),
      signature: argumentSignature,
      publicKey: "",
    }
  }
  const { signature, publicKey } = await new Promise<SignatureData>(
    (resolve, reject) => {
      openStructuredDataSignatureRequestPopup({
        stxAddress: address,
        domain: signatureDomain,
        message: clairty,
        appDetails: STACK_APP_DETAILS,
        network: STACK_NETWORK,
        onFinish: resolve,
        onCancel: () => reject(new CancelError("User cancelled")),
      }).catch(reject)
    },
  )
  const payload = serializeCV(clairty).toString("hex")
  return { payload, signature, publicKey }
}

export async function signAuthInfo(
  payload: string,
  signature: string,
): Promise<string> {
  const {
    data: { access_token },
  } = await sendPublicRequest("AccountController_login", {
    body: {
      payload,
      signature,
    },
  })
  if (!argumentSignature) {
    window.localStorage.setItem(AuthInfoJWTTokenKey, access_token)
  }
  return access_token
}
