import { FC } from "react"
import { Divider } from "../../components/Divider"
import { NavLink } from "../../components/NavLink"
import { PrimaryPageTitle } from "../../components/PageStack/PrimaryPageTitle"
import { ReactComponent as BookIcon } from "./book.svg"
import { WiredDataComparisonModal } from "./components/WiredDataComparisonModal"
import { WiredPerformancePanel } from "./components/WiredPerformancePanel"
import { WiredSimulatorPanel } from "./components/WiredSimulatorPanel"
import video from "./crp.mp4"
import { CRPSimulatorStoreProvider } from "./store/useCRPSimulatorStore"

const WHITEPAPER_LINK =
  "https://medium.com/alexgobtc/whitepaper-2-automated-market-making-of-the-collateral-rebalancing-pool-937f1068fe0"

const PageTitle: FC = () => {
  return (
    <div className={"flex flex-wrap gap-2 items-center justify-between"}>
      <PrimaryPageTitle
        subtitle="Introducing dynamic collateral asset management to avert liquidation."
        uppercase={false}
      >
        ALEX Collateral Rebalancing Pool (CRP)
      </PrimaryPageTitle>

      <NavLink to={WHITEPAPER_LINK} className="flex items-center gap-2.5">
        <BookIcon />
        <span className="text-base leading-6 font-normal gray-100">
          Read the Whitepaper
        </span>
      </NavLink>
    </div>
  )
}

const PageContent: FC = () => {
  return (
    <div className="flex flex-row gap-x-8">
      <WiredSimulatorPanel />
      <WiredPerformancePanel />
    </div>
  )
}

const CaseStudy: FC = () => {
  return (
    <section className="flex flex-col gap-y-2.5">
      <h1 className="text-xl leading-7 font-medium text-white">
        What is CRP？ (Case Study)
      </h1>
      <div className="flex flex-row gap-8">
        <div className="w-[30rem] h-[22.5rem] flex-none">
          <video
            controls={false}
            playsInline
            preload="auto"
            autoPlay
            muted
            loop
            disablePictureInPicture
          >
            <source src={video} type="video/mp4" />
            Sorry, your browser does not support embedded videos.
          </video>
        </div>
        <div className="flex flex-col gap-y-6 flex-auto text-base leading-6 font-medium text-gray-400">
          <p>
            ALEX CRP uses diversified collateral pools, to mitigate the chance
            of default and potential shortening of the loan term caused by
            liquidation.
          </p>

          <p>
            An algorithmic engine dynamically adjusts the split of the risky and
            risk-free asset in the diversified pool, based on a quantitative
            model.
          </p>

          <p>
            In Mar 2020, all risky assets declined sharply and BTC experienced
            one of its largest drops in history.
          </p>

          <p>
            Between Mar 12 and 13, 2022, BTC fell 40%, triggering de-leveraging
            of CRP pool. By the end of the sell-off, the BTC weight shifted from
            c. 60% to 20%, with LTV increasing from c. 80% to 90%. The dynamic
            de-leveraging meant, on Mar 30, the pool still retained c. 82% of
            the initial collateral value.
          </p>

          <p>
            Dynamic rebalancing of CRP avoids concentrated liquidation required
            by many lending platforms, thus minimising slippage and costs to
            both lenders and borrowers.
          </p>
        </div>
      </div>
    </section>
  )
}

export const CRPSimulatorPage: FC = () => {
  return (
    <CRPSimulatorStoreProvider>
      <div className="flex flex-col gap-y-10">
        <PageTitle />
        <PageContent />
        <Divider className="border-gray-500" />
        <CaseStudy />
      </div>

      <WiredDataComparisonModal />
    </CRPSimulatorStoreProvider>
  )
}
