import { FC } from "react"
import {
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory"
import { formatPercentNumber } from "../../../../../components/PercentNumber"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { isNotNull } from "../../../../../utils/utils"
import {
  CalcPositionLabel,
  VictoryDatum,
} from "../../../components/EarningsPreviewChartFrame"

const animateGraph = {
  duration: 500,
  onLoad: { duration: 500 },
}

export const lineStyles = {
  autoStakeEarningsCurve: {
    width: 4,
    color: "#059669",
  },
  manualStakeEarningsCurve: {
    width: 4,
    color: "#EA580C",
  },
} as const

export interface CurvePoint {
  cycle: number
  apr: number
}

export interface EarningsPreviewChartProps {
  alexToken: TokenInfo
  autoAlexToken: TokenInfo

  autoStakeEarningsCurve: CurvePoint[]
  manualStakeEarningsCurve: CurvePoint[]
}

export const EarningsPreviewChart: FC<EarningsPreviewChartProps> = props => {
  return (
    <div style={{ margin: "-10px -40px" }}>
      {/* Graph */}
      <VictoryChart
        padding={{ top: 10, right: 32, bottom: 10, left: 32 }}
        containerComponent={
          <VictoryVoronoiContainer
            labels={data => {
              const point: VictoryDatum<CurvePoint> = data.datum

              const auto = props.autoStakeEarningsCurve.find(
                p => p.cycle === point.cycle,
              )
              const manual = props.manualStakeEarningsCurve.find(
                p => p.cycle === point.cycle,
              )

              const isTwoCurveWillBePresentInOneLabel =
                auto && manual && auto.apr === manual.apr
              if (
                isTwoCurveWillBePresentInOneLabel &&
                point.childName === "manualStakeEarningsCurve"
              ) {
                return undefined as any
              }

              return [
                `Cycle: ${point.cycle}`,
                auto == null
                  ? null
                  : `Auto Staking: ${formatPercentNumber(auto.apr)}`,
                manual == null
                  ? null
                  : `Manual Staking: ${formatPercentNumber(manual.apr)}`,
              ]
                .filter(isNotNull)
                .join("\n")
            }}
            labelComponent={<VictoryTooltip constrainToVisibleArea />}
          />
        }
      >
        {/* X-axis */}
        <VictoryAxis
          label="Cycles"
          tickFormat={() => ""}
          tickCount={props.autoStakeEarningsCurve.length}
          axisLabelComponent={
            <CalcPositionLabel
              angle={0}
              dy={-42}
              calcX={props => (props.x ?? 0) * 2 - 34}
              textAnchor={"end"}
              verticalAnchor={"start"}
              style={{ fontSize: 11, fill: "#E5E7EB" }}
            />
          }
          style={{
            axis: {
              stroke: "#7E8597",
              strokeWidth: 1,
            },
            grid: {
              stroke: "#E5E7EB",
              strokeWidth: 0.5,
              opacity: 0.25,
            },
          }}
        />

        {/* Y-axis */}
        <VictoryAxis
          dependentAxis
          label="Reward"
          tickFormat={() => ""}
          axisLabelComponent={
            <CalcPositionLabel
              angle={0}
              dx={30}
              calcY={() => 12}
              textAnchor={"start"}
              verticalAnchor={"start"}
              style={{ fontSize: 11, fill: "#E5E7EB" }}
            />
          }
          style={{
            axis: {
              stroke: "#7E8597",
              strokeWidth: 1,
            },
          }}
        />

        {/* Orange line graph */}
        <VictoryLine
          name={"manualStakeEarningsCurve"}
          data={props.manualStakeEarningsCurve}
          x={"cycle"}
          y={"apr"}
          style={{
            data: {
              stroke: lineStyles.manualStakeEarningsCurve.color,
              strokeWidth: lineStyles.manualStakeEarningsCurve.width,
              strokeDasharray: "10,5",
            },
          }}
          animate={animateGraph}
        />

        {/* Green line graph */}
        <VictoryLine
          name={"autoStakeEarningsCurve"}
          data={props.autoStakeEarningsCurve}
          x={"cycle"}
          y={"apr"}
          style={{
            data: {
              strokeWidth: lineStyles.autoStakeEarningsCurve.width,
              stroke: lineStyles.autoStakeEarningsCurve.color,
            },
          }}
          animate={animateGraph}
        />
      </VictoryChart>
    </div>
  )
}
