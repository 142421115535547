import clsx from "clsx"
import { FCC } from "../../utils/reactHelpers/types"
import { ReactComponent as EmptyIcon } from "./_/EmptyStateIcon.svg"

export const EmptyState: FCC<{
  className?: string
}> = props => (
  <div
    className={clsx(
      "flex flex-col gap-y-4 items-center justify-center",
      props.className,
    )}
  >
    <EmptyIcon />

    <span className="text-base leading-6 font-normal text-gray-600">
      {props.children ?? "No data"}
    </span>
  </div>
)
