import { useConnect } from "@stacks/connect-react"
import { action } from "mobx"
import { FC } from "react"
import { Modal } from "../../../components/Modal"
import { useContentfulLongText } from "../../../generated/i18nHelpers/useContentfulLongText"
import { suspenseResource } from "../../../utils/SuspenseResource"
import { AcknowledgeTermsModalContent } from "../components/AcknowledgeTermsModalContent/AcknowledgeTermsModalContent"
import { TestNetworkSetupGuideModalContent } from "../components/TestNetworkSetupGuideModalContent/TestNetworkSetupGuideModalContent"
import { TradingAccountContent } from "../components/TradingAccount/TradingAccountContent"
import { TradingAccountContent$ApplyEligibility } from "../components/TradingAccount/TradingAccountContent$ApplyEligibility"
import { TradingAccountContent$ConnectWallet } from "../components/TradingAccount/TradingAccountContent$ConnectWallet"
import { TradingAccountContent$SwitchNetwork } from "../components/TradingAccount/TradingAccountContent$SwitchNetwork"
import { useOrderbookStore } from "../store/useOrderbookStore"
import { WiredDepositDialog } from "./WiredDepositDialog"
import { WiredWithdrawDialog } from "./WiredWithdrawDialog"

export const WiredTradingAccountContent: FC<{
  gapClassName?: string
}> = props => {
  const { doAuth } = useConnect()
  const store = useOrderbookStore()
  const { authStore } = store

  const testnetSetupGuideMarkdownContent = useContentfulLongText(
    () => store.longText.testnetSetupGuide.value$,
  )

  if (!store.myInfo.isUsingRightNetwork$) {
    return (
      <>
        <TradingAccountContent$SwitchNetwork
          gapClassName={props.gapClassName}
          onSeeSwitchNetworkGuide={action(() => {
            store.myInfo.showingSwitchNetworkGuide = true
          })}
        />
        <Modal
          visible={store.myInfo.showingSwitchNetworkGuide}
          onClose={store.myInfo.hideSwitchNetworkGuide}
        >
          <TestNetworkSetupGuideModalContent
            markdownContent={testnetSetupGuideMarkdownContent}
            onClose={store.myInfo.hideSwitchNetworkGuide}
          />
        </Modal>
      </>
    )
  }

  if (!authStore.isWalletConnected) {
    return <TradingAccountContent$ConnectWallet onConnectWallet={doAuth} />
  }

  if (
    !store.myInfo.hasRegistered$ &&
    !store.myInfo.isWalletInWhitelist.value$
  ) {
    return (
      <TradingAccountContent$ApplyEligibility
        gapClassName={props.gapClassName}
        formLink={"https://orderbook.alexlab.co/"}
      />
    )
  }

  return (
    <>
      <TradingAccountContent
        gapClassName={props.gapClassName}
        totalBalancePnlInTokenCount={suspenseResource(
          () => store.myInfo.todayPNL.value$.count,
        )}
        totalBalancePnlInPercentage={suspenseResource(
          () => store.myInfo.todayPNL.value$.percentage,
        )}
        totalBalance={suspenseResource(() => store.myInfo.totalBalanceInUSD$)}
        viewingToken={store.currency.getTokenInfo$(store.myInfo.currency.read$)}
        viewingTokenAvailableCount={suspenseResource(
          () => store.myInfo.selectedTokenAvailableBalance$,
        )}
        viewingTokenLockedCount={suspenseResource(
          () => store.myInfo.selectedTokenLockedBalance$,
        )}
        availableTokens={store.info.allSupportedCurrencies.map(c => ({
          token: store.currency.getTokenInfo$(c),
          availableCount: suspenseResource(
            () => store.myInfo.stxDxBalance$(c)?.available ?? 0,
          ),
        }))}
        onViewingTokenChange={newToken =>
          store.myInfo.currency.set(newToken.id as any)
        }
        onDeposit={() =>
          store.myInfo.hasRegistered$
            ? store.myInfo.startDeposit()
            : store.myInfo.showAgreement()
        }
        onWithdraw={suspenseResource(() =>
          store.myInfo.hasRegistered$
            ? () => store.myInfo.startWithdraw()
            : null,
        )}
      />
      <WiredDepositDialog />
      <WiredWithdrawDialog />
      <WiredAgreementModal />
    </>
  )
}

const WiredAgreementModal: FC = () => {
  const store = useOrderbookStore()
  const contentMarkdown = useContentfulLongText(
    () => store.longText.acknowledgeTerm.value$,
  )

  return (
    <Modal
      visible={store.myInfo.showingAgreement}
      onClose={action(() => (store.myInfo.showingAgreement = false))}
    >
      <AcknowledgeTermsModalContent
        contentMarkdown={contentMarkdown}
        onClose={action(() => (store.myInfo.showingAgreement = false))}
        onCancel={action(() => (store.myInfo.showingAgreement = false))}
        onAccept={() => store.myInfo.startDeposit()}
      />
    </Modal>
  )
}
