import { TokenInfo } from "../../../utils/models/TokenInfo"

export enum TradingFormErrorType {
  ConnectWalletRequired = "ConnectWalletRequired",
  AmountIsEmpty = "AmountIsEmpty",
  LimitPriceTooSmall = "LimitPriceTooSmall",
  TradeTokenCountTooSmall = "TradeTokenCountTooSmall",
  TotalPriceTooSmall = "TotalPriceTooSmall",
  InsufficientPriceTokenBalance = "InsufficientPriceTokenBalance",
  InsufficientTradeTokenBalance = "InsufficientTradeTokenBalance",
}

export enum TradingFormWarningType {
  AbnormalBuyingPrice = "AbnormalBuyingPrice",
  AbnormalSellingPrice = "AbnormalSellingPrice",
}

export type TradingFormError =
  | TradingFormError.Common
  | TradingFormError.TotalPriceTooSmall
export namespace TradingFormError {
  export interface Common {
    type: Exclude<
      TradingFormErrorType | TradingFormWarningType,
      TradingFormErrorType.TotalPriceTooSmall
    >
  }

  export interface TotalPriceTooSmall {
    type: TradingFormErrorType.TotalPriceTooSmall
    priceToken: TokenInfo
    minimumPriceTokenCount: number
  }
}

export enum DepositFormErrorType {
  InsufficientTokenBalance,
  LessThanMinimizeAmount,
  AmountIsEmpty,
}
export interface DepositFormError {
  type: DepositFormErrorType
  message?: string
}

export enum WithdrawFormErrorType {
  ExceedMaxAmount,
  AmountIsEmpty,
}
export interface WithdrawFormError {
  type: WithdrawFormErrorType
  message?: string
}

export enum StxDxOrderType {
  Limit = "limit",
  Market = "market",
  StopLimit = "stopLimit",
}

export type OrderDirection = "buy" | "sell"

export interface TriggerCondition {
  type: "gte" | "lte"
  priceTokenCountPerTradeToken: number
}

export type OpenOrderRecord =
  | OpenOrderRecord.Limit
  | OpenOrderRecord.Market
  | OpenOrderRecord.StopLimit
export namespace OpenOrderRecord {
  export interface Common {
    orderHash: string
    status: StxDxOrderStatus
    orderDirection: OrderDirection
    expectedTradeTokenCount: number
    filledPriceTokenCount: number
    filledPercentage: number
    createdAt: Date
    tradeToken: TokenInfo
    priceToken: TokenInfo
    onCancel?: () => void | Promise<void>
  }

  export interface Limit extends Common {
    orderType: StxDxOrderType.Limit
    priceTokenCountPerTradeToken: number
    priceTokenTotalCount: number
  }

  export interface Market extends Common {
    orderType: StxDxOrderType.Market
  }

  export interface StopLimit extends Common {
    orderType: StxDxOrderType.StopLimit
    priceTokenCountPerTradeToken: number
    priceTokenTotalCount: number
    triggerCondition: TriggerCondition
  }
}
export enum StxDxOrderStatus {
  Matching = "matching",
  Matched = "matched",
  Settled = "settled",
  Expired = "expired",
  Canceled = "canceled",
}

export enum FundHistoryRecordDirection {
  Deposit = "deposit",
  Withdraw = "withdraw",
}
export enum FundHistoryRecordStatus {
  Pending = "pending",
  Confirmed = "confirmed",
  Failed = "failed",
}
export interface FundHistoryRecord {
  status: FundHistoryRecordStatus
  token: TokenInfo
  tokenCount: number
  direction: FundHistoryRecordDirection
  transactionId: string
  createdAt: Date
  explorerLink: string
}

export type TradeHistoryRecord =
  | TradeHistoryRecord.Limit
  | TradeHistoryRecord.Market
  | TradeHistoryRecord.StopLimit
export namespace TradeHistoryRecord {
  interface Common {
    id: string
    tradeToken: TokenInfo
    priceToken: TokenInfo
    orderDirection: OrderDirection
    executedTradeTokenCount: number
    priceTokenTotalCount: number
    feePriceTokenCount: number
    createdAt: Date
    status: TradesHistoryRecordStatus
  }

  export interface Limit extends Common {
    orderType: StxDxOrderType.Limit
    priceTokenCountPerTradeToken: number
  }

  export interface Market extends Common {
    orderType: StxDxOrderType.Market
    priceTokenCountAverage: number
  }

  export interface StopLimit extends Common {
    orderType: StxDxOrderType.StopLimit
    priceTokenCountPerTradeToken: number
  }
}
export enum TradesHistoryRecordStatus {
  Settling = "Settling",
  Succeed = "Succeed",
  Failed = "Failed",
}

export type OrdersHistoryRecord =
  | OrdersHistoryRecord.Limit
  | OrdersHistoryRecord.Market
  | OrdersHistoryRecord.StopLimit
export namespace OrdersHistoryRecord {
  interface Common {
    orderHash: string
    tradeToken: TokenInfo
    priceToken: TokenInfo
    orderDirection: OrderDirection
    status: OrdersHistoryRecordStatus
    expectedTradeTokenCount: number
    executedTradeTokenCount: number
    priceTokenTotalCount: number
    createdAt: Date
  }

  export interface Limit extends Common {
    orderType: StxDxOrderType.Limit
    priceTokenCountPerTradeToken: number
  }

  export interface Market extends Common {
    orderType: StxDxOrderType.Market
    priceTokenCountAverage: number
  }

  export interface StopLimit extends Common {
    orderType: StxDxOrderType.StopLimit
    priceTokenCountPerTradeToken: number
    triggerCondition: TriggerCondition
  }
}
export enum OrdersHistoryRecordStatus {
  Normal = "Normal",
  Expired = "Expired",
  Cancelled = "Cancelled",
}
