import { FC } from "react"
import { GradientFilledButton } from "../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { Card } from "../../components/Card"
import { useConnect } from "../../components/ConnectWallet/ConnectProvider"
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator"
import { Spensor } from "../../components/Spensor"
import { useAuthStore } from "../../stores/authStore/useAuthStore"
import { useNotifyStore } from "../../stores/notifyStore/useNotifyStore"
import { ReactComponent as EmptyIcon } from "./components/TransactionsTable/empty.svg"
import { TransactionTable } from "./components/TransactionsTable/TransactionsTable"
import { SYSTEM_MESSAGE_FILTER } from "./store/SystemMessagesModule"

export const WiredTransactionsTable: FC = () => {
  const store = useNotifyStore()
  const authStore = useAuthStore()
  const [connect] = useConnect()
  return (
    <Spensor
      fallback={
        authStore.isWalletConnected ? (
          <div className="flex items-center justify-center py-20">
            <LoadingIndicator className="m-auto" />
          </div>
        ) : (
          <Card className="min-w-min">
            <div className="flex flex-col gap-y-4 items-center justify-center py-20">
              <EmptyIcon />
              <span className="text-base leading-6 font-normal text-gray-600">
                Your transaction history will appear here.
              </span>
              <GradientFilledButton onClick={connect}>
                Connect Wallet
              </GradientFilledButton>
            </div>
          </Card>
        )
      }
    >
      {() =>
        store.filter === SYSTEM_MESSAGE_FILTER ? null : (
          <TransactionTable
            data={store.transactions.rowData$}
            dataToExport={store.transactions.rowToExportData$}
            allTokenInfos={store.currencyStore.allTokenInfos$}
            filter={store.filter}
          />
        )
      }
    </Spensor>
  )
}
