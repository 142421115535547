import { FC } from "react"
import { StakingHarvestRowData } from "../../../../store/transformerGroups/stake/stakingHarvest"
import { AllTokenInfos } from "../../../../types"
import { BaseCellContainer } from "../../cells/BaseCellContainer"
import { OneLineAmountCell } from "../../cells/OneLineAmountCell"
import { OneLineCurrencyCell } from "../../cells/OneLineCurrencyCell"
import { BaseRow } from "../BaseRow"

export const StakingHarvestRow: FC<{
  rowData: StakingHarvestRowData
  allTokenInfos: AllTokenInfos
}> = ({ rowData, allTokenInfos }) => (
  <BaseRow
    rowData={rowData}
    currencyCell={
      <OneLineCurrencyCell
        allTokenInfos={allTokenInfos}
        fromCurrencyAmounts={[rowData.principalCurrencyAmount]}
        toCurrencyAmounts={rowData.rewards}
      />
    }
    amountCell={
      <OneLineAmountCell
        allTokenInfos={allTokenInfos}
        fromCurrencyAmounts={[rowData.principalCurrencyAmount]}
        toCurrencyAmounts={rowData.rewards}
      />
    }
    tradeInfoCell={<BaseCellContainer>Reward Harvest</BaseCellContainer>}
  />
)
