import clsx from "clsx"
import { FC, ReactNode, SVGProps, useState } from "react"
import { defineMessages, useIntl } from "react-intl"
import { HeadlessButton } from "../../../components/button/HeadlessButton"
import { Card, CardDivider } from "../../../components/Card"
import { Dropdown } from "../../../components/Dropdown"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../components/InfoList"
import { PercentNumber } from "../../../components/PercentNumber"
import { Spensor } from "../../../components/Spensor"
import { TipIcon } from "../../../components/TipIcon/TipIcon"
import { TokenCount } from "../../../components/TokenCount"
import { TokenName } from "../../../components/TokenName"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { useEffectWithRef } from "../../../utils/reactHelpers/useEffectWithRef"
import { readResource, SuspenseResource } from "../../../utils/SuspenseResource"
import { Colors } from "./designTokens"
import { TokenNameStack } from "./TokenNameStack"
import { ExclamationOutlinedIcon } from "../../../components/TipIcon/ExclamationOutlinedIcon"

const horizontalPadding = 4 * 4

export const SummaryBar: FC<{
  className?: string
  tradeToken: TokenInfo
  priceToken: TokenInfo
  currentPrice: SuspenseResource<number>
  currentPriceDeltaPercentage: SuspenseResource<number>
  priceChangeIn24Hours?: SuspenseResource<{
    priceBefore24Hours: number
    priceDeltaPercentage: number
  }>
  priceLowestIn24Hours?: SuspenseResource<number>
  priceHighestIn24Hours?: SuspenseResource<number>
  tradeTokenVolumeIn24Hours?: SuspenseResource<number>
  priceTokenVolumeIn24Hours?: SuspenseResource<number>
  tokenPairSelector: ReactNode
  onShowNetworkSetupGuide?: () => void
}> = props => {
  const { $t } = useIntl()

  const [selectorVisible, setSelectorVisible] = useState(false)

  useEffectWithRef<[TokenInfo, TokenInfo]>(
    memoRef => {
      if (memoRef.current == null) {
        memoRef.current = [props.tradeToken, props.priceToken]
        return
      }

      if (
        !TokenInfo.isIdentical(memoRef.current[0], props.tradeToken) ||
        !TokenInfo.isIdentical(memoRef.current[1], props.priceToken)
      ) {
        memoRef.current = [props.tradeToken, props.priceToken]
        setSelectorVisible(false)
      }
    },
    [props.priceToken, props.tradeToken],
  )

  return (
    <Card
      className={clsx(
        "flex items-stretch gap-x-4 min-w-fit-content min-h-[60px]",
        props.className,
      )}
      boxClassName={"py-2.5"}
      style={{
        paddingInline: horizontalPadding,
      }}
    >
      <Dropdown
        visible={selectorVisible}
        onVisibleChange={setSelectorVisible}
        triggerMethod={"click"}
        triggerContainerClassName={"flex items-center"}
        trigger={
          <HeadlessButton
            className={"flex items-center text-gray-200 text-lg font-semibold"}
          >
            <TokenNameStack
              tradeToken={props.tradeToken}
              priceToken={props.priceToken}
            />
            &nbsp;
            <DropdownIcon />
          </HeadlessButton>
        }
        withoutInnerContainer={true}
        placement={"start"}
      >
        <div style={{ marginLeft: -horizontalPadding }}>
          {props.tokenPairSelector}
        </div>
      </Dropdown>

      <CardDivider direction={"vertical"} />

      <InfoList
        gapClassName={"gap-x-8"}
        listItemClassName={"justify-center"}
        direction={"row"}
        listItemDirection={"column"}
      >
        <InfoListItem>
          <InfoListItemTitle className={"sr-only"}>
            {$t(i18n.currentPrice)}
          </InfoListItemTitle>
          <InfoListItemDetail className={"flex flex-row items-center"}>
            <Spensor>
              {() => (
                <>
                  <span
                    className={clsx(
                      "text-base font-semibold",
                      readResource(props.currentPriceDeltaPercentage) < 0
                        ? Colors.decreaseTextClassName
                        : readResource(props.currentPriceDeltaPercentage) > 0
                        ? Colors.increaseTextClassName
                        : "",
                    )}
                  >
                    <TokenCount
                      token={props.priceToken}
                      count={readResource(props.currentPrice)}
                    />
                  </span>
                  &nbsp;
                  <TipIcon
                    Icon={ExclamationOutlinedIcon}
                    inline
                    tip={$t(i18n.currentPriceDisclaimer)}
                  />
                </>
              )}
            </Spensor>
          </InfoListItemDetail>
        </InfoListItem>

        {props.priceChangeIn24Hours && (
          <InfoListItem>
            <InfoListItemTitle>{$t(i18n["24hChange"])}</InfoListItemTitle>
            <Spensor>
              {() => {
                const priceChange = readResource(props.priceChangeIn24Hours!)
                return (
                  <InfoListItemDetail
                    className={
                      priceChange.priceDeltaPercentage < 0
                        ? Colors.decreaseTextClassName
                        : Colors.increaseTextClassName
                    }
                  >
                    <TokenCount
                      token={props.priceToken}
                      count={Math.abs(priceChange.priceBefore24Hours)}
                    />{" "}
                    {priceChange.priceDeltaPercentage !== 0 && (
                      <>
                        {priceChange.priceDeltaPercentage < 0 ? "-" : "+"}{" "}
                        <PercentNumber
                          number={Math.abs(priceChange.priceDeltaPercentage)}
                        />
                      </>
                    )}
                  </InfoListItemDetail>
                )
              }}
            </Spensor>
          </InfoListItem>
        )}

        {props.priceHighestIn24Hours && (
          <InfoListItem>
            <InfoListItemTitle>{$t(i18n["24hHigh"])}</InfoListItemTitle>
            <InfoListItemDetail>
              <Spensor fallback="--">
                {() => (
                  <TokenCount
                    token={props.priceToken}
                    count={readResource(props.priceHighestIn24Hours)!}
                  />
                )}
              </Spensor>
            </InfoListItemDetail>
          </InfoListItem>
        )}

        {props.priceLowestIn24Hours && (
          <InfoListItem>
            <InfoListItemTitle>{$t(i18n["24hLow"])}</InfoListItemTitle>
            <InfoListItemDetail>
              <Spensor fallback="--">
                {() => (
                  <TokenCount
                    token={props.priceToken}
                    count={readResource(props.priceLowestIn24Hours)!}
                  />
                )}
              </Spensor>
            </InfoListItemDetail>
          </InfoListItem>
        )}

        {props.tradeTokenVolumeIn24Hours && (
          <InfoListItem>
            <InfoListItemTitle>
              {$t(i18n["24hVolume"], {
                token: <TokenName token={props.tradeToken} />,
              })}
            </InfoListItemTitle>
            <InfoListItemDetail>
              <Spensor fallback="--">
                {() => (
                  <TokenCount
                    token={props.tradeToken}
                    count={readResource(props.tradeTokenVolumeIn24Hours)!}
                  />
                )}
              </Spensor>
            </InfoListItemDetail>
          </InfoListItem>
        )}

        {props.priceTokenVolumeIn24Hours && (
          <InfoListItem>
            <InfoListItemTitle>
              {$t(i18n["24hVolume"], {
                token: <TokenName token={props.priceToken} />,
              })}
            </InfoListItemTitle>
            <InfoListItemDetail>
              <Spensor fallback="--">
                {() => (
                  <TokenCount
                    token={props.priceToken}
                    count={readResource(props.priceTokenVolumeIn24Hours!)}
                  />
                )}
              </Spensor>
            </InfoListItemDetail>
          </InfoListItem>
        )}
      </InfoList>

      <CardDivider
        borderClassName={"border-transparent"}
        direction={"vertical"}
      />

      {props.onShowNetworkSetupGuide != null && (
        <HeadlessButton
          className={
            "ml-auto flex flex-row items-center text-blue-600 whitespace-nowrap"
          }
          onClick={props.onShowNetworkSetupGuide}
        >
          <HelpIcon className={"inline"} />
          &nbsp; {$t(i18n.howToSetTest)}
        </HeadlessButton>
      )}
    </Card>
  )
}

const i18n = defineMessages({
  currentPrice: {
    defaultMessage: "Current Price",
    description: "Orderbook/top summary bar",
  },
  currentPriceDisclaimer: {
    defaultMessage:
      "Disclaimer: the prices reflect the latest traded on the orderbook, which may be away from those on other exchanges/AMMs",
    description: "Orderbook/top summary bar",
  },
  "24hChange": {
    defaultMessage: "24h Change",
    description: "Orderbook/top summary bar",
  },
  "24hHigh": {
    defaultMessage: "24h High",
    description: "Orderbook/top summary bar",
  },
  "24hLow": {
    defaultMessage: "24h Low",
    description: "Orderbook/top summary bar",
  },
  "24hVolume": {
    defaultMessage: "24h Volume ({token})",
    description: "Orderbook/top summary bar",
  },
  howToSetTest: {
    defaultMessage: "How to set testnet",
    description: "Orderbook/top summary bar",
  },
})

const HelpIcon: FC<{ className?: string }> = props => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 8C1 4.13428 4.13428 1 8 1C11.8657 1 15 4.13428 15 8C15 11.8657 11.8657 15 8 15C4.13428 15 1 11.8657 1 8ZM8 0C3.582 0 0 3.582 0 8C0 12.418 3.582 16 8 16C12.418 16 16 12.418 16 8C16 3.582 12.418 0 8 0ZM7.16681 11.3333C7.16681 11.7933 7.54015 12.1666 8.00015 12.1666C8.46081 12.1666 8.83348 11.7933 8.83348 11.3333C8.83348 10.8733 8.46081 10.5 8.00015 10.5C7.54015 10.5 7.16681 10.8733 7.16681 11.3333ZM8.68415 9.66665C8.67615 9.22398 8.70481 8.86465 9.30748 8.21465C9.75881 7.72798 10.3195 7.12265 10.3301 6.20065C10.3375 5.58332 10.1408 5.05398 9.76081 4.66865C9.35548 4.25798 8.75148 4.03198 8.06081 4.03198C6.60681 4.03198 5.66748 5.06532 5.66748 6.66532H7.00748C7.00748 5.67465 7.56015 5.32332 8.03281 5.32332C8.45615 5.32332 8.90415 5.60398 8.94281 6.14065C8.98481 6.70465 8.68348 6.99198 8.30148 7.35465C7.36081 8.24998 7.34281 8.68332 7.34748 9.66665H8.68415Z"
      fill="#C4C4C4"
    />
  </svg>
)

const DropdownIcon: FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.307 12.75L2.764 6.256l1.06-1.06 5.483 5.482 5.482-5.482 1.06 1.06-6.542 6.494z"
        fill="#E5E7EB"
        opacity={0.5}
      />
    </svg>
  )
}
