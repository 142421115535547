import {
  action,
  makeObservable,
  observable,
  onBecomeObserved,
  onBecomeUnobserved,
} from "mobx"
import { utilWindowFocus } from "../utils/utilWindowFocus"

class TimerValue {
  constructor(private readonly interval: number) {
    makeObservable(this)

    let timer: () => void
    onBecomeObserved(this, "value", () => {
      timer = setIntervalWithTimeout(
        action(() => {
          this.value = new Date().getTime()
        }),
        interval,
      )
    })
    onBecomeUnobserved(this, "value", () => {
      timer()
    })
  }

  @observable value = new Date().getTime()
}

// fix chrome issue where in background setInterval will fire multiple time on resume
export function setIntervalWithTimeout(
  action: () => void,
  duration: number,
): () => void {
  let timer = setTimeout(async function repeat() {
    await utilWindowFocus()
    action()
    timer = setTimeout(repeat, duration)
  }, duration)
  return () => clearTimeout(timer)
}

export default TimerValue
