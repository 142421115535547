import React, { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { connectWallet$t } from "../../../commonIntlMessages"
import { GradientFilledButton } from "../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { useConnect } from "../../../components/ConnectWallet/ConnectProvider"
import { useDialog } from "../../../components/dialog/useDialog"
import { MessageItem, WarnIcon } from "../../../components/message/MessageItem"
import { useMessage } from "../../../components/message/MessageProvider"
import { PercentNumber } from "../../../components/PercentNumber"
import { TokenCount } from "../../../components/TokenCount"
import { TokenName } from "../../../components/TokenName"
import { suspenseResource } from "../../../utils/SuspenseResource"
import { assertNever } from "../../../utils/types"
import { FundsHistoryTabContent } from "../components/OrderHistory/FundsHistoryTabContent/FundsHistoryTabContent"
import { OpenOrdersTabContent } from "../components/OrderHistory/OpenOrdersTabContent"
import {
  HistoryContentType,
  OrderHistoryFrame,
} from "../components/OrderHistory/OrderHistoryFrame"
import { OrdersHistoryTabContent } from "../components/OrderHistory/OrdersHistoryTabContent"
import { TradeHistoryTabContent } from "../components/OrderHistory/TradeHistoryTabContent/TradeHistoryTabContent"
import { useOrderbookStore } from "../store/useOrderbookStore"
import { RectButton } from "../components/wrappedCommonComponents/RectButton"
import { useAuthStore } from "../../../stores/authStore/useAuthStore"
import { useCreation } from "../../../utils/reactHelpers/useCreation"
import { action, observable } from "mobx"

const WiredOpenOrdersTabContent: FC = () => {
  const store = useOrderbookStore()
  const message = useMessage()
  const dialog = useDialog()
  const { $t } = useIntl()
  const page = useCreation(() => observable.box(0), [])
  const pageSize = 10
  return (
    <OpenOrdersTabContent
      records={suspenseResource(() =>
        store.myHistory.myPendingOrders$
          .slice(page.get() * pageSize, (page.get() + 1) * pageSize)
          .map(o => ({
            ...o,
            onCancel:
              o.onCancel == null
                ? undefined
                : async () => {
                    await dialog.confirm("Confirm cancelling this open order?")
                      .value
                    await o.onCancel!()

                    const [incomingToken, outgoingToken] =
                      o.orderDirection === "buy"
                        ? [o.tradeToken, o.priceToken]
                        : [o.priceToken, o.tradeToken]
                    const titleForNotify = $t(
                      defineMessage({
                        description:
                          "Orderbook/order cancel notification title",
                        defaultMessage:
                          "{orderDirection, select, other {Buy} sell {Sell}} {orderType, select, other {Limit} market {Market} stopLimit {Stop-Limit}} Order Canceled",
                      }),
                      {
                        orderType: o.orderType,
                        orderDirection: o.orderDirection,
                      },
                    )
                    const descForNotify = $t(
                      defineMessage({
                        description: "Orderbook/order cancel notification body",
                        defaultMessage:
                          "Cancelled {orderDirection, select, other {buy} sell {sell}} {orderType, select, other {limit} market {market} stopLimit {stop-limit}} order for {receivingTokenCount} {receivingTokenName} by using {sendingTokenName}，{executedPercentage} is executed",
                      }),
                      {
                        orderType: o.orderType,
                        orderDirection: o.orderDirection,
                        receivingTokenCount: (
                          <TokenCount
                            token={incomingToken}
                            count={o.expectedTradeTokenCount}
                          />
                        ),
                        receivingTokenName: <TokenName token={incomingToken} />,
                        sendingTokenName: <TokenName token={outgoingToken} />,
                        executedPercentage: (
                          <PercentNumber number={o.filledPercentage} />
                        ),
                      },
                    )
                    message.show(({ close }) => ({
                      message: (
                        <MessageItem
                          icon={<WarnIcon />}
                          title={titleForNotify}
                          content={descForNotify}
                          onClose={close}
                        />
                      ),
                    }))
                  },
          })),
      )}
      pagination={suspenseResource(() => ({
        recordCountTotal: store.myHistory.myPendingOrders$.length,
        currentPage: page.get(),
        recordCountPerPage: pageSize,
      }))}
      onChangePage={action(newPage => page.set(newPage))}
    />
  )
}

const WiredMyOrdersTabContent: FC = () => {
  const store = useOrderbookStore()
  const page = useCreation(() => observable.box(0), [])
  const pageSize = 10
  return (
    <OrdersHistoryTabContent
      records={suspenseResource(() =>
        store.myHistory.myNonPendingOrders$.slice(
          page.get() * pageSize,
          (page.get() + 1) * pageSize,
        ),
      )}
      pagination={suspenseResource(() => ({
        recordCountTotal: store.myHistory.myNonPendingOrders$.length,
        currentPage: page.get(),
        recordCountPerPage: pageSize,
      }))}
      onChangePage={action(newPage => page.set(newPage))}
    />
  )
}

const WiredMyTradesTabContent: FC = () => {
  const store = useOrderbookStore()
  const authStore = useAuthStore()
  const [connect] = useConnect()
  const { $t } = useIntl()
  const page = useCreation(() => observable.box(0), [])
  const pageSize = 10
  if (!authStore.isWalletConnected) {
    return (
      <RectButton Variant={GradientFilledButton} onClick={connect}>
        {$t(connectWallet$t)}
      </RectButton>
    )
  }
  return (
    <TradeHistoryTabContent
      records={suspenseResource(() =>
        store.myHistory.myTrades$.slice(
          page.get() * pageSize,
          (page.get() + 1) * pageSize,
        ),
      )}
      pagination={suspenseResource(() => ({
        recordCountTotal: store.myHistory.myTrades$.length,
        currentPage: page.get(),
        recordCountPerPage: pageSize,
      }))}
      onChangePage={action(newPage => page.set(newPage))}
    />
  )
}

const WiredFundsHistoryTabContent: FC = () => {
  const store = useOrderbookStore()
  const page = useCreation(() => observable.box(0), [])
  const pageSize = 10
  return (
    <FundsHistoryTabContent
      records={suspenseResource(() =>
        store.myHistory.myFunds$.slice(
          page.get() * pageSize,
          (page.get() + 1) * pageSize,
        ),
      )}
      pagination={suspenseResource(() => ({
        recordCountTotal: store.myHistory.myFunds$.length,
        currentPage: page.get(),
        recordCountPerPage: pageSize,
      }))}
      onChangePage={action(newPage => page.set(newPage))}
    />
  )
}

export const WiredOrderHistory: FC<{ className?: string }> = props => {
  const store = useOrderbookStore()
  const [connect] = useConnect()
  const { $t } = useIntl()

  if (!store.authStore.isWalletConnected) {
    return (
      <div className={props.className}>
        <OrderHistoryFrame
          openOrderCount={0}
          isHideOtherSymbols={true}
          onChangeHideOtherSymbols={store.myHistory.changeFilterByCurrentMarket}
        >
          {() => (
            <GradientFilledButton
              className="m-auto w-[340px]"
              onClick={connect}
            >
              {$t(connectWallet$t)}
            </GradientFilledButton>
          )}
        </OrderHistoryFrame>
      </div>
    )
  }

  return (
    <div className={props.className}>
      <OrderHistoryFrame
        openOrderCount={suspenseResource(
          () => store.myHistory.myPendingOrders$.length,
        )}
        isHideOtherSymbols={store.myHistory.filterByCurrentMarket}
        onChangeHideOtherSymbols={store.myHistory.changeFilterByCurrentMarket}
      >
        {({ value }) => {
          if (value === HistoryContentType.OpenOrders) {
            return <WiredOpenOrdersTabContent />
          } else if (value === HistoryContentType.OrdersHistory) {
            return <WiredMyOrdersTabContent />
          } else if (value === HistoryContentType.TradeHistory) {
            return <WiredMyTradesTabContent />
          } else if (value === HistoryContentType.FundsHistory) {
            return <WiredFundsHistoryTabContent />
          } else {
            assertNever(value)
          }
        }}
      </OrderHistoryFrame>
    </div>
  )
}
