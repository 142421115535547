import { computed, makeObservable } from "mobx"
import { CONTRACT_DEPLOYER } from "../../../../config"
import { asSender } from "../../../../generated/smartContractHelpers/asSender"
import AccountStore from "../../../../stores/accountStore/AccountStore"
import { AppEnvStore } from "../../../../stores/appEnvStore/AppEnvStore"
import AuthStore from "../../../../stores/authStore/AuthStore"
import { ChainStore } from "../../../../stores/chainStore/ChainStore"
import CurrencyStore from "../../../../stores/currencyStore/CurrencyStore"
import { LazyValue } from "../../../../stores/LazyValue/LazyValue"
import { Currency } from "../../../../utils/alexjs/Currency"
import { isDualYieldPool } from "../../../../utils/alexjs/currencyHelpers"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { suspenseResource } from "../../../../utils/SuspenseResource"
import type { RewardInfos } from "../../types"
import StakeChainModule from "../shared/StakeChainModule"
import {
  cycleStartBlockForToken,
  stakeCycleLength,
} from "../shared/StakeSharedModule.service"
import { getEarningPreview, StakingToken } from "./ManualStakeStore.service"
import AddStakeViewModule from "./_/AddStakeViewModule"
import { DualYieldModule } from "./_/DualYieldModule"
import MyStakingViewModule from "./_/MyStakingViewModule"

export const stakeStoreCache: { [P in string]: ManualStakeStore } = {}

class ManualStakeStore {
  constructor(
    readonly token: StakingToken,
    readonly appEnv: Pick<AppEnvStore, "config$">,
    readonly authStore: Pick<
      AuthStore,
      "stxAddress$" | "currentBlockHeight$" | "isWalletConnected"
    >,
    readonly currencyStore: Pick<CurrencyStore, "getPrice$" | "getTokenInfo$">,
    readonly accountStore: Pick<AccountStore, "getBalance$" | "transactions$">,
    readonly chainStore: Pick<
      ChainStore,
      "estimatedDateForBlock$" | "currentBlockHeight$" | "stakeChainModule"
    >,
  ) {
    this.addStake = new AddStakeViewModule(this, appEnv, this.stakeChain)
    makeObservable(this)
    stakeStoreCache[token] = this
  }

  @computed({ keepAlive: true }) get dualYield(): DualYieldModule | null {
    if (this.token !== Currency.ALEX && isDualYieldPool(this.token)) {
      return new DualYieldModule(this.token, this)
    }
    return null
  }

  @computed get tokenInfo$(): TokenInfo {
    return this.currencyStore.getTokenInfo$(this.token)
  }

  addStake: AddStakeViewModule
  myStaking = new MyStakingViewModule(this)
  get stakeChain(): StakeChainModule {
    return this.chainStore.stakeChainModule(this.token)
  }

  private _apowerMultiplier$ = new LazyValue(
    () => this.token,
    token =>
      asSender(CONTRACT_DEPLOYER)
        .contract("alex-reserve-pool")
        .func("get-apower-multiplier-in-fixed-or-default")
        .call({ token })
        .then(a => a / 1e8),
  )

  @computed get apowerMultiplier$(): number {
    return this._apowerMultiplier$.value$
  }

  @computed get currentCycle$(): number {
    return this.stakeChain.currentCycle$
  }

  @computed get nextCycle$(): number {
    return this.currentCycle$ + 1
  }

  blocksPerCycle = new LazyValue(() => null, stakeCycleLength)

  firstCycleBlock = new LazyValue(
    () => [this.token] as const,
    ([token]) => cycleStartBlockForToken(token),
  )

  @computed get nextCycleBlock$(): number {
    return this.stakeChain.nextCycleBlock$
  }

  @computed get nextCycleDate$(): Date {
    return this.stakeChain.nextCycleDate$
  }

  private _earningPreview = new LazyValue(
    () => [this.token] as const,
    ([token]) => getEarningPreview(token),
  )

  @computed get idealEarningPreview(): number[] {
    return this._earningPreview.value$.map(
      a => a * (this.dualYield?.aprMultiplier$ ?? 1),
    )
  }

  @computed get alexTokenInfo$(): TokenInfo {
    return this.currencyStore.getTokenInfo$(Currency.ALEX)
  }

  @computed get apowerTokenInfo$(): TokenInfo {
    return this.currencyStore.getTokenInfo$(Currency.APOWER)
  }

  @computed get rewardInfos$(): RewardInfos {
    const dualYield = this.dualYield
    const alexReward = {
      token: this.alexTokenInfo$,
      count: this.myStaking.pendingReward,
      countToUSD: suspenseResource(() => {
        return (
          this.myStaking.pendingReward *
          this.currencyStore.getPrice$(Currency.ALEX)
        )
      }),
    }
    const apowerReward = {
      token: this.apowerTokenInfo$,
      count: this.myStaking.pendingReward * this.apowerMultiplier$,
    }
    const dualYieldReward =
      dualYield != null
        ? {
            token: this.currencyStore.getTokenInfo$(dualYield.underlyingToken$),
            count: this.myStaking.pendingReward * dualYield.multiplier$,
            countToUSD: suspenseResource(() => {
              return (
                this.myStaking.pendingReward *
                dualYield.multiplier$ *
                this.currencyStore.getPrice$(dualYield.underlyingToken$)
              )
            }),
          }
        : null
    return dualYieldReward == null
      ? [alexReward, apowerReward]
      : [alexReward, dualYieldReward, apowerReward]
  }

  @computed get v1StakeEndCycle$(): undefined | number {
    if (this.appEnv.config$.stakingCycleLimitBlockHeight == null) return
    return this.stakeChain.getCycleNumberFromBlockHeight(
      this.appEnv.config$.stakingCycleLimitBlockHeight,
    )
  }
}

export default ManualStakeStore
