import { isStacksWalletInstalled } from "@stacks/connect"
import { isEqual } from "lodash"
import { computed, makeObservable } from "mobx"
import {
  distinctUntilChanged,
  from,
  interval,
  Observable,
  startWith,
  switchMap,
} from "rxjs"
import { ENV_NAME } from "../../config"
import { untilWindowLoad } from "../../utils/untilWindowLoad"
import { LazyValue } from "../LazyValue/LazyValue"
import type { AppConfigs } from "./appEnv.services"
import { getAppConfig } from "./appEnv.services"

export enum EnvironmentUnsupportedReason {
  WalletNotInstalled = "WalletNotInstalled",
}

export class AppEnvStore {
  constructor() {
    makeObservable(this)
  }

  environmentUnsupportedReason = new LazyValue(
    () => null,
    (): Observable<EnvironmentUnsupportedReason | undefined> =>
      interval(2000).pipe(
        startWith(0),
        switchMap(() => from(getEnvironmentUnsupportedReason())),
        distinctUntilChanged(isEqual),
      ),
  )

  private _config = new LazyValue(
    () => ENV_NAME,
    env => getAppConfig(env),
  )

  @computed get config$(): AppConfigs {
    return this._config.value$
  }
}

async function getEnvironmentUnsupportedReason(): Promise<
  EnvironmentUnsupportedReason | undefined
> {
  // The Hiro Wallet extension will inject code into page to make
  // `isStacksWalletInstalled` returns true, so we need to wait until all assets
  // ready, otherwise we might get the wrong conclusion
  await untilWindowLoad()
  if (!isStacksWalletInstalled()) {
    return EnvironmentUnsupportedReason.WalletNotInstalled
  }

  return
}
