import { computed, makeObservable } from "mobx"
import { Observable } from "rxjs"
import { CancelError, DisplayableError } from "../../utils/error"
import { isPromiseLike } from "../../utils/promiseHelpers"
import { LazyValue } from "../LazyValue/LazyValue"
import { SuspenseObservable } from "../SuspenseObservable"
import {
  getIsPreviouslyConnected,
  setIsPreviouslyConnected,
} from "./MetaMaskEthereumProvider/connectedProvider"
import {
  getMetaMaskEthereumProvider,
  MetaMaskEthereumProvider,
} from "./MetaMaskEthereumProvider/MetaMaskEthereumProvider"
import { isMayBeProviderRpcError } from "./MetaMaskEthereumProvider/MetaMaskEthereumProviderBasic"
import {
  connectedChain,
  connectedWalletAddress,
  currentBlockNumber,
  ETHChain,
  ETHChainInfo,
  getSignTypedDataFn,
  getUSDCBalance,
  getWBTCBalance,
  switchToChain,
  switchToChainSimply,
  transferERC20Token,
  TransferResponse,
} from "./MetaMaskModule.service"

export { ETHChain } from "./MetaMaskModule.service"

export class MetaMaskModule {
  constructor() {
    makeObservable(this)

    if (getIsPreviouslyConnected()) {
      void this.connectImpl()
    }
  }

  private metaMaskEthereumProvider =
    new SuspenseObservable<MetaMaskEthereumProvider>()

  connectedChain = new LazyValue(
    () => [this.metaMaskEthereumProvider.read$] as const,
    ([p]) => connectedChain(p),
  )

  connectedWalletAddress = new LazyValue(
    () => [this.metaMaskEthereumProvider.read$] as const,
    ([p]) =>
      new Observable<string>(ob => {
        const sub = connectedWalletAddress(p).subscribe({
          ...ob,
          next(v) {
            if (v == null) return
            ob.next(v)
          },
        })
        return () => {
          sub.unsubscribe()
        }
      }),
  )

  currentBlockNumber = new LazyValue(
    () => [this.metaMaskEthereumProvider.read$] as const,
    ([p]) => currentBlockNumber(p),
  )

  usdcBalance = new LazyValue(
    () =>
      [
        this.metaMaskEthereumProvider.read$,
        this.connectedChain.value$,
        this.connectedWalletAddress.value$,
      ] as const,
    ([p, chain, addr]) => getUSDCBalance(p, chain.chain, addr),
  )

  wbtcBalance = new LazyValue(
    () =>
      [
        this.metaMaskEthereumProvider.read$,
        this.connectedChain.value$,
        this.connectedWalletAddress.value$,
      ] as const,
    ([p, chain, addr]) => getWBTCBalance(p, chain.chain, addr),
  )

  @computed get isConnected(): boolean {
    return this.metaMaskEthereumProvider.get() != null
  }

  private async connectImpl(): Promise<void> {
    try {
      const provider = await getMetaMaskEthereumProvider()
      if (provider == null) return
      await provider.request({
        method: "eth_requestAccounts",
      })
      this.metaMaskEthereumProvider.set(provider)
    } catch (e) {
      if (isPromiseLike(e)) {
        return
      }

      if (isMayBeProviderRpcError(e) && e.code === 4001) {
        throw new CancelError()
      }

      throw e
    }
  }

  connect = async (): Promise<void> => {
    setIsPreviouslyConnected(true)
    await this.connectImpl()
  }

  disconnect = async (): Promise<void> => {
    this.metaMaskEthereumProvider.set(undefined)
    setIsPreviouslyConnected(false)
  }

  switchToChain = new LazyValue(
    () => [this.metaMaskEthereumProvider.read$] as const,
    async ([p]) => this.switchToChainFactory(p),
  )

  private switchToChainFactory(provider: MetaMaskEthereumProvider) {
    return async (chain: ETHChain) => {
      if (chain === ETHChain.Ethereum) {
        await switchToChainSimply(provider, ETHChainInfo.EthereumChainId)
      } else if (chain === ETHChain.Goerli) {
        await switchToChainSimply(provider, ETHChainInfo.GoerliChainId)
      } else if (chain === ETHChain.Polygon) {
        await switchToChain(provider, ETHChainInfo.PolygonChainInfo)
      } else if (chain === ETHChain.AVAX) {
        await switchToChain(provider, ETHChainInfo.AVAXChainInfo)
      } else {
        throw new DisplayableError(`Unsupported chain: ${chain}`)
      }
    }
  }

  transfer = new LazyValue(
    () =>
      [
        this.metaMaskEthereumProvider.read$,
        this.connectedChain.value$,
        this.connectedWalletAddress.value$,
      ] as const,
    async ([p, chain, addr]) => this.transferFactory(p, chain.chain, addr),
  )

  private transferFactory(
    provider: MetaMaskEthereumProvider,
    chain: ETHChain,
    fromWalletAddress: string,
  ): TransferFn {
    return async (tokenName, toWalletAddress, amount) => {
      try {
        return await transferERC20Token(
          provider,
          chain,
          tokenName,
          fromWalletAddress,
          toWalletAddress,
          amount,
        )
      } catch (e) {
        if (isMayBeProviderRpcError(e) && e.code === 4001) {
          throw new CancelError()
        }

        throw e
      }
    }
  }

  signTypedData = new LazyValue(
    () =>
      [
        this.metaMaskEthereumProvider.read$,
        this.connectedChain.value$,
      ] as const,
    async ([p, c]) => getSignTypedDataFn(p, c.chainId),
  )
}

export type TransferFn = (
  tokenName: "usdc" | "wbtc",
  toWalletAddress: string,
  amount: number,
) => Promise<TransferResponse>
