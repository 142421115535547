import { FC } from "react"
import { PlainIconButton } from "../../../../../components/button/variants/PlainIconButton"
import { CardDivider } from "../../../../../components/Card"
import { PercentNumber } from "../../../../../components/PercentNumber"
import { TokenCount } from "../../../../../components/TokenCount"
import { TokenIconProps } from "../../../../../components/TokenIcon"
import { TokenName } from "../../../../../components/TokenName"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { TokenInfoPresets } from "../../../../../utils/TokenInfoPresets/TokenInfoPresets"
import {
  ClaimTokenInfoVerticalList,
  StakingSummaryList,
  StakingSummaryListItem,
} from "../../../components/StakingSummaryList"
import { ReactComponent as OpenIcon } from "./open.svg"

export const MyStakingSummary: FC<{
  className?: string
  gapClassName?: string
  alexToken: TokenInfo
  autoAlexToken: TokenInfo
  autoAlexTokenCount: number
  autoStakingAPY: number
  manualStakingAlexTokenCount: number
  manualStakingAPR: number
  autoAlexApowerToBeDistributed?: number
  onShowApowerDistribution?: () => void
}> = props => (
  <StakingSummaryList
    className={props.className}
    gapClassName={props.gapClassName ?? "gap-6"}
  >
    <StakingSummaryListItem
      titleClassName={"flex flex-col"}
      title={"Auto Staking"}
      detail={
        <ClaimTokenInfoVerticalList
          tokenIconSize={TokenIconProps.defaultSize}
          countTextClassName={
            "text-[24px] sm:text-[30px] leading-[24px] sm:leading-[36px]"
          }
          infos={[
            { token: props.autoAlexToken, count: props.autoAlexTokenCount },
          ]}
        />
      }
    />

    <StakingSummaryListItem
      titleClassName={"flex flex-col"}
      title={"APY"}
      detail={
        <span
          className={
            "text-[24px] sm:text-[30px] leading-[24px] sm:leading-[36px]"
          }
        >
          <PercentNumber number={props.autoStakingAPY} />
        </span>
      }
    />

    {props.autoAlexApowerToBeDistributed != null && (
      <StakingSummaryListItem
        titleClassName="flex flex-row gap-x-2.5"
        title={
          <>
            <span>APower to be Distributed</span>
            <PlainIconButton
              icon={<OpenIcon />}
              onClick={props.onShowApowerDistribution}
            />
          </>
        }
        detail={
          <span>
            <TokenCount
              token={TokenInfoPresets.APower}
              count={props.autoAlexApowerToBeDistributed}
            />{" "}
            <TokenName token={TokenInfoPresets.APower} />
          </span>
        }
      />
    )}

    <CardDivider />

    <StakingSummaryListItem
      titleClassName={"flex flex-col"}
      title={"Manual Staking"}
      detail={
        <ClaimTokenInfoVerticalList
          tokenIconSize={TokenIconProps.defaultSize}
          countTextClassName={
            "text-[24px] sm:text-[30px] leading-[24px] sm:leading-[36px]"
          }
          infos={[
            {
              token: props.alexToken,
              count: props.manualStakingAlexTokenCount,
            },
          ]}
        />
      }
    />

    <StakingSummaryListItem
      title={"APR"}
      detail={
        <span
          className={
            "text-[24px] sm:text-[30px] leading-[24px] sm:leading-[36px]"
          }
        >
          <PercentNumber number={props.manualStakingAPR} />
        </span>
      }
    />
  </StakingSummaryList>
)
