import { sortBy } from "lodash"
import { action, computed, makeObservable, observable } from "mobx"
import { map } from "rxjs"
import { LazyValue } from "../../../../stores/LazyValue/LazyValue"
import { MemoValue } from "../../../../stores/MemoValue"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { safelyGet } from "../../../../utils/waitFor"
import { Order, OrderListOrderType } from "../../components/OrderbookList/types"
import { OrderbookStore } from "../OrderbookStore"
import { stxDxTokenPairs } from "../OrderbookStore.service/OrderbookStore.service"
import { fetchMyTradeOrders } from "./OrderbookOrderbookModule.service/fetchMyTradeOrders"
import { fetchOrderbook } from "./OrderbookOrderbookModule.service/fetchOrderbook"
import {
  fetchTradeOrders,
  transformTrade,
} from "./OrderbookOrderbookModule.service/fetchTradeOrders"

export class OrderbookOrderbookModule {
  constructor(readonly store: OrderbookStore) {
    makeObservable(this)
  }

  @observable showingOrderType: OrderListOrderType =
    OrderListOrderType.OrderbookOrders
  @action onChangeOrderType = (newType: OrderListOrderType): void => {
    this.showingOrderType = newType
  }

  @computed get priceTokenInfo$(): TokenInfo {
    return this.store.currency.getTokenInfo$(
      stxDxTokenPairs(this.store.market).priceToken,
    )
  }

  @computed get tradeTokenInfo$(): TokenInfo {
    return this.store.currency.getTokenInfo$(
      stxDxTokenPairs(this.store.market).tradeToken,
    )
  }

  @computed get lastPriceTokenCountPerTradeToken$(): number {
    return this.orderbook.immediateValue$.latestPrice
  }

  private prevMemoValue = new MemoValue(
    () => this.lastPriceTokenCountPerTradeToken$,
  )
  @computed get lastPriceTokenCountPerTradeTokenDelta$(): number {
    if (this.prevMemoValue.previousValue == null) return 0
    return this.prevMemoValue.previousValue - this.store.info.currentPrice$
  }

  @action onClickOrder = (order: Order): void => {
    this.store.trade.customPrice.set(order.priceTokenCountPerTradeToken)
  }

  private orderbook = new LazyValue(
    () =>
      [this.store.market, safelyGet(() => this.store.myInfo.authJWT$)] as const,
    ([market, jwt]) => fetchOrderbook(market, jwt),
  )
  orderbookSellOrders = new LazyValue(
    () => [this.orderbook.immediateValue$] as const,
    async ([value]) => value.sell,
  )
  orderbookBuyOrders = new LazyValue(
    () => [this.orderbook.immediateValue$] as const,
    async ([value]) => value.buy,
  )

  @computed get bestAskPrice$(): number | undefined {
    return sortBy(
      this.orderbookSellOrders.immediateValue$,
      o => o.priceTokenCountPerTradeToken,
    )[0]?.priceTokenCountPerTradeToken
  }
  @computed get bestBidPrice$(): number | undefined {
    return sortBy(
      this.orderbookBuyOrders.immediateValue$,
      o => -o.priceTokenCountPerTradeToken,
    )[0]?.priceTokenCountPerTradeToken
  }

  tradeOrders = new LazyValue(
    () => [this.store.market, this.store.myInfo.userId.value] as const,
    ([market, currentUserId]) =>
      fetchTradeOrders(market).pipe(
        map(os => os.map(transformTrade(currentUserId))),
      ),
  )

  myTradeOrders = new LazyValue(
    () => [this.store.myInfo.authJWT$, this.store.market] as const,
    ([auth, market]) => fetchMyTradeOrders(auth, market),
  )
}
