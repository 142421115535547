import { GradientFilledButton } from "../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { useConnect } from "../../../components/ConnectWallet/ConnectProvider"
import { FCC } from "../../../utils/reactHelpers/types"
import { withClassName } from "../../../utils/reactHelpers/withClassName"

export const WiredConnectWalletButton: FCC<{ className?: string }> = props => {
  const [connect] = useConnect()
  return (
    <MainButton onClick={connect} className={props.className}>
      {props.children ?? "Connect Wallet"}
    </MainButton>
  )
}

const MainButton = withClassName("block mt-3.5 mb-2", GradientFilledButton)
