import { FC } from "react"
import {
  CardDivider,
  CardInsetDescription,
} from "../../../../../../components/Card"
import {
  createInfoListItemDetail,
  createInfoListItemTitle,
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../../../components/InfoList"
import { TokenCount } from "../../../../../../components/TokenCount"
import { TokenName } from "../../../../../../components/TokenName"
import { TokenInfo } from "../../../../../../utils/models/TokenInfo"
import { Burn } from "../../../_/Burn"
import { PrizeWinningInfo } from "../types"

export const RoundResult: FC<{
  roundNumber: number
  token: TokenInfo
  firstPrize: PrizeWinningInfo
  secondPrize: PrizeWinningInfo
  thirdPrize: PrizeWinningInfo
  burnPrizeTokenCount: number
  rolloverPrizeTokenCount: number
}> = props => {
  return (
    <>
      <CardInsetDescription className={"text-center"}>
        Round #{props.roundNumber} results:
      </CardInsetDescription>

      <InfoList
        className={"flex-wrap"}
        direction={"row"}
        listItemDirection={"column"}
        listItemClassName={"flex-1 text-center"}
        InfoListItemTitle={createInfoListItemTitle({
          textClassName: "text-base text-gray-200",
        })}
        InfoListItemDetail={createInfoListItemDetail({
          textClassName: "text-xs text-gray-200/50",
        })}
      >
        <InfoListItem>
          <InfoListItemTitle>First Prize</InfoListItemTitle>
          <InfoListItemDetail>
            <TokenCount
              token={props.token}
              count={props.firstPrize.prizeTokenCount}
            />{" "}
            <TokenName token={props.token} /> each <br />
            {props.firstPrize.winningTicketCount} Winning Tickets
          </InfoListItemDetail>
        </InfoListItem>

        <InfoListItem>
          <InfoListItemTitle>Second Prize</InfoListItemTitle>
          <InfoListItemDetail>
            <TokenCount
              token={props.token}
              count={props.secondPrize.prizeTokenCount}
            />{" "}
            <TokenName token={props.token} /> each <br />
            {props.secondPrize.winningTicketCount} Winning Tickets
          </InfoListItemDetail>
        </InfoListItem>

        <InfoListItem>
          <InfoListItemTitle>Third Prize</InfoListItemTitle>
          <InfoListItemDetail>
            <TokenCount
              token={props.token}
              count={props.thirdPrize.prizeTokenCount}
            />{" "}
            <TokenName token={props.token} /> each <br />
            {props.thirdPrize.winningTicketCount} Winning Tickets
          </InfoListItemDetail>
        </InfoListItem>

        <InfoListItem>
          <InfoListItemTitle>
            <Burn
              className={"inline-block align-text-bottom"}
              width={"1em"}
              height={"1em"}
            />{" "}
            Burn
          </InfoListItemTitle>
          <InfoListItemDetail>
            <TokenCount token={props.token} count={props.burnPrizeTokenCount} />{" "}
            <TokenName token={props.token} />
          </InfoListItemDetail>
        </InfoListItem>
      </InfoList>

      {props.rolloverPrizeTokenCount > 0 && (
        <>
          <CardDivider className={"w-full"} />

          <CardInsetDescription className={"text-center"}>
            <span className={"font-bold"}>
              <TokenCount
                token={props.token}
                count={props.rolloverPrizeTokenCount}
              />{" "}
              <TokenName token={props.token} />
            </span>{" "}
            rollover to next round.
          </CardInsetDescription>
        </>
      )}
    </>
  )
}
