import { FC } from "react"
import { PageStackPage } from "../../components/PageStack/PageStackPage"
import { PrimaryPageTitle } from "../../components/PageStack/PrimaryPageTitle"
import { suspenseResource } from "../../utils/SuspenseResource"
import { AllPoolsSection } from "./components/AllPoolsSection"
import { MyPoolsSection } from "./components/MyPoolsSection"
import {
  PoolListStoreProvider,
  usePoolListStore,
} from "./store/list/usePoolListStore"

const WiredAllPools: FC = () => {
  const store = usePoolListStore()
  return (
    <AllPoolsSection
      pools={suspenseResource(() =>
        store.allPoolStores$.map(p => ({
          ...p.info$,
          poolTokenInfo: p.poolTokenInfo,
        })),
      )}
    />
  )
}

const WiredMyPools: FC = () => {
  const store = usePoolListStore()
  return (
    <MyPoolsSection
      pools={suspenseResource(() =>
        store.myPools$.map(p => ({
          ...p.info$,
          poolTokenInfo: p.poolTokenInfo,
          myBalance: p.pooledAmount$,
        })),
      )}
    />
  )
}

const PageContent: FC = () => {
  return (
    <div className={"flex flex-col gap-5"}>
      <WiredMyPools />
      <WiredAllPools />
    </div>
  )
}

const PoolListPage: FC = () => {
  return (
    <PoolListStoreProvider>
      <PageStackPage
        title={
          <PrimaryPageTitle subtitle={"Provide liquidity to earn trading fees"}>
            Pool
          </PrimaryPageTitle>
        }
      >
        <PageContent />
      </PageStackPage>
    </PoolListStoreProvider>
  )
}

export default PoolListPage
