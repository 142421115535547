import { unwrapResponse } from "clarity-codegen"
import { range } from "lodash"
import { action, computed, makeObservable, observable } from "mobx"
import { createTransformer } from "mobx-utils"
import { CONTRACT_DEPLOYER } from "../../../../config"
import {
  asSender,
  contractAddr,
} from "../../../../generated/smartContractHelpers/asSender"
import AccountStore from "../../../../stores/accountStore/AccountStore"
import AuthStore from "../../../../stores/authStore/AuthStore"
import { ChainStore } from "../../../../stores/chainStore/ChainStore"
import { ConfirmTransactionStore } from "../../../../stores/confirmTransactionDialogStore/ConfirmTransactionStore"
import CurrencyStore from "../../../../stores/currencyStore/CurrencyStore"
import { LazyValue } from "../../../../stores/LazyValue/LazyValue"
import { SuspenseObservable } from "../../../../stores/SuspenseObservable"
import { Currency } from "../../../../utils/alexjs/Currency"
import { transfer } from "../../../../utils/alexjs/postConditions"
import { asyncAction, runAsyncAction } from "../../../../utils/asyncAction"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { isPromiseLike } from "../../../../utils/promiseHelpers"
import { Result } from "../../../../utils/Result"
import { suspenseResource } from "../../../../utils/SuspenseResource"
import {
  FormError,
  FormErrorType,
} from "../../manualStakeComponents/AddStakeSection/types"
import { AutoAlexCyclePrice } from "../../types"
import StakeChainModule from "../shared/StakeChainModule"
import { AutoStakeCycleViewModule } from "./AutoStakeCycleViewModule"
import {
  ApowerDistributionCycle,
  ApowerDistributionStatus,
  fetchAlexPerAtAlexInSwap,
  fetchApowerDistribution,
  getAtAlexValues,
} from "./AutoStakeStore.service"

type AddStakeFormData = { alexAmount: number; swapDiscount?: number }

class AutoStakeStore {
  constructor(
    readonly chainStore: ChainStore,
    readonly authStore: AuthStore,
    readonly accountStore: AccountStore,
    readonly currencyStore: CurrencyStore,
  ) {
    makeObservable(this)
  }

  token = Currency.ALEX as const

  @computed get atAlexTokenInfo(): TokenInfo {
    return this.currencyStore.getTokenInfo$(Currency.ATALEX)
  }

  get shared(): StakeChainModule {
    return this.chainStore.stakeChainModule(this.token)
  }

  @computed get currentAPY$(): number {
    return this.statsInfo.value$.apys[0]!
  }

  intrinsic = new LazyValue(
    () => this.chainStore.currentBlockHeight$,
    () =>
      asSender(CONTRACT_DEPLOYER)
        .contract("auto-alex")
        .func("get-intrinsic")
        .call([])
        .then(a => Math.max(1, unwrapResponse(a) / 1e8)),
  )

  statsInfo = new LazyValue(
    () => null,
    () => getAtAlexValues(),
  )

  @computed get stakingAPY$(): number {
    return this.atAlexBalance$ > 0 ? this.statsInfo.value$.apys[0]! : 0
  }

  @computed get alexBalance$(): number {
    return this.accountStore.getBalance$(Currency.ALEX)
  }

  @computed get atAlexBalance$(): number {
    return this.accountStore.getBalance$(Currency.ATALEX)
  }

  @computed get priceInAlex$(): number {
    return this.intrinsic.value$
  }

  @observable amountToStake = new SuspenseObservable<number>()

  @computed get amountToStakeInUSD$(): number {
    return (
      this.amountToStake.read$ * this.currencyStore.getPrice$(Currency.ALEX)
    )
  }

  getAmountToStakeInAtAlex$ = createTransformer((alexCount: number): number => {
    return alexCount / this.intrinsic.value$
  })

  @computed get amountToStakeInAtAlex$(): number {
    return this.getAmountToStakeInAtAlex$(this.amountToStake.read$)
  }

  @observable confirmingStakeData: undefined | AddStakeFormData
  @observable confirmingDiscount: undefined | AddStakeFormData

  @action proceedIgnoreDiscount(): void {
    this.confirmingStakeData = this.confirmingDiscount
    this.confirmingDiscount = undefined
  }

  #swapPrice = new LazyValue(() => null, fetchAlexPerAtAlexInSwap)
  @computed get swapDiscountPercentage$(): number {
    return (
      (this.intrinsic.value$ - this.#swapPrice.value$) / this.intrinsic.value$
    )
  }

  @computed get addStakeData(): Result<AddStakeFormData, FormError> {
    if (!this.authStore.isWalletConnected) {
      return Result.error({
        type: FormErrorType.WalletNotConnected,
        message: "Connect Wallet",
      })
    }

    // noinspection DuplicatedCode
    try {
      if (this.amountToStake.read$ === 0) {
        // noinspection ExceptionCaughtLocallyJS
        throw Promise.reject()
      }
    } catch (e) {
      if (isPromiseLike(e)) {
        return Result.error({
          type: FormErrorType.AmountIsEmpty,
          message: "Enter an amount",
        })
      }
      throw e
    }

    if (Number(this.amountToStake.read$) === 0) {
      return Result.error({
        type: FormErrorType.LessThanMinimizeAmount,
        message: "Insufficient amount",
      })
    }

    if (this.amountToStake.read$ > this.alexBalance$) {
      return Result.error<FormError>({
        type: FormErrorType.InsufficientTokenBalance,
        message: "Insufficient balance",
      })
    }

    return Result.ok({
      alexAmount: this.amountToStake.read$,
      swapDiscount: this.swapDiscountPercentage$,
    })
  }

  addStakeTx = new ConfirmTransactionStore()

  @asyncAction async addStake(
    data: AddStakeFormData,
    run = runAsyncAction,
  ): Promise<void> {
    this.confirmingStakeData = undefined
    try {
      const { txId } = await run(
        asSender(this.authStore.stxAddress$)
          .contract("auto-alex")
          .func("add-to-position")
          .call(
            {
              dx: data.alexAmount * 1e8,
            },
            [
              transfer(
                this.authStore.stxAddress$,
                Currency.ALEX,
                data.alexAmount,
              ),
              transfer(
                contractAddr("auto-alex"),
                Currency.ALEX,
                data.alexAmount,
              ),
            ],
          ),
      )
      this.confirmingStakeData = undefined
      this.addStakeTx.successRunning(txId)
    } catch (e) {
      this.addStakeTx.errorRunning(e as Error)
    }
  }

  @computed get currentCycle$(): number {
    return this.shared.currentCycle$
  }

  @computed get upcomingCycles(): AutoAlexCyclePrice[] {
    return range(this.currentCycle$, this.currentCycle$ + 4).map(circle => ({
      cycleNumber: circle,
      intrinsicValue: suspenseResource(() => {
        return this.cycleViewModule(circle).intrinsicAlexValue
      }),
    }))
  }

  cycleViewModule = createTransformer(
    (cycle: number) => new AutoStakeCycleViewModule(this, cycle),
    { keepAlive: true },
  )

  @observable showAllCycles = false
  @observable tab: "live" | "finished" = "live"
  @computed get allCycles(): AutoStakeCycleViewModule[] {
    const currentCycle = this.currentCycle$
    if (this.tab === "live") {
      return range(currentCycle, currentCycle + 33).map(this.cycleViewModule)
    }
    return range(0, currentCycle).reverse().map(this.cycleViewModule)
  }

  @observable showApowerDistribution = false
  private apowerDistribution = new LazyValue(
    () => this.authStore.stxAddress$,
    fetchApowerDistribution,
  )
  @computed get apowerDistribution$(): ApowerDistributionCycle[] {
    return this.apowerDistribution.value$
  }
  @computed get apowerToBeDistributed$(): number {
    return this.apowerDistribution$.reduce(
      (acc, curr) =>
        acc +
        (curr.status === ApowerDistributionStatus.Waiting
          ? curr.apowerRewards ?? 0
          : 0),
      0,
    )
  }
}

export default AutoStakeStore
