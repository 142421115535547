import clsx from "clsx"
import { FC, ReactNode } from "react"
import { APowerTip } from "../../../../components/APowerTip/APowerTip"
import {
  BasicContentContainer,
  CollapsableCard,
} from "../../../../components/CollapsableCard/CollapsableCard"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { ManualStakingCyclesSimpleTable } from "../../components/ManualStakingCyclesSimpleTable/ManualStakingCyclesSimpleTable"
import { ClaimTokenInfo, StakeCycle } from "../../types"
import { MyStakingSummary } from "./_/MyStakingSummary/MyStakingSummary"

export interface MyStakingSectionProps {
  className?: string
  title: ReactNode
  copywriting: {
    activeStaking: ReactNode
    averageAPRTip: ReactNode
    principalClaim: ReactNode
    cycleTip: ReactNode
    toBeStaked: {
      finishedStatus: string
      currentState: string
      upcomingStatus: string
    }
  }
  defaultCollapsed?: boolean
  principalToken: TokenInfo
  currentBlock: number
  stakingCount: number
  averageAPR: number
  rewardPairs: ClaimTokenInfo[]
  principalToClaimCount: number
  currentCycleEndedAt: Date
  currentCycleStartedAtBlock: number
  currentCycleEndedAtBlock: number
  stakingCycles: StakeCycle[]
  onHarvestAll?: () => void
  onShowAllCycles?: () => void
}

export const MyStakingSection: FC<MyStakingSectionProps> = props => {
  return (
    <CollapsableCard
      className={props.className}
      title={props.title}
      defaultCollapsed={props.defaultCollapsed}
      ContentContainer={props => (
        <BasicContentContainer
          {...props}
          gapClassName={"gap-6"}
          className={clsx(
            "flex flex-wrap",
            props.gapClassName,
            props.className,
          )}
        />
      )}
    >
      <div className={"flex-1 min-w-full lg:min-w-0"}>
        <MyStakingSummary
          activeFarming={{
            title: props.copywriting.activeStaking,
            token: props.principalToken,
            count: props.stakingCount,
          }}
          averageAPR={{
            title: "Average APR",
            apr: props.averageAPR,
            tip: props.copywriting.averageAPRTip,
          }}
          rewardClaim={{
            title: "Rewards to claim",
            tipArea: <APowerTip />,
            tokens: props.rewardPairs,
          }}
          principalClaim={{
            title: props.copywriting.principalClaim,
            tokens: [
              {
                token: props.principalToken,
                count: props.principalToClaimCount,
              },
            ],
          }}
          onHarvestAll={props.onHarvestAll}
        />
      </div>

      <ManualStakingCyclesSimpleTable
        className={"flex-1 min-w-full lg:min-w-0"}
        copywriting={props.copywriting}
        currentCycleEndedAtTime={props.currentCycleEndedAt}
        currentCycleStartedAtBlock={props.currentCycleStartedAtBlock}
        currentCycleEndedAtBlock={props.currentCycleEndedAtBlock}
        currentBlock={props.currentBlock}
        onExpand={props.onShowAllCycles}
        stakeCycles={props.stakingCycles}
      />
    </CollapsableCard>
  )
}
