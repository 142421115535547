import { createContext, FC, ReactNode, useContext } from "react"
import { useCreation } from "../../utils/reactHelpers/useCreation"
import { useChainStore } from "../chainStore/useChainStore"
import AuthStore from "./AuthStore"

const AuthStoreContext = createContext<AuthStore | null>(null)

export const AuthStoreProvider: FC<{
  children: ReactNode
}> = props => {
  const chainStore = useChainStore()
  const store = useCreation(() => new AuthStore(chainStore), [chainStore])
  return (
    <AuthStoreContext.Provider value={store}>
      {props.children}
    </AuthStoreContext.Provider>
  )
}

export const useAuthStore = (): AuthStore => {
  const store = useContext(AuthStoreContext)
  if (store == null) {
    throw new Error("useAuthStore must be used within a AuthStoreProvider")
  }
  return store
}
