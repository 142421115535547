import { FC } from "react"
import { CoFarmClaimRowData } from "../../../../store/transformerGroups/farm/coFarmClaim"
import { AllTokenInfos } from "../../../../types"
import { BaseCellContainer } from "../../cells/BaseCellContainer"
import { OneLineAmountCell } from "../../cells/OneLineAmountCell"
import { OneLineCurrencyCell } from "../../cells/OneLineCurrencyCell"
import { BaseRow } from "../BaseRow"

export const CoFarmClaimRow: FC<{
  rowData: CoFarmClaimRowData
  allTokenInfos: AllTokenInfos
}> = ({ rowData, allTokenInfos }) => (
  <BaseRow
    rowData={rowData}
    currencyCell={
      <OneLineCurrencyCell
        allTokenInfos={allTokenInfos}
        toCurrencyAmounts={[rowData.claimedPrincipalCurrencyAmount]}
      />
    }
    amountCell={
      <OneLineAmountCell
        allTokenInfos={allTokenInfos}
        toCurrencyAmounts={[rowData.claimedPrincipalCurrencyAmount]}
      />
    }
    tradeInfoCell={
      <BaseCellContainer>
        <p className="whitespace-nowrap">Co-Farm Claim</p>
      </BaseCellContainer>
    }
  />
)
