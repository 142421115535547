import {
  isStacksWalletInstalled,
  useConnect as useStackConnect,
} from "@stacks/connect-react"
import {
  createContext,
  FC,
  ReactChild,
  useCallback,
  useContext,
  useState,
} from "react"
import { isMobileDevice } from "../../utils/domHelpers/browserEnv"
import { ConnectXverseWalletDialog } from "./ConnectXverseWalletDialog"

const ConnectContext = createContext<{
  connect: () => void
} | null>(null)

export const ConnectProvider: FC<{ children: ReactChild }> = ({ children }) => {
  const [visible, setVisible] = useState(false)
  const { doOpenAuth } = useStackConnect()

  const connect = useCallback(() => {
    if (isMobileDevice() && !isStacksWalletInstalled()) {
      return setVisible(true)
    }
    doOpenAuth()
  }, [doOpenAuth])

  return (
    <>
      <ConnectContext.Provider value={{ connect }}>
        {children}
      </ConnectContext.Provider>
      <ConnectXverseWalletDialog
        visible={visible}
        onClose={() => setVisible(false)}
      />
    </>
  )
}

export const useConnect = (): [() => void] => {
  const context = useContext(ConnectContext)
  if (!context) {
    throw new Error("[useConnect] must be used inside a ConnectProvider")
  }
  return [context.connect]
}
