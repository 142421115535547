import { startOfDay } from "date-fns"
import { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { Modal } from "../../../../components/Modal"
import { useContentfulLongText } from "../../../../generated/i18nHelpers/useContentfulLongText"
import { isInvalidDate } from "../../../../utils/dateHelpers"
import { useOrderbookStore } from "../../store/useOrderbookStore"
import { TestNetworkSetupGuideModalContent } from "./TestNetworkSetupGuideModalContent"

export interface WiredDailyTestNetworkSetupGuideModalRefValue {
  show: () => void
}

export const WiredDailyTestNetworkSetupGuideModal = forwardRef<
  WiredDailyTestNetworkSetupGuideModalRefValue,
  // eslint-disable-next-line @typescript-eslint/ban-types
  {}
>((props, ref) => {
  const store = useOrderbookStore()

  const markdownContent = useContentfulLongText(
    () => store.longText.testnetSetupGuide.value$,
  )

  const [dialogVisible, setDialogVisible] = useState(false)

  useEffect(() => {
    const storageKey = "WiredDailySwitchNetworkGuideModal-lastShowTime"
    const lastShowTime = new Date(
      Number(localStorage.getItem(storageKey) ?? ""),
    )
    const isShowedInThisDay = isInvalidDate(lastShowTime)
      ? false
      : startOfDay(lastShowTime).getTime() === startOfDay(new Date()).getTime()
    if (!isShowedInThisDay) {
      setDialogVisible(true)
      localStorage.setItem(storageKey, String(new Date().getTime()))
    }
  }, [])

  useImperativeHandle(ref, () => ({
    show() {
      setDialogVisible(true)
    },
  }))

  return (
    <Modal visible={dialogVisible} onClose={() => setDialogVisible(false)}>
      <TestNetworkSetupGuideModalContent
        markdownContent={markdownContent}
        onClose={() => setDialogVisible(false)}
      />
    </Modal>
  )
})
