import { FC } from "react"
import { GradientFilledButton } from "../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteFilledButton } from "../../../components/button/variants/WhiteFilledButton"
import { CardModalContent } from "../../../components/CardModal/CardModal"
import { BlockTopTitleLine } from "../../../components/TokenInput/Block"
import {
  TokenBlock,
  TokenCountPair,
} from "../../../components/TokenInput/TokenBlock"

export interface RemoveLiquidityConfirmationProps {
  poolToken: TokenCountPair
  onConfirm?: () => void
  onClose?: () => void
}

const RemoveLiquidityConfirmation: FC<
  RemoveLiquidityConfirmationProps
> = props => {
  return (
    <CardModalContent
      onClose={props.onClose}
      title={"Confirm Remove Liquidity"}
      className={"flex flex-col gap-4"}
      width={480}
    >
      <TokenBlock
        topArea={<BlockTopTitleLine>LP Token</BlockTopTitleLine>}
        tokens={[props.poolToken]}
      />
      <div className={"mt-3.5 flex flex-row gap-2.5"}>
        <WhiteFilledButton className={"flex-1"} onClick={props.onClose}>
          Cancel
        </WhiteFilledButton>

        <GradientFilledButton className={"flex-1"} onClick={props.onConfirm}>
          Confirm
        </GradientFilledButton>
      </div>
    </CardModalContent>
  )
}

export default RemoveLiquidityConfirmation
