import clsx from "clsx"
import { FC } from "react"
import { WhiteGradientAlert } from "../../../../components/alert/WhiteGradientAlert"
import {
  btnPresets,
  Button,
  ButtonProps,
} from "../../../../components/button/Button"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteFilledButton } from "../../../../components/button/variants/WhiteFilledButton"
import {
  CollapsableCard,
  IdentityContentContainer,
} from "../../../../components/CollapsableCard/CollapsableCard"
import { EmptyState } from "../../../../components/EmptyState/EmptyState"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../components/InfoList"
import { IconTokenCount } from "../../../../components/RichTokenCount"
import { TimeCountdown } from "../../../../components/TimeCountdown/TimeCountdown"
import { TipIcon } from "../../../../components/TipIcon/TipIcon"
import { Tooltip } from "../../../../components/Tooltip/Tooltip"
import { FCC } from "../../../../utils/reactHelpers/types"
import { CollapsableCardScrollableRow } from "../CollapsableCardScrollableRow"
import { DepositRecord } from "../types"

export const MyDepositsPanel: FC<{
  className?: string
  defaultCollapsed?: boolean
  depositRecords: DepositRecord[]
}> = props => {
  return (
    <CollapsableCard
      className={props.className}
      title={"Deposited"}
      defaultCollapsed={props.defaultCollapsed}
      ContentContainer={IdentityContentContainer}
    >
      {props.depositRecords.map((r, idx) => (
        <CollapsableCardScrollableRow key={idx}>
          <WhiteGradientAlert withInteractionEffect={true}>
            {r.status === "active" ? (
              <ActiveDepositRecord record={r} />
            ) : (
              <ExpiredDepositRecord record={r} />
            )}
          </WhiteGradientAlert>
        </CollapsableCardScrollableRow>
      ))}
      {!props.depositRecords.length && (
        <EmptyState className={"m-auto"}>No Deposit Data</EmptyState>
      )}
    </CollapsableCard>
  )
}

const ActiveDepositRecord: FC<{ record: DepositRecord.Active }> = props => (
  <InfoList
    className={"flex-1"}
    direction={"row"}
    listItemDirection={"column"}
    listItemClassName={"flex-1 flex flex-col gap-2"}
  >
    <InfoListItem>
      <InfoListItemTitle>Active deposits</InfoListItemTitle>
      <InfoListItemDetail className={"my-auto"}>
        <IconTokenCount
          className={"text-2xl"}
          iconSize={32}
          tokenNameClassName={"text-base"}
          token={props.record.yieldToken}
          count={props.record.yieldTokenCount}
        />
      </InfoListItemDetail>
    </InfoListItem>

    <InfoListItem>
      <InfoListItemTitle>Maturity Amount</InfoListItemTitle>
      <InfoListItemDetail>
        <IconTokenCount
          token={props.record.intrinsicToken}
          count={props.record.intrinsicTokenCount}
        />
      </InfoListItemDetail>
    </InfoListItem>

    <SimpleSection flex={0.5}>
      <Tooltip title={`Maturity Block：${props.record.expiredAtBlock}`}>
        <TimeCountdown time={props.record.expiredAt} />
      </Tooltip>
    </SimpleSection>

    <SimpleSection className={"gap-5"}>
      <StyledButton
        {...btnPresets.small}
        Variant={WhiteFilledButton}
        onClick={props.record.onSell}
      >
        Sell
      </StyledButton>
    </SimpleSection>
  </InfoList>
)

const ExpiredDepositRecord: FC<{ record: DepositRecord.Expired }> = props => (
  <InfoList
    className={"flex-1"}
    direction={"row"}
    listItemDirection={"column"}
    listItemClassName={"flex-1 flex flex-col gap-2"}
  >
    <InfoListItem>
      <InfoListItemTitle>Expired deposits</InfoListItemTitle>
      <InfoListItemDetail className={"my-auto"}>
        <IconTokenCount
          className={"text-2xl"}
          iconSize={32}
          tokenNameClassName={"text-base"}
          token={props.record.yieldToken}
          count={props.record.yieldTokenCount}
        />
      </InfoListItemDetail>
    </InfoListItem>

    <InfoListItem>
      <InfoListItemTitle>Claimable Amount</InfoListItemTitle>
      <InfoListItemDetail>
        <IconTokenCount
          token={props.record.intrinsicToken}
          count={props.record.intrinsicTokenCount}
        />
      </InfoListItemDetail>
    </InfoListItem>

    <SimpleSection flex={0.5} />

    <SimpleSection className={"gap-5"}>
      <StyledButton
        {...btnPresets.small}
        Variant={WhiteFilledButton}
        onClick={props.record.onClaim}
      >
        Claim
      </StyledButton>
      <StyledButton
        {...btnPresets.small}
        Variant={GradientFilledButton}
        onClick={props.record.onRollover}
        disabled={true}
      >
        Rollover{" "}
        <TipIcon
          inline
          tip={
            "Rollover extends your deposit period through the next reward cycle."
          }
        />
      </StyledButton>
    </SimpleSection>
  </InfoList>
)

const StyledButton: FC<ButtonProps> = props => (
  <Button
    {...props}
    className={clsx(btnPresets.small.className, "min-w-[100px]")}
  />
)

const SimpleSection: FCC<{ className?: string; flex?: number }> = props => (
  <div
    className={clsx(
      "flex-1 flex justify-end h-[fit-content] self-center",
      props.className,
    )}
    style={{
      flex: props.flex ?? 1,
    }}
  >
    {props.children}
  </div>
)
