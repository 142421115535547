import { startCase } from "lodash"
import { ComponentType, FC, ReactNode } from "react"
import { WhiteFilledButton } from "../../../../components/button/variants/WhiteFilledButton"
import { Card } from "../../../../components/Card"
import {
  Table,
  TableHeadRow,
  Tbody,
  Thead,
} from "../../../../components/table/StyledTable"
import { FCC } from "../../../../utils/reactHelpers/types"
import { isNotNull } from "../../../../utils/utils"
import { EXPORTER_COLUMNS, TRANSACTION_COLUMNS } from "../../constants"
import { coFarmExporter } from "../../exporters/farm/coFarm"
import { coFarmAPowerDistributionExporter } from "../../exporters/farm/coFarmAPowerDistribution"
import { coFarmAutoHarvestExporter } from "../../exporters/farm/coFarmAutoHarvest"
import { coFarmClaimExporter } from "../../exporters/farm/coFarmClaim"
import { farmExporter } from "../../exporters/farm/farm"
import { farmHarvestExporter } from "../../exporters/farm/farmHarvest"
import { borrowExporter } from "../../exporters/lend/borrow"
import { borrowClaimExporter } from "../../exporters/lend/borrowClaim"
import { borrowRolloverExporter } from "../../exporters/lend/borrowRollover"
import { depositExporter } from "../../exporters/lend/deposit"
import { depositClaimExporter } from "../../exporters/lend/depositClaim"
import { depositRolloverExporter } from "../../exporters/lend/depositRollover"
import { depositSellExporter } from "../../exporters/lend/depositSell"
import { buyLotteryExporter } from "../../exporters/lottery/buyLottery"
import { lotteryWonExporter } from "../../exporters/lottery/lotteryWon"
import { addLiquidityExporter } from "../../exporters/pool/addLiquidity"
import { removeLiquidityExporter } from "../../exporters/pool/removeLiquidity"
import { autoStakingExporter } from "../../exporters/stake/autoStaking"
import { autoStakingAPowerDistributionExporter } from "../../exporters/stake/autoStakingAPowerDistribution"
import { manualStakingExporter } from "../../exporters/stake/manualStaking"
import { stakingHarvestExporter } from "../../exporters/stake/stakingHarvest"
import { stakingHarvestAndMintAtAlexExporter } from "../../exporters/stake/stakingHarvestAndMintAtAlex"
import { swapExporter } from "../../exporters/swap/swap"
import {
  AllTokenInfos,
  BaseRowData,
  Exporter,
  NotifyFilter,
  NotifyTransactionType,
} from "../../types"
import { CanScrollMask } from "../CanScrollMask/CanScrollMask"
import { ReactComponent as EmptyIcon } from "./empty.svg"
import { ReactComponent as ExportIcon } from "./export.svg"
import { CoFarmAPowerDistributionRow } from "./rows/farm/CoFarmAPowerDistributionRow"
import { CoFarmAutoHarvestRow } from "./rows/farm/CoFarmAutoHarvestRow"
import { CoFarmClaimRow } from "./rows/farm/CoFarmClaimRow"
import { CoFarmRow } from "./rows/farm/CoFarmRow"
import { FarmHarvestRow } from "./rows/farm/FarmHarvestRow"
import { FarmRow } from "./rows/farm/FarmRow"
import { BorrowClaimRow } from "./rows/lend/BorrowClaimRow"
import { BorrowRolloverRow } from "./rows/lend/BorrowRolloverRow"
import { BorrowRow } from "./rows/lend/BorrowRow"
import { DepositClaimRow } from "./rows/lend/DepositClaimRow"
import { DepositRolloverRow } from "./rows/lend/DepositRolloverRow"
import { DepositRow } from "./rows/lend/DepositRow"
import { DepositSellRow } from "./rows/lend/DepositSellRow"
import { BuyLotteryRow } from "./rows/lottery/BuyLotteryRow"
import { LotteryWonRow } from "./rows/lottery/LotteryWonRow"
import { AddLiquidityRow } from "./rows/pool/AddLiquidityRow"
import { RemoveLiquidityRow } from "./rows/pool/RemoveLiquidityRow"
import { AutoStakingAPowerDistributionRow } from "./rows/stake/AutoStakingAPowerDistributionRow"
import { AutoStakingRow } from "./rows/stake/AutoStakingRow"
import { ManualStakingRow } from "./rows/stake/ManualStakingRow"
import { StakingHarvestAndMintAtAlexRow } from "./rows/stake/StakingHarvestAndMintAtAlexRow"
import { StakingHarvestRow } from "./rows/stake/StakingHarvestRow"
import { SwapRow } from "./rows/swap/SwapRow"

export interface TransactionsTableProps {
  data: BaseRowData[]
  dataToExport: BaseRowData[]
  allTokenInfos: AllTokenInfos
  filter: NotifyFilter
}

const rowTypeMap: {
  [type in NotifyTransactionType]: ComponentType<{
    rowData: any
    allTokenInfos: AllTokenInfos
  }>
} = {
  // swap
  [NotifyTransactionType.Swap]: SwapRow,

  // pool
  [NotifyTransactionType.AddLiquidity]: AddLiquidityRow,
  [NotifyTransactionType.RemoveLiquidity]: RemoveLiquidityRow,

  // stake
  [NotifyTransactionType.ManualStaking]: ManualStakingRow,
  [NotifyTransactionType.StakingHarvest]: StakingHarvestRow,

  [NotifyTransactionType.StakingHarvestAndMintAtAlex]:
    StakingHarvestAndMintAtAlexRow,
  [NotifyTransactionType.AutoStaking]: AutoStakingRow,
  [NotifyTransactionType.AutoStakingAPowerDistribution]:
    AutoStakingAPowerDistributionRow,

  // farm
  [NotifyTransactionType.Farm]: FarmRow,
  [NotifyTransactionType.FarmHarvest]: FarmHarvestRow,
  [NotifyTransactionType.CoFarm]: CoFarmRow,
  [NotifyTransactionType.CoFarmAutoHarvest]: CoFarmAutoHarvestRow,
  [NotifyTransactionType.CoFarmAPowerDistribution]: CoFarmAPowerDistributionRow,
  [NotifyTransactionType.CoFarmClaim]: CoFarmClaimRow,

  // lend
  [NotifyTransactionType.Deposit]: DepositRow,
  [NotifyTransactionType.DepositClaim]: DepositClaimRow,
  [NotifyTransactionType.DepositRollover]: DepositRolloverRow,
  [NotifyTransactionType.DepositSell]: DepositSellRow,
  [NotifyTransactionType.Borrow]: BorrowRow,
  [NotifyTransactionType.BorrowClaim]: BorrowClaimRow,
  [NotifyTransactionType.BorrowRollover]: BorrowRolloverRow,

  // lottery
  [NotifyTransactionType.BuyLottery]: BuyLotteryRow,
  [NotifyTransactionType.LotteryWon]: LotteryWonRow,
}

const rowTypeToExportMap: {
  [type in NotifyTransactionType]?: Exporter<any>
} = {
  // swap
  [NotifyTransactionType.Swap]: swapExporter,

  // pool
  [NotifyTransactionType.AddLiquidity]: addLiquidityExporter,
  [NotifyTransactionType.RemoveLiquidity]: removeLiquidityExporter,

  // stake
  [NotifyTransactionType.ManualStaking]: manualStakingExporter,
  [NotifyTransactionType.StakingHarvest]: stakingHarvestExporter,

  [NotifyTransactionType.StakingHarvestAndMintAtAlex]:
    stakingHarvestAndMintAtAlexExporter,
  [NotifyTransactionType.AutoStaking]: autoStakingExporter,
  [NotifyTransactionType.AutoStakingAPowerDistribution]:
    autoStakingAPowerDistributionExporter,

  // farm
  [NotifyTransactionType.Farm]: farmExporter,
  [NotifyTransactionType.FarmHarvest]: farmHarvestExporter,
  [NotifyTransactionType.CoFarm]: coFarmExporter,
  [NotifyTransactionType.CoFarmAutoHarvest]: coFarmAutoHarvestExporter,
  [NotifyTransactionType.CoFarmAPowerDistribution]:
    coFarmAPowerDistributionExporter,
  [NotifyTransactionType.CoFarmClaim]: coFarmClaimExporter,

  // lend
  [NotifyTransactionType.Deposit]: depositExporter,
  [NotifyTransactionType.DepositClaim]: depositClaimExporter,
  [NotifyTransactionType.DepositRollover]: depositRolloverExporter,
  [NotifyTransactionType.DepositSell]: depositSellExporter,
  [NotifyTransactionType.Borrow]: borrowExporter,
  [NotifyTransactionType.BorrowClaim]: borrowClaimExporter,
  [NotifyTransactionType.BorrowRollover]: borrowRolloverExporter,

  // lottery
  [NotifyTransactionType.BuyLottery]: buyLotteryExporter,
  [NotifyTransactionType.LotteryWon]: lotteryWonExporter,
}

export const TransactionTable: FC<TransactionsTableProps> = props => (
  <TransactionTableContainer
    empty={props.data.length === 0}
    downloadCsvButton={
      <ExporterButton
        data={props.dataToExport}
        allTokenInfos={props.allTokenInfos}
        filename={props.filter}
      />
    }
  >
    {props.data.map(rowData => {
      const Component = rowTypeMap[rowData.type]
      return (
        <Component
          key={rowData.id}
          rowData={rowData as any}
          allTokenInfos={props.allTokenInfos}
        />
      )
    })}
  </TransactionTableContainer>
)

export const ExporterButton: FC<{
  data: BaseRowData[]
  allTokenInfos: AllTokenInfos
  filename?: string
}> = props => {
  const rows = props.data
    .map(rowData => {
      const exporter = rowTypeToExportMap[rowData.type]
      return exporter
        ? exporter(rowData, props.allTokenInfos).join(",")
        : undefined
    })
    .filter(isNotNull)
  const blob = new Blob(
    [...(EXPORTER_COLUMNS.join(",") + "\n"), ...rows.join("\n")],
    { type: "text/csv;charset=utf-8;" },
  )
  const href = URL.createObjectURL(blob)
  return (
    <WhiteFilledButton boxClassName="">
      <a
        href={href}
        download={`${props.filename ?? "transactions"}.csv`}
        className="flex flex-row items-center justify-center gap-x-1 py-1 px-5"
      >
        <ExportIcon />
        <span>Export</span>
      </a>
    </WhiteFilledButton>
  )
}

export const TransactionTableContainer: FCC<{
  empty?: boolean
  downloadCsvButton?: ReactNode
}> = props => (
  <CanScrollMask>
    <Card className={"min-w-min"}>
      <Table>
        <Thead>
          <TableHeadRow>
            {[...TRANSACTION_COLUMNS.slice(0, -1)].map(c => (
              <th
                key={c}
                className="px-2 pb-4 text-gray-500 text-sm font-normal whitespace-nowrap last:text-right"
              >
                {startCase(c)}
              </th>
            ))}
            {props.downloadCsvButton ? (
              <th className="px-2 pb-4 text-gray-500 text-sm font-normal whitespace-nowrap last:text-right">
                {props.downloadCsvButton}
              </th>
            ) : (
              <th className="px-2 pb-4 text-gray-500 text-sm font-normal whitespace-nowrap last:text-right">
                {startCase("Time")}
              </th>
            )}
          </TableHeadRow>
        </Thead>
        <Tbody>{props.children}</Tbody>
      </Table>
      {props.empty && (
        <div className="flex flex-col gap-y-4 items-center justify-center py-20">
          <EmptyIcon />
          <span className="text-base leading-6 font-normal text-gray-600">
            No data
          </span>
        </div>
      )}
    </Card>
  </CanScrollMask>
)
