import { FC } from "react"
import {
  ChartPanelFrame,
  ChartPlaceholder,
  ChartTitle,
} from "../../../../components/ChartPanel"
import { MayDollarSignTokenName } from "../../../../components/MayDollarSignTokenName"
import { Spensor } from "../../../../components/Spensor"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import {
  readResource,
  SuspenseResource,
} from "../../../../utils/SuspenseResource"
import {
  CurvePoint,
  EarningsPreviewChart,
  lineStyles,
} from "./_/EarningsPreviewChart"

export type { CurvePoint } from "./_/EarningsPreviewChart"

export interface EarningsPreviewSectionProps {
  className?: string

  token: TokenInfo

  userSelectedCycleCount: number
  missedCycleCount: number
  maxSelectableCycleCount: number

  chartCurves?: {
    gapCurve: SuspenseResource<CurvePoint[]>
    idealEarningsCurve: SuspenseResource<CurvePoint[]>
    estimatedEarningsCurve: SuspenseResource<CurvePoint[]>
  }
}

export const EarningsPreviewSection: FC<
  EarningsPreviewSectionProps
> = props => {
  return (
    <ChartPanelFrame
      className={props.className}
      header={
        <>
          <ChartTitle.Primary>Earnings Preview</ChartTitle.Primary>
          <ChartTitle.Secondary>
            Longer cycles will yield a higher APR
          </ChartTitle.Secondary>
        </>
      }
    >
      <Spensor fallback={<ChartPlaceholder.Loading />}>
        {() =>
          props.chartCurves == null ? (
            <ChartPlaceholder.Unavailable />
          ) : (
            <EarningsPreviewChart
              maxSelectableCycleCount={props.maxSelectableCycleCount}
              userSelectCycleCount={props.userSelectedCycleCount}
              idealEarningsCurve={readResource(
                props.chartCurves.idealEarningsCurve,
              )}
              estimatedEarningsCurve={readResource(
                props.chartCurves.estimatedEarningsCurve,
              )}
              gapCurve={readResource(props.chartCurves.gapCurve)}
            />
          )
        }
      </Spensor>

      <div className="flex flex-col gap-4 text-gray-200">
        <div className="flex items-center">
          <div
            className="w-12 mr-3.5"
            style={{
              borderWidth: 2,
              borderColor: lineStyles.userSelectedEarningCurve.color,
            }}
          />
          <div>
            <p>
              Selected <MayDollarSignTokenName token={props.token} /> staking
              cycle APR curve ({props.userSelectedCycleCount} Cycles)
            </p>
            <p className="text-gray-500">
              * There will be 1 cycle missed after every{" "}
              {props.userSelectedCycleCount} cycles
            </p>
          </div>
        </div>

        <div className="flex items-center">
          <div
            className="w-12 mr-3.5"
            style={{
              borderWidth: 2,
              borderColor: lineStyles.estimatedEarningsCurve.color,
              borderStyle: "dashed",
              opacity: lineStyles.estimatedEarningsCurve.opacity,
            }}
          />
          <p>Estimated APR curve based on your cycle selection</p>
        </div>

        <div className="flex items-center">
          <div
            className="w-12 mr-3.5"
            style={{
              borderWidth: 2,
              borderColor: lineStyles.idealEarningsCurve.color,
            }}
          />
          <p>
            Maximum <MayDollarSignTokenName token={props.token} /> staking cycle
            APR curve ({props.maxSelectableCycleCount} Cycles)
          </p>
        </div>
      </div>
    </ChartPanelFrame>
  )
}
