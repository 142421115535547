import { FC } from "react"
import { withClassName } from "../../../../../utils/reactHelpers/withClassName"
import { ReactComponent as Icon } from "./prize-funds.svg"

export const PrizeFunds: FC = () => {
  return (
    <section className="flex flex-row flex-wrap gap-6">
      <Content className={"flex-1"}>
        <div>
          <h1 className="text-xl leading-7 font-medium text-white">
            Prize Funds
          </h1>
          <h2 className="text-base leading-6 font-medium text-yellow-200">
            The prizes for each lottery round come from three sources:
          </h2>
        </div>
        <div className="flex flex-col gap-y-4">
          <div>
            <ListTitle>Ticket Purchases</ListTitle>
            <ListContainer>
              <li>
                100% of the ALEX swapped for tickets every round goes back into
                the prize pools.
              </li>
            </ListContainer>
          </div>
          <div>
            <ListTitle>ALEX Lab Foundation Contributions</ListTitle>
            <ListContainer>
              <li>
                The ALEX Lab Foundation will contribute the ticket value in
                $ALEX for every ticket users acquire through APower.
              </li>
            </ListContainer>
          </div>
        </div>
      </Content>

      <Icon className="max-w-full shrink-0" />
    </section>
  )
}

const Content = withClassName(
  "flex flex-col gap-y-7 text-sm leading-5 font-normal text-gray-400",
  "div",
)

const ListTitle = withClassName(
  "text-xm leading-5 font-semibold text-white",
  "h3",
)
const ListContainer = withClassName("list-disc list-outside pl-4", "ul")
