import { FC } from "react"
import { LayoutContainer } from "../../components/LayoutContainer/LayoutContainer"
import { RootLayoutContainer } from "../../components/LayoutContainer/RootLayoutContainer"
import { PageStackPage } from "../../components/PageStack/PageStackPage"
import { PrimaryPageTitle } from "../../components/PageStack/PrimaryPageTitle"
import { suspenseResource } from "../../utils/SuspenseResource"
import TokenListTable from "./components/TokenListTable"
import {
  TokenListStoreProvider,
  useTokenListStore,
} from "./store/useTokenListStore"

const WiredTokenListPageContent: FC = () => {
  const store = useTokenListStore()

  return (
    <TokenListTable
      tokenListItems={suspenseResource(() => store.tokenList.value$)}
    />
  )
}

const TokenListPage: FC = () => {
  return (
    <PageStackPage
      title={
        <LayoutContainer>
          <PrimaryPageTitle className={"w-fit-content"} uppercase={false}>
            Token List
          </PrimaryPageTitle>
        </LayoutContainer>
      }
    >
      <RootLayoutContainer fullWidth={true}>
        <TokenListStoreProvider>
          <LayoutContainer className={"overflow-x-auto"}>
            <WiredTokenListPageContent />
          </LayoutContainer>
        </TokenListStoreProvider>
      </RootLayoutContainer>
    </PageStackPage>
  )
}

export default TokenListPage
