import clsx from "clsx"
import { format } from "date-fns"
import { FC } from "react"
import { OpacityButton } from "../../../../components/button/variants/OpacityButton"
import { Divider } from "../../../../components/Divider"
import { NavLink } from "../../../../components/NavLink"
import { TokenIcon, TokenIconProps } from "../../../../components/TokenIcon"
import { TokenName } from "../../../../components/TokenName"
import { LockdropBannerProps } from "../../store/LockdropDetailStore"
import { LockdropBadge } from "./Badge"
import styles from "./Banner.module.scss"
import { LockdropSteps } from "./Steps"

export const LockdropBanner: FC<LockdropBannerProps> = props => {
  return (
    <section className={clsx(styles.container, "rounded-2xl pb-60 sm:pb-6")}>
      <div className={styles["container-image"]} />
      <div className="flex flex-col px-6 pt-6 gap-6">
        <div className="flex flex-col gap-2.5">
          <h1 className="text-4xl leading-10 font-medium text-white">
            {props.title}
          </h1>
          <p className="text-base leading-6 font-normal text-white/50">
            {props.description}
          </p>
        </div>

        <Divider className="border-gray-200/30 max-w-[34.5rem] lg:max-w-[44rem] min-w-0" />

        <div className="flex flex-col gap-2.5">
          <p className="flex flex-row items-center gap-2.5 flex-wrap">
            <span className="text-base leading-6 font-semibold shrink-0">{`${
              props.name
            }: ${formatDate(props.startDate)} - ${
              props.uncoverSnapshots[2]
                ? formatDate(props.snapshotDates[2]!)
                : "To be announced"
            }`}</span>
            <LockdropBadge status={props.status} />
          </p>

          <p className="text-xs leading-4 font-normal text-white/50">
            Reward Tokens:
          </p>

          <ul className="flex flex-row gap-2.5 flex-wrap max-w-[34.5rem] lg:max-w-[44rem]">
            {props.rewardTokens.map(t => (
              <li key={t.id} className="flex flex-row gap-1.5 items-center">
                <TokenIcon size={TokenIconProps.sizes.small} token={t} />
                <TokenName
                  token={t}
                  className="text-sm leading-5 font-medium"
                />
              </li>
            ))}
          </ul>
        </div>

        <div className="overflow-x-auto rounded-lg p-2.5 sm:p-x-5 bg-[#3a206c] max-w-[34.5rem] lg:max-w-[44rem]">
          <LockdropSteps
            currentStepIndex={props.currentStepIndex}
            startDate={props.startDate}
            endDate={props.uncoverEndDate ? props.endDate : undefined}
            snapshotDates={props.snapshotDates.map((date, index) =>
              props.uncoverSnapshots[index] ? date : undefined,
            )}
          />
        </div>

        <NavLink
          to="#details"
          className="mx-auto"
          onClick={e => {
            e.preventDefault()
            document
              .getElementById("details")
              ?.scrollIntoView({ behavior: "smooth" })
          }}
        >
          <OpacityButton
            boxClassName="px-14 sm:px-16 py-2"
            className="inline-flex flex-row items-center gap-2.5"
          >
            <span className="shrink-0">Rule Details</span>
            <DownArrow />
          </OpacityButton>
        </NavLink>
      </div>
    </section>
  )
}

function formatDate(date: Date): string {
  return format(date, "LLL d")
}

const DownArrow: FC = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.77227 11.3335L2.95666 5.56098L3.89947 4.61816L8.77266 9.49133L13.6451 4.61892L14.5879 5.56172L8.77227 11.3335Z"
      fill="#C4C4C4"
    />
  </svg>
)
