import clsx from "clsx"
import { FC } from "react"
import {
  CardDivider,
  CardInset,
  CardInsetDivider,
  CardInsetTitle,
} from "../../../../components/Card"
import {
  BasicContentContainer,
  CollapsableCard,
} from "../../../../components/CollapsableCard/CollapsableCard"
import { NoteParagraph } from "../../../../components/NoteParagraph/NoteParagraph"
import { BlockTimeCountdown } from "../../../../components/TimeCountdown/BlockTimeCountdown"
import { formatTimeCountdownText } from "../../../../components/TimeCountdown/TimeCountdownText"
import { TokenName } from "../../../../components/TokenName"
import { Tooltip } from "../../../../components/Tooltip/Tooltip"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import {
  StakeCycleTable,
  StakeCycleTableHeaderRow,
  StakeCycleTableRow,
} from "../../components/StakeCycleTable"
import { MixedStakeCycle } from "../../types"
import { ReactComponent as CyclesFullscreenIcon } from "./cyclesFullscreen.svg"
import { HarvestButton } from "./_/HarvestButton"
import { MyStakingSummary } from "./_/MyStakingSummary"
import { columnCount, StakeCycleRowContent } from "./_/StakeCycleRowContent"

export interface MyStakingSectionProps {
  className?: string
  defaultCollapsed?: boolean

  alexToken: TokenInfo
  autoAlexToken: TokenInfo

  autoAlexTokenCount: number
  autoStakingAPY: number
  autoAlexApowerToBeDistributed?: number
  manualStakingAlexTokenCount: number
  manualStakingAPR: number
  harvestableAlexTokenCount: number
  harvestableAPowerCount: number

  currentBlock: number
  currentCycleEndedAt: Date
  currentCycleStartedAtBlock: number
  currentCycleEndedAtBlock: number

  stakingCycles: MixedStakeCycle[]
  onHarvestAll?: (autoMint: boolean) => void
  onShowAllCycles?: () => void
  onShowApowerDistribution?: () => void
}

export const MyStakingSection: FC<MyStakingSectionProps> = props => {
  return (
    <CollapsableCard
      className={props.className}
      title={"My Staking"}
      defaultCollapsed={props.defaultCollapsed}
      ContentContainer={props => (
        <BasicContentContainer
          {...props}
          gapClassName={"gap-6"}
          className={clsx(
            "flex flex-wrap",
            props.gapClassName,
            props.className,
          )}
        />
      )}
    >
      <div
        className={clsx(
          "flex-1 min-w-full lg:min-w-0 py-6",
          "flex flex-col gap-6",
        )}
      >
        <MyStakingSummary
          gapClassName={"gap-6"}
          alexToken={props.alexToken}
          autoAlexToken={props.autoAlexToken}
          autoAlexTokenCount={props.autoAlexTokenCount}
          autoAlexApowerToBeDistributed={props.autoAlexApowerToBeDistributed}
          autoStakingAPY={props.autoStakingAPY}
          manualStakingAlexTokenCount={props.manualStakingAlexTokenCount}
          manualStakingAPR={props.manualStakingAPR}
          onShowApowerDistribution={props.onShowApowerDistribution}
        />

        {props.manualStakingAlexTokenCount > 0 && (
          <HarvestButton
            className={"block"}
            alexToken={props.alexToken}
            autoAlexToken={props.autoAlexToken}
            alexTokenCount={props.harvestableAlexTokenCount}
            apowerTokenCount={props.harvestableAPowerCount}
            onClick={props.onHarvestAll}
          />
        )}

        <CardDivider className={"w-full"} />

        <NoteParagraph className={"w-full"}>
          To unstake <TokenName token={props.autoAlexToken} />, go to SWAP and
          exchange <TokenName token={props.autoAlexToken} /> for{" "}
          <TokenName token={props.alexToken} />.
        </NoteParagraph>
      </div>

      <CardInset className={"flex-1 min-w-full lg:min-w-0 flex flex-col gap-4"}>
        <div className={"flex flex-row items-center"}>
          <CardInsetTitle>Cycles</CardInsetTitle>

          <Tooltip
            className={"mr-auto ml-2.5"}
            title={`Current Cycle Countdown: ${formatTimeCountdownText(
              props.currentCycleEndedAt,
            )}\nStart Block: ${props.currentCycleStartedAtBlock} / End Block: ${
              props.currentCycleEndedAtBlock
            }`}
          >
            <BlockTimeCountdown
              currentBlock={props.currentBlock}
              targetBlock={props.currentCycleEndedAtBlock}
              time={props.currentCycleEndedAt}
            />
          </Tooltip>

          <CyclesFullscreenIcon
            className={"cursor-pointer"}
            onClick={props.onShowAllCycles}
          />
        </div>

        <StakeCycleTable
          divider={<CardInsetDivider />}
          columnCount={columnCount}
        >
          <StakeCycleTableHeaderRow
            titles={["Cycle", "Auto Staking", "Manual Staking"]}
          />
          {props.stakingCycles.map(cycle => (
            <StakeCycleTableRow
              key={cycle.cycleNumber}
              cycleNumber={cycle.cycleNumber}
              status={cycle.cycleStatus}
              fromBlock={cycle.fromBlock}
              targetBlock={cycle.targetBlock}
            >
              <StakeCycleRowContent
                alexToken={props.alexToken}
                autoAlexToken={props.autoAlexToken}
                cycle={cycle}
              />
            </StakeCycleTableRow>
          ))}
        </StakeCycleTable>
      </CardInset>
    </CollapsableCard>
  )
}
