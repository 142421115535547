import { action, makeObservable, observable } from "mobx"
import { DEFAULT_FILTER } from "../../pages/Notify/constants"
import { SystemMessagesModule } from "../../pages/Notify/store/SystemMessagesModule"
import { TransactionsModule } from "../../pages/Notify/store/TransactionsModule"
import * as types from "../../pages/Notify/types"
import AccountStore from "../accountStore/AccountStore"
import { AppEnvStore } from "../appEnvStore/AppEnvStore"
import AuthStore from "../authStore/AuthStore"
import { ChainStore } from "../chainStore/ChainStore"
import CurrencyStore from "../currencyStore/CurrencyStore"

class NotifyStore {
  constructor(
    readonly accountStore: Pick<AccountStore, "getBalance$" | "transactions$">,
    readonly currencyStore: Pick<
      CurrencyStore,
      "getPrice$" | "getTokenInfo$" | "fetchPoolBreakdown$" | "allTokenInfos$"
    >,
    readonly authStore: Pick<
      AuthStore,
      "stxAddress$" | "currentBlockHeight$" | "isWalletConnected"
    >,
    readonly appEnvStore: Pick<AppEnvStore, "config$">,
    readonly chainStore: Pick<
      ChainStore,
      | "estimatedDateForBlock$"
      | "currentBlockHeight$"
      | "currentBlockHash$"
      | "stakeChainModule"
    >,
  ) {
    makeObservable(this)
  }
  transactions = new TransactionsModule(this)
  systemMessages = new SystemMessagesModule(this)

  // sync route query params with store
  @observable filter: types.NotifyFilter = DEFAULT_FILTER
  @action setFilter(filter: types.NotifyFilter): void {
    this.filter = filter
  }
  @observable pageIndex = 0
  @action setPageIndex(index: number): void {
    this.pageIndex = index
  }
}

export default NotifyStore
