import { action } from "mobx"
import { FC } from "react"
import { Modal } from "../../../components/Modal"
import { usePathGenerator } from "../../../routes/routes"
import { Currency } from "../../../utils/alexjs/Currency"
import { useAutoStakeStore } from "../store/autoStaking/useAutoStakeStore"
import { BetterPricesInSwap } from "./BetterPricesInSwap/BetterPricesInSwap"

const WiredBetterPricesInSwap: FC = () => {
  const store = useAutoStakeStore()
  const discount = store.confirmingDiscount?.swapDiscount
  const onClose = action(() => (store.confirmingDiscount = undefined))
  const g = usePathGenerator()
  return (
    <Modal visible={discount != null} onClose={onClose}>
      {discount != null && (
        <BetterPricesInSwap
          discountPercentage={discount}
          onClose={onClose}
          swapLink={
            g.swap() +
            `?fromCurrency=${Currency.ALEX}&toCurrency=${Currency.ATALEX}`
          }
          onProceed={() => store.proceedIgnoreDiscount()}
        />
      )}
    </Modal>
  )
}

export default WiredBetterPricesInSwap
