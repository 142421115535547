import clsx from "clsx"
import { FC, ReactNode } from "react"
import {
  CardInset,
  CardInsetDivider,
  CardInsetTitle,
} from "../../../../components/Card"
import { BlockTimeCountdown } from "../../../../components/TimeCountdown/BlockTimeCountdown"
import { formatTimeCountdownText } from "../../../../components/TimeCountdown/TimeCountdownText"
import { TipIcon } from "../../../../components/TipIcon/TipIcon"
import { Tooltip } from "../../../../components/Tooltip/Tooltip"
import { StakeCycle } from "../../types"
import { StakeCycleTable, StakeCycleTableRow } from "../StakeCycleTable"
import { ReactComponent as CyclesFullscreenIcon } from "./_/cyclesFullscreen.svg"
import { columnCount, StakeCycleRowContent } from "./_/StakeCycleRowContent"

export interface ManualStakingCyclesSimpleTableProps {
  className?: string
  copywriting: {
    cycleTip: ReactNode
    toBeStaked: {
      finishedStatus: string
      currentState: string
      upcomingStatus: string
    }
  }
  currentCycleEndedAtTime: Date
  currentCycleStartedAtBlock: number
  currentCycleEndedAtBlock: number
  currentBlock: number
  stakeCycles: StakeCycle[]
  onExpand?: () => void
}

export const ManualStakingCyclesSimpleTable: FC<
  ManualStakingCyclesSimpleTableProps
> = props => {
  return (
    <CardInset className={clsx("flex flex-col gap-4", props.className)}>
      <div className={"flex flex-row items-center"}>
        <CardInsetTitle>Cycles</CardInsetTitle>

        <TipIcon className={"ml-2.5"} tip={props.copywriting.cycleTip} />

        <Tooltip
          className={"ml-auto mr-6"}
          title={`Current Cycle Countdown: ${formatTimeCountdownText(
            props.currentCycleEndedAtTime,
          )}\nStart Block: ${props.currentCycleStartedAtBlock} / End Block: ${
            props.currentCycleEndedAtBlock
          }`}
        >
          <BlockTimeCountdown
            currentBlock={props.currentBlock}
            targetBlock={props.currentCycleEndedAtBlock}
            time={props.currentCycleEndedAtTime}
          />
        </Tooltip>

        <CyclesFullscreenIcon
          className={"cursor-pointer"}
          onClick={props.onExpand}
        />
      </div>

      <CardInsetDivider />

      <StakeCycleTable divider={<CardInsetDivider />} columnCount={columnCount}>
        {props.stakeCycles.map(cycle => (
          <StakeCycleTableRow
            key={cycle.cycleNumber}
            cycleNumber={cycle.cycleNumber}
            fromBlock={cycle.fromBlock}
            targetBlock={cycle.targetBlock}
            status={cycle.cycleStatus}
          >
            <StakeCycleRowContent
              status={cycle.cycleStatus}
              apr={cycle.apr}
              toBeFarmed={cycle.myStaked}
              principalToClaim={cycle.principalToClaim}
              rewardToClaim={cycle.rewardToClaim}
              finishedStatusCopies={{
                toBeFarmed: props.copywriting.toBeStaked.finishedStatus,
                estimatedClaim: "Estimated Earnings",
              }}
              upcomingStatusCopies={{
                toBeFarmed: props.copywriting.toBeStaked.upcomingStatus,
                estimatedClaim: "Estimated Earnings",
              }}
              currentStatusCopies={{
                toBeFarmed: props.copywriting.toBeStaked.currentState,
                estimatedClaim: "Estimated Earnings",
              }}
            />
          </StakeCycleTableRow>
        ))}
      </StakeCycleTable>
    </CardInset>
  )
}
