import clsx from "clsx"
import { FC } from "react"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { CardInset } from "../../../../components/Card"
import { CardModalContent } from "../../../../components/CardModal/CardModal"
import { TokenCount } from "../../../../components/TokenCount"
import { TokenName } from "../../../../components/TokenName"
import { ordinal } from "../../../../utils/stringHelpers"
import { TokenInfoPresets } from "../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { APowerRuleRange } from "../types"
import styles from "./ValidateAPowerRuleModalContent.module.scss"

export const ValidateAPowerRuleModalContent: FC<{
  ranges: APowerRuleRange[]
  maxTickets: number
  onClose?: () => void
  onConfirm?: () => void
}> = props => {
  return (
    <CardModalContent
      className={"text-gray-200"}
      title={"APower IDO Table"}
      width={640}
      onClose={props.onClose}
    >
      <CardInset>
        <ValidateAPowerRuleTable ranges={props.ranges} />
        <p>
          Note: Each wallet address will only be able to validate{" "}
          {props.maxTickets} tickets at maximum
        </p>
      </CardInset>

      <GradientFilledButton className={"w-full"} onClick={props.onConfirm}>
        OK
      </GradientFilledButton>
    </CardModalContent>
  )
}

export const ValidateAPowerRuleTable: FC<{
  className?: string
  ranges: APowerRuleRange[]
}> = props => (
  <div className={clsx(styles.table, props.className)}>
    <table>
      <thead>
        <tr>
          <th align="left">Tickets</th>
          <th align="left">
            <TokenName token={TokenInfoPresets.APower} /> Per Ticket
          </th>
        </tr>
      </thead>
      <tbody>
        {props.ranges.map(range => (
          <tr key={range.start}>
            <td align="left">
              {ordinal(range.start)} - {ordinal(range.end)}
            </td>
            <td align="left">
              <TokenCount
                token={TokenInfoPresets.APower}
                count={range.amount}
              />{" "}
              <TokenName token={TokenInfoPresets.APower} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)
