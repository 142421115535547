import clsx from "clsx"
import React, { ReactNode } from "react"
import { RootLayoutContainerProvider } from "../../../../components/LayoutContainer/RootLayoutContainer"
import { NavLink } from "../../../../components/NavLink"
import { TopNavBarHeight } from "../../../../components/TopNavBar/TopNavBar"
import styles from "../../../../layouts/Layout/Layout.module.scss"
import { FCC } from "../../../../utils/reactHelpers/types"
import { BridgeBackgroundContainer } from "../BridgeBackgroundContainer/BridgeBackgroundContainer"
import logoSrc from "./_/logo.svg"

export const NewBrandWrapBridgePageLayout: FCC<{
  rightSide?: ReactNode
}> = props => {
  return (
    <BridgeBackgroundContainer>
      <div className={"fixed top-0 left-0 right-0 z-1"}>
        <div
          className={
            "flex items-center py-5 px-4 sm:px-8 backdrop-blur-lg justify-between gap-1 relative z-1"
          }
          style={{
            height: TopNavBarHeight,
            background: "rgba(17, 24, 39, 0.2)",
          }}
        >
          <NavLink
            className={"flex items-center cursor-pointer gap-4"}
            to={"/"}
          >
            <img
              width={40}
              height={40}
              src={logoSrc}
              alt={"Stacks Bridge Logo"}
            />
            <span className={"text-xl leading-7 font-semibold text-white"}>
              BRIDGE
            </span>
          </NavLink>

          {props.rightSide}
        </div>
      </div>

      <RootLayoutContainerProvider
        className={"pb-8 sm:py-[8.75rem] grow"}
        topNavHeight={TopNavBarHeight}
        topExtraPadding={16}
      >
        {props.children}
      </RootLayoutContainerProvider>

      <div
        className={clsx(
          styles["widgets-container"],
          "sm:fixed bottom-0 left-0 right-0 mt-16 p-5 flex items-end",
        )}
      />
    </BridgeBackgroundContainer>
  )
}
