import { FC } from "react"
import { CardPlate } from "../../../components/Card"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../components/InfoList"
import { PercentNumber } from "../../../components/PercentNumber"
import { Spensor } from "../../../components/Spensor"
import { TipIcon } from "../../../components/TipIcon/TipIcon"
import { TokenCount } from "../../../components/TokenCount"
import { TokenExchangeRoute } from "../../../components/TokenExchangeRoute"
import { TokenName } from "../../../components/TokenName"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { readResource, SuspenseResource } from "../../../utils/SuspenseResource"

export type SpotInfoProps = {
  liquidityProviderFee: SuspenseResource<number>
  slippage: number
  minimumReceived: SuspenseResource<number>
  fromToken: SuspenseResource<TokenInfo>
  toToken: SuspenseResource<TokenInfo>
  routes: SuspenseResource<TokenInfo[]>
}

export const SpotInfo: FC<SpotInfoProps> = props => {
  return (
    <CardPlate>
      <InfoList direction={"column"} listItemDirection={"row-responsive"}>
        <InfoListItem>
          <InfoListItemTitle>Route</InfoListItemTitle>
          <InfoListItemDetail>
            <Spensor>
              {() => <TokenExchangeRoute tokens={readResource(props.routes)} />}
            </Spensor>
          </InfoListItemDetail>
        </InfoListItem>

        <InfoListItem>
          <InfoListItemTitle>
            Liquidity Provider Fee{" "}
            <TipIcon
              inline
              tip={
                "To receive a share of these fees, become a Liquidity Provider through “Pool”"
              }
            />
          </InfoListItemTitle>
          <InfoListItemDetail>
            <Spensor fallback="--">
              {() => (
                <>
                  <TokenCount
                    token={readResource(props.fromToken)}
                    count={readResource(props.liquidityProviderFee)}
                  />{" "}
                  <TokenName token={readResource(props.fromToken)} />
                </>
              )}
            </Spensor>
          </InfoListItemDetail>
        </InfoListItem>

        <InfoListItem>
          <InfoListItemTitle>
            Slippage Tolerance{" "}
            <TipIcon
              inline
              tip="Your transaction will revert if the price changes unfavorably by more than this percentage."
            />
          </InfoListItemTitle>
          <InfoListItemDetail>
            <PercentNumber number={props.slippage} />
          </InfoListItemDetail>
        </InfoListItem>
        <InfoListItem>
          <InfoListItemTitle>Minimum Received</InfoListItemTitle>
          <InfoListItemDetail>
            <Spensor>
              {() => (
                <>
                  <TokenCount
                    token={readResource(props.toToken)}
                    count={readResource(props.minimumReceived)}
                  />{" "}
                  <TokenName token={readResource(props.toToken)} />
                </>
              )}
            </Spensor>
          </InfoListItemDetail>
        </InfoListItem>
      </InfoList>
    </CardPlate>
  )
}
