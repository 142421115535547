import clsx from "clsx"
import RcPagination from "rc-pagination"
import { FC, ReactNode, SVGProps } from "react"
import { FCC } from "../utils/reactHelpers/types"
import { withClassName } from "../utils/reactHelpers/withClassName"
import { safeReadResource, SuspenseResource } from "../utils/SuspenseResource"
import { OpacityButton } from "./button/variants/OpacityButton"

export interface PaginationInfo {
  /**
   * zero based page number
   */
  currentPage: number

  recordCountPerPage: number

  recordCountTotal: SuspenseResource<number>
}

export interface PaginationProps extends PaginationInfo {
  className?: string
  onChange?: (info: { page: number }) => void
}

export const Pagination: FC<PaginationProps> = props => {
  return (
    <div
      className={clsx(
        "flex flex-row justify-between align-center",
        props.className,
      )}
    >
      {props.recordCountTotal <= 0 ? (
        <p />
      ) : (
        <p
          className={
            "self-center inline-block text-sm leading-5 font-normal text-white"
          }
        >
          Showing{" "}
          <PaginateNumber>
            {props.currentPage * props.recordCountPerPage + 1}
          </PaginateNumber>{" "}
          to{" "}
          <PaginateNumber>
            {(props.currentPage + 1) * props.recordCountPerPage}
          </PaginateNumber>{" "}
          of{" "}
          <PaginateNumber>
            {safeReadResource(props.recordCountTotal) ?? "--"}
          </PaginateNumber>{" "}
          results
        </p>
      )}

      <RcPagination
        className={"max-w-full flex flex-row gap-[2px]"}
        locale={paginationLocale}
        current={props.currentPage + 1}
        total={
          safeReadResource(props.recordCountTotal) ??
          // ensure we will render at least 1 page
          props.recordCountPerPage
        }
        pageSize={props.recordCountPerPage}
        itemRender={paginationItemRender(props.currentPage + 1)}
        showLessItems={true}
        onChange={page => props.onChange?.({ page: page - 1 })}
      />
    </div>
  )
}

const paginationLocale = {
  // Options.jsx
  items_per_page: "/ page",
  jump_to: "Go to",
  jump_to_confirm: "confirm",
  page: "Page",

  // Pagination.jsx
  prev_page: "Previous Page",
  next_page: "Next Page",
  prev_5: "Previous 5 Pages",
  next_5: "Next 5 Pages",
  prev_3: "Previous 3 Pages",
  next_3: "Next 3 Pages",
  page_size: "Page Size",
}

const paginationItemRender =
  (currentPage: number) =>
  (
    page: number,
    type: "page" | "prev" | "next" | "jump-prev" | "jump-next",
  ): ReactNode => {
    if (type === "page") {
      return (
        <PaginateButton state={currentPage === page ? "active" : "idle"}>
          {page}
        </PaginateButton>
      )
    }

    if (type === "jump-next" || type === "jump-prev") {
      return <PaginateButton state={"disabled"}>...</PaginateButton>
    }

    if (type === "next") {
      return (
        <PaginateButton
          className={"rounded-r-lg h-full flex items-center"}
          state={"idle"}
        >
          <ArrowIcon className={"rotate-180"} />
        </PaginateButton>
      )
    }

    if (type === "prev") {
      return (
        <PaginateButton
          className={"rounded-l-lg h-full flex items-center"}
          state={"idle"}
        >
          <ArrowIcon />
        </PaginateButton>
      )
    }

    return null
  }

const PaginateButton: FCC<{
  className?: string
  state: "idle" | "active" | "disabled"
}> = props => {
  return (
    <OpacityButton
      className={clsx(
        props.className,
        props.state === "disabled" && "pointer-events-none",
        props.state === "active" && "pointer-events-none",
      )}
      boxClassName={"px-3.5 py-2.5"}
      textClassName={
        "text-sm leading-5 font-normal text-gray-400 hover:text-white active:text-white"
      }
      bgClassName={clsx(
        "bg-white hover:bg-black hover:bg-opacity-5 active:bg-black active:bg-opacity-15 disabled:bg-black disabled:bg-opacity-15",
        props.state === "active" ? "bg-opacity-20" : "bg-opacity-5",
      )}
      roundedClassName={""}
    >
      {props.children}
    </OpacityButton>
  )
}

const ArrowIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="#9CA3AF"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M4.91502 7.97589L10.6876 13.7915L11.6304 12.8487L6.7572 7.97551L11.6296 3.10309L10.6868 2.16028L4.91502 7.97589Z" />
  </svg>
)

const PaginateNumber = withClassName("font-bold", "span")
