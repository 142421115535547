import clsx from "clsx"
import { FC, ReactNode } from "react"
import { defineMessage, useIntl } from "react-intl"
import { Spensor } from "../../../../components/Spensor"
import { TabBar } from "../../../../components/TabBar"
import { ThumbSwitch } from "../../../../components/ThumbSwitch"
import {
  readResource,
  SuspenseResource,
} from "../../../../utils/SuspenseResource"
import { RecordTableContainer } from "./_/RecordRow"

export enum HistoryContentType {
  OpenOrders,
  OrdersHistory,
  TradeHistory,
  FundsHistory,
}

export const OrderHistoryFrame: FC<{
  className?: string
  openOrderCount: SuspenseResource<number>
  isHideOtherSymbols: boolean
  onChangeHideOtherSymbols: (isHideOtherSymbols: boolean) => void
  children: (info: { value: HistoryContentType }) => ReactNode | ReactNode[]
}> = props => {
  const { $t } = useIntl()

  const openOrdersTabName = defineMessage({
    defaultMessage: `Open Orders ({orderCount})`,
    description: "Orderbook/OrderHistory/tab name",
  })

  return (
    <TabBar
      className={clsx(props.className)}
      tabClassName={"px-4 py-3 text-sm leading-5 font-semibold"}
      tabs={[
        {
          label: (
            <Spensor fallback={$t(openOrdersTabName, { orderCount: "..." })}>
              {() =>
                $t(openOrdersTabName, {
                  orderCount: readResource(props.openOrderCount),
                })
              }
            </Spensor>
          ),
          value: HistoryContentType.OpenOrders,
        },
        {
          label: $t(
            defineMessage({
              defaultMessage: `Order History`,
              description: "Orderbook/OrderHistory/tab name",
            }),
          ),
          value: HistoryContentType.OrdersHistory,
        },
        {
          label: $t(
            defineMessage({
              defaultMessage: `Trade History`,
              description: "Orderbook/OrderHistory/tab name",
            }),
          ),
          value: HistoryContentType.TradeHistory,
        },
        {
          label: $t(
            defineMessage({
              defaultMessage: `Funds`,
              description: "Orderbook/OrderHistory/tab name",
            }),
          ),
          value: HistoryContentType.FundsHistory,
        },
      ]}
      rightSide={
        <div className={"ml-auto"}>
          <label
            className={
              "flex items-center gap-x-2 text-gray-200 text-xs leading-4 font-normal"
            }
          >
            {$t(
              defineMessage({
                defaultMessage: "{checkbox} Hide Other Markets",
                description:
                  "Orderbook/OrderHistory/Hide Other Markets option label",
              }),
              {
                checkbox: (
                  <ThumbSwitch
                    containerClassName={"bg-gray-800 checked:bg-green-600"}
                    thumbClassName={"bg-gray-600"}
                    inline
                    checked={props.isHideOtherSymbols}
                    onCheckedChange={props.onChangeHideOtherSymbols}
                  />
                ),
              },
            )}
          </label>
        </div>
      }
    >
      {info => (
        <RecordTableContainer>
          <div className={"flex flex-col gap-5 min-h-[300px]"}>
            {props.children(info)}
          </div>
        </RecordTableContainer>
      )}
    </TabBar>
  )
}
