import clsx from "clsx"
import { identity, noop, omit } from "lodash"
import {
  ComponentType,
  createContext,
  CSSProperties,
  FC,
  ReactNode,
  useContext,
  useId,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { hasAny } from "../utils/arrayHelpers"
import { FCC } from "../utils/reactHelpers/types"
import { createUseCheckNested } from "../utils/reactHelpers/useCheckNested"
import { usePersistFn } from "../utils/reactHelpers/usePersistFn"

export type ListItemDirection = "row" | "column" | "column-reverse"

interface InfoListContextValue {
  listItemDirection: ListItemDirection
  listItemClassName?: string
  listItemUpdateWrappedState: (elId: string, isWrapped: boolean) => void
  InfoListItemTitle?: ComponentType<InfoListItemTitleProps>
  InfoListItemDetail?: ComponentType<InfoListItemDetailProps>
}
const InfoListContext = createContext<InfoListContextValue>({
  listItemDirection: "row",
  listItemUpdateWrappedState: noop,
})

export interface AccessibleInfoListContext {
  listItemDirection: ListItemDirection
}
export const useInfoListContext = (): AccessibleInfoListContext => {
  const ctx = useContext(InfoListContext)

  return useMemo(
    () => ({ listItemDirection: ctx.listItemDirection }),
    [ctx.listItemDirection],
  )
}

export interface InfoListProps {
  className?: string

  /**
   * @default gap-2.5
   */
  gapClassName?: string

  /**
   * @default column
   *
   * `row-responsive` means will become `column` layout when the container width
   * is not enough
   */
  direction?: "row" | "column" | "row-responsive"

  /**
   * @default row
   *
   * `row-responsive` means will become `column` layout when the container width
   * is not enough
   */
  listItemDirection?: ListItemDirection | "row-responsive"
  listItemClassName?: string

  InfoListItemTitle?: ComponentType<InfoListItemTitleProps>
  InfoListItemDetail?: ComponentType<InfoListItemDetailProps>
}
export const InfoList: FCC<InfoListProps> = props => {
  const { direction = "column", gapClassName = "gap-2.5" } = props

  const [wrappedState, setWrappedState] = useState<Record<string, boolean>>({})
  const listItemUpdateWrappedState: InfoListContextValue["listItemUpdateWrappedState"] =
    usePersistFn((id, isWrapped) => {
      setWrappedState(state => {
        if (isWrapped) {
          return { ...state, [id]: true }
        } else {
          return omit(state, [id])
        }
      })
    })

  let finalDirection: ListItemDirection
  if (props.listItemDirection === "row-responsive") {
    const isAnyWrapped = Object.values(wrappedState).some(identity)
    finalDirection = isAnyWrapped ? "column" : "row"
  } else {
    finalDirection = props.listItemDirection ?? "row"
  }

  const ctxValue: InfoListContextValue = useMemo(
    () => ({
      listItemDirection: finalDirection,
      listItemClassName: props.listItemClassName,
      listItemUpdateWrappedState,
      InfoListItemTitle: props.InfoListItemTitle,
      InfoListItemDetail: props.InfoListItemDetail,
    }),
    [
      finalDirection,
      listItemUpdateWrappedState,
      props.InfoListItemDetail,
      props.InfoListItemTitle,
      props.listItemClassName,
    ],
  )

  return (
    <InfoListContext.Provider value={ctxValue}>
      <dl
        className={clsx(
          "flex",
          gapClassName,
          direction === "row-responsive" && "flex-wrap",
          direction === "column" && "flex-col",
          props.className,
        )}
      >
        {props.children}
      </dl>
    </InfoListContext.Provider>
  )
}

export const InfoListItem: FCC<{
  className?: string
  style?: CSSProperties
}> = props => {
  const { listItemDirection, listItemUpdateWrappedState, listItemClassName } =
    useContext(InfoListContext)

  const elRef = useRef<HTMLDivElement>(null)

  const compId = useId()

  useLayoutEffect(() => {
    if (elRef.current == null) return

    const childElements = Array.from(elRef.current.childNodes).filter(
      (node): node is Element => node.nodeType === Node.ELEMENT_NODE,
    )
    if (!hasAny(childElements)) return

    const dlHeight = elRef.current.getBoundingClientRect().height
    const isContentWrapped = childElements.every(
      e => dlHeight > e.getBoundingClientRect().height,
    )

    listItemUpdateWrappedState(compId, isContentWrapped)

    return () => {
      listItemUpdateWrappedState(compId, false)
    }
  }, [compId, listItemUpdateWrappedState])

  return (
    <div
      ref={elRef}
      className={clsx(
        "flex flex-col",
        listItemDirection === "row" &&
          "sm:flex-row sm:items-center sm:flex-wrap",
        listItemClassName,
        props.className,
      )}
      style={props.style}
    >
      {props.children}
    </div>
  )
}

export interface InfoListItemTitleProps {
  className?: string
  textClassName?: string
  children?: ReactNode
}
export const DefaultInfoListItemTitle: FC<InfoListItemTitleProps> = props => {
  return (
    <dt
      className={clsx(
        "whitespace-pre",
        props.className,
        props.textClassName ?? "text-sm text-gray-400",
      )}
    >
      {props.children}
    </dt>
  )
}
export const createInfoListItemTitle = (
  defaultProps: InfoListItemTitleProps,
): ComponentType<InfoListItemTitleProps> => {
  const Comp: FC<InfoListItemTitleProps> = props => {
    return <DefaultInfoListItemTitle {...defaultProps} {...props} />
  }
  Comp.displayName = "created(InfoListItemTitle)"
  return Comp
}
const InfoListItemTitleCheckNested = createUseCheckNested("InfoListItemTitle")
export const InfoListItemTitle: FC<InfoListItemTitleProps> = props => {
  const { InfoListItemTitle } = useContext(InfoListContext)

  InfoListItemTitleCheckNested.useThrowNested()

  if (InfoListItemTitle) {
    return (
      <InfoListItemTitleCheckNested.Provider>
        <InfoListItemTitle {...props} />
      </InfoListItemTitleCheckNested.Provider>
    )
  } else {
    return <DefaultInfoListItemTitle {...props} />
  }
}

export interface InfoListItemDetailProps {
  className?: string
  textClassName?: string
  /**
   * will be `left` when `ListItem` direction is `column`, and be `right` when
   * direction is `row`
   */
  alignSelf?: "left" | "right"
}
export const DefaultInfoListItemDetail: FCC<
  InfoListItemDetailProps
> = props => {
  const { listItemDirection } = useContext(InfoListContext)

  const alignSelf =
    props.alignSelf ?? listItemDirection === "column" ? "left" : "right"

  return (
    <dd
      className={clsx(
        "whitespace-pre",
        props.className,
        alignSelf === "right" && "ml-auto",
        props.textClassName ?? "text-base text-gray-200",
      )}
    >
      {props.children}
    </dd>
  )
}
export const createInfoListItemDetail = (
  defaultProps: InfoListItemDetailProps,
): ComponentType<InfoListItemDetailProps> => {
  const Comp: FC<InfoListItemDetailProps> = props => {
    return <DefaultInfoListItemDetail {...defaultProps} {...props} />
  }
  Comp.displayName = "created(InfoListItemDetail)"
  return Comp
}
const InfoListItemDetailCheckNested = createUseCheckNested("InfoListItemDetail")
export const InfoListItemDetail: FCC<InfoListItemDetailProps> = props => {
  const { InfoListItemDetail } = useContext(InfoListContext)

  InfoListItemDetailCheckNested.useThrowNested()

  if (InfoListItemDetail) {
    return (
      <InfoListItemDetailCheckNested.Provider>
        <InfoListItemDetail {...props} />
      </InfoListItemDetailCheckNested.Provider>
    )
  } else {
    return <DefaultInfoListItemDetail {...props} />
  }
}
