import { FC } from "react"
import { Spensor } from "../../../../components/Spensor"
import { Tooltip } from "../../../../components/Tooltip/Tooltip"
import {
  readResource,
  SuspenseResource,
} from "../../../../utils/SuspenseResource"
import { ReactComponent as CycleCountQuestionIcon } from "./cycleCount.svg"

export interface EstimateCycleDaysProps {
  cycleCount: number
  cycleTip: string
  estimateDayCount: SuspenseResource<number>
}

export const EstimateCycleDays: FC<EstimateCycleDaysProps> = props => (
  <div className={"flex flex-wrap items-center justify-between"}>
    <div className={"flex items-center gap-2.5"}>
      <span className={"text-sm leading-5 font-normal text-gray-400"}>
        Staking for:
      </span>
      <span className={"text-lg leading-7 font-medium text-gray-200"}>
        {props.cycleCount} Cycle
        {props.cycleCount > 1 ? "s" : ""}
      </span>
      <Tooltip title={props.cycleTip}>
        <CycleCountQuestionIcon className={"opacity-40"} fill={"#eaeaea"} />
      </Tooltip>
    </div>

    <p className={"text-sm leading-5 font-normal text-gray-500"}>
      Estimate&nbsp;
      <Spensor fallback={"..."}>
        {() => {
          const day = readResource(props.estimateDayCount)
          return day === 0 ? "<1" : day
        }}
      </Spensor>
      &nbsp;days
    </p>
  </div>
)
