import { sum } from "lodash"
import { FC } from "react"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteFilledButton } from "../../../../components/button/variants/WhiteFilledButton"
import { CardModalContent } from "../../../../components/CardModal/CardModal"
import { Spensor } from "../../../../components/Spensor"
import { TokenCount } from "../../../../components/TokenCount"
import { BlockTopTitleLine } from "../../../../components/TokenInput/Block"
import {
  BlockGroup,
  BlockGroupPlusIcon,
} from "../../../../components/TokenInput/BlockGroup"
import { TokenBlock } from "../../../../components/TokenInput/TokenBlock"
import { TokenName } from "../../../../components/TokenName"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { readResource } from "../../../../utils/SuspenseResource"
import { TokenInfoPresets } from "../../../../utils/TokenInfoPresets/TokenInfoPresets"
import {
  AddStakeSectionInfoList,
  AddStakeSectionInfoListItem,
} from "../../components/AddStakeSectionInfoList"
import { ClaimTokenInfo } from "../../types"

export interface ConfirmHarvestModalContentProps {
  principalToClaim: ClaimTokenInfo
  rewardToClaim: [ClaimTokenInfo, ...ClaimTokenInfo[]]
  onConfirm?: () => void
  onClose?: () => void
}

export const ConfirmHarvestModalContent: FC<
  ConfirmHarvestModalContentProps
> = props => (
  <CardModalContent
    onClose={props.onClose}
    title={"Confirm Harvest"}
    className={"flex flex-col gap-4"}
    width={480}
  >
    <BlockGroup
      icon={<BlockGroupPlusIcon />}
      firstBlock={
        <TokenBlock
          topArea={<BlockTopTitleLine>Principal</BlockTopTitleLine>}
          tokens={[props.principalToClaim]}
        />
      }
      secondBlock={
        <TokenBlock
          topArea={<BlockTopTitleLine>Reward</BlockTopTitleLine>}
          tokens={props.rewardToClaim}
        />
      }
    />

    <AddStakeSectionInfoList>
      <Spensor>
        {() => (
          <AddStakeSectionInfoListItem
            title={"Total Claim"}
            detail={
              <ul className={"text-right"}>
                {ClaimTokenInfo.groupSameTokenInfo([
                  props.principalToClaim,
                  ...props.rewardToClaim,
                ]).map((group, idx) => (
                  <li key={idx}>
                    <ClaimCountInfo
                      token={group[0].token}
                      count={sum(group.map(info => info.count))}
                      countToUSD={sum(
                        group.map(info =>
                          info.countToUSD == null
                            ? NaN
                            : readResource(info.countToUSD),
                        ),
                      )}
                    />
                  </li>
                ))}
              </ul>
            }
          />
        )}
      </Spensor>
    </AddStakeSectionInfoList>

    <div className={"mt-3.5 flex flex-row gap-2.5"}>
      <WhiteFilledButton className={"flex-1"} onClick={props.onClose}>
        Cancel
      </WhiteFilledButton>

      <GradientFilledButton className={"flex-1"} onClick={props.onConfirm}>
        Confirm
      </GradientFilledButton>
    </div>
  </CardModalContent>
)

const ClaimCountInfo: FC<{
  token: TokenInfo
  count: number
  countToUSD?: number
}> = props => (
  <>
    <TokenCount token={props.token} count={props.count} />
    &nbsp;
    <TokenName token={props.token} />
    {props.countToUSD != null && !Number.isNaN(props.countToUSD) && (
      <span className={"text-gray-500 ml-[5px]"}>
        ($&nbsp;
        <TokenCount token={TokenInfoPresets.USD} count={props.countToUSD} />)
      </span>
    )}
  </>
)
