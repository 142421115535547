import clsx from "clsx"
import { FC } from "react"
import {
  NoteParagraph,
  SuccessIcon,
} from "../../../components/NoteParagraph/NoteParagraph"
import { TokenName } from "../../../components/TokenName"
import { TokenInfo } from "../../../utils/models/TokenInfo"

export const CoFarmWhy: FC<{
  className?: string
  lpLeftToken: TokenInfo
  rewardToken: TokenInfo
  endCycle: number
}> = props => (
  <ul className={clsx(props.className, "flex flex-col gap-2.5")}>
    <li>
      <NoteParagraph icon={<SuccessIcon />}>
        You commit <TokenName token={props.lpLeftToken} /> half of the pool
        only.
      </NoteParagraph>
    </li>

    <li>
      <NoteParagraph icon={<SuccessIcon />}>
        The ALEX Lab Foundation will provide the other half and co-farm.
      </NoteParagraph>
    </li>

    <li>
      <NoteParagraph icon={<SuccessIcon />}>
        At the end of farming, you will receive the same amount the{" "}
        <TokenName token={props.lpLeftToken} /> as what you initially committed.
      </NoteParagraph>
    </li>

    <li>
      <NoteParagraph icon={<SuccessIcon />}>
        Co-Farming will conclude at cycle #{props.endCycle}
      </NoteParagraph>
    </li>

    <li>
      <NoteParagraph icon={<SuccessIcon />}>
        <TokenName token={props.rewardToken} /> rewards will be automatically
        sent to your wallet address after each cycle.
      </NoteParagraph>
    </li>
  </ul>
)
