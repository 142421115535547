import clsx from "clsx"
import { motion } from "framer-motion"
import { GrayBadge } from "../../../../../components/Badge"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../../components/InfoList"
import { LoadingIndicator } from "../../../../../components/LoadingIndicator/LoadingIndicator"
import { NavLink } from "../../../../../components/NavLink"
import { NoteParagraph } from "../../../../../components/NoteParagraph/NoteParagraph"
import { PercentNumber } from "../../../../../components/PercentNumber"
import { Spensor } from "../../../../../components/Spensor"
import { SwitchLabel, ThumbSwitch } from "../../../../../components/ThumbSwitch"
import { TipIcon } from "../../../../../components/TipIcon/TipIcon"
import { TokenCount } from "../../../../../components/TokenCount"
import {
  BlockTopLine,
  renderThinnerBlock,
} from "../../../../../components/TokenInput/Block"
import {
  BlockGroup,
  BlockGroupDownArrowIcon,
} from "../../../../../components/TokenInput/BlockGroup"
import { TokenInput } from "../../../../../components/TokenInput/TokenInput"
import { TokenName } from "../../../../../components/TokenName"
import { AnimatePresence } from "../../../../../utils/animateHelpers/AnimatePresence"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import {
  readResource,
  safeReadResource,
  SuspenseResource,
} from "../../../../../utils/SuspenseResource"
import {
  AddStakeSectionInfoList,
  AddStakeSectionInfoListItem,
} from "../../../components/AddStakeSectionInfoList"
import { DetailPlate } from "../../../components/DetailPlate/DetailPlate"
import { OnSwitchFormModeFn, StakeForm } from "../types"
import { AlexTokenInput } from "./AlexTokenInput"
import {
  collapseAnimationProps,
  showhideAnimationProps,
} from "./animationHelpers"
import { CycleCountSlider } from "./CycleCountSlider"

export const renderAutoModeForm = (props: {
  gapClassName?: string
  alexToken: TokenInfo
  autoAlexToken: TokenInfo
  form: StakeForm.Auto
  customizeEnabled?: boolean
  onCustomizeEnableChanged?: (enabled: boolean) => void
  onSwitchFormMode?: OnSwitchFormModeFn
  isTokenInputShouldBeErrorState?: SuspenseResource<boolean>
}): JSX.Element => {
  return (
    <>
      <AnimatePresence initial={false}>
        {BlockGroup({
          icon: (
            <motion.div animate={{ opacity: 1 }} transition={{ duration: 0.1 }}>
              <BlockGroupDownArrowIcon />
            </motion.div>
          ),
          firstBlock: (
            <AlexTokenInput
              alexToken={props.alexToken}
              alexTokenCount={props.form.alexTokenCount}
              setAlexTokenCount={props.form.setAlexTokenCount}
              alexTokenCountToUSD={props.form.alexTokenCountToUSD}
              alexBalance={props.form.alexBalance}
              isTokenInputShouldBeErrorState={
                props.isTokenInputShouldBeErrorState
              }
            />
          ),
          secondBlock: (
            <TokenInput
              token={props.autoAlexToken}
              readonly={true}
              value={safeReadResource(props.form.autoAlexTokenCount) ?? null}
              topArea={
                <BlockTopLine className={"flex flex-row items-center"}>
                  Auto &nbsp;
                  <TipIcon
                    tipMaxWidth={320}
                    tip={
                      "Any funds you stake in this pool will be automatically harvested and restaked (compounded) for you"
                    }
                  />
                </BlockTopLine>
              }
              renderBlock={props => (
                <motion.div
                  layoutId={"blockGroupSecond"}
                  transition={{ duration: 0.1 }}
                  className={"w-full"}
                >
                  {renderThinnerBlock({
                    ...props,
                    className: clsx(props.className, "w-full"),
                    children: (
                      <motion.div
                        key={"AutoModeSecondBlockContent"}
                        {...showhideAnimationProps}
                      >
                        {props.children}
                      </motion.div>
                    ),
                  })}
                </motion.div>
              )}
            />
          ),
        })}
      </AnimatePresence>

      <InfoList direction={"column"} listItemDirection={"row-responsive"}>
        <InfoListItem>
          <InfoListItemTitle>Current value</InfoListItemTitle>
          <InfoListItemDetail>
            1 <TokenName token={props.autoAlexToken} /> ={" "}
            <Spensor fallback={"-"}>
              {() => (
                <TokenCount
                  token={props.alexToken}
                  count={readResource(props.form.stakePrice)}
                />
              )}
            </Spensor>{" "}
            <TokenName token={props.alexToken} />
          </InfoListItemDetail>
        </InfoListItem>

        <InfoListItem>
          <InfoListItemTitle>APY</InfoListItemTitle>
          <InfoListItemDetail>
            <Spensor fallback={"-"}>
              {() => <PercentNumber number={readResource(props.form.apy)} />}
            </Spensor>
          </InfoListItemDetail>
        </InfoListItem>

        <InfoListItem>
          <InfoListItemTitle>Staking mode</InfoListItemTitle>
          <InfoListItemDetail>
            <ThumbSwitch
              checked={props.customizeEnabled ?? false}
              onCheckedChange={props.onCustomizeEnableChanged}
              right={<SwitchLabel>Customize</SwitchLabel>}
            />
          </InfoListItemDetail>
        </InfoListItem>
      </InfoList>

      <AnimatePresence initial={false}>
        {props.customizeEnabled && (
          <motion.div {...collapseAnimationProps}>
            <CycleCountSlider
              value={props.form}
              onChange={props.onSwitchFormMode}
            />
          </motion.div>
        )}
      </AnimatePresence>

      <DetailPlate
        gapClassName={props.gapClassName}
        title={"Staking Details"}
        collapseAnimationProps={collapseAnimationProps}
      >
        <motion.div key={"AutoPlateContent"} {...showhideAnimationProps}>
          <div className={clsx("flex flex-col", props.gapClassName)}>
            <Spensor
              fallback={
                <AddStakeSectionInfoList
                  className={"flex items-center justify-center p-6"}
                >
                  <LoadingIndicator />
                </AddStakeSectionInfoList>
              }
            >
              {() => (
                <AddStakeSectionInfoList>
                  <p
                    className={
                      "text-xs leading-4 font-normal text-gray-500 -mb-1"
                    }
                  >
                    Per <TokenName token={props.autoAlexToken} /> price:
                  </p>

                  {readResource(props.form.recentAutoAlexPrices).map(
                    (p, idx) => (
                      <AddStakeSectionInfoListItem
                        key={idx}
                        title={
                          <>
                            Cycle {p.cycleNumber}{" "}
                            {idx === 0 && (
                              <GrayBadge boxClassName={"px-2 py-0.5"}>
                                current price
                              </GrayBadge>
                            )}
                          </>
                        }
                        detail={
                          <>
                            <Spensor fallback={"-"}>
                              {() => (
                                <TokenCount
                                  token={props.alexToken}
                                  count={readResource(p.intrinsicValue)}
                                />
                              )}
                            </Spensor>{" "}
                            <TokenName token={props.alexToken} />
                          </>
                        }
                      />
                    ),
                  )}
                </AddStakeSectionInfoList>
              )}
            </Spensor>

            <NoteParagraph>
              <TokenName token={props.autoAlexToken} /> will automatically
              harvest and re-stake the <TokenName token={props.alexToken} />{" "}
              reward from <TokenName token={props.alexToken} /> staking to
              maximize your APY.{" "}
              <NavLink
                className={"text-blue-600"}
                to={
                  "https://medium.com/alexgobtc/autoalex-the-power-of-compound-interest-eedb2b8535c5"
                }
              >
                Learn more
              </NavLink>
            </NoteParagraph>
          </div>
        </motion.div>
      </DetailPlate>
    </>
  )
}
