import clsx from "clsx"
import { FC, ReactNode } from "react"
import { Button } from "../../../../../components/button/Button"
import { GradientFilledButton } from "../../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import {
  CardInset,
  CardInsetTitle,
  CardNote,
  CardSubTitle,
} from "../../../../../components/Card"
import { TokenName } from "../../../../../components/TokenName"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { OneOrMore } from "../../../../../utils/types"
import {
  PageClockCountdown,
  PageClockCountdownTitleFrame,
} from "../../PageClockCountdown"
import { LotteryTicket } from "../../types"
import { LotteryTicketCardGrid } from "../../_/LotteryTicketCardGrid"
import { PrizeWinningInfo } from "./types"
import { LotteryFinishedIcon } from "./_/LotteryFinishedIcon/LotteryFinishedIcon"
import { RoundResult } from "./_/RoundResult"

export const ActionPanel$Finished: FC<{
  roundNumber: number
  nextRoundStartedAt: Date
  nextRoundStartedAtBlock: number
  currentBlock: number
  prizeToken: TokenInfo
  firstPrize: PrizeWinningInfo
  secondPrize: PrizeWinningInfo
  thirdPrize: PrizeWinningInfo
  burnPrizeTokenCount: number
  rolloverPrizeTokenCount: number
  bottomArea: ReactNode
}> = props => {
  return (
    <CardInset className={"flex flex-col items-center gap-6"}>
      <div className={"flex flex-col items-center gap-4"}>
        <LotteryFinishedIcon />

        <CardInsetTitle>
          Round #{props.roundNumber} has been finalized.
        </CardInsetTitle>
      </div>

      <PageClockCountdownTitleFrame
        title={<CardNote>Next round will start in</CardNote>}
      >
        <PageClockCountdown
          targetBlock={props.nextRoundStartedAtBlock}
          currentBlock={props.currentBlock}
          time={props.nextRoundStartedAt}
        />
      </PageClockCountdownTitleFrame>

      <CardInset className={clsx("w-full overflow-x-auto")}>
        <div className={"flex flex-col gap-4"}>
          <RoundResult
            roundNumber={props.roundNumber}
            token={props.prizeToken}
            firstPrize={props.firstPrize}
            secondPrize={props.secondPrize}
            thirdPrize={props.thirdPrize}
            burnPrizeTokenCount={props.burnPrizeTokenCount}
            rolloverPrizeTokenCount={props.rolloverPrizeTokenCount}
          />
        </div>
      </CardInset>

      {props.bottomArea}
    </CardInset>
  )
}

export const ConnectWalletBottomArea: FC<{
  onConnectWallet: () => void
}> = props => (
  <Button
    className={"w-full"}
    Variant={GradientFilledButton}
    onClick={props.onConnectWallet}
  >
    Connect wallet to view My Lottery Ticket
  </Button>
)

export const LotteryTicketsBottomArea: FC<{
  prizeToken: TokenInfo
  lotteryTickets: OneOrMore<LotteryTicket>
}> = props => (
  <>
    <div className={"flex flex-col gap-2.5 text-center"}>
      <CardSubTitle>My Lottery Ticket</CardSubTitle>
      <CardNote>
        The prize has been drawn!
        <br />
        The <TokenName token={props.prizeToken} /> tokens will be distributed in
        sequence.
        <br />
        One ticket can win multiple prizes.
      </CardNote>
    </div>

    <LotteryTicketCardGrid
      prizeToken={props.prizeToken}
      lotteryTickets={props.lotteryTickets}
    />
  </>
)
