import { gql } from "@urql/core"
import { unwrapResponse } from "clarity-codegen"
import { fromPairs } from "lodash"
import { Observable } from "rxjs"
import { CONTRACT_DEPLOYER } from "../../../../config"
import {
  FetchAPowerDistributionQuery,
  FetchAPowerDistributionQueryVariables,
  FetchAtAlexInfoQuery,
  FetchAtAlexInfoQueryVariables,
} from "../../../../generated/graphql/graphql.generated"
import { asSender } from "../../../../generated/smartContractHelpers/asSender"
import { Currency } from "../../../../utils/alexjs/Currency"
import { gqlQuery } from "../../../../utils/graphqlHelpers"
import { fromUrqlSource } from "../../../../utils/Observable/fromUrqlSource"
import { isNotNull } from "../../../../utils/utils"

export type AutoStakeInfo = {
  currentCycle: number
  apys: number[]
  poolFutureVolumeStats: number[]
  poolToReturnStats: number[]
  poolTotalStakedStats: number[]
}

export function getAtAlexValues(): Observable<AutoStakeInfo> {
  return fromUrqlSource(
    gqlQuery<FetchAtAlexInfoQuery, FetchAtAlexInfoQueryVariables>(gql`
      query FetchAtAlexInfo {
        laplace_latest_auto_alex {
          current_cycle
          apys
          pool_future_volume_stats
          pool_to_return_stats
          pool_total_staked_stats
          pool_user_id
        }
      }
    `),
    result => {
      const value = result.data.laplace_latest_auto_alex[0]
      if (value == null) {
        // not started
        return {
          currentCycle: 0,
          apys: Array(32).fill(0),
          poolFutureVolumeStats: Array(32).fill(0),
          poolToReturnStats: Array(32).fill(0),
          poolTotalStakedStats: Array(32).fill(0),
        }
      }
      return {
        apys: value.apys.map(Number),
        poolToReturnStats: value.pool_to_return_stats
          .map(Number)
          .map(normalize),
        poolTotalStakedStats: value.pool_total_staked_stats
          .map(Number)
          .map(normalize),
        poolFutureVolumeStats: value.pool_future_volume_stats
          .map(Number)
          .map(normalize),
        currentCycle: Number(value.current_cycle),
      }
    },
  )
}

export enum ApowerDistributionStatus {
  Distributed = "Distributed",
  Waiting = "Waiting for distribution",
}

export interface ApowerDistributionCycle {
  cycle: number
  eligibleAutoAlex?: number
  snapshotBlockHeight?: number
  apowerRewards?: number
  status?: ApowerDistributionStatus
}

export function fetchApowerDistribution(
  address: string,
): Observable<ApowerDistributionCycle[]> {
  return fromUrqlSource(
    gqlQuery<
      FetchAPowerDistributionQuery,
      FetchAPowerDistributionQueryVariables
    >(
      gql`
        query FetchAPowerDistribution($address: String!) {
          public_dbt_dim_balance_autoalex_apower_distribution(
            where: {
              address: { _eq: $address }
              cycle_index: { _is_null: false }
            }
            order_by: { cycle_index: desc }
          ) {
            address
            apower_distribution
            block_height
            changed_block_height
            cycle_index
            total_autoalex_balance
          }
          public_dbt_dim_autoalex_apower_cycle_reward_status {
            cycle
            is_cycle_rewards_sent
          }
        }
      `,
      { address },
    ),
    result => {
      const rewardStatus = fromPairs(
        result.data.public_dbt_dim_autoalex_apower_cycle_reward_status
          .map(value =>
            value.cycle
              ? ([value.cycle, Boolean(value.is_cycle_rewards_sent)] as [
                  number,
                  boolean,
                ])
              : null,
          )
          .filter(isNotNull),
      )
      return result.data.public_dbt_dim_balance_autoalex_apower_distribution.map<ApowerDistributionCycle>(
        value => {
          const cycle = value.cycle_index!
          return {
            cycle,
            eligibleAutoAlex: value.total_autoalex_balance ?? undefined,
            apowerRewards: value.apower_distribution ?? undefined,
            snapshotBlockHeight: value.apower_distribution
              ? value.block_height ?? undefined
              : undefined,
            status:
              value.block_height && value.apower_distribution
                ? rewardStatus[cycle]
                  ? ApowerDistributionStatus.Distributed
                  : ApowerDistributionStatus.Waiting
                : undefined,
          }
        },
      )
    },
  )
}

function normalize(input: number): number {
  return input / 1e8
}

export async function fetchAlexPerAtAlexInSwap(): Promise<number> {
  const amountToCalculateFee = 100 * 1e8
  const amountInAtAlex = await asSender(CONTRACT_DEPLOYER)
    .contract("swap-helper-v1-03")
    .func("get-helper")
    .call({
      "token-x": Currency.ATALEX,
      "token-y": Currency.ALEX,
      dx: amountToCalculateFee,
    })
    .then(unwrapResponse)
  return amountInAtAlex / amountToCalculateFee
}
