import { FC, useMemo } from "react"
import { Column, useTable } from "react-table"
import { CardModalContent } from "../../../../components/CardModal/CardModal"
import { LoadingIndicator } from "../../../../components/LoadingIndicator/LoadingIndicator"
import { Spensor } from "../../../../components/Spensor"
import {
  Table,
  TableBodyRow,
  TableHeadRow,
  Tbody,
  Td,
  Th,
  Thead,
} from "../../../../components/table/StyledTable"
import { TokenCount } from "../../../../components/TokenCount"
import { TokenName } from "../../../../components/TokenName"
import { customReactTableRender } from "../../../../utils/customReactTableRender"
import {
  readResource,
  SuspenseResource,
} from "../../../../utils/SuspenseResource"
import { TokenInfoPresets } from "../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { isNotNull } from "../../../../utils/utils"
import { LotteryTicket, LotteryTicketPrizeType } from "../types"
import { ReactComponent as EmptyIcon } from "./empty.svg"

export interface MyHistoryModalContentProps {
  rows: SuspenseResource<RowData[]>
  onClose?: () => void
}

export interface RowData {
  round: number
  myLottery: number
  won: LotteryTicket.Won | null
}

export const MyHistoryModalContent: FC<MyHistoryModalContentProps> = props => (
  <CardModalContent
    width={"80vw"}
    minHeight={"80vh"}
    title="My History"
    onClose={props.onClose}
  >
    <Spensor
      fallback={
        <div className="w-full grow flex items-center justify-center">
          <LoadingIndicator className="mx-auto" size={72} />
        </div>
      }
    >
      {() => <DataTable rows={readResource(props.rows)} />}
    </Spensor>
  </CardModalContent>
)

const DataTable: FC<{ rows: RowData[] }> = props => {
  const tableInstance = useTable({
    columns: useTableSchema(),
    data: props.rows,
  })
  const { getTableProps, headerGroups, rows, getTableBodyProps, prepareRow } =
    tableInstance

  return (
    <div className="flex flex-col flex-1 w-full overflow-auto">
      <Table {...getTableProps()}>
        <Thead>
          {headerGroups.map(headerGroup => (
            <TableHeadRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <Th
                  {...column.getHeaderProps()}
                  className="text-sm leading-5 font-normal text-gray-500"
                >
                  {customReactTableRender(column as any, "Header")}
                </Th>
              ))}
            </TableHeadRow>
          ))}
        </Thead>

        <Tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <TableBodyRow {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                ))}
              </TableBodyRow>
            )
          })}
        </Tbody>
      </Table>
      {props.rows.length === 0 && (
        <div className="grow w-full flex flex-col gap-y-4 items-center justify-center">
          <EmptyIcon />
          <span className="text-base leading-6 font-normal text-gray-600">
            No lottery history found
          </span>
          {/* TODO: Buy Lottery button */}
        </div>
      )}
    </div>
  )
}

const useTableSchema = (): Column<RowData>[] =>
  useMemo(
    () => [
      {
        accessor: "round",
        Header: "Round",
        Cell: props => (
          <span className="flex flex-row items-center gap-x-2.5">
            <span className="text-lg leading-6 font-bold text-gray-200">
              #{props.cell.value}
            </span>
          </span>
        ),
      },
      {
        accessor: "myLottery",
        Header: "My Lottery",
      },
      {
        accessor: "won",
        Header: "Won",
        Cell: ({ value }) => (
          <div className="flex flex-col">
            {value ? transformWonPrizes(value).map(x => <span>{x}</span>) : "-"}
          </div>
        ),
      },
      {
        id: "wonAlex",
        accessor: data =>
          data.won ? (
            <>
              <TokenCount
                count={data.won.wonPrizeTokenCount}
                token={TokenInfoPresets.MockAlex}
              />
              &nbsp;
              <TokenName token={TokenInfoPresets.MockAlex} />
            </>
          ) : (
            "-"
          ),
        Header: (
          <>
            Won&nbsp;
            <TokenName token={TokenInfoPresets.MockAlex} />
          </>
        ),
      },
    ],
    [],
  )

const transformWonPrizes = (won: LotteryTicket.Won): string[] => {
  const prizes = won.prizes
    .map(prize => prize.type)
    .reduce<{ [key in LotteryTicketPrizeType]?: number }>(
      (acc, curr) => ({ ...acc, [curr]: acc[curr] ? acc[curr]! + 1 : 1 }),
      {},
    )
  return [
    LotteryTicketPrizeType.First,
    LotteryTicketPrizeType.Second,
    LotteryTicketPrizeType.Third,
  ]
    .map(prize =>
      prizes[prize] ? `${prizes[prize]} * ${prizesMap[prize]}` : undefined,
    )
    .filter(isNotNull)
}

const prizesMap: { [key in LotteryTicketPrizeType]: string } = {
  [LotteryTicketPrizeType.First]: "1st Prize",
  [LotteryTicketPrizeType.Second]: "2nd Prize",
  [LotteryTicketPrizeType.Third]: "3rd Prize",
}
