import { FC, useRef } from "react"
import { NavLink } from "../../components/NavLink"
import { GradientSecondaryPageTitle } from "../../components/PageStack/GradientSecondaryPageTitle"
import { PageStackPage } from "../../components/PageStack/PageStackPage"
import { WiredTransactionStateDialog } from "../../components/TransactionStateDialog/WiredTransactionStateDialog"
import { ReactComponent as YouTubeIcon } from "./components/assets/youtube.svg"
import { WiredBridgePanel } from "./components/WiredBridgePanel"
import { WiredMetaMaskTransactionStateModal } from "./components/WiredMetaMaskTransactionStateModal"
import WiredWhiteListCheck from "./components/WiredWhiteListCheck"
import { WiredWrapBridgeHistoryContent } from "./components/WiredWrapBridgeHistoryContent"
import { WiredWrapBridgeRoute } from "./components/WiredWrapBridgeRoute"
import { WiredWrapBridgeSignBanner } from "./components/WiredWrapBridgeSignBanner"
import {
  useWrapBridgeStore,
  WrapBridgeStoreProvider,
} from "./store/useWrapBridgeStore"

const WrapBridgePageContent: FC = () => {
  const { wrapForm } = useWrapBridgeStore()
  const historyOrderRef = useRef<HTMLDivElement>(null)

  return (
    <div className="flex flex-col gap-8">
      <WiredWrapBridgeSignBanner historyOrderRef={historyOrderRef} />
      <WiredBridgePanel />
      <WiredWrapBridgeRoute />
      <WiredWrapBridgeHistoryContent headerRef={historyOrderRef} />

      <WiredMetaMaskTransactionStateModal />
      <WiredTransactionStateDialog store={wrapForm.stacksTransactionStore} />
      <WiredWhiteListCheck />
    </div>
  )
}

export const WrapBridgePage: FC = () => {
  return (
    <PageStackPage
      title={
        <>
          <GradientSecondaryPageTitle
            returnIcon={<></>}
            subtitle={
              <span>
                Bridge is a cross-chain bridge between USDC (ERC20) and xUSD
                <br />
                designed for high performance and speed
              </span>
            }
          >
            Bridge
          </GradientSecondaryPageTitle>
          <NavLink
            className={"text-sm text-white flex-0 flex items-center gap-2"}
            to={
              "https://medium.com/alexgobtc/what-is-stacks-bridge-29be1fc71ede"
            }
          >
            <YouTubeIcon />
            How does the Bridge work?
          </NavLink>
        </>
      }
    >
      <WrapBridgeStoreProvider>
        <WrapBridgePageContent />
      </WrapBridgeStoreProvider>
    </PageStackPage>
  )
}
