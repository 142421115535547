import React, { FC } from "react"
import { Card, CardDivider } from "../../../../components/Card"
import { GradientText } from "../../../../components/GradientText"

export const LockdropMyStatusContainer: FC<{
  children: React.ReactNode
}> = props => {
  return (
    <Card boxClassName="p-6 flex flex-col gap-y-4">
      <div>
        <GradientText
          className="text-2xl leading-8 font-medium"
          gradientStyle={
            "linear-gradient(90.83deg, #FFFFFF 0.71%, #FBBF24 99.29%)"
          }
        >
          My Status
        </GradientText>
      </div>

      <CardDivider />
      <div className="flex flex-col gap-y-8">{props.children}</div>
    </Card>
  )
}
