import { FunctionDescriptor } from "clarity-codegen"
import { FC, useState } from "react"
import { GreenBadge } from "../../components/Badge"
import { GradientFilledButton } from "../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { OpacityButton } from "../../components/button/variants/OpacityButton"
import { Dropdown, DropdownItem } from "../../components/Dropdown"
import { PageStackPage } from "../../components/PageStack/PageStackPage"
import { PrimaryPageTitle } from "../../components/PageStack/PrimaryPageTitle"
import { WiredTransactionStateDialog } from "../../components/TransactionStateDialog/WiredTransactionStateDialog"
import { AlexContracts } from "../../generated/smartContract/contracts_Alex"
import { asSender } from "../../generated/smartContractHelpers/asSender"
import { useAuthStore } from "../../stores/authStore/useAuthStore"
import { ConfirmTransactionStore } from "../../stores/confirmTransactionDialogStore/ConfirmTransactionStore"
import { useCreation } from "../../utils/reactHelpers/useCreation"
import { withClassName } from "../../utils/reactHelpers/withClassName"
import { ConnectWallet } from "./AdminPage"

const Input = withClassName("flex p-2 text-lg", "input")

const ParamsInput: FC<{
  descriptor: FunctionDescriptor
  onSubmit: (params: any) => void
}> = props => {
  const [inputs, setInputs] = useState<{ [key: string]: string }>(() => {
    if (props.descriptor.mode === "mapEntry") {
      return {}
    }
    const urlSearchParams = new URLSearchParams(window.location.search)
    const result = {} as { [key: string]: string }
    for (const input of props.descriptor.input) {
      result[input.name] = decodeURIComponent(
        urlSearchParams.get("args_" + input.name) ?? "",
      )
    }
    return result
  })
  if (props.descriptor.mode === "mapEntry") {
    return <div>Map Entry is Not supported at the moment</div>
  }
  return (
    <div className="flex flex-col gap-3 mt-4 items-start">
      {props.descriptor.input.map(input => (
        <div key={input.name} className="flex flex-row items-center gap-2">
          <label className="text-xl">{input.name}: </label>
          <Input
            value={inputs[input.name] ?? ""}
            onChange={e =>
              setInputs(p => ({ ...p, [input.name]: e.target.value }))
            }
          />
        </div>
      ))}
      <GradientFilledButton
        onClick={() => {
          const result = {} as any
          Object.keys(inputs).forEach(key => {
            try {
              result[key] = JSON.parse(inputs[key]!)
            } catch (e) {
              result[key] = inputs[key]
            }
          })
          props.onSubmit(result)
        }}
        className="mt-4"
      >
        Run
      </GradientFilledButton>
    </div>
  )
}

const Content: FC = () => {
  const authStore = useAuthStore()
  const txStore = useCreation(() => new ConfirmTransactionStore(), [])
  const urlSearchParams = new URLSearchParams(window.location.search)
  const [contract, setContract] = useState<string | undefined>(
    urlSearchParams.get("contract") ?? undefined,
  )
  const [func, setFunc] = useState<string | undefined>(
    urlSearchParams.get("function") ?? undefined,
  )
  return (
    <div>
      <div className="flex flex-row gap-3 items-center">
        <Dropdown
          triggerMethod="click"
          trigger={
            <OpacityButton>{contract ?? "Select Contracts"}</OpacityButton>
          }
        >
          {Object.keys(AlexContracts).map(t => (
            <DropdownItem
              key={t}
              onClick={() => {
                setFunc(undefined)
                setContract(t)
              }}
            >
              {t}
            </DropdownItem>
          ))}
        </Dropdown>
        {contract && (
          <Dropdown
            triggerMethod="click"
            trigger={<OpacityButton>{func ?? "Select Function"}</OpacityButton>}
          >
            {Object.keys((AlexContracts as any)[contract]).map(t => (
              <DropdownItem key={t} onClick={() => setFunc(t)}>
                {t}
              </DropdownItem>
            ))}
          </Dropdown>
        )}
        {contract && func && (
          <GreenBadge>{(AlexContracts as any)[contract][func].mode}</GreenBadge>
        )}
      </div>
      {contract && func && (
        <ParamsInput
          descriptor={(AlexContracts as any)[contract][func]}
          onSubmit={async params => {
            const result = await (asSender(authStore.stxAddress$) as any)
              .contract(contract)
              .func(func)
              .call(params)
            if ((AlexContracts as any)[contract][func].mode === "public") {
              txStore.successRunning(result.txId)
            } else {
              alert(JSON.stringify(result, null, 2))
            }
          }}
        />
      )}
      <WiredTransactionStateDialog store={txStore} />
    </div>
  )
}

const AdminPage: FC = () => {
  const authStore = useAuthStore()
  if (!authStore.isWalletConnected) {
    return <ConnectWallet />
  }
  return (
    <PageStackPage
      title={
        <PrimaryPageTitle subtitle="This page can be dangerous, use with caution">
          Sandbox
        </PrimaryPageTitle>
      }
    >
      <Content />
    </PageStackPage>
  )
}

export default AdminPage
