import clsx from "clsx"
import { FC } from "react"
import { Card, CardTitle } from "../../../components/Card"
import { PercentNumber } from "../../../components/PercentNumber"
import { TipIcon } from "../../../components/TipIcon/TipIcon"
import { TokenName } from "../../../components/TokenName"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { withClassName } from "../../../utils/reactHelpers/withClassName"
import { LiquidityNumber } from "../../Farm/components/LiquidityNumber"
import { LiquidityInfo } from "../store/detail/PoolDetailStore.services"

const Container = withClassName(
  "border border-gray-700 rounded-lg p-4 flex flex-col gap-2",
  "div",
)
const Title = withClassName("text-sm text-gray-200 text-opacity-50", "p")
const Detail = withClassName("text-2xl font-bold break-all", "p")

const PoolStatsInfo: FC<
  LiquidityInfo & { className?: string; tokenX: TokenInfo; tokenY: TokenInfo }
> = props => {
  return (
    <Card className={clsx("flex flex-col gap-5", props.className)}>
      <CardTitle>Pool Info</CardTitle>
      <div className="flex-1 grid grid-cols-1 sm:grid-cols-2 grid-rows-1 sm:grid-rows-2 gap-3">
        <Container>
          <Title>Liquidity</Title>
          <Detail>
            $ <LiquidityNumber count={props.liquidity} />
            {props.tokenXPercentage != null && (
              <div className="text-sm font-normal">
                <TokenName token={props.tokenX} />
                {": "}
                <PercentNumber number={props.tokenXPercentage} />
              </div>
            )}
            {props.tokenYPercentage != null && (
              <div className="text-sm font-normal">
                <TokenName token={props.tokenY} />
                {": "}
                <PercentNumber number={props.tokenYPercentage} />
              </div>
            )}
          </Detail>
        </Container>
        <Container>
          <Title>Volume 24h</Title>
          <Detail>
            $ <LiquidityNumber count={props.volume24h} />
          </Detail>
        </Container>
        <Container>
          <div className="flex flex-row items-center gap-2">
            <Title>Fees</Title>
            <TipIcon
              tip={
                "By adding liquidity you'll earn fees from all trades on this pair proportional to your share of the pool.\nFees are added to the pool, accrue in real time and can be claimed by withdrawing your liquidity."
              }
            />
          </div>
          <Detail>
            {props.apr == null ? "N/A" : <PercentNumber number={props.apr} />}
          </Detail>
        </Container>
        <Container>
          <Title>Volume 7d</Title>
          <Detail>
            $ <LiquidityNumber count={props.volume7d} />
          </Detail>
        </Container>
      </div>
    </Card>
  )
}

export default PoolStatsInfo
