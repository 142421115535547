import { FC } from "react"
import { Dropdown, DropdownItem } from "../../../../../components/Dropdown"
import { WrapBridgeNetwork } from "../../../types/types"
import { ReactComponent as DownArrow } from "./assets/arrow.svg"

export const WrapBridgeNetworkComponent: FC<{
  network: WrapBridgeNetwork
  showArrow?: boolean
}> = props => {
  return (
    <div className="flex h-9 items-center gap-1 bg-white bg-opacity-5 pl-1 pr-2 rounded-lg">
      <img
        className={"block w-8 h-8 object-center"}
        alt={`Network ${props.network.name} icon`}
        src={props.network.icon}
      />
      <span className="text-gray-200 font-medium text-sm">
        {props.network.name}
      </span>
      {props.showArrow && <DownArrow />}
    </div>
  )
}

export const WrapBridgeNetworkSelectComponent: FC<{
  network: WrapBridgeNetwork
  availableNetworks: WrapBridgeNetwork[]
  onChange: (network: WrapBridgeNetwork) => void
}> = props => {
  return (
    <Dropdown
      trigger={
        <WrapBridgeNetworkComponent network={props.network} showArrow={true} />
      }
    >
      {props.availableNetworks.map(n => (
        <DropdownItem
          key={n.id}
          selected={n.id === props.network.id}
          onClick={() => props.onChange(n)}
          contentContainerClassName="flex items-center gap-2"
        >
          <img
            className={"block w-8 h-8 object-center"}
            alt={`Network ${n.name} icon`}
            src={n.icon}
          />
          <span className="text-gray-200 font-medium text-sm">{n.name}</span>
        </DropdownItem>
      ))}
    </Dropdown>
  )
}
