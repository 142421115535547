import { FC } from "react"
import { CardDivider, CardInset } from "../../../../components/Card"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../components/InfoList"
import { NoteParagraph } from "../../../../components/NoteParagraph/NoteParagraph"
import { TokenName } from "../../../../components/TokenName"
import { TokenInfo } from "../../../../utils/models/TokenInfo"

export const MyLotteryPanel$Joined: FC<{
  rewardToken: TokenInfo
  lotteryTicketCount: number
}> = props => {
  return (
    <>
      <CardInset className={"flex flex-col gap-6"}>
        <InfoList
          className={"m-auto"}
          direction={"row"}
          listItemDirection={"column"}
        >
          <InfoListItem className={"gap-2 items-center"}>
            <InfoListItemDetail className={"text-5xl"}>
              {props.lotteryTicketCount}
            </InfoListItemDetail>
            <InfoListItemTitle>My Lottery Tickets</InfoListItemTitle>
          </InfoListItem>
        </InfoList>

        <CardDivider className={"w-full"} />

        <NoteParagraph>
          Winning tickets will have <TokenName token={props.rewardToken} />{" "}
          automatically sent to the wallet
        </NoteParagraph>
      </CardInset>
    </>
  )
}
