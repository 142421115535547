import clsx from "clsx"
import { FC } from "react"
import { PercentNumber } from "../../../components/PercentNumber"
import { TokenCount } from "../../../components/TokenCount"
import { TokenIcon } from "../../../components/TokenIcon"
import { TokenName } from "../../../components/TokenName"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { TokenInfoPresets } from "../../../utils/TokenInfoPresets/TokenInfoPresets"
import { VerticalInfoListContainer, VerticalInfoListItem } from "./InfoList"
import { TokenIDOSuccessInfo } from "./types"

export interface IDOSucceedNumbersListProps {
  className?: string
  idoToken: TokenInfo
  tokenIDOSuccessSummary: TokenIDOSuccessInfo
}

export const IDOSucceedNumbersList: FC<IDOSucceedNumbersListProps> = props => {
  return (
    <VerticalInfoListContainer className={clsx(props.className)}>
      <VerticalInfoListItem
        title="Filled"
        detail={
          <PercentNumber number={props.tokenIDOSuccessSummary.fillRate} />
        }
      />

      <VerticalInfoListItem
        title="Total Allocation"
        detail={
          <>
            <TokenIcon className={"mr-2"} token={props.idoToken} size={24} />
            <TokenCount
              token={props.idoToken}
              count={props.tokenIDOSuccessSummary.totalAllocation}
            />
            &nbsp;
            <TokenName token={props.idoToken} />
          </>
        }
      />

      <VerticalInfoListItem
        title="Total Lottery"
        detail={
          <>
            <TokenCount
              token={TokenInfoPresets.IDOLotteryTicket}
              count={props.tokenIDOSuccessSummary.currentActivation}
            />
            &nbsp; Tickets
          </>
        }
      />
    </VerticalInfoListContainer>
  )
}
