import { unwrapResponse } from "clarity-codegen"
import { memoize } from "lodash"
import { computed, makeObservable } from "mobx"
import { computedFn, createTransformer } from "mobx-utils"
import { CONTRACT_DEPLOYER } from "../../config"
import { asSender } from "../../generated/smartContractHelpers/asSender"
import { AMMSwapPool } from "../../utils/alexjs/AMMSwapPool"
import { Currency, WrappedCurrency } from "../../utils/alexjs/Currency"
import {
  FungibleToken,
  getAssetIdentifierByCurrency,
  isFWPToken,
  isYTPToken,
  LiquidityPoolToken,
  liquidityTokenPairs,
} from "../../utils/alexjs/currencyHelpers"
import { TokenInfo } from "../../utils/models/TokenInfo"
import AuthStore from "../authStore/AuthStore"
import { LazyValue } from "../LazyValue/LazyValue"
import { pMemoizeDecorator } from "../LazyValue/pMemoizeDecorator"
import {
  fetchLatestPrices,
  getDxDyOnFixedWeightPool,
  getTokensFromCMS,
} from "./CurrencyStore.service"
import safeUnwrap = WrappedCurrency.safeUnwrap

class CurrencyStore {
  constructor(
    readonly authStore: Pick<AuthStore, "stxAddress$" | "currentBlockHeight$">,
  ) {
    makeObservable(this)
  }

  private _prices = new LazyValue(
    () => null,
    () => fetchLatestPrices(),
  )

  private prices$ = createTransformer((currency: Currency): number => {
    if (!(currency in this._prices.value$)) {
      console.error(new Error(`Currency ${currency} not found in prices`))
      return 1
    }
    return this._prices.value$[currency]!
  })

  private _fwpPriceBreakdown = memoize(
    (currency: LiquidityPoolToken) =>
      new LazyValue(
        () => [currency, this.authStore.currentBlockHeight$] as const,
        ([currency]) => getDxDyOnFixedWeightPool(currency),
        { decorator: pMemoizeDecorator({ persistKey: `fwp-dx-dy` }) },
      ),
  )

  fetchPoolBreakdown$ = createTransformer((currency: LiquidityPoolToken) => {
    return this._fwpPriceBreakdown(currency).value$
  })

  getPrice$ = createTransformer((currency: FungibleToken): number => {
    if (isFWPToken(currency) || isYTPToken(currency)) {
      const { y, x, dx, dy } = this.fetchPoolBreakdown$(currency)
      return this.prices$(x) * dx + this.prices$(y) * dy
    }
    return this.prices$(currency)
  })

  private allTokenInfoFromCMS = new LazyValue(() => null, getTokensFromCMS)

  @computed get allAssetIdentifiers$(): string[] {
    return this.allTokenInfoFromCMS.value$.flatMap(t =>
      getAssetIdentifierByCurrency(safeUnwrap(t.id as Currency)),
    )
  }

  getTokenInfoForAsset$ = createTransformer(
    (
      assetIdentifierOrAssetContractAddress: string,
    ): Omit<TokenInfo, "id"> & { id?: string } => {
      const foundToken = this.allTokenInfoFromCMS.value$.find(
        t =>
          getAssetIdentifierByCurrency(safeUnwrap(t.id as Currency)) ===
          assetIdentifierOrAssetContractAddress,
      )
      if (foundToken == null) {
        throw new Error(
          `Unable to find token for asset ${assetIdentifierOrAssetContractAddress}`,
        )
      }
      return foundToken
    },
    { keepAlive: true },
  )

  getTokenInfo$ = createTransformer(
    (currency: Currency): TokenInfo => {
      return {
        ...this.getTokenInfoForAsset$(
          getAssetIdentifierByCurrency(WrappedCurrency.safeUnwrap(currency)),
        ),
        id: currency,
      }
    },
    { keepAlive: true },
  )

  @computed get allTokenInfos$(): { [key in Currency]?: TokenInfo } {
    return Object.keys(Currency)
      .map(key => this.getTokenInfo$((Currency as any)[key]))
      .reduce<{ [key in Currency]?: TokenInfo }>((acc, curr) => {
        if (curr.id) {
          return {
            ...acc,
            [curr.id]: curr,
          }
        }
        return acc
      }, {})
  }

  ammPoolInfo = computedFn(
    (
      tokenX: AMMSwapPool.SwapTokens,
      tokenY: AMMSwapPool.SwapTokens,
      factor: number,
    ) =>
      new LazyValue(
        () => [tokenX, tokenY] as const,
        async ([tokenX, tokenY]) =>
          asSender(CONTRACT_DEPLOYER)
            .contract("amm-swap-pool")
            .func("get-pool-details")
            .call({
              factor,
              "token-x": tokenX,
              "token-y": tokenY,
            })
            .then(unwrapResponse),
      ),
  )

  ammPoolIdFromCurrency = createTransformer(
    (currency: AMMSwapPool.PoolTokens) => {
      const [tokenX, tokenY] = liquidityTokenPairs(currency)
      return this.ammPoolInfo(
        tokenX as AMMSwapPool.SwapTokens,
        tokenY as AMMSwapPool.SwapTokens,
        AMMSwapPool.getFactor(currency),
      ).value$["pool-id"]
    },
    { keepAlive: true },
  )
}

export default CurrencyStore
