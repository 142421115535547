import { FC, ReactNode } from "react"
import { readResource, SuspenseResource } from "../../utils/SuspenseResource"
import { Spensor } from "../Spensor"
import { TimeCountdownText } from "./TimeCountdownText"

export const BlockTimeCountdownText: FC<{
  targetBlock: number
  currentBlock: SuspenseResource<number>
  time: SuspenseResource<Date>
  estimateSuffix?: ReactNode
  format?: (duration: Duration) => string
}> = props => (
  <Spensor>
    {() => {
      const targetBlock = props.targetBlock
      const currentBlock = readResource(props.currentBlock)

      if (targetBlock - currentBlock > 1) {
        const time = readResource(props.time)
        return (
          <>
            <TimeCountdownText time={time} format={props.format} />
            {props.estimateSuffix}
          </>
        )
      }
      return <>1 Block Remaining</>
    }}
  </Spensor>
)
