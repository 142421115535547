import clsx from "clsx"
import { FC, useState } from "react"
import { WhiteFilledButton } from "../../../../../components/button/variants/WhiteFilledButton"
import { SwitchLabel, ThumbSwitch } from "../../../../../components/ThumbSwitch"
import { TipIcon } from "../../../../../components/TipIcon/TipIcon"
import { TokenCount } from "../../../../../components/TokenCount"
import { TokenIcon } from "../../../../../components/TokenIcon"
import { TokenName } from "../../../../../components/TokenName"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { TokenInfoPresets } from "../../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { HarvestIcon } from "../../HarvestIcon"

export interface HarvestButtonProps {
  className?: string
  alexToken: TokenInfo
  autoAlexToken: TokenInfo
  alexTokenCount: number
  apowerTokenCount: number
  onClick?: (autoMint: boolean) => void
}

export const HarvestButton: FC<HarvestButtonProps> = props => {
  const [autoMint, setAutoMint] = useState(false)

  const canHarvest = props.alexTokenCount > 0 || props.apowerTokenCount > 0

  return (
    <>
      <WhiteFilledButton
        disabled={!canHarvest}
        className={clsx(
          props.className,
          "flex flex-row flex-wrap gap-x-6 items-center justify-center",
        )}
        onClick={() => props.onClick?.(autoMint)}
      >
        <span className={"text-base text-gray-900"}>
          <HarvestIcon className={"inline-block"} /> Harvest
        </span>

        {canHarvest && (
          <span className={"flex flex-row gap-2.5 text-sm text-blue-600"}>
            <span className={"block"}>
              <TokenIcon
                className={"inline-block align-text-bottom"}
                token={props.alexToken}
                size={16}
              />{" "}
              <TokenCount
                token={props.alexToken}
                count={props.alexTokenCount}
              />
            </span>

            <span className={"block"}>
              <TokenIcon
                className={"inline-block align-text-bottom"}
                token={TokenInfoPresets.APower}
                size={16}
              />{" "}
              <TokenCount
                token={TokenInfoPresets.APower}
                count={props.apowerTokenCount}
              />
            </span>
          </span>
        )}
      </WhiteFilledButton>

      {canHarvest && (
        <AutoMintToggle
          alexToken={props.alexToken}
          autoAlexToken={props.autoAlexToken}
          enabled={autoMint}
          onChange={setAutoMint}
        />
      )}
    </>
  )
}

const AutoMintToggle: FC<{
  alexToken: TokenInfo
  autoAlexToken: TokenInfo
  enabled: boolean
  onChange?: (enabled: boolean) => void
}> = props => (
  <div className={"flex justify-center"}>
    <ThumbSwitch
      checked={props.enabled}
      onCheckedChange={props.onChange}
      right={
        <SwitchLabel>
          Auto Stake{" "}
          <TipIcon
            className={"inline-block align-text-bottom"}
            tip={
              <>
                Auto stake allows user to stake their manual{" "}
                <TokenName token={props.alexToken} /> staking rewards into{" "}
                <TokenName token={props.autoAlexToken} /> in a single{" "}
                transaction
              </>
            }
          />
        </SwitchLabel>
      }
    />
  </div>
)
