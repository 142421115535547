import { FC } from "react"
import {
  CardInset,
  CardInsetDescription,
  CardInsetDivider,
  CardInsetTitle,
} from "../../../../../components/Card"
import { NoteParagraph } from "../../../../../components/NoteParagraph/NoteParagraph"
import { TokenName } from "../../../../../components/TokenName"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { TokenInfoPresets } from "../../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { IDOSucceedNumbersList } from "../../IDOSucceedNumbersList"
import { IDOSuccessIcon } from "../../IDOSuccessIcon/IDOSuccessIcon"
import { TokenIDOSuccessInfo } from "../../types"
import { LotteryTicketCard } from "./LotteryTicketCard/LotteryTicketCard"
import { LotteryTicket } from "./LotteryTicketCard/types"

export const ActionSectionContent$FinishedSucceed: FC<{
  idoToken: TokenInfo
  priceToken: TokenInfo
  lotteryTickets: LotteryTicket[]
  tokenIDOSuccessSummary: TokenIDOSuccessInfo
}> = props => {
  return (
    <CardInset className={"flex flex-col items-center gap-6"}>
      <div className={"flex flex-col items-center gap-4"}>
        <IDOSuccessIcon />

        <CardInsetTitle>
          <TokenName token={props.idoToken} /> Token launch has been finalized
        </CardInsetTitle>
      </div>

      <IDOSucceedNumbersList
        className={"w-full px-4 md:px-24 py-6"}
        idoToken={props.idoToken}
        tokenIDOSuccessSummary={props.tokenIDOSuccessSummary}
      />

      {props.lotteryTickets.length > 0 && (
        <>
          <CardInsetDivider />

          <div className={"flex flex-col items-center gap-2.5"}>
            <CardInsetTitle>Your Lottery Ticket</CardInsetTitle>

            <CardInsetDescription className={"text-center"}>
              The prizes have been drawn!
              <br />
              The winning tokens will be packaged and distributed in sequence.
            </CardInsetDescription>
          </div>

          <div className={"flex flex-col gap-4"}>
            <ul className={"flex flex-wrap justify-center gap-2.5"}>
              {props.lotteryTickets.map((t, idx) => (
                <li key={idx}>
                  <LotteryTicketCard
                    idoToken={props.idoToken}
                    priceToken={props.priceToken}
                    lotteryTicket={t}
                  />
                </li>
              ))}
            </ul>

            <NoteParagraph>
              The <TokenName token={props.priceToken} /> submitting with your
              lottery ticket will be refunded if you draw a losing ticket, and
              will be swapped for IDO tokens if you win. The{" "}
              <TokenName token={TokenInfoPresets.APower} /> submitted is burnt
              either win or lose.
            </NoteParagraph>
          </div>
        </>
      )}
    </CardInset>
  )
}
