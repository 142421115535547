import { computed, makeObservable } from "mobx"
import { ESTIMATED_BLOCK_DURATION } from "../../../config"
import AuthStore from "../../../stores/authStore/AuthStore"
import { ChainStore } from "../../../stores/chainStore/ChainStore"
import { assertNever } from "../../../utils/types"
import type LaunchPadContractStore from "./LaunchPadContractStore"
import { LaunchingStatus } from "./LaunchPadContractStore"

export class IDOStepsModule {
  constructor(
    private store: LaunchPadContractStore,
    private authStore: Pick<AuthStore, "stxAddress$" | "currentBlockHeight$">,
    private chainStore: Pick<
      ChainStore,
      "currentBlockHeight$" | "estimatedDateForBlock$"
    >,
  ) {
    makeObservable(this)
  }

  @computed private get singleBlockDuration$(): number {
    return ESTIMATED_BLOCK_DURATION
  }

  @computed get currentStepNumber$(): number {
    switch (this.currentStatus$) {
      case LaunchingStatus.Upcoming:
        return 1
      case LaunchingStatus.Registration:
        return 2
      case LaunchingStatus.Claim:
        return 3
      case LaunchingStatus.Finished:
        return 4
      default:
        assertNever(this.currentStatus$)
    }
  }

  @computed get registrationStartedAtBlock$(): number {
    return this.store.detail$["registration-start-height"]
  }

  @computed get registrationStartedAt$(): Date {
    return this.chainStore.estimatedDateForBlock$(
      this.registrationStartedAtBlock$,
    )
  }

  @computed get claimStartedAtBlock$(): number {
    return this.store.detail$["registration-end-height"]
  }

  @computed get claimStartedAt$(): Date {
    return this.chainStore.estimatedDateForBlock$(this.claimStartedAtBlock$)
  }

  @computed get willFinishedAtBlock$(): number {
    return this.store.detail$["claim-end-height"]
  }

  @computed get willFinishedAt$(): Date {
    const willStartAfterMS =
      (this.willFinishedAtBlock$ - this.store.currentBlock$) *
      this.singleBlockDuration$
    return new Date(Date.now() + willStartAfterMS)
  }

  @computed get currentStatus$(): LaunchingStatus {
    if (this.store.currentBlock$ < this.registrationStartedAtBlock$) {
      return LaunchingStatus.Upcoming
    }
    if (this.store.currentBlock$ <= this.claimStartedAtBlock$ + 1) {
      return LaunchingStatus.Registration
    }
    if (
      this.store.currentBlock$ < this.store.detail$["claim-end-height"] &&
      this.store.activationProgress$ >= 1
    ) {
      return LaunchingStatus.Claim
    }
    return LaunchingStatus.Finished
  }

  @computed get currentStatusEndedAtBlock$(): null | number {
    if (this.currentStatus$ === LaunchingStatus.Upcoming) {
      return this.registrationStartedAtBlock$
    }
    if (this.currentStatus$ === LaunchingStatus.Registration) {
      return this.claimStartedAtBlock$
    }
    if (this.currentStatus$ === LaunchingStatus.Claim) {
      return this.willFinishedAtBlock$
    }
    return null
  }

  @computed get currentStatusEndedAt$(): null | Date {
    if (this.currentStatus$ === LaunchingStatus.Upcoming) {
      return this.registrationStartedAt$
    }
    if (this.currentStatus$ === LaunchingStatus.Registration) {
      return this.claimStartedAt$
    }
    if (this.currentStatus$ === LaunchingStatus.Claim) {
      return this.willFinishedAt$
    }
    return null
  }

  @computed get idoStepsPlan$(): Record<
    | LaunchingStatus.Registration
    | LaunchingStatus.Claim
    | LaunchingStatus.Finished,
    Date
  > {
    return {
      [LaunchingStatus.Registration]: this.registrationStartedAt$,
      [LaunchingStatus.Claim]: this.claimStartedAt$,
      [LaunchingStatus.Finished]: this.willFinishedAt$,
    }
  }
}
