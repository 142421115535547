import {
  booleanT,
  bufferT,
  defineContract,
  listT,
  numberT,
  optionalT,
  principalT,
  responseSimpleT,
  stringT,
  tupleT,
} from "../smartContractHelpers/codegenImport"

export const age009TokenLock = defineContract({
  "age009-token-lock": {
    callback: {
      input: [
        { name: "sender", type: principalT },
        { name: "memo", type: bufferT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "get-tokens": {
      input: [
        { name: "extension", type: principalT },
        { name: "vesting-id", type: numberT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "get-tokens-many": {
      input: [
        { name: "extension", type: principalT },
        { name: "vesting-ids", type: listT(numberT) },
      ],
      output: responseSimpleT(listT(responseSimpleT(booleanT))),
      mode: "public",
    },
    "is-dao-or-extension": {
      input: [],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-recipient": {
      input: [
        { name: "address", type: principalT },
        { name: "name", type: stringT },
      ],
      output: responseSimpleT(numberT),
      mode: "public",
    },
    "set-vesting-schedule": {
      input: [
        { name: "recipient-id", type: numberT },
        { name: "vesting-id", type: numberT },
        { name: "vesting-timestamp", type: numberT },
        { name: "amount", type: numberT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-vesting-schedule-many": {
      input: [
        {
          name: "items",
          type: listT(
            tupleT({
              amount: numberT,
              "recipient-id": numberT,
              "vesting-id": numberT,
              "vesting-timestamp": numberT,
            }),
          ),
        },
      ],
      output: responseSimpleT(listT(responseSimpleT(booleanT))),
      mode: "public",
    },
    "byte-to-uint": {
      input: [{ name: "byte", type: bufferT }],
      output: numberT,
      mode: "readonly",
    },
    "get-recipient-id-or-default": {
      input: [{ name: "address", type: principalT }],
      output: numberT,
      mode: "readonly",
    },
    "get-recipient-info-or-fail": {
      input: [{ name: "recipient-id", type: numberT }],
      output: responseSimpleT(tupleT({ address: principalT, name: stringT })),
      mode: "readonly",
    },
    "get-tokens-to-vest": { input: [], output: numberT, mode: "readonly" },
    "get-vesting-schedule-or-fail": {
      input: [
        { name: "recipient-id", type: numberT },
        { name: "vesting-id", type: numberT },
      ],
      output: responseSimpleT(
        tupleT({ amount: numberT, "vesting-timestamp": numberT }),
      ),
      mode: "readonly",
    },
    "uint-to-buff-be": {
      input: [{ name: "n", type: numberT }],
      output: bufferT,
      mode: "readonly",
    },
    "address-to-id": {
      input: principalT,
      output: optionalT(numberT),
      mode: "mapEntry",
    },
    recipients: {
      input: numberT,
      output: optionalT(tupleT({ address: principalT, name: stringT })),
      mode: "mapEntry",
    },
    "vesting-schedule": {
      input: tupleT({ "recipient-id": numberT, "vesting-id": numberT }),
      output: optionalT(
        tupleT({ amount: numberT, "vesting-timestamp": numberT }),
      ),
      mode: "mapEntry",
    },
  },
} as const)
