import { StringOnly } from "./types"

const neverUnknownSymbol = Symbol("NeverUnknown")
// prettier-ignore
type ReplaceUnknown<T, Replacement = never> =
  unknown extends T ?
    T extends typeof neverUnknownSymbol ?
      T :
    Replacement :
  T
// type ReplaceUnknownTest1 = ReplaceUnknown<any, 1>
// type ReplaceUnknownTest2 = ReplaceUnknown<unknown, 1>
// type ReplaceUnknownTest3 = ReplaceUnknown<void, 1>
// type ReplaceUnknownTest4 = ReplaceUnknown<never, 1>
// type ReplaceUnknownTest5 = ReplaceUnknown<undefined, 1>
// type ReplaceUnknownTest6 = ReplaceUnknown<null, 1>
// type ReplaceUnknownTest7 = ReplaceUnknown<1, 1>

type UnnecessaryFields<T> = {
  [K in keyof T]-?: [ReplaceUnknown<T[K]>] extends [never] ? K : never
}[keyof T]
type OptionalNeverValueFields<T> = Omit<T, UnnecessaryFields<T>>

// prettier-ignore
type PickField<Field extends string, T> =
  T extends { [K in Field]?: infer R } ? R : never

type PickResource<
  OperationSchemas,
  Url extends string,
> = Url extends keyof OperationSchemas ? OperationSchemas[Url] : never

type PickOperation<
  Method extends string,
  Resource,
> = Method extends keyof Resource ? Resource[Method] : never

type AnyOperationRef = { url: string; method: string }
type OperationInfo<
  OperationSchemas,
  OperationRef extends AnyOperationRef,
> = PickOperation<
  OperationRef["method"],
  PickResource<OperationSchemas, OperationRef["url"]>
>

type OperationResponse<T> = Partial<Record<200 | 201 | 202 | 203 | 204, T>>

type OperationMethodMap<
  OperationSchemas,
  OperationRefMap extends Record<string, AnyOperationRef>,
> = {
  [K in keyof OperationRefMap]: {
    request: OptionalNeverValueFields<{
      header: PickField<
        "header",
        PickField<
          "parameters",
          OperationInfo<OperationSchemas, OperationRefMap[K]>
        >
      >
      path: PickField<
        "path",
        PickField<
          "parameters",
          OperationInfo<OperationSchemas, OperationRefMap[K]>
        >
      >
      query: PickField<
        "query",
        PickField<
          "parameters",
          OperationInfo<OperationSchemas, OperationRefMap[K]>
        >
      >
      body: PickField<
        "requestBody",
        OperationInfo<OperationSchemas, OperationRefMap[K]>
      > extends { content: { "application/json": infer R } }
        ? R
        : never
    }>
    response: PickField<
      "responses",
      OperationInfo<OperationSchemas, OperationRefMap[K]>
    > extends OperationResponse<{ content: { "application/json": infer R } }>
      ? R
      : never
  }
}

interface AnyOperationMethodRequest {
  header?: Record<string, unknown>
  path?: Record<string, unknown>
  query?: Record<string, unknown>
  body?: Record<string, unknown>
}

// export type SendRequestParams<
//   OperationSchemas,
//   OperationRefMap extends Record<string, AnyOperationRef>,
//   OperationId
//   > = <OperationId extends keyof OperationRefMap>(
//   operationId: OperationId extends keyof OperationRefMap ? OperationId : never,
//   request: OperationMethodMap<
//     OperationSchemas,
//     OperationRefMap
//     >[typeof operationId]["request"],
// )
export type SendRequestFn<
  OperationSchemas,
  OperationRefMap extends Record<string, AnyOperationRef>,
> = <OperationId extends keyof OperationRefMap>(
  operationId: OperationId extends StringOnly<keyof OperationRefMap>
    ? OperationId
    : never,
  request: OperationMethodMap<
    OperationSchemas,
    OperationRefMap
  >[typeof operationId]["request"],
) => Promise<{
  data: OperationMethodMap<
    OperationSchemas,
    OperationRefMap
  >[typeof operationId]["response"]
}>
export const createSendRequest = <
  OperationSchemas,
  OperationRefMap extends Record<string, AnyOperationRef>,
>(
  refMap: OperationRefMap,
  options: {
    baseUrl: string
  },
): SendRequestFn<OperationSchemas, OperationRefMap> => {
  return async (operationId, request) => {
    const ref = refMap[operationId]
    if (ref == null) {
      throw new Error(`[sendRequest] not supported operationId: ${operationId}`)
    }

    const { url: templateUrl, method } = ref

    const _request: AnyOperationMethodRequest = request

    let url: string = templateUrl
    const path = _request.path
    if (path != null) {
      url = templateUrl.replace(
        /\{([^}]+)\}/g,
        ($0: string, $1: string): string => {
          if (path[$1] == null) return $1
          return encodeURIComponent(String(path[$1]))
        },
      )
    }

    const query = _request.query
    if (query != null) {
      url = `${url}?${new URLSearchParams(
        JSON.parse(JSON.stringify(query as any)),
      ).toString()}`
    }

    url = `${options.baseUrl}${url}`

    return fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json",
        ...(_request.header as any),
      },
      body: _request.body != null ? JSON.stringify(_request.body) : undefined,
    })
      .then(async resp => {
        if (!resp.ok) {
          throw new Error(await resp.text().catch(e => resp.statusText))
        }
        return resp.json()
      })
      .then(data => {
        return { data }
      })
  }
}

interface AnyOperationInfo {
  parameters?: {
    header?: Partial<
      Record<AnyOperationInfo.HeaderField, AnyOperationInfo.HeaderFieldValue>
    >
    path?: Partial<
      Record<AnyOperationInfo.PathField, AnyOperationInfo.PathFieldValue>
    >
    query?: Partial<
      Record<AnyOperationInfo.QueryField, AnyOperationInfo.QueryFieldValue>
    >
    cookie?: Partial<
      Record<AnyOperationInfo.CookieField, AnyOperationInfo.CookieFieldValue>
    >
  }
  requestBody?: {
    content: Partial<
      Record<AnyOperationInfo.ContentType, Record<string, unknown>>
    >
  }
  responses: Partial<
    Record<
      AnyOperationInfo.StatusCode,
      | unknown
      | Partial<{
          content: Record<AnyOperationInfo.ContentType, Record<string, unknown>>
        }>
    >
  >
}
namespace AnyOperationInfo {
  export type HeaderField = string
  export type HeaderFieldValue = unknown
  export type PathField = string
  export type PathFieldValue = unknown
  export type QueryField = string
  export type QueryFieldValue = unknown
  export type CookieField = string
  export type CookieFieldValue = unknown

  export type ContentType = "application/json"

  export type StatusCode = number
}
