import { FC, Suspense, useMemo } from "react"
import { Column, useTable } from "react-table"
import { LoadingIndicator } from "../../../components/LoadingIndicator/LoadingIndicator"
import { Pagination, PaginationInfo } from "../../../components/Pagination"
import { Spensor } from "../../../components/Spensor"
import {
  Table,
  TableBodyRow,
  TableHeadRow,
  Tbody,
  Td,
  Th,
  Thead,
} from "../../../components/table/StyledTable"
import { trunc } from "../../../utils/addressHelpers"
import { customReactTableRender } from "../../../utils/customReactTableRender"
import { readResource, SuspenseResource } from "../../../utils/SuspenseResource"

export interface LotteryRecord {
  lotteryStartId: number
  lotteryEndId: number
  block: number
  walletAddress: string
  wonTicketIds: SuspenseResource<number[] | null>
}

const TableContent: FC<{
  hideResultColumn: boolean
  data: SuspenseResource<LotteryRecord[]>
}> = props => {
  const tableInstance = useTable({
    columns: useTableSchema({
      hideResultCol: props.hideResultColumn,
    }),
    data: readResource(props.data),
  })

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance
  return (
    <Table {...getTableProps()} className={"-mt-4"}>
      <Thead>
        {headerGroups.map(g => (
          <TableHeadRow {...g.getHeaderGroupProps()}>
            {g.headers.map(cell => (
              <Th {...cell.getHeaderProps()}>
                {customReactTableRender(cell, "Header")}
              </Th>
            ))}
          </TableHeadRow>
        ))}
      </Thead>

      <Tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row)
          return (
            <TableBodyRow {...row.getRowProps()}>
              {row.cells.map(cell => (
                <Td {...cell.getCellProps()} className={"break-words"}>
                  {cell.render("Cell")}
                </Td>
              ))}
            </TableBodyRow>
          )
        })}
      </Tbody>
    </Table>
  )
}

export interface LotteryRecordsTableProps extends PaginationInfo {
  /**
   * @default true
   */
  hideResultColumn?: boolean
  records: SuspenseResource<LotteryRecord[]>
  onChangePage: (info: { page: number }) => void
}

export const LotteryRecordsTable: FC<LotteryRecordsTableProps> = props => {
  return (
    <div className={"flex flex-col items-center w-full"}>
      <Suspense
        fallback={
          <div className={"flex-1 flex items-center justify-center p-[30px]"}>
            <LoadingIndicator />
          </div>
        }
      >
        <TableContent
          data={props.records}
          hideResultColumn={props.hideResultColumn ?? true}
        />
      </Suspense>
      <Pagination
        className="w-full mt-6"
        currentPage={props.currentPage}
        recordCountPerPage={props.recordCountPerPage}
        recordCountTotal={props.recordCountTotal}
        onChange={props.onChangePage}
      />
    </div>
  )
}

const useTableSchema = (info: {
  hideResultCol: boolean
}): Column<LotteryRecord>[] => {
  return useMemo(
    () => [...commonColumns, ...(info.hideResultCol ? [] : resultColumns)],
    [info.hideResultCol],
  )
}

const commonColumns: Column<LotteryRecord>[] = [
  {
    Header: "Lottery Code",
    accessor: "lotteryStartId",
    Cell: ({ cell }) => (
      <>
        No. {cell.value} - {cell.row.original.lotteryEndId - 1}
      </>
    ),
  },
  {
    Header: "Block",
    accessor: "block",
    Cell: ({ cell }) => <>#{cell.value}</>,
  },
  {
    Header: "Wallet Address",
    accessor: "walletAddress",
    Cell: ({ cell }) => (
      <span title={cell.value}>{trunc(cell.value, { tail: 4 })}</span>
    ),
  },
]

const resultColumns: Column<LotteryRecord>[] = [
  {
    Header: "Result",
    accessor: "wonTicketIds",
    Cell: ({ cell }) => (
      <Spensor>
        {() => {
          const wonIds = readResource(cell.value)
          if (wonIds == null) {
            return <span>--</span>
          }
          if (wonIds.length === 0) {
            return <span>Lost all</span>
          }
          return (
            <span>
              Won {wonIds.slice(0, 10).join(",")} ({wonIds.length} Tickets)
            </span>
          )
        }}
      </Spensor>
    ),
  },
]
