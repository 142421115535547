import { TransactionResponse } from "@ethersproject/abstract-provider"
import { TypedDataDomain, TypedDataField } from "@ethersproject/abstract-signer"
import { BigNumberish, Contract, providers, utils as ethersUtils } from "ethers"
import { noop } from "lodash"
import { from, map, Observable, of, switchMap } from "rxjs"
import { assertNever } from "../../utils/types"
import { ethSubscribe } from "./MetaMaskEthereumProvider/ethSubscribe"
import { MetaMaskEthereumProvider } from "./MetaMaskEthereumProvider/MetaMaskEthereumProvider"
import { ConnectInfo } from "./MetaMaskEthereumProvider/MetaMaskEthereumProviderNativeEvents"
import { AddEthereumChainParameter } from "./MetaMaskEthereumProvider/MetaMaskEthereumProviderRequests"

export enum ETHChain {
  Ethereum = "ERC-20",
  Polygon = "Polygon",
  AVAX = "Avalanche C-Chain",
  Goerli = "Goerli",
  Unknown = "Unknown",
}
export namespace ETHChainInfo {
  const chainIdFromDecimals = (id: number): string => `0x${id.toString(16)}`

  export const EthereumChainId = chainIdFromDecimals(1)

  export const GoerliChainId = chainIdFromDecimals(5)

  export const PolygonChainInfo: AddEthereumChainParameter = {
    chainId: chainIdFromDecimals(137),
    chainName: "Polygon Mainnet",
    rpcUrls: ["https://polygon-rpc.com"],
  }

  export const AVAXChainInfo: AddEthereumChainParameter = {
    chainId: chainIdFromDecimals(43114),
    chainName: "Avalanche C-Chain",
    rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
  }
}
function chainIdToETHChain(chainId: string): ETHChain {
  switch (chainId) {
    case ETHChainInfo.EthereumChainId:
      return ETHChain.Ethereum
    case ETHChainInfo.GoerliChainId:
      return ETHChain.Goerli
    case ETHChainInfo.PolygonChainInfo.chainId:
      return ETHChain.Polygon
    case ETHChainInfo.AVAXChainInfo.chainId:
      return ETHChain.AVAX
    default:
      return ETHChain.Unknown
  }
}

export const connectedChain = (
  provider: MetaMaskEthereumProvider,
): Observable<{
  chain: ETHChain
  chainId: string
}> => {
  return new Observable<{ chain: ETHChain; chainId: string }>(ob => {
    const handleConnected = (info: ConnectInfo): void => {
      ob.next({
        chain: chainIdToETHChain(info.chainId),
        chainId: info.chainId,
      })
    }
    provider.on("connect", handleConnected)

    const handleChainId = (chainId: string): void => {
      ob.next({
        chain: chainIdToETHChain(chainId),
        chainId: chainId,
      })
    }
    provider.on("chainChanged", handleChainId)

    provider
      .request({ method: "eth_chainId" })
      .then(handleChainId, err => ob.error(err))

    return () => {
      provider.removeListener("connect", handleConnected)
      provider.removeListener("chainChanged", handleChainId)
    }
  })
}

export const switchToChainSimply = async (
  provider: MetaMaskEthereumProvider,
  chainId: string,
): Promise<void> => {
  await provider.request({
    method: "wallet_switchEthereumChain",
    params: [{ chainId }],
  })
}
export const switchToChain = async (
  provider: MetaMaskEthereumProvider,
  chainInfo: AddEthereumChainParameter,
): Promise<void> => {
  try {
    await switchToChainSimply(provider, chainInfo.chainId)
  } catch (switchError) {
    // This error code indicates that the chain has not been added to MetaMask.
    if ((switchError as any).code === 4902) {
      await provider.request({
        method: "wallet_addEthereumChain",
        params: [chainInfo],
      })
    }
    throw switchError
  }
}

export const connectedWalletAddress = (
  provider: MetaMaskEthereumProvider,
): Observable<undefined | string> => {
  return new Observable<string | undefined>(ob => {
    const handleAccounts = (accounts: string[]): void => {
      ob.next(accounts[0])
    }
    provider.on("accountsChanged", handleAccounts)

    const handleDisconnect = (): void => {
      ob.next(undefined)
    }
    provider.on("disconnect", handleDisconnect)

    provider
      .request({ method: "eth_accounts" })
      .then(handleAccounts, err => ob.error(err))

    return () => {
      provider.removeListener("accountsChanged", handleAccounts)
      provider.removeListener("disconnect", handleDisconnect)
    }
  })
}

export const currentBlockNumber = (
  provider: MetaMaskEthereumProvider,
): Observable<string> => {
  return new Observable(ob => {
    let receivedValueFromEvents = false

    const sub = ethSubscribe(provider, "newHeads", result => {
      receivedValueFromEvents = true
      ob.next(result.number)
    })

    sub.promise.catch(ob.error)

    provider.request({ method: "eth_blockNumber" }).then(
      num => {
        if (receivedValueFromEvents) return
        ob.next(num)
      },
      err => ob.error(err),
    )

    return sub.unsubscribe
  })
}

const usdcAddress: Partial<Record<ETHChain, string>> = {
  [ETHChain.Ethereum]: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
  [ETHChain.Goerli]: "0x7Ffd58D5bB024A982D918B127F9AbEf2C974dFCD",
  [ETHChain.Polygon]: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
  [ETHChain.AVAX]: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
}
export const getUSDCBalance = (
  provider: MetaMaskEthereumProvider,
  chain: ETHChain,
  walletAddress: string,
): Observable<number> => {
  const addr = usdcAddress[chain]
  if (addr) {
    return getERC20TokenBalance(provider, addr, walletAddress)
  } else {
    return of(0)
  }
}

const wbtcAddress: Partial<Record<ETHChain, string>> = {
  [ETHChain.Ethereum]: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
  [ETHChain.Goerli]: "0x577D296678535e4903D59A4C929B718e1D575e0A",
  [ETHChain.Polygon]: "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6",
  [ETHChain.AVAX]: "0x50b7545627a5162F82A992c33b87aDc75187B218",
}
export const getWBTCBalance = (
  provider: MetaMaskEthereumProvider,
  chain: ETHChain,
  walletAddress: string,
): Observable<number> => {
  const addr = wbtcAddress[chain]
  if (addr) {
    return getERC20TokenBalance(provider, addr, walletAddress)
  } else {
    return of(0)
  }
}

const erc20TokenABI = [
  "function decimals() public view returns (uint8)",
  "function balanceOf(address) view returns (uint)",
  "function allowance(address, address) view returns (uint256)",
  "function approve(address, uint256) returns (bool)",
  "function transfer(address, uint) returns (bool)",
]
function getERC20TokenBalance(
  provider: MetaMaskEthereumProvider,
  tokenContractAddress: string,
  walletAddress: string,
): Observable<number> {
  const web3Provider = new providers.Web3Provider(provider as any)
  const signer = web3Provider.getSigner()
  const contract = new Contract(tokenContractAddress, erc20TokenABI, signer)

  return currentBlockNumber(provider).pipe(
    switchMap(() =>
      from(
        Promise.all([
          contract.decimals() as Promise<number>,
          contract.balanceOf(walletAddress) as Promise<BigNumberish>,
        ]),
      ),
    ),
    map(([decimals, rawBalance]) =>
      Number(ethersUtils.formatUnits(rawBalance, decimals)),
    ),
  )
}

export type SignTypedDataFn = (
  domain: Omit<TypedDataDomain, "chainId">,
  types: Record<string, Array<TypedDataField>>,
  value: Record<string, any>,
) => Promise<string>
export async function getSignTypedDataFn(
  provider: MetaMaskEthereumProvider,
  chainId: string,
): Promise<SignTypedDataFn> {
  const web3Provider = new providers.Web3Provider(provider as any)
  const signer = web3Provider.getSigner()

  return async (domain, types, value) => {
    return await signer._signTypedData(
      { chainId: String(parseInt(chainId, 16)), ...domain },
      types,
      value,
    )
  }
}

export interface TransferResponse {
  hash: string
  waitConfirmations: () => Promise<void>
}
export async function transferERC20Token(
  provider: MetaMaskEthereumProvider,
  chain: ETHChain,
  tokenName: "usdc" | "wbtc",
  fromWalletAddress: string,
  toWalletAddress: string,
  amount: number,
): Promise<TransferResponse> {
  const tokenContractAddress =
    tokenName === "usdc"
      ? usdcAddress[chain]
      : tokenName === "wbtc"
      ? wbtcAddress[chain]
      : assertNever(tokenName)

  if (tokenContractAddress == null) {
    throw new Error(`[transferERC20Token] unsupported token name: ${tokenName}`)
  }

  const web3Provider = new providers.Web3Provider(provider as any)
  const signer = web3Provider.getSigner()
  const contract = new Contract(tokenContractAddress, erc20TokenABI, signer)

  const decimals = await (contract.decimals() as Promise<number>)
  const requested = ethersUtils.parseUnits(String(amount), decimals)

  const resp = await (contract.transfer(
    toWalletAddress,
    requested,
  ) as Promise<TransactionResponse>)

  return {
    hash: resp.hash,
    waitConfirmations: () => resp.wait().then(noop),
  }
}
