import clsx from "clsx"
import React, { FC, Suspense } from "react"
import { useParams } from "react-router-dom"
import { usePreventGlobalWiredDisclaimerModal } from "../../components/DisclaimerModalContent/WiredDisclaimerModal"
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator"
import { GradientSecondaryPageTitle } from "../../components/PageStack/GradientSecondaryPageTitle"
import { PageStackPage } from "../../components/PageStack/PageStackPage"
import { VideoIntro } from "../../components/PageStack/VideoIntro/VideoIntro"
import { usePathGenerator } from "../../routes/routes"
import { WiredActionSection } from "./component/ActionSection/WiredActionSection/WiredActionSection"
import { WiredDisclaimerModal } from "./component/DisclaimerModalContent/WiredDisclaimerModal"
import { WiredTokenProfileSection } from "./component/TokenProfileSection/WiredTokenProfileSection"
import { WiredUserIDOStatusSection } from "./component/UserIDOStatusSection/UserIDOStatusSection"
import {
  LaunchPadProvider,
  useLaunchPadContentfulStore,
} from "./store/useLaunchPadStores"

const WiredLaunchpadDashboardContentImpl: FC = () => {
  return (
    <div className={"grid grid-cols-12 grid-rows-1 gap-y-8 lg:gap-8 mb-8"}>
      <div
        className={clsx("col-span-12", "lg:col-span-5", "flex flex-col gap-8")}
      >
        <WiredTokenProfileSection className={"lg:row-start-1"} />

        <WiredUserIDOStatusSection className={"flex-1"} />
      </div>

      <WiredActionSection
        className={clsx(
          "col-span-12",
          "lg:col-start-6 lg:col-span-7 lg:row-start-1",
        )}
      />
    </div>
  )
}

const WiredLaunchpadDashboardPageContent: FC = () => {
  const contentfulStore = useLaunchPadContentfulStore()
  const g = usePathGenerator()

  return (
    <PageStackPage
      title={
        <>
          <GradientSecondaryPageTitle
            subtitle={contentfulStore.meta$.subtitle}
            subtitlePosition={"top"}
            returnFallbackURL={g.launchpad()}
          >
            {contentfulStore.meta$.title} Dashboard
          </GradientSecondaryPageTitle>
          <VideoIntro link={"https://youtu.be/-nMjmkNFRYQ"}>
            How does the Launchpad work?
          </VideoIntro>
        </>
      }
    >
      <WiredLaunchpadDashboardContentImpl />
      <WiredDisclaimerModal />
    </PageStackPage>
  )
}

export const LaunchpadDashboardPage: FC = () => {
  const urlSlug = useParams<"urlSlug">().urlSlug!

  usePreventGlobalWiredDisclaimerModal()

  return (
    <LaunchPadProvider urlSlug={urlSlug}>
      <Suspense fallback={<LoadingIndicator className={"m-auto"} />}>
        <WiredLaunchpadDashboardPageContent />
      </Suspense>
    </LaunchPadProvider>
  )
}
