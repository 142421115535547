import { FC } from "react"
import { BorrowRolloverRowData } from "../../../../store/transformerGroups/lend/borrowRollover"
import { BaseCellContainer } from "../../cells/BaseCellContainer"
import { BaseRow } from "../BaseRow"

export const BorrowRolloverRow: FC<{
  rowData: BorrowRolloverRowData
}> = ({ rowData }) => (
  <BaseRow
    rowData={rowData}
    currencyCell={<BaseCellContainer>-</BaseCellContainer>}
    amountCell={<BaseCellContainer>(Collateral Rollover)</BaseCellContainer>}
    tradeInfoCell={<BaseCellContainer>#{rowData.expiry}</BaseCellContainer>}
  />
)
