import { gql } from "@urql/core"
import { Observable } from "rxjs"
import { ALLOW_CONTRACT_ARGUMENTATION, IS_MAIN_NET } from "../../../config"
import {
  FetchLaunchPadCmsDetailQuery,
  FetchLaunchPadCmsDetailQueryVariables,
  LaunchPadCmsDetailFragmentFragment,
} from "../../../generated/graphql/graphql.generated"
import {
  parseTokenFragment,
  TokenFragment,
} from "../../../stores/currencyStore/CurrencyStore.service"
import { gqlQuery } from "../../../utils/graphqlHelpers"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { fromUrqlSource } from "../../../utils/Observable/fromUrqlSource"
import { APowerRuleRange, TokenMetaInfo } from "../component/types"

export interface LaunchPadContentfulDetail {
  urlSlug: string
  featuredImage?: string
  idoToken: TokenInfo
  priceToken: TokenInfo
  meta: TokenMetaInfo
  introHtml: string
  idoId?: number
  hotBadge: boolean
  estimateIdoAmount: number
  estimateValidationStart?: Date
  estimateValidationEnd?: Date
  totalRaisePriceTokenCount?: number
  pricePerIdoToken?: number
  aPowerRules: APowerRuleRange[]
  maxAllowedTickets: number
  tosLink?: string
  finishedAllTimeHigh?: number
  finishedFillRate?: number
  finishedParticipateWallets?: number
  finishedDate?: Date
}

export const LaunchPadCMSDetailFragment = gql`
  ${TokenFragment}
  fragment LaunchPadCMSDetailFragment on Ido {
    urlSlug
    idoToken {
      ...TokenFragment
    }
    priceToken {
      ...TokenFragment
    }
    title
    subtitle
    featuredImage {
      url(transform: { width: 1024, height: 606 })
    }
    idoId
    idoIdDev
    tokenImage {
      url(transform: { width: 200, height: 200 })
    }
    website
    discordLink
    mediumLink
    twitterLink
    content
    hotBadge
    estimateIdoAmount
    estimateValidationStart
    estimateValidationEnd
    estimatedPriceTokenCount
    idoTokenPriceInPriceToken
    aPowerRules
    maxAllowTicket
    tosLink
    finishedAllTimeHigh
    finishedFillRate
    finishedParticipateWallets
    finishedDate
  }
`

const argumentIdoID =
  ALLOW_CONTRACT_ARGUMENTATION &&
  new URLSearchParams(window.location.search).has("asIdoId")
    ? Number(new URLSearchParams(window.location.search).get("asIdoId"))
    : undefined

export function mapLaunchPadCmsDetailFragment(
  ido: LaunchPadCmsDetailFragmentFragment,
): LaunchPadContentfulDetail {
  return {
    urlSlug: ido.urlSlug!,
    featuredImage: ido.featuredImage?.url ?? undefined,
    idoId:
      argumentIdoID ?? (IS_MAIN_NET ? ido.idoId : ido.idoIdDev) ?? undefined,
    meta: {
      title: ido.title!,
      subtitle: ido.subtitle ?? undefined,
      logoSrc: ido.tokenImage!.url!,
      links: {
        website: ido.website!,
        discord: ido.discordLink!,
        medium: ido.mediumLink!,
        twitter: ido.twitterLink!,
      },
    },
    introHtml: ido.content ?? "",
    hotBadge: ido.hotBadge ?? false,
    estimateIdoAmount: ido.estimateIdoAmount!,
    estimateValidationStart: ido.estimateValidationStart
      ? new Date(ido.estimateValidationStart)
      : undefined,
    estimateValidationEnd: ido.estimateValidationEnd
      ? new Date(ido.estimateValidationEnd)
      : undefined,
    idoToken: parseTokenFragment(ido.idoToken!),
    priceToken: parseTokenFragment(ido.priceToken!),
    pricePerIdoToken: ido.idoTokenPriceInPriceToken ?? undefined,
    totalRaisePriceTokenCount: ido.estimatedPriceTokenCount ?? undefined,
    aPowerRules: ido.aPowerRules ?? [
      {
        start: 1,
        end: 5,
        amount: 10,
      },
      {
        start: 6,
        end: 10,
        amount: 50,
      },
      {
        start: 11,
        end: 20,
        amount: 100,
      },
      {
        start: 21,
        end: 30,
        amount: 150,
      },
      {
        start: 31,
        end: 40,
        amount: 200,
      },
    ],
    maxAllowedTickets: ido.maxAllowTicket ?? 50,
    tosLink: ido.tosLink ?? undefined,
    finishedAllTimeHigh: ido.finishedAllTimeHigh ?? undefined,
    finishedFillRate: ido.finishedFillRate ?? undefined,
    finishedParticipateWallets: ido.finishedParticipateWallets ?? undefined,
    finishedDate: ido.finishedDate ? new Date(ido.finishedDate) : undefined,
  }
}

export const fetchContentfulDetails = (
  urlSlug: string,
): Observable<LaunchPadContentfulDetail> =>
  fromUrqlSource(
    gqlQuery<
      FetchLaunchPadCmsDetailQuery,
      FetchLaunchPadCmsDetailQueryVariables
    >(
      gql`
        ${LaunchPadCMSDetailFragment}
        query FetchLaunchPadCMSDetail($urlSlug: String!) {
          idoCollection(where: { urlSlug: $urlSlug }, limit: 1) {
            items {
              ...LaunchPadCMSDetailFragment
            }
          }
        }
      `,
      { urlSlug },
    ),
    ({ data }) => {
      const ido = data?.idoCollection?.items?.[0]
      if (ido == null) {
        throw new Error(`No IDO data for urlSlug: ${urlSlug}`)
      }
      return mapLaunchPadCmsDetailFragment(ido)
    },
  )
