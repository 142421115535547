import React, { FC, Suspense } from "react"
import { CollapsableCard } from "../../components/CollapsableCard/CollapsableCard"
import { PageStackPage } from "../../components/PageStack/PageStackPage"
import { usePathGenerator } from "../../routes/routes"
import { useCurrencyStore } from "../../stores/currencyStore/useCurrencyStore"
import { suspenseResource } from "../../utils/SuspenseResource"
import WiredLoadingOrConnectWallet from "../Auth/components/WiredLoadingOrConnectWallet"
import {
  MyDashboardPanel,
  myDashboardPanelTitle,
} from "./components/indexPage/MyDashboardPanel"
import { PoolEntriesPanel } from "./components/indexPage/PoolEntriesPanel"
import { WiredSummaryRow } from "./components/indexPage/SummaryRow/WiredSummaryRow"
import { useLendIndexStore } from "./store/useLendStore"

const WiredMyDashboardPanel: FC = () => {
  const store = useLendIndexStore()
  const currency = useCurrencyStore()
  const g = usePathGenerator()
  if (store.myDeposits$.length === 0 && store.myBorrows$.length === 0) {
    return null
  }
  return (
    <MyDashboardPanel
      depositRecords={store.myDeposits$.map(s => ({
        depositLink: g.lendDetailDeposit({ id: s.poolToken }),
        yieldToken: currency.getTokenInfo$(s.yieldToken),
        yieldTokenCount: s.yieldTokenBalance$,
        depositedToken: currency.getTokenInfo$(s.underlyingToken),
        claimableAmount: s.claimableYieldToken$,
        claimableUponMaturity: s.yieldTokenBalance$ - s.claimableYieldToken$,
      }))}
      borrowRecords={store.myBorrows$.map(s => ({
        borrowLink: g.lendDetailBorrow({ id: s.poolToken }),
        borrowedToken: currency.getTokenInfo$(s.underlyingToken),
        borrowedTokenCount: s.keyTokenBalance$,
        collateralTokens: [
          {
            token: currency.getTokenInfo$(s.collateralToken),
            count: suspenseResource(
              () => s.myBorrow.pendingBreakdown.collateral,
            ),
          },
          {
            token: currency.getTokenInfo$(s.underlyingToken),
            count: suspenseResource(() => s.myBorrow.pendingBreakdown.uToken),
          },
        ],
        claimTokens: [
          {
            token: currency.getTokenInfo$(s.collateralToken),
            count: suspenseResource(
              () => s.myBorrow.claimableBreakdown$.collateral,
            ),
          },
          {
            token: currency.getTokenInfo$(s.underlyingToken),
            count: suspenseResource(
              () => s.myBorrow.claimableBreakdown$.uToken,
            ),
          },
        ],
      }))}
    />
  )
}

const WiredPoolEntriesPanel: FC = () => {
  const stores = useLendIndexStore()
  const currency = useCurrencyStore()
  const g = usePathGenerator()
  return (
    <PoolEntriesPanel
      lendPools={stores.allPoolStores.map(s => ({
        token: currency.getTokenInfo$(s.underlyingToken),
        borrowApr: suspenseResource(() => s.borrowAPR$),
        borrowCollateral: [currency.getTokenInfo$(s.collateralToken)],
        depositApr: suspenseResource(() => s.depositAPR$),
        borrowPageLink: g.lendDetailBorrow({ id: s.poolToken }),
        depositPageLink: g.lendDetailDeposit({ id: s.poolToken }),
      }))}
    />
  )
}

export const LendIndexPage: FC = () => {
  return (
    <PageStackPage>
      <Suspense>
        <div className={"flex flex-col gap-8"}>
          <WiredSummaryRow gapClassName={"gap-8"} />
          <Suspense
            fallback={
              <CollapsableCard title={myDashboardPanelTitle}>
                <WiredLoadingOrConnectWallet />
              </CollapsableCard>
            }
          >
            <WiredMyDashboardPanel />
          </Suspense>
          <Suspense>
            <WiredPoolEntriesPanel />
          </Suspense>
        </div>
      </Suspense>
    </PageStackPage>
  )
}
