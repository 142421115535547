import { action, computed, makeObservable, observable } from "mobx"
import { computedFn } from "mobx-utils"
import { ConfirmTransactionStore } from "../../../../stores/confirmTransactionDialogStore/ConfirmTransactionStore"
import { SuspenseObservable } from "../../../../stores/SuspenseObservable"
import { Currency } from "../../../../utils/alexjs/Currency"
import { asyncAction, runAsyncAction } from "../../../../utils/asyncAction"
import { Result } from "../../../../utils/Result"
import { DepositFormError, DepositFormErrorType } from "../../components/types"
import { OrderbookStore } from "../OrderbookStore"
import type {
  DepositFormData,
  StxDxAsset,
} from "../OrderbookStore.service/OrderbookStore.service"
import {
  allStxDxAssets,
  depositToStxDx,
} from "../OrderbookStore.service/OrderbookStore.service"

export class OrderbookDepositModule {
  constructor(readonly store: OrderbookStore) {
    makeObservable(this)
  }

  txStore = new ConfirmTransactionStore()

  @observable tokens: {
    token: StxDxAsset
    amount: SuspenseObservable<number>
  }[] = [
    {
      token: Currency.W_XUSD,
      amount: new SuspenseObservable<number>(),
    },
  ]

  @action reset(): void {
    this.tokens = [
      {
        token: Currency.W_XUSD,
        amount: new SuspenseObservable<number>(),
      },
    ]
  }

  @computed get canDelete(): boolean {
    return this.tokens.length > 1
  }

  @computed get canAddMoreToken(): boolean {
    return this.tokens.length < allStxDxAssets.length
  }

  @action addToken(): void {
    const existing = this.tokens.map(t => t.token)
    const notIncluded = allStxDxAssets.filter(a => !existing.includes(a))[0]
    if (notIncluded == null) {
      return
    }
    this.tokens.push({
      token: notIncluded,
      amount: new SuspenseObservable<number>(),
    })
  }

  @action deleteToken(token: StxDxAsset): void {
    this.tokens = this.tokens.filter(t => t.token !== token)
  }

  @computed get maxSTXWarning(): boolean {
    const stxAmount =
      this.tokens.find(s => s.token === Currency.W_STX)?.amount.get() ?? 0
    const balance = this.store.account.getBalance$(Currency.W_STX)
    return stxAmount !== 0 && stxAmount >= balance - 0.1
  }

  @observable depositing = false
  @observable confirming = false

  @observable selectingFromToken?: StxDxAsset

  @computed get formData$(): Result<DepositFormData, DepositFormError> {
    if (this.tokens.some(t => !t.amount.get())) {
      return Result.error({
        type: DepositFormErrorType.AmountIsEmpty,
        message: "Input Amount",
      })
    }
    if (
      this.tokens.some(
        t => t.amount.read$ > this.store.account.getBalance$(t.token),
      )
    ) {
      return Result.error({
        type: DepositFormErrorType.InsufficientTokenBalance,
        message: "Insufficient Balance",
      })
    }
    return Result.ok({
      stxAddress: this.store.authStore.stxAddress$,
      userId: this.store.myInfo.userId$,
      tokens: this.tokens.map(t => ({
        asset: t.token,
        assetId: this.store.info.currencyAssetId$(t.token),
        amount: t.amount.read$,
      })),
    })
  }

  @asyncAction
  async deposit(form: DepositFormData, run = runAsyncAction): Promise<void> {
    try {
      this.confirming = false
      this.depositing = false
      const { txId } = await run(depositToStxDx(form))
      this.txStore.successRunning(txId)
    } catch (e) {
      this.txStore.errorRunning(e as Error)
    }
  }

  maxDepositableCount$ = computedFn((_currency: StxDxAsset) => {
    return 1000000
  })
}
