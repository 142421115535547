import {
  EXPLORER_ADDR_URL,
  EXPLORER_TX_URL,
  IS_MAIN_NET,
} from "../../../config"
import { assertNever, OneOrMore } from "../../../utils/types"

export enum BridgeChain {
  Unknown = "Unknown",
  Stacks = "Stacks",
  Ethereum = "Ethereum",
  Goerli = "Goerli",
}

export type ETHBridgeChain = Exclude<
  BridgeChain,
  BridgeChain.Stacks | BridgeChain.Unknown
>
export const ethBridgeChains: OneOrMore<ETHBridgeChain> = IS_MAIN_NET
  ? [BridgeChain.Ethereum]
  : [BridgeChain.Goerli]
export function isETHBridgeChain(chain: BridgeChain): chain is ETHBridgeChain {
  return ethBridgeChains.includes(chain as any)
}

export type WrappableETHBridgeChain = ETHBridgeChain & {
  __brand: "WrappableETHBridgeChain"
}
export function isWrappableETHBridgeChain(
  chain: BridgeChain,
): chain is WrappableETHBridgeChain {
  return ethBridgeChains.includes(chain as any)
}

export type UnwrappableETHBridgeChain = ETHBridgeChain & {
  __brand: "UnwrappableETHBridgeChain"
}
export function isUnwrappableETHBridgeChain(
  chain: BridgeChain,
): chain is UnwrappableETHBridgeChain {
  return ethBridgeChains.includes(chain as any)
}

export function contractAssignedChainIdFromBridgeChain(
  chain: ETHBridgeChain,
): number {
  if (!ethBridgeChains.includes(chain)) {
    throw new Error(
      `[contractAssignedChainIdFromBridgeChain] Not supported chain in current environment: ${chain}`,
    )
  }

  switch (chain) {
    case BridgeChain.Goerli:
    case BridgeChain.Ethereum:
      return 1
    default:
      assertNever(chain)
  }
}

export function getETHChainExplorerAddrLink(
  chain: ETHBridgeChain,
  addr: string,
): string {
  switch (chain) {
    case BridgeChain.Ethereum:
      return `https://etherscan.io/address/${addr}`
    case BridgeChain.Goerli:
      return `https://goerli.etherscan.io/address/${addr}`
    default:
      assertNever(chain)
  }
}

export function getExplorerAddrLink(
  chain: BridgeChain,
  addr: string,
): string | undefined {
  if (chain === BridgeChain.Stacks) {
    return EXPLORER_ADDR_URL(addr)
  } else if (chain === BridgeChain.Unknown) {
    return undefined
  } else {
    return getETHChainExplorerAddrLink(chain, addr)
  }
}

export function getETHChainExplorerTxLink(
  chain: ETHBridgeChain,
  txHash: string,
): string {
  switch (chain) {
    case BridgeChain.Ethereum:
      return `https://etherscan.io/tx/${txHash}`
    case BridgeChain.Goerli:
      return `https://goerli.etherscan.io/tx/${txHash}`
    default:
      assertNever(chain)
  }
}

export function getExplorerTxLink(
  chain: BridgeChain,
  hash: string,
): string | undefined {
  if (chain === BridgeChain.Stacks) {
    return EXPLORER_TX_URL(hash)
  } else if (chain === BridgeChain.Unknown) {
    return undefined
  } else {
    return getETHChainExplorerTxLink(chain, hash)
  }
}
