import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { clear$t } from "../../../../commonIntlMessages"
import { HeadlessButton } from "../../../../components/button/HeadlessButton"
import { LoadableButton } from "../../../../components/button/LoadableButton"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { OpacityButton } from "../../../../components/button/variants/OpacityButton"
import { RedFilledButton } from "../../../../components/button/variants/RedFilledButton"
import {
  CardModalContent,
  TitleBarWithLeftSideTitle,
} from "../../../../components/CardModal/CardModal"
import { Spensor } from "../../../../components/Spensor"
import { BalanceBottomArea } from "../../../../components/TokenInput/BalanceBottomArea"
import { TokenInput } from "../../../../components/TokenInput/TokenInput"
import { TokenNameSelectTrigger } from "../../../../components/TokenInput/TokenNameSelect"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import {
  readResource,
  safeReadResource,
  SuspenseResource,
} from "../../../../utils/SuspenseResource"
import { MaxSTXWarning } from "../../../Pool/components/ChangeLiquiditySection/ChangeLiquiditySection"
import { DepositFormError, DepositFormErrorType } from "../types"
import { RectButton } from "../wrappedCommonComponents/RectButton"
import { ReactComponent as DeleteButtonIcon } from "./deleteButton.svg"

const deposit$t = defineMessage({
  defaultMessage: "Deposit",
  description: `Orderbook/Deposit Modal/"Deposit" button text`,
})

export const DepositModalContent: FC<{
  tokens: {
    token: TokenInfo
    tokenCount: SuspenseResource<null | number>
    tokenBalanceCount: SuspenseResource<number>
    isError?: SuspenseResource<boolean>
    onPressMax: () => void
    onTokenCountChange: (newCount: null | number) => void
    onDelete?: () => void
  }[]
  onAdd?: () => void
  onSelectCurrency: (fromCurrency: TokenInfo) => void
  maxSTXWarning: SuspenseResource<boolean>
  error?: SuspenseResource<DepositFormError | undefined>
  onSubmit: () => void
  onClear: () => void
  onClose: () => void
}> = props => {
  const { $t } = useIntl()

  return (
    <CardModalContent
      titleBar={
        <TitleBarWithLeftSideTitle
          title={$t(
            defineMessage({
              defaultMessage: "Deposit",
              description: `Orderbook/Deposit Modal/"Deposit" modal title`,
            }),
          )}
          rightSide={
            <HeadlessButton
              className={"ml-auto text-blue-600"}
              onClick={props.onClear}
            >
              {$t(clear$t)}
            </HeadlessButton>
          }
          onClose={props.onClose}
        />
      }
    >
      {props.tokens.map(token => (
        <TokenInput
          token={token.token}
          value={token.tokenCount}
          tokenNameArea={
            <>
              <TokenNameSelectTrigger
                token={token.token}
                onClick={() => props.onSelectCurrency(token.token)}
              />
              {token.onDelete != null && (
                <HeadlessButton onClick={token.onDelete}>
                  <DeleteButtonIcon />
                </HeadlessButton>
              )}
            </>
          }
          bottomArea={
            <BalanceBottomArea
              token={token.token}
              balance={token.tokenBalanceCount}
              onPressMax={token.onPressMax}
            />
          }
          error={safeReadResource(token.isError)}
          onChange={token.onTokenCountChange}
        />
      ))}
      {props.onAdd != null && (
        <RectButton Variant={OpacityButton} onClick={props.onAdd}>
          {$t(
            defineMessage({
              defaultMessage: "+ Add a token",
              description: `Orderbook/Deposit Modal`,
            }),
          )}
        </RectButton>
      )}
      {safeReadResource(props.maxSTXWarning) && (
        <MaxSTXWarning className="max-w-fit" />
      )}
      <Spensor
        fallback={
          <LoadableButton Variant={GradientFilledButton} loading={true}>
            {$t(deposit$t)}
          </LoadableButton>
        }
      >
        {() => {
          const error = readResource(props.error)
          if (error != null) {
            return (
              <RectButton
                Variant={
                  isErrorStateButtonShouldRenderAsGradientButton(error)
                    ? GradientFilledButton
                    : RedFilledButton
                }
                disabled={true}
              >
                {error.message}
              </RectButton>
            )
          }
          return (
            <RectButton Variant={GradientFilledButton} onClick={props.onSubmit}>
              {$t(deposit$t)}
            </RectButton>
          )
        }}
      </Spensor>
    </CardModalContent>
  )
}

const isErrorStateButtonShouldRenderAsGradientButton = (
  error?: DepositFormError,
): boolean => {
  if (!error) return false
  return error.type === DepositFormErrorType.AmountIsEmpty
}
