import { FC } from "react"
import {
  CardInset,
  CardInsetDivider,
  CardInsetTitle,
} from "../../../../../components/Card"
import { BlockTimeCountdownText } from "../../../../../components/TimeCountdown/BlockTimeCountdownText"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { ReactComponent as AfterRegisteredIcon } from "./afterRegisteredImage.svg"
import { HowLotteryWorkLink } from "./HowLotteryWorkLink"
import { LotteryTicketCard } from "./LotteryTicketCard/LotteryTicketCard"
import { LotteryTicket } from "./LotteryTicketCard/types"
import { PurpleBlock } from "./PurpleBlock"

export const ActionSectionContent$AfterRegistered: FC<{
  currentBlock: number
  startedAt: Date
  startedAtBlock: number
  idoToken: TokenInfo
  priceToken: TokenInfo
  lotteryTickets: LotteryTicket[]
}> = props => {
  return (
    <CardInset className={"flex flex-col items-center gap-6"}>
      <div className={"flex flex-col items-center gap-4"}>
        <AfterRegisteredIcon />

        <CardInsetTitle>
          You have completed validation, Lottery drawing will begin in
        </CardInsetTitle>

        <PurpleBlock
          primaryText={
            <BlockTimeCountdownText
              targetBlock={props.startedAtBlock}
              currentBlock={props.currentBlock}
              time={props.startedAt}
              estimateSuffix={<> (Estimate)</>}
            />
          }
          secondaryText={<>start at: Block {props.startedAtBlock}</>}
        />
      </div>

      <CardInsetDivider />

      <CardInsetTitle>Your Lottery Ticket</CardInsetTitle>

      <ul className={"flex flex-wrap justify-center gap-2.5"}>
        {props.lotteryTickets.map((t, idx) => (
          <li key={idx}>
            <LotteryTicketCard
              idoToken={props.idoToken}
              priceToken={props.priceToken}
              lotteryTicket={t}
            />
          </li>
        ))}
      </ul>

      <HowLotteryWorkLink />
    </CardInset>
  )
}
