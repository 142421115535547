import { keys, toPairs, values, zipObject } from "lodash"
import { isEnumValue } from "../enumHelpers"

export enum Currency {
  ALEX = "age000-governance-token",
  ATALEX = "auto-alex",
  APOWER = "token-apower",

  // CRP tokens
  YIELD_ALEX = "yield-alex-v1",
  KEY_ALEX_AUTOALEX = "key-alex-autoalex-v1",

  // Fixed Weight liquidity Pool tokens
  FWP_STX_ALEX_50_50_V1_01 = "fwp-wstx-alex-50-50-v1-01",
  FWP_STX_ALEX_TRANCHED = "fwp-wstx-alex-tranched-120",
  FWP_STX_XBTC_50_50_V1_01 = "fwp-wstx-wbtc-50-50-v1-01",
  FWP_ALEX_BANANA = "fwp-alex-wban",
  FWP_ALEX_USDA = "fwp-alex-usda",
  FWP_ALEX_ATALEX = "fwp-alex-autoalex",
  FWP_ALEX_SLIME = "fwp-alex-wslm",
  FWP_STX_XUSD = "fwp-wstx-wxusd-50-50-v1-01",
  FWP_STX_MIA = "fwp-wstx-wmia-50-50-v1-01",
  FWP_STX_NYCC = "fwp-wstx-wnycc-50-50-v1-01",

  // Yield token pool
  YTP_ALEX = "ytp-alex-v1",
  AUTO_YTP_ALEX = "auto-ytp-alex",

  // Wrapped tokens, above tokens are native tokens
  W_USDA = "token-wusda",
  W_STX = "token-wstx",
  W_BANANA = "token-wban",
  W_XBTC = "token-wbtc",
  W_DIKO = "token-wdiko",
  W_SLIME = "token-wslm",
  W_XUSD = "token-wxusd",
  W_MIA = "token-wmia",
  W_NYCC = "token-wnycc",

  // AMM_SWAP_POOL
  AMM_SWAP_POOL_WXUSD_WUSDA = "token-amm-swap-pool:token-wxusd,token-wusda,0.0001e8",
  AMM_SWAP_POOL_WSTX_ALEX = "token-amm-swap-pool:token-wstx,age000-governance-token,0.99e8",
  AMM_SWAP_POOL_WSTX_XBTC = "token-amm-swap-pool:token-wstx,token-wbtc,0.99e8",
  AMM_SWAP_POOL_ALEX_WBAN = "token-amm-swap-pool:token-wstx,token-wban,0.99e8",
  AMM_SWAP_POOL_ALEX_WUSDA = "token-amm-swap-pool:token-wstx,token-wusda,0.99e8",
}

export const isCurrency = (tokenTrait: unknown): tokenTrait is Currency =>
  isEnumValue(Currency, tokenTrait)
export type NativeCurrency = Exclude<Currency, WrappedCurrency>

export enum DownstreamCurrency {
  USDA = "token-wusda-downstream",
  STX = "token-wstx-downstream",
  BANANA = "token-wban-downstream",
  XBTC = "token-wbtc-downstream",
  DIKO = "token-wdiko-downstream",
  SLIME = "token-wslm-downstream",
  XUSD = "token-wxusd-downstream",
  MIA = "token-wmia-downstream",
  NYCC = "token-wnycc-downstream",
}

type PickWrappedCurrency<T> = T extends `W_${string}` ? T : never
export type WrappedCurrency = typeof Currency[PickWrappedCurrency<
  keyof typeof Currency
>]
export namespace WrappedCurrency {
  export const isWrapped = (
    currency: Currency | DownstreamCurrency,
  ): currency is WrappedCurrency => {
    if (!isCurrency(currency)) return false

    const pair = toPairs(Currency).find(([, value]) => value === currency)
    if (!pair) {
      throw new Error(
        `[WrappedCurrency.isWrapped] Unknown currency: ${currency}`,
      )
    }

    return pair[0].startsWith("W_")
  }

  const wrappedCurrencyMap: Record<DownstreamCurrency, WrappedCurrency> = {
    [DownstreamCurrency.USDA]: Currency.W_USDA,
    [DownstreamCurrency.STX]: Currency.W_STX,
    [DownstreamCurrency.BANANA]: Currency.W_BANANA,
    [DownstreamCurrency.XBTC]: Currency.W_XBTC,
    [DownstreamCurrency.DIKO]: Currency.W_DIKO,
    [DownstreamCurrency.SLIME]: Currency.W_SLIME,
    [DownstreamCurrency.XUSD]: Currency.W_XUSD,
    [DownstreamCurrency.MIA]: Currency.W_MIA,
    [DownstreamCurrency.NYCC]: Currency.W_NYCC,
  }
  const wrappedCurrencyRevertMap: Record<WrappedCurrency, DownstreamCurrency> =
    zipObject(values(wrappedCurrencyMap), keys(wrappedCurrencyMap)) as any

  export const wrap = (currency: DownstreamCurrency): WrappedCurrency => {
    return wrappedCurrencyMap[currency]
  }

  export const unwrap = (currency: WrappedCurrency): DownstreamCurrency => {
    return wrappedCurrencyRevertMap[currency]
  }

  export const safeUnwrap = (
    currency: Currency,
  ): NativeCurrency | DownstreamCurrency => {
    if (isWrapped(currency)) {
      return unwrap(currency)
    } else {
      return currency
    }
  }
}
