import clsx from "clsx"
import { ReactNode } from "react"
import { Card, CardTitle } from "../../../../../components/Card"
import { FCC } from "../../../../../utils/reactHelpers/types"

export const ActionSectionFrame: FCC<{
  className?: string
  titleRightSide?: ReactNode
}> = props => (
  <Card className={clsx(props.className, "flex flex-col gap-4")}>
    <div className={"flex items-center justify-between"}>
      <CardTitle>Schedule</CardTitle>
      {props.titleRightSide}
    </div>

    {props.children}
  </Card>
)
