import { memoize } from "lodash"
import { computed, makeObservable } from "mobx"
import { createTransformer } from "mobx-utils"
import AccountStore from "../../../stores/accountStore/AccountStore"
import { AppEnvStore } from "../../../stores/appEnvStore/AppEnvStore"
import AuthStore from "../../../stores/authStore/AuthStore"
import { ChainStore } from "../../../stores/chainStore/ChainStore"
import CurrencyStore from "../../../stores/currencyStore/CurrencyStore"
import { LazyValue } from "../../../stores/LazyValue/LazyValue"
import { Currency } from "../../../utils/alexjs/Currency"
import { FWPToken } from "../../../utils/alexjs/currencyHelpers"
import ManualStakeStore, {
  stakeStoreCache,
} from "../../Stake/store/manualStaking/ManualStakeStore"
import CoFarmStore from "./CoFarmStore"
import { getFarmsList } from "./FarmStore.service"

export interface FarmListItem {
  token: FWPToken
  liquidity?: number
  apr?: number
  fees?: number
}

class FarmStore {
  constructor(
    readonly appEnv: Pick<AppEnvStore, "config$">,
    readonly authStore: Pick<
      AuthStore,
      "stxAddress$" | "currentBlockHeight$" | "isWalletConnected"
    >,
    readonly currencyStore: Pick<
      CurrencyStore,
      "getPrice$" | "getTokenInfo$" | "fetchPoolBreakdown$"
    >,
    readonly accountStore: Pick<AccountStore, "getBalance$" | "transactions$">,
    readonly chainStore: Pick<
      ChainStore,
      "estimatedDateForBlock$" | "currentBlockHeight$" | "stakeChainModule"
    >,
  ) {
    makeObservable(this)
  }

  @computed get apowerMultiplier$(): number {
    return this.myFarms$[0]?.apowerMultiplier$ ?? 0.3
  }

  @computed get allPoolTokens$(): FWPToken[] {
    return this.appEnv.config$.farmingPool
  }

  private _poolStats = new LazyValue(
    () =>
      [
        this.allPoolTokens$,
        this.currencyStore.getPrice$(Currency.W_STX),
        this.currencyStore.getPrice$(Currency.ALEX),
      ] as const,
    ([pools, stxPrice, alexPrice]) => getFarmsList(pools, stxPrice, alexPrice),
  )

  poolStats$ = createTransformer((pool: FWPToken) => {
    return this._poolStats.value$.find(a => a.token === pool)
  })

  aprForPool$ = createTransformer((pool: FWPToken): number | undefined => {
    const apr = this.poolStats$(pool)?.apr
    if (apr == null) {
      return undefined
    }
    const multiplier = this.stakingStore(pool).dualYield?.aprMultiplier$ ?? 1
    return apr * multiplier
  })

  getBalance$ = createTransformer((token: FWPToken) => {
    return this.accountStore.getBalance$(token)
  })

  @computed private get allStakeStores(): ManualStakeStore[] {
    return this.allPoolTokens$.map(this.stakingStore)
  }

  @computed get myFarms$(): ManualStakeStore[] {
    return this.allStakeStores.filter(
      a =>
        a.myStaking.activeStaking +
          (a.token === Currency.FWP_STX_ALEX_50_50_V1_01
            ? this.coFarmStore.nextCycleBalance$
            : 0) >
          0 || a.myStaking.hasAnythingToClaim$,
    )
  }

  stakingStore = memoize(
    (token: FWPToken) =>
      stakeStoreCache[token] ??
      new ManualStakeStore(
        token,
        this.appEnv,
        this.authStore,
        this.currencyStore,
        this.accountStore,
        this.chainStore,
      ),
  )

  @computed({ keepAlive: true }) get coFarmStore(): CoFarmStore {
    return new CoFarmStore(
      Currency.FWP_STX_ALEX_50_50_V1_01,
      this.stakingStore(Currency.FWP_STX_ALEX_50_50_V1_01),
      this.currencyStore,
    )
  }
}

export default FarmStore
