import { FC, useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { PageStackPage } from "../../components/PageStack/PageStackPage"
import { PrimaryPageTitle } from "../../components/PageStack/PrimaryPageTitle"
import { useNotifyStore } from "../../stores/notifyStore/useNotifyStore"
import { DEFAULT_FILTER, NOTIFY_FILTERS } from "./constants"
import { NotifyFilter } from "./types"
import { WiredNotifyTabbar } from "./WiredNotifyTabbar"
import { WiredPagination } from "./WiredPagination"
import { WiredSystemMessageList } from "./WiredSystemMessageList"
import { WiredTransactionsTable } from "./WiredTransactionsTable"

const PageContent: FC = () => {
  const store = useNotifyStore()
  const [searchParams, setSearchParams] = useSearchParams()

  const filter = searchParams.get("filter")
  const page = searchParams.get("page")

  /**
   * make sure filter and page search params exists
   */
  useEffect(() => {
    // make sure filter and page index is valid
    let realFilter: NotifyFilter
    if (filter) {
      realFilter = NOTIFY_FILTERS.includes(filter as NotifyFilter)
        ? (filter as NotifyFilter)
        : DEFAULT_FILTER
    } else {
      realFilter = DEFAULT_FILTER
    }

    let realPageIndex = page ? parseInt(page) - 1 : 0
    if (Number.isNaN(realPageIndex) || realPageIndex < 0) {
      realPageIndex = 0
    }

    // update search params
    setSearchParams(
      {
        ...Object.fromEntries(searchParams.entries()),
        filter: realFilter,
        page: (realPageIndex + 1).toString(),
      },
      { replace: true },
    )

    // sync filter and page search params to store
    if (realFilter !== store.filter) {
      store.setFilter(realFilter)
    }
    if (realPageIndex !== store.pageIndex) {
      store.setPageIndex(realPageIndex)
    }
  }, [filter, page, searchParams, setSearchParams, store])

  return (
    <div>
      <WiredNotifyTabbar className="mb-[0.625rem] sm:mb-6" />
      <WiredTransactionsTable />
      <WiredPagination />
      <WiredSystemMessageList />
    </div>
  )
}

export const NotifyPage: FC = () => (
  <PageStackPage title={<PrimaryPageTitle>Notify</PrimaryPageTitle>}>
    <PageContent />
  </PageStackPage>
)
