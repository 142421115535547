import { ClarityError } from "clarity-codegen"
import { noop } from "lodash"
import {
  action,
  computed,
  IReactionDisposer,
  makeObservable,
  observable,
  reaction,
} from "mobx"
import { computedFn } from "mobx-utils"
import AccountStore from "../../../stores/accountStore/AccountStore"
import { AppConfigs } from "../../../stores/appEnvStore/appEnv.services"
import AuthStore from "../../../stores/authStore/AuthStore"
import { ConfirmTransactionStore } from "../../../stores/confirmTransactionDialogStore/ConfirmTransactionStore"
import CurrencyStore from "../../../stores/currencyStore/CurrencyStore"
import { LazyValue } from "../../../stores/LazyValue/LazyValue"
import { pMemoizeDecorator } from "../../../stores/LazyValue/pMemoizeDecorator"
import { SlippageStore } from "../../../stores/slippageStore/SlippageStore"
import { SuspenseObservable } from "../../../stores/SuspenseObservable"
import { ValidationStore } from "../../../stores/ValidationStore"
import { AMMSwapPool } from "../../../utils/alexjs/AMMSwapPool"
import { Currency } from "../../../utils/alexjs/Currency"
import type { SwappableCurrency } from "../../../utils/alexjs/currencyHelpers"
import { errorCodesFromName } from "../../../utils/alexjs/errorCode"
import { asyncAction, runAsyncAction } from "../../../utils/asyncAction"
import { closeTo } from "../../../utils/numberHelpers"
import { Result } from "../../../utils/Result"
import { SwapFormErrorType } from "../types"
import { LiquidityProviderFeeModule } from "./LiquidityProviderFeeModule"
import { getRoute, getYAmountFromOneX, runSpot } from "./SpotStore.services"

class SpotStore {
  private disposeFnArray: IReactionDisposer[]
  constructor(
    readonly appConfig: {
      config$: Pick<AppConfigs, "commonSwapCoins" | "allSwapCoins" | "pools">
    },
    readonly authStore: Pick<AuthStore, "stxAddress$" | "isWalletConnected">,
    readonly accountStore: Pick<AccountStore, "getBalance$" | "updateBalance">,
    readonly currencyStore: Pick<CurrencyStore, "getPrice$">,
    readonly chainStore: Pick<AuthStore, "currentBlockHeight$">,
  ) {
    makeObservable(this)
    this.disposeFnArray = [
      reaction(
        () => [
          this.fromCurrency.read$,
          this.toCurrency.read$,
          this.inputtedFromAmount.read$,
        ],
        () => {
          this.validation.reset()
        },
        { onError: noop },
      ),
      reaction(
        () => this.availableCurrencies$,
        currencies => {
          if (currencies) {
            const queries = new URLSearchParams(window.location.search)
            const from: SwappableCurrency = queries.get("fromCurrency") as any
            const to: SwappableCurrency = queries.get("toCurrency") as any
            if (from && currencies.includes(from)) {
              this.fromCurrency.set(from)
            } else {
              this.fromCurrency.set(currencies[0])
            }
            if (to && currencies.includes(to)) {
              this.toCurrency.set(to)
            }
          }
        },
        { fireImmediately: true },
      ),
    ]
  }

  destroy(): void {
    this.disposeFnArray.forEach(fn => fn())
    this.disposeFnArray.length = 0
  }

  slippagePercent = new SlippageStore()
  liquidityProviderFee = new LiquidityProviderFeeModule(this)

  @computed get availableAMMPools$(): AMMSwapPool.PoolTokens[] {
    return this.appConfig.config$.pools.filter(AMMSwapPool.isPoolToken)
  }

  @computed get commonCurrencies$(): SwappableCurrency[] {
    return this.appConfig.config$.commonSwapCoins
  }

  @computed get availableCurrencies$(): SwappableCurrency[] {
    return this.appConfig.config$.allSwapCoins
  }

  @observable fromCurrency = new SuspenseObservable<SwappableCurrency>()
  @observable toCurrency = new SuspenseObservable<SwappableCurrency>()

  @observable onSelectType?: "from" | "to"
  @observable showConfirmation = false

  spotRunning = new ConfirmTransactionStore()
  inputtedFromAmount = new SuspenseObservable<number>()

  @computed get fromCurrencyBalance$(): number {
    return this.accountStore.getBalance$(this.fromCurrency.read$)
  }

  private _route = new LazyValue(
    () =>
      [
        this.fromCurrency.read$,
        this.toCurrency.read$,
        this.availableAMMPools$,
        this.chainStore.currentBlockHeight$,
      ] as const,
    ([from, to, pools]) => getRoute(from, to, pools),
    { decorator: pMemoizeDecorator({ persistKey: "spotStore.route" }) },
  )

  @computed get routes$(): SwappableCurrency[] {
    return this._route.value$
  }
  @computed get minimumReceived$(): number {
    return this.toAmount$ * (1 - this.slippagePercent.slippagePercentage)
  }

  @computed get isMaxSTX$(): boolean {
    return (
      this.fromCurrency.read$ === Currency.W_STX &&
      closeTo(
        this.accountStore.getBalance$(this.fromCurrency.read$),
        Number(this.inputtedFromAmount.read$),
      )
    )
  }

  @computed get fromCurrencyUnitPrice$(): number {
    return this.currencyStore.getPrice$(this.fromCurrency.read$)
  }

  @computed get toCurrencyUnitPrice$(): number {
    return this.currencyStore.getPrice$(this.toCurrency.read$)
  }

  @computed get fromAmountEstimatedUSD$(): number {
    return Number(this.inputtedFromAmount.read$) * this.fromCurrencyUnitPrice$
  }

  @computed get toAmountEstimatedUSD$(): number {
    return (
      Number(this.toAmount$) *
      this.currencyStore.getPrice$(this.toCurrency.read$)
    )
  }

  @computed get toAmount$(): number {
    return this.inputtedFromAmount.read$ * this.fromToExchangeRate$
  }

  @computed get bothTokenSelected(): boolean {
    return this.fromCurrency !== null && this.toCurrency !== null
  }

  @action reset(): void {
    this.setFromAmount(undefined)
  }

  @action swapFromAndTo(): void {
    this.setCurrency("to", this.fromCurrency.read$)
  }

  @action setFromAmount(amount?: number): void {
    this.inputtedFromAmount.set(amount)
  }

  @action setCurrency(
    type: "from" | "to",
    newCurrency?: SwappableCurrency,
  ): void {
    this.onSelectType = undefined
    const currencyKey = type === "from" ? "fromCurrency" : "toCurrency"
    if (this[currencyKey].get() === newCurrency) {
      return
    }
    const otherCurrencyKey = type === "from" ? "toCurrency" : "fromCurrency"
    if (this[otherCurrencyKey].get() === newCurrency) {
      this[otherCurrencyKey].set(this[currencyKey].get())
      this.setFromAmount(undefined)
    }
    this[currencyKey].set(newCurrency)
  }

  exchangeRates = computedFn(
    (from: SwappableCurrency, to: SwappableCurrency, fromUnitPrice: number) =>
      new LazyValue(
        () =>
          [
            this.authStore.stxAddress$,
            from,
            to,
            fromUnitPrice,
            this.availableAMMPools$,
            this.chainStore.currentBlockHeight$,
          ] as const,
        ([stxAddress, from, to, fromUnitPrice, ammPools]) => {
          return getYAmountFromOneX(
            stxAddress,
            from,
            to,
            200 / fromUnitPrice,
            ammPools,
          ).catch(() =>
            getYAmountFromOneX(
              stxAddress,
              from,
              to,
              20 / fromUnitPrice,
              ammPools,
            ),
          )
        },
        { decorator: pMemoizeDecorator({ persistKey: "exchangeRate" }) },
      ),
    { keepAlive: true },
  )

  @computed get fromToExchangeRate$(): number {
    return this.exchangeRates(
      this.fromCurrency.read$,
      this.toCurrency.read$,
      this.fromCurrencyUnitPrice$,
    ).value$
  }

  @computed get toFromExchangeRate$(): number {
    return this.exchangeRates(
      this.toCurrency.read$,
      this.fromCurrency.read$,
      this.toCurrencyUnitPrice$,
    ).value$
  }

  validation = new ValidationStore(
    async (
      input: SpotFormData,
    ): Promise<Result<SpotFormData, SwapFormErrorType>> => {
      try {
        await getYAmountFromOneX(
          this.authStore.stxAddress$,
          this.fromCurrency.read$,
          this.toCurrency.read$,
          this.inputtedFromAmount.read$,
          this.availableAMMPools$,
        )
        return Result.ok(input)
      } catch (e) {
        if (e instanceof ClarityError) {
          if (errorCodesFromName("ERR-MAX-IN-RATIO").includes(e.code)) {
            return Result.error(SwapFormErrorType.ErrorMaxInRatio)
          } else if (errorCodesFromName("ERR-MAX-OUT-RATIO").includes(e.code)) {
            return Result.error(SwapFormErrorType.ErrorMaxOutRatio)
          } else {
            throw e
          }
        } else {
          throw e
        }
      }
    },
  )

  @computed get spotFormData$(): Result<SpotFormData, SwapFormErrorType> {
    if (!this.authStore.isWalletConnected) {
      return Result.error(SwapFormErrorType.WalletNotConnected)
    }
    if (this.fromCurrency.get() == null || this.toCurrency.get() == null) {
      return Result.error(SwapFormErrorType.TokenNotSelected)
    }
    const amount = this.inputtedFromAmount.get()
    if (amount == null || amount === 0) {
      return Result.error(SwapFormErrorType.AmountIsEmpty)
    }
    if (amount > this.fromCurrencyBalance$) {
      return Result.error(SwapFormErrorType.InsufficientTokenBalance)
    }
    const exchangeRate = this.exchangeRates(
      this.fromCurrency.read$,
      this.toCurrency.read$,
      this.fromCurrencyUnitPrice$,
    ).value$
    const valid = this.validation.read$
    if (valid?.type === "error") {
      return valid
    }
    return Result.ok({
      exchangeRate,
      stxAddress: this.authStore.stxAddress$,
      fromCurrency: this.fromCurrency.read$,
      toCurrency: this.toCurrency.read$,
      slippageAmount:
        this.toAmount$ * (1 - this.slippagePercent.slippagePercentage),
      fromAmount: Number(this.inputtedFromAmount.read$),
      middleSteps: this.routes$.slice(1, -1),
      ammPools: this.availableAMMPools$,
    })
  }

  @asyncAction async showConfirmationModal(
    run = runAsyncAction,
  ): Promise<void> {
    if (this.spotFormData$.type !== "ok") {
      return
    }
    const result = await run(
      this.validation.validate(this.spotFormData$.payload),
    )
    if (result.type === "error") {
      return
    }
    this.showConfirmation = true
  }

  @action hideConfirmationModal(): void {
    this.showConfirmation = false
  }

  @asyncAction async spot(
    spotFormData: SpotFormData,
    run = runAsyncAction,
  ): Promise<void> {
    if (this.spotRunning.running) return
    if (
      spotFormData.fromCurrency === null ||
      spotFormData.toCurrency === null
    ) {
      return
    }
    this.showConfirmation = false
    try {
      const transactionId = await run(
        runSpot(
          spotFormData.stxAddress,
          spotFormData.fromCurrency,
          spotFormData.toCurrency,
          spotFormData.fromAmount,
          spotFormData.slippageAmount,
          spotFormData.middleSteps,
          spotFormData.ammPools,
        ),
      )
      this.spotRunning.successRunning(transactionId)
      this.reset()
    } catch (e) {
      this.spotRunning.errorRunning(e as Error)
    }
  }
}

export interface SpotFormData {
  exchangeRate: number
  stxAddress: string
  fromCurrency: SwappableCurrency
  toCurrency: SwappableCurrency
  fromAmount: number
  slippageAmount: number
  middleSteps: Array<SwappableCurrency>
  ammPools: Array<AMMSwapPool.PoolTokens>
}

export default SpotStore
