import { computed, makeObservable } from "mobx"
import AccountStore from "../../../stores/accountStore/AccountStore"
import AuthStore from "../../../stores/authStore/AuthStore"
import { ChainStore } from "../../../stores/chainStore/ChainStore"
import CurrencyStore from "../../../stores/currencyStore/CurrencyStore"
import { LazyValue } from "../../../stores/LazyValue/LazyValue"
import type { TokenMetaInfo, TosItem } from "../component/types"
import type { LaunchPadContentfulDetail } from "./LaunchPadContentfulStore.services"
import { fetchContentfulDetails } from "./LaunchPadContentfulStore.services"
import LaunchPadContractStore from "./LaunchPadContractStore"

class LaunchPadContentfulStore {
  constructor(
    readonly source:
      | { type: "urlSlug"; urlSlug: string }
      | { type: "fullDetail"; detail: LaunchPadContentfulDetail },
    readonly authStore: Pick<AuthStore, "stxAddress$" | "currentBlockHeight$">,
    readonly accountStore: Pick<AccountStore, "getBalance$" | "transactions$">,
    readonly currencyStore: Pick<CurrencyStore, "getPrice$" | "getTokenInfo$">,
    readonly chainStore: Pick<
      ChainStore,
      "estimatedDateForBlock$" | "currentBlockHeight$"
    >,
  ) {
    makeObservable(this)
  }

  @computed get urlSlug(): string {
    return this.source.type === "urlSlug"
      ? this.source.urlSlug
      : this.source.detail.urlSlug
  }

  private data = new LazyValue(() => this.urlSlug, fetchContentfulDetails)

  @computed get contentfulDetail$(): LaunchPadContentfulDetail {
    if (this.source.type === "fullDetail") {
      return this.source.detail
    }
    return this.data.value$
  }

  @computed get meta$(): TokenMetaInfo {
    return this.contentfulDetail$.meta
  }

  @computed get introHtml$(): string {
    return this.contentfulDetail$.introHtml
  }

  @computed get isLegacyAlexIdo(): boolean {
    return this.contentfulDetail$.idoId === -1
  }

  @computed get idoId$(): number | undefined {
    return this.data.value$.idoId
  }

  @computed get tosItem$(): TosItem {
    return {
      name: `Terms and Conditions of $${this.meta$.title} IDO`,
      link:
        this.contentfulDetail$.tosLink ?? "https://terms.alexlab.co/launchpad",
      briefIntro: `BY ACCESSING/USING THE ALEX LAUNCHPAD AND/OR PURCHASING TOKENS VIA/USING ALEX LAUNCHPAD AND/OR TOKEN SALE PROTOCOL, YOU ACCEPT AND AGREE TO BE LEGALLY BOUND BY THE TERMS AND CONDITIONS OF $${this.meta$.title} IDO (INCLUDING THE ANNEXES HERETO) ("TERMS") AND YOU EXPRESSLY ACKNOWLEDGE AND REPRESENT THAT YOU HAVE CAREFULLY REVIEWED THESE TERMS AND FULLY UNDERSTAND THE RISKS, COSTS, AND BENEFITS OF PURCHASING TOKENS.`,
    }
  }

  @computed({ keepAlive: true }) get contractStore$():
    | LaunchPadContractStore
    | undefined {
    if (this.idoId$ == null || this.isLegacyAlexIdo) {
      return undefined
    }
    return new LaunchPadContractStore(
      this.idoId$,
      this.authStore,
      this.accountStore,
      this.currencyStore,
      this.chainStore,
    )
  }
}

export default LaunchPadContentfulStore
