import clsx from "clsx"
import { ReactNode, Suspense } from "react"
import { FCC } from "../../utils/reactHelpers/types"
import { LoadingControllerFactoryProvider } from "./useLoading"
import { useLoadingControllerFactory } from "./useLoadingControllerFactory"

export const ScopedLoadingBoundary: FCC<{
  className?: string
  contentContainerClassName?: string
  isLoading?: boolean
  loadingIndicator: ReactNode

  /**
   * @default true
   */
  mask?: boolean
}> = props => {
  const [isSubtreeLoading, ctrlFactory] = useLoadingControllerFactory()

  const isLoading = isSubtreeLoading || props.isLoading
  const renderMask = props.mask ?? true

  const loadingJsx = (
    <div
      className={clsx(
        "col-start-1 row-start-1 grid items-center justify-center",
        renderMask && "bg-black/30",
      )}
    >
      {props.loadingIndicator}
    </div>
  )

  return (
    <div className={clsx(props.className, "grid")}>
      <LoadingControllerFactoryProvider factory={ctrlFactory}>
        <Suspense fallback={loadingJsx}>
          <div
            className={clsx(
              "col-start-1 row-start-1",
              props.contentContainerClassName,
              isLoading && "opacity-50",
            )}
          >
            {props.children}
          </div>
        </Suspense>
      </LoadingControllerFactoryProvider>

      {isLoading && loadingJsx}
    </div>
  )
}
