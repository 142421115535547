import { FC } from "react"
import { useConnect } from "../../../components/ConnectWallet/ConnectProvider"
import { Spensor } from "../../../components/Spensor"
import { useAuthStore } from "../../../stores/authStore/useAuthStore"
import { assertNever } from "../../../utils/types"
import { BridgeChain } from "../types/BridgeChain"
import {
  AddressConnected,
  ConnectMetaMask,
  ConnectWallet,
} from "./WrapBridgePanel/WrapBridgePanel/WrapBridgePanel"

export const WiredChainConnector: FC<{
  chain: BridgeChain
}> = props => {
  const [connect] = useConnect()
  const authStore = useAuthStore()
  const { metaMaskModule } = authStore

  switch (props.chain) {
    case BridgeChain.Unknown:
    case BridgeChain.Ethereum:
    case BridgeChain.Goerli:
      return (
        <Spensor
          fallback={
            <ConnectMetaMask onClick={() => metaMaskModule.connect()} />
          }
        >
          {() => (
            <AddressConnected
              address={metaMaskModule.connectedWalletAddress.value$}
              onDisconnect={() => {
                void metaMaskModule.disconnect()
              }}
            />
          )}
        </Spensor>
      )
    case BridgeChain.Stacks:
      return (
        <Spensor fallback={<ConnectWallet onClick={() => connect()} />}>
          {() => (
            <AddressConnected
              address={authStore.stxAddress$}
              onDisconnect={() => {
                void authStore.signOut()
              }}
            />
          )}
        </Spensor>
      )
    default:
      assertNever(props.chain)
  }
}
