import { FC } from "react"
import { Spensor } from "../../../components/Spensor"
import { useLockdropDetailStore } from "../store/useLockdropDetailStore"
import { LockdropRankings } from "./Rankings/Rankings"

export const WiredLockdropRankings: FC<{ className?: string }> = ({
  className,
}) => {
  const store = useLockdropDetailStore()
  return (
    <Spensor fallback={<LockdropRankings rankings={[]} loading />}>
      {() => (
        <LockdropRankings {...store.rankingsProps$} className={className} />
      )}
    </Spensor>
  )
}
