import clsx from "clsx"
import { noop } from "lodash"
import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { LinkedButton } from "../../../../components/button/LinkedButton"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { CardPlate } from "../../../../components/Card"
import {
  NoteParagraph,
  WarnIcon,
} from "../../../../components/NoteParagraph/NoteParagraph"
import {
  SuspenseResource,
  suspenseResource,
} from "../../../../utils/SuspenseResource"
import { TokenInfoPresets } from "../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { RectButton } from "../wrappedCommonComponents/RectButton"
import { TradingAccountContent } from "./TradingAccountContent"

function neverResolveSuspenseResource<T>(): SuspenseResource<T> {
  return suspenseResource(() => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    throw new Promise(() => {})
  })
}

export const TradingAccountContent$ApplyEligibility: FC<{
  gapClassName?: string
  formLink: string
}> = props => {
  const { $t } = useIntl()
  return (
    <div className={"grid"}>
      <div
        className={clsx(
          "col-start-1 col-span-1 row-start-1 row-span-1",
          "blur",
          "flex flex-col",
          props.gapClassName,
        )}
      >
        <TradingAccountContent
          gapClassName={props.gapClassName}
          totalBalance={neverResolveSuspenseResource<number>()}
          viewingToken={TokenInfoPresets.APower}
          viewingTokenAvailableCount={neverResolveSuspenseResource<number>()}
          viewingTokenLockedCount={neverResolveSuspenseResource<number>()}
          availableTokens={[]}
          onViewingTokenChange={noop}
          onDeposit={noop}
          onWithdraw={noop}
        />
      </div>

      <div
        className={clsx(
          "col-start-1 col-span-1 row-start-1 row-span-1 z-1",
          "min-w-full min-h-full flex flex-col gap-2.5 items-center justify-center",
        )}
      >
        <CardPlate>
          <NoteParagraph className={"text-yellow-500"} icon={<WarnIcon />}>
            {$t(
              defineMessage({
                defaultMessage:
                  "Visit ALEX2 introduction website to acquire Qualification of the Test",
                description: `Orderbook/Trading Account Panel/apply eligibility state/apply eligibility tip text`,
              }),
            )}
          </NoteParagraph>
        </CardPlate>

        <LinkedButton
          Variant={p => (
            <RectButton
              {...p}
              Variant={GradientFilledButton}
              className={"flex items-center"}
            />
          )}
          link={props.formLink}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 2V14H16V2H0ZM4.41533 7.286L1.33333 11.094V4.78867L4.41533 7.286ZM1.65467 3.33333H14.3447L8 8.47533L1.65467 3.33333ZM5.45067 8.12533L8 10.1913L10.5533 8.122L14.2947 12.6667H1.77533L5.45067 8.12533ZM11.5893 7.28267L14.6667 4.78867V11.0207L11.5893 7.28267Z"
              fill="#F3F4F6"
            />
          </svg>
          &nbsp;{" "}
          {$t(
            defineMessage({
              defaultMessage: "Go Website",
              description: `Orderbook/Trading Account Panel/apply eligibility state/apply eligibility website button text`,
            }),
          )}
        </LinkedButton>
      </div>
    </div>
  )
}
