import clsx from "clsx"
import { FC, ReactNode } from "react"
import { GradientText } from "../../../../../components/GradientText"
import { NavLink } from "../../../../../components/NavLink"
import { Spensor } from "../../../../../components/Spensor"
import { TokenCount } from "../../../../../components/TokenCount"
import { TokenName } from "../../../../../components/TokenName"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { FCC } from "../../../../../utils/reactHelpers/types"
import {
  readResource,
  SuspenseResource,
} from "../../../../../utils/SuspenseResource"
import borrowBgUrl from "./borrowBg.svg"
import depositBgUrl from "./depositBg.svg"

export const SummaryRow: FC<{
  className?: string
  gapClassName: string
  alexToken: TokenInfo
  depositedAlexCount: SuspenseResource<number>
  depositIntroLink: string
  borrowedAlexCount: SuspenseResource<number>
  borrowIntroLink: string
}> = props => (
  <div className={clsx(props.className, props.gapClassName, "flex flex-wrap")}>
    <SummaryRowCard
      className={"flex-1"}
      bgGradientStyle={
        "linear-gradient(99.29deg, rgba(0, 0, 0, 0.5) 0%, rgba(23, 187, 133, 0.5) 100%)"
      }
      cornerBgImageUrl={depositBgUrl}
      title={
        <DefaultTitle
          gradientStyle={
            "linear-gradient(90.83deg, #FFFFFF 0.71%, #10B981 99.29%)"
          }
        >
          Total Deposit Amount
        </DefaultTitle>
      }
      content={
        <DefaultContent
          token={props.alexToken}
          tokenCount={props.depositedAlexCount}
        />
      }
      introLink={
        <DefaultIntroLink link={props.depositIntroLink}>
          How does Deposit work?
        </DefaultIntroLink>
      }
    />

    <SummaryRowCard
      className={"flex-1"}
      bgGradientStyle={
        "linear-gradient(99.29deg, rgba(0, 0, 0, 0.5) 0%, rgba(237, 79, 157, 0.5) 100%)"
      }
      cornerBgImageUrl={borrowBgUrl}
      title={
        <DefaultTitle
          gradientStyle={
            "linear-gradient(90.83deg, #FFFFFF 0.71%, #EC4899 99.29%)"
          }
        >
          Total Borrow Amount
        </DefaultTitle>
      }
      content={
        <DefaultContent
          token={props.alexToken}
          tokenCount={props.borrowedAlexCount}
        />
      }
      introLink={
        <DefaultIntroLink link={props.borrowIntroLink}>
          How does Borrow work?
        </DefaultIntroLink>
      }
    />
  </div>
)

export const SummaryRowCard: FC<{
  className?: string
  bgGradientStyle: string
  cornerBgImageUrl: string
  title: ReactNode
  content: ReactNode
  introLink: ReactNode
}> = props => (
  <div
    className={clsx(
      "flex flex-col gap-4 rounded-2xl p-6 min-w-[330px] min-h-[220px]",
      props.className,
    )}
    style={{
      backgroundImage: `url(${props.cornerBgImageUrl}), ${props.bgGradientStyle}`,
      backgroundSize: "206px 150px, 100% 100%",
      backgroundPosition: "bottom 0px right 24px, center",
      backgroundRepeat: "no-repeat",
    }}
  >
    <div>{props.title}</div>

    <div>{props.content}</div>

    <div className={"mt-auto text-sm text-blue-600"}>{props.introLink}</div>
  </div>
)

export const DefaultTitle: FCC<{
  gradientStyle: string
}> = props => (
  <GradientText className={"text-2xl"} gradientStyle={props.gradientStyle}>
    {props.children}
  </GradientText>
)

export const DefaultContent: FC<{
  token: TokenInfo
  tokenCount: SuspenseResource<number>
}> = props => (
  <span className={"flex items-center text-3xl"}>
    ≈&nbsp;
    <Spensor>
      {() => (
        <TokenCount
          token={{ ...props.token, precision: 0 }}
          count={readResource(props.tokenCount)}
        />
      )}
    </Spensor>
    &nbsp;
    <TokenName className={"text-xl"} token={props.token} />
  </span>
)

export const DefaultIntroLink: FCC<{ link: string }> = props => (
  <NavLink to={props.link}>{props.children}</NavLink>
)
